import React from "react";
import CreateFinalTestForm from "../../components/forms/CreateFinalTestForm";

const CreateFinalTestFormPage: React.FC = () => {

	return (
		<CreateFinalTestForm/>
	);
};

export default CreateFinalTestFormPage;
