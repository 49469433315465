import React, {useState} from "react";
import {Button} from "reactstrap";
import {FinalTestForm} from "client";
import FinalTestFormsHistoryOptionsModal from "../../modals/FinalTestFormsHistoryOptionsModal";
import FinalTestFormsOptionsModal from "../../modals/FinalTestFormsOptionsModal";

interface IProps {
	form: FinalTestForm;
	onDone(): Promise<void>;
}

const FinalTestFormsOptionsCell: React.FC<IProps> = (props) => {

	const [showOptionsModal, setShowOptionsModal] = useState(false);

	/**
	 * Toggle showing the options modal.
	 *
	 */
	function toggleShowOptionsModal(): void {
		setShowOptionsModal(!showOptionsModal);
	}

	/**
	 * close modal & call API to update parent table.
	 *
	 */
	function onDone(): void {
		setShowOptionsModal(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<FinalTestFormsOptionsModal
				isOpen={showOptionsModal}
				form={props.form}
				onClose={toggleShowOptionsModal}
				onDone={onDone}
			/>

			<div className="d-flex justify-content-center">
				<Button
					color="black"
					onClick={toggleShowOptionsModal}
				>
					Final Test Form Options
				</Button>
			</div>
		</React.Fragment>
	);
};

export default FinalTestFormsOptionsCell;
