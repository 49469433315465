/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
/**
 * Check if a given object implements the Address interface.
 */
export function instanceOfAddress(value) {
    let isInstance = true;
    isInstance = isInstance && "addressLine1" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "country" in value;
    return isInstance;
}
export function AddressFromJSON(json) {
    return AddressFromJSONTyped(json, false);
}
export function AddressFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'addressLine1': json['addressLine1'],
        'addressLine2': !exists(json, 'addressLine2') ? undefined : json['addressLine2'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'city': json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'country': json['country'],
    };
}
export function AddressToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'zipCode': value.zipCode,
        'city': value.city,
        'state': value.state,
        'country': value.country,
    };
}
