import {CreateCraneMonthlyLogBody} from "client";

/**
 * Parse the keys from the interfaces in the strings from the PDFs.
 *
 * @param key
 */
export function getCraneMonthlyLogInspectionItemDisplayName(key: keyof CreateCraneMonthlyLogBody): string {
	switch (key) {
		case "visualInspectionAndNoteBogieWear":
			return "Visual inspection and note bogie wear (traveling cranes)";
		case "visualInspectionOfCriticalAreas":
			return "Visual inspection of the critical areas, paying special attention to the welding's, base, slewing table, and joints";
		case "visualInspectionOfWearOfPad":
			return "Visual inspection for wear of pad, drum, clamp on exposed brake system";
		case "inspectHoisting":
			return "Inspect hoisting, trolley or luffing line sheave reaving path, sheave wear, bearings, and mounts";
		case "checkWireRope":
			return "Check the wire rope lay on the drum and lubricate the wire ropes on a dry day at end of shift";
		case "greaseTheHoisting":
			return "Grease the hoisting, trolley, or luffing mechanism";
		case "visualInspectionOfCabSupport":
			return "Visual inspection of the cab support, railings, cab windows and guards, fire extinguisher and heater";
		case "visualInspectionForOfSlewRingTeeth":
			return "Visual inspection of slew ring teeth, grease inside and outside the slewing ring";
		case "checkAllGearboxes":
			return "Check all gearboxes for leaks and oil level";
		case "visualInspectionOfPendantLineConnections":
			return "Visual inspection of pendant line connections, pins and keepers";
		case "supervisorNotifiedOfDefects":
			return "Supervisor notified of defects or faults";
		default:
			return key
	}
}
