import {Token} from "client";
import React from "react";
import AdminCreateTimeSheetForUserForm from "../../components/forms/AdminCreateTimeSheetForUserForm";
import {RouteComponentProps} from "react-router";
import {useHistory} from "react-router-dom";

interface IProps extends RouteComponentProps{
	dispatch?: any;
	fullToken: Token;
}

const AdminCreateTimeSheetForUserPage: React.FC<IProps> = (props) => {

	const history = useHistory();

	const params: URLSearchParams = new URLSearchParams(props.location?.search);
	const timeSheetId: string = params.get("timesheet");

	if (timeSheetId?.length < 1) {
		history.replace("/allied-crane-employee-forms/time-sheets");
	}

	return (
		<AdminCreateTimeSheetForUserForm timeSheetId={timeSheetId}/>
	);
};

export default AdminCreateTimeSheetForUserPage;
