/**
 * Checks if published date exists.
 *
 * @param publishedDate
 */
export function getFormPublishedStatus(publishedDate: number): string {
	return (publishedDate !== undefined && typeof publishedDate === "number") ? "Yes" : "No";
}

/**
 * Checks if submitted data exists
 *
 * @param submittedDate
 */
export function getFormSubmittedStatus(submittedDate: number): string {
	return (submittedDate !== undefined && typeof submittedDate === "number") ? "Yes" : "No";
}
