import React, {useState} from "react";
import {Site} from "client";
import {Button} from "reactstrap";
import ManageSitesDisableModal from "../../modals/ManageSitesDisableModal";

interface IProps {
	site: Site;
	onDone: () => Promise<void>;
}

const ManageSitesDisableCell: React.FC<IProps> = (props) => {

	const [showDisable, setShowDisable] = useState(false);

	/**
	 * Toggle showing the edit user modal.
	 *
	 */
	function toggleShowDisable(): void {
		setShowDisable(!showDisable);
	}

	function onDone(): void {
		setShowDisable(false);
		props.onDone().then().catch();
	}

	return (
		<div className="d-flex justify-content-center">
			<ManageSitesDisableModal
				isOpen={showDisable}
				site={props.site}
				onClose={toggleShowDisable}
				onDone={onDone}
			/>

			<Button
				color="red"
				onClick={toggleShowDisable}
			>
				Delete Site
			</Button>
		</div>
	);
};

export default ManageSitesDisableCell;
