import React from "react";

interface IProps {
	value: string;
	onChange: (_newValue: string) => void;
}

const WorkPerformedInput: React.FC<IProps> = (props) => {

	function onChangeHelper(e: React.ChangeEvent<HTMLTextAreaElement>): void {
		props.onChange(e.target.value);
	}

	return (
		<textarea
			placeholder="Add Comment..."
			value={props.value}
			onChange={onChangeHelper}
		/>
	);
};

export default WorkPerformedInput;
