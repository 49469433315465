import React from "react";
import {Button} from "reactstrap";
import {CraneDailyLogGroup} from "client";
import ViewCraneDailyLogButton from "../../printables/buttons/ViewCraneDailyLogButton";

interface IProps {
	logs: CraneDailyLogGroup;
}

const CraneDailyLogsAdminsViewCell: React.FC<IProps> = (props) => {

	return (
		<div className="d-flex justify-content-center">
			<ViewCraneDailyLogButton groupedCraneLogs={props.logs}>
				View Logs
			</ViewCraneDailyLogButton>
		</div>
	);
};

export default CraneDailyLogsAdminsViewCell;
