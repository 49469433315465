import React from "react";
import {Button} from "reactstrap";
import {CraneWeeklyLog} from "client";
import ViewCraneWeeklyLogButton from "../../printables/buttons/ViewCraneWeeklyLogButton";

interface IProps {
	log: CraneWeeklyLog;
}

const CraneWeeklyLogsAdminsViewCell: React.FC<IProps> = (props) => {

	return (
		<div className="d-flex justify-content-center">
			<ViewCraneWeeklyLogButton id={props.log?._id}>
				View Log
			</ViewCraneWeeklyLogButton>
		</div>
	);
};

export default CraneWeeklyLogsAdminsViewCell;
