import {CreateCraneDailyLogBody, InspectionStatus, UpdateCraneDailyLogBody} from "client";
import {IAlliedSelectInputListItem} from "../components/inputs/inputList/AlliedSelectInputList";
import {ISelectOption} from "../components/SelectOptionsFactory";
import {getCraneDailyLogInspectionItemDisplayName} from "./getCraneDailyLogInspectionItemDisplayName";
import {
	getLogInspectionItemDisplayAndInputOptions,
	InspectionItemLabelConfig,
	InspectionOptionLookupInterfaces,
} from "./logInspectionOptionsFinder";

/**
 * Returns array with the Label & Value pairs for the select inputs,
 * with dynamically generated options based on "key".
 *
 * @param key
 */
export function getInspectionItemOptions(key: keyof InspectionOptionLookupInterfaces): Array<ISelectOption> {
	const options: InspectionItemLabelConfig = getLogInspectionItemDisplayAndInputOptions(key).inputOptions;

	return [
		{
			label: options.positive,
			value: InspectionStatus.APPROVED,
		},
		{
			label: options.negative,
			value: InspectionStatus.FAULTY,
		},
		{
			label: options.notApplicable,
			value: InspectionStatus.NOT_APPLICABLE,
		},
	];
}

/**
 * Create config for a single inspection item input.
 *
 * @param key
 * @param createCraneDailyLogForm
 */
export function convertCraneDailyInspectionItemToInputListItem(key: keyof CreateCraneDailyLogBody, createCraneDailyLogForm: CreateCraneDailyLogBody | UpdateCraneDailyLogBody): IAlliedSelectInputListItem {
	return {
		label: getCraneDailyLogInspectionItemDisplayName(key),
		interfaceKey: key,
		value: createCraneDailyLogForm[key] as string,
		options: getInspectionItemOptions(key),
	}
}

/**
 * Convert the array of keys on the daily log to usable drop-down options
 *
 * @param keys
 * @param createCraneDailyLogForm
 */
export function convertCraneDailyInspectionItemsToInputListItems(keys: string[], createCraneDailyLogForm: CreateCraneDailyLogBody | UpdateCraneDailyLogBody): Array<IAlliedSelectInputListItem> {
	return keys.map((_key: keyof CreateCraneDailyLogBody) => {
		return convertCraneDailyInspectionItemToInputListItem(_key, createCraneDailyLogForm);
	})
}
