import {
    Crane,
    CraneOperator,
    CraneDailyLog,
    CreateLuffingTowerCraneDailyLogBody,
    CreateSelfErectingCraneDailyLogBody, CreateTowerCraneDailyLogBody, GeneratorDailyLog,
    LogComment,
    User, UserType
} from "client";
import React, {ReactNode} from "react";
import {find} from "lodash";
import moment from "moment";
import {FiCheck} from "react-icons/fi";
import {FiX} from "react-icons/all";
import {valueOrBustPrintables} from "../../../utils/valueOrBust";
import {parseInspectionStatusForPrintableTable} from "../../../utils/parseInspectionStatus";
import {getCraneDailyLogInspectionItems} from "../../../utils/getCraneDailyLogInspectionItems";
import {getCraneDailyLogInspectionItemDisplayName} from "../../../utils/getCraneDailyLogInspectionItemDisplayName";
import {convertBooleanToString} from "../../../utils/convertBooleanToString";
import PrintableSpacer from "./PrintableSpacer";
import {InspectionOptionLookupInterfaces} from "../../../utils/logInspectionOptionsFinder";
import {getFullNameForTable} from "../../../utils/getFullNameForTable";
import {isCraneOperator} from "../../../utils/userTypeGaurds";

interface IProps {
    crane: Crane;
    weekOf: number;
    craneDailyLogs: Array<CraneDailyLog>;
}

const PrintableCraneDailyLogChecklistTable: React.FC<IProps> = (props) => {

    /**
     * Renders a check mark if any flagged comments are found,
     * otherwise renders and "x".
     *
     * @param _log
     */
    function getEntryMadeDeficiencyReport(_log: CraneDailyLog): ReactNode {
        return _log?.comments?.reduce((acc: number, comment: LogComment) => {
            if (comment.flagged) {
                acc++;
            }

            return acc;
        }, 0) > 0 ? (
            <React.Fragment>
                <FiCheck className="printable__react-icon"/>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <FiX className="printable__react-icon"/>
            </React.Fragment>
        );
    }

    function renderDailyOperators(log: CraneDailyLog): ReactNode {
        return log?.users?.map((u: User) => {
            if (isCraneOperator(u)) {
                return (
                    <p style={{whiteSpace: "pre"}}>
                        {`${getFullNameForTable(u)} -\n#${u.certificationNumber}`}
                    </p>
                );
            }

            return (
                <p style={{whiteSpace: "pre"}}>
                    {getFullNameForTable(u)}
                </p>
            );
        });
    }

    /**
     * Create reusable variables for each log.
     *
     */
    const sundayLog: CraneDailyLog = props.craneDailyLogs[0];
    const mondayLog: CraneDailyLog = props.craneDailyLogs[1];
    const tuesdayLog: CraneDailyLog = props.craneDailyLogs[2];
    const wednesdayLog: CraneDailyLog = props.craneDailyLogs[3];
    const thursdayLog: CraneDailyLog = props.craneDailyLogs[4];
    const fridayLog: CraneDailyLog = props.craneDailyLogs[5];
    const saturdayLog: CraneDailyLog = props.craneDailyLogs[6];

    function makeDynamicInspectionRowsBasedOnCraneType(): ReactNode {
        return getCraneDailyLogInspectionItems(props.crane?.craneType)?.map((inspectionItem: keyof CreateLuffingTowerCraneDailyLogBody | keyof CreateSelfErectingCraneDailyLogBody | keyof CreateTowerCraneDailyLogBody, i: number) => {
            return (
                <tr key={`crane-daily-log-inspection-row-item_${i}_${inspectionItem}_${Math.random()}`}>
                    <td>
                        {i + 1}
                    </td>

                    <td>
                        {getCraneDailyLogInspectionItemDisplayName(inspectionItem)}
                    </td>

                    <td className="printable__table__centered">
                        {valueOrBustPrintables(sundayLog, parseInspectionStatusForPrintableTable(inspectionItem as keyof InspectionOptionLookupInterfaces, sundayLog?.[inspectionItem]))}
                    </td>

                    <td className="printable__table__centered">
                        {valueOrBustPrintables(mondayLog, parseInspectionStatusForPrintableTable(inspectionItem as keyof InspectionOptionLookupInterfaces, mondayLog?.[inspectionItem]))}
                    </td>

                    <td className="printable__table__centered">
                        {valueOrBustPrintables(tuesdayLog, parseInspectionStatusForPrintableTable(inspectionItem as keyof InspectionOptionLookupInterfaces, tuesdayLog?.[inspectionItem]))}
                    </td>

                    <td className="printable__table__centered">
                        {valueOrBustPrintables(wednesdayLog, parseInspectionStatusForPrintableTable(inspectionItem as keyof InspectionOptionLookupInterfaces, wednesdayLog?.[inspectionItem]))}
                    </td>

                    <td className="printable__table__centered">
                        {valueOrBustPrintables(thursdayLog, parseInspectionStatusForPrintableTable(inspectionItem as keyof InspectionOptionLookupInterfaces, thursdayLog?.[inspectionItem]))}
                    </td>

                    <td className="printable__table__centered">
                        {valueOrBustPrintables(fridayLog, parseInspectionStatusForPrintableTable(inspectionItem as keyof InspectionOptionLookupInterfaces, fridayLog?.[inspectionItem]))}
                    </td>

                    <td className="printable__table__centered">
                        {valueOrBustPrintables(saturdayLog, parseInspectionStatusForPrintableTable(inspectionItem as keyof InspectionOptionLookupInterfaces, saturdayLog?.[inspectionItem]))}
                    </td>
                </tr>
            );
        });
    }

    return (
        <div style={{width: "100"}}>
            <table className="printable__table">
                <tr>
                    <td className="printable__table__label">
                        #
                    </td>

                    <td className="printable__table__label">
                        Inspection Item
                    </td>

                    <td className="printable__table__label printable__table__centered">
                        SUN
                    </td>

                    <td className="printable__table__label printable__table__centered">
                        MON
                    </td>

                    <td className="printable__table__label printable__table__centered">
                        Tue
                    </td>

                    <td className="printable__table__label printable__table__centered">
                        WED
                    </td>

                    <td className="printable__table__label printable__table__centered">
                        THU
                    </td>

                    <td className="printable__table__label printable__table__centered">
                        FRI
                    </td>

                    <td className="printable__table__label printable__table__centered">
                        SAT
                    </td>
                </tr>

                {makeDynamicInspectionRowsBasedOnCraneType()}

                <tr className="printable__table__spacer-row">
                    <PrintableSpacer size={10}/>
                </tr>

                <tr>
                    <td className="printable__table__label" colSpan={2}>
                        Operators
                    </td>

                    <td className="printable__table__centered">
                        {renderDailyOperators(sundayLog)}
                    </td>

                    <td className="printable__table__centered">
                        {renderDailyOperators(mondayLog)}
                    </td>

                    <td className="printable__table__centered">
                        {renderDailyOperators(tuesdayLog)}
                    </td>

                    <td className="printable__table__centered">
                        {renderDailyOperators(wednesdayLog)}
                    </td>

                    <td className="printable__table__centered">
                        {renderDailyOperators(thursdayLog)}
                    </td>

                    <td className="printable__table__centered">
                        {renderDailyOperators(fridayLog)}
                    </td>

                    <td className="printable__table__centered">
                        {renderDailyOperators(saturdayLog)}
                    </td>
                </tr>

                <tr>
                    <td className="printable__table__label" colSpan={2}>
                        Operator Daily Initial
                    </td>

                    <td className="printable__table__centered">
                        {valueOrBustPrintables(sundayLog, convertBooleanToString(sundayLog?.initials))}
                    </td>

                    <td className="printable__table__centered">
                        {valueOrBustPrintables(mondayLog, convertBooleanToString(mondayLog?.initials))}
                    </td>

                    <td className="printable__table__centered">
                        {valueOrBustPrintables(tuesdayLog, convertBooleanToString(tuesdayLog?.initials))}
                    </td>

                    <td className="printable__table__centered">
                        {valueOrBustPrintables(wednesdayLog, convertBooleanToString(wednesdayLog?.initials))}
                    </td>
                    <td className="printable__table__centered">
                        {valueOrBustPrintables(thursdayLog, convertBooleanToString(thursdayLog?.initials))}
                    </td>

                    <td className="printable__table__centered">
                        {valueOrBustPrintables(fridayLog, convertBooleanToString(fridayLog?.initials))}
                    </td>

                    <td className="printable__table__centered">
                        {valueOrBustPrintables(saturdayLog, convertBooleanToString(saturdayLog?.initials))}
                    </td>
                </tr>

                <tr>
                    <td className="printable__table__label" colSpan={2}>
                        Daily Crane Hours
                    </td>

                    <td className="printable__table__centered">
                        {valueOrBustPrintables(sundayLog, sundayLog?.hours?.toString())}
                    </td>

                    <td className="printable__table__centered">
                        {valueOrBustPrintables(mondayLog, mondayLog?.hours?.toString())}
                    </td>

                    <td className="printable__table__centered">
                        {valueOrBustPrintables(tuesdayLog, tuesdayLog?.hours?.toString())}
                    </td>

                    <td className="printable__table__centered">
                        {valueOrBustPrintables(wednesdayLog, wednesdayLog?.hours?.toString())}
                    </td>

                    <td className="printable__table__centered">
                        {valueOrBustPrintables(thursdayLog, thursdayLog?.hours?.toString())}
                    </td>

                    <td className="printable__table__centered">
                        {valueOrBustPrintables(fridayLog, fridayLog?.hours?.toString())}
                    </td>

                    <td className="printable__table__centered">
                        {valueOrBustPrintables(saturdayLog, saturdayLog?.hours?.toString())}
                    </td>
                </tr>

                <tr className="printable__table__spacer-row">
                    <PrintableSpacer size={10}/>
                </tr>

                <tr>
                    <td className="printable__table__label" colSpan={2}>
                        Deficiency Reports Made
                    </td>

                    <td className="printable__table__centered">
                        {getEntryMadeDeficiencyReport(sundayLog)}
                    </td>

                    <td className="printable__table__centered">
                        {getEntryMadeDeficiencyReport(mondayLog)}
                    </td>

                    <td className="printable__table__centered">
                        {getEntryMadeDeficiencyReport(tuesdayLog)}
                    </td>

                    <td className="printable__table__centered">
                        {getEntryMadeDeficiencyReport(wednesdayLog)}
                    </td>

                    <td className="printable__table__centered">
                        {getEntryMadeDeficiencyReport(thursdayLog)}
                    </td>

                    <td className="printable__table__centered">
                        {getEntryMadeDeficiencyReport(fridayLog)}
                    </td>

                    <td className="printable__table__centered">
                        {getEntryMadeDeficiencyReport(saturdayLog)}
                    </td>
                </tr>
            </table>
        </div>
    );
};

export default PrintableCraneDailyLogChecklistTable;
