import React from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import AlliedModal from "./AlliedModal";
import {EquipmentApi, MaterialHoist, Token} from "client";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	materialHoist: MaterialHoist,
	onClose: () => void;
	onDone: () => void;
}

const ManageMaterialHoistsDisableModal: React.FC<IProps> = (props) => {

	/**
	 * Call api to toggle the material hoist's "disabled" status.
	 *
	 */
	async function disableMaterialHoist(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			if (props.materialHoist?.disabled) {
				await new EquipmentApi(getConfig(props.fullToken)).enableEquipment({
					enableEquipmentBody: {
						equipmentID: props.materialHoist?._id,
					}
				})
			} else {
				await new EquipmentApi(getConfig(props.fullToken)).disableEquipment({
				disableEquipmentBody: {
					equipmentID: props.materialHoist?._id,
				},
			});
			}

			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<AlliedModal
			isOpen={props.isOpen}
			size="md"
		>
			<AlliedModalHeader
				title={`${props.materialHoist.disabled ? `Enable` : `Disable`} Material Hoist`}
				onClose={props.onClose}
			/>

			<hr/>

			<p>
				Are you sure you want to {props.materialHoist.disabled ? `enable` : `disable`} this {`${props.materialHoist?.make} ${props.materialHoist?.model} material hoist`}?
			</p>

			<div>
				<Button
					color="red"
					onClick={disableMaterialHoist}
				>
					{props.materialHoist.disabled ? `Enable` : `Disable`} Material Hoist
				</Button>
			</div>
		</AlliedModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageMaterialHoistsDisableModal);
