import {Button} from "reactstrap";
import React, {useState} from "react";
import {Deficiency, DeficiencyStatus} from "client";
import GeneralDeficiencyResolveIssueModal from "../../modals/GeneralDeficiencyResolveIssueModal";

interface IProps {
	deficiency: Deficiency;
	onDone: () => Promise<void>;
}

const GeneralDeficiencyResolveIssueCell: React.FC<IProps> = (props) => {

	const [showModal, setShowModal] = useState(false);

	/**
	 * Toggle visibility of the modal.
	 *
	 */
	function toggleResolveModal(): void {
		setShowModal(!showModal);
	}

	/**
	 * When finished resolving, close modal,
	 * and call props to udpate table.
	 *
	 */
	function onDoneResolving(): void {
		setShowModal(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<GeneralDeficiencyResolveIssueModal
				isOpen={showModal}
				deficiency={props.deficiency}
				onClose={toggleResolveModal}
				onDone={onDoneResolving}
			/>

			<div className="allied-table-cell">
				<Button
					color="red"
					onClick={toggleResolveModal}
					disabled={props.deficiency?.status === DeficiencyStatus.RESOLVED}
				>
					Resolve Issue
				</Button>
			</div>
		</React.Fragment>
	);
};

export default GeneralDeficiencyResolveIssueCell;
