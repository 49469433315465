import {Log, User} from "client";

/**
 * Check if the form has been approved.
 *
 * @param approvedBy
 */
export function getFormApprovedStatus(approvedBy: User | Log): string {
	if ((approvedBy as User).username != null) {
		return "Yes";
	}

	return "No";
}
