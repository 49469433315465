/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { InspectionStatusFromJSON, InspectionStatusToJSON, } from './InspectionStatus';
import { LogFromJSONTyped, LogToJSON, } from './Log';
import { LogCommentFromJSON, LogCommentToJSON, } from './LogComment';
/**
 * Check if a given object implements the CraneWeeklyLog interface.
 */
export function instanceOfCraneWeeklyLog(value) {
    let isInstance = true;
    return isInstance;
}
export function CraneWeeklyLogFromJSON(json) {
    return CraneWeeklyLogFromJSONTyped(json, false);
}
export function CraneWeeklyLogFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...LogFromJSONTyped(json, ignoreDiscriminator),
        'comments': !exists(json, 'comments') ? undefined : (json['comments'].map(LogCommentFromJSON)),
        'visualInspectionForOfSlewRing': !exists(json, 'visualInspectionForOfSlewRing') ? undefined : InspectionStatusFromJSON(json['visualInspectionForOfSlewRing']),
        'visualInspectionOfTrolleyRollers': !exists(json, 'visualInspectionOfTrolleyRollers') ? undefined : InspectionStatusFromJSON(json['visualInspectionOfTrolleyRollers']),
        'visualInspectionOfSheaves': !exists(json, 'visualInspectionOfSheaves') ? undefined : InspectionStatusFromJSON(json['visualInspectionOfSheaves']),
        'jibBackstops': !exists(json, 'jibBackstops') ? undefined : InspectionStatusFromJSON(json['jibBackstops']),
        'boomHoistBrake': !exists(json, 'boomHoistBrake') ? undefined : InspectionStatusFromJSON(json['boomHoistBrake']),
        'checkWireRopeEnds': !exists(json, 'checkWireRopeEnds') ? undefined : InspectionStatusFromJSON(json['checkWireRopeEnds']),
        'inspectLoadLine': !exists(json, 'inspectLoadLine') ? undefined : InspectionStatusFromJSON(json['inspectLoadLine']),
        'visualInspectionOfTieIns': !exists(json, 'visualInspectionOfTieIns') ? undefined : InspectionStatusFromJSON(json['visualInspectionOfTieIns']),
        'ensureMachineIsProperlyLubricated': !exists(json, 'ensureMachineIsProperlyLubricated') ? undefined : InspectionStatusFromJSON(json['ensureMachineIsProperlyLubricated']),
        'visualInspectionOfAllDriveComponents': !exists(json, 'visualInspectionOfAllDriveComponents') ? undefined : InspectionStatusFromJSON(json['visualInspectionOfAllDriveComponents']),
        'visualInspectionOfCounterweightSupports': !exists(json, 'visualInspectionOfCounterweightSupports') ? undefined : InspectionStatusFromJSON(json['visualInspectionOfCounterweightSupports']),
        'visualInspectionOfAnchorBolts': !exists(json, 'visualInspectionOfAnchorBolts') ? undefined : InspectionStatusFromJSON(json['visualInspectionOfAnchorBolts']),
        'visualInspectionOfTowerBolts': !exists(json, 'visualInspectionOfTowerBolts') ? undefined : InspectionStatusFromJSON(json['visualInspectionOfTowerBolts']),
        'ensureTrackIsLevel': !exists(json, 'ensureTrackIsLevel') ? undefined : InspectionStatusFromJSON(json['ensureTrackIsLevel']),
        'supervisorNotifiedOfDefects': !exists(json, 'supervisorNotifiedOfDefects') ? undefined : InspectionStatusFromJSON(json['supervisorNotifiedOfDefects']),
    };
}
export function CraneWeeklyLogToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...LogToJSON(value),
        'comments': value.comments === undefined ? undefined : (value.comments.map(LogCommentToJSON)),
        'visualInspectionForOfSlewRing': InspectionStatusToJSON(value.visualInspectionForOfSlewRing),
        'visualInspectionOfTrolleyRollers': InspectionStatusToJSON(value.visualInspectionOfTrolleyRollers),
        'visualInspectionOfSheaves': InspectionStatusToJSON(value.visualInspectionOfSheaves),
        'jibBackstops': InspectionStatusToJSON(value.jibBackstops),
        'boomHoistBrake': InspectionStatusToJSON(value.boomHoistBrake),
        'checkWireRopeEnds': InspectionStatusToJSON(value.checkWireRopeEnds),
        'inspectLoadLine': InspectionStatusToJSON(value.inspectLoadLine),
        'visualInspectionOfTieIns': InspectionStatusToJSON(value.visualInspectionOfTieIns),
        'ensureMachineIsProperlyLubricated': InspectionStatusToJSON(value.ensureMachineIsProperlyLubricated),
        'visualInspectionOfAllDriveComponents': InspectionStatusToJSON(value.visualInspectionOfAllDriveComponents),
        'visualInspectionOfCounterweightSupports': InspectionStatusToJSON(value.visualInspectionOfCounterweightSupports),
        'visualInspectionOfAnchorBolts': InspectionStatusToJSON(value.visualInspectionOfAnchorBolts),
        'visualInspectionOfTowerBolts': InspectionStatusToJSON(value.visualInspectionOfTowerBolts),
        'ensureTrackIsLevel': InspectionStatusToJSON(value.ensureTrackIsLevel),
        'supervisorNotifiedOfDefects': InspectionStatusToJSON(value.supervisorNotifiedOfDefects),
    };
}
