import {EquipmentType} from "client";

/**
 * Parse the EquipmentType enum into a user friendly display string.
 *
 * @param _equipmentType
 */
export function getEquipmentTypeDisplay(_equipmentType: EquipmentType): string {

	switch (_equipmentType) {
		case EquipmentType.CRANE:
			return "Crane";
		case EquipmentType.GENERATOR:
			// return "Generator";
			return "Equipment";
		case EquipmentType.MATERIAL_HOIST:
			return "Material Hoist";
		default:
			return _equipmentType;
	}
}
