/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { EquipmentFromJSON, EquipmentToJSON, } from './Equipment';
import { SiteFromJSON, SiteToJSON, } from './Site';
import { UserFromJSON, UserToJSON, } from './User';
/**
 * Check if a given object implements the GetFinalTestFormListFiltersResponse interface.
 */
export function instanceOfGetFinalTestFormListFiltersResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "sites" in value;
    isInstance = isInstance && "equipment" in value;
    isInstance = isInstance && "makes" in value;
    isInstance = isInstance && "models" in value;
    isInstance = isInstance && "serialNumbers" in value;
    return isInstance;
}
export function GetFinalTestFormListFiltersResponseFromJSON(json) {
    return GetFinalTestFormListFiltersResponseFromJSONTyped(json, false);
}
export function GetFinalTestFormListFiltersResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'sites': (json['sites'].map(SiteFromJSON)),
        'equipment': (json['equipment'].map(EquipmentFromJSON)),
        'users': !exists(json, 'users') ? undefined : (json['users'].map(UserFromJSON)),
        'makes': json['makes'],
        'models': json['models'],
        'serialNumbers': json['serialNumbers'],
    };
}
export function GetFinalTestFormListFiltersResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'sites': (value.sites.map(SiteToJSON)),
        'equipment': (value.equipment.map(EquipmentToJSON)),
        'users': value.users === undefined ? undefined : (value.users.map(UserToJSON)),
        'makes': value.makes,
        'models': value.models,
        'serialNumbers': value.serialNumbers,
    };
}
