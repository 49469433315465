import React from "react";
import {Deficiency, Equipment} from "client";
import PrintableCellLabelWithValue from "../elements/PrintableCellLabelWithValue";
import {valueOrBustPrintables} from "../../../utils/valueOrBust";
import {asPercentOf100} from "../../../utils/asPercentOf100";
import PrintableCellRow from "../elements/PrintableCellRow";
import PrintableSpacer from "../elements/PrintableSpacer";
import {parseSiteAddressForSingleInput} from "../../../utils/parseSiteAddressForSingleInput";
import PrintableDeficienciesTable from "../elements/PrintableDeficienciesTable";

export interface IDeficiencyPrintableFormInterface {
	equipment: Equipment;
	deficiencies: Array<Deficiency>;
}

interface IProps {
	form: IDeficiencyPrintableFormInterface;
}

const CraneDeficiencyPrintable: React.FC<IProps> = (props) => {

	return (
		<React.Fragment>
			<p className="printable__header">
				Deficiency Form
			</p>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Make:"
					value={valueOrBustPrintables(props.form?.equipment?.make)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Model:"
					value={valueOrBustPrintables(props.form?.equipment?.model)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="S/N:"
					value={valueOrBustPrintables(props.form?.equipment?.serialNumber)}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<p className="printable__sub-header">
				Current Site
			</p>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Site Name:"
					value={valueOrBustPrintables(props.form?.equipment?.site.name)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Site Address:"
					value={valueOrBustPrintables(props.form?.equipment?.site?.address, parseSiteAddressForSingleInput(props.form?.equipment?.site?.address))}
					width={asPercentOf100(2 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableDeficienciesTable deficiencies={props.form?.deficiencies}/>
		</React.Fragment>
	);
};

export default CraneDeficiencyPrintable;
