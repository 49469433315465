import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	CraneMonthlyLog,
	CraneMonthlyLogSortField,
	Equipment,
	FormsApi,
	GetCraneMonthlyLogListRequest,
	GetCraneMonthlyLogListResponse,
	Site,
	Token,
	UserType
} from "client";
import AlliedContainer from "../../components/AlliedContainer";
import AlliedPageHeader from "../../components/AlliedPageHeader";
import AlliedTableHeaderCell from "../../components/tables/cells/AlliedTableHeaderCell";
import {getGenericObjectProperty} from "../../utils/getGenericObjectProperty";
import AlliedTableContainer from "../../components/tables/AlliedTableContainer";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {cleanRequestForAPISubmission} from "../../utils/cleanRequestForAPISubmission";
import {TableData} from "frame-one-table/build/contextTypes";
import {ColumnOption} from "frame-one-table/build/TableGenerator";
import CraneMonthlyLogsViewCell from "../../components/tables/cells/CraneMonthlyLogsViewCell";
import CraneMonthlyLogsFilterModal from "../../components/modals/CraneMonthlyLogsFilterModal";
import {userTypeCheck} from "../../utils/userTypeCheck";
import CraneMonthlyLogsOperatorOptionsCell from "../../components/tables/cells/CraneMonthlyLogsOperatorOptionsCell";
import CraneMonthlyLogsManagerAndSupervisorOptionsCell
	from "../../components/tables/cells/CraneMonthlyLogsManagerAndSupervisorOptionsCell";
import {parseDateForTableDisplay} from "../../utils/parseDateForTableDisplay";
import classNames from "classnames";
import {formatLastUpdatedLogDateAndFlagged} from "../../utils/formatLastUpdatedLogDateAndFlagged";
import {displayMonthForTable} from "../../utils/displayMonthForTable";
import {getFullNameForTable} from "../../utils/getFullNameForTable";
import {getFormPublishedStatus} from "../../utils/getFormPublishedStatus";
import {getFormApprovedStatus} from "../../utils/getFormApprovedStatus";
import CraneMonthlyLogsAdminsViewCell from "../../components/tables/cells/CraneMonthlyLogsAdminsViewCell";
import CraneMonthlyLogsViewOtherFieldsCell from "../../components/tables/cells/CraneMonthlyLogsViewOtherFieldsCell";
import {alliedPaginationLimitOptions} from "../../components/tables/paginator/AlliedPaginatorLimitController";
import {convertOffsetDate} from "../../utils/timeZoneConversions";
import {abstractOnSortChange, getTableHeaderSortClassName} from "../../utils/abstractOnSortChange";
import WeeklyMonthlyLogsViewHistoryCell from "../../components/tables/cells/WeeklyMonthlyLogsViewHistoryCell";
import moment from "moment";

interface IProps {
	dispatch?: any;
	fullToken: Token;
}

const defaultFilter: GetCraneMonthlyLogListRequest = {
	limit: alliedPaginationLimitOptions[0],
	offset: 0,
	month: [],
	make: [],
	model: [],
	serialNumber: [],
	site: [],
	user: [],
}

const CraneMonthlyLogsPage: React.FC<IProps> = (props) => {

	const [showFilterModal, setShowFilterModal] = useState(false);
	const [filter, setFilter] = useState<GetCraneMonthlyLogListRequest>(defaultFilter);
	const [logsResponse, setLogsResponse] = useState<GetCraneMonthlyLogListResponse>(undefined);

	useEffect(() => {
		getMonthlyLogHistory().then().catch();
	}, [JSON.stringify(filter)]);

	/**
	 * Call api to get the crane monthly logs.
	 *
	 */
	async function getMonthlyLogHistory(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FormsApi(getConfig(props.fullToken)).getCraneMonthlyLogList(cleanRequestForAPISubmission({
				...filter,
				months: filter?.month?.map(m => convertOffsetDate(m)),
			} as GetCraneMonthlyLogListRequest));
			setLogsResponse(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Toggles visibility of the filter modal.
	 *
	 */
	function toggleFilterModal(): void {
		setShowFilterModal(!showFilterModal);
	}

	/**
	 * When finished the filter modal, save the new filter (will prompt api via useEffect),
	 * and hide the filter modal.
	 *
	 * @param newFilter
	 */
	function onDoneFilterModal(newFilter: GetCraneMonthlyLogListRequest): void {
		setFilter({
			...newFilter,
			offset: 0,
		});
		setShowFilterModal(false);
	}

	/**
	 * Renderer for the View Logs column.
	 *
	 * @param value
	 * @param row
	 * @param key
	 * @param data
	 * @param column
	 * @param index
	 */
	function makeViewCell(value: any, row: CraneMonthlyLog, key: string | number, data: TableData, column: ColumnOption, index?: number): ReactNode {
		return (
			<CraneMonthlyLogsViewCell
				key={"view" + row._id}
				log={row}
			/>
		);
	}

	/**
	 * Renderer of the View All Fields column.
	 *
	 * @param log
	 */
	function makeOtherFieldsCell(log: CraneMonthlyLog): ReactNode {
		return (
			<CraneMonthlyLogsViewOtherFieldsCell
				key={"other-fields" + log._id}
				log={log}
			/>
		);
	}

	/**
	 * Renderer of the Operator's version of the Options column.
	 *
	 * @param log
	 */
	function makeOperatorOptionsCell(log: CraneMonthlyLog): ReactNode {
		return (
			<CraneMonthlyLogsOperatorOptionsCell
				key={"options" + log._id}
				log={log}
				onDone={getMonthlyLogHistory}
			/>
		);
	}

	/**
	 * Renderer of the Manager & Supervisor versions of the Options column.
	 *
	 * @param log
	 */
	function makeManagerAndSupervisorOptionsCell(log: CraneMonthlyLog): ReactNode {
		return (
			<CraneMonthlyLogsManagerAndSupervisorOptionsCell
				key={"manager-superviser-options" + log._id}
				log={log}
				onDone={getMonthlyLogHistory}
			/>
		);
	}

	/**
	 * Renderer of the Admin's version of the Options column.
	 *
	 * @param log
	 */
	function makeAdminViewCell(log: CraneMonthlyLog): ReactNode {
		return (
			<CraneMonthlyLogsAdminsViewCell
				key={"admins-view" + log._id}
				log={log}
			/>
		);
	}

	/**
	 * Renderer for the view history button column.
	 *
	 * @param log
	 */
	function makeViewHistoryCell(log: CraneMonthlyLog): ReactNode {
		return (
			<WeeklyMonthlyLogsViewHistoryCell
				key={"history" + log._id}
				history={log.history}
			/>
		);
	}

	function makeDateListString(months: Array<number | string>): string {
		let str = "";
		months.forEach((w, i) => {
			if (i > 0) {
				str += ", ";
			}

			str += moment(parseInt(w.toString())).format("MMM YYYY");
		});

		return str;
	}

	return (
		<React.Fragment>
			<CraneMonthlyLogsFilterModal
				isOpen={showFilterModal}
				filter={filter}
				onClose={toggleFilterModal}
				onDone={onDoneFilterModal}
			/>

			<AlliedContainer>
				<AlliedPageHeader
					title="Crane Monthly Log History"
					onClick={toggleFilterModal}
				/>
				{filter.month && filter.month.length > 0 && (
					<p>
						{`Showing results from the month(s) of ${makeDateListString(filter.month)}.`}
					</p>
				)}

				<hr/>

				<AlliedTableContainer
					<GetCraneMonthlyLogListRequest>
					data={logsResponse?.craneMonthlyLogs}
					showPaginator={true}
					paginationInfo={logsResponse?.paginationInfo}
					filter={filter}
					setFilter={setFilter}
					columnOptions={[
						{
							headerValue: "Date Submitted",
							key: "submittedDate",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							rowCellClassName: "font-weight-bold",
							valueFormatter: (date: number) => {
								if (typeof date === "number") {
									return parseDateForTableDisplay(date);
								}

								return "-";
							},
							headerCellClassName: getTableHeaderSortClassName(CraneMonthlyLogSortField.submittedDate, filter),
							onSort: abstractOnSortChange<CraneMonthlyLogSortField, GetCraneMonthlyLogListRequest>(CraneMonthlyLogSortField.submittedDate, filter, setFilter),
						},
						{
							headerValue: "Last Updated",
							key: "",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							rowCellClassName: (value, cellContextDetails) => classNames("font-weight-bold", formatLastUpdatedLogDateAndFlagged(value, cellContextDetails.row as CraneMonthlyLog).className),
							valueFormatter: (value, cellContextDetails) => formatLastUpdatedLogDateAndFlagged(value, cellContextDetails.row as CraneMonthlyLog).value,
							headerCellClassName: getTableHeaderSortClassName(CraneMonthlyLogSortField.updatedAt, filter),
							onSort: abstractOnSortChange<CraneMonthlyLogSortField, GetCraneMonthlyLogListRequest>(CraneMonthlyLogSortField.updatedAt, filter, setFilter),
						},
						{
							headerValue: "Month",
							key: "date",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: displayMonthForTable,
						},
						{
							headerValue: "Make",
							key: "equipment",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getGenericObjectProperty<Equipment>("make"),
						},
						{
							headerValue: "Model",
							key: "equipment",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getGenericObjectProperty<Equipment>("model"),
						},
						{
							headerValue: "S/N",
							key: "equipment",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getGenericObjectProperty<Equipment>("serialNumber"),
						},
						{
							headerValue: "Site Name",
							key: "originalSite",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getGenericObjectProperty<Site>("name"),
						},
						// (userTypeCheck([UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR, UserType.HEALTH_AND_SAFETY_OFFICER], props.fullToken) &&
							{
								headerValue: "Operator",
								key: "owner",
								showSortIcons: false,
								sortable: true,
								headerRender: AlliedTableHeaderCell,
								valueFormatter: getFullNameForTable,
							},
						// ),
						// {
						// 	headerValue: "Certification Number",
						// 	key: "certificateNumber",
						// 	showSortIcons: false,
						// 	sortable: true,
						// 	headerRender: AlliedTableHeaderCell,
						// 	valueFormatter: valueOrBust,
						// },
						(userTypeCheck([UserType.ADMIN], props.fullToken) &&
							{
								headerValue: "Published",
								key: "publishedDate",
								showSortIcons: false,
								sortable: true,
								headerRender: AlliedTableHeaderCell,
								valueFormatter: getFormPublishedStatus,
								headerCellClassName: getTableHeaderSortClassName(CraneMonthlyLogSortField.publishedDate, filter),
								onSort: abstractOnSortChange<CraneMonthlyLogSortField, GetCraneMonthlyLogListRequest>(CraneMonthlyLogSortField.publishedDate, filter, setFilter),
							}
						),
						(userTypeCheck([UserType.SECONDARY_ADMIN, UserType.SUPERVISOR, UserType.CRANE_OPERATOR], props.fullToken) &&
							{
								headerValue: "Approved",
								key: "approvedBy",
								showSortIcons: false,
								sortable: true,
								headerRender: AlliedTableHeaderCell,
								valueFormatter: getFormApprovedStatus,
							}
						),
						{
							headerValue: "View All Fields",
							key: undefined,
							showSortIcons: false,
							sortable: false,
							headerRender: AlliedTableHeaderCell,
							cellRender: makeOtherFieldsCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
						(userTypeCheck([UserType.HEALTH_AND_SAFETY_OFFICER, UserType.ALLIED_CRANE_EMPLOYEE, UserType.CRANE_OPERATOR], props.fullToken) &&
							{
								headerValue: "Monthly Log Options",
								key: undefined,
								showSortIcons: false,
								sortable: false,
								headerRender: AlliedTableHeaderCell,
								cellRender: makeOperatorOptionsCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							}
						),
						(userTypeCheck([UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR], props.fullToken) &&
							{
								headerValue: "Monthly Log Options",
								key: undefined,
								showSortIcons: false,
								sortable: false,
								headerRender: AlliedTableHeaderCell,
								cellRender: makeManagerAndSupervisorOptionsCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							}
						),
						(userTypeCheck([UserType.HEALTH_AND_SAFETY_OFFICER], props.fullToken) &&
							{
								headerValue: "View Log",
								key: undefined,
								showSortIcons: false,
								sortable: false,
								headerRender: AlliedTableHeaderCell,
								cellRender: makeAdminViewCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							}
						),
						{
							headerValue: "View Edit History",
							key: undefined,
							showSortIcons: false,
							sortable: false,
							headerRender: AlliedTableHeaderCell,
							cellRender: makeViewHistoryCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
					]}
				/>
			</AlliedContainer>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(CraneMonthlyLogsPage);
