import {IAutoCompleteOption} from "../components/inputs/AlliedAutoComplete";
import {Equipment} from "client";
import {getEquipmentTypeDisplay} from "./getEquipmentTypeDisplay";

export function parseEquipmentForAutoComplete(equipmentList: Array<Equipment> = []): Array<IAutoCompleteOption> {
	return equipmentList.map((_equipment: Equipment): IAutoCompleteOption => {
		return {
			value: _equipment._id,
			label: _equipment.make + ", " + _equipment.model + ", " + _equipment.serialNumber,
		}
	});
}

export function parseEquipmentForDeficienciesTitle(equipment: Equipment): string {
	return equipment.make + " " + equipment.model + " " + getEquipmentTypeDisplay(equipment.type) + " " + equipment.serialNumber;
}
