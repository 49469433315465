/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { FormTypeFromJSON, FormTypeToJSON, } from './FormType';
import { UserFromJSON, UserToJSON, } from './User';
import { CraneDailyLogGroupFromJSONTyped, CraneMonthlyLogFromJSONTyped, CraneWeeklyLogFromJSONTyped, DeficiencyFromJSONTyped, FinalTestFormFromJSONTyped, GeneratorDailyLogGroupFromJSONTyped, ServiceReportFromJSONTyped, TimeSheetFromJSONTyped } from './';
/**
 * Check if a given object implements the Form interface.
 */
export function instanceOfForm(value) {
    let isInstance = true;
    isInstance = isInstance && "_id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "owner" in value;
    return isInstance;
}
export function FormFromJSON(json) {
    return FormFromJSONTyped(json, false);
}
export function FormFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['type'] === 'CRANE_DAILY_LOG_GROUP') {
            return CraneDailyLogGroupFromJSONTyped(json, true);
        }
        if (json['type'] === 'CRANE_MONTHLY_LOG') {
            return CraneMonthlyLogFromJSONTyped(json, true);
        }
        if (json['type'] === 'CRANE_WEEKLY_LOG') {
            return CraneWeeklyLogFromJSONTyped(json, true);
        }
        if (json['type'] === 'DEFICIENCY') {
            return DeficiencyFromJSONTyped(json, true);
        }
        if (json['type'] === 'FINAL_TEST_FORM') {
            return FinalTestFormFromJSONTyped(json, true);
        }
        if (json['type'] === 'GENERATOR_DAILY_LOG_GROUP') {
            return GeneratorDailyLogGroupFromJSONTyped(json, true);
        }
        if (json['type'] === 'SERVICE_REPORT') {
            return ServiceReportFromJSONTyped(json, true);
        }
        if (json['type'] === 'TIME_SHEET') {
            return TimeSheetFromJSONTyped(json, true);
        }
    }
    return {
        '_id': json['_id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'type': FormTypeFromJSON(json['type']),
        'owner': UserFromJSON(json['owner']),
        'publishedDate': !exists(json, 'publishedDate') ? undefined : json['publishedDate'],
        'submittedDate': !exists(json, 'submittedDate') ? undefined : json['submittedDate'],
    };
}
export function FormToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'type': FormTypeToJSON(value.type),
        'owner': UserToJSON(value.owner),
        'publishedDate': value.publishedDate,
        'submittedDate': value.submittedDate,
    };
}
