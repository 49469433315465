import React, {useEffect, useRef, useState} from "react";
import ReactToPrint from "react-to-print";
import {Button} from "reactstrap";
import {ButtonProps} from "reactstrap/lib/Button";
import PrintableContainer from "../PrintableContainer";
import classNames from "classnames";
import moment from "moment";

interface IProps<T> extends ButtonProps {
	onClick: () => Promise<T>;
	component: React.FC<{form: T}>;
	showPrintable?: boolean; // Set to true when on a test page to see your JSX render in the browser as opposed to needing to open the print prompt. Leave false for real implementations on the user-facing pages.
	className?: string;
	documentTitle: string;
}

function AbstractPrintableButtonManager<T>(props: IProps<T>): JSX.Element {

	const reactToPrintRef = useRef<ReactToPrint>();
	const printableRef = useRef<HTMLDivElement>();

	const [savedForm, setSavedForm] = useState<T>()

	/**
	 * Once the form is saved, the PrintableContainer
	 * should render, and then we prompt the print window.
	 *
	 */
	useEffect(() => {
		if (savedForm) {
			reactToPrintRef.current.handlePrint();
		}
	}, [savedForm]);

	/**
	 * Return the ref of the printable container.
	 *
	 */
	function getPrintableRef(): React.ReactInstance {
		return printableRef.current;
	}

	/**
	 * When clicking the button, await the function
	 * from props to perform the api call to get the form,
	 * then save the returned form.
	 *
	 */
	async function onButtonClickHelper(): Promise<void> {
		const form: T = await props.onClick();
		setSavedForm(form);
	}

	return (
		<React.Fragment>
			<Button
				{...props}
				onClick={onButtonClickHelper}
			>
				{props.children}
			</Button>

			<ReactToPrint
				ref={reactToPrintRef}
				content={getPrintableRef}
				documentTitle={`${props.documentTitle}_${moment().format("MM-DD-YYYY_HH-mm")}`}
			/>

			<div className={classNames({"d-none": !props.showPrintable})}>
				<div ref={printableRef}>
					<PrintableContainer
						<T>
						form={savedForm}
						component={props.component}
					/>
				</div>
			</div>
		</React.Fragment>
	);
}


export default AbstractPrintableButtonManager;
