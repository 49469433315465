import {CreateCraneWeeklyLogBody} from "client";
import {IAlliedSelectInputListItem} from "../components/inputs/inputList/AlliedSelectInputList";
import {getCraneWeeklyLogInspectionItemDisplayName} from "./getCraneWeeklyLogInspectionItemDisplayName";
import {getInspectionItemOptions} from "./convertCraneDailyInspectionItemToInputListItem";

export function convertCraneWeeklyInspectionItemToInputListItem(key: keyof CreateCraneWeeklyLogBody, createCraneWeeklyLogForm: CreateCraneWeeklyLogBody): IAlliedSelectInputListItem {
	return {
		label: getCraneWeeklyLogInspectionItemDisplayName(key),
		interfaceKey: key,
		value: createCraneWeeklyLogForm[key] as string,
		options: getInspectionItemOptions(key),
	}
}

/**
 * Convert the array of keys on the daily log to usable drop-down options
 *
 * @param keys
 * @param createCraneWeeklyLogForm
 */
export function convertCraneWeeklyInspectionItemsToInputListItems(keys: Array<keyof CreateCraneWeeklyLogBody>, createCraneWeeklyLogForm: CreateCraneWeeklyLogBody): Array<IAlliedSelectInputListItem> {
	return keys.map((_key: keyof CreateCraneWeeklyLogBody) => {
		return convertCraneWeeklyInspectionItemToInputListItem(_key, createCraneWeeklyLogForm);
	})
}
