import {CreateCraneMonthlyLogBody} from "client";
import {IAlliedSelectInputListItem} from "../components/inputs/inputList/AlliedSelectInputList";
import {getCraneMonthlyLogInspectionItemDisplayName} from "./getCraneMonthlyLogInspectionItemDisplayName";
import {getInspectionItemOptions} from "./convertCraneDailyInspectionItemToInputListItem";

export function convertCraneMonthlyInspectionItemToInputListItem(key: keyof CreateCraneMonthlyLogBody, createCraneMonthlyLogForm: CreateCraneMonthlyLogBody): IAlliedSelectInputListItem {
	return {
		label: getCraneMonthlyLogInspectionItemDisplayName(key),
		interfaceKey: key,
		value: createCraneMonthlyLogForm[key] as string,
		options: getInspectionItemOptions(key),
	}
}

/**
 * Convert the array of keys on the daily log to usable drop-down options
 *
 * @param keys
 * @param createCraneMonthlyLogForm
 */
export function convertCraneMonthlyInspectionItemsToInputListItems(keys: Array<keyof CreateCraneMonthlyLogBody>, createCraneMonthlyLogForm: CreateCraneMonthlyLogBody): Array<IAlliedSelectInputListItem> {
	return keys.map((_key: keyof CreateCraneMonthlyLogBody) => {
		return convertCraneMonthlyInspectionItemToInputListItem(_key, createCraneMonthlyLogForm);
	})
}
