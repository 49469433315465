/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { AssetFromJSON, AssetBase64ResponseFromJSON, StorageDetailsFromJSON, } from '../models';
/**
 *
 */
export class AssetsApi extends runtime.BaseAPI {
    /**
     * Uploads an asset.
     */
    async createAssetRaw(requestParameters, initOverrides) {
        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset', 'Required parameter requestParameters.asset was null or undefined when calling createAsset.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.asset !== undefined) {
            formParams.append('asset', requestParameters.asset);
        }
        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name);
        }
        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description);
        }
        if (requestParameters.urlExtension !== undefined) {
            formParams.append('urlExtension', requestParameters.urlExtension);
        }
        const response = await this.request({
            path: `/assets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AssetFromJSON(jsonValue));
    }
    /**
     * Uploads an asset.
     */
    async createAsset(requestParameters, initOverrides) {
        const response = await this.createAssetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Gets all assets from the asset manager this user as access to.
     */
    async getAssetsRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/assets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AssetFromJSON));
    }
    /**
     * Gets all assets from the asset manager this user as access to.
     */
    async getAssets(initOverrides) {
        const response = await this.getAssetsRaw(initOverrides);
        return await response.value();
    }
    /**
     * Used to tell how much storage space the user has used, has left, and what the total amount allowed is.
     */
    async getStorageDetailsRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/assets/storage-details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => StorageDetailsFromJSON(jsonValue));
    }
    /**
     * Used to tell how much storage space the user has used, has left, and what the total amount allowed is.
     */
    async getStorageDetails(initOverrides) {
        const response = await this.getStorageDetailsRaw(initOverrides);
        return await response.value();
    }
    /**
     * Updates and existing asset
     */
    async updateAssetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.assetID !== undefined) {
            formParams.append('assetID', requestParameters.assetID);
        }
        if (requestParameters.asset !== undefined) {
            formParams.append('asset', requestParameters.asset);
        }
        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name);
        }
        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description);
        }
        const response = await this.request({
            path: `/assets`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AssetFromJSON(jsonValue));
    }
    /**
     * Updates and existing asset
     */
    async updateAsset(requestParameters = {}, initOverrides) {
        const response = await this.updateAssetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * pipes the asset from Google Storage.
     */
    async viewAssetRaw(requestParameters, initOverrides) {
        if (requestParameters.urlExtension === null || requestParameters.urlExtension === undefined) {
            throw new runtime.RequiredError('urlExtension', 'Required parameter requestParameters.urlExtension was null or undefined when calling viewAsset.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/assets/{urlExtension}`.replace(`{${"urlExtension"}}`, encodeURIComponent(String(requestParameters.urlExtension))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.BlobApiResponse(response);
    }
    /**
     * pipes the asset from Google Storage.
     */
    async viewAsset(requestParameters, initOverrides) {
        const response = await this.viewAssetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Gets the base64 of a particular asset.
     */
    async viewAssetBase64Raw(requestParameters, initOverrides) {
        if (requestParameters.urlExtension === null || requestParameters.urlExtension === undefined) {
            throw new runtime.RequiredError('urlExtension', 'Required parameter requestParameters.urlExtension was null or undefined when calling viewAssetBase64.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/assets/base64/{urlExtension}`.replace(`{${"urlExtension"}}`, encodeURIComponent(String(requestParameters.urlExtension))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AssetBase64ResponseFromJSON(jsonValue));
    }
    /**
     * Gets the base64 of a particular asset.
     */
    async viewAssetBase64(requestParameters, initOverrides) {
        const response = await this.viewAssetBase64Raw(requestParameters, initOverrides);
        return await response.value();
    }
}
