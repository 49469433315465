/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const FinalTestFormSortField = {
    publishedDate: 'publishedDate',
    submittedDate: 'submittedDate',
    date: 'date',
    baseType: 'baseType',
    travelGear: 'travelGear',
    towerHeight: 'towerHeight',
    heightUnderHook: 'heightUnderHook',
    jibLength: 'jibLength',
    requiredPowerSupplyVolts: 'requiredPowerSupplyVolts',
    phase: 'phase',
    amps: 'amps',
    measuredPowerSupplyUnderLoad: 'measuredPowerSupplyUnderLoad',
    noLoad: 'noLoad',
    trollyInLimitSlow: 'trollyInLimitSlow',
    trollyInLimitFinal: 'trollyInLimitFinal',
    trollyOutLimitSlow: 'trollyOutLimitSlow',
    trollyOutLimitFinal: 'trollyOutLimitFinal',
    hoistUpSlow: 'hoistUpSlow',
    hoistUpFinal: 'hoistUpFinal',
    hoistDownSlow: 'hoistDownSlow',
    hoistDownFinal: 'hoistDownFinal',
    travelInLimitSlow: 'travelInLimitSlow',
    travelInLimitFinal: 'travelInLimitFinal',
    travelOutLimitSlow: 'travelOutLimitSlow',
    travelOutLimitFinal: 'travelOutLimitFinal',
    slowingLimitInstalled: 'slowingLimitInstalled',
    verifyTheCraneCannotMakeMoreThan2TurnsToEachSide: 'verifyTheCraneCannotMakeMoreThan2TurnsToEachSide',
    requiredWeightAsPerManualForMomentLimit: 'requiredWeightAsPerManualForMomentLimit',
    percentMaxWeight: 'percentMaxWeight',
    primaryWeightUsed: 'primaryWeightUsed',
    deadManControlOperational: 'deadManControlOperational',
    constantPressureControls: 'constantPressureControls',
    springLoadedControls: 'springLoadedControls',
    controlFunctionsTested: 'controlFunctionsTested',
    eStopFunctioning: 'eStopFunctioning',
    operatorInformationDisplayInCab: 'operatorInformationDisplayInCab',
    displayCalibrated: 'displayCalibrated',
    zoningSystemInstalled: 'zoningSystemInstalled',
    craneOperatorOriented: 'craneOperatorOriented',
    minimumFreeSlewRadius: 'minimumFreeSlewRadius',
    name: 'name',
    licenseNumber: 'licenseNumber',
    comments: 'comments',
    createdAt: 'createdAt',
    updatedAt: 'updatedAt'
};
export function FinalTestFormSortFieldFromJSON(json) {
    return FinalTestFormSortFieldFromJSONTyped(json, false);
}
export function FinalTestFormSortFieldFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function FinalTestFormSortFieldToJSON(value) {
    return value;
}
