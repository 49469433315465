import React, {useEffect, useState} from "react";
import {
	Equipment,
	EquipmentApi,
	EquipmentType,
	GetSiteListFiltersResponse,
	SitesApi,
	Token,
	User,
	UsersApi,
	UserType
} from "client";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button, Col, Row} from "reactstrap";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import AlliedAutoComplete from "../inputs/AlliedAutoComplete";
import AutoCompleteListDisplay from "../inputs/AutoCompleteListDisplay";
import {parseStringArrayForAutoComplete} from "../../utils/parseStringArrayForAutoComplete";
import {parseUsersForAutoComplete} from "../../utils/parseUsersForAutoComplete";
import {GetSiteListRequestFrontend} from "../../pages/sitesOptions/ManageSitesPages";
import {parseEquipmentForAutoComplete} from "../../utils/parseEquipmentForAutoComplete";
import {userTypeCheck} from "../../utils/userTypeCheck";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	filter: GetSiteListRequestFrontend;
	onClose: () => void;
	onDone: (newFilter: GetSiteListRequestFrontend) => void;
}

const ManageSitesFilterModal: React.FC<IProps> = (props) => {

	const [filter, setFilter] = useState<GetSiteListRequestFrontend>(props.filter);

	// Api responses for the auto-complete inputs, these only get set once.
	const [apiFilters, setApiFilters] = useState<GetSiteListFiltersResponse>(undefined);
	const [apiManagers, setApiManagers] = useState<Array<User>>(undefined);
	const [apiSupervisors, setApiSupervisors] = useState<Array<User>>(undefined);
	const [apiEmployees, setApiEmployees] = useState<Array<User>>(undefined);
	const [apiOperators, setApiOperators] = useState<Array<User>>(undefined);
	const [apiHealthOfficers, setApiHealthOfficers] = useState<Array<User>>(undefined);
	const [apiEquipment, setApiEquipment] = useState<Array<Equipment>>(undefined);

	/**
	 * When modal opens, update the local filter to match that of props,
	 * and call api to get values for the auto-complete inputs.
	 *
	 */
	useEffect(() => {
		if (props.isOpen) {
			setFilter(props.filter);
			getSitesFilters().then().catch();
		}
	}, [props.isOpen]);

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function resetAndClose(): void {
		setFilter(props.filter);
		props.onClose();
	}

	/**
	 * Call api to get the filterable options from the backend.
	 *
	 */
	async function getSitesFilters(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			// Site Filters
			const siteListFiltersRes = await new SitesApi(getConfig(props.fullToken)).getSiteListFilters();
			setApiFilters(siteListFiltersRes);
			// Users
			const userApiBase = new UsersApi(getConfig(props.fullToken));

			if (userTypeCheck([UserType.ADMIN], props.fullToken)) {
				const managers = await userApiBase.getUserList({
					limit: 10000,
					offset: 0,
					type: [UserType.SECONDARY_ADMIN],
				});
				setApiManagers(managers.users);
			}

			const supervisors = await userApiBase.getUserList({
				limit: 10000,
				offset: 0,
				type: [UserType.SUPERVISOR],
			});
			setApiSupervisors(supervisors.users);

			const operators = await userApiBase.getUserList({
				limit: 10000,
				offset: 0,
				type: [UserType.CRANE_OPERATOR],
			});
			setApiOperators(operators.users);

			if (userTypeCheck([UserType.ADMIN], props.fullToken)) {
				const employees = await userApiBase.getUserList({
					limit: 10000,
					offset: 0,
					type: [UserType.ALLIED_CRANE_EMPLOYEE],
				});
				setApiEmployees(employees.users);
			}

			if (userTypeCheck([UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR], props.fullToken)) {
				const healthOfficers = await userApiBase.getUserList({
					limit: 10000,
					offset: 0,
					type: [UserType.HEALTH_AND_SAFETY_OFFICER],
				});
				setApiHealthOfficers(healthOfficers.users);
			}

			// Equipment
			const equipmentRes = await new EquipmentApi(getConfig(props.fullToken)).getEquipmentList({
				limit: 10000,
				offset: 0,
				type: [EquipmentType.CRANE, EquipmentType.GENERATOR, EquipmentType.MATERIAL_HOIST],
			});
			setApiEquipment(equipmentRes.equipment)
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Dynamic onChange helper to update the string arrays in the filter object.
	 *
	 * @param key
	 */
	function filterArrayOnChange(key: keyof GetSiteListRequestFrontend): (value: string[]) => void {
		return (value: string[]) => {
			setFilter({
				...filter,
				[key]: value,
			});
		}
	}

	/**
	 * When done configuring filter, call onDone function from props.
	 *
	 */
	function onSave(): void {
		props.onDone(filter);
	}

	return (
		<AlliedModal
			size="lg"
			isOpen={props.isOpen}
		>
			<AlliedModalHeader
				title="Filter Sites"
				onClose={resetAndClose}
			/>

			<hr/>

			<Row>
				<Col xs={12} md={6} className="mb-3">
					<label>Specify Site Names...</label>
					<AlliedAutoComplete
						placeholder="Select Site Names..."
						options={parseStringArrayForAutoComplete(apiFilters?.names)}
						selections={filter?.name}
						setSelections={filterArrayOnChange("name")}
					/>
				</Col>
			</Row>

			<Row>
				{userTypeCheck([UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR], props.fullToken) && (
					<React.Fragment>
						<Col xs={12} md={6} className="mb-3">
							<label>Specify Site Contacts...</label>
							<AlliedAutoComplete
								placeholder="Select Site Contacts..."
								options={parseStringArrayForAutoComplete(apiFilters?.owners)}
								selections={filter?.owner}
								setSelections={filterArrayOnChange("owner")}
							/>
						</Col>

						<Col xs={12} md={6} className="mb-3">
							<label>Specify Site Renters...</label>
							<AlliedAutoComplete
								placeholder="Select Site Renters..."
								options={parseStringArrayForAutoComplete(apiFilters?.renters)}
								selections={filter?.renter}
								setSelections={filterArrayOnChange("renter")}
							/>
						</Col>
					</React.Fragment>
				)}

				{userTypeCheck([UserType.ADMIN], props.fullToken) && (
						<Col xs={12} md={6} className="mb-3">
							<label>Specify Managers...</label>
							<AlliedAutoComplete
								placeholder="Select Managers..."
								options={parseUsersForAutoComplete(apiManagers)}
								selections={filter?.managers}
								setSelections={filterArrayOnChange("managers")}
							/>
						</Col>
				)}

				<Col xs={12} md={6} className="mb-3">
					<label>Specify Supervisors...</label>
					<AlliedAutoComplete
						placeholder="Select Supervisors..."
						options={parseUsersForAutoComplete(apiSupervisors)}
						selections={filter?.supervisors}
						setSelections={filterArrayOnChange("supervisors")}
					/>
				</Col>

				<Col xs={12} md={6} className="mb-3">
					<label>Specify Operators...</label>
					<AlliedAutoComplete
						placeholder="Select Operators..."
						options={parseUsersForAutoComplete(apiOperators)}
						selections={filter?.operators}
						setSelections={filterArrayOnChange("operators")}
					/>
				</Col>

				{userTypeCheck([UserType.ADMIN], props.fullToken) && (
					<Col xs={12} md={6} className="mb-3">
						<label>Specify AC Employee...</label>
						<AlliedAutoComplete
							placeholder="Select AC Employee..."
							options={parseUsersForAutoComplete(apiEmployees)}
							selections={filter?.alliedCraneEmployees}
							setSelections={filterArrayOnChange("alliedCraneEmployees")}
						/>
					</Col>
				)}

				{userTypeCheck([UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR], props.fullToken) && (
					<Col xs={12} md={6} className="mb-3">
						<label>Specify Health & Safety Workers...</label>
						<AlliedAutoComplete
							placeholder="Select Health & Safety Workers..."
							options={parseUsersForAutoComplete(apiHealthOfficers)}
							selections={filter?.safetyOfficers}
							setSelections={filterArrayOnChange("safetyOfficers")}
						/>
					</Col>
				)}
			</Row>

			<Row>
				<Col xs={12} md={6} className="mb-3">
					<label>Specify Makes...</label>
					<AlliedAutoComplete
						placeholder="Select Makes..."
						options={parseStringArrayForAutoComplete(apiFilters?.makes)}
						selections={filter?.make}
						setSelections={filterArrayOnChange("make")}
					/>
				</Col>

				<Col xs={12} md={6} className="mb-3">
					<label>Specify Models...</label>
					<AlliedAutoComplete
						placeholder="Select Models..."
						options={parseStringArrayForAutoComplete(apiFilters?.models)}
						selections={filter?.model}
						setSelections={filterArrayOnChange("model")}
					/>
				</Col>

				<Col xs={12} md={6} className="mb-3">
					<label>Specify S/N...</label>
					<AlliedAutoComplete
						placeholder="Select S/N..."
						options={parseStringArrayForAutoComplete(apiFilters?.serialNumbers)}
						selections={filter?.serialNumber}
						setSelections={filterArrayOnChange("serialNumber")}
					/>
				</Col>

				<Col xs={12} md={6} className="mb-3">
					<label>Specify Equipment...</label>
					<AlliedAutoComplete
						placeholder="Select Equipment..."
						options={parseEquipmentForAutoComplete(apiEquipment)}
						selections={filter?.equipment}
						setSelections={filterArrayOnChange("equipment")}
					/>
				</Col>
			</Row>

			<div>
				<Button
					color="black"
					onClick={onSave}
				>
					Apply Filters
				</Button>
			</div>
		</AlliedModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageSitesFilterModal);
