import React from "react";
import {ColumnOption, IPaginatorProps, TableGenerator} from "frame-one-table";
import {TableGeneratorProps} from "frame-one-table/build/TableGenerator";
import AlliedPaginatorOffsetController from "./paginator/AlliedPaginatorOffsetController";
import {PaginationInfo} from "client";
import AlliedPaginatorLimitController from "./paginator/AlliedPaginatorLimitController";
import AlliedTableHeaderCell from "./cells/AlliedTableHeaderCell";
import classNames from "classnames";

interface IProps<T> extends Partial<TableGeneratorProps> {
	paginationInfo?: PaginationInfo;
	showPaginator?: boolean;
	filter?: T;
	setFilter?: (filter: T) => void;
}

function AlliedTableContainer<T>(props: IProps<T>): JSX.Element {

	function onOffsetChange(newOffset: number): void {
		props.setFilter({
			...props.filter,
			offset: newOffset,
		});
	}

	function onLimitChange(newLimit: number): void {
		props.setFilter({
			...props.filter,
			offset: 0,
			limit: newLimit,
		});
	}

	return (
		<div>
			<div className="allied-table-container">
				<TableGenerator
					paginatorProps={{show: false} as IPaginatorProps}
					showHeader={true}
					showBody={true}
					{...props}
					columnOptions={props.columnOptions.map((col): ColumnOption => {
						return {
							headerRender: AlliedTableHeaderCell,
							showSortIcons: false,
							sortable: false,
							...col,
							headerCellClassName: classNames(col.headerCellClassName, {
								"cursor-pointer": col.onSort !== undefined,
							}),
						}
					})}
				/>
			</div>

			{props.showPaginator && props.paginationInfo && Object.keys(props.paginationInfo).length > 0 && (
				<div className="allied-paginator-container">
					<AlliedPaginatorLimitController
						paginationInfo={props.paginationInfo}
						onLimitChange={onLimitChange}
					/>

					<AlliedPaginatorOffsetController
						paginationInfo={props.paginationInfo}
						onOffsetChange={onOffsetChange}
					/>
				</div>
			)}
		</div>
	);
}

export default AlliedTableContainer;
