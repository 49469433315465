import React, {CSSProperties} from "react";

interface IProps {
	title: string;
	titleStyle?: CSSProperties;
	showTooltip?: boolean;
	onClick?: () => void;
}

const AlliedPageHeader: React.FC<IProps> = (props) => {

	return (
		<React.Fragment>
			<div className="allied-page-header">
				<h1
					className="pr-3"
					style={props.titleStyle}
				>
					{props.title}
				</h1>

				{props.onClick && (
					<div
						className="allied-page-header_filter-container"
						onClick={props.onClick}
					>
						Filter
						<img
							src={process.env.PUBLIC_URL + "/icons/filter.svg"}
							alt="filter"
							className="allied-page-header_filter-container_icon"
						/>
					</div>
				)}
			</div>

			{props.showTooltip && (
				<p>
					Not seeing what you expect? Click on the "filter" button to change the displayed time range and/or other filtering options.
				</p>
			)}
		</React.Fragment>
	);
};

AlliedPageHeader.defaultProps = {
	showTooltip: true,
};

export default AlliedPageHeader;
