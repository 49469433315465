import React from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import AlliedModal from "./AlliedModal";
import {Site, SitesApi, Token} from "client";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	site: Site,
	onClose: () => void;
	onDone: () => void;
}

const ManageSitesDisableModal: React.FC<IProps> = (props) => {

	/**
	 * Call api to toggle the site's "disabled" status.
	 *
	 */
	async function deleteSite(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new SitesApi(getConfig(props.fullToken)).disableSite({
				disableSiteBody: {
					siteID: props.site?._id,
				},
			});

			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<AlliedModal
			isOpen={props.isOpen}
			size="md"
		>
			<AlliedModalHeader
				title="Delete Site"
				onClose={props.onClose}
			/>

			<hr/>

			<p>
				Are you sure you want to delete {props.site?.name}?
			</p>

			<div>
				<Button
					color="red"
					onClick={deleteSite}
				>
					Delete Site
				</Button>
			</div>
		</AlliedModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageSitesDisableModal);
