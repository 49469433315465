import React, {ReactNode} from "react";
import {
	CraneDailyLog, CraneMonthlyLog, CreateCraneMonthlyLogBody,
} from "client";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {parseInspectionStatus} from "../../utils/parseInspectionStatus";
import {getCraneMonthlyLogInspectionItems} from "../../utils/getCraneMonthlyLogInspectionItems";
import {getCraneMonthlyLogInspectionItemDisplayName} from "../../utils/getCraneMonthlyLogInspectionItemDisplayName";
import {InspectionOptionLookupInterfaces} from "../../utils/logInspectionOptionsFinder";

interface IProps {
	isOpen: boolean
	log: CraneMonthlyLog;
	onClose: () => void;
}

const CraneMonthlyLogsViewOtherFieldsModal: React.FC<IProps> = (props) => {

	/**
	 * Filter out any fields of the log that aren't inspection items,
	 * and then parse & display the remaining inspection item.
	 *
	 * @param log
	 */
	function makeFieldEntries(log: CraneMonthlyLog): ReactNode {
		const fields: Array<keyof CreateCraneMonthlyLogBody> = getCraneMonthlyLogInspectionItems();

		return Object.keys(props.log).filter((p: keyof CreateCraneMonthlyLogBody) => {
			return fields.indexOf(p) > -1
		}).map((entry: string) => {
			return (
				<div>
					<label>{getCraneMonthlyLogInspectionItemDisplayName(entry as keyof CreateCraneMonthlyLogBody)}</label>
					<p>
						{parseInspectionStatus(entry as keyof InspectionOptionLookupInterfaces, log[entry])}
					</p>
				</div>
			);
		});
	}

	return (
		<AlliedModal
			isOpen={props.isOpen}
			size="md"
		>
			<AlliedModalHeader
				title="Log Inspection"
				onClose={props.onClose}
			/>

			<hr/>

			{makeFieldEntries(props.log)}
		</AlliedModal>
	);
};

export default CraneMonthlyLogsViewOtherFieldsModal;
