import React, {ReactNode, useEffect, useRef, useState} from "react";
import classNames from "classnames";
import {useHistory, withRouter} from "react-router-dom";
import {FiChevronDown} from "react-icons/all";
import {INavLinkEntry, INavLinkGroup} from "../../utils/getUserTypeNavLinks";
import {toggleSideBar} from "../../redux/meta/MetaActions";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import ViewEquipmentLocationListButton from "../printables/buttons/ViewEquipmentLocationListButton";

interface IProps extends INavLinkGroup {
	dispatch?: any;
	sidebarVisible?: boolean;
	onNestedHeightChange?(heightChange: number): void;
}

const _SideBarNavigationLinkGroup: React.FC<IProps> = (props) => {

	const history = useHistory();
	const [open, setOpen] = useState(true); // Controls if the nav group is "open" or not. Toggled when the group's title is clicked.
	const [listHeight, setListHeight] = useState(undefined); // Controlled height of the nav group.
	const [initialHeight, setInitialHeight] = useState(undefined); // Initial height used to toggle between 0 & the real height as a reference.
	const listRef = useRef<any>(undefined); // ref of the list div used to find the initial height.

	/**
	 * Listen for the first time listRef gets set & save the height of the list.
	 *
	 */
	useEffect(() => {
		if (listRef && !initialHeight) {
			const height: number = listRef?.current?.getBoundingClientRect()?.height;
			setInitialHeight(height);
		}
	}, [listRef]);

	/**
	 * List for the initial height being set, which should only happen once, and
	 * then set the controlled value for the list height to the initial height.
	 *
	 * Doing it in this chain of useEffects solved an issue where the first collapse
	 * was not animating, so don't try to combine these useEffects.
	 *
	 */
	useEffect(() => {
		setListHeight(initialHeight);
	}, [initialHeight])

	/**
	 * Toggle the height of the list between the initial height & 0
	 * when user clicks on the header row to toggle the open state.
	 *
	 */
	useEffect(() => {
		if (open) {
			setListHeight(initialHeight);

			if (props.onNestedHeightChange) {
				props.onNestedHeightChange(initialHeight);
			}
		} else {
			setListHeight(0);

			if (props.onNestedHeightChange) {
				props.onNestedHeightChange(initialHeight * -1);
			}
		}
	}, [open]);

	/**
	 * Toggle the open state of this nav group.
	 *
	 */
	function toggleOpen(): void {
		setOpen(!open);
	}

	/**
	 * Helper to handle when nested groups are collapsed / opened.
	 * Without this implementation, collapsing / opening a child grouping
	 * will not change the height of its parent.
	 *
	 * @param heightChange
	 */
	function onNestedHeightChange(heightChange: number): void {
		setInitialHeight(initialHeight + heightChange);
	}

	/**
	 * Keep mapping the nav links until the deepest nested level is reached.
	 *
	 * @param _entry
	 */
	function makeList(_entry: INavLinkEntry | INavLinkGroup): ReactNode {

		function goToPage(e: React.MouseEvent<HTMLElement>): void {
			e.preventDefault();

			if (props.sidebarVisible) {
				props.dispatch(toggleSideBar(false));
			}

			history.push((_entry as INavLinkEntry).link);
		}

		if (Object.keys(_entry).includes("entries")) {
			return (
				<SideBarNavigationLinkGroup
					// @ts-ignore
					title={(_entry as INavLinkGroup).title}
					entries={(_entry as INavLinkGroup).entries}
					onNestedHeightChange={onNestedHeightChange}
					sidebarVisible={props.sidebarVisible}
				/>
			);
		} else {
			if ((_entry as INavLinkEntry).label === "Equipment Location Form") {
				return (
					<ViewEquipmentLocationListButton className="sidebar-inner__custom-button">
						Equipment Location Form
					</ViewEquipmentLocationListButton>
				);
			} else {
				return (
					<a
						href="#"
						onClick={goToPage}
						className={classNames({
							"active-link": history?.location?.pathname === (_entry as INavLinkEntry).link,
						})}
					>
						{(_entry as INavLinkEntry).label}
					</a>
				);
			}
		}
	}

	return (
		<div className="sidebar-inner-navigation-entry">
			<div
				className="sidebar-inner-navigation-entry-header-container"
				onClick={toggleOpen}
			>
				<h5>
					{props?.title}

					<FiChevronDown
						className={classNames(
							"sidebar-inner-navigation-entry-header-container-collapse-icon", {
								"sidebar-inner-navigation-entry-header-container-collapse-icon-closed": !open,
							}
						)}
					/>
				</h5>
			</div>

			<div
				className="sidebar-inner-navigation-entry-list"
				ref={listRef}
				style={{height: listHeight}}
			>
				{props?.entries?.map(makeList)}
			</div>
		</div>
	);
};

// @ts-ignore
const SideBarNavigationLinkGroup = withRouter(connect((store: IStore, props: IProps) => {
	return {
		sidebarVisible: store.metaStore.sidebarVisible,
		...props,
	}
})(_SideBarNavigationLinkGroup));

export default SideBarNavigationLinkGroup;
