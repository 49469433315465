/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
/**
 * Check if a given object implements the UpdateLogCommentBody interface.
 */
export function instanceOfUpdateLogCommentBody(value) {
    let isInstance = true;
    isInstance = isInstance && "logCommentID" in value;
    return isInstance;
}
export function UpdateLogCommentBodyFromJSON(json) {
    return UpdateLogCommentBodyFromJSONTyped(json, false);
}
export function UpdateLogCommentBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'logCommentID': json['logCommentID'],
        'flagged': !exists(json, 'flagged') ? undefined : json['flagged'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}
export function UpdateLogCommentBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'logCommentID': value.logCommentID,
        'flagged': value.flagged,
        'comment': value.comment,
    };
}
