import React from "react";

interface IProps {
	size: number;
}

const PrintableSpacer: React.FC<IProps> = (props) => {

	return (
		<div style={{height: `${props.size}px`}}/>
	);
};

export default PrintableSpacer;
