import React from "react";

interface IProps {
	titleA: string;
	titleB: string;
}

const PrintableSplitSubHeaders: React.FC<IProps> = (props) => {

	return (
		<div className="printable__row">
			<div style={{width: "50%"}}>
				<p className="printable__sub-header">
					{props.titleA}
				</p>
			</div>

			<div style={{width: "50%"}}>
				<p className="printable__sub-header">
					{props.titleB}
				</p>
			</div>
		</div>
	);
};

export default PrintableSplitSubHeaders;
