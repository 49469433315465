/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const SiteSortField = {
    name: 'name',
    contactEmail: 'contactEmail',
    renter: 'renter',
    siteOwner: 'siteOwner',
    createdAt: 'createdAt',
    updatedAt: 'updatedAt'
};
export function SiteSortFieldFromJSON(json) {
    return SiteSortFieldFromJSONTyped(json, false);
}
export function SiteSortFieldFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function SiteSortFieldToJSON(value) {
    return value;
}
