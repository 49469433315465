import * as React from "react";
import {ChangeEventHandler, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {EquipmentApi, EquipmentType, Generator, Token} from "client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button, Col, Row} from "reactstrap";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	generator: Generator;
	onClose: () => void;
	onDone: () => void;
}

const ManageGeneratorsEditModal: React.FC<IProps> = (props) => {

	const [equipmentForm, setEquipmentForm] = useState<Generator>(props.generator);

	/**
	 * Reset any changes to the form & call the onClose function from props.
	 *
	 */
	function resetAndClose(): void {
		setEquipmentForm(props.generator);
		props.onClose();
	}

	/**
	 * Dynamic onChange for the form text fields.
	 *
	 * @param key
	 */
	function editEquipmentOnChange(key: keyof Generator): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> {
		return (e) => {
			setEquipmentForm({
				...equipmentForm,
				[key]: e?.target?.value,
			});
		}
	}

	/**
	 * Dynamically call the correct update api on the equipment.
	 *
	 */
	async function submitEquipmentChange(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new EquipmentApi(getConfig(props.fullToken)).updateGenerator({
				updateGeneratorBody: {
					equipmentID: props.generator._id,
					ownedBy: equipmentForm.ownedBy,
					rentedBy: equipmentForm.rentedBy,
					make: equipmentForm.make,
					model: equipmentForm.model,
					serialNumber: equipmentForm.serialNumber,
					preExistingUsageHours: Number(equipmentForm.preExistingUsageHours),
					siteID: props.generator.site?._id,
				},
			})
			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<AlliedModal
			isOpen={props.isOpen}
			size="lg"
		>
			<AlliedModalHeader
				title="Edit Generator"
				onClose={resetAndClose}
			/>

			<hr/>

			<Row>

				<Col xs={12} md={6} className="mb-3">
					<label>Owned By</label>
					<input
						placeholder="Enter Owner..."
						value={equipmentForm.ownedBy}
						onChange={editEquipmentOnChange("ownedBy")}
					/>
				</Col>
			</Row>

			<Row>

				<Col xs={12} md={6} className="mb-3">
					<label>Make</label>
					<input
						placeholder="Make..."
						value={equipmentForm.make}
						onChange={editEquipmentOnChange("make")}
					/>
				</Col>

				<Col xs={12} md={6} className="mb-3">
					<label>Model</label>
					<input
						placeholder="Enter Model..."
						value={equipmentForm.model}
						onChange={editEquipmentOnChange("model")}
					/>
				</Col>

				<Col xs={12} md={6} className="mb-3">
					<label>S/N</label>
					<input
						placeholder="Enter S/N..."
						value={equipmentForm.serialNumber}
						onChange={editEquipmentOnChange("serialNumber")}
					/>
				</Col>
			</Row>

			<Row>
				<Col xs={12} md={6} className="mb-3">
					<label>Current Renter</label>
					<input
						placeholder="Enter Renter..."
						value={equipmentForm.rentedBy}
						onChange={editEquipmentOnChange("rentedBy")}
					/>
				</Col>

				<Col xs={12} md={6} className="mb-3">
					<label>Usage Hours</label>
					<input
						placeholder="Enter Usage Hours..."
						value={equipmentForm.preExistingUsageHours}
						onChange={editEquipmentOnChange("preExistingUsageHours")}
					/>
				</Col>

			</Row>

			<div>
				<Button
					color="black"
					onClick={submitEquipmentChange}
				>
					Save Changes
				</Button>
			</div>
		</AlliedModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageGeneratorsEditModal);

