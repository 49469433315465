/**
 * Utility to dynamically grab fields from nested objects in api responses for tables.
 *
 * @param property
 * @param formatter
 */
export function getGenericObjectProperty<T>(property: keyof T, formatter?: (value: any) => any): (item: T) => any {
	return (item: T) => {
		return item?.[property] ? item?.[property] : "-";
	}
}
