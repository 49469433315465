import React from "react";
import CreateServiceReportForm from "../../components/forms/CreateServiceReportForm";
import {RouteComponentProps} from "react-router";
import {useHistory} from "react-router-dom";
import {userTypeCheck} from "../../utils/userTypeCheck";
import {Token, UserType} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";

interface IProps extends RouteComponentProps {
	fullToken?: Token;
}

const EditServiceReportPage: React.FC<IProps> = (props) => {

	const history = useHistory();

	const params: URLSearchParams = new URLSearchParams(props.location?.search);
	const id: string = params.get("id");

	if (id?.length < 1) {
		if (userTypeCheck([UserType.ALLIED_CRANE_EMPLOYEE], props.fullToken)) {
			history.replace("/form-history/service-reports-history");
		} else {
			history.replace("/allied-crane-employee-forms/service-reports");
		}
	}

	return (
		<CreateServiceReportForm id={id}/>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(EditServiceReportPage);
