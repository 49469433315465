
import React, {useState} from "react";
import {TimeSheet, TimeSheetEntry, Token} from "client";
import {Button} from "reactstrap";
import ManageTimeSheetsEntryReferenceModal from "../../modals/ManageTimeSheetsEntryReferenceModal";
import ManageTimeSheetsEntryConfirmDeleteModal from "../../modals/ManageTimeSheetsEntryConfirmDeleteModal";

interface IProps {
	entry: TimeSheetEntry;
	timeSheet: TimeSheet;
	onDone: () => Promise<void>;
}

// Version for employees

const ManageTimeSheetsHistoryDeleteEntryCell: React.FC<IProps> = (props) => {

	const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

	/**
	 * Toggle visibility of the confirm deletion modal.
	 *
	 */
	function toggleShowConfirmDeleteModal(): void {
		setShowConfirmDeleteModal(!showConfirmDeleteModal);
	}

	/**
	 * Close the confirm delete modal and call the props.onDone function.
	 *
	 */
	function onDone(): void {
		setShowConfirmDeleteModal(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<ManageTimeSheetsEntryConfirmDeleteModal
				isOpen={showConfirmDeleteModal}
				entry={props.entry}
				onClose={toggleShowConfirmDeleteModal}
				onDone={onDone}
			/>

			<div className="allied-table-cell">
				<Button
					color="red"
					onClick={toggleShowConfirmDeleteModal}
					disabled={props.timeSheet?.submittedDate !== undefined}
				>
					Delete Entry
				</Button>
			</div>
		</React.Fragment>
	);
};

export default ManageTimeSheetsHistoryDeleteEntryCell;
