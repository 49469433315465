import React, {ReactNode} from "react";
import classNames from "classnames";

export interface IAlliedRadioProps {
	className?: string;
	label?: string;
	name?: string;
	checked?: boolean;
	onChange?: () => void;
}

const AlliedInputRadio: React.FC<IAlliedRadioProps> = (props) => {

	return (
		<label className={classNames("custom-radio", props.className)}>
			{props.label}
			<input
				type="radio"
				name={props.name ? props.name : (props.label ? props.label : "radio")}
				checked={props.checked}
				onClick={props.onChange}
			/>
			<span className="checkmark"/>
		</label>
	);
};

export const AlliedInputRadioFactory: React.FC<{radios: Array<IAlliedRadioProps>}> = (props) => {

	/**
	 * Make a radio input
	 *
	 * @param _radio
	 * @param i
	 */
	function makeRadio(_radio: IAlliedRadioProps, i: number): ReactNode {
		return (
			<AlliedInputRadio
				{..._radio}
				key={`${_radio.label + Math.random()}-${i}`}
			/>
		);
	}

	return (
		<React.Fragment>
			{props.radios.map(makeRadio)}
		</React.Fragment>
	);
};

export default AlliedInputRadio;
