import React from "react";
import {TimeSheet, TimeSheetEntry} from "client";
import PrintableSpacer from "../elements/PrintableSpacer";
import PrintableCellLabelWithValue from "../elements/PrintableCellLabelWithValue";
import {asPercentOf100} from "../../../utils/asPercentOf100";
import PrintableCellRow from "../elements/PrintableCellRow";
import {getFullNameForTable} from "../../../utils/getFullNameForTable";
import {getWeekRangeForTable} from "../../../utils/getWeekRangeForTable";
import PrintableTimeSheetEntriesTable from "../elements/PrintableTimeSheetEntriesTable";
import {valueOrBustPrintables} from "../../../utils/valueOrBust";
import PrintableTextArea from "../elements/PrintableTextArea";
import {generateOffsetDate} from "../../../utils/timeZoneConversions";
import {humanReadableDuration} from "../../../utils/convertMillisecondsToHours";

interface IProps {
	form: TimeSheet;
}

const TimeSheetPrintable: React.FC<IProps> = (props) => {

	return (
		<React.Fragment>
			<p className="printable__header">
				Time Sheet
			</p>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="NAME:"
					value={getFullNameForTable(props.form?.owner)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="WEEK OF:"
					value={getWeekRangeForTable(1)(generateOffsetDate(props.form?.weekOf))}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="PAY PERIOD:"
					value={getWeekRangeForTable(2)(generateOffsetDate(props.form?.paymentPeriod))}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableTimeSheetEntriesTable entries={props.form?.entries}/>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="TOTAL HOURS:"
					value={humanReadableDuration(props.form?.entries?.reduce((acc: number, entry: TimeSheetEntry) => {
						if (entry.isSickDay) {
							return acc;
						}

						return acc + entry.hours
					}, 0))}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableTextArea
					label="ADMIN COMMENTS:"
					value={valueOrBustPrintables(props.form?.notes)}
					width={100}
				/>
			</PrintableCellRow>
		</React.Fragment>
	);
}

export default TimeSheetPrintable;
