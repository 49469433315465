/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CreateLogCommentBodyFromJSON, CreateLogCommentBodyToJSON, } from './CreateLogCommentBody';
import { InspectionStatusFromJSON, InspectionStatusToJSON, } from './InspectionStatus';
/**
 * Check if a given object implements the CreateTowerCraneDailyLogBody interface.
 */
export function instanceOfCreateTowerCraneDailyLogBody(value) {
    let isInstance = true;
    isInstance = isInstance && "equipmentID" in value;
    isInstance = isInstance && "siteID" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "initials" in value;
    return isInstance;
}
export function CreateTowerCraneDailyLogBodyFromJSON(json) {
    return CreateTowerCraneDailyLogBodyFromJSONTyped(json, false);
}
export function CreateTowerCraneDailyLogBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'equipmentID': json['equipmentID'],
        'siteID': json['siteID'],
        'date': json['date'],
        'initials': json['initials'],
        'createLogCommentBodies': !exists(json, 'createLogCommentBodies') ? undefined : (json['createLogCommentBodies'].map(CreateLogCommentBodyFromJSON)),
        'users': !exists(json, 'users') ? undefined : json['users'],
        'hours': !exists(json, 'hours') ? undefined : json['hours'],
        'electricalPowerCords': !exists(json, 'electricalPowerCords') ? undefined : InspectionStatusFromJSON(json['electricalPowerCords']),
        'groundFaultCircuitInterrupter': !exists(json, 'groundFaultCircuitInterrupter') ? undefined : InspectionStatusFromJSON(json['groundFaultCircuitInterrupter']),
        'onOffSwitch': !exists(json, 'onOffSwitch') ? undefined : InspectionStatusFromJSON(json['onOffSwitch']),
        'craneBaseInspection': !exists(json, 'craneBaseInspection') ? undefined : InspectionStatusFromJSON(json['craneBaseInspection']),
        'inspectWalkways': !exists(json, 'inspectWalkways') ? undefined : InspectionStatusFromJSON(json['inspectWalkways']),
        'inspectStructure': !exists(json, 'inspectStructure') ? undefined : InspectionStatusFromJSON(json['inspectStructure']),
        'ensureAllDoors': !exists(json, 'ensureAllDoors') ? undefined : InspectionStatusFromJSON(json['ensureAllDoors']),
        'operatorsControlsAdequate': !exists(json, 'operatorsControlsAdequate') ? undefined : InspectionStatusFromJSON(json['operatorsControlsAdequate']),
        'loadMomentHoistLimit': !exists(json, 'loadMomentHoistLimit') ? undefined : InspectionStatusFromJSON(json['loadMomentHoistLimit']),
        'confirmLocationOfZoning': !exists(json, 'confirmLocationOfZoning') ? undefined : InspectionStatusFromJSON(json['confirmLocationOfZoning']),
        'maximumLoad': !exists(json, 'maximumLoad') ? undefined : InspectionStatusFromJSON(json['maximumLoad']),
        'hoistUpDecelerationLimit': !exists(json, 'hoistUpDecelerationLimit') ? undefined : InspectionStatusFromJSON(json['hoistUpDecelerationLimit']),
        'hoistUpperLimit': !exists(json, 'hoistUpperLimit') ? undefined : InspectionStatusFromJSON(json['hoistUpperLimit']),
        'hoistDownLimit': !exists(json, 'hoistDownLimit') ? undefined : InspectionStatusFromJSON(json['hoistDownLimit']),
        'ensureAllAudioVisualIndicatorsFunction': !exists(json, 'ensureAllAudioVisualIndicatorsFunction') ? undefined : InspectionStatusFromJSON(json['ensureAllAudioVisualIndicatorsFunction']),
        'anemometer': !exists(json, 'anemometer') ? undefined : InspectionStatusFromJSON(json['anemometer']),
        'hoistBrakeFunctioning': !exists(json, 'hoistBrakeFunctioning') ? undefined : InspectionStatusFromJSON(json['hoistBrakeFunctioning']),
        'slewingBrakeFunctioning': !exists(json, 'slewingBrakeFunctioning') ? undefined : InspectionStatusFromJSON(json['slewingBrakeFunctioning']),
        'visuallyInspectLoadBlockAndHook': !exists(json, 'visuallyInspectLoadBlockAndHook') ? undefined : InspectionStatusFromJSON(json['visuallyInspectLoadBlockAndHook']),
        'travelBrakeToRail': !exists(json, 'travelBrakeToRail') ? undefined : InspectionStatusFromJSON(json['travelBrakeToRail']),
        'railTravelForwardAndReverse': !exists(json, 'railTravelForwardAndReverse') ? undefined : InspectionStatusFromJSON(json['railTravelForwardAndReverse']),
        'inspectTracksForLoseConnections': !exists(json, 'inspectTracksForLoseConnections') ? undefined : InspectionStatusFromJSON(json['inspectTracksForLoseConnections']),
        'housekeeping': !exists(json, 'housekeeping') ? undefined : InspectionStatusFromJSON(json['housekeeping']),
        'supervisorNotedOfAnyDefectsOrFaults': !exists(json, 'supervisorNotedOfAnyDefectsOrFaults') ? undefined : InspectionStatusFromJSON(json['supervisorNotedOfAnyDefectsOrFaults']),
        'ensureAllTower': !exists(json, 'ensureAllTower') ? undefined : InspectionStatusFromJSON(json['ensureAllTower']),
        'loadMomentTrolleyLimit': !exists(json, 'loadMomentTrolleyLimit') ? undefined : InspectionStatusFromJSON(json['loadMomentTrolleyLimit']),
        'trolleyOut': !exists(json, 'trolleyOut') ? undefined : InspectionStatusFromJSON(json['trolleyOut']),
        'trolleyIn': !exists(json, 'trolleyIn') ? undefined : InspectionStatusFromJSON(json['trolleyIn']),
        'trolleyBrake': !exists(json, 'trolleyBrake') ? undefined : InspectionStatusFromJSON(json['trolleyBrake']),
    };
}
export function CreateTowerCraneDailyLogBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'equipmentID': value.equipmentID,
        'siteID': value.siteID,
        'date': value.date,
        'initials': value.initials,
        'createLogCommentBodies': value.createLogCommentBodies === undefined ? undefined : (value.createLogCommentBodies.map(CreateLogCommentBodyToJSON)),
        'users': value.users,
        'hours': value.hours,
        'electricalPowerCords': InspectionStatusToJSON(value.electricalPowerCords),
        'groundFaultCircuitInterrupter': InspectionStatusToJSON(value.groundFaultCircuitInterrupter),
        'onOffSwitch': InspectionStatusToJSON(value.onOffSwitch),
        'craneBaseInspection': InspectionStatusToJSON(value.craneBaseInspection),
        'inspectWalkways': InspectionStatusToJSON(value.inspectWalkways),
        'inspectStructure': InspectionStatusToJSON(value.inspectStructure),
        'ensureAllDoors': InspectionStatusToJSON(value.ensureAllDoors),
        'operatorsControlsAdequate': InspectionStatusToJSON(value.operatorsControlsAdequate),
        'loadMomentHoistLimit': InspectionStatusToJSON(value.loadMomentHoistLimit),
        'confirmLocationOfZoning': InspectionStatusToJSON(value.confirmLocationOfZoning),
        'maximumLoad': InspectionStatusToJSON(value.maximumLoad),
        'hoistUpDecelerationLimit': InspectionStatusToJSON(value.hoistUpDecelerationLimit),
        'hoistUpperLimit': InspectionStatusToJSON(value.hoistUpperLimit),
        'hoistDownLimit': InspectionStatusToJSON(value.hoistDownLimit),
        'ensureAllAudioVisualIndicatorsFunction': InspectionStatusToJSON(value.ensureAllAudioVisualIndicatorsFunction),
        'anemometer': InspectionStatusToJSON(value.anemometer),
        'hoistBrakeFunctioning': InspectionStatusToJSON(value.hoistBrakeFunctioning),
        'slewingBrakeFunctioning': InspectionStatusToJSON(value.slewingBrakeFunctioning),
        'visuallyInspectLoadBlockAndHook': InspectionStatusToJSON(value.visuallyInspectLoadBlockAndHook),
        'travelBrakeToRail': InspectionStatusToJSON(value.travelBrakeToRail),
        'railTravelForwardAndReverse': InspectionStatusToJSON(value.railTravelForwardAndReverse),
        'inspectTracksForLoseConnections': InspectionStatusToJSON(value.inspectTracksForLoseConnections),
        'housekeeping': InspectionStatusToJSON(value.housekeeping),
        'supervisorNotedOfAnyDefectsOrFaults': InspectionStatusToJSON(value.supervisorNotedOfAnyDefectsOrFaults),
        'ensureAllTower': InspectionStatusToJSON(value.ensureAllTower),
        'loadMomentTrolleyLimit': InspectionStatusToJSON(value.loadMomentTrolleyLimit),
        'trolleyOut': InspectionStatusToJSON(value.trolleyOut),
        'trolleyIn': InspectionStatusToJSON(value.trolleyIn),
        'trolleyBrake': InspectionStatusToJSON(value.trolleyBrake),
    };
}
