import React, {PropsWithChildren} from "react";
import {Label} from "reactstrap";
import { FiCheck } from "react-icons/fi";

export interface IAlliedInputCheckboxProps extends PropsWithChildren {
	name?: string;
	disabled?: boolean;
	checkmarkClassName?: string;
	checkmarkColor?: string;
	checked?: boolean;
	onToggle?: () => void;
}

const AlliedInputCheckbox: React.FC<IAlliedInputCheckboxProps> = (props): JSX.Element => {

	const {checked, onToggle, disabled, checkmarkColor, checkmarkClassName} = props;

	const handleClick = () => {
		if (!disabled) {
			onToggle();
		}
	};

	return (
		<div className="pr-3 text-left">
			<Label className="custom-checkbox">{props.children}
				<input type="checkbox" checked={checked} onChange={handleClick} />
				<span className={`checkmark ${checkmarkClassName}`}>
                    <FiCheck size={"4rem"} color={checkmarkColor} className={!checked ? "d-none" : ""} />
                </span>
			</Label>
		</div>
	);
}

AlliedInputCheckbox.defaultProps = {
	disabled: false,
	checked: false,
	checkmarkClassName: "",
	checkmarkColor: "red",
}
export default AlliedInputCheckbox
