import React, {ReactNode} from "react";
import {IAutoCompleteOption} from "./AlliedAutoComplete";
import {FiX} from "react-icons/all";
import {cloneDeep} from "lodash";

interface IProps {
	options: Array<IAutoCompleteOption>;
	selections: Array<string>;
	setSelections: (newSelections: Array<string>) => void;
}

const AutoCompleteListDisplay: React.FC<IProps> = (props) => {

	/**
	 * Create a ReactNode for each selected item & attach functionality.
	 *
	 * @param item
	 * @param i
	 */
	function makeItem(item: IAutoCompleteOption, i: number): ReactNode {
		if (props.selections.indexOf(item.value) < 0) {
			return null;
		}

		function removeOption(): void {
			const clonedSelections: string[] = cloneDeep(props.selections);
			const indexOfSelection: number = props.selections.indexOf(item.value);

			clonedSelections.splice(indexOfSelection, 1);

			props.setSelections(clonedSelections);
		}

		return (
			<div
				key={`auto-complete-item-${item.value}-${i}`}
				onClick={removeOption}
				className="auto-complete-list-container-item"
			>
				<span>
					{item.label}
				</span>

				<div className="auto-complete-list-container-item-close-icon">
					<FiX className="auto-complete-list-container-item-close-icon-x"/>
				</div>
			</div>
		);
	}

	if (!props.selections || props.selections?.length < 0) {
		return null;
	}

	return (
		<div className="auto-complete-list-container">
			{props.options?.map(makeItem)}
		</div>
	);
};

export default AutoCompleteListDisplay;
