import React, {ReactNode, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	CraneDailyLog,
	FormsApi,
	GeneratorDailyLog,
	GeneratorDailyLogGroup,
	InspectionStatus,
	Token,
	UserType
} from "client";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import AlliedTableContainer from "../tables/AlliedTableContainer";
import AlliedTableHeaderCell from "../tables/cells/AlliedTableHeaderCell";
import {
	isInspectionStatus,
	parseInspectionStatus,
	parseInspectionStatusTableHelper
} from "../../utils/parseInspectionStatus";
import {TableData} from "frame-one-table/build/contextTypes";
import {ColumnOption} from "frame-one-table/build/TableGenerator";
import GeneratorDailyLogsEditIndividualLogCell from "../tables/cells/GeneratorDailyLogsEditIndividualLogCell";
import {valueOrBust} from "../../utils/valueOrBust";
import {parseDateForTableDisplay} from "../../utils/parseDateForTableDisplay";
import ViewGeneratorDailyLogButton from "../printables/buttons/ViewGeneratorDailyLogButton";
import DailyLogsGroupViewHistoryCell from "../tables/cells/DailyLogsGroupViewHistoryCell";
import {Link} from "react-router-dom";
import {getFullNameForTable} from "../../utils/getFullNameForTable";
import {
	addDatesToEachIndividualGeneratorDailyLogWithinGroup, CraneDailyLogWithDate,
	GeneratorDailyLogWithDate
} from "../../utils/getDateOfIndividualDailyLogBasedOnDay";
import {getOperatorsOnSingleDailyLog} from "../../utils/getAllOperatorsFromGroupedDailyLogs";
import CraneDailyLogsDeleteIndividualLogCell from "../tables/cells/CraneDailyLogsDeleteIndividualLogCell";
import GeneratorDailyLogsDeleteIndividualLogCell from "../tables/cells/GeneratorDailyLogsDeleteIndividualLogCell";
import {userTypeCheck} from "../../utils/userTypeCheck";
import CraneDailyLogsOperatorConfirmDeleteGroup from "./CraneDailyLogsOperatorConfirmDeleteGroupModal";
import GeneratorDailyLogsOperatorConfirmDeleteGroupModal from "./GeneratorDailyLogsOperatorConfirmDeleteGroupModal";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	logGroup: GeneratorDailyLogGroup;
	onClose: () => void;
	onDone: () => void;
}

const GeneratorDailyLogsOperatorOptionsModal: React.FC<IProps> = (props) => {

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	function toggleDeleteGroupModal(): void {
		setShowDeleteModal(!showDeleteModal);
	}

	function onDoneDeleteModal(): void {
		setShowDeleteModal(false);
		props.onDone();
	}

	/**
	 * Call api to submit the Generator Daily Logs,
	 * and call onDone function from props after to update table.
	 *
	 */
	async function onSubmitGeneratorDailyLogs(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new FormsApi(getConfig(props.fullToken)).submitGeneratorDailyLog({
				id: props.logGroup?._id,
			});
			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Renderer of the Edit Log column.
	 *
	 * @param log
	 */
	function makeEditCell(log: GeneratorDailyLogWithDate): ReactNode {
		return (
			<GeneratorDailyLogsEditIndividualLogCell
				log={log}
				logGroup={props.logGroup}
			/>
		);
	}

	/**
	 * Renderer of the Edit Log column.
	 *
	 * @param log
	 */
	function makeDeleteCell(log: GeneratorDailyLogWithDate): ReactNode {
		return (
			<GeneratorDailyLogsDeleteIndividualLogCell
				log={log}
				logGroup={props.logGroup}
				onDone={props.onDone}
			/>
		);
	}

	return (
		<React.Fragment>
			<GeneratorDailyLogsOperatorConfirmDeleteGroupModal
				isOpen={showDeleteModal}
				log={props.logGroup}
				onClose={toggleDeleteGroupModal}
				onDone={onDoneDeleteModal}
			/>

			<AlliedModal
				isOpen={props.isOpen}
				size="xl"
			>
				<AlliedModalHeader
					title="Generator Daily Log Options"
					onClose={props.onClose}
				/>

				<hr/>

				<p>
					*Note that you can only edit the logs <b>before</b> they're submitted.
				</p>

				<div className="d-flex flex-row flex-wrap align-items-start">
					<Button
						color="red"
						onClick={onSubmitGeneratorDailyLogs}
						disabled={props.logGroup?.submittedDate !== undefined}
						className="mb-3 mr-3"
					>
						Submit Logs
					</Button>

					<ViewGeneratorDailyLogButton
						groupedGeneratorLogs={props.logGroup}
						className="mb-3 mr-3"
					>
						View Logs
					</ViewGeneratorDailyLogButton>

					<Button
						color="black"
						onClick={toggleDeleteGroupModal}
						disabled={props.logGroup?.submittedDate !== undefined}
						className="mb-3 mr-3"
					>
						Delete Log Group
					</Button>

					<Link to="/create-forms/create-daily-log/create-equipment-daily-log">
						<Button
							color="black"
							className="mb-3"
						>
							Create New Log
						</Button>
					</Link>
				</div>

				<hr className="mt-0"/>

				<AlliedTableContainer
					data={addDatesToEachIndividualGeneratorDailyLogWithinGroup(props.logGroup)}
					columnOptions={[
						{
							headerValue: "Date",
							key: "date",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: parseDateForTableDisplay,
						},
						{
							headerValue: "Hours",
							key: "hours",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: valueOrBust,
						},
						{
							headerValue: "Operator(s)",
							key: "users",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: (value, cellContextDetails) => getOperatorsOnSingleDailyLog(cellContextDetails.row as GeneratorDailyLog),
						},
						{
							headerValue: "Hour Meter Start",
							key: "hourMeterStart",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: valueOrBust,
						},
						{
							headerValue: "Hour Meter End",
							key: "hourMeterEnd",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: valueOrBust,
						},
						{
							headerValue: "Hour Meter Total",
							key: "hourMeterTotal",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: valueOrBust,
						},
						{
							headerValue: "Check Oil Level",
							key: "checkOilLevel",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: (value, cellContextDetails) => parseInspectionStatusTableHelper("checkOilLevel")(isInspectionStatus((cellContextDetails.row as GeneratorDailyLog).checkOilLevel) ? cellContextDetails.row?.["checkOilLevel"] : undefined),
						},
						{
							headerValue: "Check Coolant Levels",
							key: "checkCoolantLevels",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: (value, cellContextDetails) => parseInspectionStatusTableHelper("checkCoolantLevels")(isInspectionStatus((cellContextDetails.row as GeneratorDailyLog).checkCoolantLevels) ? cellContextDetails.row?.["checkCoolantLevels"] : undefined),
						},
						{
							headerValue: "Visual Inspection for Wear",
							key: "visualInspectionForWear",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: (value, cellContextDetails) => parseInspectionStatusTableHelper("visualInspectionForWear")(isInspectionStatus((cellContextDetails.row as GeneratorDailyLog).visualInspectionForWear) ? cellContextDetails.row?.["visualInspectionForWear"] : undefined),
						},
						{
							headerValue: "Check for Leakage",
							key: "checkForLeakage",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: (value, cellContextDetails) => parseInspectionStatusTableHelper("checkForLeakage")(isInspectionStatus((cellContextDetails.row as GeneratorDailyLog).checkForLeakage) ? cellContextDetails.row?.["checkForLeakage"] : undefined),
						},
						{
							headerValue: "Ensure Door Panels and Covers are Closed",
							key: "ensureDoorPanelsAndCoversAreClosed",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: (value, cellContextDetails) => parseInspectionStatusTableHelper("ensureDoorPanelsAndCoversAreClosed")(isInspectionStatus((cellContextDetails.row as GeneratorDailyLog).ensureDoorPanelsAndCoversAreClosed) ? cellContextDetails.row?.["ensureDoorPanelsAndCoversAreClosed"] : undefined),
						},
						// // {
						// // 	headerValue: "Certification Number",
						// // 	key: "certificateNumber",
						// // 	showSortIcons: false,
						// // 	sortable: true,
						// // 	headerRender: AlliedTableHeaderCell,
						// // 	valueFormatter: valueOrBust,
						// // },
						{
							headerValue: "Edit Log",
							key: undefined,
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							cellRender: makeEditCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
						(userTypeCheck([UserType.CRANE_OPERATOR], props.fullToken) &&
							{
								headerValue: "Delete Log",
								key: undefined,
								showSortIcons: false,
								sortable: false,
								headerRender: AlliedTableHeaderCell,
								cellRender: makeDeleteCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							}
						),
					]}
				/>
			</AlliedModal>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(GeneratorDailyLogsOperatorOptionsModal);
