import React from "react";
import {ServiceReport, Token} from "client";
import PrintableCellRow from "../elements/PrintableCellRow";
import PrintableCellLabelWithValue from "../elements/PrintableCellLabelWithValue";
import moment from "moment";
import {parseSiteAddressForSingleInput} from "../../../utils/parseSiteAddressForSingleInput";
import {asPercentOf100} from "../../../utils/asPercentOf100";
import PrintableTextArea from "../elements/PrintableTextArea";
import PrintableWorkPerformedList from "../elements/PrintableWorkPerformedList";
import PrintableServiceHoursTable from "../elements/PrintableServiceHoursTable";
import {valueOrBustPrintables} from "../../../utils/valueOrBust";
import {convertMinutesToHoursAndMinutes} from "../../../utils/convertMinutesToHoursAndMinutes";
import {convertBooleanToString} from "../../../utils/convertBooleanToString";
import PrintableLunchBreakTable from "../elements/PrintableLunchBreakTable";
import PrintablePartsAndSuppliesTable from "../elements/PrintablePartsAndSuppliesTable";
import PrintableSpacer from "../elements/PrintableSpacer";
import {generateOffsetDate} from "../../../utils/timeZoneConversions";
import {parseServiceReportShortID} from "../../../utils/parseServiceReportShortID";
import { connect } from "react-redux";
import {IStore} from "../../../redux/defaultStore";

interface IProps {
	fullToken?: Token;
	form: ServiceReport;
}

const ServiceReportPrintable: React.FC<IProps> = (props) => {

	return (
		<React.Fragment>
			<p className="printable__header">
				Service Report
			</p>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="No:"
					value={parseServiceReportShortID(props.form?.shortIdentifier)}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Customer:"
					value={valueOrBustPrintables(props.form?.customer)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Date:"
					value={valueOrBustPrintables(props.form?.date, moment(generateOffsetDate(props.form?.date)).format("MM/DD/YYYY"))}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Site Name:"
					value={valueOrBustPrintables(props.form?.originalSite?.name)}
					width={asPercentOf100(2 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Site Address:"
					value={valueOrBustPrintables(props.form?.originalSite?.address, parseSiteAddressForSingleInput(props.form?.originalSite?.address))}
					width={asPercentOf100(2 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Service Requested By:"
					value={valueOrBustPrintables(props.form?.serviceRequestedBy)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Usage Hours:"
					value={valueOrBustPrintables(props.form?.usageHours, props.form?.usageHours?.toString())}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Equipment Make/Model:"
					value={valueOrBustPrintables(props.form?.equipment?.make) + "/" + valueOrBustPrintables(props.form?.equipment?.model)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Serial #:"
					value={valueOrBustPrintables(props.form?.equipment?.serialNumber)}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableTextArea
					label="Reported Issue:"
					value={valueOrBustPrintables(props.form?.reportedIssue)}
					width={100}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<p className="printable__sub-header">
				Work Performed
			</p>

			<PrintableWorkPerformedList workPerformed={props.form?.workPerformed}/>

			<PrintableSpacer size={10}/>

			<p className="printable__sub-header">
				Service Hours
			</p>

			{props.form?.entries?.length > 0 && (
				<PrintableLunchBreakTable lunchBreakers={props.form?.entries}/>
			)}

			<PrintableSpacer size={10}/>

			<p className="printable__sub-header">
				Parts and Supplies
			</p>

			<PrintablePartsAndSuppliesTable
				partsAndSuppliesArray={props.form?.partsAndSuppliesArray}
				userType={props.fullToken?.type}
			/>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Additional Service Required?"
					value={valueOrBustPrintables(props.form?.additionalServiceRequired, convertBooleanToString(props.form?.additionalServiceRequired))}
					width={asPercentOf100(1/3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Representative Email:"
					value={valueOrBustPrintables(props.form?.repEmail)}
					width={asPercentOf100(1/3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Authorized Representative:"
					value={valueOrBustPrintables(props.form?.authorizedRepresentative)}
					width={asPercentOf100(1/3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableTextArea
					label="ADMIN COMMENTS:"
					value={valueOrBustPrintables(props.form?.notes)}
					width={100}
				/>
			</PrintableCellRow>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ServiceReportPrintable);
