/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
/**
 * Check if a given object implements the TimeSheetEntry interface.
 */
export function instanceOfTimeSheetEntry(value) {
    let isInstance = true;
    isInstance = isInstance && "_id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "timeIn" in value;
    return isInstance;
}
export function TimeSheetEntryFromJSON(json) {
    return TimeSheetEntryFromJSONTyped(json, false);
}
export function TimeSheetEntryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'isSickDay': !exists(json, 'isSickDay') ? undefined : json['isSickDay'],
        'timeIn': json['timeIn'],
        'timeOut': !exists(json, 'timeOut') ? undefined : json['timeOut'],
        'hours': !exists(json, 'hours') ? undefined : json['hours'],
        'jobDescription': !exists(json, 'jobDescription') ? undefined : json['jobDescription'],
        'lunchBreak': !exists(json, 'lunchBreak') ? undefined : json['lunchBreak'],
        'driveTime': !exists(json, 'driveTime') ? undefined : json['driveTime'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
    };
}
export function TimeSheetEntryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'isSickDay': value.isSickDay,
        'timeIn': value.timeIn,
        'timeOut': value.timeOut,
        'hours': value.hours,
        'jobDescription': value.jobDescription,
        'lunchBreak': value.lunchBreak,
        'driveTime': value.driveTime,
        'reference': value.reference,
    };
}
