/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeficiencyFromJSON, DeficiencyToJSON, } from './Deficiency';
import { EquipmentFromJSON, EquipmentToJSON, } from './Equipment';
import { PaginationInfoFromJSON, PaginationInfoToJSON, } from './PaginationInfo';
/**
 * Check if a given object implements the GetDeficiencyListForEquipmentResponse interface.
 */
export function instanceOfGetDeficiencyListForEquipmentResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "paginationInfo" in value;
    isInstance = isInstance && "equipment" in value;
    isInstance = isInstance && "deficiencies" in value;
    return isInstance;
}
export function GetDeficiencyListForEquipmentResponseFromJSON(json) {
    return GetDeficiencyListForEquipmentResponseFromJSONTyped(json, false);
}
export function GetDeficiencyListForEquipmentResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'paginationInfo': PaginationInfoFromJSON(json['paginationInfo']),
        'equipment': EquipmentFromJSON(json['equipment']),
        'deficiencies': (json['deficiencies'].map(DeficiencyFromJSON)),
    };
}
export function GetDeficiencyListForEquipmentResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'paginationInfo': PaginationInfoToJSON(value.paginationInfo),
        'equipment': EquipmentToJSON(value.equipment),
        'deficiencies': (value.deficiencies.map(DeficiencyToJSON)),
    };
}
