import React, {useState} from "react";
import {ServiceReport, TimeSheet} from "client";
import {Button} from "reactstrap";
import ManageServiceReportsCommentModal from "../../modals/ManageServiceReportsCommentModal";

interface IProps {
	serviceReport: ServiceReport;
	onDone: () => Promise<void>;
}

const ManageServiceReportsCommentCell: React.FC<IProps> = (props) => {

	const [showCommentModal, setShowCommentModal] = useState(false);

	/**
	 * Toggle showing the modal flow.
	 *
	 */
	function toggleShowModalFlow(): void {
		setShowCommentModal(!showCommentModal);
	}

	/**
	 * Close modal and call API from parent to update table.
	 *
	 */
	function onDone(): void {
		setShowCommentModal(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<ManageServiceReportsCommentModal
				isOpen={showCommentModal}
				serviceReport={props.serviceReport}
				onClose={toggleShowModalFlow}
				onDone={onDone}
			/>

			<div className="allied-table-cell">
				<Button
					color="black"
					onClick={toggleShowModalFlow}
				>
					Add/Edit Comment
				</Button>
			</div>
		</React.Fragment>
	);
};

export default ManageServiceReportsCommentCell;
