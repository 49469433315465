import {CraneWeeklyLog, CreateCraneWeeklyLogBody} from "client";
import {defaultCreateCraneWeeklyLogForm} from "../components/forms/CreateCraneWeeklyLogForm";
import {generateOffsetDate} from "./timeZoneConversions";

export function convertCraneWeeklyLogResponseToCreateBody(res: CraneWeeklyLog): CreateCraneWeeklyLogBody {
	return {
		equipmentID: res?.equipment?._id,
		siteID: res?.originalSite?._id,
		date: res.date !== undefined ? generateOffsetDate(res.date) : undefined,
		initials: res.initials,
		createLogCommentBodies: defaultCreateCraneWeeklyLogForm.createLogCommentBodies,
		visualInspectionForOfSlewRing: res.visualInspectionForOfSlewRing,
		visualInspectionOfTrolleyRollers: res.visualInspectionOfTrolleyRollers,
		visualInspectionOfSheaves: res.visualInspectionOfSheaves,
		jibBackstops: res.jibBackstops,
		boomHoistBrake: res.boomHoistBrake,
		checkWireRopeEnds: res.checkWireRopeEnds,
		inspectLoadLine: res.inspectLoadLine,
		visualInspectionOfTieIns: res.visualInspectionOfTieIns,
		ensureMachineIsProperlyLubricated: res.ensureMachineIsProperlyLubricated,
		visualInspectionOfAllDriveComponents: res.visualInspectionOfAllDriveComponents,
		visualInspectionOfCounterweightSupports: res.visualInspectionOfCounterweightSupports,
		visualInspectionOfAnchorBolts: res.visualInspectionOfAnchorBolts,
		visualInspectionOfTowerBolts: res.visualInspectionOfTowerBolts,
		ensureTrackIsLevel: res.ensureTrackIsLevel,
		supervisorNotifiedOfDefects: res.supervisorNotifiedOfDefects,
	}
}

