import * as React from "react";
import {BsChevronDown} from "react-icons/all";
import {ChangeEvent, ChangeEventHandler, SelectHTMLAttributes, SyntheticEvent} from "react";

interface IProps extends SelectHTMLAttributes<HTMLSelectElement> {
}

const AlliedSelect: React.FC<IProps> = (props: IProps) => {

    return (
        <div className="allied-custom-select">
            <select {...props}>
                {props.children}
            </select>
            <span><BsChevronDown /></span>
        </div>
    );
}
export default AlliedSelect;