/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
/**
 * Check if a given object implements the OperatorSignUpBody interface.
 */
export function instanceOfOperatorSignUpBody(value) {
    let isInstance = true;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "confirmPassword" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "certificationNumber" in value;
    isInstance = isInstance && "crane" in value;
    isInstance = isInstance && "securityCode" in value;
    return isInstance;
}
export function OperatorSignUpBodyFromJSON(json) {
    return OperatorSignUpBodyFromJSONTyped(json, false);
}
export function OperatorSignUpBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'username': json['username'],
        'password': json['password'],
        'confirmPassword': json['confirmPassword'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'certificationNumber': json['certificationNumber'],
        'crane': json['crane'],
        'securityCode': json['securityCode'],
    };
}
export function OperatorSignUpBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'username': value.username,
        'password': value.password,
        'confirmPassword': value.confirmPassword,
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'certificationNumber': value.certificationNumber,
        'crane': value.crane,
        'securityCode': value.securityCode,
    };
}
