/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
/**
 * Check if a given object implements the AddTimeSheetEntryBody interface.
 */
export function instanceOfAddTimeSheetEntryBody(value) {
    let isInstance = true;
    return isInstance;
}
export function AddTimeSheetEntryBodyFromJSON(json) {
    return AddTimeSheetEntryBodyFromJSONTyped(json, false);
}
export function AddTimeSheetEntryBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userID': !exists(json, 'userID') ? undefined : json['userID'],
        'isSickDay': !exists(json, 'isSickDay') ? undefined : json['isSickDay'],
        'timeIn': !exists(json, 'timeIn') ? undefined : json['timeIn'],
        'timeOut': !exists(json, 'timeOut') ? undefined : json['timeOut'],
        'jobDescription': !exists(json, 'jobDescription') ? undefined : json['jobDescription'],
        'lunchBreak': !exists(json, 'lunchBreak') ? undefined : json['lunchBreak'],
        'driveTime': !exists(json, 'driveTime') ? undefined : json['driveTime'],
    };
}
export function AddTimeSheetEntryBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userID': value.userID,
        'isSickDay': value.isSickDay,
        'timeIn': value.timeIn,
        'timeOut': value.timeOut,
        'jobDescription': value.jobDescription,
        'lunchBreak': value.lunchBreak,
        'driveTime': value.driveTime,
    };
}
