import React from "react";
import {ServiceReport} from "client";
import {Button} from "reactstrap";
import ViewServiceReportButton from "../../printables/buttons/ViewServiceReportButton";

interface IProps {
	report: ServiceReport;
}

const ManageServiceReportsViewFormCell: React.FC<IProps> = (props) => {

	return (
		<div className="d-flex justify-content-center">
			<ViewServiceReportButton id={props.report?._id}>
				View Service Report
			</ViewServiceReportButton>
		</div>
	);
};

export default ManageServiceReportsViewFormCell;
