/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the MoveEquipmentToSiteBody interface.
 */
export function instanceOfMoveEquipmentToSiteBody(value) {
    let isInstance = true;
    isInstance = isInstance && "siteID" in value;
    isInstance = isInstance && "equipmentIDs" in value;
    return isInstance;
}
export function MoveEquipmentToSiteBodyFromJSON(json) {
    return MoveEquipmentToSiteBodyFromJSONTyped(json, false);
}
export function MoveEquipmentToSiteBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'siteID': json['siteID'],
        'equipmentIDs': json['equipmentIDs'],
    };
}
export function MoveEquipmentToSiteBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'siteID': value.siteID,
        'equipmentIDs': value.equipmentIDs,
    };
}
