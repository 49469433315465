import React from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Token, User} from "client";
import {getTokenTypeDisplay} from "../../utils/getTokenTypeDisplay";
import SideBarNavigationLinkGenerator from "./SideBarNavigationLinkGenerator";
import {getFullNameForTable} from "../../utils/getFullNameForTable";

interface IProps {
	fullToken?: Token;
	currentUser?: User;
}

const SideBarInner: React.FC<IProps> = (props: IProps) => {

	return (
		<React.Fragment>
			<div className="sidebar-inner">
				<div className="sidebar-inner-header">
					<img
						src={process.env.PUBLIC_URL + "/logos/allied-logo.svg"}
					/>

					<h5>Logged in as {getFullNameForTable(props.currentUser)} ({getTokenTypeDisplay(props.fullToken?.type)})</h5>
				</div>

				<div className="sidebar-inner-divider"/>

				<div className="sidebar-inner-navigation">
					<SideBarNavigationLinkGenerator/>
				</div>
			</div>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		currentUser: store.metaStore.currentUser,
		...props,
	}
})(SideBarInner);
