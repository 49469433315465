/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaginationInfoFromJSON, PaginationInfoToJSON, } from './PaginationInfo';
import { ServiceReportFromJSON, ServiceReportToJSON, } from './ServiceReport';
/**
 * Check if a given object implements the GetServiceReportListResponse interface.
 */
export function instanceOfGetServiceReportListResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "paginationInfo" in value;
    isInstance = isInstance && "serviceReports" in value;
    return isInstance;
}
export function GetServiceReportListResponseFromJSON(json) {
    return GetServiceReportListResponseFromJSONTyped(json, false);
}
export function GetServiceReportListResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'paginationInfo': PaginationInfoFromJSON(json['paginationInfo']),
        'serviceReports': (json['serviceReports'].map(ServiceReportFromJSON)),
    };
}
export function GetServiceReportListResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'paginationInfo': PaginationInfoToJSON(value.paginationInfo),
        'serviceReports': (value.serviceReports.map(ServiceReportToJSON)),
    };
}
