import React, {ChangeEventHandler, useEffect, useState} from "react";
import {
	CreateFinalTestFormBody,
	CreateFinalTestFormResponse,
	Equipment,
	EquipmentApi,
	EquipmentType,
	FinalTestForm,
	FormsApi,
	FormType,
	Site,
	SitesApi,
	Token,
	UserType
} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {useHistory} from "react-router-dom";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import moment from "moment";
import AlliedContainer from "../AlliedContainer";
import AlliedPageHeader from "../AlliedPageHeader";
import AlliedFormContainer from "../AlliedFormContainer";
import {Button, Col, Row} from "reactstrap";
import AlliedAutoComplete from "../inputs/AlliedAutoComplete";
import {parseEquipmentForSelectDropdown} from "../../utils/parseEquipmentForSelectDropdown";
import AlliedDatePicker from "../inputs/AlliedDatePicker";
import AlliedRadioRow from "../inputs/AlliedRadioRow";
import {AlliedInputRadioFactory} from "../inputs/AlliedInputRadio";
import {convertFinalTestFormResponseToCreateBody} from "../../utils/convertFinalTestFormResponseToCreateBody";
import {userTypeCheck} from "../../utils/userTypeCheck";
import {cloneDeep, find} from "lodash";
import {parseSitesForAutoComplete} from "../../utils/parseSitesForAutoComplete";
import {convertOffsetDate} from "../../utils/timeZoneConversions";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	id?: string;
}

const defaultCreateFinalTestForm: CreateFinalTestFormBody = {
	equipmentID: "",
	siteID: "",
	date: moment().startOf("day").valueOf(),
	baseType: "",
	travelGear: "",
	towerHeight: "",
	heightUnderHook: "",
	jibLength: "",
	requiredPowerSupplyVolts: "",
	phase: "",
	amps: "",
	measuredPowerSupplyUnderLoad: "",
	noLoad: "",
	minimumFreeSlewRadius: "",
	trollyInLimitSlow: "",
	trollyInLimitFinal: "",
	trollyOutLimitSlow: "",
	trollyOutLimitFinal: "",
	hoistUpSlow: "",
	hoistUpFinal: "",
	hoistDownSlow: "",
	hoistDownFinal: "",
	travelInLimitSlow: "N/A",
	travelInLimitFinal: "N/A",
	travelOutLimitSlow: "N/A",
	travelOutLimitFinal: "N/A",
	slowingLimitInstalled: undefined,
	verifyTheCraneCannotMakeMoreThan2TurnsToEachSide: undefined,
	requiredWeightForTipLimit: "",
	primaryWeightUsedForTipLimit: "",
	kickerWeightUsedForTipLimit: "",
	requiredWeightForMaxLinePull: "",
	setPercentOfMaxLinePull: "",
	primaryWeightUsedForMaxLinePull: "",
	kickerWeightUsedForMaxLinePull: "",
	isCraneGrounded:undefined,
	deadManControlOperational: undefined,
	constantPressureControls: undefined,
	springLoadedControls: undefined,
	controlFunctionsTested: undefined,
	eStopFunctioning: undefined,
	operatorInformationDisplayInCab: undefined,
	displayCalibrated: undefined,
	zoningSystemInstalled: undefined,
	craneOperatorOriented: undefined,
	name: "",
	licenseNumber: "",
	comments: "",
}

const CreateFinalTestForm: React.FC<IProps> = (props) => {

	const history = useHistory();
	const [equipmentList, setEquipmentList] = useState<Array<Equipment>>([]);
	const [siteList, setSitesList] = useState<Array<Site>>([]);
	const [createFinalTestForm, setCreateFinalTestForm] = useState<CreateFinalTestFormBody>(defaultCreateFinalTestForm);
	const [rawEditingResponse, setRawEditingResponse] = useState<FinalTestForm>(undefined);

	useEffect(() => {
		getAutoCompleteValues().then().catch();

		if (props.id) {
			getFormForEditing().then().catch();
		}
	}, []);

	/**
	 * Call api to get list of equipment & sites for the auto-complete inputs.
	 *
	 */
	async function getAutoCompleteValues(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const equipmentRes = await new EquipmentApi(getConfig(props.fullToken)).getEquipmentList({
				type: [EquipmentType.CRANE],
				limit: 10000,
				offset: 0,
			});

			const sitesRes = await new SitesApi(getConfig(props.fullToken)).getSiteList({
				limit: 10000,
				offset: 0,
			});

			setEquipmentList(equipmentRes.equipment);
			setSitesList(sitesRes.sites);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Call api to get existing Final Test Form if props.id exists,
	 * and save it to the form object after formatting correctly.
	 *
	 */
	async function getFormForEditing(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FormsApi(getConfig(props.fullToken)).getFinalTestForm({
				finalTestFormID: props.id,
			});

			setCreateFinalTestForm(convertFinalTestFormResponseToCreateBody(res));
			setRawEditingResponse(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Handle the auto-complete onChange for the selected equipment,
	 * which returns an array, but we only want the first element.
	 *
	 * Also set the siteID to that of the selected equipment's,
	 * if siteID isn't already set on the form.
	 *
	 * @param equipment
	 */
	function equipmentOnChange(equipment: string[]): void {
		const newForm: CreateFinalTestFormBody = cloneDeep(createFinalTestForm);
		const isNewSelection: boolean = createFinalTestForm.equipmentID !== equipment[0];

		if (!newForm.siteID && isNewSelection) {
			newForm.siteID = find(equipmentList, ["_id", equipment[0]])?.site?._id;
		}

		setCreateFinalTestForm({
			...newForm,
			equipmentID: isNewSelection ? equipment[0] : "",
		});
	}

	/**
	 * Handle the auto-complete onChange for the selected site,
	 * which returns an array, but we only want the first element.
	 *
	 * @param site
	 */
	function siteOnChange(site: string[]): void {
		setCreateFinalTestForm({
			...createFinalTestForm,
			siteID: createFinalTestForm.siteID !== site[0] ? site[0] : "",
		});
	}

	/**
	 * onChange for the date input.
	 *
	 * @param key
	 */
	function onDateChange(key: keyof CreateFinalTestFormBody): (d: Date) => void {
		return (d: Date) => {
			setCreateFinalTestForm({
				...createFinalTestForm,
				[key]: moment(d).valueOf(),
			});
		}
	}

	/**
	 * Dynamic onChange for all of the standard text inputs.
	 *
	 * @param key
	 */
	function textInputOnChange(key: keyof CreateFinalTestFormBody): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> {
		return (e) => {
			setCreateFinalTestForm({
				...createFinalTestForm,
				[key]: e?.target?.value,
			});
		}
	}

	/**
	 * Dynamic onChange for all of the radio inputs.
	 *
	 * @param key
	 * @param toggle
	 */
	function onRadioInputChange(key: keyof CreateFinalTestFormBody, toggle: boolean): () => void {
		return () => {
			setCreateFinalTestForm({
				...createFinalTestForm,
				[key]: toggle,
			});
		}
	}

	/**
	 * Call api to either submit new Final Test Form or update existing and return id.
	 *
	 * @param e
	 */
	async function saveFinalTestForm(e?: React.SyntheticEvent): Promise<string> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		let createResponse: CreateFinalTestFormResponse;

		try {
			// Update fields to be submitted to api properly
			let req: CreateFinalTestFormBody = {
				equipmentID: createFinalTestForm.equipmentID ? createFinalTestForm.equipmentID : undefined,
				siteID: createFinalTestForm.siteID ? createFinalTestForm.siteID : undefined,
				date: createFinalTestForm.date !== undefined ? convertOffsetDate(createFinalTestForm.date) : undefined,
				baseType: createFinalTestForm.baseType ? createFinalTestForm.baseType : undefined,
				travelGear: createFinalTestForm.travelGear ? createFinalTestForm.travelGear : undefined,
				towerHeight: createFinalTestForm.towerHeight ? createFinalTestForm.towerHeight : undefined,
				heightUnderHook: createFinalTestForm.heightUnderHook ? createFinalTestForm.heightUnderHook : undefined,
				jibLength: createFinalTestForm.jibLength ? createFinalTestForm.jibLength : undefined,
				requiredPowerSupplyVolts: createFinalTestForm.requiredPowerSupplyVolts ? createFinalTestForm.requiredPowerSupplyVolts : undefined,
				phase: createFinalTestForm.phase ? createFinalTestForm.phase : undefined,
				amps: createFinalTestForm.amps ? createFinalTestForm.amps : undefined,
				measuredPowerSupplyUnderLoad: createFinalTestForm.measuredPowerSupplyUnderLoad ? createFinalTestForm.measuredPowerSupplyUnderLoad : undefined,
				noLoad: createFinalTestForm.noLoad ? createFinalTestForm.noLoad : undefined,
				minimumFreeSlewRadius: createFinalTestForm.minimumFreeSlewRadius ? createFinalTestForm.minimumFreeSlewRadius : undefined,
				trollyInLimitSlow: createFinalTestForm.trollyInLimitSlow ? createFinalTestForm.trollyInLimitSlow : undefined,
				trollyInLimitFinal: createFinalTestForm.trollyInLimitFinal ? createFinalTestForm.trollyInLimitFinal : undefined,
				trollyOutLimitSlow: createFinalTestForm.trollyOutLimitSlow ? createFinalTestForm.trollyOutLimitSlow : undefined,
				trollyOutLimitFinal: createFinalTestForm.trollyOutLimitFinal ? createFinalTestForm.trollyOutLimitFinal : undefined,
				hoistUpSlow: createFinalTestForm.hoistUpSlow ? createFinalTestForm.hoistUpSlow : undefined,
				hoistUpFinal: createFinalTestForm.hoistUpFinal ? createFinalTestForm.hoistUpFinal : undefined,
				hoistDownSlow: createFinalTestForm.hoistDownSlow ? createFinalTestForm.hoistDownSlow : undefined,
				hoistDownFinal: createFinalTestForm.hoistDownFinal ? createFinalTestForm.hoistDownFinal : undefined,
				travelInLimitSlow: createFinalTestForm.travelInLimitSlow ? createFinalTestForm.travelInLimitSlow : undefined,
				travelInLimitFinal: createFinalTestForm.travelInLimitFinal ? createFinalTestForm.travelInLimitFinal : undefined,
				travelOutLimitSlow: createFinalTestForm.travelOutLimitSlow ? createFinalTestForm.travelOutLimitSlow : undefined,
				travelOutLimitFinal: createFinalTestForm.travelOutLimitFinal ? createFinalTestForm.travelOutLimitFinal : undefined,
				slowingLimitInstalled: createFinalTestForm.slowingLimitInstalled !== undefined ? createFinalTestForm.slowingLimitInstalled : undefined,
				verifyTheCraneCannotMakeMoreThan2TurnsToEachSide: createFinalTestForm.verifyTheCraneCannotMakeMoreThan2TurnsToEachSide !== undefined ? createFinalTestForm.verifyTheCraneCannotMakeMoreThan2TurnsToEachSide : undefined,
				requiredWeightForTipLimit: createFinalTestForm.requiredWeightForTipLimit ? createFinalTestForm.requiredWeightForTipLimit : undefined,
				primaryWeightUsedForTipLimit: createFinalTestForm.primaryWeightUsedForTipLimit ? createFinalTestForm.primaryWeightUsedForTipLimit : undefined,
				kickerWeightUsedForTipLimit: createFinalTestForm.kickerWeightUsedForTipLimit ? createFinalTestForm.kickerWeightUsedForTipLimit : undefined,
				requiredWeightForMaxLinePull: createFinalTestForm.requiredWeightForMaxLinePull ? createFinalTestForm.requiredWeightForMaxLinePull : undefined,
				setPercentOfMaxLinePull: createFinalTestForm.setPercentOfMaxLinePull ? createFinalTestForm.setPercentOfMaxLinePull : undefined,
				primaryWeightUsedForMaxLinePull: createFinalTestForm.primaryWeightUsedForMaxLinePull ? createFinalTestForm.primaryWeightUsedForMaxLinePull : undefined,
				kickerWeightUsedForMaxLinePull: createFinalTestForm.kickerWeightUsedForMaxLinePull ? createFinalTestForm.kickerWeightUsedForMaxLinePull : undefined,
				isCraneGrounded: createFinalTestForm.isCraneGrounded !== undefined ? createFinalTestForm.isCraneGrounded : undefined,
				deadManControlOperational: createFinalTestForm.deadManControlOperational !== undefined ? createFinalTestForm.deadManControlOperational : undefined,
				constantPressureControls: createFinalTestForm.constantPressureControls !== undefined ? createFinalTestForm.constantPressureControls : undefined,
				springLoadedControls: createFinalTestForm.springLoadedControls !== undefined ? createFinalTestForm.springLoadedControls : undefined,
				controlFunctionsTested: createFinalTestForm.controlFunctionsTested !== undefined ? createFinalTestForm.controlFunctionsTested : undefined,
				eStopFunctioning: createFinalTestForm.eStopFunctioning !== undefined ? createFinalTestForm.eStopFunctioning : undefined,
				operatorInformationDisplayInCab: createFinalTestForm.operatorInformationDisplayInCab !== undefined ? createFinalTestForm.operatorInformationDisplayInCab : undefined,
				displayCalibrated: createFinalTestForm.displayCalibrated !== undefined ? createFinalTestForm.displayCalibrated : undefined,
				zoningSystemInstalled: createFinalTestForm.zoningSystemInstalled !== undefined ? createFinalTestForm.zoningSystemInstalled : undefined,
				craneOperatorOriented: createFinalTestForm.craneOperatorOriented !== undefined ? createFinalTestForm.craneOperatorOriented : undefined,
				// name: createFinalTestForm.name ? createFinalTestForm.name : undefined,
				// licenseNumber: createFinalTestForm.licenseNumber ? createFinalTestForm.licenseNumber : undefined,
				comments: createFinalTestForm.comments ? createFinalTestForm.comments : undefined,
			}

			if (props.id) {
				await new FormsApi(getConfig(props.fullToken)).updateFinalTestForm({
					updateFinalTestFormBody: {
						...req,
						formID: props.id,
					},
				});
			} else {
				createResponse = await new FormsApi(getConfig(props.fullToken)).createFinalTestForm({
					createFinalTestFormBody: req,
				});
			}

		} catch (err) {
			props.dispatch(addError(err));
			props.dispatch(decrementLoading());
			throw err;
		}

		props.dispatch(decrementLoading());

		// Return the newly created id if we're not editing.
		if (!props.id && createResponse) {
			return createResponse?.finalTestFormID;
		}
	}

	/**
	 * User when creating or updating to save the draft.
	 * Afterwards, navigate to the appropriate page based on usertype.
	 *
	 * @param e
	 */
	async function saveDraft(e?: React.SyntheticEvent): Promise<void> {
		e?.preventDefault();
		try {
			await saveFinalTestForm();
			if (props.fullToken?.type === UserType.ALLIED_CRANE_EMPLOYEE) {
				history.push("/form-history/final-test-forms-history");
			} else if (props.fullToken?.type === UserType.ADMIN) {
				history.push("/allied-crane-employee-forms/final-test-forms");
			}
		} catch (e) {

		}
	}

	/**
	 * Save & submit for approval.
	 * Calls the save function to get an id used to submit afterwards - if one doesn't exist from props.
	 *
	 * Should only be possible to be called by employees.
	 * (admins can't submit - only publish, which they do via the table).
	 *
	 * @param e
	 */
	async function saveAndSubmitFinalTestForm(e?: React.SyntheticEvent): Promise<void> {
		e?.preventDefault();
		const newId: string = await saveFinalTestForm();
		props.dispatch(incrementLoading());

		try {
			await new FormsApi(getConfig(props.fullToken)).submitForm({
				iDBody: {
					id: props.id ? props.id : newId,
				},
			});

			if (props.fullToken?.type === UserType.ALLIED_CRANE_EMPLOYEE) {
				history.push("/form-history/final-test-forms-history");
			} else if (props.fullToken?.type === UserType.ADMIN) {
				history.push("/allied-crane-employee-forms/final-test-forms");
			}
		} catch (e) {
			props.dispatch(addError(e));

			if (!props.id) {
				props.dispatch(decrementLoading());
				history.push(`/edit-forms/edit-final-test-form?id=${newId}`);
			}
		}

		props.dispatch(decrementLoading());
	}

	return (
		<AlliedContainer>
			<AlliedPageHeader
				title={`${props.id ? "Edit" : "Create"} Final Test Form`}
				showTooltip={false}
			/>

			<hr/>

			<AlliedFormContainer>
				<form onSubmit={saveDraft}>
					<Row>
						<Col xs={12} md={6} className="mb-3">
							<label>Select Equipment</label>
							<AlliedAutoComplete
								placeholder="Select Equipment..."
								options={parseEquipmentForSelectDropdown(equipmentList)}
								selections={createFinalTestForm.equipmentID ? [createFinalTestForm.equipmentID] : []}
								setSelections={equipmentOnChange}
								multiSelect={false}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={6} className="mb-3">
							<label>Select Site</label>
							<AlliedAutoComplete
								placeholder="Select Site..."
								options={parseSitesForAutoComplete(siteList)}
								selections={createFinalTestForm.siteID ? [createFinalTestForm.siteID] : []}
								setSelections={siteOnChange}
								multiSelect={false}
							/>
						</Col>
					</Row>

					<hr/>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Date</label>
							<AlliedDatePicker
								selected={createFinalTestForm.date}
								onChange={onDateChange("date")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Base Type</label>
							<input
								placeholder="Enter Base Type..."
								value={createFinalTestForm.baseType}
								onChange={textInputOnChange("baseType")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Travel Gear</label>
							<input
								placeholder="Enter Travel Gear..."
								value={createFinalTestForm.travelGear}
								onChange={textInputOnChange("travelGear")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Tower Height</label>
							<input
								placeholder="Enter Tower Height..."
								value={createFinalTestForm.towerHeight}
								onChange={textInputOnChange("towerHeight")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Height Under Hook</label>
							<input
								placeholder="Enter Height Under Hook..."
								value={createFinalTestForm.heightUnderHook}
								onChange={textInputOnChange("heightUnderHook")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Jib Length</label>
							<input
								placeholder="Enter Jib Length..."
								value={createFinalTestForm.jibLength}
								onChange={textInputOnChange("jibLength")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<label>Required Power Supply Volts</label>
						</Col>
						<Col xs={12} md={4} className="mb-3">
							<input
								placeholder="Enter Required Power Supply Volts..."
								value={createFinalTestForm.requiredPowerSupplyVolts}
								onChange={textInputOnChange("requiredPowerSupplyVolts")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Phase</label>
							<input
								placeholder="Enter Phase..."
								value={createFinalTestForm.phase}
								onChange={textInputOnChange("phase")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Amps</label>
							<input
								placeholder="Enter Amps..."
								value={createFinalTestForm.amps}
								onChange={textInputOnChange("amps")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<label>Measured Power Supply Under Load</label>
						</Col>
						<Col xs={12} md={4} className="mb-3">
							<input
								placeholder="Enter Measured Power Supply Under Load..."
								value={createFinalTestForm.measuredPowerSupplyUnderLoad}
								onChange={textInputOnChange("measuredPowerSupplyUnderLoad")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Measured Power Supply with No Load</label>
							<input
								placeholder="Enter Measured Power Supply with No Load..."
								value={createFinalTestForm.noLoad}
								onChange={textInputOnChange("noLoad")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Minimum Free Slew Radius</label>
							<input
								placeholder="Enter Minimum Free Slew Radius..."
								value={createFinalTestForm.minimumFreeSlewRadius}
								onChange={textInputOnChange("minimumFreeSlewRadius")}
							/>
						</Col>
					</Row>

					<h5 className="font-weight-bold">Crane Limits</h5>

					<Row>
						<Col xs={12}>
							<label>Trolly In Limit</label>
						</Col>
						<Col xs={6} md={3} className="mb-3">
							<input
								placeholder="Slow..."
								value={createFinalTestForm.trollyInLimitSlow}
								onChange={textInputOnChange("trollyInLimitSlow")}
							/>
						</Col>
						<Col xs={6} md={3} className="mb-3">
							<input
								placeholder="Final..."
								value={createFinalTestForm.trollyInLimitFinal}
								onChange={textInputOnChange("trollyInLimitFinal")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<label>Trolly Out Limit</label>
						</Col>
						<Col xs={6} md={3} className="mb-3">
							<input
								placeholder="Slow..."
								value={createFinalTestForm.trollyOutLimitSlow}
								onChange={textInputOnChange("trollyOutLimitSlow")}
							/>
						</Col>
						<Col xs={6} md={3} className="mb-3">
							<input
								placeholder="Final..."
								value={createFinalTestForm.trollyOutLimitFinal}
								onChange={textInputOnChange("trollyOutLimitFinal")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<label>Hoist Up (1m)</label>
						</Col>
						<Col xs={6} md={3} className="mb-3">
							<input
								placeholder="Slow..."
								value={createFinalTestForm.hoistUpSlow}
								onChange={textInputOnChange("hoistUpSlow")}
							/>
						</Col>
						<Col xs={6} md={3} className="mb-3">
							<input
								placeholder="Final..."
								value={createFinalTestForm.hoistUpFinal}
								onChange={textInputOnChange("hoistUpFinal")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<label>Hoist Down</label>
						</Col>
						<Col xs={6} md={3} className="mb-3">
							<input
								placeholder="Slow..."
								value={createFinalTestForm.hoistDownSlow}
								onChange={textInputOnChange("hoistDownSlow")}
							/>
						</Col>
						<Col xs={6} md={3} className="mb-3">
							<input
								placeholder="Final..."
								value={createFinalTestForm.hoistDownFinal}
								onChange={textInputOnChange("hoistDownFinal")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<label>Travel In Limit</label>
						</Col>
						<Col xs={6} md={3} className="mb-3">
							<input
								placeholder="Slow..."
								value={createFinalTestForm.travelInLimitSlow}
								onChange={textInputOnChange("travelInLimitSlow")}
							/>
						</Col>
						<Col xs={6} md={3} className="mb-3">
							<input
								placeholder="Final..."
								value={createFinalTestForm.travelInLimitFinal}
								onChange={textInputOnChange("travelInLimitFinal")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<label>Travel Out Limit</label>
						</Col>
						<Col xs={6} md={3} className="mb-3">
							<input
								placeholder="Slow..."
								value={createFinalTestForm.travelOutLimitSlow}
								onChange={textInputOnChange("travelOutLimitSlow")}
							/>
						</Col>
						<Col xs={6} md={3} className="mb-3">
							<input
								placeholder="Final..."
								value={createFinalTestForm.travelOutLimitFinal}
								onChange={textInputOnChange("travelOutLimitFinal")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} className="mb-3">
							<label>Slewing Limit Installed</label>
							<AlliedRadioRow>
								<AlliedInputRadioFactory
									radios={[
										{
											label: "Yes",
											name: "Yes Slewing Limit Installed",
											checked: createFinalTestForm.slowingLimitInstalled === true,
											onChange: onRadioInputChange("slowingLimitInstalled", true),
										},
										{
											label: "No",
											name: "No Slewing Limit Installed",
											checked: createFinalTestForm.slowingLimitInstalled === false,
											onChange: onRadioInputChange("slowingLimitInstalled", false),
										}
									]}
								/>
							</AlliedRadioRow>
						</Col>
					</Row>

					<Row>
						<Col xs={12} className="mb-3">
							<label>Verify the crane cannot make more than 2 turns to each side</label>
							<AlliedRadioRow>
								<AlliedInputRadioFactory
									radios={[
										{
											label: "Yes",
											name: "Yes Verify Crane Cannot Make More Than 2 Turns",
											checked: createFinalTestForm.verifyTheCraneCannotMakeMoreThan2TurnsToEachSide === true,
											onChange: onRadioInputChange("verifyTheCraneCannotMakeMoreThan2TurnsToEachSide", true),
										},
										{
											label: "No",
											name: "No Verify Crane Cannot Make More Than 2 Turns",
											checked: createFinalTestForm.verifyTheCraneCannotMakeMoreThan2TurnsToEachSide === false,
											onChange: onRadioInputChange("verifyTheCraneCannotMakeMoreThan2TurnsToEachSide", false),
										}
									]}
								/>
							</AlliedRadioRow>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<label>Required Weight for Tip Limit</label>
						</Col>
						<Col xs={12} md={4} className="mb-3">
							<input
								placeholder="Enter Required Weight..."
								value={createFinalTestForm.requiredWeightForTipLimit}
								onChange={textInputOnChange("requiredWeightForTipLimit")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<label>Primary Weight used for Tip Limit</label>
						</Col>
						<Col xs={12} md={4} className="mb-3">
							<input
								placeholder="Enter Primary Weight..."
								value={createFinalTestForm.primaryWeightUsedForTipLimit}
								onChange={textInputOnChange("primaryWeightUsedForTipLimit")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<label>Kicker Weight used for Tip Limit</label>
						</Col>
						<Col xs={12} md={4} className="mb-3">
							<input
								placeholder="Enter Kicker Weight..."
								value={createFinalTestForm.kickerWeightUsedForTipLimit}
								onChange={textInputOnChange("kickerWeightUsedForTipLimit")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<label>Required Weight for Max Line Pull</label>
						</Col>
						<Col xs={12} md={4} className="mb-3">
							<input
								placeholder="Enter Required Weight..."
								value={createFinalTestForm.requiredWeightForMaxLinePull}
								onChange={textInputOnChange("requiredWeightForMaxLinePull")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<label>Set % of Max Line Pull</label>
						</Col>
						<Col xs={12} md={4} className="mb-3">
							<input
								placeholder="Enter Set Percent..."
								value={createFinalTestForm.setPercentOfMaxLinePull}
								onChange={textInputOnChange("setPercentOfMaxLinePull")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<label>Primary Weight used for Max Line Pull</label>
						</Col>
						<Col xs={12} md={4} className="mb-3">
							<input
								placeholder="Enter Primary Weight..."
								value={createFinalTestForm.primaryWeightUsedForMaxLinePull}
								onChange={textInputOnChange("primaryWeightUsedForMaxLinePull")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<label>Kicker Weight used for Max Line Pull</label>
						</Col>
						<Col xs={12} md={4} className="mb-3">
							<input
								placeholder="Enter Kicker Weight..."
								value={createFinalTestForm.kickerWeightUsedForMaxLinePull}
								onChange={textInputOnChange("kickerWeightUsedForMaxLinePull")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} className="mb-3">
							<label>Is the crane grounded?</label>
							<AlliedRadioRow>
								<AlliedInputRadioFactory
									radios={[
										{
											label: "Yes",
											name: "Yes Crane Is Grounded",
											checked: createFinalTestForm.isCraneGrounded === true,
											onChange: onRadioInputChange("isCraneGrounded", true),
										},
										{
											label: "No",
											name: "No Crane Is Not Grounded",
											checked: createFinalTestForm.isCraneGrounded === false,
											onChange: onRadioInputChange("isCraneGrounded", false),
										}
									]}
								/>
							</AlliedRadioRow>
						</Col>
					</Row>

					<Row>
						<Col xs={12} className="mb-3">
							<label>Dead Man Control Operational</label>
							<AlliedRadioRow>
								<AlliedInputRadioFactory
									radios={[
										{
											label: "Yes",
											name: "Yes Dead Man Control Operational",
											checked: createFinalTestForm.deadManControlOperational === true,
											onChange: onRadioInputChange("deadManControlOperational", true),
										},
										{
											label: "No",
											name: "No Dead Man Control Operational",
											checked: createFinalTestForm.deadManControlOperational === false,
											onChange: onRadioInputChange("deadManControlOperational", false),
										}
									]}
								/>
							</AlliedRadioRow>
						</Col>
					</Row>

					<Row>
						<Col xs={12} className="mb-3">
							<label>Constant Pressure Controls</label>
							<AlliedRadioRow>
								<AlliedInputRadioFactory
									radios={[
										{
											label: "Yes",
											name: "Yes Constant Pressure Controls",
											checked: createFinalTestForm.constantPressureControls === true,
											onChange: onRadioInputChange("constantPressureControls", true),
										},
										{
											label: "No",
											name: "No Constant Pressure Controls",
											checked: createFinalTestForm.constantPressureControls === false,
											onChange: onRadioInputChange("constantPressureControls", false),
										}
									]}
								/>
							</AlliedRadioRow>
						</Col>
					</Row>

					<Row>
						<Col xs={12} className="mb-3">
							<label>Spring Loaded Controls</label>
							<AlliedRadioRow>
								<AlliedInputRadioFactory
									radios={[
										{
											label: "Yes",
											name: "Yes Spring Loaded Controls",
											checked: createFinalTestForm.springLoadedControls === true,
											onChange: onRadioInputChange("springLoadedControls", true),
										},
										{
											label: "No",
											name: "No Spring Loaded Controls",
											checked: createFinalTestForm.springLoadedControls === false,
											onChange: onRadioInputChange("springLoadedControls", false),
										}
									]}
								/>
							</AlliedRadioRow>
						</Col>
					</Row>

					<Row>
						<Col xs={12} className="mb-3">
							<label>Control Functions Tested/Correct</label>
							<AlliedRadioRow>
								<AlliedInputRadioFactory
									radios={[
										{
											label: "Yes",
											name: "Yes Control Functions Tested/Correct",
											checked: createFinalTestForm.controlFunctionsTested === true,
											onChange: onRadioInputChange("controlFunctionsTested", true),
										},
										{
											label: "No",
											name: "No Control Functions Tested/Correct",
											checked: createFinalTestForm.controlFunctionsTested === false,
											onChange: onRadioInputChange("controlFunctionsTested", false),
										}
									]}
								/>
							</AlliedRadioRow>
						</Col>
					</Row>

					<Row>
						<Col xs={12} className="mb-3">
							<label>"E" Stop Functioning</label>
							<AlliedRadioRow>
								<AlliedInputRadioFactory
									radios={[
										{
											label: "Yes",
											name: "Yes E Stop Functioning",
											checked: createFinalTestForm.eStopFunctioning === true,
											onChange: onRadioInputChange("eStopFunctioning", true),
										},
										{
											label: "No",
											name: "No E Stop Functioning",
											checked: createFinalTestForm.eStopFunctioning === false,
											onChange: onRadioInputChange("eStopFunctioning", false),
										}
									]}
								/>
							</AlliedRadioRow>
						</Col>
					</Row>

					<Row>
						<Col xs={12} className="mb-3">
							<label>Operator Information Display in Cab</label>
							<AlliedRadioRow>
								<AlliedInputRadioFactory
									radios={[
										{
											label: "Yes",
											name: "Yes Operator Information Display in Cab",
											checked: createFinalTestForm.operatorInformationDisplayInCab === true,
											onChange: onRadioInputChange("operatorInformationDisplayInCab", true),
										},
										{
											label: "No",
											name: "No Operator Information Display in Cab",
											checked: createFinalTestForm.operatorInformationDisplayInCab === false,
											onChange: onRadioInputChange("operatorInformationDisplayInCab", false),
										}
									]}
								/>
							</AlliedRadioRow>
						</Col>
					</Row>

					<Row>
						<Col xs={12} className="mb-3">
							<label>Display Calibrated/Functioning</label>
							<AlliedRadioRow>
								<AlliedInputRadioFactory
									radios={[
										{
											label: "Yes",
											name: "Yes Display Calibrated/Functioning",
											checked: createFinalTestForm.displayCalibrated === true,
											onChange: onRadioInputChange("displayCalibrated", true),
										},
										{
											label: "No",
											name: "No Display Calibrated/Functioning",
											checked: createFinalTestForm.displayCalibrated === false,
											onChange: onRadioInputChange("displayCalibrated", false),
										}
									]}
								/>
							</AlliedRadioRow>
						</Col>
					</Row>

					<h5 className="font-weight-bold">Additional Feature</h5>

					<Row>
						<Col xs={12} className="mb-3">
							<label>Zoning System Installed</label>
							<AlliedRadioRow>
								<AlliedInputRadioFactory
									radios={[
										{
											label: "Yes",
											name: "Yes Zoning System Installed",
											checked: createFinalTestForm.zoningSystemInstalled === true,
											onChange: onRadioInputChange("zoningSystemInstalled", true),
										},
										{
											label: "No",
											name: "No Zoning System Installed",
											checked: createFinalTestForm.zoningSystemInstalled === false,
											onChange: onRadioInputChange("zoningSystemInstalled", false),
										}
									]}
								/>
							</AlliedRadioRow>
						</Col>
					</Row>

					<h5 className="font-weight-bold">Operator Details</h5>

					<Row>
						<Col xs={12} className="mb-3">
							<label>Crane Operator Oriented</label>
							<AlliedRadioRow>
								<AlliedInputRadioFactory
									radios={[
										{
											label: "Yes",
											name: "Yes Crane Operator Oriented",
											checked: createFinalTestForm.craneOperatorOriented === true,
											onChange: onRadioInputChange("craneOperatorOriented", true),
										},
										{
											label: "No",
											name: "No Crane Operator Oriented",
											checked: createFinalTestForm.craneOperatorOriented === false,
											onChange: onRadioInputChange("craneOperatorOriented", false),
										}
									]}
								/>
							</AlliedRadioRow>
						</Col>
					</Row>

					{/*<Row>*/}
					{/*	<Col xs={12}>*/}
					{/*		<label>Name</label>*/}
					{/*	</Col>*/}
					{/*	<Col xs={12} md={4} className="mb-3">*/}
					{/*		<input*/}
					{/*			placeholder="Enter Operator Name..."*/}
					{/*			value={createFinalTestForm.name}*/}
					{/*			onChange={textInputOnChange("name")}*/}
					{/*		/>*/}
					{/*	</Col>*/}
					{/*</Row>*/}

					{/*<Row>*/}
					{/*	<Col xs={12}>*/}
					{/*		<label>License Number</label>*/}
					{/*	</Col>*/}
					{/*	<Col xs={12} md={4} className="mb-3">*/}
					{/*		<input*/}
					{/*			placeholder="Enter License Number..."*/}
					{/*			value={createFinalTestForm.licenseNumber}*/}
					{/*			onChange={textInputOnChange("licenseNumber")}*/}
					{/*		/>*/}
					{/*	</Col>*/}
					{/*</Row>*/}

					<Row>
						<Col xs={12}>
							<label>Comments</label>
						</Col>
						<Col xs={12} md={6} className="mb-3">
							<textarea
								placeholder="Enter Comments..."
								value={createFinalTestForm.comments}
								onChange={textInputOnChange("comments")}
							/>
						</Col>
					</Row>

					<div className="d-flex flex-column align-items-center flex-sm-row">
						<Button
							color="black"
							type="submit"
							onClick={saveDraft}
							className="mr-0 mr-sm-3 mb-3 mb-sm-0"
						>
							Save Draft
						</Button>
						{userTypeCheck([UserType.ALLIED_CRANE_EMPLOYEE], props.fullToken) && (
							!props.id ||
							rawEditingResponse?.submittedDate === undefined
						) && (
							<Button
								color="red"
								onClick={saveAndSubmitFinalTestForm}
							>
								Save & Submit for Approval
							</Button>
						)}

						{userTypeCheck([UserType.ADMIN], props.fullToken) && (
							!props.id ||
							rawEditingResponse?.submittedDate === undefined
						) && (
							<Button
								color="red"
								onClick={saveAndSubmitFinalTestForm}
							>
								Save & Submit
							</Button>
						)}
					</div>
				</form>
			</AlliedFormContainer>
		</AlliedContainer>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(CreateFinalTestForm);
