import React, {ChangeEventHandler, useEffect, useState} from "react";
import {
	Crane,
	CraneOperator,
	CraneType,
	CreateCraneDailyLogBody,
	CreateLogCommentBody,
	CreateLuffingTowerCraneDailyLogBody,
	CreateSelfErectingCraneDailyLogBody,
	CreateTowerCraneDailyLogBody,
	EquipmentApi,
	EquipmentType,
	FormsApi,
	InspectionStatus,
	Site,
	SitesApi,
	Token,
	User,
	UsersApi,
	UserType,
} from "client";
import moment from "moment";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {useHistory} from "react-router-dom";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {cloneDeep, find} from "lodash";
import {getFullNameForTable} from "../../utils/getFullNameForTable";
import AlliedContainer from "../AlliedContainer";
import AlliedPageHeader from "../AlliedPageHeader";
import AlliedFormContainer from "../AlliedFormContainer";
import {Button, Col, Row} from "reactstrap";
import AlliedAutoComplete from "../inputs/AlliedAutoComplete";
import {parseEquipmentForSelectDropdown} from "../../utils/parseEquipmentForSelectDropdown";
import AlliedDatePicker from "../inputs/AlliedDatePicker";
import AlliedSelectInputList from "../inputs/inputList/AlliedSelectInputList";
import {
	convertCraneDailyInspectionItemsToInputListItems
} from "../../utils/convertCraneDailyInspectionItemToInputListItem";
import {getCraneDailyLogInspectionItems} from "../../utils/getCraneDailyLogInspectionItems";
import NumberFormat from "react-number-format";
import AlliedGenericInputList from "../inputs/AlliedGenericInputList";
import DailyLogCommentInput from "../inputs/DailyLogCommentInput";
import {parseSitesForAutoComplete} from "../../utils/parseSitesForAutoComplete";
import {convertOffsetDate} from "../../utils/timeZoneConversions";
import {parseUsersForAutoComplete} from "../../utils/parseUsersForAutoComplete";

export const defaultCreateCraneDailyLogForm: CreateCraneDailyLogBody = {
	users: [],
	equipmentID: "",
	siteID: "",
	date: moment().startOf("day").valueOf(),
	initials: false,
	hours: undefined,
	createLogCommentBodies: [{
		flagged: false,
		comment: "",
	}],
};

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	id?: string;
}

const CreateCraneDailyLogForm: React.FC<IProps> = (props) => {

	const history = useHistory();
	const [craneList, setCraneList] = useState<Array<Crane>>([]);
	const [siteList, setSitesList] = useState<Array<Site>>([]);
	const [operatorsList, setOperatorsList] = useState<Array<CraneOperator>>([]);
	const [createCraneDailyLogForm, setCreateCraneDailyLogForm] = useState<CreateCraneDailyLogBody>(defaultCreateCraneDailyLogForm);
	const [userProfile, setUserProfile] = useState<User>(undefined); // Used for making the user enter their name as a signature (matching the name).
	const [signature, setSignature] = useState(""); // The "signature" as entered by the user; not submitted to api but used to check for the "initials" (boolean) field.

	useEffect(() => {
		getAutoCompleteValues().then().catch();
	}, []);

	useEffect(() => {
		if (
			createCraneDailyLogForm.users.indexOf(userProfile?._id) === -1
			&& createCraneDailyLogForm.siteID
			&& createCraneDailyLogForm.equipmentID
		) {
			setCreateCraneDailyLogForm({
				...createCraneDailyLogForm,
				users: [userProfile?._id]
			});
		}
	}, [createCraneDailyLogForm?.siteID, createCraneDailyLogForm?.equipmentID]);

	/**
	 * Call api to get the list of cranes for the auto-complete,
	 * and the user's profile for the signature match.
	 *
	 */
	async function getAutoCompleteValues(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const cranesRes = await new EquipmentApi(getConfig(props.fullToken)).getEquipmentList({
				limit: 10000,
				offset: 0,
				type: [EquipmentType.CRANE],
			});

			const sitesRes = await new SitesApi(getConfig(props.fullToken)).getSiteList({
				limit: 10000,
				offset: 0,
			});

			const operatorsRes = await new UsersApi(getConfig(props.fullToken)).getUserList({
				limit: 200000,
				offset: 0,
			});

			const userRes = await new UsersApi(getConfig(props.fullToken)).getProfile();

			setCraneList(cranesRes.equipment as Array<Crane>);
			setSitesList(sitesRes.sites);
			setOperatorsList(operatorsRes.users as Array<CraneOperator>);
			setUserProfile(userRes.user);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Handle the auto-complete onChange for the selected equipment, which returns an array, but we only want the first element.
	 * Also, each time we change the selected crane, let's delete all the inspection items and they'll be reset when needed.
	 *
	 * Also set the siteID to that of the selected equipment's, if siteID isn't already set on the form.
	 *
	 * @param equipment
	 */
	function equipmentOnChange(equipment: string[]): void {
		const clonedForm: any = cloneDeep(createCraneDailyLogForm);
		const isNewSelection: boolean = createCraneDailyLogForm.equipmentID !== equipment[0];

		if (!clonedForm.siteID && isNewSelection) {
			clonedForm.siteID = find(craneList, ["_id", equipment[0]])?.site?._id;
		}

		// Generic
		delete clonedForm.electricalPowerCords;
		delete clonedForm.groundFaultCircuitInterrupter;
		delete clonedForm.onOffSwitch;
		delete clonedForm.craneBaseInspection;
		delete clonedForm.inspectWalkways;
		delete clonedForm.inspectStructure;
		delete clonedForm.ensureAllDoors;
		delete clonedForm.operatorsControlsAdequate;
		delete clonedForm.loadMomentHoistLimit;
		delete clonedForm.confirmLocationOfZoning;
		delete clonedForm.maximumLoad;
		delete clonedForm.hoistUpDecelerationLimit;
		delete clonedForm.hoistUpperLimit;
		delete clonedForm.hoistDownLimit;
		delete clonedForm.ensureAllAudioVisualIndicatorsFunction;
		delete clonedForm.anemometer;
		delete clonedForm.hoistBrakeFunctioning;
		delete clonedForm.slewingBrakeFunctioning;
		delete clonedForm.visuallyInspectLoadBlockAndHook;
		delete clonedForm.travelBrakeToRail;
		delete clonedForm.railTravelForwardAndReverse;
		delete clonedForm.inspectTracksForLoseConnections;
		delete clonedForm.housekeeping;
		delete clonedForm.supervisorNotedOfAnyDefectsOrFaults;

		// Luffing
		delete clonedForm.ensureAllTower;
		delete clonedForm.loadMomentLuffingLimit;
		delete clonedForm.luffingUpSwitch;
		delete clonedForm.luffingDownSwitch;
		delete clonedForm.luffingUpAndDownDecelerationSwitch;
		delete clonedForm.boomAngleIndicator;
		delete clonedForm.counterweightSafetySwitch;
		delete clonedForm.antiTwoBlockSwitch;
		delete clonedForm.luffingBrake;

		// Self Erecting
		delete clonedForm.loadMomentTrolleyLimit;
		delete clonedForm.trolleyOut;
		delete clonedForm.trolleyIn;
		delete clonedForm.trolleyBrake;
		delete clonedForm.baseLevel;
		delete clonedForm.foundationCondition;

		setCreateCraneDailyLogForm({
			...clonedForm,
			equipmentID: isNewSelection ? equipment[0] : "",
			users: [],
		});
	}

	/**
	 * Handle the auto-complete onChange for the selected site,
	 * which returns an array, but we only want the first element.
	 *
	 * @param site
	 */
	function siteOnChange(site: string[]): void {
		setCreateCraneDailyLogForm({
			...createCraneDailyLogForm,
			siteID: createCraneDailyLogForm.siteID !== site[0] ? site[0] : "",
			users: [],
		});
	}

	/**
	 * onChange for the date input.
	 *
	 * @param key
	 */
	function onDateChange(key: keyof CreateCraneDailyLogBody): (d: Date) => void {
		return (d: Date) => {
			setCreateCraneDailyLogForm({
				...createCraneDailyLogForm,
				[key]: moment(d).valueOf(),
			});
		}
	}

	/**
	 * onChange handler for the "Inspection Items" section, which
	 * are displayed dynamically based on the type of crane selected.
	 *
	 * @param key
	 * @param value
	 */
	function onInspectionItemChange(key: keyof CreateCraneDailyLogBody, value: InspectionStatus): void {
		setCreateCraneDailyLogForm({
			...createCraneDailyLogForm,
			[key]: value,
		});
	}

	/**
	 * onChange to help with the single select auto-complete for choosing an employee.
	 *
	 * @param operators
	 */
	function operatorsOnChange(operators: string[]): void {
		setCreateCraneDailyLogForm({
			...createCraneDailyLogForm,
			users: operators,
		});
	}

	/**
	 * Dynamic onChange for number inputs using the NumberFormat library.
	 *
	 * @param key
	 */
	function numberFormatOnChange(key: keyof CreateCraneDailyLogBody): (e) => void {
		return (e) => {
			setCreateCraneDailyLogForm({
				...createCraneDailyLogForm,
				[key]: e?.floatValue,
			});
		}
	}

	/**
	 * onChange function for all of the dynamic lists with plus & minus icons.
	 *
	 * @param key
	 */
	function inputListOnChange<T>(key: keyof CreateCraneDailyLogBody): (i: number, value: T) => void {
		return (i: number, value: T) => {
			const copy: Array<T> = cloneDeep(createCraneDailyLogForm[key] as unknown as Array<T>);
			copy[i] = value;

			setCreateCraneDailyLogForm({
				...createCraneDailyLogForm,
				[key]: copy,
			});
		}
	}

	/**
	 * Add another entry to the array in question.
	 *
	 */
	function inputListOnAdd<T>(key: keyof CreateCraneDailyLogBody): () => void {
		return () => {
			setCreateCraneDailyLogForm({
				...createCraneDailyLogForm,
				[key]: (createCraneDailyLogForm[key] as unknown as Array<T>).concat([defaultCreateCraneDailyLogForm[key][0]]),
			});
		}
	}

	/**
	 * Remove item at index i from the array in question.
	 *
	 */
	function inputListOnRemove<T>(key: keyof CreateCraneDailyLogBody): (i: number) => void {
		return (i: number) => {
			const copy: Array<T> = cloneDeep(createCraneDailyLogForm[key] as unknown as Array<T>);
			copy.splice(i, 1);

			setCreateCraneDailyLogForm({
				...createCraneDailyLogForm,
				[key]: copy,
			});
		}
	}

	/**
	 * Dynamic onChange for standard text inputs.
	 *
	 * @param key
	 */
	function textInputOnChange(key: keyof CreateCraneDailyLogBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setCreateCraneDailyLogForm({
				...createCraneDailyLogForm,
				[key]: e?.target?.value,
			});
		}
	}

	/**
	 * onChange for the signature field that the user must enter before submitting.
	 *
	 * @param e
	 */
	function onSignatureChange(e: React.ChangeEvent<HTMLInputElement>): void {
		setSignature(e?.target?.value);
	}

	/**
	 * Call api to either submit new Crane Daily Log or update existing and return id.
	 * Checks which type of crane so we know which api to use,
	 * checks if entered signature is correct,
	 * navigate to the form history for crane daily logs on success.
	 *
	 */
	async function saveCraneDailyLog(e?: React.SyntheticEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			const apiBase = new FormsApi(getConfig(props.fullToken));
			const selectedEquipment: Crane = find(craneList, ["_id", createCraneDailyLogForm?.equipmentID]); // Get the selected equipment to determine which api & craneType field on the api to submit.
			const initials: boolean = signature?.toLowerCase() === getFullNameForTable(userProfile)?.toLowerCase(); // Check if the user's entered signature matches their name properly.

			let req: Partial<CreateCraneDailyLogBody> = {
				users: createCraneDailyLogForm.users,
				equipmentID: createCraneDailyLogForm?.equipmentID ? createCraneDailyLogForm?.equipmentID : undefined,
				siteID: createCraneDailyLogForm.siteID ? createCraneDailyLogForm.siteID : undefined,
				date: createCraneDailyLogForm?.date !== undefined ? convertOffsetDate(createCraneDailyLogForm?.date) : undefined,
				initials,
				hours: createCraneDailyLogForm?.hours !== undefined ? createCraneDailyLogForm?.hours : undefined,
				createLogCommentBodies: createCraneDailyLogForm?.createLogCommentBodies?.filter((c: CreateLogCommentBody): boolean => {
					return c.comment?.length > 0;
				}).map(c => {
					return {
						...c,
						date: convertOffsetDate(createCraneDailyLogForm?.date),
					}
				}),
				electricalPowerCords: createCraneDailyLogForm?.electricalPowerCords !== undefined ? createCraneDailyLogForm?.electricalPowerCords : undefined,
				groundFaultCircuitInterrupter: createCraneDailyLogForm?.groundFaultCircuitInterrupter !== undefined ? createCraneDailyLogForm?.groundFaultCircuitInterrupter : undefined,
				onOffSwitch: createCraneDailyLogForm?.onOffSwitch !== undefined ? createCraneDailyLogForm?.onOffSwitch : undefined,
				craneBaseInspection: createCraneDailyLogForm?.craneBaseInspection !== undefined ? createCraneDailyLogForm?.craneBaseInspection : undefined,
				inspectWalkways: createCraneDailyLogForm?.inspectWalkways !== undefined ? createCraneDailyLogForm?.inspectWalkways : undefined,
				inspectStructure: createCraneDailyLogForm?.inspectStructure !== undefined ? createCraneDailyLogForm?.inspectStructure : undefined,
				ensureAllDoors: createCraneDailyLogForm?.ensureAllDoors !== undefined ? createCraneDailyLogForm?.ensureAllDoors : undefined,
				operatorsControlsAdequate: createCraneDailyLogForm?.operatorsControlsAdequate !== undefined ? createCraneDailyLogForm?.operatorsControlsAdequate : undefined,
				loadMomentHoistLimit: createCraneDailyLogForm?.loadMomentHoistLimit !== undefined ? createCraneDailyLogForm?.loadMomentHoistLimit : undefined,
				confirmLocationOfZoning: createCraneDailyLogForm?.confirmLocationOfZoning !== undefined ? createCraneDailyLogForm?.confirmLocationOfZoning : undefined,
				maximumLoad: createCraneDailyLogForm?.maximumLoad !== undefined ? createCraneDailyLogForm?.maximumLoad : undefined,
				hoistUpDecelerationLimit: createCraneDailyLogForm?.hoistUpDecelerationLimit !== undefined ? createCraneDailyLogForm?.hoistUpDecelerationLimit : undefined,
				hoistUpperLimit: createCraneDailyLogForm?.hoistUpperLimit !== undefined ? createCraneDailyLogForm?.hoistUpperLimit : undefined,
				hoistDownLimit: createCraneDailyLogForm?.hoistDownLimit !== undefined ? createCraneDailyLogForm?.hoistDownLimit : undefined,
				ensureAllAudioVisualIndicatorsFunction: createCraneDailyLogForm?.ensureAllAudioVisualIndicatorsFunction !== undefined ? createCraneDailyLogForm?.ensureAllAudioVisualIndicatorsFunction : undefined,
				anemometer: createCraneDailyLogForm?.anemometer !== undefined ? createCraneDailyLogForm?.anemometer : undefined,
				hoistBrakeFunctioning: createCraneDailyLogForm?.hoistBrakeFunctioning !== undefined ? createCraneDailyLogForm?.hoistBrakeFunctioning : undefined,
				slewingBrakeFunctioning: createCraneDailyLogForm?.slewingBrakeFunctioning !== undefined ? createCraneDailyLogForm?.slewingBrakeFunctioning : undefined,
				visuallyInspectLoadBlockAndHook: createCraneDailyLogForm?.visuallyInspectLoadBlockAndHook !== undefined ? createCraneDailyLogForm?.visuallyInspectLoadBlockAndHook : undefined,
				travelBrakeToRail: createCraneDailyLogForm?.travelBrakeToRail !== undefined ? createCraneDailyLogForm?.travelBrakeToRail : undefined,
				railTravelForwardAndReverse: createCraneDailyLogForm?.railTravelForwardAndReverse !== undefined ? createCraneDailyLogForm?.railTravelForwardAndReverse : undefined,
				inspectTracksForLoseConnections: createCraneDailyLogForm?.inspectTracksForLoseConnections !== undefined ? createCraneDailyLogForm?.inspectTracksForLoseConnections : undefined,
				housekeeping: createCraneDailyLogForm?.housekeeping !== undefined ? createCraneDailyLogForm?.housekeeping : undefined,
				supervisorNotedOfAnyDefectsOrFaults: createCraneDailyLogForm?.supervisorNotedOfAnyDefectsOrFaults !== undefined ? createCraneDailyLogForm?.supervisorNotedOfAnyDefectsOrFaults : undefined,
			};

			switch (selectedEquipment.craneType) {
				case CraneType.LUFFING_TOWER:
					const luffingCreateBodyCopy: CreateLuffingTowerCraneDailyLogBody = cloneDeep(createCraneDailyLogForm);

					req = {
						...req,
						ensureAllTower: luffingCreateBodyCopy?.ensureAllTower !== undefined ? luffingCreateBodyCopy?.ensureAllTower : undefined,
						loadMomentLuffingLimit: luffingCreateBodyCopy?.loadMomentLuffingLimit !== undefined ? luffingCreateBodyCopy?.loadMomentLuffingLimit : undefined,
						luffingUpSwitch: luffingCreateBodyCopy?.luffingUpSwitch !== undefined ? luffingCreateBodyCopy?.luffingUpSwitch : undefined,
						luffingDownSwitch: luffingCreateBodyCopy?.luffingDownSwitch !== undefined ? luffingCreateBodyCopy?.luffingDownSwitch : undefined,
						luffingUpAndDownDecelerationSwitch: luffingCreateBodyCopy?.luffingUpAndDownDecelerationSwitch !== undefined ? luffingCreateBodyCopy?.luffingUpAndDownDecelerationSwitch : undefined,
						boomAngleIndicator: luffingCreateBodyCopy?.boomAngleIndicator !== undefined ? luffingCreateBodyCopy?.boomAngleIndicator : undefined,
						counterweightSafetySwitch: luffingCreateBodyCopy?.counterweightSafetySwitch !== undefined ? luffingCreateBodyCopy?.counterweightSafetySwitch : undefined,
						antiTwoBlockSwitch: luffingCreateBodyCopy?.antiTwoBlockSwitch !== undefined ? luffingCreateBodyCopy?.antiTwoBlockSwitch : undefined,
						luffingBrake: luffingCreateBodyCopy?.luffingBrake !== undefined ? luffingCreateBodyCopy?.luffingBrake : undefined,
					} as CreateLuffingTowerCraneDailyLogBody;

					await apiBase.createLuffingTowerCraneDailyLog({
						createLuffingTowerCraneDailyLogBody: req as CreateLuffingTowerCraneDailyLogBody,
					});

					break;
				case CraneType.SELF_ERECTING:
					const selfErectingCreateBodyCopy: CreateSelfErectingCraneDailyLogBody = cloneDeep(createCraneDailyLogForm);

					req = {
						...req,
						loadMomentTrolleyLimit: selfErectingCreateBodyCopy?.loadMomentTrolleyLimit !== undefined ? selfErectingCreateBodyCopy?.loadMomentTrolleyLimit : undefined,
						trolleyOut: selfErectingCreateBodyCopy?.trolleyOut !== undefined ? selfErectingCreateBodyCopy?.trolleyOut : undefined,
						trolleyIn: selfErectingCreateBodyCopy?.trolleyIn !== undefined ? selfErectingCreateBodyCopy?.trolleyIn : undefined,
						trolleyBrake: selfErectingCreateBodyCopy?.trolleyBrake !== undefined ? selfErectingCreateBodyCopy?.trolleyBrake : undefined,
						baseLevel: selfErectingCreateBodyCopy?.baseLevel !== undefined ? selfErectingCreateBodyCopy?.baseLevel : undefined,
						foundationCondition: selfErectingCreateBodyCopy?.foundationCondition !== undefined ? selfErectingCreateBodyCopy?.foundationCondition : undefined,
					} as CreateSelfErectingCraneDailyLogBody;

					await apiBase.createSelfErectingCraneDailyLog({
						createSelfErectingCraneDailyLogBody: req as CreateSelfErectingCraneDailyLogBody,
					});

					break;
				case CraneType.TOWER:
					const towerCreateBodyCopy: CreateTowerCraneDailyLogBody = cloneDeep(createCraneDailyLogForm);

					req = {
						...req,
						ensureAllTower: towerCreateBodyCopy?.ensureAllTower !== undefined ? towerCreateBodyCopy?.ensureAllTower : undefined,
						loadMomentTrolleyLimit: towerCreateBodyCopy?.loadMomentTrolleyLimit !== undefined ? towerCreateBodyCopy?.loadMomentTrolleyLimit : undefined,
						trolleyOut: towerCreateBodyCopy?.trolleyOut !== undefined ? towerCreateBodyCopy?.trolleyOut : undefined,
						trolleyIn: towerCreateBodyCopy?.trolleyIn !== undefined ? towerCreateBodyCopy?.trolleyIn : undefined,
						trolleyBrake: towerCreateBodyCopy?.trolleyBrake !== undefined ? towerCreateBodyCopy?.trolleyBrake : undefined,
					} as CreateTowerCraneDailyLogBody;

					await apiBase.createTowerCraneDailyLog({
						createTowerCraneDailyLogBody: req as CreateTowerCraneDailyLogBody,
					});

					break;
				default:
					return;
			}
		} catch (e) {
			props.dispatch(addError(e));
			throw e;
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Used when creating or updating to save the draft.
	 * Afterwards, navigate to the history page.
	 *
	 * @param e
	 */
	async function saveDraft(e?: React.SyntheticEvent): Promise<void> {
		e?.preventDefault();
		try {
			await saveCraneDailyLog();
			history.push("/form-history/daily-log-history/crane-daily-log-history");
		} catch (e) {

		}
	}

	/**
	 * Util to check if the "Save Draft" button should be enabled
	 *
	 * @param _signature
	 * @param user
	 * @param _equipmentId
	 */
	function checkForSaveButtonEnabled(_signature: string, user: User, _equipmentId: string): boolean {
		return !((_signature?.toLowerCase() === getFullNameForTable(user).toLowerCase()) && _equipmentId?.length > 0);
	}

	/**
	 * Frontend filtering to get just the operators who exist on the selected site.
	 *
	 * @param operators
	 */
	function getFilteredOperators(operators: Array<User>): Array<User> {
		return operators.filter(o => {
			const found = o.sites?.find(s => s._id === createCraneDailyLogForm.siteID);
			const forceFindAdmin = o.type === UserType.ADMIN;
			return found || forceFindAdmin;
		});
	}

	const selectedCrane: Crane = find(craneList, ["_id", createCraneDailyLogForm?.equipmentID]);

	return (
		<AlliedContainer>
			<AlliedPageHeader
				title={`${props.id ? "Edit" : "Create"} Crane Daily Log`}
				showTooltip={false}
			/>

			<hr/>

			<AlliedFormContainer>
				<form onSubmit={saveDraft}>
					<Row>
						<Col xs={12} md={6} className="mb-3">
							<label>Select Crane for Log</label>
							<AlliedAutoComplete
								placeholder="Select Crane..."
								options={parseEquipmentForSelectDropdown(craneList)}
								selections={createCraneDailyLogForm.equipmentID ? [createCraneDailyLogForm.equipmentID] : []}
								setSelections={equipmentOnChange}
								multiSelect={false}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={6} className="mb-3">
							<label>Select Site</label>
							<AlliedAutoComplete
								placeholder="Select Site..."
								options={parseSitesForAutoComplete(siteList)}
								selections={createCraneDailyLogForm.siteID ? [createCraneDailyLogForm.siteID] : []}
								setSelections={siteOnChange}
								multiSelect={false}
							/>
						</Col>
					</Row>

					<hr/>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Date</label>
							<AlliedDatePicker
								selected={createCraneDailyLogForm.date}
								onChange={onDateChange("date")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={6} className="mb-3">
							<AlliedSelectInputList
								key={JSON.stringify(selectedCrane)}
								title="Inspection Items"
								emptyMessage="Please select a crane first."
								inputs={
									convertCraneDailyInspectionItemsToInputListItems(
										getCraneDailyLogInspectionItems(selectedCrane?.craneType),
										createCraneDailyLogForm,
									)}
								onChange={onInspectionItemChange}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Operators for Selected Date</label>
							{(createCraneDailyLogForm?.equipmentID && createCraneDailyLogForm?.siteID) ? (
								<AlliedAutoComplete
									placeholder="Select Operators..."
									options={parseUsersForAutoComplete(getFilteredOperators(operatorsList))}
									selections={createCraneDailyLogForm.users}
									setSelections={operatorsOnChange}
								/>
							) : (
								<div>
									<i>
										Please select a crane & site first.
									</i>
								</div>
							)}
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Usage Hours</label>
							<NumberFormat
								allowLeadingZeros={false}
								placeholder="Enter Usage Hours for Date..."
								value={createCraneDailyLogForm.hours}
								onValueChange={numberFormatOnChange("hours")}
								allowNegative={false}
								decimalScale={2}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<h5 className="font-weight-bold">Comments</h5>
						</Col>
						<Col xs={12} md={6} className="mb-3">
							<AlliedGenericInputList
								values={createCraneDailyLogForm.createLogCommentBodies}
								onChange={inputListOnChange<CreateLogCommentBody>("createLogCommentBodies")}
								onAdd={inputListOnAdd("createLogCommentBodies")}
								onRemove={inputListOnRemove("createLogCommentBodies")}
								component={DailyLogCommentInput}
							/>
						</Col>
					</Row>

					<hr/>

					<Row>
						<Col xs={12}>
							<label>Enter full name to confirm this report is accurate.</label>
						</Col>
					</Row>

					<Row>
						<Col xs={12} sm={6} md={4} lg={3} className="mb-3">
							<input
								placeholder="Enter Signature..."
								value={signature}
								onChange={onSignatureChange}
							/>
						</Col>
						<Col xs={12} sm={6} md={4} lg={3} className="mb-3 user-select-none">
							<input
								disabled={true}
								placeholder="Loading..."
								value={getFullNameForTable(userProfile)}
								className="user-select-none pointer-events-none"
							/>
						</Col>
					</Row>

					<div className="d-flex flex-column align-items-center flex-sm-row">
						<Button
							color="black"
							disabled={checkForSaveButtonEnabled(signature, userProfile, createCraneDailyLogForm?.equipmentID)}
							type="submit"
							onClick={saveDraft}
							className="mr-0 mr-sm-3 mb-3 mb-sm-0"
						>
							Save Draft
						</Button>
					</div>
				</form>
			</AlliedFormContainer>
		</AlliedContainer>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(CreateCraneDailyLogForm);
