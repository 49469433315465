import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	FormsApi, GetServiceReportListRequest,
	GetTimeSheetListRequest,
	GetTimeSheetListResponse,
	ServiceReportSortField,
	TimeSheet, TimeSheetSortField,
	Token, UserType
} from "client";
import AlliedContainer from "../../components/AlliedContainer";
import AlliedPageHeader from "../../components/AlliedPageHeader";
import AlliedTableContainer from "../../components/tables/AlliedTableContainer";
import {defaultFilter} from "../formHistory/TimeSheetsHistoryPage";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {cleanRequestForAPISubmission} from "../../utils/cleanRequestForAPISubmission";
import AlliedTableHeaderCell from "../../components/tables/cells/AlliedTableHeaderCell";
import {parseDateForTableDisplay} from "../../utils/parseDateForTableDisplay";
import {getFullNameForTable} from "../../utils/getFullNameForTable";
import ManageTimeSheetsCommentCell from "../../components/tables/cells/ManageTimeSheetsCommentCell";
import ManageTimeSheetsEditEntriesCell from "../../components/tables/cells/ManageTimeSheetsEditEntriesCell";
import {getWeekRangeForTable} from "../../utils/getWeekRangeForTable";
import {getFormPublishedStatus} from "../../utils/getFormPublishedStatus";
import ManageTimeSheetsViewCell from "../../components/tables/cells/ManageTimeSheetsViewCell";
import ManageTimeSheetsFilterModal from "../../components/modals/ManageTimeSheetsFilterModal";
import {convertOffsetDate} from "../../utils/timeZoneConversions";
import {abstractOnSortChange, getTableHeaderSortClassName} from "../../utils/abstractOnSortChange";
import moment from "moment";

interface IProps {
	dispatch?: any;
	fullToken: Token;
}

// This is the version of the page for ADMINS.

const TimeSheetsPage: React.FC<IProps> = (props) => {

	const [showFilterModal, setShowFilterModal] = useState(false);
	const [filter, setFilter] = useState<GetTimeSheetListRequest>(defaultFilter);
	const [sheetsResponse, setSheetsResponse] = useState<GetTimeSheetListResponse>(undefined);

	useEffect(() => {
		getTimeSheets().then().catch();
	}, [JSON.stringify(filter)]);

	/**
	 * Call api to get list of time sheets.
	 *
	 */
	async function getTimeSheets(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FormsApi(getConfig(props.fullToken)).getTimeSheetList(cleanRequestForAPISubmission({
				...filter,
				weekOfs: filter?.weekOf?.map(w => convertOffsetDate(w)),
				payPeriods: filter?.payPeriod?.map(w => convertOffsetDate(w)),
			} as GetTimeSheetListRequest));
			setSheetsResponse(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Toggles visibility of the filter modal.
	 *
	 */
	function toggleFilterModal(): void {
		setShowFilterModal(!showFilterModal);
	}

	/**
	 * When finished the filter modal, save the new filter (will prompt api via useEffect),
	 * and hide the filter modal.
	 *
	 * @param newFilter
	 */
	function onDoneFilterModal(newFilter: GetTimeSheetListRequest): void {
		setFilter({
			...newFilter,
			offset: 0,
		});
		setShowFilterModal(false);
	}

	/**
	 * Renderer for the Add/Edit Comment column.
	 *
	 */
	function makeCommentCell(timeSheet: TimeSheet): ReactNode {
		return (
			<ManageTimeSheetsCommentCell
				key={"comment" + timeSheet._id}
				timeSheet={timeSheet}
				onDone={getTimeSheets}
			/>
		);
	}

	/**
	 * Renderer for the Edit Entries column.
	 *
	 */
	function makeEditCell(timeSheet: TimeSheet): ReactNode {
		return (
			<ManageTimeSheetsEditEntriesCell
				key={"edit" + timeSheet._id}
				timeSheet={timeSheet}
				onDone={getTimeSheets}
			/>
		);
	}

	/**
	 * Renderer for the View Time Sheet column.
	 *
	 */
	function makeViewCell(timeSheet: TimeSheet): ReactNode {
		return (
			<ManageTimeSheetsViewCell
				key={"view" + timeSheet._id}
				timeSheet={timeSheet}
			/>
		);
	}

	function makeDateListString(weeks: Array<number | string>): string {
		let str = "";
		weeks.forEach((w, i) => {
			if (i > 0) {
				str += ", ";
			}

			str += moment(parseInt(w.toString())).format("MMM DD YYYY");
		});

		return str;
	}

	return (
		<React.Fragment>
			<ManageTimeSheetsFilterModal
				isOpen={showFilterModal}
				filter={filter}
				onClose={toggleFilterModal}
				onDone={onDoneFilterModal}
			/>

			<AlliedContainer>
				<AlliedPageHeader
					title="Time Sheets"
					onClick={toggleFilterModal}
				/>
				{filter.weekOf && filter.weekOf.length > 0 && (
					<p>
						{`Showing results from the week(s) of ${makeDateListString(filter.weekOf)}.`}
					</p>
				)}

				<hr/>

				<AlliedTableContainer
					<GetTimeSheetListRequest>
					data={sheetsResponse?.timeSheets}
					showPaginator={true}
					paginationInfo={sheetsResponse?.paginationInfo}
					filter={filter}
					setFilter={setFilter}
					columnOptions={[
						{
							headerValue: "Date Submitted",
							key: "submittedDate",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							rowCellClassName: "font-weight-bold",
							valueFormatter: parseDateForTableDisplay,
							headerCellClassName: getTableHeaderSortClassName(TimeSheetSortField.submittedDate, filter),
							onSort: abstractOnSortChange<TimeSheetSortField, GetTimeSheetListRequest>(TimeSheetSortField.submittedDate, filter, setFilter),
						},
						{
							headerValue: "AC Employee",
							key: "owner",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							rowCellClassName: "font-weight-bold",
							valueFormatter: getFullNameForTable,
						},
						{
							headerValue: "Last Updated",
							key: "updatedAt",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: parseDateForTableDisplay,
							headerCellClassName: getTableHeaderSortClassName(TimeSheetSortField.updatedAt, filter),
							onSort: abstractOnSortChange<TimeSheetSortField, GetTimeSheetListRequest>(TimeSheetSortField.updatedAt, filter, setFilter),
						},
						{
							headerValue: "Week",
							key: "weekOf",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getWeekRangeForTable(1),
							headerCellClassName: getTableHeaderSortClassName(TimeSheetSortField.weekOf, filter),
							onSort: abstractOnSortChange<TimeSheetSortField, GetTimeSheetListRequest>(TimeSheetSortField.weekOf, filter, setFilter),
						},
						{
							headerValue: "Pay Period",
							key: "paymentPeriod",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getWeekRangeForTable(2),
						},
						{
							headerValue: "Published",
							key: "publishedDate",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getFormPublishedStatus,
							headerCellClassName: getTableHeaderSortClassName(TimeSheetSortField.publishedDate, filter),
							onSort: abstractOnSortChange<TimeSheetSortField, GetTimeSheetListRequest>(TimeSheetSortField.publishedDate, filter, setFilter),
						},
						{
							headerValue: "Add/Edit Comment",
							key: undefined,
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							cellRender: makeCommentCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
						{
							headerValue: "Time Sheet Options",
							key: undefined,
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							cellRender: makeEditCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
						{
							headerValue: "View Time Sheet",
							key: undefined,
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							cellRender: makeViewCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
					]}
				/>
			</AlliedContainer>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(TimeSheetsPage);
