import React, {ReactNode} from "react";

interface IProps {
	children?: ReactNode;
}

const AlliedFormContainer: React.FC<IProps> = (props) => {

	return (
		<div className="allied-form-container">
			{props.children}
		</div>
	);
};

export default AlliedFormContainer;
