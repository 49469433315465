import {IAutoCompleteOption} from "../components/inputs/AlliedAutoComplete";

/**
 * Converts a string array into an array of options for the auto-complete list.
 * (simply reuse the values from the array as both the value & display for the auto-complete option)
 *
 * @param strings
 */
export function parseStringArrayForAutoComplete(strings: string[] = []): Array<IAutoCompleteOption> {
	return strings.map((_string: string): IAutoCompleteOption => {
		return {
			value: _string,
			label: _string,
		}
	});
}
