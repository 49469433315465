import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Equipment, EquipmentApi, GetEquipmentListResponse, Site, Token} from "client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import AlliedTableContainer from "../tables/AlliedTableContainer";
import AlliedTableHeaderCell from "../tables/cells/AlliedTableHeaderCell";
import {getEquipmentTypeDisplay} from "../../utils/getEquipmentTypeDisplay";
import {valueOrBust} from "../../utils/valueOrBust";
import {getCraneTypeDisplay} from "../../utils/getCraneTypeDisplay";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	site: Site;
	onClose: () => void;
}

const ManageSitesEquipmentModalStandardView: React.FC<IProps> = (props) => {

	const [equipmentResponse, setEquipmentResponse] = useState<GetEquipmentListResponse>(undefined);

	useEffect(() => {
		if (props.isOpen) {
			getEquipmentForSite().then().catch();
		}
	}, [props.isOpen]);

	/**
	 * Get all the equipment for this site.
	 *
	 */
	async function getEquipmentForSite(): Promise<void> {
		props.dispatch(incrementLoading());
		try {
			const res = await new EquipmentApi(getConfig(props.fullToken)).getEquipmentList({
				limit: 10000,
				offset: 0,
				site: [props.site?._id],
			});

			setEquipmentResponse(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<React.Fragment>
			<AlliedModal
				isOpen={props.isOpen}
				size="xl"
			>
				<AlliedModalHeader
					title={`View Equipment for ${props.site?.name}`}
					onClose={props.onClose}
				/>

				<hr/>

				<AlliedTableContainer
					data={equipmentResponse?.equipment}
					columnOptions={[
						{
							headerValue: "Make",
							key: "make",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
						},
						{
							headerValue: "Model",
							key: "model",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
						},
						{
							headerValue: "S/N",
							key: "serialNumber",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
						},
						{
							headerValue: "Equipment",
							key: "type",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getEquipmentTypeDisplay,
						},
						{
							headerValue: "Type",
							key: "craneType",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: (value: any) => valueOrBust(getCraneTypeDisplay(value)),
						},
					]}
				/>
			</AlliedModal>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageSitesEquipmentModalStandardView);
