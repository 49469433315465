import {
	CreateCraneDailyLogBody,
	CreateCraneMonthlyLogBody,
	CreateCraneWeeklyLogBody,
	CreateGeneratorDailyLogBody, InspectionStatus
} from "client";

export type InspectionOptionLookupInterfaces =
	CreateCraneDailyLogBody
	& CreateCraneWeeklyLogBody
	& CreateCraneMonthlyLogBody
	& CreateGeneratorDailyLogBody;

export interface InspectionItemLabelConfig {
	positive: string;
	negative: string;
	notApplicable: string;
}

export const defaultInspectionItemLabels: InspectionItemLabelConfig = {
	positive: "Approved, and in good order",
	negative: "Found faulty, notified supervisor",
	notApplicable: "Not Applicable",
}

/**
 * Used only in this file while generating for the labels.
 *
 */
interface InspectionKeyLookupDataIntermediary {
	webDisplay: InspectionItemLabelConfig;
	printableDisplay: InspectionItemLabelConfig;
	inputOptions: InspectionItemLabelConfig;
}

/**
 * Returned to the relevant function
 *
 */
export interface InspectionKeyLookupData {
	webDisplay: string;
	printableDisplay: string;
	inputOptions: InspectionItemLabelConfig;
}

export function getLogInspectionItemDisplayAndInputOptions(key: keyof InspectionOptionLookupInterfaces, status?: InspectionStatus): InspectionKeyLookupData {
	let webDisplay;
	let printableDisplay;

	const detailedOptions: InspectionKeyLookupDataIntermediary = getIndividualInspectionItemLabels(key);

	switch (status) {
		case InspectionStatus.APPROVED:
			webDisplay = detailedOptions.webDisplay.positive;
			printableDisplay = detailedOptions.printableDisplay.positive;
			break
		case InspectionStatus.FAULTY:
			webDisplay = detailedOptions.webDisplay.negative;
			printableDisplay = detailedOptions.printableDisplay.negative;
			break
		case InspectionStatus.NOT_APPLICABLE:
			webDisplay = detailedOptions.webDisplay.notApplicable;
			printableDisplay = detailedOptions.printableDisplay.notApplicable;
			break
		default:
			webDisplay = status;
			printableDisplay = status;
			break;
	}

	return {
		webDisplay,
		printableDisplay,
		inputOptions: detailedOptions.inputOptions,
	}
}

export function getIndividualInspectionItemLabels(key: keyof InspectionOptionLookupInterfaces): InspectionKeyLookupDataIntermediary {
	switch (key) {
		case "supervisorNotedOfAnyDefectsOrFaults":
			return {
				webDisplay: {
					positive: "Yes",
					negative: "No",
					notApplicable: "Not Applicable",
				},
				printableDisplay: {
					positive: "Yes",
					negative: "No",
					notApplicable: "N/A",
				},
				inputOptions: {
					...defaultInspectionItemLabels,
					positive: "Yes",
					negative: "No",
				},
			}
		case "housekeeping":
			return {
				webDisplay: {
					positive: "Clear of Obstructions",
					negative: "Requires Action",
					notApplicable: "Not Applicable",
				},
				printableDisplay: {
					positive: "Clear of Obstructions",
					negative: "Requires Action",
					notApplicable: "N/A",
				},
				inputOptions: {
					...defaultInspectionItemLabels,
					positive: "Clear of Obstructions",
					negative: "Requires Action",
				},
			}
		default:
			return {
				webDisplay: {
					positive: "Approved, and in good order",
					negative: "Found faulty, notified supervisor",
					notApplicable: "Not Applicable",
				},
				printableDisplay: {
					positive: "Approved",
					negative: "Faulty",
					notApplicable: "N/A",
				},
				inputOptions: defaultInspectionItemLabels,
			}
	}
}
