import {InspectionStatus} from "client";
import {
	getLogInspectionItemDisplayAndInputOptions, InspectionItemLabelConfig, InspectionKeyLookupData,
	InspectionOptionLookupInterfaces
} from "./logInspectionOptionsFinder";

/**
 * Convert the inspection status enum into user friendly string for display.
 *
 * @param key
 * @param status
 */
export function parseInspectionStatus(key: keyof InspectionOptionLookupInterfaces, status: InspectionStatus): string {
	if (!status) {
		return "-";
	}

	return getLogInspectionItemDisplayAndInputOptions(key, status).webDisplay;
}

/**
 * Convert the inspection status enum into user friendly string for display on the printable table (shortened).
 *
 * @param key
 * @param status
 */
export function parseInspectionStatusForPrintableTable(key: keyof InspectionOptionLookupInterfaces, status: InspectionStatus): string {
	if (!status) {
		return "-";
	}

	return getLogInspectionItemDisplayAndInputOptions(key, status).printableDisplay;
}

export function parseInspectionStatusTableHelper(key: keyof InspectionOptionLookupInterfaces): (status: InspectionStatus) => void {
	return (status: InspectionStatus) => {
		if (!status) {
			return "-"
		}

		return parseInspectionStatus(key, status);
	}
}

export function isInspectionStatus(s: any): s is InspectionStatus {
	console.log("s:", s);
	return Object.values(InspectionStatus).includes(s);
}
