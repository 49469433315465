/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { UserFromJSON, UserToJSON, } from './User';
import { UserTypeFromJSON, UserTypeToJSON, } from './UserType';
/**
 * Check if a given object implements the Token interface.
 */
export function instanceOfToken(value) {
    let isInstance = true;
    isInstance = isInstance && "_id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "token" in value;
    return isInstance;
}
export function TokenFromJSON(json) {
    return TokenFromJSONTyped(json, false);
}
export function TokenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'type': UserTypeFromJSON(json['type']),
        'owner': !exists(json, 'owner') ? undefined : UserFromJSON(json['owner']),
        'token': json['token'],
    };
}
export function TokenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'type': UserTypeToJSON(value.type),
        'owner': UserToJSON(value.owner),
        'token': value.token,
    };
}
