import React, {ReactNode} from "react";
import {
	Crane,
	CraneDailyLog, CraneDailyLogGroup,
	CraneType,
	CreateLuffingTowerCraneDailyLogBody,
	CreateSelfErectingCraneDailyLogBody, CreateTowerCraneDailyLogBody,
	FormType
} from "client";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {getCraneDailyLogInspectionItems} from "../../utils/getCraneDailyLogInspectionItems";
import {getCraneDailyLogInspectionItemDisplayName} from "../../utils/getCraneDailyLogInspectionItemDisplayName";
import {parseInspectionStatus} from "../../utils/parseInspectionStatus";
import {InspectionOptionLookupInterfaces} from "../../utils/logInspectionOptionsFinder";

interface IProps {
	isOpen: boolean
	log: CraneDailyLog;
	logGroup: CraneDailyLogGroup;
	onClose: () => void;
}

const CraneDailyLogsViewOtherFieldsModal: React.FC<IProps> = (props) => {

	/**
	 * Check the FormType of the form to derive
	 * the CraneType for use in the function to get
	 * the list of inspection items.
	 *
	 * @param log
	 */
	function getAdjustedCraneType(log: CraneDailyLog): CraneType {
		return CraneType.LUFFING_TOWER;
		// switch (type) {
		// 	case FormType.LuffingTowerCraneDailyLog:
		// 		return CraneType.LUFFING_TOWER;
		// 	case FormType.SelfErectingCraneDailyLog:
		// 		return CraneType.SELF_ERECTING;
		// 	case FormType.TowerCraneDailyLog:
		// 		return CraneType.TOWER;
		// }
	}

	/**
	 * Filter out any fields of the log that aren't inspection items,
	 * and then parse & display the remaining inspection item.
	 *
	 * @param log
	 */
	function makeFieldEntries(log: CraneDailyLog): ReactNode {
		const fields: string[] = getCraneDailyLogInspectionItems((props.logGroup?.equipment as Crane)?.craneType);

		return Object.keys(props.log).filter((p: string) => {
			return fields.indexOf(p) > -1
		}).map((entry: keyof CreateLuffingTowerCraneDailyLogBody | keyof CreateSelfErectingCraneDailyLogBody | keyof CreateTowerCraneDailyLogBody) => {
			return (
				<div>
					<label>{getCraneDailyLogInspectionItemDisplayName(entry as keyof CreateLuffingTowerCraneDailyLogBody | keyof CreateSelfErectingCraneDailyLogBody | keyof CreateTowerCraneDailyLogBody)}</label>
					<p>
						{parseInspectionStatus(entry as keyof InspectionOptionLookupInterfaces, log[entry])}
					</p>
				</div>
			);
		});
	}

	return (
		<AlliedModal
			isOpen={props.isOpen}
			size="md"
		>
			<AlliedModalHeader
				title="Log Inspection"
				onClose={props.onClose}
			/>

			<hr/>

			{makeFieldEntries(props.log)}
		</AlliedModal>
	);
};

export default CraneDailyLogsViewOtherFieldsModal;
