import React, {ReactElement, ReactNode} from "react";
import {CraneDailyLogGroup, GeneratorDailyLogGroup, Log, LogComment} from "client";
import moment from "moment";
import {FiCheck} from "react-icons/fi";
import {FiX} from "react-icons/all";
import PrintableSpacer from "./PrintableSpacer";
import {getFullNameForTable} from "../../../utils/getFullNameForTable";

export type LogWithComment = Log & {comments?: Array<LogComment>};

interface IProps<T> {
	logs: Array<T>;
	logGroup: CraneDailyLogGroup | GeneratorDailyLogGroup;
}

function PrintableDailyLogCommentTableGroup<T extends LogWithComment>(props: IProps<T>): ReactElement {

	function makeCommentEntries(_comment: LogComment, i: number): ReactNode {
		return (
			<tr key={`log-comment-entry-${i}`}>
				<td className="printable__table__centered">
					{_comment?.flagged ? (
						<React.Fragment>
							<FiCheck className="printable__react-icon"/>
						</React.Fragment>
					) : (
						<React.Fragment>
							<FiX className="printable__react-icon"/>
						</React.Fragment>
					)}
				</td>

				<td className="printable__table__label printable__table__centered">
					{getFullNameForTable(_comment.user)}
				</td>

				<td>
					{_comment?.comment}
				</td>
			</tr>
		);
	}

	function makeTableEntries(_log: LogWithComment, i: number): ReactNode {
		// handle undefined days in the array (ie. no daily log exists yet for that day of the week)
		if (!_log) {
			return null;
		}

		return (
			<React.Fragment>
				{i > 0 && (
					<PrintableSpacer size={10}/>
				)}

				<table className="printable__table" key={`log-comment-table_${i}_${_log?._id}`}>
					<tr>
						<td className="printable__table__label printable__table__centered" style={{width: "7%"}}>
							Flagged?
						</td>

						<td className="printable__table__label printable__table__centered" style={{width: "14%"}}>
							Commenter
						</td>

						<td className="printable__table__label">
							{moment(props.logGroup?.date).startOf("day").add(i, "day").format("dddd")}
						</td>
					</tr>

					{_log?.comments?.length > 0 ? (
						<React.Fragment>
							{_log?.comments?.map(makeCommentEntries)}
						</React.Fragment>
					) : (
						<tr>
							<td className="printable__table__centered" colSpan={3}>
								No Comments
							</td>
						</tr>
					)}
				</table>
			</React.Fragment>
		);
	}

	return (
		<div style={{width: "100%"}}>
			{props.logs?.length > 0 ? (
				<React.Fragment>
					{props.logs?.map(makeTableEntries)}
				</React.Fragment>
			) : (
				<table className="printable__table">
					<tr>
						<td className="printable__table__centered">
							No Comments
						</td>
					</tr>
				</table>
			)}
		</div>
	);
}

export default PrintableDailyLogCommentTableGroup;
