import React from "react";
import {Button} from "reactstrap";
import {CraneMonthlyLog} from "client";
import ViewCraneMonthlyLogButton from "../../printables/buttons/ViewCraneMonthlyLogButton";

interface IProps {
	log: CraneMonthlyLog;
}

const CraneMonthlyLogsAdminsViewCell: React.FC<IProps> = (props) => {

	return (
		<div className="d-flex justify-content-center">
			<ViewCraneMonthlyLogButton id={props.log?._id}>
				View Log
			</ViewCraneMonthlyLogButton>
		</div>
	);
};

export default CraneMonthlyLogsAdminsViewCell;
