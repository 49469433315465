import {CraneDailyLog, CraneDailyLogGroup, GeneratorDailyLog, GeneratorDailyLogGroup} from "client";
import {
	getCraneDailyLogGroupAsArray,
	getCraneDailyLogGroupAsArrayUnFiltered,
	getGeneratorDailyLogGroupAsArrayUnFiltered
} from "./getGroupedDailyLogsAsArray";

export interface CraneDailyLogWithDate extends CraneDailyLog {
	date: number; // date in ms of this single log
	day: number; // the same day used to determine this log's spot in the week on mongo, see on frontend as "_x"
}

export function addDatesToEachIndividualCraneDailyLogWithinGroup(logGroup: CraneDailyLogGroup): Array<CraneDailyLogWithDate> {
	return getCraneDailyLogGroupAsArrayUnFiltered(logGroup).map((log, i: number): CraneDailyLogWithDate => {
		if (!log) {
			return undefined;
		}

		return {
			...log,
			date: getDateOfSingleIndividualLog(i, logGroup),
			day: i,
		}
	}).filter(l => l !== undefined);
}

export interface GeneratorDailyLogWithDate extends GeneratorDailyLog {
	date: number; // date in ms of this single log
	day: number; // the same day used to determine this log's spot in the week on mongo, see on frontend as "_x"
}

export function addDatesToEachIndividualGeneratorDailyLogWithinGroup(logGroup: GeneratorDailyLogGroup): Array<GeneratorDailyLogWithDate> {
	return getGeneratorDailyLogGroupAsArrayUnFiltered(logGroup).map((log, i: number): GeneratorDailyLogWithDate => {
		if (!log) {
			return undefined;
		}

		return {
			...log,
			date: getDateOfSingleIndividualLog(i, logGroup),
			day: i,
		}
	}).filter(l => l !== undefined);
}

export function getDateOfSingleIndividualLog(day: number, logGroup: CraneDailyLogGroup | GeneratorDailyLogGroup): number {
	return logGroup.date + (day * 1000 * 60 * 60 * 24)
}
