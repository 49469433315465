/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { SiteIDAndNameFromJSON, SiteIDAndNameToJSON, } from './SiteIDAndName';
/**
 * Check if a given object implements the GetEquipmentListFiltersResponse interface.
 */
export function instanceOfGetEquipmentListFiltersResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "owners" in value;
    isInstance = isInstance && "renters" in value;
    isInstance = isInstance && "makes" in value;
    isInstance = isInstance && "models" in value;
    isInstance = isInstance && "serialNumbers" in value;
    return isInstance;
}
export function GetEquipmentListFiltersResponseFromJSON(json) {
    return GetEquipmentListFiltersResponseFromJSONTyped(json, false);
}
export function GetEquipmentListFiltersResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'owners': json['owners'],
        'renters': json['renters'],
        'makes': json['makes'],
        'models': json['models'],
        'serialNumbers': json['serialNumbers'],
        'siteIDs': !exists(json, 'siteIDs') ? undefined : (json['siteIDs'].map(SiteIDAndNameFromJSON)),
    };
}
export function GetEquipmentListFiltersResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'owners': value.owners,
        'renters': value.renters,
        'makes': value.makes,
        'models': value.models,
        'serialNumbers': value.serialNumbers,
        'siteIDs': value.siteIDs === undefined ? undefined : (value.siteIDs.map(SiteIDAndNameToJSON)),
    };
}
