import React, {ReactNode} from "react";
import AlliedModalHeader from "./AlliedModalHeader";
import {getEquipmentTypeDisplay} from "../../utils/getEquipmentTypeDisplay";
import {Button} from "reactstrap";
import AlliedModal from "./AlliedModal";

interface IProps {
	isOpen: boolean;
	title?: string;
	content: ReactNode;
	buttonText: string;
	onClose(): void;
}

const GenericMessageModal: React.FC<IProps> = (props) => {

	return (
		<AlliedModal
			size="md"
			isOpen={props.isOpen}
		>
			<AlliedModalHeader
				title={props.title}
				onClose={props.onClose}
			/>

			<hr/>

			{props.content}

			<div>
				<Button
					color="black"
					onClick={props.onClose}
				>
					{props.buttonText}
				</Button>
			</div>
		</AlliedModal>
	);
};

export default GenericMessageModal;
