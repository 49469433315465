/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const CraneWeeklyLogSortField = {
    publishedDate: 'publishedDate',
    submittedDate: 'submittedDate',
    visualInspectionForOfSlewRing: 'visualInspectionForOfSlewRing',
    visualInspectionOfTrolleyRollers: 'visualInspectionOfTrolleyRollers',
    visualInspectionOfSheaves: 'visualInspectionOfSheaves',
    jibBackstops: 'jibBackstops',
    boomHoistBrake: 'boomHoistBrake',
    checkWireRopeEnds: 'checkWireRopeEnds',
    inspectLoadLine: 'inspectLoadLine',
    visualInspectionOfTieIns: 'visualInspectionOfTieIns',
    ensureMachineIsProperlyLubricated: 'ensureMachineIsProperlyLubricated',
    visualInspectionOfAllDriveComponents: 'visualInspectionOfAllDriveComponents',
    visualInspectionOfCounterweightSupports: 'visualInspectionOfCounterweightSupports',
    visualInspectionOfAnchorBolts: 'visualInspectionOfAnchorBolts',
    visualInspectionOfTowerBolts: 'visualInspectionOfTowerBolts',
    ensureTrackIsLevel: 'ensureTrackIsLevel',
    supervisorNotifiedOfDefects: 'supervisorNotifiedOfDefects',
    createdAt: 'createdAt',
    updatedAt: 'updatedAt'
};
export function CraneWeeklyLogSortFieldFromJSON(json) {
    return CraneWeeklyLogSortFieldFromJSONTyped(json, false);
}
export function CraneWeeklyLogSortFieldFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function CraneWeeklyLogSortFieldToJSON(value) {
    return value;
}
