/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { CheckTokenExpirationResponseFromJSON, TokenBodyToJSON, VersionResponseFromJSON, } from '../models';
/**
 *
 */
export class UtilsApi extends runtime.BaseAPI {
    /**
     * It takes in a token and returns a boolean if its still active. If the token is still active, then extend the token\'s expiration.
     */
    async checkTokenExpirationRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/utils/check_token_expiration`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenBodyToJSON(requestParameters.tokenBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CheckTokenExpirationResponseFromJSON(jsonValue));
    }
    /**
     * It takes in a token and returns a boolean if its still active. If the token is still active, then extend the token\'s expiration.
     */
    async checkTokenExpiration(requestParameters = {}, initOverrides) {
        const response = await this.checkTokenExpirationRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * deletes a token
     */
    async deleteTokenRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/utils/delete_token`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: TokenBodyToJSON(requestParameters.tokenBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * deletes a token
     */
    async deleteToken(requestParameters = {}, initOverrides) {
        await this.deleteTokenRaw(requestParameters, initOverrides);
    }
    /**
     * Should always return a status code of 200. Used to determine if the server is up and working.
     */
    async healthRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/utils/health`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Should always return a status code of 200. Used to determine if the server is up and working.
     */
    async health(initOverrides) {
        await this.healthRaw(initOverrides);
    }
    /**
     * A safe public API to initialize the server. This API is sustainable to even if compromised, it will not cause problems.
     */
    async initRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/utils/init`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * A safe public API to initialize the server. This API is sustainable to even if compromised, it will not cause problems.
     */
    async init(initOverrides) {
        await this.initRaw(initOverrides);
    }
    /**
     * Finds what version of the app you are using
     */
    async versionRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/utils/version`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => VersionResponseFromJSON(jsonValue));
    }
    /**
     * Finds what version of the app you are using
     */
    async version(initOverrides) {
        const response = await this.versionRaw(initOverrides);
        return await response.value();
    }
}
