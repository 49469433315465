import {Token, UserType} from "client";

/**
 * Utility to check against the user's token and a list of allowed user types.
 *
 * @param permittedUserTypes
 * @param fullToken
 */
export function userTypeCheck(permittedUserTypes: Array<UserType> = [], fullToken: Token): boolean {
	return permittedUserTypes.indexOf(fullToken.type) > -1;
}
