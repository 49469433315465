import * as React from "react";
import {Button} from "reactstrap";
import {ChangeEvent, PropsWithChildren, useRef} from "react";
import {IoIosCloseCircle} from "react-icons/all";
import {$red} from "../../style/_colors";

interface IProps extends PropsWithChildren<any>{
    value: File;
    onChange: (v: File) => void;
}

const AlliedInputFile: React.FC<IProps> = (props: IProps) => {

    const {value, onChange} = props;

    const inputRef = useRef<HTMLInputElement>();

    function inputClick(): void {
        inputRef?.current?.click();
    }

    function handleFileSelected(e: ChangeEvent<HTMLInputElement>): void {
        let files;

        if (e.target.files && e.target.files.length > 0) {
            files = Array.from(e.target.files)
            onChange(files);
        }
    }

    function createFileElement(item, index) {

        const _delete = () => {
            onChange((value as any).filter(selected => selected !== item));
        }

        return (
            <span className={`d-flex flex-row ${props.multiple && "input-file__text-render--hover"}`} key={`input-file-item-${index}`} onClick={props.multiple && _delete}>
                {item.name}
                {props.multiple &&
                    <div className="px-1">
                        <IoIosCloseCircle color={$red} />
                    </div>
                }
            </span>
        );
    }

    return (
                    <div className="input-file">
                        <div className="input-file__button">
                                <Button className="w-100" color="red" onClick={inputClick} >Upload File</Button>
                        </div>
                        <div className="input-file__text-render">
                            {(props.value) ? (
                                (props.value as any).map(createFileElement)
                            ) : (
                                <span>No file selected...</span>
                            )}
                        </div>

                        <input
                            {...props}
                            value={""}
                            ref={inputRef as any}
                            onChange={handleFileSelected}
                            type="file"
                            className="d-none"
                            accept="image/png, image/jpeg"
                        />
                    </div>
    );
};

export default AlliedInputFile;
