import React, {ChangeEvent, ChangeEventHandler, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {CraneType, CreateEquipmentBody, EquipmentApi, EquipmentType, SitesApi, SiteWithUsersAndEquipment, Token} from "client";
import {Button, Col, Row} from "reactstrap";
import AlliedContainer from "../../components/AlliedContainer";
import AlliedPageHeader from "../../components/AlliedPageHeader";
import AlliedFormContainer from "../../components/AlliedFormContainer";
import {getEquipmentTypeDisplay} from "../../utils/getEquipmentTypeDisplay";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {getCraneTypeDisplay} from "../../utils/getCraneTypeDisplay";
import NumberFormat from "react-number-format";
import getConfig from "../../utils/getConfig";
import AlliedAutoComplete from "../../components/inputs/AlliedAutoComplete";
import GenericMessageModal from "../../components/modals/GenericMessageModal";
import {parseSitesForAutoComplete} from "../../utils/parseSitesForAutoComplete";
import AlliedSelect from "../../components/inputs/AlliedSelect";

const defaultCreateEquipmentForm: CreateEquipmentBody = {
	ownedBy: "",
	rentedBy: "",
	make: "",
	model: "",
	serialNumber: "",
	preExistingUsageHours: undefined,
	siteID: "",
};

interface IProps {
	dispatch?: any;
	fullToken: Token;
}

const CreateNewEquipmentPage: React.FC<IProps> = (props) => {

	const [equipmentType, setEquipmentType] = useState<EquipmentType>(undefined); // type of equipment user is creating (set via the top drop-down)
	const [createEquipmentForm, setCreateEquipmentForm] = useState<CreateEquipmentBody>(defaultCreateEquipmentForm); // form to edit
	const [craneType, setCraneType] = useState<CraneType>(undefined); // type of crane, only applicable when equipmentType === EquipmentType.CRANE
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [selectedSiteList, setSelectedSiteList] = useState<string[]>([]); // sites the user has selected from the drop-down

	const [apiSiteList, setApiSiteList] = useState<Array<SiteWithUsersAndEquipment>>(undefined); // sites as returned from the api

	/**
	 * On mount, call api to get values from the auto-complete inputs.
	 *
	 */
	useEffect(() => {
		getExistingSites().then().catch();
	}, []);

	/**
	 * Call api to get the auto-complete list for the existing sites in the system.
	 *
	 */
	async function getExistingSites(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new SitesApi(getConfig(props.fullToken)).getSiteList({
				limit: 10000,
				offset: 0,
			});

			setApiSiteList(res.sites);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	function equipmentTypeOnChange(e: ChangeEvent<HTMLSelectElement>): void {
		setEquipmentType(e.target.value as EquipmentType);
	}

	/**
	 * Dynamic onChange for the form text fields.
	 *
	 * @param key
	 */
	function createEquipmentOnChange(key: keyof CreateEquipmentBody): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> {
		return (e) => {
			setCreateEquipmentForm({
				...createEquipmentForm,
				[key]: e?.target?.value,
			});
		}
	}

	/**
	 * onChange for the crane type field that only appears when equipment type is set to crane.
	 *
	 * @param event
	 */
	function craneTypeOnChange(event): void {
		setCraneType(event?.target?.value);
	}

	/**
	 * Dynamic onChange for number inputs using the NumberFormat library.
	 *
	 * @param key
	 */
	function numberFormatOnChange(key: string): (e) => void {
		return (e) => {
			setCreateEquipmentForm({
				...createEquipmentForm,
				[key]: e?.floatValue,
			});
		}
	}

	/**
	 * Call api to submit new equipment dynamically based on "type" field,
	 * show modal on success.
	 *
	 * @param e
	 */
	async function submitNewEquipment(e?: React.SyntheticEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			const apiBase = new EquipmentApi(getConfig(props.fullToken));

			const combinedForm: CreateEquipmentBody = {
				...createEquipmentForm,
				siteID: selectedSiteList[0], // our system requires equipment to exist at some site at all times
			}

			switch (equipmentType) {
				case EquipmentType.CRANE:
					await apiBase.createCrane({
						createCraneBody: {
							...combinedForm,
							craneType,
						},
					});
					break;
				case EquipmentType.GENERATOR:
					await apiBase.createGenerator({
						createGeneratorBody: combinedForm,
					});
					break;
				case EquipmentType.MATERIAL_HOIST:
					await apiBase.createMaterialHoist({
						createMaterialHoistBody: combinedForm,
					});
					break
			}

			setShowSuccessModal(true);
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Refresh the page to reset the equipment form on successful add.
	 *
	 */
	function dismissSuccessModal(): void {
		window?.location?.reload();
	}

	return (
		<React.Fragment>
			<GenericMessageModal
				isOpen={showSuccessModal}
				title="Success"
				content={(<p>New equipment has been saved successfully.</p>)}
				buttonText="Dismiss"
				onClose={dismissSuccessModal}
			/>

			<AlliedContainer>
				<AlliedPageHeader
					title="Create New Equipment"
					showTooltip={false}
				/>

				<hr/>

				<AlliedFormContainer>
					<form onSubmit={submitNewEquipment}>
						<Row>
							<Col xs={12} md={4} className="mb-2">
								<label>Select Equipment Type to Create</label>
								<AlliedSelect
									value={equipmentType}
									onChange={equipmentTypeOnChange}
								>
									<option value="" selected disabled>Select Equipment Type</option>
									<hr/>
									<option value={EquipmentType.CRANE}>{getEquipmentTypeDisplay(EquipmentType.CRANE)}</option>
									<option value={EquipmentType.GENERATOR}>{getEquipmentTypeDisplay(EquipmentType.GENERATOR)}</option>
									<option value={EquipmentType.MATERIAL_HOIST}>{getEquipmentTypeDisplay(EquipmentType.MATERIAL_HOIST)}</option>
								</AlliedSelect>
							</Col>
						</Row>

						{equipmentType && (
							<React.Fragment>
								<hr/>

								<h3>Create New {getEquipmentTypeDisplay(equipmentType)}</h3>

								<Row>
									<Col xs={12} md={4} className="mb-3">
										<label>Make</label>
										<input
											placeholder="Enter Make..."
											value={createEquipmentForm.make}
											onChange={createEquipmentOnChange("make")}
										/>
									</Col>

									<Col xs={12} md={4} className="mb-3">
										<label>Model</label>
										<input
											placeholder="Enter Model..."
											value={createEquipmentForm.model}
											onChange={createEquipmentOnChange("model")}
										/>
									</Col>

									<Col xs={12} md={4} className="mb-3">
										<label>S/N</label>
										<input
											placeholder="Enter S/N..."
											value={createEquipmentForm.serialNumber}
											onChange={createEquipmentOnChange("serialNumber")}
										/>
									</Col>

									{equipmentType === EquipmentType.CRANE && (
										<React.Fragment>
											<Col xs={12} md={4} className="mb-3">
												<label>Type</label>
												<AlliedSelect
													value={craneType}
													onChange={craneTypeOnChange}
												>
													<option value="" selected disabled>Select Crane Type</option>
													<hr/>
													<option value={CraneType.LUFFING_TOWER}>{getCraneTypeDisplay(CraneType.LUFFING_TOWER)}</option>
													<option value={CraneType.SELF_ERECTING}>{getCraneTypeDisplay(CraneType.SELF_ERECTING)}</option>
													<option value={CraneType.TOWER}>{getCraneTypeDisplay(CraneType.TOWER)}</option>
												</AlliedSelect>
											</Col>

											<Col xs={0} md={8}/>
										</React.Fragment>
									)}

									<Col xs={12} md={4} className="mb-3">
										<label>Owned By</label>
										<input
											placeholder="Enter Equipment Owner..."
											value={createEquipmentForm.ownedBy}
											onChange={createEquipmentOnChange("ownedBy")}
										/>
									</Col>

									<Col xs={12} md={4} className="mb-3">
										<label>Rented By</label>
										<input
											placeholder="Enter Equipment Renter..."
											value={createEquipmentForm.rentedBy}
											onChange={createEquipmentOnChange("rentedBy")}
										/>
									</Col>

									<Col xs={0} md={4}/>

									<Col xs={12} md={4} className="mb-3">
										<label>Historical Hours</label>
										<NumberFormat
											allowLeadingZeros={false}
											placeholder="Enter Equipment Historical Hours..."
											value={createEquipmentForm.preExistingUsageHours}
											onValueChange={numberFormatOnChange("preExistingUsageHours")}
											allowNegative={false}
										/>
									</Col>

									<Col xs={0} md={8}/>

									<Col xs={12} md={4} className="mb-3">
										<label>Site</label>
										<AlliedAutoComplete
											placeholder="Enter Site Name..."
											options={parseSitesForAutoComplete(apiSiteList)}
											selections={selectedSiteList}
											setSelections={setSelectedSiteList}
											multiSelect={false}
										/>
									</Col>

									<Col xs={0} md={8}/>

								</Row>

								<Button
									color="black"
									type="submit"
									onClick={submitNewEquipment}
								>
									Save Equipment
								</Button>
							</React.Fragment>
						)}
					</form>
				</AlliedFormContainer>
			</AlliedContainer>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(CreateNewEquipmentPage);
