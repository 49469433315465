import * as React from "react";
import {useEffect, useState} from "react";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {
	EquipmentApi,
	EquipmentType,
	GetEquipmentListFiltersResponse,
	GetEquipmentListRequest,
	Token
} from "client";
import {Button, Col, Row} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import AlliedAutoComplete from "../inputs/AlliedAutoComplete";
import {parseStringArrayForAutoComplete} from "../../utils/parseStringArrayForAutoComplete";
import AutoCompleteListDisplay from "../inputs/AutoCompleteListDisplay";
import {parseFilterSiteArrayForAutoComplete} from "../../utils/parseFilterSiteArrayForAutoComplete";
import AlliedInputCheckbox from "../inputs/AlliedInputCheckbox";
import getConfig from "../../utils/getConfig";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	filter: GetEquipmentListRequest;
	onClose: () => void;
	onDone: (newFilter: GetEquipmentListRequest) => void;
}

const ManageGeneratorsFilterModal: React.FC<IProps> = (props) => {

	const [filter, setFilter] = useState<GetEquipmentListRequest>(props.filter);

	const [apiFilterOptions, setApiFilterOptions] = useState<GetEquipmentListFiltersResponse>(undefined);

	/**
	 * When modal opens, update the local filter to match that of props,
	 * and call api to get values for the auto-complete inputs.
	 *
	 */
	useEffect(() => {
		if (props.isOpen) {
			setFilter(props.filter);
			getGeneratorsFilterOptions().then().catch();
		}
	}, [props.isOpen]);

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function resetAndClose(): void {
		setFilter(props.filter);
		props.onClose();
	}

	/**
	 * Call api to get list of generators for the auto-complete input.
	 *
	 */
	async function getGeneratorsFilterOptions(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new EquipmentApi(getConfig(props.fullToken)).getEquipmentListFilters({
				equipmentType: EquipmentType.GENERATOR,
			});
			setApiFilterOptions(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}


	/**
	 * Dynamic onChange helper to update the string arrays in the filter object.
	 *
	 * @param key
	 */
	function filterArrayOnChange(key: keyof GetEquipmentListRequest): (value: string[]) => void {
		return (value: string[]) => {
			setFilter({
				...filter,
				[key]: value,
			});
		}
	}

	/**
	 * Toggles the visibility of the generators that are disabled
	 */
	function toggleShowDisabled(): void {
		setFilter({
			...filter,
			showDisabled: !filter.showDisabled,
		});
	}

	/**
	 * When done configuring filter, call onDone function from props.
	 *
	 */
	function onSave(): void {
		props.onDone(filter);
	}

	return (
		<AlliedModal
			size="lg"
			isOpen={props.isOpen}
		>
			<AlliedModalHeader
				title="Filter Equipment"
				onClose={resetAndClose}
			/>

			<hr/>


			<Row>
				<Col xs={12} lg={6} className="mb-3">
					<label>Owned By</label>
					<AlliedAutoComplete
						placeholder="Select Owner..."
						options={parseStringArrayForAutoComplete(apiFilterOptions?.owners)}
						selections={filter?.ownedBy}
						setSelections={filterArrayOnChange("ownedBy")}
					/>
				</Col>
			</Row>

			<Row>
				<Col xs={12} lg={6} className="mb-3">
					<label>Make</label>
					<AlliedAutoComplete
						placeholder="Select Make..."
						options={parseStringArrayForAutoComplete(apiFilterOptions?.makes)}
						selections={filter.make}
						setSelections={filterArrayOnChange("make")}
					/>
				</Col>
				<Col xs={12} lg={6} className="mb-3">
					<label>Model</label>
					<AlliedAutoComplete
						placeholder="Select Model..."
						options={parseStringArrayForAutoComplete(apiFilterOptions?.models)}
						selections={filter.model}
						setSelections={filterArrayOnChange("model")}
					/>
				</Col>
			</Row>

			<Row>
				<Col xs={12} lg={6} className="mb-3">
					<label>S/N</label>
					<AlliedAutoComplete
						placeholder="Select S/N..."
						options={parseStringArrayForAutoComplete(apiFilterOptions?.serialNumbers)}
						selections={filter.serialNumber}
						setSelections={filterArrayOnChange("serialNumber")}
					/>
				</Col>
			</Row>

			<Row>
				<Col xs={12} lg={6} className="mb-3">
					<label>Current Site</label>
					<AlliedAutoComplete
						placeholder="Select Sites..."
						options={parseFilterSiteArrayForAutoComplete(apiFilterOptions?.siteIDs)}
						selections={filter.site}
						setSelections={filterArrayOnChange("site")}
					/>
				</Col>
				<Col xs={12} lg={6} className="mb-3">
					<label>Current Renter</label>
					<AlliedAutoComplete
						placeholder="Select Renter..."
						options={parseStringArrayForAutoComplete(apiFilterOptions?.renters)}
						selections={filter.rentedBy}
						setSelections={filterArrayOnChange("rentedBy")}
					/>
				</Col>
			</Row>

			<Row>
				<Col xs={12} lg={6} className="mb-3">
					<AlliedInputCheckbox
						checked={filter.showDisabled}
						onToggle={toggleShowDisabled}
					>
						Show Disabled Equipment
					</AlliedInputCheckbox>
				</Col>
			</Row>

			<div>
				<Button
					color="black"
					onClick={onSave}
				>
					Apply Filters
				</Button>
			</div>
		</AlliedModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageGeneratorsFilterModal);
