import React, {ReactElement} from "react";
import {Crane, Equipment, Token} from "client";
import EquipmentLocationListPrintable from "../forms/EquipmentLocationListPrintable";
import AbstractPrintableButtonManager from "./AbstractPrintableButtonManager";
import CraneQrCodePrintable from "../forms/CraneQrCodePrintable";

interface Props {
	crane: Crane;
	children: string;
	showPrintable?: boolean;
	className?: string;
}

function ViewCraneQrCodeButton({crane, children, showPrintable, className}: Props): ReactElement {

	async function getCrane(): Promise<Crane> {
		return crane;
	}

	return (
		<AbstractPrintableButtonManager
			<Crane>
			color="black"
			onClick={getCrane}
			component={CraneQrCodePrintable}
			showPrintable={showPrintable}
			className={className}
			documentTitle="equipment-location-list"
		>
			{children}
		</AbstractPrintableButtonManager>
	);
}

export default ViewCraneQrCodeButton;