/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
/**
 * Check if a given object implements the CreateFinalTestFormBody interface.
 */
export function instanceOfCreateFinalTestFormBody(value) {
    let isInstance = true;
    isInstance = isInstance && "equipmentID" in value;
    isInstance = isInstance && "siteID" in value;
    return isInstance;
}
export function CreateFinalTestFormBodyFromJSON(json) {
    return CreateFinalTestFormBodyFromJSONTyped(json, false);
}
export function CreateFinalTestFormBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'equipmentID': json['equipmentID'],
        'siteID': json['siteID'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'baseType': !exists(json, 'baseType') ? undefined : json['baseType'],
        'travelGear': !exists(json, 'travelGear') ? undefined : json['travelGear'],
        'towerHeight': !exists(json, 'towerHeight') ? undefined : json['towerHeight'],
        'heightUnderHook': !exists(json, 'heightUnderHook') ? undefined : json['heightUnderHook'],
        'jibLength': !exists(json, 'jibLength') ? undefined : json['jibLength'],
        'requiredPowerSupplyVolts': !exists(json, 'requiredPowerSupplyVolts') ? undefined : json['requiredPowerSupplyVolts'],
        'phase': !exists(json, 'phase') ? undefined : json['phase'],
        'amps': !exists(json, 'amps') ? undefined : json['amps'],
        'measuredPowerSupplyUnderLoad': !exists(json, 'measuredPowerSupplyUnderLoad') ? undefined : json['measuredPowerSupplyUnderLoad'],
        'noLoad': !exists(json, 'noLoad') ? undefined : json['noLoad'],
        'trollyInLimitSlow': !exists(json, 'trollyInLimitSlow') ? undefined : json['trollyInLimitSlow'],
        'trollyInLimitFinal': !exists(json, 'trollyInLimitFinal') ? undefined : json['trollyInLimitFinal'],
        'trollyOutLimitSlow': !exists(json, 'trollyOutLimitSlow') ? undefined : json['trollyOutLimitSlow'],
        'trollyOutLimitFinal': !exists(json, 'trollyOutLimitFinal') ? undefined : json['trollyOutLimitFinal'],
        'hoistUpSlow': !exists(json, 'hoistUpSlow') ? undefined : json['hoistUpSlow'],
        'hoistUpFinal': !exists(json, 'hoistUpFinal') ? undefined : json['hoistUpFinal'],
        'hoistDownSlow': !exists(json, 'hoistDownSlow') ? undefined : json['hoistDownSlow'],
        'hoistDownFinal': !exists(json, 'hoistDownFinal') ? undefined : json['hoistDownFinal'],
        'travelInLimitSlow': !exists(json, 'travelInLimitSlow') ? undefined : json['travelInLimitSlow'],
        'travelInLimitFinal': !exists(json, 'travelInLimitFinal') ? undefined : json['travelInLimitFinal'],
        'travelOutLimitSlow': !exists(json, 'travelOutLimitSlow') ? undefined : json['travelOutLimitSlow'],
        'travelOutLimitFinal': !exists(json, 'travelOutLimitFinal') ? undefined : json['travelOutLimitFinal'],
        'slowingLimitInstalled': !exists(json, 'slowingLimitInstalled') ? undefined : json['slowingLimitInstalled'],
        'verifyTheCraneCannotMakeMoreThan2TurnsToEachSide': !exists(json, 'verifyTheCraneCannotMakeMoreThan2TurnsToEachSide') ? undefined : json['verifyTheCraneCannotMakeMoreThan2TurnsToEachSide'],
        'requiredWeightForTipLimit': !exists(json, 'requiredWeightForTipLimit') ? undefined : json['requiredWeightForTipLimit'],
        'primaryWeightUsedForTipLimit': !exists(json, 'primaryWeightUsedForTipLimit') ? undefined : json['primaryWeightUsedForTipLimit'],
        'kickerWeightUsedForTipLimit': !exists(json, 'kickerWeightUsedForTipLimit') ? undefined : json['kickerWeightUsedForTipLimit'],
        'requiredWeightForMaxLinePull': !exists(json, 'requiredWeightForMaxLinePull') ? undefined : json['requiredWeightForMaxLinePull'],
        'setPercentOfMaxLinePull': !exists(json, 'setPercentOfMaxLinePull') ? undefined : json['setPercentOfMaxLinePull'],
        'primaryWeightUsedForMaxLinePull': !exists(json, 'primaryWeightUsedForMaxLinePull') ? undefined : json['primaryWeightUsedForMaxLinePull'],
        'kickerWeightUsedForMaxLinePull': !exists(json, 'kickerWeightUsedForMaxLinePull') ? undefined : json['kickerWeightUsedForMaxLinePull'],
        'isCraneGrounded': !exists(json, 'isCraneGrounded') ? undefined : json['isCraneGrounded'],
        'deadManControlOperational': !exists(json, 'deadManControlOperational') ? undefined : json['deadManControlOperational'],
        'constantPressureControls': !exists(json, 'constantPressureControls') ? undefined : json['constantPressureControls'],
        'springLoadedControls': !exists(json, 'springLoadedControls') ? undefined : json['springLoadedControls'],
        'controlFunctionsTested': !exists(json, 'controlFunctionsTested') ? undefined : json['controlFunctionsTested'],
        'eStopFunctioning': !exists(json, 'eStopFunctioning') ? undefined : json['eStopFunctioning'],
        'operatorInformationDisplayInCab': !exists(json, 'operatorInformationDisplayInCab') ? undefined : json['operatorInformationDisplayInCab'],
        'displayCalibrated': !exists(json, 'displayCalibrated') ? undefined : json['displayCalibrated'],
        'zoningSystemInstalled': !exists(json, 'zoningSystemInstalled') ? undefined : json['zoningSystemInstalled'],
        'craneOperatorOriented': !exists(json, 'craneOperatorOriented') ? undefined : json['craneOperatorOriented'],
        'minimumFreeSlewRadius': !exists(json, 'minimumFreeSlewRadius') ? undefined : json['minimumFreeSlewRadius'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'licenseNumber': !exists(json, 'licenseNumber') ? undefined : json['licenseNumber'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
    };
}
export function CreateFinalTestFormBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'equipmentID': value.equipmentID,
        'siteID': value.siteID,
        'date': value.date,
        'baseType': value.baseType,
        'travelGear': value.travelGear,
        'towerHeight': value.towerHeight,
        'heightUnderHook': value.heightUnderHook,
        'jibLength': value.jibLength,
        'requiredPowerSupplyVolts': value.requiredPowerSupplyVolts,
        'phase': value.phase,
        'amps': value.amps,
        'measuredPowerSupplyUnderLoad': value.measuredPowerSupplyUnderLoad,
        'noLoad': value.noLoad,
        'trollyInLimitSlow': value.trollyInLimitSlow,
        'trollyInLimitFinal': value.trollyInLimitFinal,
        'trollyOutLimitSlow': value.trollyOutLimitSlow,
        'trollyOutLimitFinal': value.trollyOutLimitFinal,
        'hoistUpSlow': value.hoistUpSlow,
        'hoistUpFinal': value.hoistUpFinal,
        'hoistDownSlow': value.hoistDownSlow,
        'hoistDownFinal': value.hoistDownFinal,
        'travelInLimitSlow': value.travelInLimitSlow,
        'travelInLimitFinal': value.travelInLimitFinal,
        'travelOutLimitSlow': value.travelOutLimitSlow,
        'travelOutLimitFinal': value.travelOutLimitFinal,
        'slowingLimitInstalled': value.slowingLimitInstalled,
        'verifyTheCraneCannotMakeMoreThan2TurnsToEachSide': value.verifyTheCraneCannotMakeMoreThan2TurnsToEachSide,
        'requiredWeightForTipLimit': value.requiredWeightForTipLimit,
        'primaryWeightUsedForTipLimit': value.primaryWeightUsedForTipLimit,
        'kickerWeightUsedForTipLimit': value.kickerWeightUsedForTipLimit,
        'requiredWeightForMaxLinePull': value.requiredWeightForMaxLinePull,
        'setPercentOfMaxLinePull': value.setPercentOfMaxLinePull,
        'primaryWeightUsedForMaxLinePull': value.primaryWeightUsedForMaxLinePull,
        'kickerWeightUsedForMaxLinePull': value.kickerWeightUsedForMaxLinePull,
        'isCraneGrounded': value.isCraneGrounded,
        'deadManControlOperational': value.deadManControlOperational,
        'constantPressureControls': value.constantPressureControls,
        'springLoadedControls': value.springLoadedControls,
        'controlFunctionsTested': value.controlFunctionsTested,
        'eStopFunctioning': value.eStopFunctioning,
        'operatorInformationDisplayInCab': value.operatorInformationDisplayInCab,
        'displayCalibrated': value.displayCalibrated,
        'zoningSystemInstalled': value.zoningSystemInstalled,
        'craneOperatorOriented': value.craneOperatorOriented,
        'minimumFreeSlewRadius': value.minimumFreeSlewRadius,
        'name': value.name,
        'licenseNumber': value.licenseNumber,
        'comments': value.comments,
    };
}
