import React, {ReactNode} from "react";
import {PartsAndSupplies, UserType} from "client";
import {valueOrBustPrintables} from "../../../utils/valueOrBust";
import {asPercentOf100} from "../../../utils/asPercentOf100";

interface IProps {
	partsAndSuppliesArray?: Array<PartsAndSupplies>;
	userType: UserType;
}

const smallTableColumnWidth: string = `${asPercentOf100(1 / 6)}%`

const PrintablePartsAndSuppliesTable: React.FC<IProps> = (props) => {

	function makePartsEntry(entry: PartsAndSupplies, i: number): ReactNode {
		return (
			<tr key={`parts-and-supplies-entry_${i}_${entry.partNumber}_${entry.description}`}>
				<td style={{width: smallTableColumnWidth}}>
					{valueOrBustPrintables(entry.quantity?.toString())}
				</td>

				{props.userType !== UserType.SUPERVISOR && (
					<td style={{width: smallTableColumnWidth}}>
						{valueOrBustPrintables(entry.partNumber)}
					</td>
				)}

				<td>
					{valueOrBustPrintables(entry.description)}
				</td>
			</tr>
		);
	}

	return (
		<div style={{width: "100%"}}>
			<table className="printable__table">
				<tr>
					<td className="printable__table__label printable__table__centered">
						Qty
					</td>

					{props.userType !== UserType.SUPERVISOR && (
						<td className="printable__table__label printable__table__centered">
							Part Number
						</td>
					)}

					<td className="printable__table__label printable__table__centered">
						Description
					</td>
				</tr>

				{props.partsAndSuppliesArray?.length > 0 ? (
					<React.Fragment>
						{props.partsAndSuppliesArray?.map(makePartsEntry)}
					</React.Fragment>
				) : (
					<td
						className="printable__table__centered"
						colSpan={3}
					>
						No Entries
					</td>
				)}
			</table>
		</div>
	);
};

export default PrintablePartsAndSuppliesTable;
