import React from "react";
import CreateCraneWeeklyLogForm from "../../components/forms/CreateCraneWeeklyLogForm";

const CreateCraneWeeklyLogPage: React.FC = () => {

	return (
		<CreateCraneWeeklyLogForm/>
	);
};

export default CreateCraneWeeklyLogPage;
