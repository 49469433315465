import React, {ReactNode} from "react";
import {GeneratorDailyLog, LogComment} from "client";
import { find } from "lodash";
import moment from "moment";
import {valueOrBustPrintables} from "../../../utils/valueOrBust";
import {parseInspectionStatusForPrintableTable} from "../../../utils/parseInspectionStatus";
import {convertBooleanToString} from "../../../utils/convertBooleanToString";
import {FiCheck} from "react-icons/fi";
import {FiX} from "react-icons/all";
import PrintableSpacer from "./PrintableSpacer";
import {getFullNameForTable} from "../../../utils/getFullNameForTable";

interface IProps {
	weekOf: number;
	generatorDailyLogs: Array<GeneratorDailyLog>;
}

const PrintableGeneratorDailyLogChecklistTable: React.FC<IProps> = (props) => {

	/**
	 * Renders a check mark if any flagged comments are found,
	 * otherwise renders and "x".
	 *
	 * @param _log
	 */
	function getEntryMadeDeficiencyReport(_log: GeneratorDailyLog): ReactNode {
		return _log?.comments?.reduce((acc: number, comment: LogComment) => {
			if (comment.flagged) {
				acc++;
			}

			return acc;
		}, 0) > 0 ? (
			<React.Fragment>
				<FiCheck className="printable__react-icon"/>
			</React.Fragment>
		) : (
			<React.Fragment>
				<FiX className="printable__react-icon"/>
			</React.Fragment>
		);
	}

	function renderDailyOperators(log: GeneratorDailyLog): ReactNode {
		return log?.users?.map((u) => {
			return (
				<p style={{whiteSpace: "pre"}}>
					{`${getFullNameForTable(u)} -\n#${u.certificationNumber}`}
				</p>
			);
		});
	}

	/**
	 * Create reusable variables for each log.
	 *
	 */
	const sundayLog: GeneratorDailyLog = props.generatorDailyLogs[0];
	const mondayLog: GeneratorDailyLog = props.generatorDailyLogs[1];
	const tuesdayLog: GeneratorDailyLog = props.generatorDailyLogs[2];
	const wednesdayLog: GeneratorDailyLog = props.generatorDailyLogs[3];
	const thursdayLog: GeneratorDailyLog = props.generatorDailyLogs[4];
	const fridayLog: GeneratorDailyLog = props.generatorDailyLogs[5];
	const saturdayLog: GeneratorDailyLog = props.generatorDailyLogs[6];

	return (
		<div style={{width: "100%"}}>
			<table className="printable__table">
				<tr>
					<td className="printable__table__label">
						#
					</td>

					<td className="printable__table__label">
						Inspection Item
					</td>

					<td className="printable__table__label printable__table__centered">
						SUN
					</td>

					<td className="printable__table__label printable__table__centered">
						MON
					</td>

					<td className="printable__table__label printable__table__centered">
						Tue
					</td>

					<td className="printable__table__label printable__table__centered">
						WED
					</td>

					<td className="printable__table__label printable__table__centered">
						THU
					</td>

					<td className="printable__table__label printable__table__centered">
						FRI
					</td>

					<td className="printable__table__label printable__table__centered">
						SAT
					</td>
				</tr>

				<tr>
					<td className="printable__table__label">
						1
					</td>

					<td>
						Check oil level
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(sundayLog, parseInspectionStatusForPrintableTable("checkOilLevel", sundayLog?.checkOilLevel))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(mondayLog, parseInspectionStatusForPrintableTable("checkOilLevel", mondayLog?.checkOilLevel))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(tuesdayLog, parseInspectionStatusForPrintableTable("checkOilLevel", tuesdayLog?.checkOilLevel))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(wednesdayLog, parseInspectionStatusForPrintableTable("checkOilLevel", wednesdayLog?.checkOilLevel))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(thursdayLog, parseInspectionStatusForPrintableTable("checkOilLevel", thursdayLog?.checkOilLevel))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(fridayLog, parseInspectionStatusForPrintableTable("checkOilLevel", fridayLog?.checkOilLevel))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(saturdayLog, parseInspectionStatusForPrintableTable("checkOilLevel", saturdayLog?.checkOilLevel))}
					</td>
				</tr>

				<tr>
					<td className="printable__table__label">
						2
					</td>

					<td>
						Check coolant levels
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(sundayLog, parseInspectionStatusForPrintableTable("checkCoolantLevels", sundayLog?.checkCoolantLevels))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(mondayLog, parseInspectionStatusForPrintableTable("checkCoolantLevels", mondayLog?.checkCoolantLevels))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(tuesdayLog, parseInspectionStatusForPrintableTable("checkCoolantLevels", tuesdayLog?.checkCoolantLevels))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(wednesdayLog, parseInspectionStatusForPrintableTable("checkCoolantLevels", wednesdayLog?.checkCoolantLevels))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(thursdayLog, parseInspectionStatusForPrintableTable("checkCoolantLevels", thursdayLog?.checkCoolantLevels))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(fridayLog, parseInspectionStatusForPrintableTable("checkCoolantLevels", fridayLog?.checkCoolantLevels))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(saturdayLog, parseInspectionStatusForPrintableTable("checkCoolantLevels", saturdayLog?.checkCoolantLevels))}
					</td>
				</tr>

				<tr>
					<td className="printable__table__label">
						3
					</td>

					<td>
						Visual inspection for wear or damage
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(sundayLog, parseInspectionStatusForPrintableTable("visualInspectionForWear", sundayLog?.visualInspectionForWear))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(mondayLog, parseInspectionStatusForPrintableTable("visualInspectionForWear", mondayLog?.visualInspectionForWear))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(tuesdayLog, parseInspectionStatusForPrintableTable("visualInspectionForWear", tuesdayLog?.visualInspectionForWear))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(wednesdayLog, parseInspectionStatusForPrintableTable("visualInspectionForWear", wednesdayLog?.visualInspectionForWear))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(thursdayLog, parseInspectionStatusForPrintableTable("visualInspectionForWear", thursdayLog?.visualInspectionForWear))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(fridayLog, parseInspectionStatusForPrintableTable("visualInspectionForWear", fridayLog?.visualInspectionForWear))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(saturdayLog, parseInspectionStatusForPrintableTable("visualInspectionForWear", saturdayLog?.visualInspectionForWear))}
					</td>
				</tr>

				<tr>
					<td className="printable__table__label">
						4
					</td>

					<td>
						Check for oil, fuel, or water leakage
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(sundayLog, parseInspectionStatusForPrintableTable("checkForLeakage", sundayLog?.checkForLeakage))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(mondayLog, parseInspectionStatusForPrintableTable("checkForLeakage", mondayLog?.checkForLeakage))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(tuesdayLog, parseInspectionStatusForPrintableTable("checkForLeakage", tuesdayLog?.checkForLeakage))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(wednesdayLog, parseInspectionStatusForPrintableTable("checkForLeakage", wednesdayLog?.checkForLeakage))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(thursdayLog, parseInspectionStatusForPrintableTable("checkForLeakage", thursdayLog?.checkForLeakage))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(fridayLog, parseInspectionStatusForPrintableTable("checkForLeakage", fridayLog?.checkForLeakage))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(saturdayLog, parseInspectionStatusForPrintableTable("checkForLeakage", saturdayLog?.checkForLeakage))}
					</td>
				</tr>

				<tr>
					<td className="printable__table__label">
						5
					</td>

					<td>
						Ensure all doors, panels, and covers are closed and in place after starting
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(sundayLog, parseInspectionStatusForPrintableTable("ensureDoorPanelsAndCoversAreClosed", sundayLog?.ensureDoorPanelsAndCoversAreClosed))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(mondayLog, parseInspectionStatusForPrintableTable("ensureDoorPanelsAndCoversAreClosed", mondayLog?.ensureDoorPanelsAndCoversAreClosed))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(tuesdayLog, parseInspectionStatusForPrintableTable("ensureDoorPanelsAndCoversAreClosed", tuesdayLog?.ensureDoorPanelsAndCoversAreClosed))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(wednesdayLog, parseInspectionStatusForPrintableTable("ensureDoorPanelsAndCoversAreClosed", wednesdayLog?.ensureDoorPanelsAndCoversAreClosed))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(thursdayLog, parseInspectionStatusForPrintableTable("ensureDoorPanelsAndCoversAreClosed", thursdayLog?.ensureDoorPanelsAndCoversAreClosed))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(fridayLog, parseInspectionStatusForPrintableTable("ensureDoorPanelsAndCoversAreClosed", fridayLog?.ensureDoorPanelsAndCoversAreClosed))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(saturdayLog, parseInspectionStatusForPrintableTable("ensureDoorPanelsAndCoversAreClosed", saturdayLog?.ensureDoorPanelsAndCoversAreClosed))}
					</td>
				</tr>

				<tr className="printable__table__spacer-row">
					<PrintableSpacer size={10}/>
				</tr>

				<tr>
					<td className="printable__table__label" colSpan={2}>
						Operators
					</td>

					<td className="printable__table__centered">
						{renderDailyOperators(sundayLog)}
					</td>

					<td className="printable__table__centered">
						{renderDailyOperators(mondayLog)}
					</td>

					<td className="printable__table__centered">
						{renderDailyOperators(tuesdayLog)}
					</td>

					<td className="printable__table__centered">
						{renderDailyOperators(wednesdayLog)}
					</td>

					<td className="printable__table__centered">
						{renderDailyOperators(thursdayLog)}
					</td>

					<td className="printable__table__centered">
						{renderDailyOperators(fridayLog)}
					</td>

					<td className="printable__table__centered">
						{renderDailyOperators(saturdayLog)}
					</td>
				</tr>

				<tr>
					<td className="printable__table__label" colSpan={2}>
						Operator Daily Initial
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(sundayLog, convertBooleanToString(sundayLog?.initials))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(mondayLog, convertBooleanToString(mondayLog?.initials))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(tuesdayLog, convertBooleanToString(tuesdayLog?.initials))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(wednesdayLog, convertBooleanToString(wednesdayLog?.initials))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(thursdayLog, convertBooleanToString(thursdayLog?.initials))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(fridayLog, convertBooleanToString(fridayLog?.initials))}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(saturdayLog, convertBooleanToString(saturdayLog?.initials))}
					</td>
				</tr>

				<tr>
					<td className="printable__table__label" colSpan={2}>
						Daily Generator Hours
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(sundayLog, sundayLog?.hours?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(mondayLog, mondayLog?.hours?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(tuesdayLog, tuesdayLog?.hours?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(wednesdayLog, wednesdayLog?.hours?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(thursdayLog, thursdayLog?.hours?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(fridayLog, fridayLog?.hours?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(saturdayLog, saturdayLog?.hours?.toString())}
					</td>
				</tr>

				<tr className="printable__table__spacer-row">
					<td className="printable__table__no-padding" colSpan={9}>
						<PrintableSpacer size={10}/>
						<p className="printable__sub-header">
							Generator Hour Meter
						</p>
					</td>
				</tr>

				<tr>
					<td className="printable__table__label" colSpan={2}>
						Record the <u>Hour Meter</u> Value at the Beginning of the Day:
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(sundayLog, sundayLog?.hourMeterStart?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(mondayLog, mondayLog?.hourMeterStart?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(tuesdayLog, tuesdayLog?.hourMeterStart?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(wednesdayLog, wednesdayLog?.hourMeterStart?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(thursdayLog, thursdayLog?.hourMeterStart?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(fridayLog, fridayLog?.hourMeterStart?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(saturdayLog, saturdayLog?.hourMeterStart?.toString())}
					</td>
				</tr>

				<tr>
					<td className="printable__table__label" colSpan={2}>
						Record the <u>Hour Meter</u> Value at the End of the Day:
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(sundayLog, sundayLog?.hourMeterEnd?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(mondayLog, mondayLog?.hourMeterEnd?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(tuesdayLog, tuesdayLog?.hourMeterEnd?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(wednesdayLog, wednesdayLog?.hourMeterEnd?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(thursdayLog, thursdayLog?.hourMeterEnd?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(fridayLog, fridayLog?.hourMeterEnd?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(saturdayLog, saturdayLog?.hourMeterEnd?.toString())}
					</td>
				</tr>

				<tr>
					<td className="printable__table__label" colSpan={2}>
						Total <u>Hour Meter</u> Value for Next Service:
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(sundayLog, sundayLog?.hourMeterTotal?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(mondayLog, mondayLog?.hourMeterTotal?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(tuesdayLog, tuesdayLog?.hourMeterTotal?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(wednesdayLog, wednesdayLog?.hourMeterTotal?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(thursdayLog, thursdayLog?.hourMeterTotal?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(fridayLog, fridayLog?.hourMeterTotal?.toString())}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(saturdayLog, saturdayLog?.hourMeterTotal?.toString())}
					</td>
				</tr>

				<tr className="printable__table__spacer-row">
					<PrintableSpacer size={10}/>
				</tr>

				<tr>
					<td className="printable__table__label" colSpan={2}>
						Deficiency Reports Made
					</td>

					<td className="printable__table__centered">
						{getEntryMadeDeficiencyReport(sundayLog)}
					</td>

					<td className="printable__table__centered">
						{getEntryMadeDeficiencyReport(mondayLog)}
					</td>

					<td className="printable__table__centered">
						{getEntryMadeDeficiencyReport(tuesdayLog)}
					</td>

					<td className="printable__table__centered">
						{getEntryMadeDeficiencyReport(wednesdayLog)}
					</td>

					<td className="printable__table__centered">
						{getEntryMadeDeficiencyReport(thursdayLog)}
					</td>

					<td className="printable__table__centered">
						{getEntryMadeDeficiencyReport(fridayLog)}
					</td>

					<td className="printable__table__centered">
						{getEntryMadeDeficiencyReport(saturdayLog)}
					</td>
				</tr>
			</table>
		</div>
	);
};

export default PrintableGeneratorDailyLogChecklistTable;

