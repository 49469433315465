import {UserType} from "client";
import {IAutoCompleteOption} from "../components/inputs/AlliedAutoComplete";

export const autoCompleteAdminRoles: Array<IAutoCompleteOption> = [
	{value: UserType.ADMIN, label: "Admin"},
	{value: UserType.ALLIED_CRANE_EMPLOYEE, label: "Allied Crane Employee"},
	{value: UserType.CRANE_OPERATOR, label: "Crane Operator"},
	{value: UserType.HEALTH_AND_SAFETY_OFFICER, label: "Health & Safety Officer"},
	{value: UserType.SECONDARY_ADMIN, label: "Manager"},
	{value: UserType.SUPERVISOR, label: "Supervisor"},
];
