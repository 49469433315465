import React, {ReactNode} from "react";

interface IProps {
	children?: ReactNode;
}

const AlliedContainer: React.FC<IProps> = (props) => {

	return (
		<div className="allied-container">
			<img
				src={process.env.PUBLIC_URL + "/images/city-scape.svg"}
				className="allied-container-bg"
				alt="allied landscape"
			/>
			{props.children}
		</div>
	);
};

export default AlliedContainer;
