import {
	CreateLuffingTowerCraneDailyLogBody,
	CreateSelfErectingCraneDailyLogBody,
	CreateTowerCraneDailyLogBody
} from "client";

/**
 * Parse the keys from the interfaces in the strings from the PDFs.
 *
 * @param key
 */
export function getCraneDailyLogInspectionItemDisplayName(key: keyof CreateLuffingTowerCraneDailyLogBody | keyof CreateSelfErectingCraneDailyLogBody | keyof CreateTowerCraneDailyLogBody): string {
	switch (key) {
		// Generic
		case "electricalPowerCords":
			return "Electrical power cords, main, feed, junction box/splice";
		case "groundFaultCircuitInterrupter":
			return "Ground fault circuit interrupter (GFCI)";
		case "onOffSwitch":
			return "ON/OFF switch (main disconnect)";
		case "craneBaseInspection":
			return "Crane base inspection";
		case "inspectWalkways":
			return "Inspect walkways, handrails, guards, and ladders";
		case "inspectStructure":
			return "Inspect structure, pins, keepers, and mast bolts";
		case "ensureAllDoors":
			return "Ensure all doors, panels, and covers are in place and weather tight";
		case "operatorsControlsAdequate":
			return "Operator's controls are functioning adequately";
		case "loadMomentHoistLimit":
			return "Load moment hoist limit";
		case "confirmLocationOfZoning":
			return "Confirm location of zoning/anti-collision system during Tip test (if applicable)";
		case "maximumLoad":
			return "Maximum load (line pull)";
		case "hoistUpDecelerationLimit":
			return "Hoist up deceleration limit";
		case "hoistUpperLimit":
			return "Hoist upper limit";
		case "hoistDownLimit":
			return "Hoist down limit or slack line";
		case "ensureAllAudioVisualIndicatorsFunction":
			return "Ensure all audio/visual indicators are functioning properly";
		case "anemometer":
			return "Anemometer";
		case "hoistBrakeFunctioning":
			return "Hoist brake functioning";
		case "slewingBrakeFunctioning":
			return "Slewing brake functioning";
		case "visuallyInspectLoadBlockAndHook":
			return "Visually inspect load block and hook";
		case "travelBrakeToRail":
			return "Travel brake to rail where applicable";
		case "railTravelForwardAndReverse":
			return "Rail travel forward and reverse";
		case "inspectTracksForLoseConnections":
			return "Inspect tracks for loose connections, proper drainage, subsidence and wear";
		case "housekeeping":
			return "Housekeeping; Concrete debris, rebar dowels, signage lights, access/egress";
		case "supervisorNotedOfAnyDefectsOrFaults":
			return "Supervisor noted of any defects or faults";

		// Luffing Tower
		case "ensureAllTower":
			return "Ensure all tower wedges or tie backs are in place and tight";
		case "loadMomentLuffingLimit":
			return "Load moment luffing limit";
		case "luffingUpSwitch":
			return "Luffing up switch(s)";
		case "luffingDownSwitch":
			return "Luffing down switch(s)";
		case "luffingUpAndDownDecelerationSwitch":
			return "Luffing up and down deceleration switch";
		case "boomAngleIndicator":
			return "Boom angle indicator";
		case "counterweightSafetySwitch":
			return "Counterweight safety switch";
		case "antiTwoBlockSwitch":
			return "Anti 2 block switch (hoist up and boom down)";
		case "luffingBrake":
			return "Luffing brake";

		// Self Erecting
		case "loadMomentTrolleyLimit":
			return "Load moment trolley limit";
		case "trolleyOut":
			return "Trolley out / Boom down limit";
		case "trolleyIn":
			return "Trolley in / Boom up limit";
		case "trolleyBrake":
			return "Trolley brake (when applicable)";
		case "baseLevel":
			return "Base level";
		case "foundationCondition":
			return "Foundation condition";

		// Tower
		// all fields for Tower are covered between the Luffing & Self Erecting

		default:
			return key;
	}
}
