/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CreateLogCommentBodyFromJSON, CreateLogCommentBodyToJSON, } from './CreateLogCommentBody';
import { InspectionStatusFromJSON, InspectionStatusToJSON, } from './InspectionStatus';
/**
 * Check if a given object implements the CreateCraneMonthlyLogBody interface.
 */
export function instanceOfCreateCraneMonthlyLogBody(value) {
    let isInstance = true;
    isInstance = isInstance && "equipmentID" in value;
    isInstance = isInstance && "siteID" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "initials" in value;
    return isInstance;
}
export function CreateCraneMonthlyLogBodyFromJSON(json) {
    return CreateCraneMonthlyLogBodyFromJSONTyped(json, false);
}
export function CreateCraneMonthlyLogBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'equipmentID': json['equipmentID'],
        'siteID': json['siteID'],
        'date': json['date'],
        'initials': json['initials'],
        'createLogCommentBodies': !exists(json, 'createLogCommentBodies') ? undefined : (json['createLogCommentBodies'].map(CreateLogCommentBodyFromJSON)),
        'visualInspectionAndNoteBogieWear': !exists(json, 'visualInspectionAndNoteBogieWear') ? undefined : InspectionStatusFromJSON(json['visualInspectionAndNoteBogieWear']),
        'visualInspectionOfCriticalAreas': !exists(json, 'visualInspectionOfCriticalAreas') ? undefined : InspectionStatusFromJSON(json['visualInspectionOfCriticalAreas']),
        'visualInspectionOfWearOfPad': !exists(json, 'visualInspectionOfWearOfPad') ? undefined : InspectionStatusFromJSON(json['visualInspectionOfWearOfPad']),
        'inspectHoisting': !exists(json, 'inspectHoisting') ? undefined : InspectionStatusFromJSON(json['inspectHoisting']),
        'checkWireRope': !exists(json, 'checkWireRope') ? undefined : InspectionStatusFromJSON(json['checkWireRope']),
        'greaseTheHoisting': !exists(json, 'greaseTheHoisting') ? undefined : InspectionStatusFromJSON(json['greaseTheHoisting']),
        'visualInspectionOfCabSupport': !exists(json, 'visualInspectionOfCabSupport') ? undefined : InspectionStatusFromJSON(json['visualInspectionOfCabSupport']),
        'visualInspectionForOfSlewRingTeeth': !exists(json, 'visualInspectionForOfSlewRingTeeth') ? undefined : InspectionStatusFromJSON(json['visualInspectionForOfSlewRingTeeth']),
        'checkAllGearboxes': !exists(json, 'checkAllGearboxes') ? undefined : InspectionStatusFromJSON(json['checkAllGearboxes']),
        'visualInspectionOfPendantLineConnections': !exists(json, 'visualInspectionOfPendantLineConnections') ? undefined : InspectionStatusFromJSON(json['visualInspectionOfPendantLineConnections']),
        'supervisorNotifiedOfDefects': !exists(json, 'supervisorNotifiedOfDefects') ? undefined : InspectionStatusFromJSON(json['supervisorNotifiedOfDefects']),
    };
}
export function CreateCraneMonthlyLogBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'equipmentID': value.equipmentID,
        'siteID': value.siteID,
        'date': value.date,
        'initials': value.initials,
        'createLogCommentBodies': value.createLogCommentBodies === undefined ? undefined : (value.createLogCommentBodies.map(CreateLogCommentBodyToJSON)),
        'visualInspectionAndNoteBogieWear': InspectionStatusToJSON(value.visualInspectionAndNoteBogieWear),
        'visualInspectionOfCriticalAreas': InspectionStatusToJSON(value.visualInspectionOfCriticalAreas),
        'visualInspectionOfWearOfPad': InspectionStatusToJSON(value.visualInspectionOfWearOfPad),
        'inspectHoisting': InspectionStatusToJSON(value.inspectHoisting),
        'checkWireRope': InspectionStatusToJSON(value.checkWireRope),
        'greaseTheHoisting': InspectionStatusToJSON(value.greaseTheHoisting),
        'visualInspectionOfCabSupport': InspectionStatusToJSON(value.visualInspectionOfCabSupport),
        'visualInspectionForOfSlewRingTeeth': InspectionStatusToJSON(value.visualInspectionForOfSlewRingTeeth),
        'checkAllGearboxes': InspectionStatusToJSON(value.checkAllGearboxes),
        'visualInspectionOfPendantLineConnections': InspectionStatusToJSON(value.visualInspectionOfPendantLineConnections),
        'supervisorNotifiedOfDefects': InspectionStatusToJSON(value.supervisorNotifiedOfDefects),
    };
}
