/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { EquipmentTypeFromJSON, EquipmentTypeToJSON, } from './EquipmentType';
import { SiteFromJSON, SiteToJSON, } from './Site';
import { CraneFromJSONTyped, GeneratorFromJSONTyped, MaterialHoistFromJSONTyped } from './';
/**
 * Check if a given object implements the Equipment interface.
 */
export function instanceOfEquipment(value) {
    let isInstance = true;
    isInstance = isInstance && "_id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "disabled" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "ownedBy" in value;
    isInstance = isInstance && "make" in value;
    isInstance = isInstance && "model" in value;
    isInstance = isInstance && "serialNumber" in value;
    isInstance = isInstance && "preExistingUsageHours" in value;
    isInstance = isInstance && "currentUsageHours" in value;
    isInstance = isInstance && "site" in value;
    return isInstance;
}
export function EquipmentFromJSON(json) {
    return EquipmentFromJSONTyped(json, false);
}
export function EquipmentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['type'] === 'CRANE') {
            return CraneFromJSONTyped(json, true);
        }
        if (json['type'] === 'GENERATOR') {
            return GeneratorFromJSONTyped(json, true);
        }
        if (json['type'] === 'MATERIAL_HOIST') {
            return MaterialHoistFromJSONTyped(json, true);
        }
    }
    return {
        '_id': json['_id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'disabled': json['disabled'],
        'type': EquipmentTypeFromJSON(json['type']),
        'ownedBy': json['ownedBy'],
        'rentedBy': !exists(json, 'rentedBy') ? undefined : json['rentedBy'],
        'make': json['make'],
        'model': json['model'],
        'serialNumber': json['serialNumber'],
        'preExistingUsageHours': json['preExistingUsageHours'],
        'currentUsageHours': json['currentUsageHours'],
        'site': SiteFromJSON(json['site']),
    };
}
export function EquipmentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'disabled': value.disabled,
        'type': EquipmentTypeToJSON(value.type),
        'ownedBy': value.ownedBy,
        'rentedBy': value.rentedBy,
        'make': value.make,
        'model': value.model,
        'serialNumber': value.serialNumber,
        'preExistingUsageHours': value.preExistingUsageHours,
        'currentUsageHours': value.currentUsageHours,
        'site': SiteToJSON(value.site),
    };
}
