import React from "react";

interface IProps {
	size: number; // value in rem
}

const Spacer: React.FC<IProps> = (props) => {

	return (
		<div style={{padding: `${props.size}rem`}}/>
	);
};

export default Spacer;
