import {LogHistoryType} from "client";

export function parseLogHistoryType(historyType: LogHistoryType): string {
	switch(historyType) {
		case LogHistoryType.CREATED:
			return "Created";
		case LogHistoryType.UPDATED:
			return "Updated";
		case LogHistoryType.PUBLISHED:
			return "Published";
		case LogHistoryType.SUBMITTED:
			return "Submitted";
	}
}
