import React, {ReactNode} from "react";
import {Deficiency, DeficiencyStatus} from "client";
import {valueOrBustPrintables} from "../../../utils/valueOrBust";
import moment from "moment";
import {getFullNameForTable} from "../../../utils/getFullNameForTable";
import {parseDeficiencyStatusForDisplay} from "../../../utils/parseDeficiencyStatusForDisplay";
import {FiCheck} from "react-icons/fi";
import {FiX} from "react-icons/all";
import {generateOffsetDate} from "../../../utils/timeZoneConversions";

interface IProps {
	deficiencies: Array<Deficiency>;
}

const PrintableDeficienciesTable: React.FC<IProps> = (props) => {

	function makeDeficiencyEntry(_deficiency: Deficiency, i: number): ReactNode {
		return (
			<tr key={`printable-equipment-deficiency-list-row_${_deficiency?._id}_${i}_${Math.random()}`}>
				<td>
					{valueOrBustPrintables(_deficiency?.date, moment(generateOffsetDate(_deficiency?.date)).format("MM/DD/YYYY"))}
				</td>

				<td className="printable__table__break-word">
					{valueOrBustPrintables(_deficiency?.site?.name)}
				</td>

				<td className="printable__table__break-word">
					{valueOrBustPrintables(_deficiency?.issue)}
				</td>

				<td>
					{valueOrBustPrintables(_deficiency?.owner, getFullNameForTable(_deficiency?.owner))}
				</td>

				<td>
					{valueOrBustPrintables(_deficiency?.status, parseDeficiencyStatusForDisplay(_deficiency?.status))}
				</td>

				<td className="printable__table__centered">
					{_deficiency?.status === DeficiencyStatus.RESOLVED ? (
						<React.Fragment>
							<FiCheck className="printable__react-icon"/>
						</React.Fragment>
					) : (
						<React.Fragment>
							<FiX className="printable__react-icon"/>
						</React.Fragment>
					)}
				</td>
			</tr>
		);
	}

	return (
		<div style={{width: "100%"}}>
			<table className="printable__table">
				<tr>
					{
						[
							"Date Added",
							"Site of Issue",
							"Issue",
							"Added By",
							"Status",
							"Resolved",
						].map((label: string, i: number) => {
							return (
								<td
									key={`deficiency-list-table-header_${label}_${i}_${Math.random()}`}
									className="printable__table__label"
								>
									{label}
								</td>
							);
						})
					}
				</tr>

				{props.deficiencies?.length > 0 ? (
					<React.Fragment>
						{props.deficiencies?.map(makeDeficiencyEntry)}
					</React.Fragment>
				) : (
					<tr>
						<td className="printable__table__centered" colSpan={9}>
							No Deficiencies Found
						</td>
					</tr>
				)}
			</table>
		</div>
	);
};

export default PrintableDeficienciesTable;
