import {CraneMonthlyLog, CreateCraneMonthlyLogBody} from "client";
import {defaultCreateCraneMonthlyLogForm} from "../components/forms/CreateCraneMonthlyLogForm";
import {generateOffsetDate} from "./timeZoneConversions";

export function convertCraneMonthlyLogResponseToCreateBody(res: CraneMonthlyLog): CreateCraneMonthlyLogBody {
	return {
		equipmentID: res.equipment?._id,
		siteID: res.originalSite?._id,
		date: res.date !== undefined ? generateOffsetDate(res.date) : undefined,
		initials: res.initials,
		createLogCommentBodies: defaultCreateCraneMonthlyLogForm.createLogCommentBodies,
		visualInspectionAndNoteBogieWear: res.visualInspectionAndNoteBogieWear,
		visualInspectionOfCriticalAreas: res.visualInspectionOfCriticalAreas,
		visualInspectionOfWearOfPad: res.visualInspectionOfWearOfPad,
		inspectHoisting: res.inspectHoisting,
		checkWireRope: res.checkWireRope,
		greaseTheHoisting: res.greaseTheHoisting,
		visualInspectionOfCabSupport: res.visualInspectionOfCabSupport,
		visualInspectionForOfSlewRingTeeth: res.visualInspectionForOfSlewRingTeeth,
		checkAllGearboxes: res.checkAllGearboxes,
		visualInspectionOfPendantLineConnections: res.visualInspectionOfPendantLineConnections,
		supervisorNotifiedOfDefects: res.supervisorNotifiedOfDefects,
	}
}
