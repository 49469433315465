/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the AddNotesToTimeSheetBody interface.
 */
export function instanceOfAddNotesToTimeSheetBody(value) {
    let isInstance = true;
    isInstance = isInstance && "timeSheetID" in value;
    isInstance = isInstance && "notes" in value;
    return isInstance;
}
export function AddNotesToTimeSheetBodyFromJSON(json) {
    return AddNotesToTimeSheetBodyFromJSONTyped(json, false);
}
export function AddNotesToTimeSheetBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'timeSheetID': json['timeSheetID'],
        'notes': json['notes'],
    };
}
export function AddNotesToTimeSheetBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'timeSheetID': value.timeSheetID,
        'notes': value.notes,
    };
}
