import React from "react";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button} from "reactstrap";
import AlliedModal from "./AlliedModal";

interface IProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
}

const ConfirmUnlockLogsModal: React.FC<IProps> = (props) => {

	return (
		<AlliedModal
			size="md"
			isOpen={props.isOpen}
		>
			<AlliedModalHeader
				title="Unlock these logs?"
				onClose={props.onClose}
			/>

			<hr/>

			<p>
				Are you sure you want to unlock these logs? This will "de-submit" them, and they will not be visible for you again until the associated user makes the required changes & re-submits the forms.
			</p>

			<div className="d-flex flex-row">
				<Button
					color="black"
					onClick={props.onClose}
					className="mr-3"
				>
					Cancel
				</Button>

				<Button
					color="red"
					onClick={props.onConfirm}
				>
					Confirm
				</Button>
			</div>
		</AlliedModal>
	);
};

export default ConfirmUnlockLogsModal;
