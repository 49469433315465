import {
	CraneMonthlyLog,
	CraneWeeklyLog,
	CraneDailyLogGroup,
	GeneratorDailyLogGroup,
	LogComment, CraneDailyLog, GeneratorDailyLog
} from "client";
import moment from "moment";

export function formatLastUpdatedLogDateAndFlaggedForGroupedDailyLogs(value: any, log: CraneDailyLogGroup | GeneratorDailyLogGroup): { value: string, className?: string } {
	const dailyLogs: Array<CraneDailyLog | GeneratorDailyLog> = [
		log["_0"],
		log["_1"],
		log["_2"],
		log["_3"],
		log["_4"],
		log["_5"],
		log["_6"],
	].filter(l => l !== undefined);

	const flags: number = dailyLogs.reduce((acc: number, log: CraneDailyLog | GeneratorDailyLog) => {
		if (log.comments) {
			acc += log.comments.reduce((_acc: number, comment: LogComment) => {
				if (comment.flagged) {
					_acc++;
				}
				return _acc;
			}, 0);
		}
		return acc;
	}, 0);

	return {
		value: moment(log.updatedAt).format("MM/DD/YYYY") + (flags > 0 ? ` - Flagged (${flags})` : ""),
		className: flags > 0 ? "text-red" : "",
	}
}

/**
 * Adds an indicator if at least 1 flagged comment is found on crane weekly or monthly logs.
 *
 * @param value
 * @param log
 */
export function formatLastUpdatedLogDateAndFlagged(value: any, log: CraneMonthlyLog | CraneWeeklyLog): { value: string, className?: string } {
	if (!log) {
		return {value: "-"}
	}

	const flags: number = log.comments.reduce((acc: number, comment: LogComment) => {
		if (comment.flagged) {
			acc++;
		}
		return acc;
	}, 0);

	return {
		value: moment(log.updatedAt).format("MM/DD/YYYY") + (flags > 0 ? ` - Flagged (${flags})` : ""),
		className: flags > 0 ? "text-red" : ""
	}
}
