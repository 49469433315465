import {useHistory} from "react-router-dom";
import {Button} from "reactstrap";
import React, {useState} from "react";
import {CraneDailyLogWithDate, GeneratorDailyLogWithDate} from "../../../utils/getDateOfIndividualDailyLogBasedOnDay";
import {CraneDailyLogGroup, GeneratorDailyLogGroup} from "client";
import CraneDailyLogsDeleteIndividualLogModal from "../../modals/CraneDailyLogsDeleteIndividualLogModal";
import GeneratorDailyLogsDeleteIndividualLogModal from "../../modals/GeneratorDailyLogsDeleteIndividualLogModal";

interface IProps {
	log: GeneratorDailyLogWithDate;
	logGroup: GeneratorDailyLogGroup;
	onDone: () => void;
}

const GeneratorDailyLogsDeleteIndividualLogCell: React.FC<IProps> = (props) => {

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	function toggleShowDeleteModal(): void {
		setShowDeleteModal(!showDeleteModal);
	}

	function onConfirmDelete(): void {
		setShowDeleteModal(false);
		props.onDone();
	}

	return (
		<React.Fragment>
			<GeneratorDailyLogsDeleteIndividualLogModal
				isOpen={showDeleteModal}
				log={props.log}
				logGroup={props.logGroup}
				onClose={toggleShowDeleteModal}
				onDone={onConfirmDelete}
			/>

			<div className="d-flex justify-content-center">
				<Button
					color="black"
					onClick={toggleShowDeleteModal}
					disabled={props.logGroup.submittedDate !== undefined}
				>
					Delete Log
				</Button>
			</div>
		</React.Fragment>
	);
}

export default GeneratorDailyLogsDeleteIndividualLogCell;
