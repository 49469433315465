/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the MaterialHoist interface.
 */
export function instanceOfMaterialHoist(value) {
    let isInstance = true;
    return isInstance;
}
export function MaterialHoistFromJSON(json) {
    return MaterialHoistFromJSONTyped(json, false);
}
export function MaterialHoistFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function MaterialHoistToJSON(value) {
    return value;
}
