/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { DeficiencyStatusFromJSON, DeficiencyStatusToJSON, } from './DeficiencyStatus';
import { EquipmentFromJSON, EquipmentToJSON, } from './Equipment';
import { FormFromJSONTyped, FormToJSON, } from './Form';
import { LogCommentFromJSON, LogCommentToJSON, } from './LogComment';
import { SiteFromJSON, SiteToJSON, } from './Site';
/**
 * Check if a given object implements the Deficiency interface.
 */
export function instanceOfDeficiency(value) {
    let isInstance = true;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "equipment" in value;
    isInstance = isInstance && "site" in value;
    isInstance = isInstance && "date" in value;
    return isInstance;
}
export function DeficiencyFromJSON(json) {
    return DeficiencyFromJSONTyped(json, false);
}
export function DeficiencyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...FormFromJSONTyped(json, ignoreDiscriminator),
        'status': DeficiencyStatusFromJSON(json['status']),
        'logComment': !exists(json, 'logComment') ? undefined : LogCommentFromJSON(json['logComment']),
        'equipment': EquipmentFromJSON(json['equipment']),
        'site': SiteFromJSON(json['site']),
        'date': json['date'],
        'issue': !exists(json, 'issue') ? undefined : json['issue'],
    };
}
export function DeficiencyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...FormToJSON(value),
        'status': DeficiencyStatusToJSON(value.status),
        'logComment': LogCommentToJSON(value.logComment),
        'equipment': EquipmentToJSON(value.equipment),
        'site': SiteToJSON(value.site),
        'date': value.date,
        'issue': value.issue,
    };
}
