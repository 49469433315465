/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { AddressFromJSON, AddressToJSON, } from './Address';
/**
 * Check if a given object implements the CreateSiteBody interface.
 */
export function instanceOfCreateSiteBody(value) {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "contactNumber" in value;
    isInstance = isInstance && "contactEmail" in value;
    isInstance = isInstance && "renter" in value;
    isInstance = isInstance && "siteOwner" in value;
    return isInstance;
}
export function CreateSiteBodyFromJSON(json) {
    return CreateSiteBodyFromJSONTyped(json, false);
}
export function CreateSiteBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'address': AddressFromJSON(json['address']),
        'contactNumber': json['contactNumber'],
        'contactEmail': json['contactEmail'],
        'renter': json['renter'],
        'siteOwner': json['siteOwner'],
        'userIDs': !exists(json, 'userIDs') ? undefined : json['userIDs'],
        'equipmentIDs': !exists(json, 'equipmentIDs') ? undefined : json['equipmentIDs'],
    };
}
export function CreateSiteBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'address': AddressToJSON(value.address),
        'contactNumber': value.contactNumber,
        'contactEmail': value.contactEmail,
        'renter': value.renter,
        'siteOwner': value.siteOwner,
        'userIDs': value.userIDs,
        'equipmentIDs': value.equipmentIDs,
    };
}
