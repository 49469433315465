import * as React from "react";
import {useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Button, Col, Container, Row} from "reactstrap";
import {$black, $gray, $red, $white} from "../style/_colors";
import AlliedSelect from "../components/inputs/AlliedSelect";
import AlliedInputCheckbox from "../components/inputs/AlliedInputCheckbox";
import AlliedInputFile from "../components/inputs/AlliedInputFile";
import AlliedAutoComplete from "../components/inputs/AlliedAutoComplete";

const colorPalette = [$red, $black, $white, $gray];

interface IProps {
	token?: string;
	dispatch?: any;
}

function renderColors(color: string, index: number) {
	return (
		<div key={`color-palette-${index}`} className="d-flex flex-column align-items-center">
			<div className="shadow rounded-circle" style={{height: 100, width: 100, backgroundColor: color}}/>
			<p className="py-3">{color}</p>
		</div>
	);
}

const DemoPage: React.FC<IProps> = (props: IProps) => {

	const [test1, setTest1] = useState(false);
	const [test2, setTest2] = useState(true);
	const [test3, setTest3] = useState();
	const [test4, setTest4] = useState();
	const [autoCompleteSelections, setAutoCompleteSelections] = useState<string[]>([]);

	return (
		<Container className="my-5">
			<Row>
				<Col xs={12}>
					<h1>Allied Crane UI Style Guide</h1>
				</Col>

				<Col xs={12} className="py-3">
					<h2>Colors & Typography</h2>
				</Col>

				<Col xs={12} sm={6} className="d-flex justify-content-between">
					{colorPalette.map(renderColors)}
				</Col>
			</Row>

			<div className="mb-5" style={{maxWidth: 300}}>
				<AlliedAutoComplete
					placeholder="Enter your test here..."
					options={[
						{label: "This is option 1", value: "1"},
						{label: "This is option 2", value: "2"},
						{label: "This is option 3", value: "3"},
						{label: "This is option 3", value: "4"},
						{label: "This is option 3", value: "5"},
						{label: "This is option 3", value: "6"},
						{label: "This is option 3", value: "7"},
						{label: "This is option 3", value: "8"},
						{label: "This is option 3", value: "9"},
						{label: "This is option 3", value: "10"},
					]}
					selections={autoCompleteSelections}
					setSelections={setAutoCompleteSelections}
				/>
			</div>

			<Row>

				<Col xs={12} sm={6}>
					<h1>This is an h1 tag</h1>
					<h2>This is an h2 tag</h2>
					<h3>This is an h3 tag</h3>
					<h4>This is an h4 tag</h4>
					<p>This is a p tag</p>
					<Link to="/test">This is an a tag</Link>
				</Col>
			</Row>
			<Row>
				<Col xs={12} className="py-3">
					<h2>Buttons & Inputs</h2>
				</Col>
				<Col xs={12} className="d-flex justify-content-between pb-3">
					<div className="d-flex flex-column">
						<Button className="mb-3" color={"black"}>Primary Button</Button>
						<Button className="mb-3" color={"outline-black"}>Border Variant</Button>
					</div>
					<div className="d-flex flex-column">
						<Button className="mb-3" color={"dark-gray"}>Secondary Button</Button>
						<Button className="mb-3" color={"outline-dark-gray"}>Border Variant</Button>
					</div>
					<div className="d-flex flex-column">
						<Button className="mb-3" color={"red"}>Alert Button</Button>
						<Button className="mb-3" color={"outline-red"}>Border Variant</Button>
					</div>
					<div className="d-flex flex-column">
						<Button className="mb-3" color={"white"}>Dark Background</Button>
						<Button className="mb-3" color={"outline-white"}>Border Variant</Button>
					</div>
					<div className="d-flex flex-column">
						<Button className="mb-3" disabled={true} color={"black"}>Option Unavailable</Button>
						<Button className="mb-3" disabled={true} color={"outline-black"}>Border Variant</Button>
					</div>
				</Col>

				<Col xs={12} className="d-flex justify-content-between pb-3">
					<div className="w-100 pr-3">
						<label>This is a text input field.</label>
						<input placeholder="This is placeholder text..."/>
					</div>
					<div className="w-100 pr-3">
						<label>This is an error input field.</label>
						<input className="input-error" placeholder="This is placeholder text..."/>
					</div>
					<div className="w-100 d-flex flex-column">
						<label>This is a dropdown menu.</label>
						<AlliedSelect>
							<option value="Test 1">Test 1</option>
							<option value="Test 2">Test 2</option>
							<option value="Test 3">Test 3</option>
							<option value="Test 4">Test 4</option>
						</AlliedSelect>
					</div>
				</Col>

				<Col xs={12} className="py-3">
					<h2>Buttons & Inputs</h2>
				</Col>
				<Col xs={12}>
					<div className="d-flex fled-row py-3">

						<label className="custom-radio pr-3">This is a an active radio toggle
							<input type="radio" name="radio"/>
							<span className="checkmark"/>
						</label>

						<label className="custom-radio">This is a an inactive radio toggle
							<input type="radio" readOnly={true} checked={false} name="radio"/>
							<span className="checkmark"/>
						</label>
					</div>
				</Col>

				<Col xs={12}>
					<div className="d-flex fled-row py-3">
						<div className="pr-3">
							<AlliedInputCheckbox
								checked={test1}
								onToggle={() => {
									setTest1(!test1);
								}}
								disabled={true}
                            >
								This is an active checkbox
							</AlliedInputCheckbox>
						</div>
						<AlliedInputCheckbox
                            checked={test2}
                            onToggle={() => {
                                setTest2(!test2);
                            }}
                            disabled={true}
                        >
                            This is an inactive checkbox
						</AlliedInputCheckbox>
					</div>
				</Col>

				<Col xs={6}>
					<h2>Upload File</h2>
					<div className="d-flex fled-row py-3">
						<AlliedInputFile value={test3} onChange={(e) => setTest3(e as any)}/>
					</div>
				</Col>
				<Col xs={6}>
					<h2>Upload Multiple Files</h2>
					<div className="d-flex fled-row py-3">
						<AlliedInputFile value={test4} onChange={(e) => setTest4(e as any)} multiple={true}/>
					</div>
				</Col>


			</Row>
		</Container>
	);
};

export default connect()(DemoPage);
