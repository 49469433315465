import React, {ChangeEventHandler, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Token, Site, SitesApi, UpdateSiteBody, CreateSiteBody, Address} from "client";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button, Col, Row} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	site: Site;
	onClose: () => void;
	onDone: () => void;
}

const ManageSitesViewDetailsModalAdminView: React.FC<IProps> = (props) => {

	const defaultSiteForm: UpdateSiteBody = {
		siteID: props.site?._id,
		name: props.site?.name,
		address: props.site?.address,
		contactNumber: props.site?.contactNumber,
		contactEmail: props.site?.contactEmail,
		renter: props.site?.renter,
		siteOwner: props.site?.siteOwner,
	}

	const [siteForm, setSiteForm] = useState<UpdateSiteBody>(defaultSiteForm);

	/**
	 * Resets any changes to the form and calls the close function from props.
	 *
	 */
	function resetAndClose(): void {
		setSiteForm(defaultSiteForm)
		props.onClose();
	}

	/**
	 * Dynamic onChange for the form text fields.
	 *
	 * @param key
	 */
	function siteOnChange(key: keyof CreateSiteBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setSiteForm({
				...siteForm,
				[key]: e?.target?.value,
			});
		}
	}

	/**
	 * Dynamic onChange for the address fields.
	 *
	 * @param key
	 */
	function addressOnChange(key: keyof Address): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setSiteForm({
				...siteForm,
				address: {
					...siteForm.address,
					[key]: e?.target?.value,
				}
			});
		}
	}

	/**
	 * Call the api to update the site, and call props.onDone() on success.
	 *
	 */
	async function submitSiteChanges(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new SitesApi(getConfig(props.fullToken)).updateSite({
				updateSiteBody: siteForm,
			});

			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<AlliedModal
			isOpen={props.isOpen}
			size="lg"
		>
			<AlliedModalHeader
				title="View/Edit Site Details"
				onClose={resetAndClose}
			/>

			<hr/>

			<Row>
				<Col xs={12} lg={6} className="mb-3">
					<label>Site Name</label>
					<input
						placeholder="Enter Site Name..."
						value={siteForm.name}
						onChange={siteOnChange("name")}
					/>
				</Col>

				<Col xs={0} lg={6}/>

				<Col xs={12} lg={6} className="mb-3">
					<label>Address Line 1</label>
					<input
						placeholder="Enter Address Line 1..."
						value={siteForm?.address?.addressLine1}
						onChange={addressOnChange("addressLine1")}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Address Line 2</label>
					<input
						placeholder="Enter Address Line 2..."
						value={siteForm?.address?.addressLine2}
						onChange={addressOnChange("addressLine2")}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>City</label>
					<input
						placeholder="Enter City..."
						value={siteForm?.address?.city}
						onChange={addressOnChange("city")}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Province</label>
					<input
						placeholder="Enter Province..."
						value={siteForm?.address?.state}
						onChange={addressOnChange("state")}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Country</label>
					<input
						placeholder="Enter Country..."
						value={siteForm?.address?.country}
						onChange={addressOnChange("country")}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Postal Code</label>
					<input
						placeholder="Enter Postal Code..."
						value={siteForm?.address?.zipCode}
						onChange={addressOnChange("zipCode")}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Renter</label>
					<input
						placeholder="Enter Renter Company Name..."
						value={siteForm?.renter}
						onChange={siteOnChange("renter")}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Contact Name</label>
					<input
						placeholder="Enter Contact Name..."
						value={siteForm?.siteOwner}
						onChange={siteOnChange("siteOwner")}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Contact Number</label>
					<input
						placeholder="Enter Contact Number..."
						value={siteForm?.contactNumber}
						onChange={siteOnChange("contactNumber")}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Contact Email</label>
					<input
						placeholder="Enter Contact Email..."
						value={siteForm?.contactEmail}
						onChange={siteOnChange("contactEmail")}
					/>
				</Col>
			</Row>

			<div>
				<Button
					color="black"
					onClick={submitSiteChanges}
				>
					Save Changes
				</Button>
			</div>
		</AlliedModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageSitesViewDetailsModalAdminView);
