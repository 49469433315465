import React, {ReactNode} from "react";
import {TimeSheetEntry} from "client";
import moment from "moment";
import {valueOrBustPrintables} from "../../../utils/valueOrBust";
import {convertBooleanToString} from "../../../utils/convertBooleanToString";
import {generateOffsetDate} from "../../../utils/timeZoneConversions";
import {humanReadableDuration} from "../../../utils/convertMillisecondsToHours";
import PrintableTextArea from "./PrintableTextArea";

interface IProps {
	entries?: Array<TimeSheetEntry>;
}

const PrintableTimeSheetEntriesTable: React.FC<IProps> = (props) => {

	function makeTimeSheetEntryRow(entry: TimeSheetEntry, i: number): ReactNode {
		return (
			<tr key={`time-sheet-entry-row_${entry.timeIn}_${entry.jobDescription}_${Math.random()}`}>
				<td className="printable__table__nowrap">
					{valueOrBustPrintables(entry.timeIn, moment(generateOffsetDate(entry.timeIn)).startOf("day").format("MM/DD/YYYY"))}
				</td>

				<td className="printable__table__white-space-pre">
					{valueOrBustPrintables(entry.jobDescription)}
				</td>

				<td className="printable__table__centered printable__table__nowrap">
					{entry.isSickDay ? "-" : valueOrBustPrintables(entry.timeIn, moment(generateOffsetDate(entry.timeIn)).format("h:mm A"))}
				</td>

				<td className="printable__table__centered printable__table__nowrap">
					{entry.isSickDay ? "0" : humanReadableDuration(entry.hours)}
				</td>

				<td className="printable__table__centered printable__table__nowrap">
					{entry.isSickDay ? "-" : valueOrBustPrintables(entry.lunchBreak, convertBooleanToString(entry.lunchBreak))}
				</td>

				<td className="printable__table__centered printable__table__nowrap">
					{entry.isSickDay ? "-" : valueOrBustPrintables(entry.driveTime)}
				</td>

				<td className="printable__table__centered printable__table__nowrap">
					{entry.isSickDay ? "-" : valueOrBustPrintables(entry.timeOut, moment(generateOffsetDate(entry.timeOut)).format("h:mm A"))}
				</td>

				<td className="printable__table__centered printable__table__white-space-pre">
					{valueOrBustPrintables(entry.reference)}
				</td>
			</tr>
		);
	}

	return (
		<div style={{width: "100%"}}>
			<table className="printable__table">
				<tr style={{maxWidth: 100}}>
					<td className="printable__table__label">
						DATE
					</td>

					<td className="printable__table__label">
						JOB DESCRIPTION
					</td>

					<td className="printable__table__label printable__table__centered">
						TIME IN
					</td>

					<td className="printable__table__label printable__table__centered">
						HRS
					</td>

					<td className="printable__table__label printable__table__centered">
						LUNCH Y/N
					</td>

					<td className="printable__table__label printable__table__centered">
						DRIVE TIME
					</td>

					<td className="printable__table__label printable__table__centered">
						TIME OUT
					</td>

					<td className="printable__table__label printable__table__centered">
						REFERENCE
					</td>
				</tr>

				{props.entries?.length > 0 ? (
					<React.Fragment>
						{props.entries?.map(makeTimeSheetEntryRow)}
					</React.Fragment>
				) : (
					<td
						className="printable__table__centered"
						colSpan={8}
					>
						No Entries
					</td>
				)}
			</table>
		</div>
	);
};

export default PrintableTimeSheetEntriesTable;
