import {IAutoCompleteOption} from "../components/inputs/AlliedAutoComplete";
import {Site, SiteIDAndName, SiteWithUsersAndEquipment} from "client";

/**
 * Converts an array of sites into an array of options for the auto-complete input.
 *
 * @param sites
 */
export function parseFilterSiteArrayForAutoComplete(sites: Array<SiteIDAndName> = []): Array<IAutoCompleteOption> {
	return sites.map((_site: SiteIDAndName): IAutoCompleteOption => {
		return {
			value: _site.id,
			label: _site.name,
		}
	});
}
