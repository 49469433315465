import {CreateCraneMonthlyLogBody} from "client";

/**
 * Util for getting a list of the keys of the fields
 * that are of type InspectionStatus on CreateCraneMonthlyLogBody.
 *
 */
export function getCraneMonthlyLogInspectionItems(): Array<keyof CreateCraneMonthlyLogBody> {
	return [
		"visualInspectionAndNoteBogieWear",
		"visualInspectionOfCriticalAreas",
		"visualInspectionOfWearOfPad",
		"inspectHoisting",
		"checkWireRope",
		"greaseTheHoisting",
		"visualInspectionOfCabSupport",
		"visualInspectionForOfSlewRingTeeth",
		"checkAllGearboxes",
		"visualInspectionOfPendantLineConnections",
		"supervisorNotifiedOfDefects",
	];
}
