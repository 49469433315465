import React, {useEffect, useState} from "react";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {GetUserListRequest, SitesApi, SiteWithUsersAndEquipment, Token} from "client";
import {Button, Col, Row} from "reactstrap";
import AlliedAutoComplete from "../inputs/AlliedAutoComplete";
import {autoCompleteAdminRoles} from "../../utils/autoCompleteAdminRoles";
import AutoCompleteListDisplay from "../inputs/AutoCompleteListDisplay";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {parseSitesForAutoComplete} from "../../utils/parseSitesForAutoComplete";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	filter: GetUserListRequest;
	onClose: () => void;
	onDone: (newFilter: GetUserListRequest) => void;
}

const ManageUsersFilterModal: React.FC<IProps> = (props) => {

	const [filter, setFilter] = useState<GetUserListRequest>(props.filter);
	const [apiSites, setApiSites] = useState<Array<SiteWithUsersAndEquipment>>(undefined);

	/**
	 * When modal opens, update the local filter to match that of props,
	 * and call api to get values for the auto-complete inputs.
	 *
	 */
	useEffect(() => {
		if (props.isOpen) {
			setFilter(props.filter);
			getSites().then().catch();
		}
	}, [props.isOpen]);

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function resetAndClose(): void {
		setFilter(props.filter);
		props.onClose();
	}

	/**
	 * Call api to get list of sites for the auto-complete input.
	 *
	 */
	async function getSites(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new SitesApi(getConfig(props.fullToken)).getSiteList({
				limit: 10000,
				offset: 0,
			});

			setApiSites(res.sites);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Dynamic onChange helper to update the string arrays in the filter object.
	 *
	 * @param key
	 */
	function filterArrayOnChange(key: keyof GetUserListRequest): (value: string[]) => void {
		return (value: string[]) => {
			setFilter({
				...filter,
				[key]: value,
			});
		}
	}

	/**
	 * When done configuring filter, call onDone function from props.
	 *
	 */
	function onSave(): void {
		props.onDone(filter);
	}

	return (
		<AlliedModal
			size="lg"
			isOpen={props.isOpen}
		>
			<AlliedModalHeader
				title="Filter Users"
				onClose={resetAndClose}
			/>

			<hr/>

			<Row>
				<Col xs={12} lg={6} className="mb-3">
					<label>Specify Roles</label>
					<AlliedAutoComplete
						placeholder="Select Roles..."
						options={autoCompleteAdminRoles}
						selections={filter.type}
						setSelections={filterArrayOnChange("type")}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Specify Sites Assigned</label>
					<AlliedAutoComplete
						placeholder="Select Sites..."
						options={parseSitesForAutoComplete(apiSites)}
						selections={filter.site}
						setSelections={filterArrayOnChange("site")}
					/>
				</Col>
			</Row>

			<div>
				<Button
					color="black"
					onClick={onSave}
				>
					Apply Filters
				</Button>
			</div>
		</AlliedModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageUsersFilterModal);
