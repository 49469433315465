import {
	CraneDailyLog,
	CraneType,
	LuffingTowerCraneDailyLog,
	SelfErectingCraneDailyLog,
	TowerCraneDailyLog,
	UpdateCraneDailyLogBody,
	UpdateLuffingTowerCraneDailyLogBody
} from "client";
import {defaultCreateCraneDailyLogForm} from "../components/forms/CreateCraneDailyLogForm";

function isLuffingTowerCraneDailyLog(c: CraneDailyLog, craneType: CraneType): c is LuffingTowerCraneDailyLog {
	return craneType === CraneType.LUFFING_TOWER;
}

function isSelfErectingCraneDailyLog(c: CraneDailyLog, craneType: CraneType): c is SelfErectingCraneDailyLog {
	return craneType === CraneType.SELF_ERECTING;
}

function isTowerCraneDailyLog(c: CraneDailyLog, craneType: CraneType): c is TowerCraneDailyLog {
	return craneType === CraneType.TOWER;
}

export function convertCraneDailyLogResponseToUpdateBody(res: CraneDailyLog, day: number, craneType: CraneType): UpdateCraneDailyLogBody {
	const base = {
		day,
		initials: res.initials,
		users: res.users.map(u => u._id),
		hours: res.hours,
		createLogCommentBodies: defaultCreateCraneDailyLogForm.createLogCommentBodies,
		electricalPowerCords: res.electricalPowerCords,
		groundFaultCircuitInterrupter: res.groundFaultCircuitInterrupter,
		onOffSwitch: res.onOffSwitch,
		craneBaseInspection: res.craneBaseInspection,
		inspectWalkways: res.inspectWalkways,
		inspectStructure: res.inspectStructure,
		ensureAllDoors: res.ensureAllDoors,
		operatorsControlsAdequate: res.operatorsControlsAdequate,
		loadMomentHoistLimit: res.loadMomentHoistLimit,
		confirmLocationOfZoning: res.confirmLocationOfZoning,
		maximumLoad: res.maximumLoad,
		hoistUpDecelerationLimit: res.hoistUpDecelerationLimit,
		hoistUpperLimit: res.hoistUpperLimit,
		hoistDownLimit: res.hoistDownLimit,
		ensureAllAudioVisualIndicatorsFunction: res.ensureAllAudioVisualIndicatorsFunction,
		anemometer: res.anemometer,
		hoistBrakeFunctioning: res.hoistBrakeFunctioning,
		slewingBrakeFunctioning: res.slewingBrakeFunctioning,
		visuallyInspectLoadBlockAndHook: res.visuallyInspectLoadBlockAndHook,
		travelBrakeToRail: res.travelBrakeToRail,
		railTravelForwardAndReverse: res.railTravelForwardAndReverse,
		inspectTracksForLoseConnections: res.inspectTracksForLoseConnections,
		housekeeping: res.housekeeping,
		supervisorNotedOfAnyDefectsOrFaults: res.supervisorNotedOfAnyDefectsOrFaults,
	}

	if (isLuffingTowerCraneDailyLog(res, craneType)) {
		return {
			...base,
			ensureAllTower: res.ensureAllTower,
			loadMomentLuffingLimit: res.loadMomentLuffingLimit,
			luffingUpSwitch: res.luffingUpSwitch,
			luffingDownSwitch: res.luffingDownSwitch,
			luffingUpAndDownDecelerationSwitch: res.luffingUpAndDownDecelerationSwitch,
			boomAngleIndicator: res.boomAngleIndicator,
			counterweightSafetySwitch: res.counterweightSafetySwitch,
			antiTwoBlockSwitch: res.antiTwoBlockSwitch,
			luffingBrake: res.luffingBrake,
		} as UpdateLuffingTowerCraneDailyLogBody;
	}

	if (isSelfErectingCraneDailyLog(res, craneType)) {
		return {
			...base,
			loadMomentTrolleyLimit: res.loadMomentTrolleyLimit,
			trolleyOut: res.trolleyOut,
			trolleyIn: res.trolleyIn,
			trolleyBrake: res.trolleyBrake,
			baseLevel: res.baseLevel,
			foundationCondition: res.foundationCondition,
		} as UpdateCraneDailyLogBody;
	}

	if (isTowerCraneDailyLog(res, craneType)) {
		return {
			...base,
			ensureAllTower: res.ensureAllTower,
			loadMomentTrolleyLimit: res.loadMomentTrolleyLimit,
			trolleyOut: res.trolleyOut,
			trolleyIn: res.trolleyIn,
			trolleyBrake: res.trolleyBrake,
		} as UpdateCraneDailyLogBody;
	}
}
