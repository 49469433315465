import React from "react";

interface IProps {
	onClick: () => void;
}

const PlusButton: React.FC<IProps> = (props) => {

	return (
		<div className="plus-icon">
			<div className="plus-icon__helper"/>

			<img
				src={process.env.PUBLIC_URL + "/images/plus.svg"}
				onClick={props.onClick}
				draggable={false}
			/>
		</div>
	);
};

export default PlusButton;
