import React, {useEffect, useState} from "react";
import AlliedModal from "./AlliedModal";
import {FormsApi, TimeSheetEntry, Token} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button, Col, Row} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	entry: TimeSheetEntry;
	onClose: () => void;
	onDone: () => void;
}

const ManageTimeSheetsEntryReferenceModal: React.FC<IProps> = (props) => {

	const [reference, setReference] = useState(props.entry?.reference);

	useEffect(() => {
		if (props.isOpen) {
			setReference(props.entry?.reference);
		}
	}, [props.isOpen]);

	/**
	 * Reset and close the form.
	 *
	 */
	function resetAndClose(): void {
		setReference(props.entry?.reference);
		props.onClose();
	}

	/**
	 * onChange handler for the reference input.
	 *
	 * @param e
	 */
	function referenceOnChange(e: React.ChangeEvent<HTMLTextAreaElement>): void {
		setReference(e?.target?.value);
	}

	/**
	 * Call api to add the reference to the time slot entry,
	 * call onDone function from props.
	 *
	 */
	async function saveReference(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new FormsApi(getConfig(props.fullToken)).addReferenceToTimeSheetEntry({
				addReferenceToTimeSheetEntryBody: {
					timeSheetEntryID: props.entry._id,
					reference: reference,
				},
			});

			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<AlliedModal
			isOpen={props.isOpen}
			size="md"
		>
			<AlliedModalHeader
				title="Enter Reference"
				onClose={resetAndClose}
			/>

			<hr/>

			<Row>
				<Col xs={12} className="mb-3">
					<label>Reference</label>
					<textarea
						placeholder="reference"
						value={reference}
						onChange={referenceOnChange}
						// maxLength={10}
					/>
				</Col>
			</Row>

			<div>
				<Button
					color="black"
					onClick={saveReference}
				>
					Save Reference
				</Button>
			</div>

		</AlliedModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageTimeSheetsEntryReferenceModal);

