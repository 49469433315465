import React from "react";
import DatePicker, {ReactDatePickerProps} from "react-datepicker";
import moment from "moment";

// @ts-ignore
interface IProps extends ReactDatePickerProps {
	selected: number;
}

const AlliedDatePicker: React.FC<IProps> = (props) => {

	/**
	 * Disable typing in the field.
	 *
	 * @param e
	 */
	function handleDateChangeRaw(e: React.FocusEvent<HTMLInputElement>): void {
		e?.preventDefault();
	}

	return (
		<DatePicker
			onChangeRaw={handleDateChangeRaw}
			{...props}
			selected={moment(props.selected).toDate()}
		/>
	);
};

export default AlliedDatePicker;
