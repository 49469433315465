/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { InspectionStatusFromJSON, InspectionStatusToJSON, } from './InspectionStatus';
import { LogFromJSONTyped, LogToJSON, } from './Log';
import { LogCommentFromJSON, LogCommentToJSON, } from './LogComment';
/**
 * Check if a given object implements the CraneMonthlyLog interface.
 */
export function instanceOfCraneMonthlyLog(value) {
    let isInstance = true;
    return isInstance;
}
export function CraneMonthlyLogFromJSON(json) {
    return CraneMonthlyLogFromJSONTyped(json, false);
}
export function CraneMonthlyLogFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...LogFromJSONTyped(json, ignoreDiscriminator),
        'comments': !exists(json, 'comments') ? undefined : (json['comments'].map(LogCommentFromJSON)),
        'visualInspectionAndNoteBogieWear': !exists(json, 'visualInspectionAndNoteBogieWear') ? undefined : InspectionStatusFromJSON(json['visualInspectionAndNoteBogieWear']),
        'visualInspectionOfCriticalAreas': !exists(json, 'visualInspectionOfCriticalAreas') ? undefined : InspectionStatusFromJSON(json['visualInspectionOfCriticalAreas']),
        'visualInspectionOfWearOfPad': !exists(json, 'visualInspectionOfWearOfPad') ? undefined : InspectionStatusFromJSON(json['visualInspectionOfWearOfPad']),
        'inspectHoisting': !exists(json, 'inspectHoisting') ? undefined : InspectionStatusFromJSON(json['inspectHoisting']),
        'checkWireRope': !exists(json, 'checkWireRope') ? undefined : InspectionStatusFromJSON(json['checkWireRope']),
        'greaseTheHoisting': !exists(json, 'greaseTheHoisting') ? undefined : InspectionStatusFromJSON(json['greaseTheHoisting']),
        'visualInspectionOfCabSupport': !exists(json, 'visualInspectionOfCabSupport') ? undefined : InspectionStatusFromJSON(json['visualInspectionOfCabSupport']),
        'visualInspectionForOfSlewRingTeeth': !exists(json, 'visualInspectionForOfSlewRingTeeth') ? undefined : InspectionStatusFromJSON(json['visualInspectionForOfSlewRingTeeth']),
        'checkAllGearboxes': !exists(json, 'checkAllGearboxes') ? undefined : InspectionStatusFromJSON(json['checkAllGearboxes']),
        'visualInspectionOfPendantLineConnections': !exists(json, 'visualInspectionOfPendantLineConnections') ? undefined : InspectionStatusFromJSON(json['visualInspectionOfPendantLineConnections']),
        'supervisorNotifiedOfDefects': !exists(json, 'supervisorNotifiedOfDefects') ? undefined : InspectionStatusFromJSON(json['supervisorNotifiedOfDefects']),
    };
}
export function CraneMonthlyLogToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...LogToJSON(value),
        'comments': value.comments === undefined ? undefined : (value.comments.map(LogCommentToJSON)),
        'visualInspectionAndNoteBogieWear': InspectionStatusToJSON(value.visualInspectionAndNoteBogieWear),
        'visualInspectionOfCriticalAreas': InspectionStatusToJSON(value.visualInspectionOfCriticalAreas),
        'visualInspectionOfWearOfPad': InspectionStatusToJSON(value.visualInspectionOfWearOfPad),
        'inspectHoisting': InspectionStatusToJSON(value.inspectHoisting),
        'checkWireRope': InspectionStatusToJSON(value.checkWireRope),
        'greaseTheHoisting': InspectionStatusToJSON(value.greaseTheHoisting),
        'visualInspectionOfCabSupport': InspectionStatusToJSON(value.visualInspectionOfCabSupport),
        'visualInspectionForOfSlewRingTeeth': InspectionStatusToJSON(value.visualInspectionForOfSlewRingTeeth),
        'checkAllGearboxes': InspectionStatusToJSON(value.checkAllGearboxes),
        'visualInspectionOfPendantLineConnections': InspectionStatusToJSON(value.visualInspectionOfPendantLineConnections),
        'supervisorNotifiedOfDefects': InspectionStatusToJSON(value.supervisorNotifiedOfDefects),
    };
}
