import React, {useState} from "react";
import {MaterialHoist} from "client";
import {Button} from "reactstrap";
import ManageMaterialHoistsDisableModal from "../../modals/ManageMaterialHoistsDisableModal";

interface IProps {
	materialHoist: MaterialHoist;
	onDone: () => Promise<void>;
}

const ManageMaterialHoistsDisableCell: React.FC<IProps> = (props) => {

	const [showDisable, setShowDisable] = useState(false);

	/**
	 * Toggle showing the edit material hoist modal.
	 *
	 */
	function toggleShowDisable(): void {
		setShowDisable(!showDisable);
	}

	function onDone(): void {
		setShowDisable(false);
		props.onDone().then().catch();
	}

	return (
		<div className="d-flex justify-content-center">
			<ManageMaterialHoistsDisableModal
				isOpen={showDisable}
				materialHoist={props.materialHoist}
				onClose={toggleShowDisable}
				onDone={onDone}
			/>

			<Button
				color={props.materialHoist?.disabled ? "gray" : "red"}
				onClick={toggleShowDisable}
			>
				{props.materialHoist?.disabled ? "Enable Material Hoist" : "Disable Material Hoist"}
			</Button>
		</div>
	);
};

export default ManageMaterialHoistsDisableCell;
