import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	Crane,
	EquipmentApi,
	EquipmentSortField,
	EquipmentType,
	GetEquipmentListRequest,
	GetEquipmentListResponse,
	Token,
	UserType,
} from "client";
import AlliedPageHeader from "../../components/AlliedPageHeader";
import AlliedContainer from "../../components/AlliedContainer";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {removeEmptyArraysFromObject} from "../../utils/removeEmptyArraysFromObject";
import AlliedTableContainer from "../../components/tables/AlliedTableContainer";
import AlliedTableHeaderCell from "../../components/tables/cells/AlliedTableHeaderCell";
import {getCraneTypeDisplay} from "../../utils/getCraneTypeDisplay";
import {getSiteName} from "../../utils/getSiteName";
import ManageCranesEditCell from "../../components/tables/cells/ManageCranesEditCell";
import ManageCranesDisableCell from "../../components/tables/cells/ManageCranesDisableCell";
import ManageCranesFilterModal from "../../components/modals/ManageCranesFilterModal";
import ManageCranesViewDeficienciesCell from "../../components/tables/cells/ManageCranesViewDeficienciesCell";
import {userTypeCheck} from "../../utils/userTypeCheck";
import {alliedPaginationLimitOptions} from "../../components/tables/paginator/AlliedPaginatorLimitController";
import {abstractOnSortChange, getTableHeaderSortClassName} from "../../utils/abstractOnSortChange";
import ManageCranesQrCodeCell from "../../components/tables/cells/ManageCranesQrCodeCell";

interface IProps {
	dispatch?: any;
	fullToken: Token;
}

const defaultFilter: GetEquipmentListRequest = {
	limit: alliedPaginationLimitOptions[0],
	offset: 0,
	showDisabled: false,
	type: [EquipmentType.CRANE],
	craneType: [],
	ownedBy: [],
	rentedBy: [],
	make: [],
	model: [],
	serialNumber: [],
	site: [],
};

const ManageCranesPage: React.FC<IProps> = (props) => {

	const [showFilterModal, setShowFilterModal] = useState(false);
	const [filter, setFilter] = useState<GetEquipmentListRequest>(defaultFilter);
	const [craneListResponse, setCraneListResponse] = useState<GetEquipmentListResponse>(undefined);

	useEffect(() => {
		getCraneList().then().catch();
	}, [JSON.stringify(filter)]);

	/**
	 * Call api to get list of equipment.
	 *
	 */
	async function getCraneList(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new EquipmentApi(getConfig(props.fullToken)).getEquipmentList(removeEmptyArraysFromObject(filter));
			console.log("res:", res);
			setCraneListResponse(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Toggles visibility of the filter modal.
	 *
	 */
	function toggleFilterModal(): void {
		setShowFilterModal(!showFilterModal);
	}

	/**
	 * When finished the filter modal, save the new filter (will prompt api via useEffect),
	 * and hide the filter modal.
	 *
	 * @param newFilter
	 */
	function onDoneFilterModal(newFilter: GetEquipmentListRequest): void {
		setFilter({
			...newFilter,
			offset: 0,
		});
		setShowFilterModal(false);
	}

	/**
	 * Renderer for the Edit Information column.
	 *
	 * @param crane
	 */
	function makeEditCell(crane: Crane): ReactNode {
		return (
			<ManageCranesEditCell
				key={"edit" + crane._id}
				crane={crane}
				onDone={getCraneList}
			/>
		);
	}

	/**
	 * Renderer for the View forms column.
	 *
	 * @param crane
	 */
	function makeViewFormsCell(crane: Crane): ReactNode {
		return (
			<ManageCranesViewDeficienciesCell
				key={"view-deficiencies" + crane._id}
				crane={crane}
			/>
		);
	}

	/**
	 * Renderer for the Disable Crane column.
	 *
	 * @param crane
	 */
	function makeDisableCell(crane: Crane): ReactNode {
		return (
			<ManageCranesDisableCell
				key={"disable" + crane._id}
				crane={crane}
				onDone={getCraneList}
			/>
		);
	}

	/**
	 * Cell for generating the print-out with the QR code for operator self-service registration.
	 *
	 * @param crane
	 */
	function makeQrCell(crane: Crane): ReactNode {
		return (
			<ManageCranesQrCodeCell
				key={"qr-code" + crane._id}
				crane={crane}
			/>
		);
	}

	return (
		<React.Fragment>
			<ManageCranesFilterModal
				isOpen={showFilterModal}
				filter={filter}
				onClose={toggleFilterModal}
				onDone={onDoneFilterModal}
			/>

			<AlliedContainer>
				<AlliedPageHeader
					title="Manage Cranes"
					onClick={toggleFilterModal}
				/>

				<hr/>

				<AlliedTableContainer
					<GetEquipmentListRequest>
					data={craneListResponse?.equipment}
					showPaginator={true}
					paginationInfo={craneListResponse?.paginationInfo}
					filter={filter}
					setFilter={setFilter}
					columnOptions={[
						{
							headerValue: "Owned By",
							key: "ownedBy",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							headerCellClassName: getTableHeaderSortClassName(EquipmentSortField.ownedBy, filter),
							onSort: abstractOnSortChange<EquipmentSortField, GetEquipmentListRequest>(EquipmentSortField.ownedBy, filter, setFilter),
						},
						{
							headerValue: "Make",
							key: "make",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							headerCellClassName: getTableHeaderSortClassName(EquipmentSortField.make, filter),
							onSort: abstractOnSortChange<EquipmentSortField, GetEquipmentListRequest>(EquipmentSortField.make, filter, setFilter),
						},
						{
							headerValue: "Model",
							key: "model",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							headerCellClassName: getTableHeaderSortClassName(EquipmentSortField.model, filter),
							onSort: abstractOnSortChange<EquipmentSortField, GetEquipmentListRequest>(EquipmentSortField.model, filter, setFilter),
						},
						{
							headerValue: "S/N",
							key: "serialNumber",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							headerCellClassName: getTableHeaderSortClassName(EquipmentSortField.serialNumber, filter),
							onSort: abstractOnSortChange<EquipmentSortField, GetEquipmentListRequest>(EquipmentSortField.serialNumber, filter, setFilter),
						},
						{
							headerValue: "Crane Type",
							key: "craneType",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getCraneTypeDisplay,
						},
						{
							headerValue: "Site Name",
							key: "site",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getSiteName,
						},
						{
							headerValue: "Rented By",
							key: "rentedBy",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							headerCellClassName: getTableHeaderSortClassName(EquipmentSortField.rentedBy, filter),
							onSort: abstractOnSortChange<EquipmentSortField, GetEquipmentListRequest>(EquipmentSortField.rentedBy, filter, setFilter),
						},
						{
							headerValue: "Historical Hours",
							key: "preExistingUsageHours",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							headerCellClassName: getTableHeaderSortClassName(EquipmentSortField.preExistingUsageHours, filter),
							onSort: abstractOnSortChange<EquipmentSortField, GetEquipmentListRequest>(EquipmentSortField.preExistingUsageHours, filter, setFilter),
						},
						{
							headerValue: "Current Hours",
							key: "currentUsageHours",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
						},
						(userTypeCheck([UserType.ADMIN], props.fullToken) &&
							{
								headerValue: "View/Edit Details",
								key: undefined,
								showSortIcons: false,
								sortable: false,
								headerRender: AlliedTableHeaderCell,
								cellRender: makeEditCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							}
						),
						{
							headerValue: "View Forms",
							key: undefined,
							showSortIcons: false,
							sortable: false,
							headerRender: AlliedTableHeaderCell,
							cellRender: makeViewFormsCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
						(userTypeCheck([UserType.ADMIN], props.fullToken) &&
							{
								headerValue: "Disable",
								key: undefined,
								showSortIcons: false,
								sortable: false,
								headerRender: AlliedTableHeaderCell,
								cellRender: makeDisableCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							}
						),
						(userTypeCheck([UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR], props.fullToken) &&
							{
								headerValue: "Print QR Code",
								key: undefined,
								showSortIcons: false,
								sortable: false,
								headerRender: AlliedTableHeaderCell,
								cellRender: makeQrCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							}
						),
					]}
				/>

			</AlliedContainer>

		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageCranesPage);
