import React from "react";

interface IProps {
	title: string;
	onClose?: () => void;
	onBack?: () => void;
}

const AlliedModalHeader: React.FC<IProps> = (props) => {

	return (
		<div className="allied-modal-header">
			<div className="allied-modal-header-title-and-back">
				{props.onBack && (
					<img
						src={process.env.PUBLIC_URL + "/icons/arrow-black.svg"}
						className="allied-modal-header-back-icon"
						onClick={props.onBack}
					/>
				)}

				<h3>
					{props.title}
				</h3>
			</div>

			{props.onClose && (
				<img
					src={process.env.PUBLIC_URL + "/icons/x.svg"}
					className="allied-modal-header-icon"
					onClick={props.onClose}
				/>
			)}
		</div>
	);
};

export default AlliedModalHeader;
