/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AddTimeSheetEntryBodyFromJSON, AddTimeSheetEntryBodyToJSON, } from './AddTimeSheetEntryBody';
/**
 * Check if a given object implements the AdminAddTimeSheetEntryToUserTimeSheet interface.
 */
export function instanceOfAdminAddTimeSheetEntryToUserTimeSheet(value) {
    let isInstance = true;
    isInstance = isInstance && "timeSheetId" in value;
    isInstance = isInstance && "timeSheetEntryBody" in value;
    return isInstance;
}
export function AdminAddTimeSheetEntryToUserTimeSheetFromJSON(json) {
    return AdminAddTimeSheetEntryToUserTimeSheetFromJSONTyped(json, false);
}
export function AdminAddTimeSheetEntryToUserTimeSheetFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'timeSheetId': json['timeSheetId'],
        'timeSheetEntryBody': AddTimeSheetEntryBodyFromJSON(json['timeSheetEntryBody']),
    };
}
export function AdminAddTimeSheetEntryToUserTimeSheetToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'timeSheetId': value.timeSheetId,
        'timeSheetEntryBody': AddTimeSheetEntryBodyToJSON(value.timeSheetEntryBody),
    };
}
