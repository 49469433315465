import {IAutoCompleteOption} from "../components/inputs/AlliedAutoComplete";
import {CraneType, User} from "client";
import {getCraneTypeDisplay} from "./getCraneTypeDisplay";

/**
 * Converts CraneType enum into an array of options for the auto-complete input.
 *
 */
export function parseCraneTypeForAutoComplete(): Array<IAutoCompleteOption> {
	return Object.values(CraneType).map((_craneType: CraneType): IAutoCompleteOption => {
		return {
			value: _craneType,
			label: getCraneTypeDisplay(_craneType),
		}
	});
}
