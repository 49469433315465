import React from "react";
import SelectOptionsFactory, {ISelectOption} from "../../SelectOptionsFactory";
import AlliedSelect from "../../inputs/AlliedSelect";
import {PaginationInfo} from "client";

// I use the small values when testing without a ton of entries. Uncomment them to make testing easier!
export const alliedPaginationLimitOptions: number[] = [
	// 1,
	// 2,
	// 3,
	// 5,
	10,
	25,
	50,
	75,
	100,
];

interface IProps {
	paginationInfo: PaginationInfo;
	onLimitChange: (limit: number) => void;
}

const AlliedPaginatorLimitController: React.FC<IProps> = (props) => {

	function onChangeHelper(e: React.ChangeEvent<HTMLSelectElement>): void {
		props.onLimitChange(parseInt(e.target.value));
	}

	return (
		<div className="allied-paginator-limit-controller">
			<div className="allied-paginator-limit-controller__top">
				<p className="allied-paginator-limit-controller__label">
					Results per page:
				</p>

				<div className="allied-paginator-limit-controller__select-container">
					<AlliedSelect
						value={props.paginationInfo.limit}
						onChange={onChangeHelper}
					>
						<SelectOptionsFactory
							options={alliedPaginationLimitOptions.map((option: number): ISelectOption => {
								return {
									value: option,
									label: option.toString(),
								}
							})}
						/>
					</AlliedSelect>
				</div>
			</div>

			<div className="allied-paginator-limit-controller__bottom">
				<p className="allied-paginator-limit-controller__bottom__range-label">
					{`Showing results ${props.paginationInfo.lowerRange} - ${props.paginationInfo.upperRange} of ${props.paginationInfo.total}.`}
				</p>
			</div>
		</div>
	);
};

export default AlliedPaginatorLimitController;
