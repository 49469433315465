/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const UserType = {
    ADMIN: 'ADMIN',
    ALLIED_CRANE_EMPLOYEE: 'ALLIED_CRANE_EMPLOYEE',
    CRANE_OPERATOR: 'CRANE_OPERATOR',
    HEALTH_AND_SAFETY_OFFICER: 'HEALTH_AND_SAFETY_OFFICER',
    SECONDARY_ADMIN: 'SECONDARY_ADMIN',
    SUPERVISOR: 'SUPERVISOR'
};
export function UserTypeFromJSON(json) {
    return UserTypeFromJSONTyped(json, false);
}
export function UserTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function UserTypeToJSON(value) {
    return value;
}
