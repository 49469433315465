/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const DeficiencyStatus = {
    PENDING_APPOINTMENT: 'PENDING_APPOINTMENT',
    REQUIRES_PARTS: 'REQUIRES_PARTS',
    PARTS_AVAILABLE: 'PARTS_AVAILABLE',
    RESOLVED: 'RESOLVED'
};
export function DeficiencyStatusFromJSON(json) {
    return DeficiencyStatusFromJSONTyped(json, false);
}
export function DeficiencyStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function DeficiencyStatusToJSON(value) {
    return value;
}
