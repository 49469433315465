import {Crane, CraneMonthlyLog, CraneOperator, LogComment} from "client";
import React from "react";
import PrintableSpacer from "../elements/PrintableSpacer";
import PrintableCellLabelWithValue from "../elements/PrintableCellLabelWithValue";
import {asPercentOf100} from "../../../utils/asPercentOf100";
import PrintableCellRow from "../elements/PrintableCellRow";
import {valueOrBustPrintables} from "../../../utils/valueOrBust";
import {parseSiteAddressForSingleInput} from "../../../utils/parseSiteAddressForSingleInput";
import {getCraneTypeDisplay} from "../../../utils/getCraneTypeDisplay";
import {isCrane} from "./FinalTestFormPrintable";
import PrintableCraneMonthlyLogChecklistTable from "../elements/PrintableCraneMonthlyLogChecklistTable";
import {convertBooleanToString} from "../../../utils/convertBooleanToString";
import PrintableWeeklyAndMonthlyLogComments from "../elements/PrintableWeeklyAndMonthlyLogComments";
import {getFullNameForTable} from "../../../utils/getFullNameForTable";
import moment from "moment";
import {generateOffsetDate} from "../../../utils/timeZoneConversions";
import {getWeekRangeForTable} from "../../../utils/getWeekRangeForTable";

interface IProps {
	form: CraneMonthlyLog;
}

const CraneMonthlyLogPrintable: React.FC<IProps> = (props) => {

	return (
		<React.Fragment>
			<p className="printable__header">
				Monthly Log for Crane
			</p>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Month of:"
					value={valueOrBustPrintables(props.form?.date, moment(generateOffsetDate(props.form?.date)).format("MMMM YYYY"))}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Site Name:"
					value={valueOrBustPrintables(props.form?.originalSite?.name)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Site Address:"
					value={valueOrBustPrintables(props.form?.originalSite?.address, parseSiteAddressForSingleInput(props.form?.originalSite?.address))}
					width={asPercentOf100(2 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Crane Make:"
					value={valueOrBustPrintables(props.form?.equipment?.make)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Crane Model:"
					value={valueOrBustPrintables(props.form?.equipment?.model)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Crane S/N:"
					value={valueOrBustPrintables(props.form?.equipment?.serialNumber)}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Crane Type:"
					value={valueOrBustPrintables(isCrane(props.form?.equipment) && getCraneTypeDisplay((props.form?.equipment as Crane)?.craneType))}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<p className="printable__sub-header">
				Monthly Log Checklist
			</p>

			<PrintableCraneMonthlyLogChecklistTable craneMonthlyLog={props.form}/>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Operator Initials:"
					value={valueOrBustPrintables(props.form?.initials, convertBooleanToString(props.form?.initials))}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Deficiency Reports - Flagged:"
					value={props.form?.comments?.reduce((acc: number, comment: LogComment) => {
						if (comment.flagged) {
							acc++;
						}

						return acc;
					}, 0) ? "Yes" : "No"}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Operator Name:"
					value={getFullNameForTable(props.form?.owner)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Certificate #:"
					value={valueOrBustPrintables((props.form?.owner as CraneOperator)?.certificationNumber)} // safe because only operators can create these
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Supervisor Name:"
					value={valueOrBustPrintables(getFullNameForTable(props.form?.approvedBy))}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<div style={{pageBreakAfter: "always"}}/>
			<p className="printable__header">
				Comments for Crane Monthly Log
			</p>
			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Week of:"
					value={valueOrBustPrintables(props.form?.date, getWeekRangeForTable(1)(generateOffsetDate(props.form?.date)))}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Site Name:"
					value={valueOrBustPrintables(props.form?.originalSite?.name)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Site Address:"
					value={valueOrBustPrintables(props.form?.originalSite?.address, parseSiteAddressForSingleInput(props.form?.originalSite?.address))}
					width={asPercentOf100(2 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Crane Make:"
					value={valueOrBustPrintables(props.form?.equipment?.make)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Crane Model:"
					value={valueOrBustPrintables(props.form?.equipment?.model)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Crane S/N:"
					value={valueOrBustPrintables(props.form?.equipment?.serialNumber)}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Crane Type:"
					value={valueOrBustPrintables(isCrane(props.form?.equipment) && getCraneTypeDisplay((props.form?.equipment as Crane)?.craneType))}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableWeeklyAndMonthlyLogComments log={props.form}/>
		</React.Fragment>
	);
};

export default CraneMonthlyLogPrintable;
