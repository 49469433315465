import React, {useState} from "react";
import {Button} from "reactstrap";
import {CraneWeeklyLog} from "client";
import CraneWeeklyLogsViewOtherFieldsModal from "../../modals/CraneWeeklyLogsViewOtherFieldsModal";

interface IProps {
	log: CraneWeeklyLog;
}

const CraneWeeklyLogsViewOtherFieldsCell: React.FC<IProps> = (props) => {

	const [showModal, setShowModal] = useState(false);

	function toggleShowModal(): void {
		setShowModal(!showModal);
	}

	return (
		<React.Fragment>
			<CraneWeeklyLogsViewOtherFieldsModal
				isOpen={showModal}
				log={props.log}
				onClose={toggleShowModal}
			/>

			<div className="d-flex justify-content-center">
				<Button
					color="black"
					onClick={toggleShowModal}
				>
					View Fields
				</Button>
			</div>
		</React.Fragment>
	);
};

export default CraneWeeklyLogsViewOtherFieldsCell;
