export function valueOrBust(value: any): string {
	return (value !== undefined && typeof value !== "object") ? value : "-";
}

/**
 * Second variant of function used only by printables that can be edited without
 * affecting the forms & general usage of the platform
 *
 * @param value
 * @param display
 */
export function valueOrBustPrintables(value: any, display?: string): string {
	if (display) {
		return value !== undefined ? display : "-";
	} else {
		if (typeof value !== "object") {
			return value !== undefined ? value : "-";
		}

		return "-";
	}
}
