import React from "react";
import {CraneMonthlyLog, FormsApi, Token} from "client";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/MetaActions";
import {connect} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import AbstractPrintableButtonManager from "./AbstractPrintableButtonManager";
import getConfig from "../../../utils/getConfig";
import CraneMonthlyLogPrintable from "../forms/CraneMonthlyLogPrintable";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	id: string;
	children: string;
	showPrintable?: boolean;
	className?: string;
}

const ViewCraneMonthlyLogButton: React.FC<IProps> = (props) => {

	/**
	 * Call api to get & return the crane weekly logs.
	 *
	 */
	async function getCranMonthlyLogs(): Promise<CraneMonthlyLog> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FormsApi(getConfig(props.fullToken)).getCraneMonthlyLog({
				craneMonthlyLogID: props.id,
			});

			props.dispatch(decrementLoading());
			return res;
		} catch (e) {
			props.dispatch(addError(e));
			props.dispatch(decrementLoading());
		}
	}

	return (
		<AbstractPrintableButtonManager
			<CraneMonthlyLog>
			color="black"
			onClick={getCranMonthlyLogs}
			component={CraneMonthlyLogPrintable}
			showPrintable={props.showPrintable}
			className={props.className}
			documentTitle="crane-monthly-log"
		>
			{props.children}
		</AbstractPrintableButtonManager>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ViewCraneMonthlyLogButton);
