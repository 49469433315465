export function convertMillisecondsToHours(milliseconds: number): number {
	return milliseconds / 1000 / 60 / 60;
}

export function humanReadableDuration(msDuration: number): string {
	console.log("ms:", msDuration);
	if (msDuration < 0) {
		return "-";
	}

	const h = Math.floor(msDuration / 1000 / 60 / 60);
	const m = (msDuration % (60000 * 60)) / 60000;
	// const s = Math.floor(((msDuration / 1000 / 60 / 60 - h) * 60 - m) * 60);

	// To get time format 00:00:00
	// const seconds: string = s < 10 ? `0${s}` : `${s}`;
	const minutes: string = m < 10 ? `0${m}` : `${m}`;
	const hours: string = h < 10 ? `0${h}` : `${h}`;

	console.log("minutes:", minutes);
	console.log("hours:", hours);

	// return `${hours}h ${minutes}m ${seconds}s`;
	return `${hours}:${minutes}`;
}
