import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	Equipment, FinalTestFormSortField,
	FormsApi,
	GetServiceReportListRequest,
	GetServiceReportListResponse,
	ServiceReport, ServiceReportSortField, SiteSortField, TimeSheet,
	Token,
	UserType
} from "client";
import {Button} from "reactstrap";
import AlliedContainer from "../../components/AlliedContainer";
import AlliedPageHeader from "../../components/AlliedPageHeader";
import AlliedTableContainer from "../../components/tables/AlliedTableContainer";
import {defaultFilter} from "../formHistory/ServiceReportsHistoryPage";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {cleanRequestForAPISubmission} from "../../utils/cleanRequestForAPISubmission";
import AlliedTableHeaderCell from "../../components/tables/cells/AlliedTableHeaderCell";
import {parseDateForTableDisplay} from "../../utils/parseDateForTableDisplay";
import {getGenericObjectProperty} from "../../utils/getGenericObjectProperty";
import {getEquipmentTypeDisplay} from "../../utils/getEquipmentTypeDisplay";
import {getSiteNameFromEquipment} from "../../utils/getSiteNameFromEquipment";
import {getFormPublishedStatus} from "../../utils/getFormPublishedStatus";
import {getFullNameForTable} from "../../utils/getFullNameForTable";
import {TableData} from "frame-one-table/build/contextTypes";
import {ColumnOption} from "frame-one-table/build/TableGenerator";
import ManageServiceReportsOptionsCell from "../../components/tables/cells/ManageServiceReportsOptionsCell";
import {userTypeCheck} from "../../utils/userTypeCheck";
import {useHistory} from "react-router-dom";
import ManageServiceReportsViewFormCell from "../../components/tables/cells/ManageServiceReportsViewFormCell";
import ManageServiceReportsFilterModal from "../../components/modals/ManageServiceReportsFilterModal";
import {convertOffsetDate} from "../../utils/timeZoneConversions";
import ManageTimeSheetsCommentCell from "../../components/tables/cells/ManageTimeSheetsCommentCell";
import ManageServiceReportsCommentCell from "../../components/tables/cells/ManageServiceReportsCommentCell";
import {parseServiceReportShortID} from "../../utils/parseServiceReportShortID";
import {abstractOnSortChange, getTableHeaderSortClassName} from "../../utils/abstractOnSortChange";
import {GetSiteListRequestFrontend} from "../sitesOptions/ManageSitesPages";
import moment from "moment/moment";

interface IProps {
	dispatch?: any;
	fullToken: Token;
}

// This is the version of the page for Admins, Managers, Supervisors, and Health & Safety workers.

const ServiceReportsPage: React.FC<IProps> = (props) => {

	const history = useHistory();
	const [showFilterModal, setShowFilterModal] = useState(false);
	const [filter, setFilter] = useState<GetServiceReportListRequest>(defaultFilter);
	const [reportsResponse, setReportsResponse] = useState<GetServiceReportListResponse>(undefined);

	useEffect(() => {
		getServiceReports().then().catch();
	}, [JSON.stringify(filter)]);

	/**
	 * Call api to get list of service reports.
	 *
	 */
	async function getServiceReports(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FormsApi(getConfig(props.fullToken)).getServiceReportList(cleanRequestForAPISubmission({
				...filter,
				startDate: convertOffsetDate(filter?.startDate),
				endDate: convertOffsetDate(filter?.endDate),
			} as GetServiceReportListRequest));
			setReportsResponse(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Toggles visibility of the filter modal.
	 *
	 */
	function toggleFilterModal(): void {
		setShowFilterModal(!showFilterModal);
	}

	/**
	 * When finished the filter modal, save the new filter (will prompt api via useEffect),
	 * and hide the filter modal.
	 *
	 * @param newFilter
	 */
	function onDoneFilterModal(newFilter: GetServiceReportListRequest): void {
		setFilter({
			...newFilter,
			offset: 0,
		});
		setShowFilterModal(false);
	}

	/**
	 * Go to the create Service Report page.
	 *
	 */
	function goToCreate(): void {
		history.push("/create-forms/create-service-report");
	}

	/**
	 * Renderer for the Add/Edit Comment column.
	 *
	 */
	function makeCommentCell(serviceReport: ServiceReport): ReactNode {
		return (
			<ManageServiceReportsCommentCell
				key={"comment" + serviceReport._id}
				serviceReport={serviceReport}
				onDone={getServiceReports}
			/>
		);
	}

	/**
	 * Renderer for the Service Report Options column.
	 *
	 */
	function makeOptionsCell(serviceReport: ServiceReport): ReactNode {
		return (
			<ManageServiceReportsOptionsCell
				key={"options" + serviceReport._id}
				report={serviceReport}
				onDone={getServiceReports}
			/>
		);
	}

	/**
	 * Renderer for the View Forms column.
	 *
	 * @param serviceReport
	 */
	function makeViewCell(serviceReport: ServiceReport): ReactNode {
		return (
			<ManageServiceReportsViewFormCell
				key={"view" + serviceReport._id}
				report={serviceReport}
			/>
		);
	}

	return (
		<React.Fragment>
			<ManageServiceReportsFilterModal
				isOpen={showFilterModal}
				filter={filter}
				onClose={toggleFilterModal}
				onDone={onDoneFilterModal}
			/>

			<AlliedContainer>
				<AlliedPageHeader
					title="Service Reports"
					onClick={toggleFilterModal}
				/>
				<p>
					{`Showing results from ${moment(filter.startDate).format("MMM DD YYYY")} - ${moment(filter.endDate).format("MMM DD YYYY")}`}
				</p>

				{userTypeCheck([UserType.ADMIN], props.fullToken) && (
					<Button
						color="black"
						onClick={goToCreate}
						className="mt-3"
					>
						Create Service Report
					</Button>
				)}

				<hr/>

				<AlliedTableContainer
					<GetServiceReportListRequest>
					data={reportsResponse?.serviceReports}
					showPaginator={true}
					paginationInfo={reportsResponse?.paginationInfo}
					filter={filter}
					setFilter={setFilter}
					columnOptions={[
						{
							headerValue: "Date Submitted",
							key: "submittedDate",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							rowCellClassName: "font-weight-bold",
							valueFormatter: parseDateForTableDisplay,
							headerCellClassName: getTableHeaderSortClassName(ServiceReportSortField.submittedDate, filter),
							onSort: abstractOnSortChange<ServiceReportSortField, GetServiceReportListRequest>(ServiceReportSortField.submittedDate, filter, setFilter),
						},
						{
							headerValue: "Date",
							key: "date",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: parseDateForTableDisplay,
							headerCellClassName: getTableHeaderSortClassName(ServiceReportSortField.date, filter),
							onSort: abstractOnSortChange<ServiceReportSortField, GetServiceReportListRequest>(ServiceReportSortField.date, filter, setFilter),
						},
						{
							headerValue: "Report ID",
							key: "shortIdentifier",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: parseServiceReportShortID,
							rowCellClassName: "text-right",
						},
						{
							headerValue: "Make",
							key: "equipment",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getGenericObjectProperty<Equipment>("make"),
						},
						{
							headerValue: "Model",
							key: "equipment",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getGenericObjectProperty<Equipment>("model"),
						},
						{
							headerValue: "S/N",
							key: "equipment",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getGenericObjectProperty<Equipment>("serialNumber"),
						},
						{
							headerValue: "Equipment",
							key: "equipment",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getGenericObjectProperty<Equipment>("type", getEquipmentTypeDisplay),
						},
						{
							headerValue: "Site Name",
							key: "equipment",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getSiteNameFromEquipment,
						},
						{
							headerValue: "User",
							key: "owner",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getFullNameForTable,
						},
						(userTypeCheck([UserType.ADMIN], props.fullToken) &&
							{
								headerValue: "Published",
								key: "publishedDate",
								showSortIcons: false,
								sortable: true,
								headerRender: AlliedTableHeaderCell,
								valueFormatter: getFormPublishedStatus,
								headerCellClassName: getTableHeaderSortClassName(ServiceReportSortField.publishedDate, filter),
								onSort: abstractOnSortChange<ServiceReportSortField, GetServiceReportListRequest>(ServiceReportSortField.publishedDate, filter, setFilter),
							}
						),
						(userTypeCheck([UserType.ADMIN], props.fullToken) &&
							{
								headerValue: "Add/Edit Comment",
								key: undefined,
								showSortIcons: false,
								sortable: true,
								headerRender: AlliedTableHeaderCell,
								cellRender: makeCommentCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							}
						),
						(userTypeCheck([UserType.ADMIN], props.fullToken) &&
							{
								headerValue: "Service Report Options",
								key: "serviceReportOptions",
								showSortIcons: false,
								sortable: false,
								headerRender: AlliedTableHeaderCell,
								cellRender: makeOptionsCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							}
						),
						(userTypeCheck([UserType.SECONDARY_ADMIN, UserType.SUPERVISOR, UserType.HEALTH_AND_SAFETY_OFFICER], props.fullToken) &&
							{
								headerValue: "View Forms",
								key: "viewForms",
								showSortIcons: false,
								sortable: false,
								headerRender: AlliedTableHeaderCell,
								cellRender: makeViewCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							}
						),
					]}
				/>
			</AlliedContainer>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ServiceReportsPage);
