import {Address, Site} from "client";

export function parseSiteAddressForSingleInput(address: Address): string {
	if (!address) {
		return "-"
	}

	return (
		address.addressLine1 + " " +
		(address.addressLine2 ? address.addressLine2 + " " : "") +
		address.city + " " +
		address.state + " " +
		address.country + " " +
		(address.zipCode ? address.zipCode : "")
	)
}
