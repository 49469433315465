import {CreateGeneratorDailyLogBody} from "client";

export function getGeneratorDailyLogInspectionItemDisplayName(key: keyof CreateGeneratorDailyLogBody): string {
	switch(key) {
		case "checkOilLevel":
			return "Check oil level";
		case "checkCoolantLevels":
			return "Check coolant levels";
		case "visualInspectionForWear":
			return "Visual inspection for wear or damage";
		case "checkForLeakage":
			return "Check for oil, fuel, or water leakage";
		case "ensureDoorPanelsAndCoversAreClosed":
			return "Ensure all doors, panels, and covers are closed and in place after starting";
		default:
			return key;
	}
}
