import React, {ReactNode} from "react";
import {
	CraneDailyLog,
	CraneWeeklyLog, CreateCraneWeeklyLogBody,
} from "client";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {parseInspectionStatus} from "../../utils/parseInspectionStatus";
import {getCraneWeeklyLogInspectionItemDisplayName} from "../../utils/getCraneWeeklyLogInspectionItemDisplayName";
import {getCraneWeeklyLogInspectionItems} from "../../utils/getCraneWeeklyLogInspectionItems";
import {InspectionOptionLookupInterfaces} from "../../utils/logInspectionOptionsFinder";

interface IProps {
	isOpen: boolean
	log: CraneWeeklyLog;
	onClose: () => void;
}

const CraneWeeklyLogsViewOtherFieldsModal: React.FC<IProps> = (props) => {

	/**
	 * Filter out any fields of the log that aren't inspection items,
	 * and then parse & display the remaining inspection item.
	 *
	 * @param log
	 */
	function makeFieldEntries(log: CraneWeeklyLog): ReactNode {
		const fields: Array<keyof CreateCraneWeeklyLogBody> = getCraneWeeklyLogInspectionItems();

		return Object.keys(props.log).filter((p: keyof CreateCraneWeeklyLogBody) => {
			return fields.indexOf(p) > -1
		}).map((entry: string) => {
			return (
				<div>
					<label>{getCraneWeeklyLogInspectionItemDisplayName(entry as keyof CreateCraneWeeklyLogBody)}</label>
					<p>
						{parseInspectionStatus(entry as keyof InspectionOptionLookupInterfaces, log[entry])}
					</p>
				</div>
			);
		});
	}

	return (
		<AlliedModal
			isOpen={props.isOpen}
			size="md"
		>
			<AlliedModalHeader
				title="Log Inspection"
				onClose={props.onClose}
			/>

			<hr/>

			{makeFieldEntries(props.log)}
		</AlliedModal>
	);
};

export default CraneWeeklyLogsViewOtherFieldsModal;
