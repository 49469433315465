import React, {ChangeEventHandler, useEffect, useState} from "react";
import {
	Crane,
	CraneMonthlyLog,
	CreateCraneMonthlyLogBody,
	CreateCraneMonthlyLogResponse,
	CreateLogCommentBody,
	EquipmentApi,
	EquipmentType,
	FormsApi,
	FormType,
	InspectionStatus,
	Site,
	SitesApi,
	Token,
	User,
	UsersApi,
	UserType
} from "client";
import moment from "moment";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {useHistory} from "react-router-dom";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {cloneDeep, find} from "lodash";
import {getFullNameForTable} from "../../utils/getFullNameForTable";
import AlliedContainer from "../AlliedContainer";
import AlliedPageHeader from "../AlliedPageHeader";
import AlliedFormContainer from "../AlliedFormContainer";
import {Button, Col, Row} from "reactstrap";
import AlliedAutoComplete from "../inputs/AlliedAutoComplete";
import {parseEquipmentForSelectDropdown} from "../../utils/parseEquipmentForSelectDropdown";
import AlliedSelectInputList from "../inputs/inputList/AlliedSelectInputList";
import {convertCraneMonthlyInspectionItemsToInputListItems} from "../../utils/convertCraneMonthlyInspectionItemsToInputListItems";
import AlliedGenericInputList from "../inputs/AlliedGenericInputList";
import DailyLogCommentInput from "../inputs/DailyLogCommentInput";
import {userTypeCheck} from "../../utils/userTypeCheck";
import {parseSitesForAutoComplete} from "../../utils/parseSitesForAutoComplete";
import {convertCraneMonthlyLogResponseToCreateBody} from "../../utils/convertCraneMonthlyLogResponseToCreateBody";
import {convertMonthListToAutoCompleteValues, getMonthsForMonthlyLogForm} from "../../utils/getMonthsForMonthlyLogForm";
import {convertOffsetDate} from "../../utils/timeZoneConversions";
import {getCraneTypeDisplay} from "../../utils/getCraneTypeDisplay";
import {getWeekRangeForTable} from "../../utils/getWeekRangeForTable";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	id?: string;
}

export const defaultCreateCraneMonthlyLogForm: CreateCraneMonthlyLogBody = {
	equipmentID: "",
	siteID: "",
	date: moment().startOf("day").valueOf(),
	initials: false,
	createLogCommentBodies: [{
		flagged: false,
		comment: "",
	}],
	visualInspectionAndNoteBogieWear: undefined,
	visualInspectionOfCriticalAreas: undefined,
	visualInspectionOfWearOfPad: undefined,
	inspectHoisting: undefined,
	checkWireRope: undefined,
	greaseTheHoisting: undefined,
	visualInspectionOfCabSupport: undefined,
	visualInspectionForOfSlewRingTeeth: undefined,
	checkAllGearboxes: undefined,
	visualInspectionOfPendantLineConnections: undefined,
	supervisorNotifiedOfDefects: undefined,
}

const CreateCraneMonthlyLogForm: React.FC<IProps> = (props) => {

	const history = useHistory();
	const [cranesList, setCranesList] = useState<Array<Crane>>([]);
	const [siteList, setSitesList] = useState<Array<Site>>([]);
	const [createCraneMonthlyLogForm, setCreateCraneMonthlyLogForm] = useState<CreateCraneMonthlyLogBody>(defaultCreateCraneMonthlyLogForm);
	const [userProfile, setUserProfile] = useState<User>(undefined); // Used for making the user enter their name as a signature (matching the name).
	const [signature, setSignature] = useState(""); // The "signature" as entered by the user; not submitted to api but used to check for the "initials" (boolean) field.
	const [rawEditingResponse, setRawEditingResponse] = useState<CraneMonthlyLog>(undefined);

	useEffect(() => {
		getAutoCompleteValues().then().catch();

		if (props.id) {
			getFormForEditing().then().catch();
		}
	}, []);

	/**
	 * Call api to get list of equipment & sites for the auto-complete inputs,
	 * and the user's profile for the signature match.
	 *
	 */
	async function getAutoCompleteValues(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new EquipmentApi(getConfig(props.fullToken)).getEquipmentList({
				limit: 10000,
				offset: 0,
				type: [EquipmentType.CRANE],
			});

			const sitesRes = await new SitesApi(getConfig(props.fullToken)).getSiteList({
				limit: 10000,
				offset: 0,
			});

			const userRes = (await new UsersApi(getConfig(props.fullToken)).getProfile()).user;

			setCranesList(res.equipment as Array<Crane>);
			setSitesList(sitesRes.sites);
			setUserProfile(userRes);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Call api to get existing Crane Monthly Log if props.id exists,
	 * and save it to the form object after formatting correctly.
	 *
	 */
	async function getFormForEditing(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FormsApi(getConfig(props.fullToken)).getCraneMonthlyLog({
				craneMonthlyLogID: props.id,
			});

			console.log("res:", res);
			setCreateCraneMonthlyLogForm(convertCraneMonthlyLogResponseToCreateBody(res));
			setRawEditingResponse(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Handle the auto-complete onChange for the selected equipment,
	 * which returns an array, but we only want the first element.
	 *
	 * Also set the siteID to that of the selected equipment's,
	 * if siteID isn't already set on the form.
	 *
	 * @param equipment
	 */
	function equipmentOnChange(equipment: string[]): void {
		const newForm: CreateCraneMonthlyLogBody = cloneDeep(createCraneMonthlyLogForm);
		const isNewSelection: boolean = createCraneMonthlyLogForm.equipmentID !== equipment[0];

		if (!newForm.siteID && isNewSelection) {
			newForm.siteID = find(cranesList, ["_id", equipment[0]])?.site?._id;
		}

		setCreateCraneMonthlyLogForm({
			...newForm,
			equipmentID: isNewSelection ? equipment[0] : "",
		});
	}

	/**
	 * Handle the auto-complete onChange for the selected site,
	 * which returns an array, but we only want the first element.
	 *
	 * @param site
	 */
	function siteOnChange(site: string[]): void {
		setCreateCraneMonthlyLogForm({
			...createCraneMonthlyLogForm,
			siteID: createCraneMonthlyLogForm.siteID !== site[0] ? site[0] : "",
		});
	}

	/**
	 * onChange for the date input.
	 *
	 * @param date
	 */
	function onDateChange(date: string[]): void{
		setCreateCraneMonthlyLogForm({
			...createCraneMonthlyLogForm,
			date: createCraneMonthlyLogForm.date?.toString() !== date[0] ? parseInt(date[0]) : undefined,
		});
	}

	/**
	 * onChange handler for the "Inspection Items" section, which
	 * are displayed dynamically based on the type of crane selected.
	 *
	 * @param key
	 * @param value
	 */
	function onInspectionItemChange(key: keyof CreateCraneMonthlyLogBody, value: InspectionStatus): void {
		setCreateCraneMonthlyLogForm({
			...createCraneMonthlyLogForm,
			[key]: value,
		});
	}

	/**
	 * Dynamic onChange for number inputs using the NumberFormat library.
	 *
	 * @param key
	 */
	function numberFormatOnChange(key: keyof CreateCraneMonthlyLogBody): (e) => void {
		return (e) => {
			setCreateCraneMonthlyLogForm({
				...createCraneMonthlyLogForm,
				[key]: e?.floatValue,
			});
		}
	}

	/**
	 * onChange function for all of the dynamic lists with plus & minus icons.
	 *
	 * @param key
	 */
	function inputListOnChange<T>(key: keyof CreateCraneMonthlyLogBody): (i: number, value: T) => void {
		return (i: number, value: T) => {
			const copy: Array<T> = cloneDeep(createCraneMonthlyLogForm[key] as unknown as Array<T>);
			copy[i] = value;

			setCreateCraneMonthlyLogForm({
				...createCraneMonthlyLogForm,
				[key]: copy,
			});
		}
	}

	/**
	 * Add another entry to the array in question.
	 *
	 */
	function inputListOnAdd<T>(key: keyof CreateCraneMonthlyLogBody): () => void {
		return () => {
			setCreateCraneMonthlyLogForm({
				...createCraneMonthlyLogForm,
				[key]: (createCraneMonthlyLogForm[key] as unknown as Array<T>).concat([defaultCreateCraneMonthlyLogForm[key][0]]),
			});
		}
	}

	/**
	 * Remove item at index i from the array in question.
	 *
	 */
	function inputListOnRemove<T>(key: keyof CreateCraneMonthlyLogBody): (i: number) => void {
		return (i: number) => {
			const copy: Array<T> = cloneDeep(createCraneMonthlyLogForm[key] as unknown as Array<T>);
			copy.splice(i, 1);

			setCreateCraneMonthlyLogForm({
				...createCraneMonthlyLogForm,
				[key]: copy,
			});
		}
	}

	/**
	 * Dynamic onChange for standard text inputs.
	 *
	 * @param key
	 */
	function textInputOnChange(key: keyof CreateCraneMonthlyLogBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setCreateCraneMonthlyLogForm({
				...createCraneMonthlyLogForm,
				[key]: e?.target?.value,
			});
		}
	}

	/**
	 * onChange for the signature field that the user must enter before submitting.
	 *
	 * @param e
	 */
	function onSignatureChange(e: React.ChangeEvent<HTMLInputElement>): void {
		setSignature(e?.target?.value);
	}

	/**
	 * Call api to either submit new Crane Monthly Log or update existing and return id.
	 * Checks which type of crane so we know which api to use,
	 * checks if entered signature is correct,
	 * navigate to the form history for crane monthly logs on success.
	 *
	 */
	async function saveCraneMonthlyLog(): Promise<string> {
		props.dispatch(incrementLoading());

		let createResponse: CreateCraneMonthlyLogResponse;

		try {
			const initials: boolean = signature?.toLowerCase() === getFullNameForTable(userProfile)?.toLowerCase(); // Check if the user's entered signature matches their name properly.

			const req = {
				equipmentID: createCraneMonthlyLogForm?.equipmentID ? createCraneMonthlyLogForm?.equipmentID : undefined,
				siteID: createCraneMonthlyLogForm?.siteID ? createCraneMonthlyLogForm?.siteID : undefined,
				date: createCraneMonthlyLogForm?.date !== undefined ? convertOffsetDate(createCraneMonthlyLogForm?.date) : undefined,
				createLogCommentBodies: createCraneMonthlyLogForm?.createLogCommentBodies?.filter((c: CreateLogCommentBody): boolean => {
					return c.comment?.length > 0;
				}),
				visualInspectionAndNoteBogieWear: createCraneMonthlyLogForm?.visualInspectionAndNoteBogieWear ? createCraneMonthlyLogForm?.visualInspectionAndNoteBogieWear : undefined,
				visualInspectionOfCriticalAreas: createCraneMonthlyLogForm?.visualInspectionOfCriticalAreas ? createCraneMonthlyLogForm?.visualInspectionOfCriticalAreas : undefined,
				visualInspectionOfWearOfPad: createCraneMonthlyLogForm?.visualInspectionOfWearOfPad ? createCraneMonthlyLogForm?.visualInspectionOfWearOfPad : undefined,
				inspectHoisting: createCraneMonthlyLogForm?.inspectHoisting ? createCraneMonthlyLogForm?.inspectHoisting : undefined,
				checkWireRope: createCraneMonthlyLogForm?.checkWireRope ? createCraneMonthlyLogForm?.checkWireRope : undefined,
				greaseTheHoisting: createCraneMonthlyLogForm?.greaseTheHoisting ? createCraneMonthlyLogForm?.greaseTheHoisting : undefined,
				visualInspectionOfCabSupport: createCraneMonthlyLogForm?.visualInspectionOfCabSupport ? createCraneMonthlyLogForm?.visualInspectionOfCabSupport : undefined,
				visualInspectionForOfSlewRingTeeth: createCraneMonthlyLogForm?.visualInspectionForOfSlewRingTeeth ? createCraneMonthlyLogForm?.visualInspectionForOfSlewRingTeeth : undefined,
				checkAllGearboxes: createCraneMonthlyLogForm?.checkAllGearboxes ? createCraneMonthlyLogForm?.checkAllGearboxes : undefined,
				visualInspectionOfPendantLineConnections: createCraneMonthlyLogForm?.visualInspectionOfPendantLineConnections ? createCraneMonthlyLogForm?.visualInspectionOfPendantLineConnections : undefined,
				supervisorNotifiedOfDefects: createCraneMonthlyLogForm?.supervisorNotifiedOfDefects ? createCraneMonthlyLogForm?.supervisorNotifiedOfDefects : undefined,
				initials,
			};

			if (props.id) {
				delete req.equipmentID;
				delete req.siteID;
				await new FormsApi(getConfig(props.fullToken)).updateCraneMonthlyLog({
					updateCraneMonthlyLogBody: {
						...req,
						formID: props.id,
					},
				});
			} else {
				createResponse = await new FormsApi(getConfig(props.fullToken)).createCraneMonthlyLog({
					createCraneMonthlyLogBody: req,
				});
			}
		} catch (e) {
			props.dispatch(addError(e));
			props.dispatch(decrementLoading());
			throw e;
		}

		props.dispatch(decrementLoading());

		// Return the newly created id if we're not editing.
		if (!props.id && createResponse) {
			return createResponse?.craneMonthlyLogID;
		}
	}

	/**
	 * Used when creating or updating to save the draft.
	 * Afterwards, navigate to the history page.
	 *
	 * @param e
	 */
	async function saveDraft(e?: React.SyntheticEvent): Promise<void> {
		e?.preventDefault();
		try {
			await saveCraneMonthlyLog();
			history.push("/form-history/monthly-log-history/crane-monthly-log-history");
		} catch (e) {

		}
	}

	/**
	 * Save & submit for approval.
	 * Calls the save function to get an id used to submit afterwards - if one doesn't exist from props.
	 *
	 * Should only be possible to be called by operators.
	 *
	 * @param e
	 */
	async function saveAndSubmitCraneMonthlyLog(e?: React.SyntheticEvent): Promise<void> {
		e?.preventDefault();
		const newId: string = await saveCraneMonthlyLog();
		props.dispatch(incrementLoading());

		try {
			await new FormsApi(getConfig(props.fullToken)).submitCraneMonthlyLog({
				iDBody: {
					id: props.id ? props.id : newId,
				},
			});

			history.push("/form-history/monthly-log-history/crane-monthly-log-history");
		} catch (e) {
			props.dispatch(addError(e));

			if (!props.id) {
				props.dispatch(decrementLoading());
				history.push(`/edit-forms/edit-crane-monthly-log?id=${newId}`);
			}
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Util to check if the "Save Draft" button should be enabled
	 *
	 * @param _signature
	 * @param user
	 * @param _equipmentId
	 */
	function checkForSaveButtonEnabled(_signature: string, user: User, _equipmentId: string): boolean {
		return !((_signature?.toLowerCase() === getFullNameForTable(user).toLowerCase()) && _equipmentId?.length > 0);
	}

	const selectedCrane: Crane = find(cranesList, ["_id", createCraneMonthlyLogForm?.equipmentID]);

	return (
		<AlliedContainer>
			<AlliedPageHeader
				title={`${props.id ? "Edit" : "Create"} Crane Monthly Log`}
				showTooltip={false}
			/>

			<hr/>

			<AlliedFormContainer>
				<form onSubmit={saveDraft}>
					{(props.id && rawEditingResponse && selectedCrane) ? (
						<Row>
							<Col xs={12} md={6} className="mb-3">
								<label>Editing log for Crane:</label>
								<br/>
								<b>
									{`${selectedCrane.make} ${selectedCrane.model} - ${getCraneTypeDisplay(selectedCrane.craneType)} ${selectedCrane.serialNumber} at ${rawEditingResponse.originalSite.name}`}
								</b>
								{/*<br/>*/}
								{/*<b>*/}
								{/*	{moment(rawEditingResponse.date).format("MMMM YYYY")}*/}
								{/*</b>*/}
							</Col>
						</Row>
					) : (
						<React.Fragment>
							<Row>
								<Col xs={12} md={6} className="mb-3">
									<label>Select Crane for Log</label>
									<AlliedAutoComplete
										placeholder="Select Crane..."
										options={parseEquipmentForSelectDropdown(cranesList)}
										selections={createCraneMonthlyLogForm.equipmentID ? [createCraneMonthlyLogForm.equipmentID] : []}
										setSelections={equipmentOnChange}
										multiSelect={false}
									/>
								</Col>
							</Row>

							<Row>
								<Col xs={12} md={6} className="mb-3">
									<label>Select Site</label>
									<AlliedAutoComplete
										placeholder="Select Site..."
										options={parseSitesForAutoComplete(siteList)}
										selections={createCraneMonthlyLogForm.siteID ? [createCraneMonthlyLogForm.siteID] : []}
										setSelections={siteOnChange}
										multiSelect={false}
									/>
								</Col>
							</Row>
						</React.Fragment>
					)}

					<hr/>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Date</label>
							<AlliedAutoComplete
								placeholder="Select Month..."
								options={convertMonthListToAutoCompleteValues(getMonthsForMonthlyLogForm())}
								selections={createCraneMonthlyLogForm?.date !== undefined ? [createCraneMonthlyLogForm?.date?.toString()] : []}
								setSelections={onDateChange}
								multiSelect={false}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={6} className="mb-3">
							<AlliedSelectInputList
								key={JSON.stringify(selectedCrane)}
								title="Inspection Items"
								emptyMessage="Please select a crane first."
								inputs={
									convertCraneMonthlyInspectionItemsToInputListItems(
										[
											"visualInspectionAndNoteBogieWear",
											"visualInspectionOfCriticalAreas",
											"visualInspectionOfWearOfPad",
											"inspectHoisting",
											"checkWireRope",
											"greaseTheHoisting",
											"visualInspectionOfCabSupport",
											"visualInspectionForOfSlewRingTeeth",
											"checkAllGearboxes",
											"visualInspectionOfPendantLineConnections",
											"supervisorNotifiedOfDefects",
										],
										createCraneMonthlyLogForm,
									)}
								onChange={onInspectionItemChange}
							/>
						</Col>
					</Row>

					{(props.id !== undefined && rawEditingResponse?.comments?.length > 0) && (
						<Row>
							<Col xs={12}>
								<h5 className="font-weight-bold">Previous Comments</h5>
								<h6>(Cannot be edited)</h6>
							</Col>
							<Col xs={12} md={6} className="mb-3">
								<AlliedGenericInputList
									values={rawEditingResponse?.comments}
									component={DailyLogCommentInput}
									forwardedProps={{radioNameSuffix: "-previous"}}
								/>
							</Col>
						</Row>
					)}

					<Row>
						<Col xs={12}>
							<h5 className="font-weight-bold">Comments</h5>
						</Col>
						<Col xs={12} md={6} className="mb-3">
							<AlliedGenericInputList
								values={createCraneMonthlyLogForm.createLogCommentBodies}
								onChange={inputListOnChange<CreateLogCommentBody>("createLogCommentBodies")}
								onAdd={inputListOnAdd("createLogCommentBodies")}
								onRemove={inputListOnRemove("createLogCommentBodies")}
								component={DailyLogCommentInput}
							/>
						</Col>
					</Row>

					<hr/>

					<Row>
						<Col xs={12}>
							<label>Enter full name to confirm this report is accurate.</label>
						</Col>
					</Row>

					<Row>
						<Col xs={12} sm={6} md={4} lg={3} className="mb-3">
							<input
								placeholder="Enter Signature..."
								value={signature}
								onChange={onSignatureChange}
							/>
						</Col>
						<Col xs={12} sm={6} md={4} lg={3} className="mb-3 user-select-none">
							<input
								disabled={true}
								placeholder="Loading..."
								value={getFullNameForTable(userProfile)}
								className="user-select-none pointer-events-none"
							/>
						</Col>
					</Row>

					<div className="d-flex flex-column align-items-center flex-sm-row">
						<Button
							color="black"
							disabled={checkForSaveButtonEnabled(signature, userProfile, createCraneMonthlyLogForm?.equipmentID)}
							type="submit"
							onClick={saveDraft}
							className="mr-0 mr-sm-3 mb-3 mb-sm-0"
						>
							Save Draft
						</Button>

						{userTypeCheck([UserType.CRANE_OPERATOR], props.fullToken) && (
							!props.id ||
							rawEditingResponse?.submittedDate === undefined
						) && (
							<Button
								color="red"
								onClick={saveAndSubmitCraneMonthlyLog}
								disabled={checkForSaveButtonEnabled(signature, userProfile, createCraneMonthlyLogForm?.equipmentID)}
							>
								Save & Submit for Approval
							</Button>
						)}
					</div>
				</form>
			</AlliedFormContainer>
		</AlliedContainer>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(CreateCraneMonthlyLogForm);
