import React, {ChangeEventHandler, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	AdminRole,
	CreateCraneOperatorBody,
	CreateUserBody,
	GetSiteListRequest, GetSiteListResponse, SitesApi,
	Token,
	UsersApi,
	UserType
} from "client";
import AlliedContainer from "../../components/AlliedContainer";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {Button, Col, Row} from "reactstrap";
import {AlliedInputRadioFactory, IAlliedRadioProps} from "../../components/inputs/AlliedInputRadio";
import AlliedRadioRow from "../../components/inputs/AlliedRadioRow";
import getConfig from "../../utils/getConfig";
import AlliedFormContainer from "../../components/AlliedFormContainer";
import AlliedPageHeader from "../../components/AlliedPageHeader";
import {Link, useHistory} from "react-router-dom";
import {omit} from "lodash";
import {removeEmptyArraysFromObject} from "../../utils/removeEmptyArraysFromObject";
import CreateUserSelectSitesModal from "../../components/modals/CreateUserSelectSitesModal";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
}

interface CreateUserBodyFrontend extends CreateUserBody {
	sites: string[];
}

const defaultCreateUserForm: CreateUserBodyFrontend = {
	type: UserType.ADMIN,
	username: "",
	password: "",
	confirmPassword: "",
	email: "",
	firstName: "",
	lastName: "",
	sites: [],
};

const CreateNewUserPage: React.FC<IProps> = (props) => {

	const history = useHistory();
	const [createUserForm, setCreateUserForm] = useState<CreateUserBodyFrontend>(defaultCreateUserForm);
	const [sitesResponse, setSitesResponse] = useState<GetSiteListResponse>(undefined);
	const [showSitesModal, setShowSitesModal] = useState(false);

	useEffect(() => {
		void getSites();
	}, [])

	/**
	 *  Call api to get list of sites.
	 *
	 */
	async function getSites(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new SitesApi(getConfig(props.fullToken)).getSiteList({
				offset: 0,
				limit: 100000,
			});
			setSitesResponse(res);
			setCreateUserForm((f) => {
				return {
					...f,
					sites: res.sites.map(s => s._id),
				}
			});
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Dynamic onChange for the form text fields.
	 *
	 * @param key
	 */
	function createUserOnChange(key: keyof CreateUserBody | keyof CreateCraneOperatorBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setCreateUserForm({
				...createUserForm,
				[key]: e?.target?.value,
			});
		}
	}

	/**
	 * Dynamic onChange for the "role" radio buttons.
	 *
	 * @param role
	 */
	function roleOnChange(role: UserType): () => void {
		return () => {
			setCreateUserForm({
				...createUserForm,
				type: role,
			});
		}
	}

	/**
	 * Call api to submit new user dynamically based on "type" field,
	 * and navigate to Manager Users page on success.
	 *
	 */
	async function submitNewUser(e?: React.SyntheticEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			const apiBase = new UsersApi(getConfig(props.fullToken));

			const req: CreateUserBody = {
				type: createUserForm.type,
				username: createUserForm.username ? createUserForm.username : undefined,
				password: createUserForm.password ? createUserForm.password : undefined,
				confirmPassword: createUserForm.confirmPassword ? createUserForm.confirmPassword : undefined,
				email: createUserForm.email ? createUserForm.email : undefined,
				firstName: createUserForm.firstName ? createUserForm.firstName : undefined,
				lastName: createUserForm.lastName ? createUserForm.lastName : undefined,
			};

			switch (createUserForm?.type) {
				case UserType.ADMIN:
					await apiBase.createAdmin({
						createAdminBody: {
							...req,
							adminRole: AdminRole.STANDARD,
						},
					});
					break;
				case UserType.SECONDARY_ADMIN:
					await apiBase.createSecondaryAdmin({
						createSecondaryAdminBody: {
							...req,
							sites: createUserForm.sites,
						},
					});
					break;
				case UserType.ALLIED_CRANE_EMPLOYEE:
					console.log("hello?:");
					await apiBase.createAlliedCraneEmployee({
						createAlliedCraneEmployeeBody: {
							...req,
							sites: createUserForm.sites,
						},
					});
					break;
				case UserType.SUPERVISOR:
					await apiBase.createSupervisor({
						createSupervisorBody: {
							...req,
							sites: createUserForm.sites,
						},
					});
					break;
				case UserType.CRANE_OPERATOR:
					if (isCreateCraneOperatorBody(createUserForm)) {
						await apiBase.createCraneOperator({
							createCraneOperatorBody: {
								...req,
								certificationNumber: createUserForm.certificationNumber ? createUserForm.certificationNumber : undefined,
								sites: createUserForm.sites,
							},
						});
					}
					break;
				case UserType.HEALTH_AND_SAFETY_OFFICER:
					await apiBase.createHealthAndSafetyOfficer({
						createHealthAndSafetyOfficerBody: {
							...req,
							sites: createUserForm.sites,
						},
					});
			}

			history.push("/user-options/manage-users");
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Returns the list of user types the current user is allowed to create.
	 *
	 */
	function getAssignableRoles(_tokenType: UserType): Array<IAlliedRadioProps> {
		switch (_tokenType) {
			case UserType.ADMIN:
				return [
					{
						label: "Admin",
						checked: createUserForm?.type === UserType.ADMIN,
						onChange: roleOnChange(UserType.ADMIN),
					},
					{
						label: "Manager",
						checked: createUserForm?.type === UserType.SECONDARY_ADMIN,
						onChange: roleOnChange(UserType.SECONDARY_ADMIN),
					},
					{
						label: "Allied Crane Employee",
						checked: createUserForm?.type === UserType.ALLIED_CRANE_EMPLOYEE,
						onChange: roleOnChange(UserType.ALLIED_CRANE_EMPLOYEE),
					},
					{
						label: "Supervisor",
						checked: createUserForm?.type === UserType.SUPERVISOR,
						onChange: roleOnChange(UserType.SUPERVISOR),
					},
					{
						label: "Crane Operator",
						checked: createUserForm?.type === UserType.CRANE_OPERATOR,
						onChange: roleOnChange(UserType.CRANE_OPERATOR),
					},
					{
						label: "Health & Safety",
						checked: createUserForm?.type === UserType.HEALTH_AND_SAFETY_OFFICER,
						onChange: roleOnChange(UserType.HEALTH_AND_SAFETY_OFFICER),
					},
				];
			case UserType.SECONDARY_ADMIN:
			case UserType.SUPERVISOR:
				return [
					{
						label: "Crane Operator",
						checked: createUserForm?.type === UserType.CRANE_OPERATOR,
						onChange: roleOnChange(UserType.CRANE_OPERATOR),
					},
					{
						label: "Health & Safety",
						checked: createUserForm?.type === UserType.HEALTH_AND_SAFETY_OFFICER,
						onChange: roleOnChange(UserType.HEALTH_AND_SAFETY_OFFICER),
					},
				];
			default:
				return [];
		}
	}

	function isCreateCraneOperatorBody(c: CreateUserBody): c is CreateCraneOperatorBody {
		return c.type === UserType.CRANE_OPERATOR;
	}

	function selectedSitesOnChange(selectedSites: string[]): void {
		setCreateUserForm((f) => {
			return {
				...f,
				sites: selectedSites,
			}
		});
	}

	return (
		<React.Fragment>
			<CreateUserSelectSitesModal
				isOpen={showSitesModal}
				sites={sitesResponse?.sites}
				selectedSites={createUserForm.sites}
				onChange={selectedSitesOnChange}
				onClose={() => setShowSitesModal(false)}
			/>

			<AlliedContainer>
				<AlliedPageHeader
					title="Create a New User"
					showTooltip={false}
				/>

				<hr/>

				<AlliedFormContainer>
					<form onSubmit={submitNewUser}>
						<Row>
							<Col xs={12} md={6} lg={6} xl={4} className="mb-3">
								<label>First Name</label>
								<input
									placeholder="Enter First Name..."
									value={createUserForm.firstName}
									onChange={createUserOnChange("firstName")}
								/>
							</Col>

							<Col xs={12} md={6} lg={6} xl={4} className="mb-3">
								<label>Last Name</label>
								<input
									placeholder="Enter Last Name..."
									value={createUserForm.lastName}
									onChange={createUserOnChange("lastName")}
								/>
							</Col>

							<Col xs={0} md={0} lg={0} xl={4}/>

							<Col xs={12} md={6} lg={6} xl={4} className="mb-3">
								<label>Username</label>
								<input
									placeholder="Enter Username..."
									value={createUserForm.username}
									onChange={createUserOnChange("username")}
								/>
							</Col>

							<Col xs={12} md={6} lg={6} xl={4} className="mb-3">
								<label>Email Address</label>
								<input
									placeholder="Enter Email Address..."
									value={createUserForm.email}
									onChange={createUserOnChange("email")}
								/>
							</Col>

							<Col xs={0} md={0} lg={0} xl={4}/>

							<Col xs={12} md={6} lg={6} xl={4} className="mb-3">
								<label>Set Password</label>
								<input
									placeholder="Enter Password..."
									value={createUserForm.password}
									onChange={createUserOnChange("password")}
									type="password"
								/>
							</Col>

							<Col xs={12} md={6} lg={6} xl={4} className="mb-3">
								<label>Confirm Password</label>
								<input
									placeholder="Confirm Password..."
									value={createUserForm.confirmPassword}
									onChange={createUserOnChange("confirmPassword")}
									type="password"
								/>
							</Col>

							<Col xs={0} md={0} lg={0} xl={4}/>
						</Row>

						<label>Assign Role</label>
						<AlliedRadioRow>
							<AlliedInputRadioFactory radios={getAssignableRoles(props.fullToken?.type)}/>
						</AlliedRadioRow>

						{isCreateCraneOperatorBody(createUserForm) && (
							<Row>
								<Col xs={12} md={6} className="mb-3">
									<label>Certification Number</label>
									<input
										placeholder="Enter Certification Number..."
										value={createUserForm.certificationNumber}
										onChange={createUserOnChange("certificationNumber")}
									/>
								</Col>
							</Row>
						)}

						{/*{isCreateCraneOperatorBody(createUserForm) && (*/}
						{/*	<Row>*/}
						{/*		<Col xs={12} md={6} className="mb-3">*/}
						{/*			<p>*/}
						{/*				After creating this user, head to the <Link to="/sites-options/manage-sites">Manage*/}
						{/*				Sites</Link> page and <b>assign</b> the new user to the sites they need access to*/}
						{/*				by clicking the "Edit Users" button on the relevant site(s). You may need to scroll*/}
						{/*				right on the table to see the buttons depending on your monitor size & zoom level.*/}
						{/*			</p>*/}
						{/*		</Col>*/}
						{/*	</Row>*/}
						{/*)}*/}

						<hr/>

						{createUserForm.type !== UserType.ADMIN && (
							<React.Fragment>
								{sitesResponse && (
									<div>
										<p>
											{`User will be automatically assigned to ${createUserForm.sites.length} site(s) upon creation.`}
										</p>
										<Button
											color="red"
											type="button"
											onClick={() => setShowSitesModal(true)}
										>
											Edit Site Assignment
										</Button>
									</div>
								)}

								<hr/>
							</React.Fragment>
						)}

						<Button
							color="black"
							type="submit"
							onClick={submitNewUser}
						>
							Create New User
						</Button>
					</form>
				</AlliedFormContainer>
			</AlliedContainer>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(CreateNewUserPage);
