/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaginationInfoFromJSON, PaginationInfoToJSON, } from './PaginationInfo';
import { TimeSheetFromJSON, TimeSheetToJSON, } from './TimeSheet';
/**
 * Check if a given object implements the GetTimeSheetListResponse interface.
 */
export function instanceOfGetTimeSheetListResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "paginationInfo" in value;
    isInstance = isInstance && "timeSheets" in value;
    return isInstance;
}
export function GetTimeSheetListResponseFromJSON(json) {
    return GetTimeSheetListResponseFromJSONTyped(json, false);
}
export function GetTimeSheetListResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'paginationInfo': PaginationInfoFromJSON(json['paginationInfo']),
        'timeSheets': (json['timeSheets'].map(TimeSheetFromJSON)),
    };
}
export function GetTimeSheetListResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'paginationInfo': PaginationInfoToJSON(value.paginationInfo),
        'timeSheets': (value.timeSheets.map(TimeSheetToJSON)),
    };
}
