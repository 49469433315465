import React, {ReactElement} from "react";
import {Button} from "reactstrap";
import {Crane} from "client";
import ViewCraneQrCodeButton from "../../printables/buttons/ViewCraneQrCodeButton";

interface Props {
	crane: Crane;
}

function ManageCranesQrCodeCell({crane}: Props): ReactElement {

	function preparePrintable(): void {
		console.log("crane 2:", crane);
	}

	return (
		<div className="d-flex justify-content-center">
			<ViewCraneQrCodeButton crane={crane}>
				Get Qr Code
			</ViewCraneQrCodeButton>
			{/*<Button*/}
			{/*	color="black"*/}
			{/*	onClick={preparePrintable}*/}
			{/*>*/}
			{/*	Get Qr Code*/}
			{/*</Button>*/}
		</div>
	);
}

export default ManageCranesQrCodeCell;