import React, {useState} from "react";
import {Crane} from "client";
import {Button} from "reactstrap";
import ManageCranesDisableModal from "../../modals/ManageCranesDisableModal";

interface IProps {
	crane: Crane;
	onDone: () => Promise<void>;
}

const ManageCranesDisableCell: React.FC<IProps> = (props) => {

	const [showDisable, setShowDisable] = useState(false);

	/**
	 * Toggle showing the edit crane modal.
	 *
	 */
	function toggleShowDisable(): void {
		setShowDisable(!showDisable);
	}

	function onDone(): void {
		setShowDisable(false);
		props.onDone().then().catch();
	}

	return (
		<div className="d-flex justify-content-center">
			<ManageCranesDisableModal
				isOpen={showDisable}
				crane={props.crane}
				onClose={toggleShowDisable}
				onDone={onDone}
			/>

			<Button
				color={props.crane?.disabled ? "gray" : "red"}
				onClick={toggleShowDisable}
			>
				{props.crane?.disabled ? "Enable Crane" : "Disable Crane"}
			</Button>
		</div>
	);
};

export default ManageCranesDisableCell;
