import moment, {Moment} from "moment";
import {getWeekRangeForTable} from "./getWeekRangeForTable";
import {IAutoCompleteOption} from "../components/inputs/AlliedAutoComplete";


/**
 * Populates auto-complete with week values starting at the beginning of baseDate
 * and up until 2 weeks in the future.
 *
 */
export function getWeeksForDailyLogFilter(): number[] {
	const baseDate: Moment = moment({year: 2021}).startOf("isoWeek");
	const endDate: Moment = moment().add(2, "weeks").startOf("isoWeek");
	const ranges: Array<Moment> = [];

	let date: Moment = baseDate;
	while (date.isBefore(endDate)) {
		ranges.push(date);
		date = moment(date).add(1, "week");
	}

	return ranges.reverse().map((r: Moment) => r.valueOf());
}

/**
 * Used to convert a list of dates representing starts of weeks into
 * options for the auto-complete input.
 *
 * @param ranges
 */
export function convertWeekListToAutoCompleteValues(ranges: number[] = []): Array<IAutoCompleteOption> {
	return ranges.map((r: number) => {
		return {
			value: r?.toString(),
			label: getWeekRangeForTable(1)(r),
		}
	});
}

/**
 * Used to convert a list of pay periods
 * to options for the auto-complete input.
 *
 * @param payPeriods
 */
export function convertPayPeriodListToAutoCompleteValues(payPeriods: number[] = []): Array<IAutoCompleteOption> {
	return payPeriods.map((r: number) => {
		return {
			value: r?.toString(),
			label: getWeekRangeForTable(2)(r),
		}
	});
}
