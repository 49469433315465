import React, {ReactNode} from "react";

interface IProps {
	workPerformed?: Array<string>;
}

const PrintableWorkPerformedList: React.FC<IProps> = (props) => {

	function makeWorkEntry(entry: string, i: number): ReactNode {
		return (
			<tr key={`work-performed_${i}_${entry}_${Math.random()}`}>
				<td className="printable__table__label">
					{i + 1}
				</td>

				<td style={{width: "100%"}} className="printable__table__white-space-pre">
					{entry}
				</td>
			</tr>
		);
	}

	return (
		<div style={{width: "100%"}}>
			<table className="printable__table">
				{props.workPerformed?.length > 0 ? (
					<React.Fragment>
						{props.workPerformed?.map(makeWorkEntry)}
					</React.Fragment>
				) : (
					<td
						className="printable__table__centered"
						colSpan={2}
					>
						No Entries
					</td>
				)}
			</table>
		</div>
	);
};

export default PrintableWorkPerformedList;
