import React, {ChangeEventHandler, ReactNode, useState} from "react";
import {CreateServiceReportEntryBody, User} from "client";
import moment from "moment";
import {Col, Row} from "reactstrap";
import AlliedAutoComplete from "./AlliedAutoComplete";
import {parseUsersForAutoComplete} from "../../utils/parseUsersForAutoComplete";
import AlliedDatePicker from "./AlliedDatePicker";
import {standardTimePickerProps} from "./LunchBreakInput";
import NumberFormat from "react-number-format";
import AlliedRadioRow from "./AlliedRadioRow";
import {AlliedInputRadioFactory} from "./AlliedInputRadio";
import classNames from "classnames";
import {
	convertMinutesToHoursAndMinutes,
	getNewMinutesValue,
	IHoursAndMinutes
} from "../../utils/convertMinutesToHoursAndMinutes";
import AlliedSelect from "./AlliedSelect";
import SelectOptionsFactory, {ISelectOption} from "../SelectOptionsFactory";
import {cloneDeep} from "lodash";
import {humanReadableDuration} from "../../utils/convertMillisecondsToHours";

export interface IAlliedServiceReportEntryInputProps {
	value: CreateServiceReportEntryBody;
	onChange: (newValue: CreateServiceReportEntryBody) => void;
	employees: Array<User>;
	index: number;
	children?: ReactNode;
}

const AlliedServiceReportEntryInput: React.FC<IAlliedServiceReportEntryInputProps> = (props) => {

	const [handledFirstCalledOutChange, setHandledFirstCalledOutChange] = useState(false); // We run logic to update "Arrival to Site" the first time the user changes the "Called Out" field.
	const [handledFirstLeftSiteChange, setHandledFirstLeftSiteChange] = useState(false);// We run logic to update "Return Time" the first time the user changes the "Left Job Site" field.
	const [handledFirstLunchBreakStartChange, setHandledFirstLunchBreakStartChange] = useState(false);// We run logic to update "Lunch End Time" the first time the user changes the "Lunch Start Time" field.

	/**
	 * onChange to help with the single select auto-complete
	 * for choosing an employee.
	 *
	 * @param users
	 */
	function employeeOnChange(users: string[]): void {
		props.onChange({
			...props.value,
			alliedCraneEmployeeID: props.value?.alliedCraneEmployeeID !== users[0] ? users[0] : "",
		});
	}

	/**
	 * Dynamic onChange for the form text fields.
	 *
	 * @param key
	 */
	function createServiceEntryReportOnChange(key: keyof Pick<CreateServiceReportEntryBody, "standByReason" | "sourcePartsReason">): ChangeEventHandler<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> {
		return (e) => {
			props.onChange({
				...props.value,
				[key]: e?.target?.value,
			});
		}
	}
	/**
	 * Dynamic onChange for start & end times.
	 *
	 * @param key
	 */
	function onTimeChange(key: keyof Pick<CreateServiceReportEntryBody, "calledOut" | "arrivalToSite" | "leftJobSite" | "returnTime" | "standByTime" | "sourcePartsTime" | "lunchBreakStartTime" | "lunchBreakEndTime">): (d: Date) => void {
		return (d: Date) => {

			const clonedForm: CreateServiceReportEntryBody = cloneDeep(props.value);
			clonedForm[key] = moment(d).valueOf();

			// if (key === "calledOut" && !handledFirstCalledOutChange) {
			// 	clonedForm.arrivalToSite = moment(d).valueOf();
			// 	setHandledFirstCalledOutChange(true);
			// } else if (key === "leftJobSite" && !handledFirstLeftSiteChange) {
			// 	clonedForm.returnTime = moment(d).valueOf();
			// 	setHandledFirstLeftSiteChange(true);
			// } else if (key === "lunchBreakStartTime" && !handledFirstLunchBreakStartChange) {
			// 	clonedForm.lunchBreakEndTime = moment(d).valueOf();
			// 	setHandledFirstLunchBreakStartChange(true);
			// }

			props.onChange(clonedForm);
		}
	}

	/**
	 * Dynamic onChange for number inputs using the NumberFormat library.
	 *
	 * @param key
	 */
	function numberFormatOnChange(key: keyof Pick<CreateServiceReportEntryBody, "standByTime" | "sourcePartsTime">): (e) => void {
		return (e) => {
			props.onChange({
				...props.value,
				[key]: e?.floatValue,
			});
		}
	}

	/**
	 * Function to toggle the lunch breaks section.
	 *
	 * @param has
	 */
	function showLunchBreaksOnChange(has: boolean): () => void {
		return () => {
			props.onChange({
				...props.value,
				lunchBreak: has,
			})
		}
	}

	return (
		<div className="w-100">
			<Row>
				<Col xs={12} className="mb-3">
					<label>Technician Name</label>
					<AlliedAutoComplete
						placeholder="Technician..."
						options={parseUsersForAutoComplete(props.employees)}
						selections={props.value?.alliedCraneEmployeeID ? [props.value?.alliedCraneEmployeeID] : []}
						setSelections={employeeOnChange}
						multiSelect={false}
					/>
				</Col>
			</Row>

			<Row>
				<Col xs={12} sm={6} className="mb-3">
					<label>Called Out</label>
					<AlliedDatePicker
						{...standardTimePickerProps}
						selected={props.value?.calledOut}
						onChange={onTimeChange("calledOut")}
					/>
				</Col>

				<Col xs={12} sm={6} className="mb-3">
					<label>Arrival to Site</label>
					<AlliedDatePicker
						{...standardTimePickerProps}
						selected={props.value?.arrivalToSite}
						onChange={onTimeChange("arrivalToSite")}
					/>
				</Col>
			</Row>

			<Row>
				<Col xs={12} sm={6} className="mb-3">
					<label>Left Job Site</label>
					<AlliedDatePicker
						{...standardTimePickerProps}
						selected={props.value?.leftJobSite}
						onChange={onTimeChange("leftJobSite")}
					/>
				</Col>

				<Col xs={12} sm={6} className="mb-3">
					<label>Return Time</label>
					<AlliedDatePicker
						{...standardTimePickerProps}
						selected={props.value?.returnTime}
						onChange={onTimeChange("returnTime")}
					/>
				</Col>
			</Row>

			<div>
				<label>On Site Time</label>
				<p>
					{humanReadableDuration(props.value.leftJobSite - props.value.arrivalToSite)}
				</p>
			</div>

			<Row>
				<Col xs={12}>
					<label>Stand By Time</label>
				</Col>
				<Col xs={12} sm={6} className="mb-3">
					<NumberFormat
						allowLeadingZeros={false}
						placeholder="Hours..."
						value={props.value?.standByTime}
						onValueChange={numberFormatOnChange("standByTime")}
						allowNegative={false}
						decimalScale={2}
					/>
				</Col>

				<Col xs={12} sm={6} className="mb-3">
					<input
						placeholder="Enter Reason..."
						value={props.value?.standByReason}
						onChange={createServiceEntryReportOnChange("standByReason")}
					/>
				</Col>
			</Row>

			<Row>
				<Col xs={12}>
					<label>Source Parts Time</label>
				</Col>
				<Col xs={12} sm={6} className="mb-3">
					<NumberFormat
						allowLeadingZeros={false}
						placeholder="Hours..."
						value={props.value?.sourcePartsTime}
						onValueChange={numberFormatOnChange("sourcePartsTime")}
						allowNegative={false}
						decimalScale={2}
					/>
				</Col>

				<Col xs={12} sm={6} className="mb-3">
					<input
						placeholder="Enter Reason..."
						value={props.value?.sourcePartsReason}
						onChange={createServiceEntryReportOnChange("sourcePartsReason")}
					/>
				</Col>
			</Row>

			<Row>
				<Col className={classNames({"mb-3": props.value?.lunchBreak})} xs={12} md={6}>
					<label>Lunch Break</label>
					<AlliedRadioRow>
						<AlliedInputRadioFactory
							radios={[
								{
									label: "Yes",
									name: `Yes Lunch Breaks_${props.index}`,
									checked: props.value?.lunchBreak === true,
									onChange: showLunchBreaksOnChange(true),
								},
								{
									label: "No",
									name: `No Lunch Breaks_${props.index}`,
									checked: props.value?.lunchBreak === false,
									onChange: showLunchBreaksOnChange(false),
								}
							]}
						/>
					</AlliedRadioRow>
				</Col>
			</Row>

			{props.value?.lunchBreak && (
				<Row>
					<Col xs={12} sm={6} className="mb-3">
						<label>Lunch Start Time</label>
						<AlliedDatePicker
							{...standardTimePickerProps}
							selected={props.value?.lunchBreakStartTime}
							onChange={onTimeChange("lunchBreakStartTime")}
						/>
					</Col>

					<Col xs={12} sm={6} className="mb-3">
						<label>Lunch End Time</label>
						<AlliedDatePicker
							{...standardTimePickerProps}
							selected={props.value?.lunchBreakEndTime}
							onChange={onTimeChange("lunchBreakEndTime")}
						/>
					</Col>
				</Row>
			)}

			{props.children}
		</div>
	);
};

// export default React.memo(AlliedServiceReportEntryInput);
export default AlliedServiceReportEntryInput;
