import React, {useState} from "react";
import {TimeSheet} from "client";
import {Button} from "reactstrap";
import ManageTimeSheetsHistoryEditEntriesModal from "../../modals/ManageTimeSheetsHistoryEditEntriesModal";

interface IProps {
	timeSheet: TimeSheet;
	onDone: () => Promise<void>;
}

// This is the version of the table cell used by Employees.
// For the Admin version see ManageTimeSheetsEditEntriesCell.

const ManageTimeSheetsHistoryEditEntriesCell: React.FC<IProps> = (props) => {

	const [showEntriesModal, setShowEntriesModal] = useState(false);

	/**
	 * Toggle visibility of the time sheet entries modal
	 *
	 */
	function toggleShowEntriesModal(): void {
		setShowEntriesModal(!showEntriesModal);
	}

	/**
	 * Close modal and call isDone function from props to update table.
	 *
	 */
	function onDone(): void {
		setShowEntriesModal(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<ManageTimeSheetsHistoryEditEntriesModal
				isOpen={showEntriesModal}
				timeSheet={props.timeSheet}
				onClose={toggleShowEntriesModal}
				onDone={onDone}
			/>

			<div className="allied-table-cell">
				<Button
					color="black"
					onClick={toggleShowEntriesModal}
				>
					Time Sheet Options
				</Button>
			</div>
		</React.Fragment>
	);
};

export default ManageTimeSheetsHistoryEditEntriesCell;
