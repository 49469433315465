import React, {useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {CraneMonthlyLog, FormsApi, Token} from "client";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {useHistory} from "react-router-dom";
import getConfig from "../../utils/getConfig";
import ViewCraneMonthlyLogButton from "../printables/buttons/ViewCraneMonthlyLogButton";
import CraneMonthlyLogConfirmDeleteDraftModalForOperator from "./CraneMonthlyLogConfirmDeleteDraftModalForOperator";

interface IProps {
    dispatch?: any;
    fullToken?: Token;
    isOpen: boolean;
    log: CraneMonthlyLog;
    onClose: () => void;
    onDone: () => void;
}

const CraneMonthlyLogsManagerAndSupervisorOptionsModal: React.FC<IProps> = (props) => {

    const history = useHistory();
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    /**
     * Send user to the edit page for the Crane Monthly Logs in question.
     *
     */
    function onEditCraneMonthlyLogs(): void {
        history.push(`/edit-forms/edit-crane-monthly-log?id=${props.log?._id}`);
    }

    function toggleConfirmDeleteModal(): void {
        setShowDeleteModal(!showDeleteModal);
    }

	function onConfirmDeleteLog(): void {
		setShowDeleteModal(false);
		props.onDone();
	}

    /**
     * Call api to approve the Crane Monthly Logs,
     * and call onDone function from props after to update table.
     *
     */
    async function onApproveCraneMonthlyLogs(): Promise<void> {
        props.dispatch(incrementLoading());

        try {
            await new FormsApi(getConfig(props.fullToken)).publishCraneMonthlyLog({
                iDBody: {
                    id: props.log?._id,
                },
            });
            props.onDone();
        } catch (e) {
            props.dispatch(addError(e));
        }

        props.dispatch(decrementLoading());
    }

    return (
        <React.Fragment>
            <CraneMonthlyLogConfirmDeleteDraftModalForOperator
                isOpen={showDeleteModal}
                log={props.log}
                onClose={toggleConfirmDeleteModal}
                onDone={onConfirmDeleteLog}
            />

            <AlliedModal
                isOpen={props.isOpen}
                size="md"
            >
                <AlliedModalHeader
                    title="Crane Monthly Log Options"
                    onClose={props.onClose}
                />

                <hr/>

                <p>
                    *Note that you can only edit the form <b>before</b> it's approved.
                </p>

				<div className="d-flex flex-row align-items-start flex-wrap">
					<ViewCraneMonthlyLogButton
						id={props.log?._id}
						className="mr-3 mb-3"
					>
						View Monthly Log
					</ViewCraneMonthlyLogButton>

					<Button
						color="black"
						onClick={onEditCraneMonthlyLogs}
						className="mb-3"
						disabled={props.log?.approvedBy !== undefined}
					>
						Edit Monthly Log
					</Button>

					<Button
						color="black"
						className="mr-3 mb-3"
						onClick={toggleConfirmDeleteModal}
						disabled={props.log?.submittedDate !== undefined}
					>
						Delete Draft
					</Button>
				</div>

				<hr className="mt-0"/>

				<div>
                    <Button
                        color="red"
                        onClick={onApproveCraneMonthlyLogs}
                        disabled={props.log?.approvedBy !== undefined}
                    >
                        Approve Monthly Log
                    </Button>
                </div>
            </AlliedModal>
        </React.Fragment>
    );
};

export default connect((store: IStore, props: IProps) => {
    return {
        fullToken: store.metaStore.fullToken,
        ...props,
    }
})(CraneMonthlyLogsManagerAndSupervisorOptionsModal);
