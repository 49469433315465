import React from "react";
import {connect} from "react-redux";
import {CraneMonthlyLog, CraneWeeklyLog, FormsApi, Token} from "client";
import {IStore} from "../../redux/defaultStore";
import AlliedModalHeader from "./AlliedModalHeader";
import ViewCraneMonthlyLogButton from "../printables/buttons/ViewCraneMonthlyLogButton";
import {Button} from "reactstrap";
import AlliedModal from "./AlliedModal";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	log: CraneWeeklyLog;
	onClose: () => void;
	onDone: () => void;
}

const CraneWeeklyLogConfirmDeleteDraftModalForOperator: React.FC<IProps> = (props) => {

	async function confirmDeleteLog(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new FormsApi(getConfig(props.fullToken)).disableForm({
				iDBody: {
					id: props.log._id,
				},
			});
			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<AlliedModal
			isOpen={props.isOpen}
			size="md"
		>
			<AlliedModalHeader
				title="Confirm Log Deletion"
				onClose={props.onClose}
			/>

			<hr/>

			<p>
				Are you sure you want to delete this weekly log draft?
			</p>

			<div className="d-flex flex-row align-items-start flex-wrap">
				<Button
					color="black"
					className="mr-3 mb-3"
					onClick={props.onClose}
				>
					Cancel
				</Button>

				<Button
					color="red"
					onClick={confirmDeleteLog}
				>
					Delete Draft
				</Button>
			</div>
		</AlliedModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(CraneWeeklyLogConfirmDeleteDraftModalForOperator);
