import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	Equipment,
	EquipmentApi,
	GetEquipmentListResponse,
	GetUserListRequest,
	Site,
	Token,
	User, UsersByType,
	UserSortField
} from "client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import AlliedTableContainer from "../tables/AlliedTableContainer";
import AlliedTableHeaderCell from "../tables/cells/AlliedTableHeaderCell";
import {getEquipmentTypeDisplay} from "../../utils/getEquipmentTypeDisplay";
import {valueOrBust} from "../../utils/valueOrBust";
import {getCraneTypeDisplay} from "../../utils/getCraneTypeDisplay";
import {getFullNameForUserTable} from "../../utils/getFullNameForTable";
import {abstractOnSortChange, getTableHeaderSortClassName} from "../../utils/abstractOnSortChange";

interface IProps {
	dispatch?: any;
	isOpen: boolean;
	users: Array<User>;
	userType: keyof UsersByType;
	title: string;
	onClose: () => void;
}

const ManageSitesUsersModal: React.FC<IProps> = (props) => {

	return (
		<AlliedModal
			isOpen={props.isOpen}
			size="lg"
		>
			<AlliedModalHeader
				title={props.title}
				onClose={props.onClose}
			/>

			<hr/>

			<AlliedTableContainer
				data={props.users}
				columnOptions={[
					{
						headerValue: "Name",
						key: undefined,
						valueFormatter: getFullNameForUserTable,
					},
					{
						headerValue: "Username",
						key: "username",
					},
					(props.userType === "craneOperators" && {
						headerValue: "Certificate #",
						key: "certificationNumber",
					}),
				]}
			/>
		</AlliedModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
	}
})(ManageSitesUsersModal);
