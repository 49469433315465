/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { LogHistoryTypeFromJSON, LogHistoryTypeToJSON, } from './LogHistoryType';
import { UserFromJSON, UserToJSON, } from './User';
/**
 * Check if a given object implements the LogHistory interface.
 */
export function instanceOfLogHistory(value) {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "date" in value;
    return isInstance;
}
export function LogHistoryFromJSON(json) {
    return LogHistoryFromJSONTyped(json, false);
}
export function LogHistoryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': LogHistoryTypeFromJSON(json['type']),
        'day': !exists(json, 'day') ? undefined : json['day'],
        'user': UserFromJSON(json['user']),
        'date': json['date'],
    };
}
export function LogHistoryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': LogHistoryTypeToJSON(value.type),
        'day': value.day,
        'user': UserToJSON(value.user),
        'date': value.date,
    };
}
