import React from "react";
import {Equipment} from "client";
import PrintableSpacer from "../elements/PrintableSpacer";
import PrintableCellRow from "../elements/PrintableCellRow";
import PrintableCellLabelWithValue from "../elements/PrintableCellLabelWithValue";
import {asPercentOf100} from "../../../utils/asPercentOf100";
import moment from "moment";
import PrintableEquipmentLocationListTable from "../elements/PrintableEquipmentLocationListTable";

interface IProps {
	form: Array<Equipment>
}

const EquipmentLocationListPrintable: React.FC<IProps> = (props) => {

	return (
		<React.Fragment>
			<p className="printable__header">
				Equipment Location List
			</p>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Date:"
					value={moment().format("MM/DD/YYYY")}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableEquipmentLocationListTable equipment={props.form}/>
		</React.Fragment>
	);
};

export default EquipmentLocationListPrintable;
