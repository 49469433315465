import {Site} from "client";

/**
 * Convert site into a user-friendly string for display in tables.
 *
 * @param site
 */
export function getSiteName(site: Site): string {
	return site?.name;
}
