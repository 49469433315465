/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { GeneratorDailyLogFromJSON, GeneratorDailyLogToJSON, } from './GeneratorDailyLog';
import { LogFromJSONTyped, LogToJSON, } from './Log';
/**
 * Check if a given object implements the GeneratorDailyLogGroup interface.
 */
export function instanceOfGeneratorDailyLogGroup(value) {
    let isInstance = true;
    return isInstance;
}
export function GeneratorDailyLogGroupFromJSON(json) {
    return GeneratorDailyLogGroupFromJSONTyped(json, false);
}
export function GeneratorDailyLogGroupFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...LogFromJSONTyped(json, ignoreDiscriminator),
        '_0': !exists(json, '0') ? undefined : GeneratorDailyLogFromJSON(json['0']),
        '_1': !exists(json, '1') ? undefined : GeneratorDailyLogFromJSON(json['1']),
        '_2': !exists(json, '2') ? undefined : GeneratorDailyLogFromJSON(json['2']),
        '_3': !exists(json, '3') ? undefined : GeneratorDailyLogFromJSON(json['3']),
        '_4': !exists(json, '4') ? undefined : GeneratorDailyLogFromJSON(json['4']),
        '_5': !exists(json, '5') ? undefined : GeneratorDailyLogFromJSON(json['5']),
        '_6': !exists(json, '6') ? undefined : GeneratorDailyLogFromJSON(json['6']),
    };
}
export function GeneratorDailyLogGroupToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...LogToJSON(value),
        '0': GeneratorDailyLogToJSON(value._0),
        '1': GeneratorDailyLogToJSON(value._1),
        '2': GeneratorDailyLogToJSON(value._2),
        '3': GeneratorDailyLogToJSON(value._3),
        '4': GeneratorDailyLogToJSON(value._4),
        '5': GeneratorDailyLogToJSON(value._5),
        '6': GeneratorDailyLogToJSON(value._6),
    };
}
