import {
	CraneOperator,
	CreateGeneratorDailyLogBody,
	CreateLogCommentBody,
	EquipmentApi,
	EquipmentType,
	FormsApi,
	FormType,
	Generator, GeneratorDailyLog, GeneratorDailyLogGroup,
	InspectionStatus, Site, SitesApi,
	Token,
	User,
	UsersApi, UserType,
} from "client";
import moment from "moment";
import React, {ChangeEventHandler, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {useHistory} from "react-router-dom";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {cloneDeep, find} from "lodash";
import {getFullNameForTable} from "../../utils/getFullNameForTable";
import AlliedContainer from "../AlliedContainer";
import AlliedPageHeader from "../AlliedPageHeader";
import AlliedFormContainer from "../AlliedFormContainer";
import {Button, Col, Row} from "reactstrap";
import AlliedAutoComplete from "../inputs/AlliedAutoComplete";
import {parseEquipmentForSelectDropdown} from "../../utils/parseEquipmentForSelectDropdown";
import AlliedDatePicker from "../inputs/AlliedDatePicker";
import AlliedSelectInputList from "../inputs/inputList/AlliedSelectInputList";
import {convertGeneratorInspectionItemsToInputListItems} from "../../utils/convertGeneratorInspectionItemToInputListItem";
import NumberFormat from "react-number-format";
import AlliedGenericInputList from "../inputs/AlliedGenericInputList";
import DailyLogCommentInput from "../inputs/DailyLogCommentInput";
import {parseSitesForAutoComplete} from "../../utils/parseSitesForAutoComplete";
import {convertOffsetDate} from "../../utils/timeZoneConversions";
import {parseUsersForAutoComplete} from "../../utils/parseUsersForAutoComplete";

export const defaultCreateGeneratorDailyLogForm: CreateGeneratorDailyLogBody = {
	initials: false,
	equipmentID: "",
	siteID: "",
	date: moment().startOf("day").valueOf(),
	users: [],
	hours: undefined,
	hourMeterStart: undefined,
	hourMeterEnd: undefined,
	hourMeterTotal: undefined,
	createLogCommentBodies: [{
		flagged: false,
		comment: "",
	}],
	checkOilLevel: undefined,
	checkCoolantLevels: undefined,
	visualInspectionForWear: undefined,
	checkForLeakage: undefined,
	ensureDoorPanelsAndCoversAreClosed: undefined,
}

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	id?: string;
}

const CreateGeneratorDailyLogForm: React.FC<IProps> = (props) => {

	const history = useHistory();
	const [generatorList, setGeneratorList] = useState<Array<Generator>>([]);
	const [siteList, setSitesList] = useState<Array<Site>>([]);
	const [operatorsList, setOperatorsList] = useState<Array<CraneOperator>>([]);
	const [createGeneratorDailyLogForm, setCreateGeneratorDailyLogForm] = useState<CreateGeneratorDailyLogBody>(defaultCreateGeneratorDailyLogForm);
	const [userProfile, setUserProfile] = useState<User>(undefined); // Used for making the user enter their name as a signature (matching the name).
	const [signature, setSignature] = useState(""); // The "signature" as entered by the user; not submitted to api but used to check for the "initials" (boolean) field.

	useEffect(() => {
		getAutoCompleteValues().then().catch();
	}, []);

	useEffect(() => {
		if (
			userProfile?.type === UserType.CRANE_OPERATOR
			&& createGeneratorDailyLogForm.users.indexOf(userProfile?._id) === -1
			&& createGeneratorDailyLogForm.siteID
			&& createGeneratorDailyLogForm.equipmentID
		) {
			setCreateGeneratorDailyLogForm({
				...createGeneratorDailyLogForm,
				users: [userProfile?._id]
			});
		}
	}, [createGeneratorDailyLogForm?.siteID, createGeneratorDailyLogForm?.equipmentID]);

	/**
	 * Call api to get the list of generators & sites for the auto-complete inputs,
	 * and the user's profile for the signature match.
	 *
	 */
	async function getAutoCompleteValues(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const generatorsRes = await new EquipmentApi(getConfig(props.fullToken)).getEquipmentList({
				limit: 10000,
				offset: 0,
				type: [EquipmentType.GENERATOR],
			});

			const sitesRes = await new SitesApi(getConfig(props.fullToken)).getSiteList({
				limit: 10000,
				offset: 0,
			});

			const operatorsRes = await new UsersApi(getConfig(props.fullToken)).getUserList({
				limit: 100000,
				offset: 0,
				type: [UserType.CRANE_OPERATOR],
			});

			const userRes = (await new UsersApi(getConfig(props.fullToken)).getProfile()).user;

			setGeneratorList(generatorsRes.equipment as Array<Generator>);
			setSitesList(sitesRes.sites);
			setOperatorsList(operatorsRes.users as Array<CraneOperator>);
			setUserProfile(userRes);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Handle the auto-complete onChange for the selected equipment,
	 * which returns an array, but we only want the first element.
	 *
	 * Also set the siteID to that of the selected equipment's,
	 * if siteID isn't already set on the form.
	 * @param equipment
	 */
	function equipmentOnChange(equipment: string[]): void {
		const newForm: CreateGeneratorDailyLogBody = cloneDeep(createGeneratorDailyLogForm);
		const isNewSelection: boolean = createGeneratorDailyLogForm.equipmentID !== equipment[0];

		if (!newForm.siteID && isNewSelection) {
			newForm.siteID = find(generatorList, ["_id", equipment[0]])?.site?._id;
		}

		setCreateGeneratorDailyLogForm({
			...newForm,
			equipmentID: isNewSelection ? equipment[0] : "",
		});
	}

	/**
	 * Handle the auto-complete onChange for the selected site,
	 * which returns an array, but we only want the first element.
	 *
	 * @param site
	 */
	function siteOnChange(site: string[]): void {
		setCreateGeneratorDailyLogForm({
			...createGeneratorDailyLogForm,
			siteID: createGeneratorDailyLogForm.siteID !== site[0] ? site[0] : "",
		});
	}

	/**
	 * onChange for the date input.
	 *
	 * @param key
	 */
	function onDateChange(key: keyof CreateGeneratorDailyLogBody): (d: Date) => void {
		return (d: Date) => {
			setCreateGeneratorDailyLogForm({
				...createGeneratorDailyLogForm,
				[key]: moment(d).valueOf(),
			});
		}
	}

	/**
	 * onChange handler for the "Inspection Items" section, which
	 * are displayed dynamically based on the type of crane selected.
	 *
	 * @param key
	 * @param value
	 */
	function onInspectionItemChange(key: keyof CreateGeneratorDailyLogBody, value: InspectionStatus): void {
		setCreateGeneratorDailyLogForm({
			...createGeneratorDailyLogForm,
			[key]: value,
		});
	}

	/**
	 * onChange to help with the single select auto-complete for choosing an employee.
	 *
	 * @param operators
	 */
	function operatorsOnChange(operators: string[]): void {
		setCreateGeneratorDailyLogForm({
			...createGeneratorDailyLogForm,
			users: operators,
		});
	}

	/**
	 * Dynamic onChange for number inputs using the NumberFormat library.
	 *
	 * @param key
	 */
	function numberFormatOnChange(key: keyof CreateGeneratorDailyLogBody): (e) => void {
		return (e) => {
			setCreateGeneratorDailyLogForm({
				...createGeneratorDailyLogForm,
				[key]: e?.floatValue,
			});
		}
	}

	/**
	 * onChange function for all of the dynamic lists with plus & minus icons.
	 *
	 * @param key
	 */
	function inputListOnChange<T>(key: keyof CreateGeneratorDailyLogBody): (i: number, value: T) => void {
		return (i: number, value: T) => {
			const copy: Array<T> = cloneDeep(createGeneratorDailyLogForm[key] as unknown as Array<T>);
			copy[i] = value;

			setCreateGeneratorDailyLogForm({
				...createGeneratorDailyLogForm,
				[key]: copy,
			});
		}
	}

	/**
	 * Add another entry to the array in question.
	 *
	 */
	function inputListOnAdd<T>(key: keyof CreateGeneratorDailyLogBody): () => void {
		return () => {
			setCreateGeneratorDailyLogForm({
				...createGeneratorDailyLogForm,
				[key]: (createGeneratorDailyLogForm[key] as unknown as Array<T>).concat([defaultCreateGeneratorDailyLogForm[key][0]]),
			});
		}
	}

	/**
	 * Remove item at index i from the array in question.
	 *
	 */
	function inputListOnRemove<T>(key: keyof CreateGeneratorDailyLogBody): (i: number) => void {
		return (i: number) => {
			const copy: Array<T> = cloneDeep(createGeneratorDailyLogForm[key] as unknown as Array<T>);
			copy.splice(i, 1);

			setCreateGeneratorDailyLogForm({
				...createGeneratorDailyLogForm,
				[key]: copy,
			});
		}
	}

	/**
	 * Dynamic onChange for standard text inputs.
	 *
	 * @param key
	 */
	function textInputOnChange(key: keyof CreateGeneratorDailyLogBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setCreateGeneratorDailyLogForm({
				...createGeneratorDailyLogForm,
				[key]: e?.target?.value,
			});
		}
	}

	/**
	 * onChange for the signature field that the user must enter before submitting.
	 *
	 * @param e
	 */
	function onSignatureChange(e: React.ChangeEvent<HTMLInputElement>): void {
		setSignature(e?.target?.value);
	}

	/**
	 * Call api to either submit new Generator Daily Log or update existing and return id.
	 *
	 * @param e
	 */
	async function saveGeneratorDailyLog(e?: React.SyntheticEvent): Promise<string> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		let createResponse: GeneratorDailyLogGroup;

		try {
			const initials: boolean = signature?.toLowerCase() === getFullNameForTable(userProfile)?.toLowerCase(); // Check if the user's entered signature matches their name properly.

			const request = {
				users: createGeneratorDailyLogForm.users,
				equipmentID: createGeneratorDailyLogForm?.equipmentID ? createGeneratorDailyLogForm?.equipmentID : undefined,
				siteID: createGeneratorDailyLogForm?.siteID ? createGeneratorDailyLogForm?.siteID : undefined,
				date: createGeneratorDailyLogForm?.date !== undefined ? convertOffsetDate(createGeneratorDailyLogForm?.date) : undefined,
				hours: createGeneratorDailyLogForm?.hours !== undefined ? createGeneratorDailyLogForm?.hours : undefined,
				hourMeterStart: createGeneratorDailyLogForm?.hourMeterStart !== undefined ? createGeneratorDailyLogForm?.hourMeterStart : undefined,
				hourMeterEnd: createGeneratorDailyLogForm?.hourMeterEnd !== undefined ? createGeneratorDailyLogForm?.hourMeterEnd : undefined,
				hourMeterTotal: createGeneratorDailyLogForm?.hourMeterTotal !== undefined ? createGeneratorDailyLogForm?.hourMeterTotal : undefined,
				createLogCommentBodies: createGeneratorDailyLogForm?.createLogCommentBodies?.filter((c: CreateLogCommentBody): boolean => {
					return c.comment?.length > 0;
				}).map(c => {
					return {
						...c,
						date: convertOffsetDate(createGeneratorDailyLogForm?.date),
					}
				}),
				checkOilLevel: createGeneratorDailyLogForm?.checkOilLevel ? createGeneratorDailyLogForm?.checkOilLevel : undefined,
				checkCoolantLevels: createGeneratorDailyLogForm?.checkCoolantLevels ? createGeneratorDailyLogForm?.checkCoolantLevels : undefined,
				visualInspectionForWear: createGeneratorDailyLogForm?.visualInspectionForWear ? createGeneratorDailyLogForm?.visualInspectionForWear : undefined,
				checkForLeakage: createGeneratorDailyLogForm?.checkForLeakage ? createGeneratorDailyLogForm?.checkForLeakage : undefined,
				ensureDoorPanelsAndCoversAreClosed: createGeneratorDailyLogForm?.ensureDoorPanelsAndCoversAreClosed ? createGeneratorDailyLogForm?.ensureDoorPanelsAndCoversAreClosed : undefined,
				initials,
			};

			createResponse = await new FormsApi(getConfig(props.fullToken)).createGeneratorDailyLog({
				createGeneratorDailyLogBody: request,
			});
		} catch (err) {
			props.dispatch(addError(err));
			props.dispatch(decrementLoading());
			throw err;
		}

		props.dispatch(decrementLoading());

		if (!props.id && createResponse) {
			return createResponse?._id;
		}
	}

	/**
	 * User when creating or updating to save the draft.
	 * Afterwards, navigate to the history page.
	 *
	 * @param e
	 */
	async function saveDraft(e?: React.SyntheticEvent): Promise<void> {
		e?.preventDefault();
		try {
			await saveGeneratorDailyLog();
			history.push("/form-history/daily-log-history/equipment-daily-log-history");
		} catch (e) {

		}
	}

	/**
	 * Util to check if the "Save Draft" button should be enabled
	 *
	 * @param _signature
	 * @param user
	 * @param _equipmentId
	 */
	function checkForSaveButtonEnabled(_signature: string, user: User, _equipmentId: string): boolean {
		return !((_signature?.toLowerCase() === getFullNameForTable(user).toLowerCase()) && _equipmentId?.length > 0);
	}

	/**
	 * Frontend filtering to get just the operators who exist on the selected site.
	 *
	 * @param operators
	 */
	function getFilteredOperators(operators: Array<CraneOperator>): Array<CraneOperator> {
		return operators.filter(o => o.sites?.find(s => s._id === createGeneratorDailyLogForm.siteID));
	}

	const selectedGenerator: Generator = find(generatorList, ["_id", createGeneratorDailyLogForm?.equipmentID]);

	return (
		<AlliedContainer>
			<AlliedPageHeader
				title={`${props.id ? "Edit" : "Create"} Generator Daily Log`}
				showTooltip={false}
			/>

			<hr/>

			<AlliedFormContainer>
				<form onSubmit={saveDraft}>
					<Row>
						<Col xs={12} md={6} className="mb-3">
							<label>Select Generator for Log</label>
							<AlliedAutoComplete
								placeholder="Select Generator..."
								options={parseEquipmentForSelectDropdown(generatorList)}
								selections={createGeneratorDailyLogForm.equipmentID ? [createGeneratorDailyLogForm.equipmentID] : []}
								setSelections={equipmentOnChange}
								multiSelect={false}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={6} className="mb-3">
							<label>Select Site</label>
							<AlliedAutoComplete
								placeholder="Select Site..."
								options={parseSitesForAutoComplete(siteList)}
								selections={createGeneratorDailyLogForm.siteID ? [createGeneratorDailyLogForm.siteID] : []}
								setSelections={siteOnChange}
								multiSelect={false}
							/>
						</Col>
					</Row>

					<hr/>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Date</label>
							<AlliedDatePicker
								selected={createGeneratorDailyLogForm.date}
								onChange={onDateChange("date")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={6} className="mb-3">
							<AlliedSelectInputList
								key={JSON.stringify(selectedGenerator)}
								title="Inspection Items"
								emptyMessage="Please select a crane first."
								inputs={
									convertGeneratorInspectionItemsToInputListItems(
										[
											"checkOilLevel",
											"checkCoolantLevels",
											"visualInspectionForWear",
											"checkForLeakage",
											"ensureDoorPanelsAndCoversAreClosed",
										],
										createGeneratorDailyLogForm,
									)}
								onChange={onInspectionItemChange}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Operators for Selected Date</label>
							{(createGeneratorDailyLogForm?.equipmentID && createGeneratorDailyLogForm?.siteID) ? (
								<AlliedAutoComplete
									placeholder="Select Operators..."
									options={parseUsersForAutoComplete(getFilteredOperators(operatorsList))}
									selections={createGeneratorDailyLogForm.users}
									setSelections={operatorsOnChange}
								/>
							) : (
								<div>
									<i>
										Please select a generator & site first.
									</i>
								</div>
							)}
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<label>Daily Generator Hours:</label>
						</Col>

						<Col xs={12} md={4} className="mb-3">
							<NumberFormat
								allowLeadingZeros={false}
								placeholder="Enter Daily Generator Hours..."
								value={createGeneratorDailyLogForm.hours}
								onValueChange={numberFormatOnChange("hours")}
								allowNegative={false}
								decimalScale={2}
							/>
						</Col>
					</Row>

					<h5 className="font-weight-bold">Generator Hour Meter</h5>

					<Row>
						<Col xs={12}>
							<label>Record the Hour Meter Value at the Beginning of the Day:</label>
						</Col>

						<Col xs={12} md={4} className="mb-3">
							<NumberFormat
								allowLeadingZeros={false}
								placeholder="Enter Hour Value..."
								value={createGeneratorDailyLogForm.hourMeterStart}
								onValueChange={numberFormatOnChange("hourMeterStart")}
								allowNegative={false}
								decimalScale={2}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<label>Record the Hour Meter Value at the End of the Day:</label>
						</Col>

						<Col xs={12} md={4} className="mb-3">
							<NumberFormat
								allowLeadingZeros={false}
								placeholder="Enter Hour Value..."
								value={createGeneratorDailyLogForm.hourMeterEnd}
								onValueChange={numberFormatOnChange("hourMeterEnd")}
								allowNegative={false}
								decimalScale={2}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<label>Total Hour Meter Value for Next Service:</label>
						</Col>

						<Col xs={12} md={4} className="mb-3">
							<NumberFormat
								allowLeadingZeros={false}
								placeholder="Enter Hour Value..."
								value={createGeneratorDailyLogForm.hourMeterTotal}
								onValueChange={numberFormatOnChange("hourMeterTotal")}
								allowNegative={false}
								decimalScale={2}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<h5 className="font-weight-bold">Comments</h5>
						</Col>
						<Col xs={12} md={6} className="mb-3">
							<AlliedGenericInputList
								values={createGeneratorDailyLogForm.createLogCommentBodies}
								onChange={inputListOnChange<CreateLogCommentBody>("createLogCommentBodies")}
								onAdd={inputListOnAdd("createLogCommentBodies")}
								onRemove={inputListOnRemove("createLogCommentBodies")}
								component={DailyLogCommentInput}
							/>
						</Col>
					</Row>

					<hr/>

					<Row>
						<Col xs={12}>
							<label>Enter full name to confirm this report is accurate.</label>
						</Col>

						<Col xs={12} sm={6} md={4} lg={3} className="mb-3">
							<input
								placeholder="Enter Signature..."
								value={signature}
								onChange={onSignatureChange}
							/>
						</Col>
						<Col xs={12} sm={6} md={4} lg={3} className="mb-3 user-select-none">
							<input
								disabled={true}
								placeholder="Loading..."
								value={getFullNameForTable(userProfile)}
								className="user-select-none pointer-events-none"
							/>
						</Col>
					</Row>

					<div className="d-flex flex-column align-items-center flex-sm-row">
						<Button
							color="black"
							disabled={checkForSaveButtonEnabled(signature, userProfile, createGeneratorDailyLogForm?.equipmentID)}
							type="submit"
							onClick={saveDraft}
							className="mr-0 mr-sm-3 mb-3 mb-sm-0"
						>
							Save Draft
						</Button>
					</div>
				</form>
			</AlliedFormContainer>
		</AlliedContainer>
	);
}

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(CreateGeneratorDailyLogForm);
