import React, {useState} from "react";
import {Button} from "reactstrap";
import AlliedModal from "../../modals/AlliedModal";
import {User} from "client";
import ManageUsersEditModal from "../../modals/ManageUsersEditModal";

interface IProps {
	user: User;
	onDone(): Promise<void>;
}

const ManageUsersEditCell: React.FC<IProps> = (props) => {

	const [showEdit, setShowEdit] = useState(false);

	/**
	 * Toggle showing the edit user modal.
	 *
	 */
	function toggleShowEdit(): void {
		setShowEdit(!showEdit);
	}

	/**
	 * Call when finished with form; close modal & call props.onDone to get new table data.
	 *
	 */
	function onDone(): void {
		setShowEdit(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<ManageUsersEditModal
				isOpen={showEdit}
				user={props.user}
				onClose={toggleShowEdit}
				onDone={onDone}
			/>

			<div className="allied-table-cell">
				<Button
					color="black"
					onClick={toggleShowEdit}
				>
					Edit User Information
				</Button>
			</div>
		</React.Fragment>
	);
};

export default ManageUsersEditCell;
