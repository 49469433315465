import React from "react";
import {FormsApi, TimeSheet, Token} from "client";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/MetaActions";
import getConfig from "../../../utils/getConfig";
import {connect} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import AbstractPrintableButtonManager from "./AbstractPrintableButtonManager";
import TimeSheetPrintable from "../forms/TimeSheetPrintable";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	id: string;
	children: string;
	showPrintable?: boolean;
}

const ViewTimeSheetButton: React.FC<IProps> = (props) => {

	/**
	 * Call api to get & return the time sheet.
	 *
	 */
	async function getFinalTestForm(): Promise<TimeSheet> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FormsApi(getConfig(props.fullToken)).getTimeSheet({
				timeSheetID: props.id,
			});

			props.dispatch(decrementLoading());
			return res;
		} catch (e) {
			props.dispatch(addError(e));
			props.dispatch(decrementLoading());
		}
	}

	return (
		<AbstractPrintableButtonManager
			<TimeSheet>
			color="black"
			onClick={getFinalTestForm}
			component={TimeSheetPrintable}
			showPrintable={props.showPrintable}
			documentTitle="time-sheet"
		>
			{props.children}
		</AbstractPrintableButtonManager>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ViewTimeSheetButton);
