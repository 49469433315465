/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the HealthAndSafetyOfficer interface.
 */
export function instanceOfHealthAndSafetyOfficer(value) {
    let isInstance = true;
    return isInstance;
}
export function HealthAndSafetyOfficerFromJSON(json) {
    return HealthAndSafetyOfficerFromJSONTyped(json, false);
}
export function HealthAndSafetyOfficerFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HealthAndSafetyOfficerToJSON(value) {
    return value;
}
