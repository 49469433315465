import React from "react";
import {Crane, CraneDailyLog, GeneratorDailyLog, GeneratorDailyLogGroup} from "client";
import PrintableSpacer from "../elements/PrintableSpacer";
import PrintableCellLabelWithValue from "../elements/PrintableCellLabelWithValue";
import {asPercentOf100} from "../../../utils/asPercentOf100";
import PrintableCellRow from "../elements/PrintableCellRow";
import {getWeekRangeForTable} from "../../../utils/getWeekRangeForTable";
import {valueOrBustPrintables} from "../../../utils/valueOrBust";
import {parseSiteAddressForSingleInput} from "../../../utils/parseSiteAddressForSingleInput";
import PrintableGeneratorDailyLogChecklistTable from "../elements/PrintableGeneratorDailyLogChecklistTable";
import PrintableDailyLogCommentTableGroup, {LogWithComment} from "../elements/PrintableDailyLogCommentTableGroup";
import {getFullNameForTable} from "../../../utils/getFullNameForTable";
import {generateOffsetDate} from "../../../utils/timeZoneConversions";
import {
	getCraneDailyLogGroupAsArray,
	getCraneDailyLogGroupAsArrayUnFiltered, getGeneratorDailyLogGroupAsArrayUnFiltered
} from "../../../utils/getGroupedDailyLogsAsArray";
import {getCraneTypeDisplay} from "../../../utils/getCraneTypeDisplay";

interface IProps {
	form: GeneratorDailyLogGroup;
}

const GeneratorDailyLogPrintable: React.FC<IProps> = (props) => {

	const dailyLogsAsArrayUnfiltered: Array<GeneratorDailyLog> = getGeneratorDailyLogGroupAsArrayUnFiltered(props.form);

	return (
		<React.Fragment>
			<p className="printable__header">
				Daily Log for Generator
			</p>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Site Name:"
					value={valueOrBustPrintables(props.form?.originalSite?.name)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Site Address:"
					value={valueOrBustPrintables(props.form?.originalSite?.address, parseSiteAddressForSingleInput(props.form?.originalSite?.address))}
					width={asPercentOf100(2 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Generator Make:"
					value={valueOrBustPrintables(props.form?.equipment?.make)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Generator Model:"
					value={valueOrBustPrintables(props.form?.equipment?.model)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Generator S/N:"
					value={valueOrBustPrintables(props.form?.equipment?.serialNumber)}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<p className="printable__sub-header">
				Daily Log Checklist
			</p>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Week of:"
					value={getWeekRangeForTable(1)(generateOffsetDate(props.form?.date))}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableGeneratorDailyLogChecklistTable
				weekOf={props.form?.date}
				generatorDailyLogs={dailyLogsAsArrayUnfiltered}
			/>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Supervisor Name:"
					value={valueOrBustPrintables(getFullNameForTable(props.form?.approvedBy))}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<div style={{pageBreakAfter: "always"}}/>
			<p className="printable__header">
				Comments for Generator Daily Log
			</p>
			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Site Name:"
					value={valueOrBustPrintables(props.form?.originalSite?.name)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Site Address:"
					value={valueOrBustPrintables(props.form?.originalSite?.address, parseSiteAddressForSingleInput(props.form?.originalSite?.address))}
					width={asPercentOf100(2 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Crane Make:"
					value={valueOrBustPrintables(props.form?.equipment?.make)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Crane Model:"
					value={valueOrBustPrintables(props.form?.equipment?.model)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Crane S/N:"
					value={valueOrBustPrintables(props.form?.equipment?.serialNumber)}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Week of:"
					value={getWeekRangeForTable(1)(generateOffsetDate(props.form?.date))}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableDailyLogCommentTableGroup
				logs={dailyLogsAsArrayUnfiltered as unknown as Array<LogWithComment>}
				logGroup={props.form}
			/>
		</React.Fragment>
	);
};

export default GeneratorDailyLogPrintable;
