import moment, {Moment} from "moment";

const defaultFormat: string = "MM/DD/YYYY";

/**
 * Takes a date and adds x weeks to get a range,
 * displays like "MM/DD/YYYY to "MM/DD/YYYY"".
 *
 * @param weeksToAdd
 * @param format
 */
export function getWeekRangeForTable(weeksToAdd: number = 0, format: string = defaultFormat): (date: number) => string {
	return (date: number) => {
		if (date === undefined) {
			return "-";
		}

		const base: Moment = moment(date);

		return (
			base.format(format) +
			" to " +
			moment(date.valueOf() - 1).add(weeksToAdd, "weeks").format(format)
		);
	}
}
