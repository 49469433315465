import React from "react";

interface IProps {
	label: string;
	value: string;
	width: number;
}

const PrintableCellLabelWithValue: React.FC<IProps> = (props) => {

	return (
		<div
			className="printable__cell printable__cell-label-with-value"
			style={{
				width: `${props.width}%`
			}}
		>
			<p className="printable__cell-label-with-value__label">
				{props.label}
			</p>

			<p className="printable__cell-label-with-value__value">
				{props.value}
			</p>
		</div>
	);
};

export default PrintableCellLabelWithValue;
