import {CraneType} from "client";

/**
 * Parse the CraneType enum into a user friendly display string.
 *
 * @param _craneType
 */
export function getCraneTypeDisplay(_craneType: CraneType): string {

	switch (_craneType) {
		case CraneType.LUFFING_TOWER:
			return "Luffing Tower";
		case CraneType.SELF_ERECTING:
			return "Self Erecting";
		case CraneType.TOWER:
			return "Tower";
		default:
			return _craneType;
	}
}
