/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { AddUsersToSiteBodyToJSON, CreateSiteBodyToJSON, DisableSiteBodyToJSON, GetSiteListFiltersResponseFromJSON, GetSiteListResponseFromJSON, GetUsersForSiteResponseFromJSON, MoveEquipmentToSiteBodyToJSON, RemoveUsersFromSiteBodyToJSON, SiteFromJSON, UpdateSiteBodyToJSON, UpdateUsersForSiteToJSON, } from '../models';
/**
 *
 */
export class SitesApi extends runtime.BaseAPI {
    /**
     * Used to add users to a site
     */
    async addUsersToSiteRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sites/users`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddUsersToSiteBodyToJSON(requestParameters.addUsersToSiteBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SiteFromJSON(jsonValue));
    }
    /**
     * Used to add users to a site
     */
    async addUsersToSite(requestParameters = {}, initOverrides) {
        const response = await this.addUsersToSiteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async createSiteRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sites`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSiteBodyToJSON(requestParameters.createSiteBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SiteFromJSON(jsonValue));
    }
    /**
     */
    async createSite(requestParameters = {}, initOverrides) {
        const response = await this.createSiteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async disableSiteRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sites/disable`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DisableSiteBodyToJSON(requestParameters.disableSiteBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SiteFromJSON(jsonValue));
    }
    /**
     */
    async disableSite(requestParameters = {}, initOverrides) {
        const response = await this.disableSiteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to get a site
     */
    async getSiteRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getSite.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sites/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SiteFromJSON(jsonValue));
    }
    /**
     * Used to get a site
     */
    async getSite(requestParameters, initOverrides) {
        const response = await this.getSiteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to get sites this user is affiliated with.
     */
    async getSiteListRaw(requestParameters, initOverrides) {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getSiteList.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getSiteList.');
        }
        const queryParameters = {};
        if (requestParameters.name) {
            queryParameters['name'] = requestParameters.name;
        }
        if (requestParameters.owner) {
            queryParameters['owner'] = requestParameters.owner;
        }
        if (requestParameters.renter) {
            queryParameters['renter'] = requestParameters.renter;
        }
        if (requestParameters.make) {
            queryParameters['make'] = requestParameters.make;
        }
        if (requestParameters.model) {
            queryParameters['model'] = requestParameters.model;
        }
        if (requestParameters.serialNumber) {
            queryParameters['serialNumber'] = requestParameters.serialNumber;
        }
        if (requestParameters.equipment) {
            queryParameters['equipment'] = requestParameters.equipment;
        }
        if (requestParameters.user) {
            queryParameters['user'] = requestParameters.user;
        }
        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }
        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetSiteListResponseFromJSON(jsonValue));
    }
    /**
     * Used to get sites this user is affiliated with.
     */
    async getSiteList(requestParameters, initOverrides) {
        const response = await this.getSiteListRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to get lists of all possible names/owners/etc. That can be used to filter for sites.
     */
    async getSiteListFiltersRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sites/filters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetSiteListFiltersResponseFromJSON(jsonValue));
    }
    /**
     * Used to get lists of all possible names/owners/etc. That can be used to filter for sites.
     */
    async getSiteListFilters(initOverrides) {
        const response = await this.getSiteListFiltersRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async getUsersForSiteRaw(requestParameters, initOverrides) {
        if (requestParameters.siteID === null || requestParameters.siteID === undefined) {
            throw new runtime.RequiredError('siteID', 'Required parameter requestParameters.siteID was null or undefined when calling getUsersForSite.');
        }
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getUsersForSite.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getUsersForSite.');
        }
        const queryParameters = {};
        if (requestParameters.siteID !== undefined) {
            queryParameters['siteID'] = requestParameters.siteID;
        }
        if (requestParameters.userType) {
            queryParameters['userType'] = requestParameters.userType;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sites/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetUsersForSiteResponseFromJSON(jsonValue));
    }
    /**
     */
    async getUsersForSite(requestParameters, initOverrides) {
        const response = await this.getUsersForSiteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to move equipment to a site
     */
    async moveEquipmentToSiteRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sites/move-equipment`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MoveEquipmentToSiteBodyToJSON(requestParameters.moveEquipmentToSiteBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used to move equipment to a site
     */
    async moveEquipmentToSite(requestParameters = {}, initOverrides) {
        await this.moveEquipmentToSiteRaw(requestParameters, initOverrides);
    }
    /**
     */
    async removeUsersFromSiteRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sites/remove_users`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveUsersFromSiteBodyToJSON(requestParameters.removeUsersFromSiteBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async removeUsersFromSite(requestParameters = {}, initOverrides) {
        await this.removeUsersFromSiteRaw(requestParameters, initOverrides);
    }
    /**
     */
    async updateSiteRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sites`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSiteBodyToJSON(requestParameters.updateSiteBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SiteFromJSON(jsonValue));
    }
    /**
     */
    async updateSite(requestParameters = {}, initOverrides) {
        const response = await this.updateSiteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async updateUsersForSiteRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sites/update_users`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUsersForSiteToJSON(requestParameters.updateUsersForSite),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async updateUsersForSite(requestParameters = {}, initOverrides) {
        await this.updateUsersForSiteRaw(requestParameters, initOverrides);
    }
}
