import moment from "moment";

/**
 * parse number and display as date in MM/YYYY format.
 *
 * @param date
 */
export function displayMonthForTable(date: number): string {
	return date !== undefined ? moment(date).format("MM/YYYY") : "-";
}
