import React from "react";
import {TimeSheet, TimeSheetEntry} from "client";
import {Button} from "reactstrap";
import {useHistory} from "react-router-dom";

interface IProps {
	entry: TimeSheetEntry;
	timeSheet: TimeSheet;
}

// This is the version of the table cell used by Employees.
// For the Admin version see ManageTimeSheetsEditEntryCell.

const ManageTimeSheetsHistoryEditEntryCell: React.FC<IProps> = (props) => {

	const history = useHistory();

	/**
	 * Go to the page to edit the time sheet entry.
	 *
	 */
	function goToEdit(): void {
		history.push(`/edit-forms/edit-time-sheet?id=${props.entry?._id}`);
	}

	return (
		<div className="allied-table-cell">
			<Button
				color="black"
				onClick={goToEdit}
				disabled={props.timeSheet?.submittedDate !== undefined}
			>
				Edit Entry
			</Button>
		</div>
	);
};

export default ManageTimeSheetsHistoryEditEntryCell;
