import {DeficiencyStatus} from "client";

export function parseDeficiencyStatusForDisplay(status: DeficiencyStatus): string {
	if (!status) {
		return "-"
	}

	switch (status) {
		case DeficiencyStatus.PENDING_APPOINTMENT:
			return "Pending Appointment";
		case DeficiencyStatus.REQUIRES_PARTS:
			return "Requires Parts";
		case DeficiencyStatus.PARTS_AVAILABLE:
			return "Parts Available";
		case DeficiencyStatus.RESOLVED:
			return "Resolved";
		default:
			return status;
	}
}
