import React, {useEffect, useState} from "react";
import {
	CraneType,
	FormsApi,
	GetCraneLogListFiltersResponse,
	GetCraneMonthlyLogListRequest,
	Token,
	UserType
} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {Button, Col, Row} from "reactstrap";
import AlliedInputRadio from "../inputs/AlliedInputRadio";
import {parseStringArrayForAutoComplete} from "../../utils/parseStringArrayForAutoComplete";
import AlliedAutoComplete from "../inputs/AlliedAutoComplete";
import {parseSitesForAutoComplete} from "../../utils/parseSitesForAutoComplete";
import {userTypeCheck} from "../../utils/userTypeCheck";
import getConfig from "../../utils/getConfig";
import {convertMonthListToAutoCompleteValues, getMonthsForMonthlyLogForm} from "../../utils/getMonthsForMonthlyLogForm";
import {parseCraneTypeForAutoComplete} from "../../utils/parseCraneTypeForAutoComplete";
import {parseUsersForAutoComplete} from "../../utils/parseUsersForAutoComplete";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	filter: GetCraneMonthlyLogListRequest;
	onClose: () => void;
	onDone: (newFilter: GetCraneMonthlyLogListRequest) => void;
}

const CraneDailyLogsFilterModal: React.FC<IProps> = (props) => {

	const [filter, setFilter] = useState<GetCraneMonthlyLogListRequest>(props.filter);
	const [apiFilterOptions, setApiFilterOptions] = useState<GetCraneLogListFiltersResponse>(undefined);

	/**
	 * When modal opens, update the local filter to match that of props,
	 * and call api to get values for the auto-complete inputs.
	 *
	 */
	useEffect(() => {
		if (props.isOpen) {
			setFilter(props.filter);
			getAutoCompleteValues().then().catch();
		}
	}, [props.isOpen]);

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function resetAndClose(): void {
		setFilter(props.filter);
		props.onClose();
	}

	/**
	 * Call api to get values for the auto-complete drop-downs.
	 *
	 */
	async function getAutoCompleteValues(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FormsApi(getConfig(props.fullToken)).getCraneLogListFilters();
			setApiFilterOptions(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Dynamic onChange helper to update the string arrays in the filter object.
	 *
	 * @param key
	 */
	function filterArrayOnChange(key: keyof GetCraneMonthlyLogListRequest): (value: string[]) => void {
		return (value: string[]) => {
			setFilter({
				...filter,
				[key]: value,
			});
		}
	}

	/**
	 * onChange to help with the single select auto-complete
	 * for choosing a crane type.
	 *
	 * @param craneTypes
	 */
	function craneTypeOnChange(craneTypes: string[]): void {
		setFilter({
			...filter,
			craneType: filter.craneType !== craneTypes[0] ? craneTypes[0] as CraneType : undefined,
		});
	}

	/**
	 * When done configuring filter, call onDone function from props.
	 *
	 */
	function onSave(): void {
		props.onDone(filter);
	}

	return (
		<AlliedModal
			size="lg"
			isOpen={props.isOpen}
		>
			<AlliedModalHeader
				title="Filter Crane Monthly Logs"
				onClose={resetAndClose}
			/>

			<hr/>

			<Row>
				<Col xs={12} lg={6} className="mb-3">
					<label>Specify Month</label>
					<AlliedAutoComplete
						placeholder="Select Month..."
						options={convertMonthListToAutoCompleteValues(getMonthsForMonthlyLogForm())}
						selections={filter?.month?.map((m) => m?.toString())}
						setSelections={filterArrayOnChange("month")}
					/>
				</Col>
			</Row>

			<Row>
				<Col xs={12} lg={6} className="mb-3">
					<label>Specify Make</label>
					<AlliedAutoComplete
						placeholder="Select Crane Make(s)..."
						options={parseStringArrayForAutoComplete(apiFilterOptions?.makes)}
						selections={filter?.make}
						setSelections={filterArrayOnChange("make")}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Specify Model</label>
					<AlliedAutoComplete
						placeholder="Select Crane Model(s)..."
						options={parseStringArrayForAutoComplete(apiFilterOptions?.models)}
						selections={filter?.model}
						setSelections={filterArrayOnChange("model")}
					/>
				</Col>
			</Row>

			<Row>
				<Col xs={12} lg={6} className="mb-3">
					<label>Specify S/N</label>
					<AlliedAutoComplete
						placeholder="Select Crane S/N(s)..."
						options={parseStringArrayForAutoComplete(apiFilterOptions?.serialNumbers)}
						selections={filter?.serialNumber}
						setSelections={filterArrayOnChange("serialNumber")}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Crane Type</label>
					<AlliedAutoComplete
						placeholder="Select Crane Type..."
						options={parseCraneTypeForAutoComplete()}
						selections={filter?.craneType ? [filter?.craneType] : []}
						setSelections={craneTypeOnChange}
						multiSelect={false}
					/>
				</Col>
			</Row>

			<Row>
				<Col xs={12} lg={6} className="mb-3">
					<label>Specify Sites</label>
					<AlliedAutoComplete
						placeholder="Select Site(s)..."
						options={parseSitesForAutoComplete(apiFilterOptions?.sites)}
						selections={filter?.site}
						setSelections={filterArrayOnChange("site")}
					/>
				</Col>

				{userTypeCheck([UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR, UserType.HEALTH_AND_SAFETY_OFFICER], props.fullToken) && (
					<Col xs={12} lg={6} className="mb-3">
						<label>Specify Operators</label>
						<AlliedAutoComplete
							placeholder="Select Operator(s)..."
							options={parseUsersForAutoComplete(apiFilterOptions?.users)}
							selections={filter?.user}
							setSelections={filterArrayOnChange("user")}
						/>
					</Col>
				)}
			</Row>

			<div>
				<Button
					color="black"
					onClick={onSave}
				>
					Apply Filters
				</Button>
			</div>
		</AlliedModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(CraneDailyLogsFilterModal);
