import React, {useEffect, useState} from "react";
import {FormsApi, ServiceReport, TimeSheet, Token} from "client";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button, Col, Row} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	serviceReport: ServiceReport;
	onClose: () => void;
	onDone: () => void;
}

const ManageServiceReportsCommentModal: React.FC<IProps> = (props) => {

	const [comment, setComment] = useState(props.serviceReport?.notes);

	useEffect(() => {
		setComment(props.serviceReport?.notes);
	}, [props.isOpen]);

	/**
	 * Reset the saved value and call the close function.
	 *
	 */
	function resetAndClose(): void {
		setComment(props.serviceReport?.notes);
		props.onClose();
	}

	/**
	 * onChange handler for the comment textarea input.
	 *
	 * @param e
	 */
	function commentOnChange(e: React.ChangeEvent<HTMLTextAreaElement>): void {
		setComment(e?.target?.value);
	}

	/**
	 * Call api to update comment on the Service Report in question,
	 * call the props function to update the table.
	 *
	 */
	async function submitComment(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new FormsApi(getConfig(props.fullToken)).addNotesToServiceReport({
				addNotesToServiceReportBody: {
					serviceReportID: props.serviceReport?._id,
					notes: comment,
				},
			});

			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<AlliedModal
			isOpen={props.isOpen}
			size={"lg"}
		>
			<AlliedModalHeader
				title="Add/Edit Comment"
				onClose={resetAndClose}
			/>

			<hr/>

			<Row>
				<Col xs={12} className="mb-3">
					<label>Admin Comment</label>
					<textarea
						placeholder="Comment for Service Report..."
						value={comment}
						onChange={commentOnChange}
					/>
				</Col>
			</Row>

			<div>
				<Button
					color="black"
					onClick={submitComment}
				>
					Save Changes
				</Button>
			</div>
		</AlliedModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageServiceReportsCommentModal);
