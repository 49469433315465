import React, {ReactNode} from "react";
import {getFullNameForTable} from "../../../utils/getFullNameForTable";
import {valueOrBustPrintables} from "../../../utils/valueOrBust";
import moment from "moment";
import {generateOffsetDate} from "../../../utils/timeZoneConversions";
import {ServiceReportEntry} from "client";
import {convertMinutesToHoursAndMinutes} from "../../../utils/convertMinutesToHoursAndMinutes";
import {humanReadableDuration} from "../../../utils/convertMillisecondsToHours";

interface IProps {
	lunchBreakers?: Array<ServiceReportEntry>;
}

const PrintableLunchBreakTable: React.FC<IProps> = (props) => {

	// function makeLunchBreakEntry(lunchBreak: LunchBreaker, i: number): ReactNode {
	function makeLunchBreakEntry(lunchBreak: ServiceReportEntry, i: number): ReactNode {
		return (
			<tr key={`work-performed_${i}_${lunchBreak?.alliedCraneEmployee?.firstName + "-" + lunchBreak?.alliedCraneEmployee?.lastName}_${Math.random()}`}>
				<td>
					{getFullNameForTable(lunchBreak.alliedCraneEmployee)}
				</td>

				<td className="printable__table__centered">
					{valueOrBustPrintables(lunchBreak.calledOut, moment(generateOffsetDate(lunchBreak.calledOut)).format("h:mm A"))}
				</td>


				<td className="printable__table__centered">
					{valueOrBustPrintables(lunchBreak.arrivalToSite, moment(generateOffsetDate(lunchBreak.arrivalToSite)).format("h:mm A"))}
				</td>

				<td className="printable__table__centered">
					{valueOrBustPrintables(lunchBreak.leftJobSite, moment(generateOffsetDate(lunchBreak.leftJobSite)).format("h:mm A"))}
				</td>

				<td className="printable__table__centered">
					{valueOrBustPrintables(lunchBreak.returnTime, moment(generateOffsetDate(lunchBreak.returnTime)).format("h:mm A"))}
				</td>

				<td className="printable__table__centered">
					{lunchBreak.lunchBreakEndTime != null && lunchBreak.lunchBreakStartTime != null ? (
						(lunchBreak.lunchBreakEndTime - lunchBreak.lunchBreakStartTime) > lunchBreak.onSiteTime ? (
							0
						) : (
							humanReadableDuration(lunchBreak.onSiteTime - (lunchBreak.lunchBreakEndTime - lunchBreak.lunchBreakStartTime))
						)
					) : (
						humanReadableDuration(lunchBreak.onSiteTime)
					)}
				</td>

				<td className="printable__table__centered">
					{valueOrBustPrintables(lunchBreak.standByTime, lunchBreak.standByTime + " hour(s)")}
				</td>

				<td>
					{valueOrBustPrintables(lunchBreak.standByReason)}
				</td>

				<td className="printable__table__centered">
					{valueOrBustPrintables(lunchBreak.sourcePartsTime, lunchBreak.sourcePartsTime + " hour(s)")}
				</td>

				<td>
					{valueOrBustPrintables(lunchBreak.sourcePartsReason)}
				</td>

				<td className="printable__table__centered">
					{valueOrBustPrintables(lunchBreak.lunchBreakStartTime, moment(generateOffsetDate(lunchBreak.lunchBreakStartTime)).format("h:mm A"))}
				</td>

				<td className="printable__table__centered">
					{valueOrBustPrintables(lunchBreak.lunchBreakEndTime, moment(generateOffsetDate(lunchBreak.lunchBreakEndTime)).format("h:mm A"))}
				</td>
			</tr>
		);
	}

	return (
		<div>
			<table className="printable__table">
				<tr>
					<td className="printable__table__label printable__table__centered">
						Employee Name
					</td>

					<td className="printable__table__label printable__table__centered">
						Called Out
					</td>

					<td className="printable__table__label printable__table__centered">
						Arrival
					</td>

					<td className="printable__table__label printable__table__centered">
						Left Site
					</td>

					<td className="printable__table__label printable__table__centered">
						Returned
					</td>

					<td className="printable__table__label printable__table__centered">
						On Site Time
					</td>

					<td className="printable__table__label printable__table__centered">
						Stand By Time
					</td>

					<td className="printable__table__label printable__table__centered">
						Stand By Reason
					</td>

					<td className="printable__table__label printable__table__centered">
						Source Parts Time
					</td>

					<td className="printable__table__label printable__table__centered">
						Source Parts Reason
					</td>

					<td className="printable__table__label printable__table__centered">
						Lunch Start Time
					</td>

					<td className="printable__table__label printable__table__centered">
						Lunch End Time
					</td>
				</tr>

				{props?.lunchBreakers?.map(makeLunchBreakEntry)}
			</table>
		</div>
	);
};

export default PrintableLunchBreakTable;
