/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { AddressFromJSON, AddressToJSON, } from './Address';
import { EquipmentFromJSON, EquipmentToJSON, } from './Equipment';
import { UsersByTypeFromJSON, UsersByTypeToJSON, } from './UsersByType';
/**
 * Check if a given object implements the SiteWithUsersAndEquipment interface.
 */
export function instanceOfSiteWithUsersAndEquipment(value) {
    let isInstance = true;
    isInstance = isInstance && "_id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "disabled" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "contactNumber" in value;
    isInstance = isInstance && "contactEmail" in value;
    isInstance = isInstance && "renter" in value;
    isInstance = isInstance && "siteOwner" in value;
    isInstance = isInstance && "users" in value;
    return isInstance;
}
export function SiteWithUsersAndEquipmentFromJSON(json) {
    return SiteWithUsersAndEquipmentFromJSONTyped(json, false);
}
export function SiteWithUsersAndEquipmentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'disabled': json['disabled'],
        'name': json['name'],
        'address': AddressFromJSON(json['address']),
        'contactNumber': json['contactNumber'],
        'contactEmail': json['contactEmail'],
        'renter': json['renter'],
        'siteOwner': json['siteOwner'],
        'users': UsersByTypeFromJSON(json['users']),
        'equipment': !exists(json, 'equipment') ? undefined : (json['equipment'].map(EquipmentFromJSON)),
    };
}
export function SiteWithUsersAndEquipmentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'disabled': value.disabled,
        'name': value.name,
        'address': AddressToJSON(value.address),
        'contactNumber': value.contactNumber,
        'contactEmail': value.contactEmail,
        'renter': value.renter,
        'siteOwner': value.siteOwner,
        'users': UsersByTypeToJSON(value.users),
        'equipment': value.equipment === undefined ? undefined : (value.equipment.map(EquipmentToJSON)),
    };
}
