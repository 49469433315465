import React, {ChangeEventHandler, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	CreateServiceReportBody, CreateServiceReportEntryBody, CreateServiceReportResponse,
	Equipment,
	EquipmentApi,
	FormsApi,
	FormType, PartsAndSupplies, ServiceReport, Site, SitesApi,
	Token,
	User,
	UsersApi,
	UserType
} from "client";
import {useHistory} from "react-router-dom";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import moment from "moment";
import {clone, cloneDeep, find} from "lodash";
import AlliedContainer from "../AlliedContainer";
import AlliedPageHeader from "../AlliedPageHeader";
import AlliedFormContainer from "../AlliedFormContainer";
import {Button, Col, Row} from "reactstrap";
import AlliedAutoComplete from "../inputs/AlliedAutoComplete";
import {parseEquipmentForSelectDropdown} from "../../utils/parseEquipmentForSelectDropdown";
import AlliedDatePicker from "../inputs/AlliedDatePicker";
import NumberFormat from "react-number-format";
import AlliedGenericInputList from "../inputs/AlliedGenericInputList";
import WorkPerformedInput from "../inputs/WorkPerformedInput";
import AlliedRadioRow from "../inputs/AlliedRadioRow";
import {AlliedInputRadioFactory} from "../inputs/AlliedInputRadio";
import PartsAndSuppliesInput from "../inputs/PartsAndSuppliesInput";
import {roundMomentObjectMinutes} from "../../utils/roundMomentObjectMinutes";
import {convertServiceReportResponseToCreateBody} from "../../utils/convertServiceReportResponseToCreateBody";
import {userTypeCheck} from "../../utils/userTypeCheck";
import {parseSitesForAutoComplete} from "../../utils/parseSitesForAutoComplete";
import {convertOffsetDate} from "../../utils/timeZoneConversions";
import AlliedServiceReportEntryInput, {IAlliedServiceReportEntryInputProps} from "../inputs/AlliedServiceReportEntryInput";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	id?: string;
}

export const defaultServiceReportEntryBody: CreateServiceReportEntryBody = {
	alliedCraneEmployeeID: undefined,
	// calledOut: roundMomentObjectMinutes(moment(), 5).valueOf(),
	// arrivalToSite: roundMomentObjectMinutes(moment(), 5).valueOf(),
	// leftJobSite: roundMomentObjectMinutes(moment(), 5).valueOf(),
	// returnTime: roundMomentObjectMinutes(moment(), 5).valueOf(),
	calledOut: moment().set({hour: 6}).startOf("hour").valueOf(),
	arrivalToSite: moment().set({hour: 7}).startOf("hour").valueOf(),
	leftJobSite: moment().set({hour: 15}).startOf("hour").valueOf(),
	returnTime: moment().set({hour: 16}).startOf("hour").valueOf(),
	standByTime: undefined,
	standByReason: "",
	sourcePartsTime: undefined,
	sourcePartsReason: "",
	lunchBreak: false,
	// lunchBreakStartTime: roundMomentObjectMinutes(moment(), 5).valueOf(),
	// lunchBreakEndTime: roundMomentObjectMinutes(moment(), 5).valueOf(),
	lunchBreakStartTime: moment().set({hour: 12}).startOf("hour").valueOf(),
	lunchBreakEndTime: moment().set({hour: 12, minute: 30}).startOf("minute").valueOf(),
}

export const defaultCreateServiceReportBody: CreateServiceReportBody = {
	equipmentID: "",
	siteID: "",
	date: moment().startOf("day").valueOf(),
	customer: "",
	serviceRequestedBy: "",
	usageHours: undefined,
	reportedIssue: "",
	workPerformed: [""],
	createServiceReportEntryBodies: [defaultServiceReportEntryBody],
	partsAndSuppliesArray: [{
		quantity: undefined,
		partNumber: "",
		description: "",
	}],
	additionalServiceRequired: false,
	repEmail: "",
	authorizedRepresentative: "",
}

const CreateServiceReportForm: React.FC<IProps> = (props) => {

	const history = useHistory();
	const [createServiceReportForm, setCreateServiceReportForm] = useState<CreateServiceReportBody>(defaultCreateServiceReportBody);
	const [equipmentList, setEquipmentList] = useState<Array<Equipment>>([]);
	const [siteList, setSitesList] = useState<Array<Site>>([]);
	const [employeeList, setEmployeeList] = useState<Array<User>>([]);
	const [rawEditingResponse, setRawEditingResponse] = useState<ServiceReport>(undefined);
	const [equipmentKey, setEquipmentKey] = useState(0);

	useEffect(() => {
		getAutoCompleteValue().then().catch();

		if (props.id) {
			getFormForEditing().then().catch();
		}
	}, []);

	useEffect(() => {
		setEquipmentKey(key => ++key);
	}, [createServiceReportForm]);

	/**
	 * Call api to get a list of equipment, sites, & employees for the drop-downs.
	 *
	 */
	async function getAutoCompleteValue(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const equipmentRes = await new EquipmentApi(getConfig(props.fullToken)).getEquipmentList({
				limit: 10000,
				offset: 0,
			});

			const sitesRes = await new SitesApi(getConfig(props.fullToken)).getSiteList({
				limit: 10000,
				offset: 0,
			});

			const employeesRes = await new UsersApi(getConfig(props.fullToken)).getUserList({
				type: [UserType.ALLIED_CRANE_EMPLOYEE],
				limit: 10000,
				offset: 0,
			});

			setEquipmentList(equipmentRes.equipment);
			setSitesList(sitesRes.sites);
			setEmployeeList(employeesRes.users);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Call api to get existing Service Report if props.id exists,
	 * and save it to the form object after formatting correctly.
	 *
	 */
	async function getFormForEditing(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FormsApi(getConfig(props.fullToken)).getServiceReport({
				serviceReportID: props.id,
			});

			setCreateServiceReportForm(convertServiceReportResponseToCreateBody(res));
			setRawEditingResponse(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Handle the auto-complete onChange for the selected equipment,
	 * which returns an array, but we only want the first element.
	 *
	 * Also set the siteID to that of the selected equipment's,
	 * if siteID isn't already set on the form.
	 *
	 * @param equipment
	 */
	function equipmentOnChange(equipment: string[]): void {
		const newForm: CreateServiceReportBody = cloneDeep(createServiceReportForm);

		if (equipment[0]) {
			newForm.siteID = find(equipmentList, ["_id", equipment[0]])?.site?._id;
		}

		setCreateServiceReportForm({
			...newForm,
			equipmentID: equipment[0] ?? "",
		});
	}

	/**
	 * Handle the auto-complete onChange for the selected site,
	 * which returns an array, but we only want the first element.
	 * If a site is found (ie. we're not de-selecting), set the email & rep to those of relevant fields on the site.
	 *
	 * @param site
	 */
	function siteOnChange(site: string[]): void {
		const foundSite: Site = siteList.find(s => s._id === site[0]);

		setCreateServiceReportForm({
			...createServiceReportForm,
			siteID: createServiceReportForm.siteID !== site[0] ? site[0] : "",
			repEmail: foundSite?.contactEmail || createServiceReportForm.repEmail,
			authorizedRepresentative: foundSite?.siteOwner || createServiceReportForm.authorizedRepresentative,
			equipmentID: "",
		});
	}

	/**
	 * onChange for the date input.
	 *
	 * @param key
	 */
	function onDateChange(key: keyof CreateServiceReportBody): (d: Date) => void {
		return (d: Date) => {
			setCreateServiceReportForm({
				...createServiceReportForm,
				[key]: moment(d).valueOf(),
			});
		}
	}

	/**
	 * Dynamic onChange for the form text fields.
	 *
	 * @param key
	 */
	function createServiceReportOnChange(key: keyof CreateServiceReportBody): ChangeEventHandler<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> {
		return (e) => {
			setCreateServiceReportForm({
				...createServiceReportForm,
				[key]: e?.target?.value,
			});
		}
	}

	/**
	 * Dynamic onChange for number inputs using the NumberFormat library.
	 *
	 * @param key
	 */
	function numberFormatOnChange(key: keyof CreateServiceReportBody): (e) => void {
		return (e) => {
			setCreateServiceReportForm({
				...createServiceReportForm,
				[key]: e?.floatValue,
			});
		}
	}

	/**
	 * onChange function for all of the dynamic lists with plus & minus icons.
	 *
	 * @param key
	 */
	function inputListOnChange<T>(key: keyof CreateServiceReportBody): (i: number, value: T) => void {
		return (i: number, value: T) => {
			const copy: Array<T> = cloneDeep(createServiceReportForm[key] as unknown as Array<T>);
			copy[i] = value;

			setCreateServiceReportForm({
				...createServiceReportForm,
				[key]: copy,
			});
		}
	}

	/**
	 * Add another entry to the array in question.
	 *
	 */
	function inputListOnAdd<T>(key: keyof CreateServiceReportBody): () => void {
		return () => {
			if (key === "createServiceReportEntryBodies") {
				const previousTechnician: CreateServiceReportEntryBody = clone(createServiceReportForm.createServiceReportEntryBodies[createServiceReportForm.createServiceReportEntryBodies.length - 1]);

				setCreateServiceReportForm({
					...createServiceReportForm,
					createServiceReportEntryBodies: createServiceReportForm.createServiceReportEntryBodies.concat([{
						...defaultCreateServiceReportBody.createServiceReportEntryBodies[0],
						calledOut: previousTechnician.calledOut,
						arrivalToSite: previousTechnician.arrivalToSite,
						leftJobSite: previousTechnician.leftJobSite,
						returnTime: previousTechnician.returnTime,
						lunchBreak: previousTechnician.lunchBreak,
						lunchBreakStartTime: previousTechnician.lunchBreakStartTime,
						lunchBreakEndTime: previousTechnician.lunchBreakEndTime,
					}]),
				});
			} else {
				setCreateServiceReportForm({
					...createServiceReportForm,
					[key]: (createServiceReportForm[key] as unknown as Array<T>).concat([defaultCreateServiceReportBody[key][0]]),
				});
			}
		}
	}

	/**
	 * Remove item at index i from the array in question.
	 *
	 */
	function inputListOnRemove<T>(key: keyof CreateServiceReportBody): (i: number) => void {
		return (i: number) => {
			const copy: Array<T> = cloneDeep(createServiceReportForm[key] as unknown as Array<T>);
			copy.splice(i, 1);

			setCreateServiceReportForm({
				...createServiceReportForm,
				[key]: copy,
			});
		}
	}

	/**
	 * onChange function for the "Additional Service Required?" radios.
	 *
	 * @param required
	 */
	function additionalServiceRequiredOnChange(required: boolean): () => void {
		return () => {
			setCreateServiceReportForm({
				...createServiceReportForm,
				additionalServiceRequired: required,
			});
		}
	}

	/**
	 * Call api to either submit new Service Report or update existing and return id.
	 *
	 */
	async function saveServiceReport(e?: React.SyntheticEvent): Promise<string> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		let createResponse: CreateServiceReportResponse;

		try {
			let req: CreateServiceReportBody = {
				equipmentID: createServiceReportForm.equipmentID ? createServiceReportForm.equipmentID : undefined,
				siteID: createServiceReportForm.siteID ? createServiceReportForm.siteID : undefined,
				date: createServiceReportForm.date !== undefined ? convertOffsetDate(createServiceReportForm.date) : undefined,
				customer: createServiceReportForm.customer ? createServiceReportForm.customer : undefined,
				serviceRequestedBy: createServiceReportForm.serviceRequestedBy ? createServiceReportForm.serviceRequestedBy : undefined,
				usageHours: createServiceReportForm.usageHours !== undefined ? createServiceReportForm.usageHours : undefined,
				reportedIssue: createServiceReportForm.reportedIssue ? createServiceReportForm.reportedIssue : undefined,
				workPerformed: createServiceReportForm.workPerformed?.filter((work: string): boolean => {
					return work?.length > 0;
				}),
				createServiceReportEntryBodies: createServiceReportForm.createServiceReportEntryBodies?.map((entry): CreateServiceReportEntryBody => {
					return {
						alliedCraneEmployeeID: entry?.alliedCraneEmployeeID ? entry?.alliedCraneEmployeeID : undefined,
						calledOut: entry?.calledOut !== undefined ? convertOffsetDate(entry?.calledOut) : undefined,
						arrivalToSite: entry?.arrivalToSite !== undefined ? convertOffsetDate(entry?.arrivalToSite) : undefined,
						leftJobSite: entry?.leftJobSite !== undefined ? convertOffsetDate(entry?.leftJobSite) : undefined,
						returnTime: entry?.returnTime !== undefined ? convertOffsetDate(entry?.returnTime) : undefined,
						standByTime: entry?.standByTime !== undefined ? entry?.standByTime : undefined,
						standByReason: entry?.standByReason || undefined,
						sourcePartsTime: entry?.sourcePartsTime !== undefined ? entry?.sourcePartsTime : undefined,
						sourcePartsReason: entry?.sourcePartsReason || undefined,
						lunchBreak: entry?.lunchBreak,
						lunchBreakStartTime: entry?.lunchBreakStartTime !== undefined ? convertOffsetDate(entry?.lunchBreakStartTime) : undefined,
						lunchBreakEndTime: entry?.lunchBreakEndTime !== undefined ? convertOffsetDate(entry?.lunchBreakEndTime) : undefined,
					}
				}),
				partsAndSuppliesArray: createServiceReportForm.partsAndSuppliesArray?.filter((entry: PartsAndSupplies): boolean => {
					return entry.quantity?.toString()?.length > 0 || entry?.partNumber?.length > 0 || entry?.description?.length > 0;
				}),
				additionalServiceRequired: createServiceReportForm.additionalServiceRequired,
				repEmail: createServiceReportForm.repEmail ? createServiceReportForm.repEmail : undefined,
				authorizedRepresentative: createServiceReportForm.authorizedRepresentative ? createServiceReportForm.authorizedRepresentative : undefined,
			};

			//
			// console.log("req:", req);
			// props.dispatch(decrementLoading());
			// return;
			//

			if (props.id) {
				await new FormsApi(getConfig(props.fullToken)).updateServiceReport({
					updateServiceReportBody: {
						...req,
						formID: props.id,
					},
				});
			} else {
				createResponse = await new FormsApi(getConfig(props.fullToken)).createServiceReport({
					createServiceReportBody: req,
				});
			}

		} catch (err) {
			props.dispatch(addError(err));
			props.dispatch(decrementLoading());
			throw err;
		}

		props.dispatch(decrementLoading());

		// Return the newly created id if we're not editing.
		if (!props.id && createResponse) {
			return createResponse?.serviceReportID;
		}
	}

	/**
	 * Used when creating or updating to save the draft.
	 * Afterwards, navigate to the appropriate page based on usertype.
	 *
	 * @param e
	 */
	async function saveDraft(e?: React.SyntheticEvent): Promise<void> {
		e?.preventDefault();
		try {
			await saveServiceReport();

			if (props.fullToken?.type === UserType.ALLIED_CRANE_EMPLOYEE) {
				history.push("/form-history/service-reports-history");
			} else if (props.fullToken?.type === UserType.ADMIN) {
				history.push("/allied-crane-employee-forms/service-reports");
			}
		} catch (e) {
		}
	}

	/**
	 * Called via the save & submit button.
	 * Create or Update the service report,
	 * and then try to submit with either the new id or the existing one.
	 *
	 * On failure to submit, if this was a new Service Report as opposed to an edit,
	 * navigate to the edit version of the page.
	 *
	 * @param e
	 */
	async function saveAndSubmitServiceReport(e?: React.SyntheticEvent): Promise<void> {
		e?.preventDefault();
		const newId: string = await saveServiceReport();
		props.dispatch(incrementLoading());

		try {
			await new FormsApi(getConfig(props.fullToken)).submitForm({
				iDBody: {
					id: props.id ? props.id : newId,
				},
			});

			if (props.fullToken?.type === UserType.ALLIED_CRANE_EMPLOYEE) {
				history.push("/form-history/service-reports-history");
			} else if (props.fullToken?.type === UserType.ADMIN) {
				history.push("/allied-crane-employee-forms/service-reports");
			}
		} catch (e) {
			props.dispatch(addError(e));

			if (!props.id) {
				props.dispatch(decrementLoading());
				history.push(`/edit-forms/edit-service-report?id=${newId}`);
			}
		}

		props.dispatch(decrementLoading());
	}

	return (
		<AlliedContainer>
			<AlliedPageHeader
				title={`${props.id ? "Edit" : "Create"} Service Report`}
				showTooltip={false}
			/>

			<hr/>

			<AlliedFormContainer>
				<form onSubmit={saveDraft}>
					<Row>
						<Col xs={12} md={6} className="mb-3">
							<label>Select Equipment for Report</label>
							<AlliedAutoComplete
								key={equipmentKey}
								placeholder="Select Equipment..."
								options={parseEquipmentForSelectDropdown(equipmentList)}
								selections={createServiceReportForm.equipmentID ? [createServiceReportForm.equipmentID] : []}
								setSelections={equipmentOnChange}
								multiSelect={false}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={6} className="mb-3">
							<label>Select Site of Report</label>
							<AlliedAutoComplete
								placeholder="Select Site..."
								options={parseSitesForAutoComplete(siteList)}
								selections={createServiceReportForm.siteID ? [createServiceReportForm.siteID] : []}
								setSelections={siteOnChange}
								multiSelect={false}
							/>
						</Col>
					</Row>

					<hr/>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Date</label>
							<AlliedDatePicker
								selected={createServiceReportForm.date}
								onChange={onDateChange("date")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Customer</label>
							<input
								placeholder="Enter Customer..."
								value={createServiceReportForm.customer}
								onChange={createServiceReportOnChange("customer")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Service Requested By</label>
							<input
								placeholder="Enter Information..."
								value={createServiceReportForm.serviceRequestedBy}
								onChange={createServiceReportOnChange("serviceRequestedBy")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Usage Hours</label>
							<NumberFormat
								allowLeadingZeros={false}
								placeholder="Enter Usage Hours..."
								value={createServiceReportForm.usageHours}
								onValueChange={numberFormatOnChange("usageHours")}
								allowNegative={false}
								decimalScale={2}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={6} className="mb-3">
							<label>Reported Issue</label>
							<input
								placeholder="Enter Issue Details..."
								value={createServiceReportForm.reportedIssue}
								onChange={createServiceReportOnChange("reportedIssue")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<h5 className="font-weight-bold">Work Performed</h5>
						</Col>
						<Col xs={12} md={6} className="mb-3">
							<AlliedGenericInputList
								values={createServiceReportForm.workPerformed}
								onChange={inputListOnChange("workPerformed")}
								onAdd={inputListOnAdd("workPerformed")}
								onRemove={inputListOnRemove("workPerformed")}
								component={WorkPerformedInput}
							/>
						</Col>
					</Row>

					<hr/>

					<Row>
						<Col xs={12}>
							<h5 className="font-weight-bold">Technician Service hours</h5>
						</Col>
						<Col xs={12} sm={12} md={8}>
							<AlliedGenericInputList
								<CreateServiceReportEntryBody, IAlliedServiceReportEntryInputProps>
								component={AlliedServiceReportEntryInput}
								values={createServiceReportForm.createServiceReportEntryBodies}
								onChange={inputListOnChange<CreateServiceReportEntryBody>("createServiceReportEntryBodies")}
								onAdd={inputListOnAdd("createServiceReportEntryBodies")}
								onRemove={inputListOnRemove("createServiceReportEntryBodies")}
								plusIconAlignment="align-items-start"
								nestRemoveIconAsChild={true}
								plusIconClassName="padding-top-2_3rem"
								removeButtonText="remove above technician"
								forwardedProps={{
									employees: employeeList,
								}}
							/>
						</Col>
					</Row>

					<hr/>

					<Row>
						<Col xs={12} md={6} className="mb-3">
							<h5 className="font-weight-bold">Parts and Supplies</h5>
							<AlliedGenericInputList
								values={createServiceReportForm.partsAndSuppliesArray}
								onChange={inputListOnChange("partsAndSuppliesArray")}
								onAdd={inputListOnAdd("partsAndSuppliesArray")}
								onRemove={inputListOnRemove("partsAndSuppliesArray")}
								component={PartsAndSuppliesInput}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} className="mb-3">
							<label>Additional Service Required?</label>
							<AlliedRadioRow>
								<AlliedInputRadioFactory
									radios={[
										{
											label: "Yes",
											name: "Yes Additional Service Required",
											checked: createServiceReportForm.additionalServiceRequired === true,
											onChange: additionalServiceRequiredOnChange(true),
										},
										{
											label: "No",
											name: "No Additional Service Required",
											checked: createServiceReportForm.additionalServiceRequired === false,
											onChange: additionalServiceRequiredOnChange(false),
										}
									]}
								/>
							</AlliedRadioRow>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Representative Email</label>
							<input
								placeholder="Enter Representative Email..."
								value={createServiceReportForm.repEmail}
								onChange={createServiceReportOnChange("repEmail")}
								type="email"
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Authorized Representative</label>
							<input
								placeholder="Enter Authorized Representative..."
								value={createServiceReportForm.authorizedRepresentative}
								onChange={createServiceReportOnChange("authorizedRepresentative")}
							/>
						</Col>
					</Row>

					<div className="d-flex flex-column align-items-center flex-sm-row">
						<Button
							color="black"
							type="submit"
							onClick={saveDraft}
							className="mr-0 mr-sm-3 mb-3 mb-sm-0"
						>
							Save Draft
						</Button>

						{userTypeCheck([UserType.ALLIED_CRANE_EMPLOYEE], props.fullToken) && (
							!props.id ||
							rawEditingResponse?.submittedDate === undefined
						) && (
							<Button
								color="red"
								onClick={saveAndSubmitServiceReport}
							>
								Save & Submit for Approval
							</Button>
						)}

						{userTypeCheck([UserType.ADMIN], props.fullToken) && (
							!props.id ||
							rawEditingResponse?.submittedDate === undefined
						) && (
							<Button
								color="red"
								onClick={saveAndSubmitServiceReport}
							>
								Save & Submit
							</Button>
						)}
					</div>
				</form>
			</AlliedFormContainer>
		</AlliedContainer>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(CreateServiceReportForm);
