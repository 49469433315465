import React, {useState} from "react";
import {FormsApi, GeneratorDailyLogGroup, Token} from "client";
import {Button} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/MetaActions";
import getConfig from "../../../utils/getConfig";
import {connect} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import ConfirmUnlockLogsModal from "../../modals/ConfirmUnlockLogsModal";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	logGroup: GeneratorDailyLogGroup;
	onDone: () => Promise<void>;
}

const GeneratorDailyLogsAdminUnlockCell: React.FC<IProps> = (props) => {

	const [showUnlockModal, setShowUnlockModal] = useState(false);

	function toggleUnlockModal(): void {
		setShowUnlockModal(!showUnlockModal);
	}

	async function confirmUnlockLogs(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new FormsApi(getConfig(props.fullToken)).unSubmitForm({
				iDBody: {
					// Pass the id of any of the entries to submit the whole group.
					id: props.logGroup?._id,
				}
			});

			setShowUnlockModal(false);
			props.onDone().then().catch();
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<React.Fragment>
			<ConfirmUnlockLogsModal
				isOpen={showUnlockModal}
				onClose={toggleUnlockModal}
				onConfirm={confirmUnlockLogs}
			/>

			<div className="d-flex justify-content-center">
				<Button
					color="black"
					onClick={toggleUnlockModal}
					disabled={props.logGroup.publishedDate === undefined}
				>
					Unlock Logs
				</Button>
			</div>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		fullToken: store.metaStore.fullToken,
	}
})(GeneratorDailyLogsAdminUnlockCell);
