import {MetaAction} from "./MetaReducer";
import {IAction} from "../IAction";
import {APIError, Token, User} from "client";

export function login(fullToken: Token): IAction<MetaAction.LOGIN, Token> {
	return {
		type: MetaAction.LOGIN,
		payload: fullToken,
	}
}

export function logout(): IAction<MetaAction.LOGOUT> {
	return {
		type: MetaAction.LOGOUT,
	}
}

export function updateCurrentUser(user: User): IAction<MetaAction.UPDATE_CURRENT_USER, User> {
	return {
		type: MetaAction.UPDATE_CURRENT_USER,
		payload: user,
	}
}

export function incrementLoading(): IAction<MetaAction.LOADING, number> {
	return {
		type: MetaAction.LOADING,
		payload: 1,
	}
}

export function decrementLoading(): IAction<MetaAction.LOADING, number> {
	return {
		type: MetaAction.LOADING,
		payload: -1,
	}
}

const makeDefaultError: () => Partial<APIError> = () => ({messages: ["An unknown error has occurred. Please contact support or try your request again."]});
export async function addError(error?: {json: () => Promise<Partial<APIError>>} | Partial<APIError>): Promise<IAction<MetaAction.ADD_ERROR, any>> {
	let _error;

	try {
		if (error === null || error === undefined) {
			_error = makeDefaultError();
		} else if (error.hasOwnProperty("messages") && Array.isArray((error as APIError).messages) && (error as APIError).messages.length > 0) {
			_error = error;
		} else {
			try {
				// @ts-ignore
				if (error.response) {
					// @ts-ignore
					_error = await (error.response as {json: () => Promise<APIError>}).json();
				} else {
					_error = await (error as {json: () => Promise<APIError>}).json();
				}
			} catch (e) {
				_error = makeDefaultError();
			}
		}
	} catch (e) {
		_error = makeDefaultError();
	}

	return {
		type: MetaAction.ADD_ERROR,
		payload: _error,
	}
}

export function removeError(i: number = 0): IAction<MetaAction.REMOVE_ERROR, number> {
	return {
		type: MetaAction.REMOVE_ERROR,
		payload: i,
	}
}

export function toggleSideBar(open: boolean): IAction<MetaAction.SIDEBAR, boolean> {
	return {
		type: MetaAction.SIDEBAR,
		payload: open,
	}
}
