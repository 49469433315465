import React from "react";
import {RouteComponentProps} from "react-router";
import {Token} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {useHistory} from "react-router-dom";
import CreateCraneMonthlyLogForm from "../../components/forms/CreateCraneMonthlyLogForm";

interface IProps extends RouteComponentProps {
	fullToken?: Token;
}

const EditCraneMonthlyLogPage: React.FC<IProps> = (props) => {

	const history = useHistory();

	const params: URLSearchParams = new URLSearchParams(props.location?.search);
	const id: string = params.get("id");

	if (id?.length < 1) {
		history.replace("/form-history/monthly-log-history/crane-monthly-log-history");
	}

	return (
		<CreateCraneMonthlyLogForm id={id}/>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(EditCraneMonthlyLogPage);
