import {IAutoCompleteOption} from "../components/inputs/AlliedAutoComplete";
import {User} from "client";

/**
 * Converts an array of users into an array of options for the auto-complete input.
 *
 * @param users
 */
export function parseUsersForAutoComplete(users: Array<User> = []): Array<IAutoCompleteOption> {
	return users.map((_user: User): IAutoCompleteOption => {
		return {
			value: _user._id,
			label: _user.firstName + " " + _user.lastName,
		}
	});
}
