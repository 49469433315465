/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const FormType = {
    FINAL_TEST_FORM: 'FINAL_TEST_FORM',
    SERVICE_REPORT: 'SERVICE_REPORT',
    TIME_SHEET: 'TIME_SHEET',
    DEFICIENCY: 'DEFICIENCY',
    CRANE_DAILY_LOG_GROUP: 'CRANE_DAILY_LOG_GROUP',
    CRANE_WEEKLY_LOG: 'CRANE_WEEKLY_LOG',
    CRANE_MONTHLY_LOG: 'CRANE_MONTHLY_LOG',
    GENERATOR_DAILY_LOG_GROUP: 'GENERATOR_DAILY_LOG_GROUP'
};
export function FormTypeFromJSON(json) {
    return FormTypeFromJSONTyped(json, false);
}
export function FormTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function FormTypeToJSON(value) {
    return value;
}
