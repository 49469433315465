import React from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Deficiency, DeficiencyStatus, FormsApi, Token} from "client";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button} from "reactstrap";
import AlliedModal from "./AlliedModal";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	deficiency: Deficiency;
	onClose: () => void;
	onDone: () => void;
}

const GeneralDeficiencyResolveIssueModal: React.FC<IProps> = (props) => {

	/**
	 * Call api to mark the issue as resolved.
	 *
	 */
	async function resolveIssue(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new FormsApi(getConfig(props.fullToken)).resolveDeficiency({
				resolveDeficiencyBody: {
					deficiencyID: props.deficiency?._id,
				},
			});
			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<AlliedModal
			isOpen={props.isOpen}
			size="md"
		>
			<AlliedModalHeader
				title="Resolve Issue"
				onClose={props.onClose}
			/>

			<hr/>

			<p>
				Are you sure you want to resolve this issue?
			</p>

			<div>
				<Button
					color="red"
					onClick={resolveIssue}
				>
					Resolve Issue
				</Button>
			</div>
		</AlliedModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(GeneralDeficiencyResolveIssueModal)
