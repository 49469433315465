import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {GetUserListRequest, GetUserListResponse, Token, User, UsersApi, UserSortField} from "client";
import AlliedContainer from "../../components/AlliedContainer";
import AlliedPageHeader from "../../components/AlliedPageHeader";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import AlliedTableContainer from "../../components/tables/AlliedTableContainer";
import getConfig from "../../utils/getConfig";
import {getTokenTypeDisplay} from "../../utils/getTokenTypeDisplay";
import {getFullNameForUserTable} from "../../utils/getFullNameForTable";
import ManageUsersEditCell from "../../components/tables/cells/ManageUsersEditCell";
import ManageUsersDisableCell from "../../components/tables/cells/ManageUsersDisableCell";
import ManageUsersFilterModal from "../../components/modals/ManageUsersFilterModal";
import {cleanRequestForAPISubmission} from "../../utils/cleanRequestForAPISubmission";
import {alliedPaginationLimitOptions} from "../../components/tables/paginator/AlliedPaginatorLimitController";
import {valueOrBust} from "../../utils/valueOrBust";
import {abstractOnSortChange, getTableHeaderSortClassName} from "../../utils/abstractOnSortChange";
import ManageUsersSiteListCell from "../../components/tables/cells/ManageUsersSiteListCell";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
}

const defaultFilter: GetUserListRequest = {
	limit: alliedPaginationLimitOptions[0],
	offset: 0,
	site: [],
	type: [],
};

const ManageUsersPage: React.FC<IProps> = (props) => {

	const [showFilterModal, setShowFilterModal] = useState(false);
	const [filter, setFilter] = useState<GetUserListRequest>(defaultFilter);
	const [usersResponse, setUsersResponse] = useState<GetUserListResponse>(undefined);

	useEffect(() => {
		getUsers().then().catch();
	}, [JSON.stringify(filter)]);

	/**
	 * Call api to get list of users.
	 *
	 */
	async function getUsers(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new UsersApi(getConfig(props.fullToken)).getUserList(cleanRequestForAPISubmission(filter));
			setUsersResponse(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Toggles visibility of the filter modal.
	 *
	 */
	function toggleFilterModal(): void {
		setShowFilterModal(!showFilterModal);
	}

	/**
	 * When finished the filter modal, save the new filter (will prompt api via useEffect),
	 * and hide the filter modal.
	 *
	 * @param newFilter
	 */
	function onDoneFilterModal(newFilter: GetUserListRequest): void {
		setFilter({
			...newFilter,
			offset: 0,
		});
		setShowFilterModal(false);
	}

	/**
	 * Renderer for the Edit Information column.
	 *
	 * @param user
	 */
	function makeEditCell(user: User): ReactNode {
		return (
			<ManageUsersEditCell
				key={"edit" + user._id}
				user={user}
				onDone={getUsers}
			/>
		);
	}

	/**
	 * Renderer for the Disable User column.
	 *
	 * @param user
	 */
	function makeDisableCell(user: User): ReactNode {
		return (
			<ManageUsersDisableCell
				key={"disable" + user._id}
				user={user}
				onDone={getUsers}
			/>
		);
	}

	/**
	 * Renders the button with modal for viewing all the user's sites.
	 *
	 */
	function renderSitesAssignedCell(user: User): ReactNode {
		return (
			<ManageUsersSiteListCell
				key={"users-sites" + user._id}
				user={user}
				sites={user.sites}
			/>
		);
	}

	return (
		<React.Fragment>
			<ManageUsersFilterModal
				isOpen={showFilterModal}
				filter={filter}
				onClose={toggleFilterModal}
				onDone={onDoneFilterModal}
			/>

			<AlliedContainer>
				<AlliedPageHeader
					title="Manage Users"
					onClick={toggleFilterModal}
				/>

				<hr/>

				<AlliedTableContainer
					<GetUserListRequest>
					data={usersResponse?.users}
					showPaginator={true}
					paginationInfo={usersResponse?.paginationInfo}
					filter={filter}
					setFilter={setFilter}
					columnOptions={[
						{
							headerValue: "Name",
							key: undefined,
							rowCellClassName: "font-weight-bold",
							valueFormatter: getFullNameForUserTable,
						},
						{
							headerValue: "Username",
							key: "username",
							rowCellClassName: "font-weight-bold",
							headerCellClassName: getTableHeaderSortClassName(UserSortField.username, filter),
							onSort: abstractOnSortChange<UserSortField, GetUserListRequest>(UserSortField.username, filter, setFilter),
						},
						{
							headerValue: "Role",
							key: "type",
							valueFormatter: getTokenTypeDisplay,
							headerCellClassName: getTableHeaderSortClassName(UserSortField.type, filter),
							onSort: abstractOnSortChange<UserSortField, GetUserListRequest>(UserSortField.type, filter, setFilter),
						},
						{
							headerValue: "Certification Number",
							key: "certificationNumber",
							valueFormatter: valueOrBust,
						},
						{
							headerValue: "Sites Assigned",
							key: undefined,
							cellRender: renderSitesAssignedCell,
						},
						{
							headerValue: "Edit Information",
							key: undefined,
							cellRender: makeEditCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
						{
							headerValue: "Disable User",
							key: undefined,
							cellRender: makeDisableCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
					]}
				/>

			</AlliedContainer>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageUsersPage);
