import React from "react";
import {Col, Row} from "reactstrap";
import NumberFormat from "react-number-format";
import {PartsAndSupplies} from "client";

interface IProps {
	value: PartsAndSupplies;
	onChange: (newValue: PartsAndSupplies) => void;
}

const PartsAndSuppliesInput: React.FC<IProps> = (props) => {

	/**
	 * onChange for the quantity field, grab the correct value from the library.
	 *
	 * @param e
	 */
	function quantityOnChange(e): void {
		props.onChange({
			...props.value,
			quantity: e?.floatValue,
		});
	}

	/**
	 * onChange for the part number field.
	 *
	 * @param e
	 */
	function onPartNumberChange(e: React.ChangeEvent<HTMLInputElement>): void {
		props.onChange({
			...props.value,
			partNumber: e.target.value,
		});
	}

	/**
	 * onChange for the description field.
	 *
	 * @param e
	 */
	function onDescriptionChange(e: React.ChangeEvent<HTMLInputElement>): void {
		props.onChange({
			...props.value,
			description: e.target.value,
		});
	}

	return (
		<Row>
			<Col xs={12} sm={6} className="mb-3">
				<label>Qty</label>
				<NumberFormat
					allowLeadingZeros={false}
					placeholder="Enter Quantity..."
					value={props.value?.quantity}
					onValueChange={quantityOnChange}
					allowNegative={false}
				/>
			</Col>

			<Col xs={12} sm={6} className="mb-3">
				<label>Part Number</label>
				<input
					placeholder="Enter Part Number..."
					value={props.value?.partNumber}
					onChange={onPartNumberChange}
				/>
			</Col>

			<Col xs={12} sm={12}>
				<label>Description</label>
				<input
					placeholder="Enter Description..."
					value={props.value?.description}
					onChange={onDescriptionChange}
				/>
			</Col>
		</Row>
	);
};

export default PartsAndSuppliesInput;
