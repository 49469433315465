/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { AdminFromJSON, ChangePasswordBodyToJSON, ChangeUserPasswordBodyToJSON, CraneOperatorFromJSON, CreateAdminBodyToJSON, CreateAlliedCraneEmployeeBodyToJSON, CreateCraneOperatorBodyToJSON, CreateHealthAndSafetyOfficerBodyToJSON, CreateSecondaryAdminBodyToJSON, CreateSupervisorBodyToJSON, GetUserListResponseFromJSON, GetUserResponseFromJSON, IDBodyToJSON, LoginBodyToJSON, LoginCraneOperatorAssignSiteBodyToJSON, OperatorSignUpBodyToJSON, RequestPasswordResetBodyToJSON, RequestPasswordResetResponseFromJSON, ResetPasswordBodyToJSON, SecondaryAdminFromJSON, SupervisorFromJSON, TokenFromJSON, UpdateProfileBodyToJSON, UpdateUserBodyToJSON, } from '../models';
/**
 *
 */
export class UsersApi extends runtime.BaseAPI {
    /**
     * Used by users to change their passwords.
     */
    async changePasswordRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/change-password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordBodyToJSON(requestParameters.changePasswordBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by users to change their passwords.
     */
    async changePassword(requestParameters = {}, initOverrides) {
        await this.changePasswordRaw(requestParameters, initOverrides);
    }
    /**
     * Used by GOD admins to change user\'s passwords.
     */
    async changeUserPasswordRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Admin", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/change_user_password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeUserPasswordBodyToJSON(requestParameters.changeUserPasswordBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by GOD admins to change user\'s passwords.
     */
    async changeUserPassword(requestParameters = {}, initOverrides) {
        await this.changeUserPasswordRaw(requestParameters, initOverrides);
    }
    /**
     * Used for crane operator self-service sign-up
     */
    async craneOperatorSignUpRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/users/operator-sign-up`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OperatorSignUpBodyToJSON(requestParameters.operatorSignUpBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CraneOperatorFromJSON(jsonValue));
    }
    /**
     * Used for crane operator self-service sign-up
     */
    async craneOperatorSignUp(requestParameters = {}, initOverrides) {
        const response = await this.craneOperatorSignUpRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async createAdminRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Admin", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/admins`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAdminBodyToJSON(requestParameters.createAdminBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AdminFromJSON(jsonValue));
    }
    /**
     */
    async createAdmin(requestParameters = {}, initOverrides) {
        const response = await this.createAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async createAlliedCraneEmployeeRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/allied-crane-employees`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAlliedCraneEmployeeBodyToJSON(requestParameters.createAlliedCraneEmployeeBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AdminFromJSON(jsonValue));
    }
    /**
     */
    async createAlliedCraneEmployee(requestParameters = {}, initOverrides) {
        const response = await this.createAlliedCraneEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to create new CraneOperator accounts
     */
    async createCraneOperatorRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/crane-operators`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCraneOperatorBodyToJSON(requestParameters.createCraneOperatorBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CraneOperatorFromJSON(jsonValue));
    }
    /**
     * Used to create new CraneOperator accounts
     */
    async createCraneOperator(requestParameters = {}, initOverrides) {
        const response = await this.createCraneOperatorRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async createHealthAndSafetyOfficerRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/health-and-safety-officers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateHealthAndSafetyOfficerBodyToJSON(requestParameters.createHealthAndSafetyOfficerBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async createHealthAndSafetyOfficer(requestParameters = {}, initOverrides) {
        await this.createHealthAndSafetyOfficerRaw(requestParameters, initOverrides);
    }
    /**
     */
    async createSecondaryAdminRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/secondary-admins`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSecondaryAdminBodyToJSON(requestParameters.createSecondaryAdminBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SecondaryAdminFromJSON(jsonValue));
    }
    /**
     */
    async createSecondaryAdmin(requestParameters = {}, initOverrides) {
        const response = await this.createSecondaryAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to create new Supervisor accounts
     */
    async createSupervisorRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/supervisors`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSupervisorBodyToJSON(requestParameters.createSupervisorBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SupervisorFromJSON(jsonValue));
    }
    /**
     * Used to create new Supervisor accounts
     */
    async createSupervisor(requestParameters = {}, initOverrides) {
        const response = await this.createSupervisorRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used by GOD admins to disable a user.
     */
    async disableUserRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Admin", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/disable`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IDBodyToJSON(requestParameters.iDBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by GOD admins to disable a user.
     */
    async disableUser(requestParameters = {}, initOverrides) {
        await this.disableUserRaw(requestParameters, initOverrides);
    }
    /**
     * Used by GOD admins to enable a user
     */
    async enableUserRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Admin", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/enable`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IDBodyToJSON(requestParameters.iDBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by GOD admins to enable a user
     */
    async enableUser(requestParameters = {}, initOverrides) {
        await this.enableUserRaw(requestParameters, initOverrides);
    }
    /**
     */
    async getProfileRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserResponseFromJSON(jsonValue));
    }
    /**
     */
    async getProfile(initOverrides) {
        const response = await this.getProfileRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async getUserListRaw(requestParameters, initOverrides) {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getUserList.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getUserList.');
        }
        const queryParameters = {};
        if (requestParameters.site) {
            queryParameters['site'] = requestParameters.site;
        }
        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }
        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }
        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserListResponseFromJSON(jsonValue));
    }
    /**
     */
    async getUserList(requestParameters, initOverrides) {
        const response = await this.getUserListRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to login a user account. Returns a token if successful
     */
    async loginRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginBodyToJSON(requestParameters.loginBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TokenFromJSON(jsonValue));
    }
    /**
     * Used to login a user account. Returns a token if successful
     */
    async login(requestParameters = {}, initOverrides) {
        const response = await this.loginRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Login crane operators and assign them to new sites
     */
    async loginCraneOperatorAssignSiteRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/login-crane-operator-assign-site`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginCraneOperatorAssignSiteBodyToJSON(requestParameters.loginCraneOperatorAssignSiteBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TokenFromJSON(jsonValue));
    }
    /**
     * Login crane operators and assign them to new sites
     */
    async loginCraneOperatorAssignSite(requestParameters = {}, initOverrides) {
        const response = await this.loginCraneOperatorAssignSiteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used by users to request a password reset verification code
     */
    async requestPasswordResetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/users/request_password_reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestPasswordResetBodyToJSON(requestParameters.requestPasswordResetBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => RequestPasswordResetResponseFromJSON(jsonValue));
    }
    /**
     * Used by users to request a password reset verification code
     */
    async requestPasswordReset(requestParameters = {}, initOverrides) {
        const response = await this.requestPasswordResetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used by users to change their password if they forgot it.
     */
    async resetPasswordRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/users/reset_password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordBodyToJSON(requestParameters.resetPasswordBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by users to change their password if they forgot it.
     */
    async resetPassword(requestParameters = {}, initOverrides) {
        await this.resetPasswordRaw(requestParameters, initOverrides);
    }
    /**
     * Used by users to update their profile.
     */
    async updateProfileRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/profile`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProfileBodyToJSON(requestParameters.updateProfileBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserResponseFromJSON(jsonValue));
    }
    /**
     * Used by users to update their profile.
     */
    async updateProfile(requestParameters = {}, initOverrides) {
        const response = await this.updateProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async updateUserRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/update-user`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserBodyToJSON(requestParameters.updateUserBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserResponseFromJSON(jsonValue));
    }
    /**
     */
    async updateUser(requestParameters = {}, initOverrides) {
        const response = await this.updateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
