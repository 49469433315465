/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { FormFromJSONTyped, FormToJSON, } from './Form';
import { TimeSheetEntryFromJSON, TimeSheetEntryToJSON, } from './TimeSheetEntry';
/**
 * Check if a given object implements the TimeSheet interface.
 */
export function instanceOfTimeSheet(value) {
    let isInstance = true;
    isInstance = isInstance && "weekOf" in value;
    isInstance = isInstance && "paymentPeriod" in value;
    return isInstance;
}
export function TimeSheetFromJSON(json) {
    return TimeSheetFromJSONTyped(json, false);
}
export function TimeSheetFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...FormFromJSONTyped(json, ignoreDiscriminator),
        'weekOf': json['weekOf'],
        'paymentPeriod': json['paymentPeriod'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'entries': !exists(json, 'entries') ? undefined : (json['entries'].map(TimeSheetEntryFromJSON)),
    };
}
export function TimeSheetToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...FormToJSON(value),
        'weekOf': value.weekOf,
        'paymentPeriod': value.paymentPeriod,
        'notes': value.notes,
        'entries': value.entries === undefined ? undefined : (value.entries.map(TimeSheetEntryToJSON)),
    };
}
