import {CreateGeneratorDailyLogBody, GeneratorDailyLog, UpdateGeneratorDailyLogBody} from "client";
import {defaultCreateGeneratorDailyLogForm} from "../components/forms/CreateGeneratorDailyLogForm";
import {generateOffsetDate} from "./timeZoneConversions";

/**
 * Takes a Generator Daily Log and converts it to the interface for the form page.
 * This is intended to be used when editing an existing Generator Daily Log.
 *
 * For the comment bodies, set to the default form values,
 * as with how comments work at the moment on logs, we don't want
 * to be re-submitting them, so instead we'll display previously
 * saved comments in the UI but they'll be uneditable, and the user
 * can add new comments if they want.
 *
 * @param res
 * @param day
 */
export function convertGeneratorDailyLogToUpdateBody(res: GeneratorDailyLog, day: number): UpdateGeneratorDailyLogBody {
	return {
		day,
		initials: res.initials,
		users: res.users.map(u => u._id),
		hours: res.hours,
		hourMeterStart: res.hourMeterStart,
		hourMeterEnd: res.hourMeterEnd,
		hourMeterTotal: res.hourMeterTotal,
		createLogCommentBodies: defaultCreateGeneratorDailyLogForm.createLogCommentBodies,
		checkOilLevel: res.checkOilLevel,
		checkCoolantLevels: res.checkCoolantLevels,
		visualInspectionForWear: res.visualInspectionForWear,
		checkForLeakage: res.checkForLeakage,
		ensureDoorPanelsAndCoversAreClosed: res.ensureDoorPanelsAndCoversAreClosed,
	}
}
