import React, {ReactNode} from "react";
import {Equipment} from "client";
import {valueOrBustPrintables} from "../../../utils/valueOrBust";
import {getEquipmentTypeDisplay} from "../../../utils/getEquipmentTypeDisplay";
import {isCrane} from "../forms/FinalTestFormPrintable";
import {getCraneTypeDisplay} from "../../../utils/getCraneTypeDisplay";
import {parseSiteAddressForSingleInput} from "../../../utils/parseSiteAddressForSingleInput";

interface IProps {
	equipment: Array<Equipment>;
}

const PrintableEquipmentLocationListTable: React.FC<IProps> = (props) => {

	function makeEquipmentEntry(_equipment: Equipment, i: number): ReactNode {
		return (
			<tr key={`printable-equipment-location-list-row_${_equipment._id}_${i}_${Math.random()}`}>
				<td>
					{valueOrBustPrintables(_equipment?.ownedBy)}
				</td>

				<td>
					{valueOrBustPrintables(_equipment?.type, getEquipmentTypeDisplay(_equipment?.type))}
				</td>

				<td>
					{valueOrBustPrintables(_equipment?.make)}
				</td>

				<td>
					{valueOrBustPrintables(_equipment?.model)}
				</td>

				<td>
					{valueOrBustPrintables(_equipment?.serialNumber)}
				</td>

				<td>
					{valueOrBustPrintables(isCrane(_equipment) ? getCraneTypeDisplay(_equipment.craneType) : "-")}
				</td>

				<td>
					{valueOrBustPrintables(_equipment?.site?.name)}
				</td>

				<td className="printable__table__break-word">
					{valueOrBustPrintables(_equipment?.site?.address, parseSiteAddressForSingleInput(_equipment?.site?.address))}
				</td>

				<td>
					{valueOrBustPrintables(_equipment?.rentedBy)}
				</td>
			</tr>
		);
	}

	return (
		<div style={{width: "100%"}}>
			<table className="printable__table">
				<tr>
					{
						[
							"Owned By",
							"Equipment",
							"Make",
							"Model",
							"S/N",
							"Type",
							"Site Name",
							"Site Address",
							"Rented By",
						].map((label: string, i: number) => {
							return (
								<td
									key={`printable-equipment-location-list-table-header_${label}_${i}_${Math.random()}`}
									className="printable__table__label"
								>
									{label}
								</td>
							);
						})
					}
				</tr>

				{props.equipment?.length > 0 ? (
					<React.Fragment>
						{props.equipment?.map(makeEquipmentEntry)}
					</React.Fragment>
				) : (
					<tr>
						<td className="printable__table__centered" colSpan={9}>
							No Equipment Found
						</td>
					</tr>
				)}
			</table>
		</div>
	);
};

export default PrintableEquipmentLocationListTable;
