import React, {ReactNode} from "react";
import {CraneMonthlyLog, CraneWeeklyLog, Log, LogComment} from "client";
import {FiCheck} from "react-icons/fi";
import {FiX} from "react-icons/all";
import {getFullNameForTable} from "../../../utils/getFullNameForTable";

interface IProps {
	log: CraneWeeklyLog | CraneMonthlyLog;
}

const PrintableWeeklyAndMonthlyLogComments: React.FC<IProps> = (props) => {

	function makeComments(comment: LogComment, i: number): ReactNode {
		return (
			<tr>
				<td className="printable__table__centered">
					{comment?.flagged ? (
						<React.Fragment>
							<FiCheck className="printable__react-icon"/>
						</React.Fragment>
					) : (
						<React.Fragment>
							<FiX className="printable__react-icon"/>
						</React.Fragment>
					)}
				</td>

				<td className="printable__table__label printable__table__centered">
					{getFullNameForTable(comment.user)}
				</td>

				<td>
					{comment.comment}
				</td>
			</tr>
		);
	}

	return (
		<div style={{width: "100%"}}>
			<table className="printable__table">
				<tr>
					<td className="printable__table__label printable__table__centered" style={{width: "7%"}}>
						Flagged?
					</td>

					<td className="printable__table__label printable__table__centered" style={{width: "14%"}}>
						Commenter
					</td>

					<td className="printable__table__label">
						Comment
					</td>
				</tr>

				{props?.log?.comments?.length > 0 ? (
					<React.Fragment>
						{props.log?.comments?.map(makeComments)}
					</React.Fragment>
				) : (
					<tr>
						<td className="printable__table__centered" colSpan={3}>
							No Comments
						</td>
					</tr>
				)}
			</table>
		</div>
	);
};

export default PrintableWeeklyAndMonthlyLogComments;
