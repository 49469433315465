import React, {useState} from "react";
import {SiteWithUsersAndEquipment} from "client";
import {Button} from "reactstrap";
import ManageSitesEditUsersModal from "../../modals/ManageSitesEditUsersModal";

interface IProps {
	site: SiteWithUsersAndEquipment;
	onDone: () => Promise<void>;
}

const ManageSitesEditUsersCell: React.FC<IProps> = (props) => {

	const [showEditUsers, setShowEditUsers] = useState(false);

	function toggleEditUsersModal(): void {
		setShowEditUsers(!showEditUsers);
	}

	function onDone(): void {
		setShowEditUsers(false);
		props.onDone().then().catch();
	}

	return (
		<div className="d-flex justify-content-center">
			<ManageSitesEditUsersModal
				isOpen={showEditUsers}
				site={props.site}
				onClose={toggleEditUsersModal}
				onDone={onDone}
			/>

			<Button
				color="black"
				onClick={toggleEditUsersModal}
			>
				Edit Users
			</Button>
		</div>
	);
};

export default ManageSitesEditUsersCell;
