import {CraneDailyLog, CraneDailyLogGroup, CraneOperator, GeneratorDailyLog, GeneratorDailyLogGroup, User} from "client";
import {uniq} from "lodash";
import {getFullNameForTable} from "./getFullNameForTable";

export function getAllOperatorsFromGroupedDailyLogs(value: any, log: CraneDailyLogGroup | GeneratorDailyLogGroup): string {
	const dailyLogs: Array<CraneDailyLog | GeneratorDailyLog> = [
		log["_0"],
		log["_1"],
		log["_2"],
		log["_3"],
		log["_4"],
		log["_5"],
		log["_6"],
	].filter(l => l !== undefined);

	const operators: Array<User> = [];

	for (const d of dailyLogs) {
		operators.push(...d.users);
	}

	let str: string = "";
	const uniqueOperators = [];

	for (const o of operators) {
		if (!(uniqueOperators.find(op => op._id === o._id))) {
			uniqueOperators.push(o)
		}
	}

	for (const o of uniqueOperators) {
		str += getFullNameForTable(o) + "\n";
	}

	return str;
}

export function getOperatorsOnSingleDailyLog(log: CraneDailyLog | GeneratorDailyLog): string {
	let str: string = "";

	for (const o of log.users) {
		str += getFullNameForTable(o) + "\n";
	}

	return str;
}
