/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { EquipmentFromJSON, EquipmentToJSON, } from './Equipment';
import { FormFromJSONTyped, FormToJSON, } from './Form';
import { LogHistoryFromJSON, LogHistoryToJSON, } from './LogHistory';
import { SiteFromJSON, SiteToJSON, } from './Site';
import { UserFromJSON, UserToJSON, } from './User';
/**
 * Check if a given object implements the Log interface.
 */
export function instanceOfLog(value) {
    let isInstance = true;
    isInstance = isInstance && "equipment" in value;
    isInstance = isInstance && "originalSite" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "initials" in value;
    isInstance = isInstance && "history" in value;
    return isInstance;
}
export function LogFromJSON(json) {
    return LogFromJSONTyped(json, false);
}
export function LogFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...FormFromJSONTyped(json, ignoreDiscriminator),
        'equipment': EquipmentFromJSON(json['equipment']),
        'originalSite': SiteFromJSON(json['originalSite']),
        'date': json['date'],
        'initials': json['initials'],
        'approvedBy': !exists(json, 'approvedBy') ? undefined : UserFromJSON(json['approvedBy']),
        'history': (json['history'].map(LogHistoryFromJSON)),
    };
}
export function LogToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...FormToJSON(value),
        'equipment': EquipmentToJSON(value.equipment),
        'originalSite': SiteToJSON(value.originalSite),
        'date': value.date,
        'initials': value.initials,
        'approvedBy': UserToJSON(value.approvedBy),
        'history': (value.history.map(LogHistoryToJSON)),
    };
}
