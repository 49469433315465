import React from "react";
import CreateCraneMonthlyLogForm from "../../components/forms/CreateCraneMonthlyLogForm";

const CreateCraneMonthlyLogPage: React.FC = () => {

	return (
		<CreateCraneMonthlyLogForm/>
	);
};

export default CreateCraneMonthlyLogPage;
