/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { EquipmentFromJSON, EquipmentToJSON, } from './Equipment';
import { FormFromJSONTyped, FormToJSON, } from './Form';
import { PartsAndSuppliesFromJSON, PartsAndSuppliesToJSON, } from './PartsAndSupplies';
import { ServiceReportEntryFromJSON, ServiceReportEntryToJSON, } from './ServiceReportEntry';
import { SiteFromJSON, SiteToJSON, } from './Site';
/**
 * Check if a given object implements the ServiceReport interface.
 */
export function instanceOfServiceReport(value) {
    let isInstance = true;
    return isInstance;
}
export function ServiceReportFromJSON(json) {
    return ServiceReportFromJSONTyped(json, false);
}
export function ServiceReportFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...FormFromJSONTyped(json, ignoreDiscriminator),
        'equipment': !exists(json, 'equipment') ? undefined : EquipmentFromJSON(json['equipment']),
        'originalSite': !exists(json, 'originalSite') ? undefined : SiteFromJSON(json['originalSite']),
        'date': !exists(json, 'date') ? undefined : json['date'],
        'customer': !exists(json, 'customer') ? undefined : json['customer'],
        'serviceRequestedBy': !exists(json, 'serviceRequestedBy') ? undefined : json['serviceRequestedBy'],
        'usageHours': !exists(json, 'usageHours') ? undefined : json['usageHours'],
        'reportedIssue': !exists(json, 'reportedIssue') ? undefined : json['reportedIssue'],
        'workPerformed': !exists(json, 'workPerformed') ? undefined : json['workPerformed'],
        'entries': !exists(json, 'entries') ? undefined : (json['entries'].map(ServiceReportEntryFromJSON)),
        'partsAndSuppliesArray': !exists(json, 'partsAndSuppliesArray') ? undefined : (json['partsAndSuppliesArray'].map(PartsAndSuppliesFromJSON)),
        'additionalServiceRequired': !exists(json, 'additionalServiceRequired') ? undefined : json['additionalServiceRequired'],
        'repEmail': !exists(json, 'repEmail') ? undefined : json['repEmail'],
        'authorizedRepresentative': !exists(json, 'authorizedRepresentative') ? undefined : json['authorizedRepresentative'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'shortIdentifier': !exists(json, 'shortIdentifier') ? undefined : json['shortIdentifier'],
    };
}
export function ServiceReportToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...FormToJSON(value),
        'equipment': EquipmentToJSON(value.equipment),
        'originalSite': SiteToJSON(value.originalSite),
        'date': value.date,
        'customer': value.customer,
        'serviceRequestedBy': value.serviceRequestedBy,
        'usageHours': value.usageHours,
        'reportedIssue': value.reportedIssue,
        'workPerformed': value.workPerformed,
        'entries': value.entries === undefined ? undefined : (value.entries.map(ServiceReportEntryToJSON)),
        'partsAndSuppliesArray': value.partsAndSuppliesArray === undefined ? undefined : (value.partsAndSuppliesArray.map(PartsAndSuppliesToJSON)),
        'additionalServiceRequired': value.additionalServiceRequired,
        'repEmail': value.repEmail,
        'authorizedRepresentative': value.authorizedRepresentative,
        'notes': value.notes,
        'shortIdentifier': value.shortIdentifier,
    };
}
