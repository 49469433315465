import React, {useState} from "react";
import {Site, Token, UserType} from "client";
import {Button} from "reactstrap";
import {connect} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import ManageSitesViewDetailsModalAdminView from "../../modals/ManageSitesViewDetailsModalAdminView";
import ManageSitesViewDetailsModalManagerSupervisorView
	from "../../modals/ManageSitesViewDetailsModalManagerSupervisorView";
import ManageSitesViewDetailsModalOperatorEmployeeView
	from "../../modals/ManageSitesViewDetailsModalOperatorEmployeeView";

interface IProps {
	fullToken?: Token;
	site: Site;
	onDone: () => Promise<void>;
}

const ManageSitesViewDetailsCell: React.FC<IProps> = (props) => {

	const [showDetails, setShowDetails] = useState(false);

	/**
	 * Show or hide the details modal.
	 *
	 */
	function toggleShowDetails(): void {
		setShowDetails(!showDetails);
	}

	/**
	 * Call when finished any actions.
	 *
	 */
	function onDone(): void {
		setShowDetails(false);
		props.onDone().then().catch();
	}

	return (
		<div className="d-flex justify-content-center">
			{props.fullToken?.type === UserType.ADMIN && (
				<ManageSitesViewDetailsModalAdminView
					isOpen={showDetails}
					site={props.site}
					onClose={toggleShowDetails}
					onDone={onDone}
				/>
			)}

			{(props.fullToken?.type === UserType.SECONDARY_ADMIN || props.fullToken?.type === UserType.SUPERVISOR) && (
				<ManageSitesViewDetailsModalManagerSupervisorView
					isOpen={showDetails}
					site={props.site}
					onClose={toggleShowDetails}
				/>
			)}

			{(props.fullToken?.type === UserType.CRANE_OPERATOR || props.fullToken?.type === UserType.ALLIED_CRANE_EMPLOYEE) && (
				<ManageSitesViewDetailsModalOperatorEmployeeView
					isOpen={showDetails}
					site={props.site}
					onClose={toggleShowDetails}
				/>
			)}

			<Button
				color="black"
				onClick={toggleShowDetails}
			>
				View Site Details
			</Button>
		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageSitesViewDetailsCell);

