import React, {ChangeEventHandler, useEffect, useState} from "react";
import {connect} from "react-redux";
import {
    addError,
    decrementLoading,
    incrementLoading,
    login,
    logout,
    updateCurrentUser
} from "../../redux/meta/MetaActions";
import {LoginCraneOperatorAssignSiteBody, Token, UsersApi, UserType} from "client";
import getConfig from "../../utils/getConfig";
import {IStore} from "../../redux/defaultStore";
import {NavLink, useHistory} from "react-router-dom";
import {Button} from "reactstrap";
import {useParams} from "react-router";

interface IProps {
    dispatch?: any;
    fullToken?: Token;
}

const LoginPage: React.FC<IProps> = (props) => {

    const history = useHistory();
    const {crane} = useParams<{ crane: string }>();
    const [loggedOutOnce, setLoggedOutOnce] = useState(false);
    const [form, setForm] = useState<LoginCraneOperatorAssignSiteBody>({username: "", password: "", craneId: "", accessCode: ""});

    /**
     * If the token exists when user lands here log them out
     *
     */
    useEffect(() => {
        if (!loggedOutOnce) {
            setLoggedOutOnce(true);

            if ((props.fullToken && props.fullToken.token)) {
                props.dispatch(logout());
            }
        }
    }, [props.fullToken?.token, loggedOutOnce]);

    /**
     * Dynamic onChange event for the login form.
     *
     * @param key
     */
    function onChangeHelper(key: keyof LoginCraneOperatorAssignSiteBody): ChangeEventHandler<HTMLInputElement> {
        return (e) => {
            setForm({
                ...form,
                [key]: e?.target?.value,
            });
        }
    }

    /**
     * Call api when form submits and direct to correct page based on token type if successful.
     *
     * @param e
     */
    async function submitLogin(e?: React.SyntheticEvent): Promise<void> {
        e?.preventDefault();
        props.dispatch(incrementLoading());

        try {
            const res = await new UsersApi(getConfig()).loginCraneOperatorAssignSite({
                loginCraneOperatorAssignSiteBody: {
                    ...form,
                    craneId: crane,
                },
            });

            const userRes = await new UsersApi(getConfig(res)).getProfile();

            await props.dispatch(login(res));
            await props.dispatch(updateCurrentUser(userRes.user));
            switch(userRes.user.type) {
                case UserType.ADMIN:
                    history.push("/sites-options/manage-sites");
                    break;
                case UserType.ALLIED_CRANE_EMPLOYEE:
                    history.push("/sites-options/view-sites");
                    break;
                case UserType.CRANE_OPERATOR:
                    history.push("/sites-options/view-sites");
                    break;
                case UserType.HEALTH_AND_SAFETY_OFFICER:
                    history.push("/form-history/daily-log-history/crane-daily-log-history");
                    break;
                case UserType.SECONDARY_ADMIN:
                    history.push("/sites-options/manage-sites");
                    break;
                case UserType.SUPERVISOR:
                    history.push("/sites-options/manage-sites");
                    break;
            }
        } catch (e) {
            props.dispatch(addError(e));
        }

        props.dispatch(decrementLoading());
    }

    return (
        <main className="login">
            <div className="login-background-container">
                <img src="/images/worker-in-reflective-vest-and-hardhat-standing-on--XCK4WA6.png"
                     alt="working background"/>
            </div>

            <div className="login-content">

                <div>
                    <div className="login-content__image-container">
                        <img src="/logos/allied-logo.svg"/>
                    </div>

                    <div className="login-content__form-container">
                        <div className="d-flex justify-content-end mb-4">
                            <NavLink to={`/operator-registration/${crane}`} className="text-white text-right">
                                Don't Have An Account?
                            </NavLink>
                        </div>

                        <form className="login-content__form-container__form" onSubmit={submitLogin}>
                            <div>
                                <label className="text-white">Username</label>
                                <input
                                    className="mb-3 border-0"
                                    placeholder="Enter Username..."
                                    value={form?.username}
                                    onChange={onChangeHelper("username")}
                                />

                                <label className="text-white">Password</label>
                                <input
                                    className="mb-3 border-0"
                                    placeholder="Enter Password..."
                                    value={form?.password}
                                    onChange={onChangeHelper("password")}
                                    type="password"
                                />

                                <label className="text-white">Access Code</label>
                                <input
                                    className="mb-3 border-0"
                                    placeholder="Enter Access Code..."
                                    value={form?.accessCode}
                                    onChange={onChangeHelper("accessCode")}
                                />

                                <div className="d-flex justify-content-end">
                                    <Button
                                        type="submit"
                                        color="black"
                                    >
                                        Log In
                                    </Button>
                                </div>
                            </div>
                        </form>

                        <div className="login-content__form-container__version-label">
                            <p>
                                {process.env.REACT_APP_VERSION}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default connect((store: IStore, props: IProps) => {
    return {
        fullToken: store.metaStore?.fullToken,
        ...props,
    }
})(LoginPage);
