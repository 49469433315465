import React from "react";
import {FormsApi, Token} from "client";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/MetaActions";
import {connect} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import AbstractPrintableButtonManager from "./AbstractPrintableButtonManager";
import getConfig from "../../../utils/getConfig";
import CraneDeficiencyPrintable, {IDeficiencyPrintableFormInterface} from "../forms/CraneDeficiencyPrintable";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	id: string;
	children: string;
	showPrintable?: boolean;
	className?: string;
}

const ViewDeficienciesButton: React.FC<IProps> = (props) => {

	/**
	 * Call api to get & return the deficiencies.
	 *
	 */
	async function getDeficiencies(): Promise<IDeficiencyPrintableFormInterface> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FormsApi(getConfig(props.fullToken)).getDeficiencyListForEquipment({
				limit: Number.MAX_SAFE_INTEGER,
				offset: 0,
				equipment: props.id,
			});

			props.dispatch(decrementLoading());

			return {
				equipment: res.equipment,
				deficiencies: res.deficiencies,
			};
		} catch (e) {
			props.dispatch(addError(e));
			props.dispatch(decrementLoading());
		}
	}

	return (
		<AbstractPrintableButtonManager
			<IDeficiencyPrintableFormInterface>
			color="black"
			onClick={getDeficiencies}
			component={CraneDeficiencyPrintable}
			showPrintable={props.showPrintable}
			className={props.className}
			documentTitle="deficiency-report"
		>
			{props.children}
		</AbstractPrintableButtonManager>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ViewDeficienciesButton);
