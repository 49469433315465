import React, {useState} from "react";
import {Button} from "reactstrap";
import {MaterialHoist} from "client";
import ManageMaterialHoistsEditModal from "../../modals/ManageMaterialHoistsEditModal";

interface IProps {
	materialHoist: MaterialHoist;
	onDone(): Promise<void>;
}

const ManageMaterialHoistsEditCell: React.FC<IProps> = (props) => {

	const [showEdit, setShowEdit] = useState(false);

	/**
	 * Toggle showing the edit material hoist modal.
	 *
	 */
	function toggleShowEdit(): void {
		setShowEdit(!showEdit);
	}

	/**
	 * Call when finished with form; close modal & call props.onDone to get new table data.
	 *
	 */
	function onDone(): void {
		setShowEdit(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<ManageMaterialHoistsEditModal
				isOpen={showEdit}
				materialHoist={props.materialHoist}
				onClose={toggleShowEdit}
				onDone={onDone}
			/>

			<div className="allied-table-cell">
				<Button
					color="black"
					onClick={toggleShowEdit}
				>
					View/Edit Details
				</Button>
			</div>
		</React.Fragment>
	);
};

export default ManageMaterialHoistsEditCell;
