import {ContextFunctionCellWithValue} from "frame-one-table/build/contextTypes";
import {User} from "client";

/**
 * Generate name for the table when row is type User.
 * (Only going to be used when the table is showing a list of users basically).
 *
 * @param user
 */
export const getFullNameForUserTable: ContextFunctionCellWithValue<User, string> = (user: User) => {
	if (user.firstName && user.lastName) {
		return user.firstName + " " + user.lastName;
	} else if (user.firstName) {
		return user.firstName;
	} else if (user.lastName) {
		return user.lastName;
	}
}

/**
 * Similar to above but to be used as a formatter when the user is nested as an
 * object within a table row for something else, ie. owner: User.
 *
 * @param user
 */
export function getFullNameForTable(user: User): string {
	if (!user) {
		return "-";
	}

	if (user.firstName && user.lastName) {
		return user.firstName + " " + user.lastName;
	} else if (user.firstName) {
		return user.firstName;
	} else if (user.lastName) {
		return user.lastName;
	}
}
