/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const UserSortField = {
    type: 'type',
    username: 'username',
    email: 'email',
    createdAt: 'createdAt',
    updatedAt: 'updatedAt'
};
export function UserSortFieldFromJSON(json) {
    return UserSortFieldFromJSONTyped(json, false);
}
export function UserSortFieldFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function UserSortFieldToJSON(value) {
    return value;
}
