/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
/**
 * Check if a given object implements the UpdateMaterialHoistBody interface.
 */
export function instanceOfUpdateMaterialHoistBody(value) {
    let isInstance = true;
    isInstance = isInstance && "equipmentID" in value;
    isInstance = isInstance && "ownedBy" in value;
    isInstance = isInstance && "make" in value;
    isInstance = isInstance && "model" in value;
    isInstance = isInstance && "serialNumber" in value;
    isInstance = isInstance && "preExistingUsageHours" in value;
    isInstance = isInstance && "siteID" in value;
    return isInstance;
}
export function UpdateMaterialHoistBodyFromJSON(json) {
    return UpdateMaterialHoistBodyFromJSONTyped(json, false);
}
export function UpdateMaterialHoistBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'equipmentID': json['equipmentID'],
        'ownedBy': json['ownedBy'],
        'rentedBy': !exists(json, 'rentedBy') ? undefined : json['rentedBy'],
        'make': json['make'],
        'model': json['model'],
        'serialNumber': json['serialNumber'],
        'preExistingUsageHours': json['preExistingUsageHours'],
        'siteID': json['siteID'],
    };
}
export function UpdateMaterialHoistBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'equipmentID': value.equipmentID,
        'ownedBy': value.ownedBy,
        'rentedBy': value.rentedBy,
        'make': value.make,
        'model': value.model,
        'serialNumber': value.serialNumber,
        'preExistingUsageHours': value.preExistingUsageHours,
        'siteID': value.siteID,
    };
}
