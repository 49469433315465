import React, {useState} from "react";
import {Button} from "reactstrap";
import {Deficiency, DeficiencyStatus} from "client";
import EditGeneralDeficiencyIssueModal from "../../modals/EditGeneralDeficiencyIssueModal";

interface IProps {
	deficiency: Deficiency;
	onDone: () => Promise<void>;
}

const GeneralDeficiencyEditIssueCell: React.FC<IProps> = (props) => {

	const [showEditModal, setShowEditModal] = useState(false);

	function toggleShowEditModal(): void {
		setShowEditModal(!showEditModal);
	}

	function doneEditing(): void {
		setShowEditModal(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<EditGeneralDeficiencyIssueModal
				isOpen={showEditModal}
				deficiency={props.deficiency}
				onClose={toggleShowEditModal}
				onDone={doneEditing}
			/>

			<div className="d-flex justify-content-center">
				<Button
					color="black"
					onClick={toggleShowEditModal}
					disabled={props.deficiency?.status === DeficiencyStatus.RESOLVED}
				>
					Edit Issue
				</Button>
			</div>
		</React.Fragment>
	);
};

export default GeneralDeficiencyEditIssueCell;
