import React, {ChangeEventHandler, useEffect, useState} from "react";
import {
	Crane,
	CraneDailyLog, CraneDailyLogGroup, CraneOperator,
	CraneType,
	CreateCraneDailyLogBody,
	CreateLogCommentBody,
	EquipmentApi,
	EquipmentType,
	FormsApi,
	InspectionStatus,
	Site,
	SitesApi,
	Token,
	UpdateCraneDailyLogBody,
	UpdateLuffingTowerCraneDailyLogBody,
	UpdateSelfErectingCraneDailyLogBody,
	UpdateTowerCraneDailyLogBody,
	User,
	UsersApi, UserType,
} from "client";
import moment from "moment";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {useHistory} from "react-router-dom";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {cloneDeep, find} from "lodash";
import {getFullNameForTable} from "../../utils/getFullNameForTable";
import AlliedContainer from "../AlliedContainer";
import AlliedPageHeader from "../AlliedPageHeader";
import AlliedFormContainer from "../AlliedFormContainer";
import {Button, Col, Row} from "reactstrap";
import AlliedDatePicker from "../inputs/AlliedDatePicker";
import AlliedSelectInputList from "../inputs/inputList/AlliedSelectInputList";
import {convertCraneDailyInspectionItemsToInputListItems} from "../../utils/convertCraneDailyInspectionItemToInputListItem";
import {getCraneDailyLogInspectionItems} from "../../utils/getCraneDailyLogInspectionItems";
import NumberFormat from "react-number-format";
import AlliedGenericInputList from "../inputs/AlliedGenericInputList";
import DailyLogCommentInput from "../inputs/DailyLogCommentInput";
import {convertCraneDailyLogResponseToUpdateBody} from "../../utils/convertCraneDailyLogResponseToCreateBody";
import {defaultCreateCraneDailyLogForm} from "./CreateCraneDailyLogForm";
import {getCraneTypeDisplay} from "../../utils/getCraneTypeDisplay";
import {isCrane} from "../printables/forms/FinalTestFormPrintable";
import AlliedAutoComplete from "../inputs/AlliedAutoComplete";
import {parseUsersForAutoComplete} from "../../utils/parseUsersForAutoComplete";
import {convertOffsetDate} from "../../utils/timeZoneConversions";

const defaultUpdateCraneDailyLogForm: UpdateCraneDailyLogBody = {
	initials: false,
	day: 0,
	hours: undefined,
	createLogCommentBodies: [{
		flagged: false,
		comment: "",
	}],
}

const validLogDays: Array<string> = ["0", "1", "2", "3", "4", "5", "6"];

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	id?: string;
	day?: string;
}

const UpdateCraneDailyLogForm: React.FC<IProps> = (props) => {

	const history = useHistory();

	const [operatorsList, setOperatorsList] = useState<Array<CraneOperator>>([]);
	const [updateCraneDailyLogForm, setUpdateCraneDailyLogForm] = useState<UpdateCraneDailyLogBody>(defaultUpdateCraneDailyLogForm);
	const [userProfile, setUserProfile] = useState<User>(undefined); // Used for making the user enter their name as a signature (matching the name).
	const [signature, setSignature] = useState(""); // The "signature" as entered by the user; not submitted to api but used to check for the "initials" (boolean) field.
	const [rawEditingResponse, setRawEditingResponse] = useState<CraneDailyLog>(undefined);
	const [rawGroupedLog, setRawGroupedLog] = useState<CraneDailyLogGroup>(undefined);

	useEffect(() => {
		getAutoCompleteValues().then().catch();

		if (props.id && props.day !== undefined) {
			if (validLogDays.indexOf(props.day) < 0) {
				history.replace("/form-history/daily-log-history/crane-daily-log-history");
			} else {
				getFormForEditing().then().catch();
			}
		}
	}, []);

	/**
	 * Call api to get the list of cranes for the auto-complete, and the user's profile for the signature match.
	 *
	 */
	async function getAutoCompleteValues(): Promise<void> {
		props.dispatch(incrementLoading());
		try {
			const operatorsRes = await new UsersApi(getConfig(props.fullToken)).getUserList({
				// type: [UserType.CRANE_OPERATOR],
				limit: 100000,
				offset: 0,
			});
			console.log("operatorsRes:", operatorsRes);

			const userRes = (await new UsersApi(getConfig(props.fullToken)).getProfile()).user;

			setOperatorsList(operatorsRes.users as Array<CraneOperator>);
			setUserProfile(userRes);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Call api to get existing Crane Daily Log if props.id exists, and save it to the form object after formatting correctly.
	 *
	 */
	async function getFormForEditing(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FormsApi(getConfig(props.fullToken)).getCraneDailyLog({
				id: props.id,
			});

			// Grab the correct "day" from the grouped res (index 0 -> 6)
			const singleDayLog: CraneDailyLog = res["_" + props.day];

			if (!isCrane(res.equipment)) {
				props.dispatch(addError({messages: ["There was an error trying to interpret the equipment associated with this log."]}));
				history.replace("/form-history/daily-log-history/crane-daily-log-history");
				return;
			}

			console.log("form to edit:", res);
			setUpdateCraneDailyLogForm(convertCraneDailyLogResponseToUpdateBody(singleDayLog, Number(props.day), res.equipment.craneType));
			setRawEditingResponse(singleDayLog);
			setRawGroupedLog(res);
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * onChange handler for the "Inspection Items" section, which
	 * are displayed dynamically based on the type of crane selected.
	 *
	 * @param key
	 * @param value
	 */
	function onInspectionItemChange(key: keyof UpdateCraneDailyLogBody, value: InspectionStatus): void {
		setUpdateCraneDailyLogForm({
			...updateCraneDailyLogForm,
			[key]: value,
		});
	}

	/**
	 * onChange to help with the single select auto-complete for choosing an employee.
	 *
	 * @param operators
	 */
	function operatorsOnChange(operators: string[]): void {
		setUpdateCraneDailyLogForm({
			...updateCraneDailyLogForm,
			users: operators,
		});
	}

	/**
	 * Dynamic onChange for number inputs using the NumberFormat library.
	 *
	 * @param key
	 */
	function numberFormatOnChange(key: keyof UpdateCraneDailyLogBody): (e) => void {
		return (e) => {
			setUpdateCraneDailyLogForm({
				...updateCraneDailyLogForm,
				[key]: e?.floatValue,
			});
		}
	}

	/**
	 * onChange function for all of the dynamic lists with plus & minus icons.
	 *
	 * @param key
	 */
	function inputListOnChange<T>(key: keyof UpdateCraneDailyLogBody): (i: number, value: T) => void {
		return (i: number, value: T) => {
			const copy: Array<T> = cloneDeep(updateCraneDailyLogForm[key] as unknown as Array<T>);
			copy[i] = value;

			setUpdateCraneDailyLogForm({
				...updateCraneDailyLogForm,
				[key]: copy,
			});
		}
	}

	/**
	 * Add another entry to the array in question.
	 *
	 */
	function inputListOnAdd<T>(key: keyof UpdateCraneDailyLogBody): () => void {
		return () => {
			setUpdateCraneDailyLogForm({
				...updateCraneDailyLogForm,
				[key]: (updateCraneDailyLogForm[key] as unknown as Array<T>).concat([defaultCreateCraneDailyLogForm[key][0]]),
			});
		}
	}

	/**
	 * Remove item at index i from the array in question.
	 *
	 */
	function inputListOnRemove<T>(key: keyof UpdateCraneDailyLogBody): (i: number) => void {
		return (i: number) => {
			const copy: Array<T> = cloneDeep(updateCraneDailyLogForm[key] as unknown as Array<T>);
			copy.splice(i, 1);

			setUpdateCraneDailyLogForm({
				...updateCraneDailyLogForm,
				[key]: copy,
			});
		}
	}

	/**
	 * onChange for the signature field that the user must enter before submitting.
	 *
	 * @param e
	 */
	function onSignatureChange(e: React.ChangeEvent<HTMLInputElement>): void {
		setSignature(e?.target?.value);
	}

	/**
	 * Call api to either submit new Crane Daily Log or update existing and return id.
	 * Checks which type of crane so we know which api to use,
	 * checks if entered signature is correct,
	 * navigate to the form history for crane daily logs on success.
	 *
	 */
	async function saveCraneDailyLog(e?: React.SyntheticEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			const apiBase = new FormsApi(getConfig(props.fullToken));
			const initials: boolean = signature?.toLowerCase() === getFullNameForTable(userProfile)?.toLowerCase(); // Check if the user's entered signature matches their name properly.

			let req: UpdateCraneDailyLogBody = {
				users: updateCraneDailyLogForm.users,
				day: Number(props.day),
				hours: updateCraneDailyLogForm?.hours !== undefined ? updateCraneDailyLogForm?.hours : undefined,
				createLogCommentBodies: updateCraneDailyLogForm?.createLogCommentBodies?.filter((c: CreateLogCommentBody): boolean => {
					return c.comment?.length > 0;
				}).map(c => {
					return {
						...c,
						date: convertOffsetDate(rawGroupedLog.date + (Number(props.day) * 1000 * 60 * 60 * 24)),
					}
				}),
				initials,
				electricalPowerCords: updateCraneDailyLogForm?.electricalPowerCords !== undefined ? updateCraneDailyLogForm?.electricalPowerCords : undefined,
				groundFaultCircuitInterrupter: updateCraneDailyLogForm?.groundFaultCircuitInterrupter !== undefined ? updateCraneDailyLogForm?.groundFaultCircuitInterrupter : undefined,
				onOffSwitch: updateCraneDailyLogForm?.onOffSwitch !== undefined ? updateCraneDailyLogForm?.onOffSwitch : undefined,
				craneBaseInspection: updateCraneDailyLogForm?.craneBaseInspection !== undefined ? updateCraneDailyLogForm?.craneBaseInspection : undefined,
				inspectWalkways: updateCraneDailyLogForm?.inspectWalkways !== undefined ? updateCraneDailyLogForm?.inspectWalkways : undefined,
				inspectStructure: updateCraneDailyLogForm?.inspectStructure !== undefined ? updateCraneDailyLogForm?.inspectStructure : undefined,
				ensureAllDoors: updateCraneDailyLogForm?.ensureAllDoors !== undefined ? updateCraneDailyLogForm?.ensureAllDoors : undefined,
				operatorsControlsAdequate: updateCraneDailyLogForm?.operatorsControlsAdequate !== undefined ? updateCraneDailyLogForm?.operatorsControlsAdequate : undefined,
				loadMomentHoistLimit: updateCraneDailyLogForm?.loadMomentHoistLimit !== undefined ? updateCraneDailyLogForm?.loadMomentHoistLimit : undefined,
				confirmLocationOfZoning: updateCraneDailyLogForm?.confirmLocationOfZoning !== undefined ? updateCraneDailyLogForm?.confirmLocationOfZoning : undefined,
				maximumLoad: updateCraneDailyLogForm?.maximumLoad !== undefined ? updateCraneDailyLogForm?.maximumLoad : undefined,
				hoistUpDecelerationLimit: updateCraneDailyLogForm?.hoistUpDecelerationLimit !== undefined ? updateCraneDailyLogForm?.hoistUpDecelerationLimit : undefined,
				hoistUpperLimit: updateCraneDailyLogForm?.hoistUpperLimit !== undefined ? updateCraneDailyLogForm?.hoistUpperLimit : undefined,
				hoistDownLimit: updateCraneDailyLogForm?.hoistDownLimit !== undefined ? updateCraneDailyLogForm?.hoistDownLimit : undefined,
				ensureAllAudioVisualIndicatorsFunction: updateCraneDailyLogForm?.ensureAllAudioVisualIndicatorsFunction !== undefined ? updateCraneDailyLogForm?.ensureAllAudioVisualIndicatorsFunction : undefined,
				anemometer: updateCraneDailyLogForm?.anemometer !== undefined ? updateCraneDailyLogForm?.anemometer : undefined,
				hoistBrakeFunctioning: updateCraneDailyLogForm?.hoistBrakeFunctioning !== undefined ? updateCraneDailyLogForm?.hoistBrakeFunctioning : undefined,
				slewingBrakeFunctioning: updateCraneDailyLogForm?.slewingBrakeFunctioning !== undefined ? updateCraneDailyLogForm?.slewingBrakeFunctioning : undefined,
				visuallyInspectLoadBlockAndHook: updateCraneDailyLogForm?.visuallyInspectLoadBlockAndHook !== undefined ? updateCraneDailyLogForm?.visuallyInspectLoadBlockAndHook : undefined,
				travelBrakeToRail: updateCraneDailyLogForm?.travelBrakeToRail !== undefined ? updateCraneDailyLogForm?.travelBrakeToRail : undefined,
				railTravelForwardAndReverse: updateCraneDailyLogForm?.railTravelForwardAndReverse !== undefined ? updateCraneDailyLogForm?.railTravelForwardAndReverse : undefined,
				inspectTracksForLoseConnections: updateCraneDailyLogForm?.inspectTracksForLoseConnections !== undefined ? updateCraneDailyLogForm?.inspectTracksForLoseConnections : undefined,
				housekeeping: updateCraneDailyLogForm?.housekeeping !== undefined ? updateCraneDailyLogForm?.housekeeping : undefined,
				supervisorNotedOfAnyDefectsOrFaults: updateCraneDailyLogForm?.supervisorNotedOfAnyDefectsOrFaults !== undefined ? updateCraneDailyLogForm?.supervisorNotedOfAnyDefectsOrFaults : undefined,
			}

			switch ((rawGroupedLog.equipment as Crane)?.craneType) {
				case CraneType.LUFFING_TOWER:
					const luffingCreateBodyCopy: UpdateLuffingTowerCraneDailyLogBody = cloneDeep(updateCraneDailyLogForm);

					req = {
						...req,
						ensureAllTower: luffingCreateBodyCopy?.ensureAllTower !== undefined ? luffingCreateBodyCopy?.ensureAllTower : undefined,
						loadMomentLuffingLimit: luffingCreateBodyCopy?.loadMomentLuffingLimit !== undefined ? luffingCreateBodyCopy?.loadMomentLuffingLimit : undefined,
						luffingUpSwitch: luffingCreateBodyCopy?.luffingUpSwitch !== undefined ? luffingCreateBodyCopy?.luffingUpSwitch : undefined,
						luffingDownSwitch: luffingCreateBodyCopy?.luffingDownSwitch !== undefined ? luffingCreateBodyCopy?.luffingDownSwitch : undefined,
						luffingUpAndDownDecelerationSwitch: luffingCreateBodyCopy?.luffingUpAndDownDecelerationSwitch !== undefined ? luffingCreateBodyCopy?.luffingUpAndDownDecelerationSwitch : undefined,
						boomAngleIndicator: luffingCreateBodyCopy?.boomAngleIndicator !== undefined ? luffingCreateBodyCopy?.boomAngleIndicator : undefined,
						counterweightSafetySwitch: luffingCreateBodyCopy?.counterweightSafetySwitch !== undefined ? luffingCreateBodyCopy?.counterweightSafetySwitch : undefined,
						antiTwoBlockSwitch: luffingCreateBodyCopy?.antiTwoBlockSwitch !== undefined ? luffingCreateBodyCopy?.antiTwoBlockSwitch : undefined,
						luffingBrake: luffingCreateBodyCopy?.luffingBrake !== undefined ? luffingCreateBodyCopy?.luffingBrake : undefined,
					} as UpdateLuffingTowerCraneDailyLogBody;

					await apiBase.updateLuffingTowerCraneDailyLog({
						id: props.id,
						updateLuffingTowerCraneDailyLogBody: req as UpdateLuffingTowerCraneDailyLogBody,
					});

					break;
				case CraneType.SELF_ERECTING:
					const selfErectingCreateBodyCopy: UpdateSelfErectingCraneDailyLogBody = cloneDeep(updateCraneDailyLogForm);

					req = {
						...req,
						loadMomentTrolleyLimit: selfErectingCreateBodyCopy?.loadMomentTrolleyLimit !== undefined ? selfErectingCreateBodyCopy?.loadMomentTrolleyLimit : undefined,
						trolleyOut: selfErectingCreateBodyCopy?.trolleyOut !== undefined ? selfErectingCreateBodyCopy?.trolleyOut : undefined,
						trolleyIn: selfErectingCreateBodyCopy?.trolleyIn !== undefined ? selfErectingCreateBodyCopy?.trolleyIn : undefined,
						trolleyBrake: selfErectingCreateBodyCopy?.trolleyBrake !== undefined ? selfErectingCreateBodyCopy?.trolleyBrake : undefined,
						baseLevel: selfErectingCreateBodyCopy?.baseLevel !== undefined ? selfErectingCreateBodyCopy?.baseLevel : undefined,
						foundationCondition: selfErectingCreateBodyCopy?.foundationCondition !== undefined ? selfErectingCreateBodyCopy?.foundationCondition : undefined,
					} as UpdateSelfErectingCraneDailyLogBody;

					await apiBase.updateSelfErectingCraneDailyLog({
						id: props.id,
						updateSelfErectingCraneDailyLogBody: req as UpdateSelfErectingCraneDailyLogBody,
					});

					break;
				case CraneType.TOWER:
					const towerCreateBodyCopy: UpdateTowerCraneDailyLogBody = cloneDeep(updateCraneDailyLogForm);

					req = {
						...req,
						ensureAllTower: towerCreateBodyCopy?.ensureAllTower !== undefined ? towerCreateBodyCopy?.ensureAllTower : undefined,
						loadMomentTrolleyLimit: towerCreateBodyCopy?.loadMomentTrolleyLimit !== undefined ? towerCreateBodyCopy?.loadMomentTrolleyLimit : undefined,
						trolleyOut: towerCreateBodyCopy?.trolleyOut !== undefined ? towerCreateBodyCopy?.trolleyOut : undefined,
						trolleyIn: towerCreateBodyCopy?.trolleyIn !== undefined ? towerCreateBodyCopy?.trolleyIn : undefined,
						trolleyBrake: towerCreateBodyCopy?.trolleyBrake !== undefined ? towerCreateBodyCopy?.trolleyBrake : undefined,
					} as UpdateTowerCraneDailyLogBody;

					await apiBase.updateTowerCraneDailyLog({
						id: props.id,
						updateTowerCraneDailyLogBody: req as UpdateTowerCraneDailyLogBody,
					});

					break;
				default:
					return;
			}
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Used when creating or updating to save the draft.
	 * Afterwards, navigate to the history page.
	 *
	 * @param e
	 */
	async function saveDraft(e?: React.SyntheticEvent): Promise<void> {
		e?.preventDefault();
		try {
			await saveCraneDailyLog();
			history.push("/form-history/daily-log-history/crane-daily-log-history");
		} catch (e) {

		}
	}

	/**
	 * Util to check if the "Save Draft" button should be enabled
	 *
	 * @param _signature
	 * @param user
	 */
	function checkForSaveButtonEnabled(_signature: string, user: User): boolean {
		return !((_signature?.toLowerCase() === getFullNameForTable(user).toLowerCase()));
	}

	/**
	 * Frontend filtering to get just the operators who exist on the selected site.
	 *
	 * @param operators
	 */
	function getFilteredOperators(operators: Array<CraneOperator>): Array<CraneOperator> {
		return operators.filter(o => {
			const found = o.sites?.find(s => s._id === rawGroupedLog.originalSite._id);
			const forceFindAdmin = o.type === UserType.ADMIN;
			return found || forceFindAdmin;
		});
	}

	if (!rawGroupedLog) {
		return null;
	}

	const selectedCrane: Crane = rawGroupedLog?.equipment as Crane;

	return (
		<AlliedContainer>
			<AlliedPageHeader
				title="Edit Crane Daily Log"
				showTooltip={false}
			/>

			<hr/>

			<AlliedFormContainer>
				<form onSubmit={saveDraft}>
					<Row>
						<Col xs={12} md={6} className="mb-3">
							<label>Editing log for Crane:</label>
							<br/>
							<b>
								{`${selectedCrane.make} ${selectedCrane.model} - ${getCraneTypeDisplay(selectedCrane.craneType)} ${selectedCrane.serialNumber} at ${rawGroupedLog.originalSite.name}`}
							</b>
							<br/>
							<b>
								{moment(rawGroupedLog.date + (Number(props.day) * 1000 * 60 * 60 * 24)).format("dddd MMM Do")}
							</b>
						</Col>
					</Row>

					<hr/>

					<Row>
						<Col xs={12} md={6} className="mb-3">
							<AlliedSelectInputList
								key={JSON.stringify(rawEditingResponse)}
								title="Inspection Items"
								emptyMessage="Please select a crane first."
								inputs={
									convertCraneDailyInspectionItemsToInputListItems(
										getCraneDailyLogInspectionItems(selectedCrane?.craneType),
										updateCraneDailyLogForm,
									)}
								onChange={onInspectionItemChange}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Operators for Selected Date</label>
							<AlliedAutoComplete
								placeholder="Select Operators..."
								options={parseUsersForAutoComplete(getFilteredOperators(operatorsList))}
								selections={updateCraneDailyLogForm.users}
								setSelections={operatorsOnChange}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Usage Hours</label>
							<NumberFormat
								allowLeadingZeros={false}
								placeholder="Enter Usage Hours Today..."
								value={updateCraneDailyLogForm.hours}
								onValueChange={numberFormatOnChange("hours")}
								allowNegative={false}
								decimalScale={2}
							/>
						</Col>
					</Row>

					{(props.id !== undefined && rawEditingResponse?.comments?.length > 0) && (
						<Row>
							<Col xs={12}>
								<h5 className="font-weight-bold">Previous Comments</h5>
								<h6>(Cannot be edited)</h6>
							</Col>
							<Col xs={12} md={6} className="mb-3">
								<AlliedGenericInputList
									values={rawEditingResponse?.comments}
									component={DailyLogCommentInput}
									forwardedProps={{radioNameSuffix: "-previous"}}
								/>
							</Col>
						</Row>
					)}

					<Row>
						<Col xs={12}>
							<h5 className="font-weight-bold">Comments</h5>
						</Col>
						<Col xs={12} md={6} className="mb-3">
							<AlliedGenericInputList
								values={updateCraneDailyLogForm.createLogCommentBodies}
								onChange={inputListOnChange<CreateLogCommentBody>("createLogCommentBodies")}
								onAdd={inputListOnAdd("createLogCommentBodies")}
								onRemove={inputListOnRemove("createLogCommentBodies")}
								component={DailyLogCommentInput}
							/>
						</Col>
					</Row>

					<hr/>

					<Row>
						<Col xs={12}>
							<label>Enter full name to confirm this report is accurate.</label>
						</Col>
					</Row>

					<Row>
						<Col xs={12} sm={6} md={4} lg={3} className="mb-3">
							<input
								placeholder="Enter Signature..."
								value={signature}
								onChange={onSignatureChange}
							/>
						</Col>
						<Col xs={12} sm={6} md={4} lg={3} className="mb-3 user-select-none">
							<input
								disabled={true}
								placeholder="Loading..."
								value={getFullNameForTable(userProfile)}
								className="user-select-none pointer-events-none"
							/>
						</Col>
					</Row>

					<div className="d-flex flex-column align-items-center flex-sm-row">
						<Button
							color="black"
							disabled={checkForSaveButtonEnabled(signature, userProfile)}
							type="submit"
							onClick={saveDraft}
							className="mr-0 mr-sm-3 mb-3 mb-sm-0"
						>
							Update Draft
						</Button>
					</div>
				</form>
			</AlliedFormContainer>
		</AlliedContainer>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(UpdateCraneDailyLogForm);
