import React, {useState} from "react";
import {SiteWithUsersAndEquipment, Token, User, UsersByType, UserType} from "client";
import AlliedTextButton from "../../AlliedTextButton";
import ManageSitesEquipmentModalAdminView from "../../modals/ManageSitesEquipmentModalAdminView";
import {connect} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import ManageSitesEquipmentModalStandardView from "../../modals/ManageSitesEquipmentModalStandardView";
import ManageSitesUsersModal from "../../modals/ManageSitesUsersModal";

interface IProps {
	users: Array<User>;
	userType: keyof UsersByType;
	modalTitle: string;
}

const ManageSitesUsersCell: React.FC<IProps> = (props) => {

	const [showUsersModal, setShowUsersModal] = useState(false);

	function toggleUsersModal(): void {
		setShowUsersModal(!showUsersModal);
	}

	return (
		<div className="d-flex justify-content-end">
			<ManageSitesUsersModal
				isOpen={showUsersModal}
				users={props.users}
				userType={props.userType}
				title={props.modalTitle}
				onClose={toggleUsersModal}
			/>

			{props.users?.length > 0 ? (
				<AlliedTextButton onClick={toggleUsersModal}>
					{`${props.users?.length} (view)`}
				</AlliedTextButton>
			) : (
				<span>No Users.</span>
			)}
		</div>
	);
};

export default ManageSitesUsersCell;