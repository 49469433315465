import {UserType} from "client";

export interface INavLinkEntry {
	label: string;
	link: string;
}

export interface INavLinkGroup {
	title: string;
	entries: Array<INavLinkGroup | INavLinkEntry>;
}

const adminLinks: Array<INavLinkGroup> = [
	{
		title: "Equipment Options",
		entries: [
			{
				label: "Create New Equipment",
				link: "/equipment-options/create-new-equipment",
			},
			{
				label: "Equipment Location Form",
				link: "/equipment-options/equipment-location-form",
			},
			{
				title: "Manage Equipment",
				entries: [
					{
						label: "Manage Cranes",
						link: "/equipment-options/manage-equipment/manage-cranes",
					},
					{
						label: "Manage Equipment",
						link: "/equipment-options/manage-equipment/manage-equipment",
					},
					{
						label: "Manage Material Hoists",
						link: "/equipment-options/manage-equipment/manage-material-hoists",
					},
				],
			},
		],
	},
	{
		title: "User Options",
		entries: [
			{
				label: "Create New User",
				link: "/user-options/create-new-user",
			},
			{
				label: "Manage Users",
				link: "/user-options/manage-users",
			},
		],
	},
	{
		title: "Sites Options",
		entries: [
			{
				label: "Create New Site",
				link: "/sites-options/create-new-site",
			},
			{
				label: "Manage Sites",
				link: "/sites-options/manage-sites",
			},
		],
	},
	{
		title: "Allied Crane Employee Forms",
		entries: [
			{
				label: "Service Reports",
				link: "/allied-crane-employee-forms/service-reports",
			},
			{
				label: "Time Sheets",
				link: "/allied-crane-employee-forms/time-sheets",
			},
			{
				label: "Final Test Forms",
				link: "/allied-crane-employee-forms/final-test-forms",
			},
		],
	},
	{
		title: "Crane Operator Forms",
		entries: [
			{
				label: "Create Crane Daily Log",
				link: "/create-forms/create-daily-log/create-crane-daily-log",
			},
			{
				label: "Create Crane Weekly Log",
				link: "/create-forms/create-crane-weekly-log",
			},
			{
				label: "Create Crane Monthly Log",
				link: "/create-forms/create-crane-monthly-log",
			},
			{
				title: "Daily Logs",
				entries: [
					{
						label: "Crane Daily Logs",
						link: "/form-history/daily-log-history/crane-daily-log-history",
					},
					{
						label: "Generator Daily Logs",
						link: "/form-history/daily-log-history/equipment-daily-log-history",
					},
				],
			},
			{
				label: "Crane Weekly Logs",
				link: "/form-history/weekly-log-history/crane-weekly-log-history",
			},
			{
				label: "Crane Monthly Logs",
				link: "/form-history/monthly-log-history/crane-monthly-log-history",
			},
		],
	},
];

const alliedCraneEmployeeLinks: Array<INavLinkGroup> = [
	{
		title: "Manage Equipment",
		entries: [
			{
				label: "Crane Deficiencies",
				link: "/manage-equipment/crane-deficiencies",
			},
			{
				label: "Generator Deficiencies",
				link: "/manage-equipment/generator-deficiencies",
			},
			{
				label: "Material Hoist Deficiencies",
				link: "/manage-equipment/material-hoist-deficiencies",
			},
		],
	},
	{
		title: "Sites Options",
		entries: [
			{
				label: "View Sites",
				link: "/sites-options/view-sites",
			},
		],
	},
	{
		title: "Create Forms",
		entries: [
			{
				label: "Create Crane Daily Log",
				link: "/create-forms/create-daily-log/create-crane-daily-log",
			},
			{
				label: "Create Crane Weekly Log",
				link: "/create-forms/create-crane-weekly-log",
			},
			{
				label: "Create Crane Monthly Log",
				link: "/create-forms/create-crane-monthly-log",
			},
			{
				label: "Create Service Report",
				link: "/create-forms/create-service-report",
			},
			{
				label: "Create Time Sheet",
				link: "/create-forms/create-time-sheet",
			},
			{
				label: "Create Final Test Form",
				link: "/create-forms/create-final-test-form",
			},
		],
	},
	{
		title: "Form History",
		entries: [
			{
				label: "Crane Daily Log History",
				link: "/form-history/daily-log-history/crane-daily-log-history",
			},
			{
				label: "Crane Weekly Log History",
				link: "/form-history/weekly-log-history/crane-weekly-log-history",
			},
			{
				label: "Crane Monthly Log History",
				link: "/form-history/monthly-log-history/crane-monthly-log-history",
			},
			{
				label: "Service Reports History",
				link: "/form-history/service-reports-history",
			},
			{
				label: "Time Sheets History",
				link: "/form-history/time-sheets-history",
			},
			{
				label: "Final Test Forms History",
				link: "/form-history/final-test-forms-history",
			},
		],
	},
];

const craneOperatorLinks: Array<INavLinkGroup> = [
	{
		title: "Sites Options",
		entries: [
			{
				label: "View Sites",
				link: "/sites-options/view-sites",
			},
		],
	},
	{
		title: "Create Forms",
		entries: [
			{
				title: "Create Daily Log",
				entries: [
					{
						label: "Create Crane Daily Log",
						link: "/create-forms/create-daily-log/create-crane-daily-log",
					},
					{
						label: "Create Generator Daily Log",
						link: "/create-forms/create-daily-log/create-equipment-daily-log",
					},
				],
			},
			{
				label: "Create Crane Weekly Log",
				link: "/create-forms/create-crane-weekly-log",
			},
			{
				label: "Create Crane Monthly Log",
				link: "/create-forms/create-crane-monthly-log",
			},
		],
	},
	{
		title: "Form History",
		entries: [
			{
				title: "Daily Log History",
				entries: [
					{
						label: "Crane Daily Log History",
						link: "/form-history/daily-log-history/crane-daily-log-history",
					},
					{
						label: "Generator Daily Log History",
						link: "/form-history/daily-log-history/equipment-daily-log-history",
					},
				],
			},
			{
				label: "Crane Weekly Log History",
				link: "/form-history/weekly-log-history/crane-weekly-log-history",
			},
			{
				label: "Crane Monthly Log History",
				link: "/form-history/monthly-log-history/crane-monthly-log-history",
			},
		],
	},
];

const healthAndSafetyLinks: Array<INavLinkGroup> = [
	{
		title: "Create Forms",
		entries: [
			{
				label: "Create Crane Daily Log",
				link: "/create-forms/create-daily-log/create-crane-daily-log",
			},
			{
				label: "Create Crane Weekly Log",
				link: "/create-forms/create-crane-weekly-log",
			},
			{
				label: "Create Crane Monthly Log",
				link: "/create-forms/create-crane-monthly-log",
			},
		],
	},
	{
		title: "View Forms",
		entries: [
			{
				title: "Daily Logs",
				entries: [
					{
						label: "Crane Daily Logs",
						link: "/form-history/daily-log-history/crane-daily-log-history",
					},
					{
						label: "Generator Daily Logs",
						link: "/form-history/daily-log-history/equipment-daily-log-history",
					},
				],
			},
			{
				label: "Crane Weekly Logs",
				link: "/form-history/weekly-log-history/crane-weekly-log-history",
			},
			{
				label: "Crane Monthly Logs",
				link: "/form-history/monthly-log-history/crane-monthly-log-history",
			},
			{
				label: "Service Reports",
				link: "/allied-crane-employee-forms/service-reports",
			},
		],
	},
];

const managerLinks: Array<INavLinkGroup> = [
	{
		title: "User Options",
		entries: [
			{
				label: "Create New User",
				link: "/user-options/create-new-user",
			},
			{
				label: "Manage Users",
				link: "/user-options/manage-users",
			}
		],
	},
	{
		title: "Site Options",
		entries: [
			{
				label: "Manage Sites",
				link: "/sites-options/manage-sites",
			},
		],
	},
	{
		title: "Create Forms",
		entries: [
			{
				label: "Create Crane Daily Log",
				link: "/create-forms/create-daily-log/create-crane-daily-log",
			},
			{
				label: "Create Crane Weekly Log",
				link: "/create-forms/create-crane-weekly-log",
			},
			{
				label: "Create Crane Monthly Log",
				link: "/create-forms/create-crane-monthly-log",
			},
		],
	},
	{
		title: "View Forms",
		entries: [
			{
				title: "Daily Logs",
				entries: [
					{
						label: "Crane Daily Logs",
						link: "/form-history/daily-log-history/crane-daily-log-history",
					},
					{
						label: "Generator Daily Logs",
						link: "/form-history/daily-log-history/equipment-daily-log-history",
					},
				],
			},
			{
				label: "Crane Weekly Logs",
				link: "/form-history/weekly-log-history/crane-weekly-log-history",
			},
			{
				label: "Crane Monthly Logs",
				link: "/form-history/monthly-log-history/crane-monthly-log-history",
			},
			{
				label: "Service Reports",
				link: "/allied-crane-employee-forms/service-reports",
			},
		],
	},
];

/**
 * Returns the appropriate list of nav links for the specified user type.
 *
 * @param tokenType
 */
export function getUserTypeNavLinks(tokenType: UserType): Array<INavLinkGroup> {
	switch(tokenType) {
		case UserType.ADMIN:
			return adminLinks;
		case UserType.ALLIED_CRANE_EMPLOYEE:
			return alliedCraneEmployeeLinks;
		case UserType.CRANE_OPERATOR:
			return craneOperatorLinks;
		case UserType.HEALTH_AND_SAFETY_OFFICER:
			return healthAndSafetyLinks;
		case UserType.SECONDARY_ADMIN:
			return managerLinks;
		case UserType.SUPERVISOR:
			return managerLinks;
		default:
			return [];
	}
}
