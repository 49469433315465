import React, {ReactElement, ReactNode} from "react";
import {DeficiencyStatus, Site} from "client";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button, Col, Row} from "reactstrap";
import AlliedDatePicker from "../inputs/AlliedDatePicker";
import AlliedAutoComplete from "../inputs/AlliedAutoComplete";
import {parseSitesForAutoComplete} from "../../utils/parseSitesForAutoComplete";
import {parseSiteAddressForSingleInput} from "../../utils/parseSiteAddressForSingleInput";
import {find} from "lodash";
import AlliedSelect from "../inputs/AlliedSelect";
import {parseDeficiencyStatusForDisplay} from "../../utils/parseDeficiencyStatusForDisplay";
import AlliedModal from "./AlliedModal";
import AlliedTableContainer from "../tables/AlliedTableContainer";
import {getFullNameForUserTable} from "../../utils/getFullNameForTable";
import AlliedInputCheckbox from "../inputs/AlliedInputCheckbox";

interface Props {
	isOpen: boolean;
	sites: Array<Site>;
	selectedSites: string[];
	onChange: (selectedSites: string[]) => void;
	onClose: () => void;
}

function CreateUserSelectSitesModal({isOpen, sites, selectedSites, onChange, onClose}: Props): ReactElement {

	function renderCheckBox(site: Site): ReactNode {
		function toggleHelper(): void {
			const newSites = [...selectedSites];

			const newSiteIndex = newSites.indexOf(site._id);
			if (newSiteIndex < 0) {
				newSites.push(site._id);
				onChange(newSites);
				return;
			}

			newSites.splice(newSiteIndex, 1);
			onChange(newSites);
		}

		return (
			<div className="create-user-select-sites-modal_check-con">
				<AlliedInputCheckbox
					checked={selectedSites.indexOf(site._id) > -1}
					onToggle={toggleHelper}
				>
					Assign
				</AlliedInputCheckbox>
			</div>
		);
	}

	return (
		<AlliedModal
			size="md"
			isOpen={isOpen}
		>
			<AlliedModalHeader
				title="Configure default sites for new user"
				onClose={onClose}
			/>

			<div className="create-user-select-sites-modal">
				<hr/>

				<AlliedTableContainer
					data={sites}
					showPaginator={false}
					columnOptions={[
						{
							headerValue: "Site",
							key: "name",
							rowCellClassName: "font-weight-bold",
						},
						{
							headerValue: "",
							key: undefined,
							cellRender: renderCheckBox,
						}
					]}
				/>

				<div className="mt-3 d-flex justify-content-end">
					<Button
						color="black"
						onClick={onClose}
					>
						Confirm
					</Button>
				</div>
			</div>
		</AlliedModal>
	);
}

export default CreateUserSelectSitesModal;