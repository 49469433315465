import React from "react";
import {Button} from "reactstrap";
import {TimeSheet} from "client";
import ViewTimeSheetButton from "../../printables/buttons/ViewTimeSheetButton";

interface IProps {
	timeSheet: TimeSheet;
}

const ManageTimeSheetsViewCell: React.FC<IProps> = (props) => {

	return (
		<div className="allied-table-cell">
			<ViewTimeSheetButton id={props.timeSheet?._id}>
				View Time Sheet
			</ViewTimeSheetButton>
		</div>
	);
};

export default ManageTimeSheetsViewCell;
