import React, {ReactNode, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {FormsApi, GeneratorDailyLog, GeneratorDailyLogGroup, Token} from "client";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {useHistory} from "react-router-dom";
import getConfig from "../../utils/getConfig";
import AlliedTableHeaderCell from "../tables/cells/AlliedTableHeaderCell";
import {parseDateForTableDisplay} from "../../utils/parseDateForTableDisplay";
import {valueOrBust} from "../../utils/valueOrBust";
import {parseInspectionStatus, parseInspectionStatusTableHelper} from "../../utils/parseInspectionStatus";
import AlliedTableContainer from "../tables/AlliedTableContainer";
import {TableData} from "frame-one-table/build/contextTypes";
import {ColumnOption} from "frame-one-table/build/TableGenerator";
import GeneratorDailyLogsEditIndividualLogCell from "../tables/cells/GeneratorDailyLogsEditIndividualLogCell";
import GeneratorDailyLogsManagerAndSupervisorEditIndividualLogCell
	from "../tables/cells/GeneratorDailyLogsManagerAndSupervisorEditIndividualLogCell";
import ViewGeneratorDailyLogButton from "../printables/buttons/ViewGeneratorDailyLogButton";
import ConfirmUnlockLogsModal from "./ConfirmUnlockLogsModal";
import {
	addDatesToEachIndividualGeneratorDailyLogWithinGroup,
	GeneratorDailyLogWithDate
} from "../../utils/getDateOfIndividualDailyLogBasedOnDay";
import {getOperatorsOnSingleDailyLog} from "../../utils/getAllOperatorsFromGroupedDailyLogs";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	logGroup: GeneratorDailyLogGroup;
	onClose: () => void;
	onDone: () => void;
}

const GeneratorDailyLogsManagerAndSupervisorOptionsModal: React.FC<IProps> = (props) => {

	const [showConfirmUnlockLogsModal, setShowConfirmUnlockLogsModal] = useState(false);

	function toggleConfirmUnlockLogsModal(): void {
		setShowConfirmUnlockLogsModal(!showConfirmUnlockLogsModal);
	}

	/**
	 * Call api to approve the Generator Daily Logs,
	 * and call onDone function from props after to update table.
	 *
	 */
	async function onApproveGeneratorDailyLogs(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new FormsApi(getConfig(props.fullToken)).publishGeneratorDailyLog({
				id: props.logGroup?._id,
			});
			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Call api to mark group of logs as un-submitted, removing them from the user's view, and making them editable
	 * again by the originally submitting user.
	 *
	 */
	async function confirmUnlockLogs(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new FormsApi(getConfig(props.fullToken)).unSubmitForm({
				iDBody: {
					// Pass the id of any of the entries to submit the whole group.
					id: props.logGroup?._id,
				}
			});

			setShowConfirmUnlockLogsModal(false);
			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Renderer of the Edit Log column.
	 *
	 * @param log
	 */
	function makeEditCell(log: GeneratorDailyLogWithDate): ReactNode {
		return (
			<GeneratorDailyLogsManagerAndSupervisorEditIndividualLogCell
				log={log}
				logGroup={props.logGroup}
			/>
		);
	}

	return (
		<React.Fragment>
			<ConfirmUnlockLogsModal
				isOpen={showConfirmUnlockLogsModal}
				onClose={toggleConfirmUnlockLogsModal}
				onConfirm={confirmUnlockLogs}
			/>

			<AlliedModal
				isOpen={props.isOpen}
				size="xl"
			>
				<AlliedModalHeader
					title="Generator Daily Log Options"
					onClose={props.onClose}
				/>

				<hr/>

				<p>
					*Note that you can only edit the form <b>before</b> it's approved.
				</p>

				<div className="d-flex flex-row flex-wrap align-items-start">
					<Button
						color="red"
						onClick={onApproveGeneratorDailyLogs}
						disabled={props.logGroup?.publishedDate !== undefined}
						className="mb-3 mr-3"
					>
						Approve Logs
					</Button>

					<ViewGeneratorDailyLogButton
						groupedGeneratorLogs={props.logGroup}
						className="mb-3 mr-3"
					>
						View Logs
					</ViewGeneratorDailyLogButton>

					{props.logGroup?.publishedDate === undefined && (
						<Button
							color="black"
							onClick={toggleConfirmUnlockLogsModal}
							className="mb-3"
						>
							Unlock Logs
						</Button>
					)}
				</div>

				<hr className="mt-0"/>

				<AlliedTableContainer
					data={addDatesToEachIndividualGeneratorDailyLogWithinGroup(props.logGroup)}
					columnOptions={[
						{
							headerValue: "Date",
							key: "date",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: parseDateForTableDisplay,
						},
						{
							headerValue: "Hours",
							key: "hours",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: valueOrBust,
						},
						{
							headerValue: "Operator(s)",
							key: "users",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: (value, cellContextDetails) => getOperatorsOnSingleDailyLog(cellContextDetails.row as GeneratorDailyLog),
						},
						{
							headerValue: "Hour Meter Start",
							key: "hourMeterStart",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: valueOrBust,
						},
						{
							headerValue: "Hour Meter End",
							key: "hourMeterEnd",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: valueOrBust,
						},
						{
							headerValue: "Hour Meter Total",
							key: "hourMeterTotal",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: valueOrBust,
						},
						{
							headerValue: "Check Oil Level",
							key: "checkOilLevel",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: parseInspectionStatusTableHelper("checkOilLevel"),
						},
						{
							headerValue: "Check Coolant Levels",
							key: "checkCoolantLevels",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: parseInspectionStatusTableHelper("checkCoolantLevels"),
						},
						{
							headerValue: "Visual Inspection for Wear",
							key: "visualInspectionForWear",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: parseInspectionStatusTableHelper("visualInspectionForWear"),
						},
						{
							headerValue: "Check for Leakage",
							key: "checkForLeakage",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: parseInspectionStatusTableHelper("checkForLeakage"),
						},
						{
							headerValue: "Ensure Door Panels and Covers are Closed",
							key: "ensureDoorPanelsAndCoversAreClosed",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: parseInspectionStatusTableHelper("ensureDoorPanelsAndCoversAreClosed"),
						},
						// {
						// 	headerValue: "Certification Number",
						// 	key: "certificateNumber",
						// 	showSortIcons: false,
						// 	sortable: true,
						// 	headerRender: AlliedTableHeaderCell,
						// 	valueFormatter: valueOrBust,
						// },
						{
							headerValue: "Edit Log",
							key: undefined,
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							cellRender: makeEditCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
					]}
				/>
			</AlliedModal>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(GeneratorDailyLogsManagerAndSupervisorOptionsModal);
