import React from "react";
import {Crane} from "client";
import QRCode from "react-qr-code";

interface Props {
	form: Crane;
}

const CraneQrCodePrintable: React.FC<Props> = ({form}: Props) =>  {
	return (
		<div className="crane-qr-printable">
			<div className="crane-qr-printable_text">
				<h4 className="crane-qr-printable_text_info">
					Crane Operator Self-Registration
				</h4>
				<h1 className="crane-qr-printable_text_crane-details">
					{form?.make + " " + form?.model + " - " + form?.serialNumber}
				</h1>
			</div>

			<div className="crane-qr-printable_qr">
				<QRCode
					style={{ height: "auto", maxWidth: "100%", width: "100%" }}
					value={`${process.env.REACT_APP_WEB_URL}/operator-registration/${form?._id}`}
					viewBox={`0 0 256 256`}
				/>
			</div>

			<div className="crane-qr-printable_access">
				<h2>Access Code</h2>
				<h1>{form?._id.slice(-6)}</h1>
			</div>

			<div className="crane-qr-printable_under">
				<h4 className="crane-qr-printable_under_instructions">
					To gain access to the web portal, scan the QR code above & fill out the form.
					<br/>
					If you have any issues, please contact the supervisor.
				</h4>
			</div>
		</div>
	);
}

export default CraneQrCodePrintable;