/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const CraneDailyLogSortField = {
    publishedDate: 'publishedDate',
    submittedDate: 'submittedDate',
    date: 'date',
    initials: 'initials',
    createdAt: 'createdAt',
    updatedAt: 'updatedAt'
};
export function CraneDailyLogSortFieldFromJSON(json) {
    return CraneDailyLogSortFieldFromJSONTyped(json, false);
}
export function CraneDailyLogSortFieldFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function CraneDailyLogSortFieldToJSON(value) {
    return value;
}
