import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Equipment, EquipmentApi, EquipmentType, Site, SitesApi, Token} from "client";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button, Col, Row} from "reactstrap";
import AlliedAutoComplete from "../inputs/AlliedAutoComplete";
import {parseUsersForAutoComplete} from "../../utils/parseUsersForAutoComplete";
import AutoCompleteListDisplay from "../inputs/AutoCompleteListDisplay";
import {parseEquipmentForAutoComplete} from "../../utils/parseEquipmentForAutoComplete";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	site: Site;
	onClose: () => void;
	onDone: () => void;
}

const ManageSitesAddEquipmentModal: React.FC<IProps> = (props) => {

	// Selected auto-complete values.
	const [selectedCranes, setSelectedCranes] = useState<string[]>([]);
	const [selectedGenerators, setSelectedGenerators] = useState<string[]>([]);
	const [selectedHoists, setSelectedHoists] = useState<string[]>([]);

	// Api responses for the auto-complete inputs, these only get set once.
	const [apiCranes, setApiCranes] = useState<Array<Equipment>>(undefined);
	const [apiGenerators, setApiGenerators] = useState<Array<Equipment>>(undefined);
	const [apiHoists, setApiHoists] = useState<Array<Equipment>>(undefined);
	useEffect(() => {
		if (props.isOpen) {
			getAutoCompleteValues().then().catch();
		}
	}, [props.isOpen]);

	/**
	 * Call api to get the auto-complete list for the different types of equipment.
	 *
	 */
	async function getAutoCompleteValues(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const equipmentApiBase = new EquipmentApi(getConfig(props.fullToken));

			const cranes = await equipmentApiBase.getEquipmentList({
				limit: 10000,
				offset: 0,
				type: [EquipmentType.CRANE],
			});

			const generators = await equipmentApiBase.getEquipmentList({
				limit: 10000,
				offset: 0,
				type: [EquipmentType.GENERATOR],
			});

			const hoists = await equipmentApiBase.getEquipmentList({
				limit: 10000,
				offset: 0,
				type: [EquipmentType.MATERIAL_HOIST],
			});

			setApiCranes(cranes.equipment);
			setApiGenerators(generators.equipment);
			setApiHoists(hoists.equipment);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Clear any entered values and call onClose from props.
	 *
	 */
	function resetAndClose(): void {
		setSelectedCranes([]);
		setSelectedGenerators([]);
		setSelectedHoists([]);
		props.onClose();
	}

	async function addEquipmentToSite(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new SitesApi(getConfig(props.fullToken)).moveEquipmentToSite({
				moveEquipmentToSiteBody: {
					siteID: props.site?._id,
					equipmentIDs: [...selectedCranes, ...selectedGenerators, ...selectedHoists],
				},
			});

			setSelectedCranes([]);
			setSelectedGenerators([]);
			setSelectedHoists([]);
			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<AlliedModal
			isOpen={props.isOpen}
			size="lg"
		>
			<AlliedModalHeader
				title={`Add Equipment to ${props.site?.name}`}
				onClose={resetAndClose}
				onBack={resetAndClose}
			/>

			<hr/>

			<Row>
				<Col xs={12} className="mb-3">
					<label>Assign Crane(s)</label>
					<AlliedAutoComplete
						placeholder="Select Crane(s)..."
						options={parseEquipmentForAutoComplete(apiCranes)}
						selections={selectedCranes}
						setSelections={setSelectedCranes}
					/>
				</Col>

				<Col xs={12} className="mb-3">
					<label>Assign Generator(s)</label>
					<AlliedAutoComplete
						placeholder="Select Generator(s)..."
						options={parseEquipmentForAutoComplete(apiGenerators)}
						selections={selectedGenerators}
						setSelections={setSelectedGenerators}
					/>
				</Col>

				<Col xs={12} className="mb-3">
					<label>Assign Material Hoist(s)</label>
					<AlliedAutoComplete
						placeholder="Select Material Hoist(s)..."
						options={parseEquipmentForAutoComplete(apiHoists)}
						selections={selectedHoists}
						setSelections={setSelectedHoists}
					/>
				</Col>
			</Row>

			<div>
				<Button
					color="black"
					onClick={addEquipmentToSite}
				>
					Add Equipment
				</Button>
			</div>
		</AlliedModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageSitesAddEquipmentModal);

