import {CreateServiceReportBody, CreateServiceReportEntryBody, ServiceReport, ServiceReportEntry} from "client";
import {defaultCreateServiceReportBody} from "../components/forms/CreateServiceReportForm";
import {generateOffsetDate} from "./timeZoneConversions";
import {roundMomentObjectMinutes} from "./roundMomentObjectMinutes";
import moment from "moment";

/**
 * Takes a Service Report and converts it to the interface for the form page.
 * This is intended to be used when editing an existing Service Report.
 *
 * Add the default arrays back to the body for editing if they don't exist on the response
 * (workPerformed, createLunchBreakerBodies, partsAndSuppliesArray).
 *
 * @param res
 */
export function convertServiceReportResponseToCreateBody(res: ServiceReport): CreateServiceReportBody {
	return {
		equipmentID: res.equipment?._id,
		siteID: res.originalSite?._id,
		date: res.date !== undefined ? generateOffsetDate(res.date) : undefined,
		customer: res.customer,
		serviceRequestedBy: res.serviceRequestedBy,
		usageHours: res.usageHours,
		reportedIssue: res.reportedIssue,
		workPerformed: res.workPerformed?.length > 0 ? res.workPerformed : defaultCreateServiceReportBody.workPerformed,

		createServiceReportEntryBodies: res.entries?.length > 0 ? res.entries?.map((entry: ServiceReportEntry): CreateServiceReportEntryBody => {
			return {
				alliedCraneEmployeeID: entry?.alliedCraneEmployee?._id,
				calledOut: entry?.calledOut !== undefined ? generateOffsetDate(entry.calledOut) : roundMomentObjectMinutes(moment(), 5).valueOf(),
				arrivalToSite: entry?.arrivalToSite !== undefined ? generateOffsetDate(entry.arrivalToSite) : roundMomentObjectMinutes(moment(), 5).valueOf(),
				leftJobSite: entry?.leftJobSite !== undefined ? generateOffsetDate(entry.leftJobSite) : roundMomentObjectMinutes(moment(), 5).valueOf(),
				returnTime: entry?.returnTime !== undefined ? generateOffsetDate(entry.returnTime) : roundMomentObjectMinutes(moment(), 5).valueOf(),
				standByTime: entry?.standByTime,
				standByReason: entry?.standByReason || "",
				sourcePartsTime: entry?.sourcePartsTime,
				sourcePartsReason: entry?.sourcePartsReason || "",
				lunchBreak: entry?.lunchBreak,
				lunchBreakStartTime: entry?.lunchBreakStartTime !== undefined ? generateOffsetDate(entry.lunchBreakStartTime) : roundMomentObjectMinutes(moment(), 5).valueOf(),
				lunchBreakEndTime: entry?.lunchBreakEndTime !== undefined ? generateOffsetDate(entry.lunchBreakEndTime) : roundMomentObjectMinutes(moment(), 5).valueOf(),
			}
		}) : defaultCreateServiceReportBody.createServiceReportEntryBodies,

		partsAndSuppliesArray: res.partsAndSuppliesArray?.length > 0 ? res.partsAndSuppliesArray : defaultCreateServiceReportBody.partsAndSuppliesArray,
		additionalServiceRequired: res.additionalServiceRequired,
		repEmail: res.repEmail,
		authorizedRepresentative: res.authorizedRepresentative,
	};
}
