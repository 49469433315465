import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {
	Deficiency,
	FormsApi,
	GetDeficiencyListForEquipmentRequest,
	GetDeficiencyListForEquipmentResponse,
	Token
} from "client";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {RouteComponentProps} from "react-router";
import {cleanRequestForAPISubmission} from "../../utils/cleanRequestForAPISubmission";
import AlliedContainer from "../../components/AlliedContainer";
import AlliedPageHeader from "../../components/AlliedPageHeader";
import AlliedTableContainer from "../../components/tables/AlliedTableContainer";
import AlliedTableHeaderCell from "../../components/tables/cells/AlliedTableHeaderCell";
import {parseDateForTableDisplay} from "../../utils/parseDateForTableDisplay";
import {getFullNameForTable} from "../../utils/getFullNameForTable";
import {parseDeficiencyStatusForDisplay} from "../../utils/parseDeficiencyStatusForDisplay";
import {TableData} from "frame-one-table/build/contextTypes";
import {ColumnOption} from "frame-one-table/build/TableGenerator";
import GeneralDeficiencyEditIssueCell from "../../components/tables/cells/GeneralDeficiencyEditIssueCell";
import {parseEquipmentForDeficienciesTitle,} from "../../utils/parseEquipmentForAutoComplete";
import {Button} from "reactstrap";
import CreateGeneralDeficiencyIssueModal from "../../components/modals/CreateGeneralDeficiencyIssueModal";
import moment from "moment";
import GeneralDeficiencyFilterModal from "../../components/modals/GeneralDeficiencyFilterModal";
import GeneralDeficiencyResolveIssueCell from "../../components/tables/cells/GeneralDeficiencyResolveIssueCell";
import ViewDeficienciesButton from "../../components/printables/buttons/ViewDeficienciesButton";
import {convertOffsetDate} from "../../utils/timeZoneConversions";

interface IProps extends RouteComponentProps {
	dispatch?: any;
	fullToken?: Token;
	id?: string;
}

const defaultFilter: GetDeficiencyListForEquipmentRequest = {
	limit: 10000,
	offset: 0,
	equipment: "",
	from: moment({year: 2021}).startOf("day").valueOf(),
	to: moment().endOf("day").valueOf(),
}

const GeneralDeficiencyReports: React.FC<IProps> = (props) => {

	const params: URLSearchParams = new URLSearchParams(props.location?.search);
	const id: string = params.get("id");

	const [showFilterModal, setShowFilterModal] = useState(false);
	const [showAddModal, setShowAddModal] = useState(false);
	const [filter, setFilter] = useState<GetDeficiencyListForEquipmentRequest>({...defaultFilter, equipment: id});
	const [deficienciesResponse, setDeficienciesResponse] = useState<GetDeficiencyListForEquipmentResponse>(undefined);

	useEffect(() => {
		getDeficiencies().then().catch();
	}, [JSON.stringify(filter)]);

	/**
	 * Call api to get deficiencies list & equipment info based on id.
	 *
	 */
	async function getDeficiencies(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FormsApi(getConfig(props.fullToken)).getDeficiencyListForEquipment(cleanRequestForAPISubmission({
				...filter,
				from: convertOffsetDate(filter?.from),
				to: convertOffsetDate(filter?.to),
			} as GetDeficiencyListForEquipmentRequest));
			setDeficienciesResponse(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Toggles visibility of the filter modal.
	 *
	 */
	function toggleFilterModal(): void {
		setShowFilterModal(!showFilterModal);
	}

	/**
	 * When finished the filter modal, save the new filter (will prompt api via useEffect),
	 * and hide the filter modal.
	 *
	 * @param newFilter
	 */
	function onDoneFilterModal(newFilter: GetDeficiencyListForEquipmentRequest): void {
		setFilter(newFilter);
		setShowFilterModal(false);
	}

	/**
	 * Toggle visibility of the "Manually Add Issue" modal.
	 *
	 */
	function toggleShowAddModal(): void {
		setShowAddModal(!showAddModal);
	}

	/**
	 * Handle user finishing adding an issue.
	 *
	 */
	function onDoneAdd(): void {
		setShowAddModal(false);
		getDeficiencies().then().catch();
	}

	/**
	 * Renderer for the Edit Issue column.
	 *
	 * @param deficiency
	 */
	function makeEditIssueCell(deficiency: Deficiency): ReactNode {
		return (
			<GeneralDeficiencyEditIssueCell
				key={"edit" + deficiency._id}
				deficiency={deficiency}
				onDone={getDeficiencies}
			/>
		);
	}

	/**
	 * Renderer for the Edit Issue column.
	 *
	 * @param deficiency
	 */
	function makeResolveIssueCell(deficiency: Deficiency): ReactNode {
		return (
			<GeneralDeficiencyResolveIssueCell
				key={"resolve" + deficiency._id}
				deficiency={deficiency}
				onDone={getDeficiencies}
			/>
		);
	}

	if (deficienciesResponse === undefined || !deficienciesResponse?.equipment) {
		return null;
	}

	return (
		<React.Fragment>
			<GeneralDeficiencyFilterModal
				isOpen={showFilterModal}
				filter={filter}
				onClose={toggleFilterModal}
				onDone={onDoneFilterModal}
			/>

			<CreateGeneralDeficiencyIssueModal
				isOpen={showAddModal}
				equipment={deficienciesResponse?.equipment}
				onClose={toggleShowAddModal}
				onDone={onDoneAdd}
			/>

			<AlliedContainer>
				<AlliedPageHeader
					title={`Deficiency Reports for ${parseEquipmentForDeficienciesTitle(deficienciesResponse?.equipment)}`}
					titleStyle={{fontSize: 24}}
					onClick={toggleFilterModal}
				/>
				<p>
					{`Showing results from ${moment(filter.from).format("MMM DD YYYY")} - ${moment(filter.to).format("MMM DD YYYY")}`}
				</p>

				<div className="mt-3 d-flex flex-row flex-wrap align-items-start">
					<ViewDeficienciesButton
						id={id}
						className="mr-3 mb-3"
					>
						Generate Form
					</ViewDeficienciesButton>

					<Button
						color="black"
						onClick={toggleShowAddModal}
					>
						Manually Add Issue
					</Button>
				</div>

				<hr/>

				<AlliedTableContainer
					data={deficienciesResponse?.deficiencies}
					columnOptions={[
						{
							headerValue: "Date",
							key: "date",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							rowCellClassName: "font-weight-bold",
							valueFormatter: parseDateForTableDisplay,
						},
						{
							headerValue: "Issue",
							key: "issue",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
						},
						{
							headerValue: "Added By",
							key: "owner",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getFullNameForTable,
						},
						{
							headerValue: "Status",
							key: "status",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: parseDeficiencyStatusForDisplay,
						},
						{
							headerValue: "Edit Issue",
							key: undefined,
							showSortIcons: false,
							sortable: false,
							headerRender: AlliedTableHeaderCell,
							cellRender: makeEditIssueCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
						{
							headerValue: "Resolved",
							key: undefined,
							showSortIcons: false,
							sortable: false,
							headerRender: AlliedTableHeaderCell,
							cellRender: makeResolveIssueCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						}
					]}
				/>
			</AlliedContainer>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(GeneralDeficiencyReports);
