import {connect} from "react-redux";
import React from "react";
import {IStore} from "../redux/defaultStore";
import {Redirect, Route, RouteProps} from "react-router";
import {addError} from "../redux/meta/MetaActions";
import {Token, UserType} from "client";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	access?: Array<UserType>;
}

const AuthenticatedRoute: React.FC<RouteProps & IProps> = (props: RouteProps & IProps) => {

	const {component, fullToken, ...rest} = props;
	const Component = component;

	// Check if token exists, if it doesn't, redirect user to main route.
	// Then, if there is an access requirement, make sure user's token type appears in the array; if not, send to main route.
	if (!fullToken) {
		props.dispatch(addError({messages: ["You must have a token to access that page. If you were previously logged in, your access token may have expired and you must log in again to get a new one."]}));

		return (
			<Redirect to="/"/>
		);
	} else if (props.access?.length > 0 && props.access?.indexOf(props.fullToken?.type) < 0) {
		props.dispatch(addError({messages: ["You do not have the correct permissions to access that page."]}));

		return (
			<Redirect to="/"/>
		);
	}

	return (
		<Route
			{...rest}
			render={props => <Component {...props}/>}
		/>
	)
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore?.fullToken,
		...props,
	}
})(AuthenticatedRoute);
