/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CraneDailyLogFromJSONTyped, CraneDailyLogToJSON, } from './CraneDailyLog';
import { InspectionStatusFromJSON, InspectionStatusToJSON, } from './InspectionStatus';
/**
 * Check if a given object implements the TowerCraneDailyLog interface.
 */
export function instanceOfTowerCraneDailyLog(value) {
    let isInstance = true;
    return isInstance;
}
export function TowerCraneDailyLogFromJSON(json) {
    return TowerCraneDailyLogFromJSONTyped(json, false);
}
export function TowerCraneDailyLogFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...CraneDailyLogFromJSONTyped(json, ignoreDiscriminator),
        'ensureAllTower': !exists(json, 'ensureAllTower') ? undefined : InspectionStatusFromJSON(json['ensureAllTower']),
        'loadMomentTrolleyLimit': !exists(json, 'loadMomentTrolleyLimit') ? undefined : InspectionStatusFromJSON(json['loadMomentTrolleyLimit']),
        'trolleyOut': !exists(json, 'trolleyOut') ? undefined : InspectionStatusFromJSON(json['trolleyOut']),
        'trolleyIn': !exists(json, 'trolleyIn') ? undefined : InspectionStatusFromJSON(json['trolleyIn']),
        'trolleyBrake': !exists(json, 'trolleyBrake') ? undefined : InspectionStatusFromJSON(json['trolleyBrake']),
    };
}
export function TowerCraneDailyLogToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...CraneDailyLogToJSON(value),
        'ensureAllTower': InspectionStatusToJSON(value.ensureAllTower),
        'loadMomentTrolleyLimit': InspectionStatusToJSON(value.loadMomentTrolleyLimit),
        'trolleyOut': InspectionStatusToJSON(value.trolleyOut),
        'trolleyIn': InspectionStatusToJSON(value.trolleyIn),
        'trolleyBrake': InspectionStatusToJSON(value.trolleyBrake),
    };
}
