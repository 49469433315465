import React, {useState} from "react";
import {SiteWithUsersAndEquipment, Token, UserType} from "client";
import AlliedTextButton from "../../AlliedTextButton";
import ManageSitesEquipmentModalAdminView from "../../modals/ManageSitesEquipmentModalAdminView";
import {connect} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import ManageSitesEquipmentModalStandardView from "../../modals/ManageSitesEquipmentModalStandardView";

interface IProps {
	fullToken?: Token;
	site: SiteWithUsersAndEquipment;
	onDone: () => Promise<void>;
}

const ManageSitesEquipmentCell: React.FC<IProps> = (props) => {

	const [showEquipmentModal, setShowEquipmentModal] = useState(false);

	function toggleEquipmentModal(): void {
		setShowEquipmentModal(!showEquipmentModal);
	}

	function onDone(): void {
		props.onDone().then().catch();
	}

	return (
		<div className="d-flex justify-content-end">
			{props.fullToken?.type === UserType.ADMIN ? (
				<ManageSitesEquipmentModalAdminView
					isOpen={showEquipmentModal}
					site={props.site}
					onClose={toggleEquipmentModal}
					onDone={onDone}
				/>
			) : (
				<ManageSitesEquipmentModalStandardView
					isOpen={showEquipmentModal}
					site={props.site}
					onClose={toggleEquipmentModal}
				/>
			)}

			<AlliedTextButton onClick={toggleEquipmentModal}>
				{`${props.site?.equipment?.length} (view details)`}
			</AlliedTextButton>
		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageSitesEquipmentCell);
