/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CraneTypeFromJSON, CraneTypeToJSON, } from './CraneType';
import { EquipmentFromJSONTyped, EquipmentToJSON, } from './Equipment';
/**
 * Check if a given object implements the Crane interface.
 */
export function instanceOfCrane(value) {
    let isInstance = true;
    isInstance = isInstance && "craneType" in value;
    return isInstance;
}
export function CraneFromJSON(json) {
    return CraneFromJSONTyped(json, false);
}
export function CraneFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...EquipmentFromJSONTyped(json, ignoreDiscriminator),
        'craneType': CraneTypeFromJSON(json['craneType']),
    };
}
export function CraneToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...EquipmentToJSON(value),
        'craneType': CraneTypeToJSON(value.craneType),
    };
}
