import React from "react";

interface IProps {
	label: string;
	value: string;
	width: number;
}

const PrintableTextArea: React.FC<IProps> = (props) => {

	return (
		<div
			className="printable__cell printable__text-area"
			style={{
				width: `${props.width}%`
			}}
		>
			<p className="printable__text-area__label">
				{props.label}
			</p>

			<p className="printable__text-area__value">
				{props.value}
			</p>
		</div>
	);
};

export default PrintableTextArea;
