import React, {ReactNode} from "react";
import {PaginationInfo} from "client";
import {FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight} from "react-icons/all";
import classNames from "classnames";
import { isNil } from "lodash";

interface IProps {
	paginationInfo: PaginationInfo;
	onOffsetChange: (offset: number) => void;
}

const AlliedPaginatorOffsetController: React.FC<IProps> = (props) => {

	function goToFirst(): void {
		if (!props.paginationInfo.enablePrevious) {
			return;
		}

		props.onOffsetChange(0);
	}

	function goPrevious(): void {
		if (!props.paginationInfo.enablePrevious) {
			return;
		}

		props.onOffsetChange(props.paginationInfo.offset - 1);
	}

	function goNext(): void {
		if (!props.paginationInfo.enableNext) {
			return;
		}

		props.onOffsetChange(props.paginationInfo.offset + 1);
	}

	function goToLast(): void {
		if (!props.paginationInfo.enableNext) {
			return;
		}

		const lastPage: number = Math.floor(props.paginationInfo.total / props.paginationInfo.limit);

		if (lastPage >= props.paginationInfo.total) {
			props.onOffsetChange(lastPage - 1);
		} else {
			props.onOffsetChange(lastPage);
		}
	}

	function makeOffsetButtons(offset: number, i: number): ReactNode {
		if (isNil(offset)) {
			return null;
		}

		function onOffsetChange(): void {
			if (offset === props.paginationInfo.offset) {
				return;
			}

			props.onOffsetChange(offset);
		}

		return (
			<div
				onClick={onOffsetChange}
				key={`table-offset-button_${offset}_${i}_${Math.random()}`}
				className={classNames("allied-paginator-offset-controller__button", {
					"allied-paginator-offset-controller__selected-offset": offset === props.paginationInfo.offset,
					"allied-paginator-offset-controller__selectable-offset": offset !== props.paginationInfo.offset,
				})}
			>
				<span>
					{offset + 1}
				</span>
			</div>
		);
	}

	return (
		<div className="allied-paginator-offset-controller">
			<div
				onClick={goToFirst}
				className={classNames("allied-paginator-offset-controller__button allied-paginator-offset-controller__arrow-container", {
					"allied-paginator-offset-controller__selectable-offset": props.paginationInfo.enablePrevious,
					"allied-paginator-offset-controller__disabled-arrow": !props.paginationInfo.enablePrevious,
				})}
			>
				<FiChevronsLeft className="allied-paginator-offset-controller__paginator-icon"/>
			</div>

			<div
				onClick={goPrevious}
				className={classNames("allied-paginator-offset-controller__button allied-paginator-offset-controller__arrow-container", {
					"allied-paginator-offset-controller__selectable-offset": props.paginationInfo.enablePrevious,
					"allied-paginator-offset-controller__disabled-arrow": !props.paginationInfo.enablePrevious,
				})}
			>
				<FiChevronLeft className="allied-paginator-offset-controller__paginator-icon"/>
			</div>

			{props.paginationInfo?.availableOffsets?.indexOf(props.paginationInfo?.offset) > -1 ? (
				<React.Fragment>
					{props.paginationInfo.availableOffsets?.map(makeOffsetButtons)}
				</React.Fragment>
			) : (
				<div className="allied-paginator-offset-controller__button allied-paginator-offset-controller__selected-offset">
					<span>
						1
					</span>
				</div>
			)}

			<div
				onClick={goNext}
				className={classNames("allied-paginator-offset-controller__button allied-paginator-offset-controller__arrow-container", {
					"allied-paginator-offset-controller__selectable-offset": props.paginationInfo.enableNext,
					"allied-paginator-offset-controller__disabled-arrow": !props.paginationInfo.enableNext,
				})}
			>
				<FiChevronRight className="allied-paginator-offset-controller__paginator-icon"/>
			</div>

			<div
				onClick={goToLast}
				className={classNames("allied-paginator-offset-controller__button allied-paginator-offset-controller__arrow-container", {
					"allied-paginator-offset-controller__selectable-offset": props.paginationInfo.enableNext,
					"allied-paginator-offset-controller__disabled-arrow": !props.paginationInfo.enableNext,
				})}
			>
				<FiChevronsRight className="allied-paginator-offset-controller__paginator-icon"/>
			</div>
		</div>
	);
};

export default AlliedPaginatorOffsetController;
