import {CreateDeficiencyBody, Deficiency} from "client";
import {generateOffsetDate} from "./timeZoneConversions";

export function convertDeficiencyToCreateBody(res: Deficiency): CreateDeficiencyBody {
	return {
		status: res.status,
		equipmentID: res?.equipment?._id,
		siteID: res?.site?._id,
		date: res.date !== undefined ? generateOffsetDate(res.date) : undefined,
		issue: res.issue,
	}
}

