import React, {ReactNode} from "react";
import {CraneMonthlyLog, CreateCraneMonthlyLogBody} from "client";
import {getCraneMonthlyLogInspectionItems} from "../../../utils/getCraneMonthlyLogInspectionItems";
import {getCraneMonthlyLogInspectionItemDisplayName} from "../../../utils/getCraneMonthlyLogInspectionItemDisplayName";
import {valueOrBustPrintables} from "../../../utils/valueOrBust";
import {parseInspectionStatusForPrintableTable} from "../../../utils/parseInspectionStatus";

interface IProps {
	craneMonthlyLog: CraneMonthlyLog;
}

const PrintableCraneMonthlyLogChecklistTable: React.FC<IProps> = (props) => {

	function makeDynamicInspectionRows(): ReactNode {
		return getCraneMonthlyLogInspectionItems().map((inspectionItem: keyof CreateCraneMonthlyLogBody, i: number) => {
			return (
				<tr key={`crane-monthly-log-inspection-row-item_${i}_${inspectionItem}_${Math.random()}`}>
					<td>
						{i + 1}
					</td>

					<td>
						{getCraneMonthlyLogInspectionItemDisplayName(inspectionItem)}
					</td>

					<td className="printable__table__centered">
						{valueOrBustPrintables(props.craneMonthlyLog?.[inspectionItem], parseInspectionStatusForPrintableTable(inspectionItem, props.craneMonthlyLog?.[inspectionItem]))}
					</td>
				</tr>
			);
		});
	}

	return (
		<div style={{width: "100%"}}>
			<table className="printable__table">
				<tr>
					<td className="printable__table__label">
						#
					</td>

					<td className="printable__table__label">
						Inspection Item
					</td>

					<td className="printable__table__label printable__table__centered">
						Status
					</td>
				</tr>

				{makeDynamicInspectionRows()}
			</table>
		</div>
	);
};

export default PrintableCraneMonthlyLogChecklistTable;
