import moment, {Moment} from "moment";

/**
 * Round a moment object to the nearest specified minutes
 *
 * @param start
 * @param interval
 * @param up
 */
export function roundMomentObjectMinutes(start: Moment = moment(), interval: number = 15, up: boolean = false): Moment {
	const remainder = interval - (start.minute() % interval);

	if (up) {
		return moment(start.startOf("minute")).add(remainder, "minutes");
	} else {
		return moment(start.startOf("minute")).subtract(interval - remainder, "minutes");
	}
}
