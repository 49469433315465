import React, {useEffect, useState} from "react";
import {Site, SitesApi, SiteWithUsersAndEquipment, Token, User, UsersApi, UserType} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {Button, Col, Row} from "reactstrap";
import AlliedAutoComplete from "../inputs/AlliedAutoComplete";
import {parseUsersForAutoComplete} from "../../utils/parseUsersForAutoComplete";
import AutoCompleteListDisplay from "../inputs/AutoCompleteListDisplay";
import {userTypeCheck} from "../../utils/userTypeCheck";
import {map} from "lodash";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	site: SiteWithUsersAndEquipment;
	onClose: () => void;
	onDone: () => void;
}

const ManageSitesEditUsersModal: React.FC<IProps> = (props) => {

	// Selected auto-complete values.
	const [selectedManagers, setSelectedManagers] = useState<string[]>(map(props.site?.users?.secondaryAdmins, "_id"));
	const [selectedSupervisors, setSelectedSupervisors] = useState<string[]>(map(props.site?.users?.supervisors, "_id"));
	const [selectedEmployees, setSelectedEmployees] = useState<string[]>(map(props.site?.users?.alliedCraneEmployees, "_id"));
	const [selectedOperators, setSelectedOperators] = useState<string[]>(map(props.site?.users?.craneOperators, "_id"));
	const [selectedHealthOfficers, setSelectedHealthOfficers] = useState<string[]>(map(props.site?.users?.healthAndSafetyOfficers, "_id"));

	// Api responses for the auto-complete inputs, these only get set once.
	const [apiManagers, setApiManagers] = useState<Array<User>>(undefined);
	const [apiSupervisors, setApiSupervisors] = useState<Array<User>>(undefined);
	const [apiEmployees, setApiEmployees] = useState<Array<User>>(undefined);
	const [apiOperators, setApiOperators] = useState<Array<User>>(undefined);
	const [apiHealthOfficers, setApiHealthOfficers] = useState<Array<User>>(undefined);

	useEffect(() => {
		if (props.isOpen) {
			getAutoCompleteValues().then().catch();
		}
	}, [props.isOpen]);

	/**
	 * Resets any changes to the form and calls the close function from props.
	 *
	 */
	function resetAndClose(): void {
		setSelectedManagers(map(props.site?.users?.secondaryAdmins, "_id"));
		setSelectedSupervisors(map(props.site?.users?.supervisors, "_id"));
		setSelectedEmployees(map(props.site?.users?.alliedCraneEmployees, "_id"));
		setSelectedOperators(map(props.site?.users?.craneOperators, "_id"));
		setSelectedHealthOfficers(map(props.site?.users?.healthAndSafetyOfficers, "_id"));
		props.onClose();
	}

	/**
	 * Call api to get auto-complete lists for managers, supervisors, employees, operators, and health & safety officers
	 *
	 */
	async function getAutoCompleteValues(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const userApiBase = new UsersApi(getConfig(props.fullToken));

			if (props.fullToken.type === UserType.ADMIN) {
				const managers = await userApiBase.getUserList({
					limit: 10000,
					offset: 0,
					type: [UserType.SECONDARY_ADMIN],
				});

				const supervisors = await userApiBase.getUserList({
					limit: 10000,
					offset: 0,
					type: [UserType.SUPERVISOR],
				});

				const employees = await userApiBase.getUserList({
					limit: 10000,
					offset: 0,
					type: [UserType.ALLIED_CRANE_EMPLOYEE],
				});

				setApiManagers(managers.users);
				setApiSupervisors(supervisors.users);
				setApiEmployees(employees.users);
			}

			const operators = await userApiBase.getUserList({
				limit: 10000,
				offset: 0,
				type: [UserType.CRANE_OPERATOR],
			});

			const healthOfficers = await userApiBase.getUserList({
				limit: 10000,
				offset: 0,
				type: [UserType.HEALTH_AND_SAFETY_OFFICER],
			});

			setApiOperators(operators.users);
			setApiHealthOfficers(healthOfficers.users);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Call the api to update the users associated with the site.
	 *
	 */
	async function submitSiteUserChanges(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new SitesApi(getConfig(props.fullToken)).updateUsersForSite({
				updateUsersForSite: {
					siteID: props.site?._id,
					userIDs: [...selectedManagers, ...selectedSupervisors, ...selectedEmployees, ...selectedOperators, ...selectedHealthOfficers],
				},
			});

			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<AlliedModal
			isOpen={props.isOpen}
			size="lg"
		>
			<AlliedModalHeader
				title={`Edit Users for ${props.site?.name}`}
				onClose={resetAndClose}
			/>

			<hr/>

			<Row>
				{userTypeCheck([UserType.ADMIN], props.fullToken) && (
					<Col xs={12} lg={6} className="mb-3">
						<label>Assigned Manager(s)</label>
						<AlliedAutoComplete
							placeholder="Select Manager(s)..."
							options={parseUsersForAutoComplete(apiManagers)}
							selections={selectedManagers}
							setSelections={setSelectedManagers}
						/>
					</Col>
				)}

				{userTypeCheck([UserType.ADMIN], props.fullToken) && (
					<Col xs={12} lg={6} className="mb-3">
						<label>Assigned Supervisor(s)</label>
						<AlliedAutoComplete
							placeholder="Select Supervisor(s)..."
							options={parseUsersForAutoComplete(apiSupervisors)}
							selections={selectedSupervisors}
							setSelections={setSelectedSupervisors}
						/>
					</Col>
				)}

				{userTypeCheck([UserType.ADMIN], props.fullToken) && (
					<Col xs={12} lg={6} className="mb-3">
						<label>Assigned AC Employee(s)</label>
						<AlliedAutoComplete
							placeholder="Select Employee(s)..."
							options={parseUsersForAutoComplete(apiEmployees)}
							selections={selectedEmployees}
							setSelections={setSelectedEmployees}
						/>
					</Col>
				)}

				<Col xs={12} lg={6} className="mb-3">
					<label>Assigned Operator(s)</label>
					<AlliedAutoComplete
						placeholder="Select Operator(s)..."
						options={parseUsersForAutoComplete(apiOperators)}
						selections={selectedOperators}
						setSelections={setSelectedOperators}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Assigned Health & Safety Worker(s)</label>
					<AlliedAutoComplete
						placeholder="Select Health & Safety Worker(s)..."
						options={parseUsersForAutoComplete(apiHealthOfficers)}
						selections={selectedHealthOfficers}
						setSelections={setSelectedHealthOfficers}
					/>
				</Col>
			</Row>

			<div>
				<Button
					color="black"
					onClick={submitSiteUserChanges}
				>
					Save Changes
				</Button>
			</div>
		</AlliedModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageSitesEditUsersModal);
