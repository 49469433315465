import React, {useState} from "react";
import {TimeSheet} from "client";
import {Button} from "reactstrap";
import ManageTimeSheetsAddEditCommentModal from "../../modals/ManageTimeSheetsAddEditCommentModal";

interface IProps {
	timeSheet: TimeSheet;
	onDone: () => Promise<void>;
}

const ManageTimeSheetsCommentCell: React.FC<IProps> = (props) => {

	const [showCommentModal, setShowCommentModal] = useState(false);

	/**
	 * Toggle showing the modal flow.
	 *
	 */
	function toggleShowModalFlow(): void {
		setShowCommentModal(!showCommentModal);
	}

	/**
	 * Close modal and call API from parent to update table.
	 *
	 */
	function onDone(): void {
		setShowCommentModal(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<ManageTimeSheetsAddEditCommentModal
				isOpen={showCommentModal}
				timeSheet={props.timeSheet}
				onClose={toggleShowModalFlow}
				onDone={onDone}
			/>

			<div className="allied-table-cell">
				<Button
					color="black"
					onClick={toggleShowModalFlow}
				>
					Add/Edit Comment
				</Button>
			</div>
		</React.Fragment>
	);
};

export default ManageTimeSheetsCommentCell;
