/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CreateUserBodyFromJSONTyped, CreateUserBodyToJSON, } from './CreateUserBody';
/**
 * Check if a given object implements the CreateAlliedCraneEmployeeBody interface.
 */
export function instanceOfCreateAlliedCraneEmployeeBody(value) {
    let isInstance = true;
    return isInstance;
}
export function CreateAlliedCraneEmployeeBodyFromJSON(json) {
    return CreateAlliedCraneEmployeeBodyFromJSONTyped(json, false);
}
export function CreateAlliedCraneEmployeeBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...CreateUserBodyFromJSONTyped(json, ignoreDiscriminator),
        'sites': !exists(json, 'sites') ? undefined : json['sites'],
    };
}
export function CreateAlliedCraneEmployeeBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...CreateUserBodyToJSON(value),
        'sites': value.sites,
    };
}
