/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the PaginationInfo interface.
 */
export function instanceOfPaginationInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "enablePrevious" in value;
    isInstance = isInstance && "enableNext" in value;
    isInstance = isInstance && "lowerRange" in value;
    isInstance = isInstance && "upperRange" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "availableOffsets" in value;
    isInstance = isInstance && "limit" in value;
    isInstance = isInstance && "offset" in value;
    return isInstance;
}
export function PaginationInfoFromJSON(json) {
    return PaginationInfoFromJSONTyped(json, false);
}
export function PaginationInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'enablePrevious': json['enablePrevious'],
        'enableNext': json['enableNext'],
        'lowerRange': json['lowerRange'],
        'upperRange': json['upperRange'],
        'total': json['total'],
        'availableOffsets': json['availableOffsets'],
        'limit': json['limit'],
        'offset': json['offset'],
    };
}
export function PaginationInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'enablePrevious': value.enablePrevious,
        'enableNext': value.enableNext,
        'lowerRange': value.lowerRange,
        'upperRange': value.upperRange,
        'total': value.total,
        'availableOffsets': value.availableOffsets,
        'limit': value.limit,
        'offset': value.offset,
    };
}
