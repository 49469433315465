import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	Generator,
	EquipmentApi,
	EquipmentType,
	GetEquipmentListRequest,
	GetEquipmentListResponse,
	Token, UserType, EquipmentSortField,
} from "client";
import AlliedPageHeader from "../../components/AlliedPageHeader";
import AlliedContainer from "../../components/AlliedContainer";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {removeEmptyArraysFromObject} from "../../utils/removeEmptyArraysFromObject";
import {TableData} from "frame-one-table/build/contextTypes";
import {ColumnOption} from "frame-one-table/build/TableGenerator";
import AlliedTableContainer from "../../components/tables/AlliedTableContainer";
import AlliedTableHeaderCell from "../../components/tables/cells/AlliedTableHeaderCell";
import {getSiteName} from "../../utils/getSiteName";
import ManageGeneratorsEditCell from "../../components/tables/cells/ManageGeneratorsEditCell";
import ManageGeneratorsDisableCell from "../../components/tables/cells/ManageGeneratorsDisableCell";
import ManageGeneratorsFilterModal from "../../components/modals/ManageGeneratorsFilterModal";
import ManageGeneratorsViewDeficienciesCell from "../../components/tables/cells/ManageGeneratorsViewDeficienciesCell";
import {userTypeCheck} from "../../utils/userTypeCheck";
import {alliedPaginationLimitOptions} from "../../components/tables/paginator/AlliedPaginatorLimitController";
import {abstractOnSortChange, getTableHeaderSortClassName} from "../../utils/abstractOnSortChange";

interface IProps {
	dispatch?: any;
	fullToken: Token;
}

const defaultFilter: GetEquipmentListRequest = {
	limit: alliedPaginationLimitOptions[0],
	offset: 0,
	showDisabled: false,
	type: [EquipmentType.GENERATOR],
	ownedBy: [],
	rentedBy: [],
	make: [],
	model: [],
	serialNumber: [],
	site: [],
};

const ManageGeneratorPage: React.FC<IProps> = (props) => {

	const [showFilterModal, setShowFilterModal] = useState(false);
	const [filter, setFilter] = useState<GetEquipmentListRequest>(defaultFilter);
	const [generatorListResponse, setGeneratorListResponse] = useState<GetEquipmentListResponse>(undefined);

	useEffect(() => {
		getGeneratorList().then().catch();
	}, [JSON.stringify(filter)]);

	/**
	 * Call api to get list of generators.
	 *
	 */
	async function getGeneratorList(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new EquipmentApi(getConfig(props.fullToken)).getEquipmentList(removeEmptyArraysFromObject(filter));
			setGeneratorListResponse(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Toggles visibility of the filter modal.
	 *
	 */
	function toggleFilterModal(): void {
		setShowFilterModal(!showFilterModal);
	}

	/**
	 * When finished the filter modal, save the new filter (will prompt api via useEffect),
	 * and hide the filter modal.
	 *
	 * @param newFilter
	 */
	function onDoneFilterModal(newFilter: GetEquipmentListRequest): void {
		setFilter({
			...newFilter,
			offset: 0,
		});
		setShowFilterModal(false);
	}

	/**
	 * Renderer for the Edit Information column.
	 *
	 * @param generator
	 */
	function makeEditCell(generator: Generator): ReactNode {
		return (
			<ManageGeneratorsEditCell
				key={"edit" + generator._id}
				generator={generator}
				onDone={getGeneratorList}
			/>
		);
	}

	/**
	 * Renderer for the View forms column.
	 *
	 * @param generator
	 */
	function makeViewFormsCell(generator: Generator): ReactNode {
		return (
			<ManageGeneratorsViewDeficienciesCell
				key={"view-deficiencies" + generator._id}
				generator={generator}
			/>
		);
	}

	/**
	 * Renderer for the Disable Generator column.
	 *
	 * @param generator
	 */
	function makeDisableCell(generator: Generator): ReactNode {
		return (
			<ManageGeneratorsDisableCell
				key={"disable" + generator._id}
				generator={generator}
				onDone={getGeneratorList}
			/>
		);
	}

	return (
		<React.Fragment>
			<ManageGeneratorsFilterModal
				isOpen={showFilterModal}
				filter={filter}
				onClose={toggleFilterModal}
				onDone={onDoneFilterModal}
			/>

			<AlliedContainer>
				<AlliedPageHeader
					title="Manage Equipment"
					onClick={toggleFilterModal}
				/>

				<hr/>

				<AlliedTableContainer
					<GetEquipmentListRequest>
					data={generatorListResponse?.equipment}
					showPaginator={true}
					paginationInfo={generatorListResponse?.paginationInfo}
					filter={filter}
					setFilter={setFilter}
					columnOptions={[
						{
							headerValue: "Owned By",
							key: "ownedBy",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							headerCellClassName: getTableHeaderSortClassName(EquipmentSortField.ownedBy, filter),
							onSort: abstractOnSortChange<EquipmentSortField, GetEquipmentListRequest>(EquipmentSortField.ownedBy, filter, setFilter),
						},
						{
							headerValue: "Make",
							key: "make",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							headerCellClassName: getTableHeaderSortClassName(EquipmentSortField.make, filter),
							onSort: abstractOnSortChange<EquipmentSortField, GetEquipmentListRequest>(EquipmentSortField.make, filter, setFilter),
						},
						{
							headerValue: "Model",
							key: "model",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							headerCellClassName: getTableHeaderSortClassName(EquipmentSortField.model, filter),
							onSort: abstractOnSortChange<EquipmentSortField, GetEquipmentListRequest>(EquipmentSortField.model, filter, setFilter),
						},
						{
							headerValue: "S/N",
							key: "serialNumber",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							headerCellClassName: getTableHeaderSortClassName(EquipmentSortField.serialNumber, filter),
							onSort: abstractOnSortChange<EquipmentSortField, GetEquipmentListRequest>(EquipmentSortField.serialNumber, filter, setFilter),
						},
						{
							headerValue: "Historical Hours",
							key: "preExistingUsageHours",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							headerCellClassName: getTableHeaderSortClassName(EquipmentSortField.preExistingUsageHours, filter),
							onSort: abstractOnSortChange<EquipmentSortField, GetEquipmentListRequest>(EquipmentSortField.preExistingUsageHours, filter, setFilter),
						},
						{
							headerValue: "Current Hours",
							key: "currentUsageHours",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
						},
						{
							headerValue: "Site Name",
							key: "site",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getSiteName,
						},
						{
							headerValue: "Rented By",
							key: "rentedBy",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							headerCellClassName: getTableHeaderSortClassName(EquipmentSortField.rentedBy, filter),
							onSort: abstractOnSortChange<EquipmentSortField, GetEquipmentListRequest>(EquipmentSortField.rentedBy, filter, setFilter),
						},
						(userTypeCheck([UserType.ADMIN], props.fullToken) &&
							{
								headerValue: "View/Edit Details",
								key: undefined,
								showSortIcons: false,
								sortable: false,
								headerRender: AlliedTableHeaderCell,
								cellRender: makeEditCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							}
						),
						{
							headerValue: "View Forms",
							key: undefined,
							showSortIcons: false,
							sortable: false,
							headerRender: AlliedTableHeaderCell,
							cellRender: makeViewFormsCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
						(userTypeCheck([UserType.ADMIN], props.fullToken) &&
							{
								headerValue: "Disable",
								key: undefined,
								showSortIcons: false,
								sortable: false,
								headerRender: AlliedTableHeaderCell,
								cellRender: makeDisableCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							}
						),
					]}
				/>

			</AlliedContainer>

		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageGeneratorPage);
