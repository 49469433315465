import React from "react";
import {CraneDailyLogGroup, Token} from "client";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/MetaActions";
import {connect} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import AbstractPrintableButtonManager from "./AbstractPrintableButtonManager";
import CraneDailyLogPrintable from "../forms/CraneDailyLogPrintable";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	groupedCraneLogs: CraneDailyLogGroup;
	children: string;
	showPrintable?: boolean;
	className?: string;
}

const ViewCraneDailyLogButton: React.FC<IProps> = (props) => {

	/**
	 * Call api to get & return the crane daily logs.
	 *
	 */
	async function getFinalTestForm(): Promise<CraneDailyLogGroup> {
		props.dispatch(incrementLoading());

		try {
			props.dispatch(decrementLoading());
			return props.groupedCraneLogs;
		} catch (e) {
			props.dispatch(addError(e));
			props.dispatch(decrementLoading());
		}
	}

	return (
		<AbstractPrintableButtonManager
			<CraneDailyLogGroup>
			color="black"
			onClick={getFinalTestForm}
			component={CraneDailyLogPrintable}
			showPrintable={props.showPrintable}
			documentTitle="crane-daily-logs"
			className={props.className}
		>
			{props.children}
		</AbstractPrintableButtonManager>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ViewCraneDailyLogButton);
