import React from "react";
import PrintableStyle from "./PrintableStyle";
import PrintableHeader from "./PrintableHeader";

interface IProps<T> {
	form: T;
	component: React.FC<{form: T}>;
}

function PrintableContainer<T>(props: IProps<T>): JSX.Element {

	const FormDisplay: React.FC<{form: T}> = props.component;

	return (
		<div className="allied-printable-container">
			<PrintableStyle/>

			<PrintableHeader/>

			<div className="printable">
				<FormDisplay form={props.form}/>
			</div>
		</div>
	);
}

export default PrintableContainer;

