import React, {ChangeEventHandler, useEffect, useState} from "react";
import {connect} from "react-redux";
import {addError, decrementLoading, incrementLoading, login, updateCurrentUser} from "../../redux/meta/MetaActions";
import {LoginBody, Token, UsersApi, UserType} from "client";
import getConfig from "../../utils/getConfig";
import {IStore} from "../../redux/defaultStore";
import {useHistory} from "react-router-dom";
import {Button} from "reactstrap";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
}

const LoginPage: React.FC<IProps> = (props) => {

	const history = useHistory();
	const [form, setForm] = useState<LoginBody>({username: "", password: ""});

	/**
	 * Respond to the token either existing on initial mount or when the login action
	 * is dispatched after successful api call. Send user to correct page based on token type.
	 *
	 */
	useEffect(() => {
		if (props.fullToken && props.fullToken?.token) {
			switch (props.fullToken?.type) {
				case UserType.ADMIN:
				case UserType.SECONDARY_ADMIN:
				case UserType.SUPERVISOR:
					history.push("/user-options/manage-users");
					break;
				case UserType.CRANE_OPERATOR:
				case UserType.ALLIED_CRANE_EMPLOYEE:
					history.push("/sites-options/view-sites");
					break;
				case UserType.HEALTH_AND_SAFETY_OFFICER:
					history.push("/form-history/daily-log-history/crane-daily-log-history");
					break;
				default:
					history.push("/404")
					break;
			}
		}
	}, [JSON.stringify(props.fullToken)]);

	/**
	 * Dynamic onChange event for the login form.
	 *
	 * @param key
	 */
	function onChangeHelper(key: keyof LoginBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e?.target?.value,
			});
		}
	}

	/**
	 * Call api when form submits and direct to correct page based on token type if successful.
	 *
	 * @param e
	 */
	async function submitLogin(e?: React.SyntheticEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			const res = await new UsersApi(getConfig()).login({
				loginBody: form,
			});

			const userRes = await new UsersApi(getConfig(res)).getProfile();

			await props.dispatch(login(res));
			await props.dispatch(updateCurrentUser(userRes.user));
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<main className="login">
			<div className="login-background-container">
				<img src="/images/worker-in-reflective-vest-and-hardhat-standing-on--XCK4WA6.png"
					 alt="working background"/>
			</div>

			<div className="login-content">

				<div>
					<div className="login-content__image-container">
						<img src="/logos/allied-logo.svg"/>
					</div>

					<div className="login-content__form-container">
						<div/>

						<form className="login-content__form-container__form" onSubmit={submitLogin}>
							<div>
								<label className="text-white">Username</label>
								<input
									className="mb-3 border-0"
									placeholder="Enter Username..."
									value={form?.username}
									onChange={onChangeHelper("username")}
								/>

								<label className="text-white">Password</label>
								<input
									className="mb-3 border-0"
									placeholder="Enter Password..."
									value={form?.password}
									onChange={onChangeHelper("password")}
									type="password"
								/>

								<div className="d-flex justify-content-end">
									<Button
										type="submit"
										color="black"
									>
										Log In
									</Button>
								</div>
							</div>
						</form>

						<div className="login-content__form-container__version-label">
							<p>
								{process.env.REACT_APP_VERSION}
							</p>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore?.fullToken,
		...props,
	}
})(LoginPage);
