import React, {ReactNode} from "react";

export interface IAlliedInputListProps {
	title: string;
	emptyMessage: string;
	children?: ReactNode;
}

const AlliedInputList: React.FC<IAlliedInputListProps> = (props) => {

	return (
		<div className="allied-input-list">
			<div className="allied-input-list__header">
				<h5>{props.title}</h5>
			</div>

			<div className="allied-input-list__body">
				{!props.children || (props.children as any[])?.length < 1 ? (
					<div className="allied-input-list__body__item allied-input-list__body__empty">
						{props.emptyMessage}
					</div>
				) : (
					<React.Fragment>
						{props.children}
					</React.Fragment>
				)}
			</div>
		</div>
	);
};

export default AlliedInputList;
