import React, {ReactNode, useEffect, useState} from "react";
import {FormsApi, TimeSheet, TimeSheetEntry, Token} from "client";
import AlliedModal from "./AlliedModal";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import AlliedModalHeader from "./AlliedModalHeader";
import AlliedTableContainer from "../tables/AlliedTableContainer";
import AlliedTableHeaderCell from "../tables/cells/AlliedTableHeaderCell";
import {convertBooleanToString} from "../../utils/convertBooleanToString";
import {TableData} from "frame-one-table/build/contextTypes";
import {ColumnOption} from "frame-one-table/build/TableGenerator";
import ManageTimeSheetsEntryReferenceCell from "../tables/cells/ManageTimeSheetsEntryReferenceCell";
import {valueOrBust} from "../../utils/valueOrBust";
import {Button} from "reactstrap";
import {parseTimeForTableDisplay} from "../../utils/parseTimeForTableDisplay";
import ManageTimeSheetsHistoryEditEntryCell from "../tables/cells/ManageTimeSheetsHistoryEditEntryCell";
import {humanReadableDuration} from "../../utils/convertMillisecondsToHours";
import ManageTimeSheetsHistoryDeleteEntryCell from "../tables/cells/ManageTimeSheetsHistoryDeleteEntryCell";
import moment from "moment/moment";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	timeSheet: TimeSheet;
	onClose: () => void;
	onDone: () => void;
}

// This is the version of the modal used by Employees.
// For the Admin version see ManageTimeSheetsEditEntriesModal.

const ManageTimeSheetsHistoryEditEntriesModal: React.FC<IProps> = (props) => {

	const [timeSheet, setTimeSheet] = useState<TimeSheet>(undefined);
	const [madeChange, setMadeChange] = useState(false);

	/**
	 * When modal opens, call api to get the current time sheet
	 * (so we don't rely solely on the table data due to updating
	 * this list without wanting to call the reference api to update the table again).
	 *
	 */
	useEffect(() => {
		if (props.isOpen) {
			getEntries().then().catch();
			setMadeChange(false);
		}
	}, [props.isOpen]);

	/**
	 * Depending on whether or not changes were made,
	 * Call either onDone or onClose from props.
	 *
	 */
	function closeHelper(): void {
		if (madeChange) {
			props.onDone()
		} else {
			props.onClose();
		}
	}

	/**
	 * Call the api to get the data for the specific time sheet.
	 * We do this each time the modal is opened to make sure we get the
	 * most up to date information, as when the user updates the assigned reference here,
	 * we want to keep this modal opened (and fetch new data) instead of closing it &
	 * calling for the full new list on the parent page.
	 *
	 */
	async function getEntries(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FormsApi(getConfig(props.fullToken)).getTimeSheet({
				timeSheetID: props.timeSheet?._id,
			});

			setTimeSheet(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Call api to update the time sheet to submitted.
	 *
	 */
	async function submitTimeSheet(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new FormsApi(getConfig(props.fullToken)).submitForm({
				iDBody: {
					id: props.timeSheet?._id,
				},
			});

			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Called when action is completed in one of the cells,
	 * flag madeChanges as true so we know what to do when
	 * the modal is closed later.
	 *
	 */
	function onDoneCell(): void {
		setMadeChange(true);
		getEntries().then().catch();
	}

	/**
	 * When a time sheet entry is deleted, call the api to get the updated list and check if that was the last entry
	 * for the week, in which case close the modal.
	 *
	 */
	async function onDeleteEntry(): Promise<void> {
		// if the entry we delete is the "last" one in the group, ignore trying to update the group of entries
		if (timeSheet.entries.length === 1) {
			props.onDone();
			return;
		}

		props.dispatch(incrementLoading());

		try {
			const res = await new FormsApi(getConfig(props.fullToken)).getTimeSheet({
				timeSheetID: props.timeSheet?._id,
			});

			if (res.entries.length === 0) {
				props.onDone();
			} else {
				setTimeSheet(res);
			}
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Renderer for the Edit Entry column.
	 *
	 */
	function makeEditCell(entry: TimeSheetEntry): ReactNode {
		return (
			<ManageTimeSheetsHistoryEditEntryCell
				entry={entry}
				timeSheet={props.timeSheet}
			/>
		);
	}

	/**
	 * Renderer for the Delete Entry column.
	 *
	 * @param entry
	 */
	function makeDeleteCell(entry: TimeSheetEntry): ReactNode {
		return (
			<ManageTimeSheetsHistoryDeleteEntryCell
				entry={entry}
				timeSheet={timeSheet}
				onDone={onDeleteEntry}
			/>
		);
	}

	/**
	 * Renderer for the Edit Entry column.
	 *
	 */
	function makeReferenceCell(entry: TimeSheetEntry): ReactNode {
		return (
			<ManageTimeSheetsEntryReferenceCell
				entry={entry}
				onDone={onDoneCell}
			/>
		);
	}

	return (
		<AlliedModal
			isOpen={props.isOpen}
			size="xl"
		>
			<AlliedModalHeader
				title="Time Sheet Entries"
				onClose={closeHelper}
			/>

			<hr/>

			<p>
				*Note that you can only edit the entries <b>before</b> they're submitted.
			</p>

			<div>
				<Button
					color="red"
					onClick={submitTimeSheet}
					disabled={props.timeSheet?.submittedDate !== undefined}
				>
					Submit Time Sheet
				</Button>
			</div>

			<hr/>

			<AlliedTableContainer
				data={timeSheet?.entries}
				columnOptions={[
					{
						headerValue: "Date",
						key: undefined,
						valueFormatter: (v: TimeSheetEntry) => {
							if (v.isSickDay) {
								return moment(v.timeIn).format("MMM DD");
							}

							return moment(v.timeIn).format("MMM DD");
						}
					},
					{
						headerValue: "Hours",
						key: undefined,
						showSortIcons: false,
						sortable: true,
						headerRender: AlliedTableHeaderCell,
						valueFormatter: (v: TimeSheetEntry) => {
							if (v.isSickDay) {
								return "Sick / 0 Hour Day"
							} else {
								return humanReadableDuration(v.hours)
							}
						},
					},
					{
						headerValue: "Time In",
						key: undefined,
						showSortIcons: false,
						sortable: true,
						headerRender: AlliedTableHeaderCell,
						valueFormatter: (v: TimeSheetEntry) => {
							if (v.isSickDay) {
								return "-"
							} else {
								return parseTimeForTableDisplay(v.timeIn)
							}
						},
					},
					{
						headerValue: "Time Out",
						key: undefined,
						showSortIcons: false,
						sortable: true,
						headerRender: AlliedTableHeaderCell,
						valueFormatter: (v: TimeSheetEntry) => {
							if (v.isSickDay) {
								return "-"
							} else {
								return parseTimeForTableDisplay(v.timeOut)
							}
						},
					},
					{
						headerValue: "Lunch Break",
						key: undefined,
						showSortIcons: false,
						sortable: true,
						headerRender: AlliedTableHeaderCell,
						valueFormatter: (v: TimeSheetEntry) => {
							if (v.isSickDay) {
								return "-"
							} else {
								return convertBooleanToString(v.lunchBreak);
							}
						},
					},
					{
						headerValue: "Drive Time",
						key: undefined,
						showSortIcons: false,
						sortable: true,
						headerRender: AlliedTableHeaderCell,
						valueFormatter: (v: TimeSheetEntry) => {
							if (v.isSickDay) {
								return "-"
							} else {
								return valueOrBust(v.driveTime);
							}
						},
					},
					{
						headerValue: "Job Description",
						key: "jobDescription",
						showSortIcons: false,
						sortable: true,
						headerRender: AlliedTableHeaderCell,
						valueFormatter: valueOrBust,
					},
					{
						headerValue: "Edit Entry",
						key: undefined,
						showSortIcons: false,
						sortable: true,
						headerRender: AlliedTableHeaderCell,
						cellRender: makeEditCell,
						headerCellClassName: "justify-content-center",
						rowCellClassName: "justify-content-center",
					},
					{
						headerValue: "Delete Entry",
						key: undefined,
						showSortIcons: false,
						sortable: true,
						headerRender: AlliedTableHeaderCell,
						cellRender: makeDeleteCell,
						headerCellClassName: "justify-content-center",
						rowCellClassName: "justify-content-center",
					},
					// {
					// 	headerValue: "Reference",
					// 	key: undefined,
					// 	showSortIcons: false,
					// 	sortable: true,
					// 	headerRender: AlliedTableHeaderCell,
					// 	cellRender: makeReferenceCell,
					// 	headerCellClassName: "justify-content-center",
					// 	rowCellClassName: "justify-content-center",
					// },
				]}
			/>
		</AlliedModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageTimeSheetsHistoryEditEntriesModal);
