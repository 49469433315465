/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const CraneType = {
    LUFFING_TOWER: 'LUFFING_TOWER',
    SELF_ERECTING: 'SELF_ERECTING',
    TOWER: 'TOWER'
};
export function CraneTypeFromJSON(json) {
    return CraneTypeFromJSONTyped(json, false);
}
export function CraneTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function CraneTypeToJSON(value) {
    return value;
}
