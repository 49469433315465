import React from "react";
import CreateGeneratorDailyLogForm from "../../components/forms/CreateGeneratorDailyLogForm";

const CreateGeneratorDailyLogPage: React.FC = () => {

	return (
		<CreateGeneratorDailyLogForm/>
	);
};

export default CreateGeneratorDailyLogPage;
