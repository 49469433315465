import React from "react";
import {Token} from "client";
import CreateTimeSheetForm from "../../components/forms/CreateTimeSheetForm";

interface IProps {
	dispatch?: any;
	fullToken: Token;
}

const CreateTimeSheetPage: React.FC<IProps> = (props) => {

	return (
		<CreateTimeSheetForm/>
	);
};

export default CreateTimeSheetPage;
