import React from "react";
import CreateServiceReportForm from "../../components/forms/CreateServiceReportForm";

const CreateServiceReportPage: React.FC = (props) => {

	return (
		<CreateServiceReportForm/>
	);
};

export default CreateServiceReportPage;
