export function parseServiceReportShortID(shortIdentifier: number = undefined): string {
	if (shortIdentifier === undefined) {
		return "-";
	}

	const stringified: string = shortIdentifier.toString();

	if (stringified.length > 6) {
		return stringified;
	}

	return stringified.padStart(6, "0");
}
