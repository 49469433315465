import {connect} from "react-redux";
import React from "react";
import {RouteComponentProps} from "react-router";
import {Token} from "client";
import {IStore} from "../../redux/defaultStore";
import {useHistory} from "react-router-dom";
import UpdateGeneratorDailyLogForm from "../../components/forms/UpdateGeneratorDailyLogForm";

interface IProps extends RouteComponentProps {
	fullToken?: Token;
}

const EditGeneratorDailyLogPage: React.FC<IProps> = (props) => {

	const history = useHistory();

	const params: URLSearchParams = new URLSearchParams(props.location?.search);
	const id: string = params.get("id");
	const day: string = params.get("day");

	if (id?.length < 1 || parseInt(day) > 6) {
		history.replace("/form-history/daily-log-history/equipment-daily-log-history");
	}

	return (
		<UpdateGeneratorDailyLogForm
			id={id}
			day={day}
		/>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(EditGeneratorDailyLogPage);
