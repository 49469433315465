import React from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Token} from "client";
import {Container} from "reactstrap";

interface IProps {
	dispatch?: any;
	fullToken: Token;
}

const EquipmentLocationFormPage: React.FC<IProps> = (props) => {

	return (
		<Container>
			<h1>
				EquipmentLocationFormPage
			</h1>
		</Container>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
	}
})(EquipmentLocationFormPage);
