import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {
	FormsApi,
	GetTimeSheetListFiltersResponse,
	GetTimeSheetListRequest,
	Token, UserType
} from "client";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button, Col, Row} from "reactstrap";
import {
	convertPayPeriodListToAutoCompleteValues,
	convertWeekListToAutoCompleteValues,
	getWeeksForDailyLogFilter
} from "../../utils/getWeeksForDailyLogFilter";
import AlliedAutoComplete from "../inputs/AlliedAutoComplete";
import {userTypeCheck} from "../../utils/userTypeCheck";
import {parseUsersForAutoComplete} from "../../utils/parseUsersForAutoComplete";
import {generateOffsetDate} from "../../utils/timeZoneConversions";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	filter: GetTimeSheetListRequest;
	onClose: () => void;
	onDone: (newFilter: GetTimeSheetListRequest) => void;
}

const ManageTimeSheetsFilterModal: React.FC<IProps> = (props) => {

	const [filter, setFilter] = useState<GetTimeSheetListRequest>(props.filter);
	const [apiFilterOptions, setApiFilterOptions] = useState<GetTimeSheetListFiltersResponse>(undefined);

	/**
	 * When modal opens, update the local filter to match that of props,
	 * and call api to get values for the auto-complete inputs.
	 *
	 */
	useEffect(() => {
		if (props.isOpen) {
			setFilter(props.filter);
			getAutoCompleteValues().then().catch();
		}
	}, [props.isOpen]);

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function resetAndClose(): void {
		setFilter(props.filter);
		props.onClose();
	}

	/**
	 * Call api to get values for the auto-complete drop-downs.
	 *
	 */
	async function getAutoCompleteValues(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FormsApi(getConfig(props.fullToken)).getTimeSheetListFilters();
			setApiFilterOptions({
				...res,
				paymentPeriods: res.paymentPeriods?.map(p => generateOffsetDate(p)),
			});
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Dynamic onChange helper to update the string arrays in the filter object.
	 *
	 * @param key
	 */
	function filterArrayOnChange(key: keyof GetTimeSheetListRequest): (value: string[]) => void {
		return (value: string[]) => {
			setFilter({
				...filter,
				[key]: value,
			});
		}
	}

	/**
	 * When done configuring filter, call onDone function from props.
	 *
	 */
	function onSave(): void {
		props.onDone(filter);
	}

	return (
		<AlliedModal
			size="lg"
			isOpen={props.isOpen}
		>
			<AlliedModalHeader
				title="Filter Time Sheets"
				onClose={resetAndClose}
			/>

			<hr/>

			<Row>
				<Col xs={12} lg={6} className="mb-3">
					<label>Specify Weeks</label>
					<AlliedAutoComplete
						placeholder="Select Week(s)..."
						options={convertWeekListToAutoCompleteValues(getWeeksForDailyLogFilter())}
						selections={filter?.weekOf?.map((w) => w?.toString())}
						setSelections={filterArrayOnChange("weekOf")}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Specify Pay Periods</label>
					<AlliedAutoComplete
						placeholder="Select Pay Period(s)..."
						options={convertPayPeriodListToAutoCompleteValues(apiFilterOptions?.paymentPeriods)}
						selections={filter?.payPeriod?.map((w) => w?.toString())}
						setSelections={filterArrayOnChange("payPeriod")}
					/>
				</Col>
			</Row>

			{userTypeCheck([UserType.ADMIN], props.fullToken) && (
				<Row>
					<Col xs={12} lg={6} className="mb-3">
						<label>Specify AC Employees</label>
						<AlliedAutoComplete
							placeholder="Select AC Employee(s)..."
							options={parseUsersForAutoComplete(apiFilterOptions?.users)}
							selections={filter?.user}
							setSelections={filterArrayOnChange("user")}
						/>
					</Col>
				</Row>
			)}

			<div>
				<Button
					color="black"
					onClick={onSave}
				>
					Apply Filters
				</Button>
			</div>
		</AlliedModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageTimeSheetsFilterModal);
