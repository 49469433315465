/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CraneDailyLogFromJSONTyped, CraneDailyLogToJSON, } from './CraneDailyLog';
import { InspectionStatusFromJSON, InspectionStatusToJSON, } from './InspectionStatus';
/**
 * Check if a given object implements the LuffingTowerCraneDailyLog interface.
 */
export function instanceOfLuffingTowerCraneDailyLog(value) {
    let isInstance = true;
    return isInstance;
}
export function LuffingTowerCraneDailyLogFromJSON(json) {
    return LuffingTowerCraneDailyLogFromJSONTyped(json, false);
}
export function LuffingTowerCraneDailyLogFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...CraneDailyLogFromJSONTyped(json, ignoreDiscriminator),
        'ensureAllTower': !exists(json, 'ensureAllTower') ? undefined : InspectionStatusFromJSON(json['ensureAllTower']),
        'loadMomentLuffingLimit': !exists(json, 'loadMomentLuffingLimit') ? undefined : InspectionStatusFromJSON(json['loadMomentLuffingLimit']),
        'luffingUpSwitch': !exists(json, 'luffingUpSwitch') ? undefined : InspectionStatusFromJSON(json['luffingUpSwitch']),
        'luffingDownSwitch': !exists(json, 'luffingDownSwitch') ? undefined : InspectionStatusFromJSON(json['luffingDownSwitch']),
        'luffingUpAndDownDecelerationSwitch': !exists(json, 'luffingUpAndDownDecelerationSwitch') ? undefined : InspectionStatusFromJSON(json['luffingUpAndDownDecelerationSwitch']),
        'boomAngleIndicator': !exists(json, 'boomAngleIndicator') ? undefined : InspectionStatusFromJSON(json['boomAngleIndicator']),
        'counterweightSafetySwitch': !exists(json, 'counterweightSafetySwitch') ? undefined : InspectionStatusFromJSON(json['counterweightSafetySwitch']),
        'antiTwoBlockSwitch': !exists(json, 'antiTwoBlockSwitch') ? undefined : InspectionStatusFromJSON(json['antiTwoBlockSwitch']),
        'luffingBrake': !exists(json, 'luffingBrake') ? undefined : InspectionStatusFromJSON(json['luffingBrake']),
    };
}
export function LuffingTowerCraneDailyLogToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...CraneDailyLogToJSON(value),
        'ensureAllTower': InspectionStatusToJSON(value.ensureAllTower),
        'loadMomentLuffingLimit': InspectionStatusToJSON(value.loadMomentLuffingLimit),
        'luffingUpSwitch': InspectionStatusToJSON(value.luffingUpSwitch),
        'luffingDownSwitch': InspectionStatusToJSON(value.luffingDownSwitch),
        'luffingUpAndDownDecelerationSwitch': InspectionStatusToJSON(value.luffingUpAndDownDecelerationSwitch),
        'boomAngleIndicator': InspectionStatusToJSON(value.boomAngleIndicator),
        'counterweightSafetySwitch': InspectionStatusToJSON(value.counterweightSafetySwitch),
        'antiTwoBlockSwitch': InspectionStatusToJSON(value.antiTwoBlockSwitch),
        'luffingBrake': InspectionStatusToJSON(value.luffingBrake),
    };
}
