import React, {ReactNode} from "react";
import AlliedInputList, {IAlliedInputListProps} from "./AlliedInputList";
import SelectOptionsFactory, {ISelectOption} from "../../SelectOptionsFactory";
import AlliedSelect from "../AlliedSelect";
import {AlliedInputRadioFactory, IAlliedRadioProps} from "../AlliedInputRadio";

export interface IAlliedSelectInputListItem {
	label: string;
	interfaceKey: string;
	value: string;
	options: Array<ISelectOption>;
	defaultOptionLabel?: string;
}

interface IProps extends IAlliedInputListProps {
	inputs: Array<IAlliedSelectInputListItem>;
	onChange: (key: keyof any, value: any) => void;
	defaultOptionLabel?: string;
}


const AlliedSelectInputList: React.FC<IProps> = (props) => {

	function makeSelect(_select: IAlliedSelectInputListItem, i: number): ReactNode {

		// function onChangeHelper(e: React.ChangeEvent<HTMLSelectElement>): void {
		// 	props.onChange(_select.interfaceKey, e.target.value);
		// }

		function radioChangeHelper(value: any): () => void {
			return () => {
				props.onChange(_select.interfaceKey, value);
			}
		}

		return (
			<div
				key={`select-item_${_select?.interfaceKey}_${i}`}
				className="allied-input-list__body__item"
			>
				<label>{_select.label}</label>
				{/*<AlliedSelect*/}
				{/*	value={_select.value}*/}
				{/*	onChange={onChangeHelper}*/}
				{/*>*/}
				{/*	<option value="" selected disabled>{_select.defaultOptionLabel ? _select.defaultOptionLabel : props.defaultOptionLabel ? props.defaultOptionLabel : "Select Option"}</option>*/}
				{/*	<hr/>*/}
				{/*	<SelectOptionsFactory options={_select.options}/>*/}
				{/*</AlliedSelect>*/}

				<AlliedInputRadioFactory
					radios={_select?.options?.map((_option): IAlliedRadioProps => {
						return {
							className: "mb-3",
							label: _option?.label,
							name: `${_select?.interfaceKey}_${_option?.label}`,
							checked: _option.value === _select.value,
							onChange: radioChangeHelper(_option.value),
						}
					})}
				/>
			</div>
		);
	}

	return (
		<AlliedInputList
			title={props.title}
			emptyMessage={props.emptyMessage}
		>
			{props.inputs.map(makeSelect)}
		</AlliedInputList>
	);
};

export default AlliedSelectInputList;
