import {CreateGeneratorDailyLogBody, UpdateGeneratorDailyLogBody} from "client";
import {IAlliedSelectInputListItem} from "../components/inputs/inputList/AlliedSelectInputList";
import {getGeneratorDailyLogInspectionItemDisplayName} from "./getGeneratorDailyLogInspectionItemDisplayName";
import {getInspectionItemOptions} from "./convertCraneDailyInspectionItemToInputListItem";

export function convertGeneratorInspectionItemToInputListItem(key: keyof CreateGeneratorDailyLogBody, createGeneratorDailyLogForm: CreateGeneratorDailyLogBody | UpdateGeneratorDailyLogBody): IAlliedSelectInputListItem {
	return {
		label: getGeneratorDailyLogInspectionItemDisplayName(key),
		interfaceKey: key,
		value: createGeneratorDailyLogForm[key] as string,
		options: getInspectionItemOptions(key),
	}
}

/**
 * Convert the array of keys on the daily log to usable drop-down options
 *
 * @param keys
 * @param createGeneratorDailyLogForm
 */
export function convertGeneratorInspectionItemsToInputListItems(keys: Array<keyof CreateGeneratorDailyLogBody>, createGeneratorDailyLogForm: CreateGeneratorDailyLogBody | UpdateGeneratorDailyLogBody): Array<IAlliedSelectInputListItem> {
	return keys.map((_key: keyof CreateGeneratorDailyLogBody) => {
		return convertGeneratorInspectionItemToInputListItem(_key, createGeneratorDailyLogForm);
	})
}
