import {SortOrder, UserSortField} from "client";
import {Dispatch, SetStateAction} from "react";

interface ITest<T> {
	sort?: T;
	order?: SortOrder;
}

export function abstractOnSortChange<T, S extends ITest<T>>(key: T, filter: S, setFilter: Dispatch<SetStateAction<S>>): () => void {
	return () => {
		if (filter.sort === key && filter.order === SortOrder.asc) {
			setFilter({
				...filter,
				sort: key,
				order: SortOrder.desc,
				offset: 0,
			});
		} else if (filter.sort === key && filter.order === SortOrder.desc) {
			setFilter({
				...filter,
				sort: undefined,
				order: undefined,
				offset: 0,
			});
		} else {
			setFilter({
				...filter,
				sort: key,
				order: SortOrder.asc,
				offset: 0,
			});
		}
	}
}

// TODO: This is the old function with the type error
// export function getTableHeaderSortClassName<T, S extends ITest<T>>(key: T, filter: S): "allied-table-header-sort-asc" | "allied-table-header-sort-desc" {
// 	if (filter.sort === key && filter.order === SortOrder.asc) {
// 		return "allied-table-header-sort-asc";
// 	} else if (filter.sort === key && filter.order === SortOrder.desc) {
// 		return "allied-table-header-sort-desc";
// 	}
//
// 	return undefined;
// }

export function getTableHeaderSortClassName<T>(key: T, filter: any): "allied-table-header-sort-asc" | "allied-table-header-sort-desc" {
	if (filter.sort === key && filter.order === SortOrder.asc) {
		return "allied-table-header-sort-asc";
	} else if (filter.sort === key && filter.order === SortOrder.desc) {
		return "allied-table-header-sort-desc";
	}

	return undefined;
}
