import React from "react";
import {FormsApi, ServiceReport, Token} from "client";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/MetaActions";
import getConfig from "../../../utils/getConfig";
import {connect} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import AbstractPrintableButtonManager from "./AbstractPrintableButtonManager";
import ServiceReportPrintable from "../forms/ServiceReportPrintable";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	id: string;
	children: string;
	showPrintable?: boolean;
	className?: string;
}

const ViewServiceReportButton: React.FC<IProps> = (props) => {

	/**
	 * Call api to get & return the service report.
	 *
	 */
	async function getServiceReport(): Promise<ServiceReport> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FormsApi(getConfig(props.fullToken)).getServiceReport({
				serviceReportID: props.id,
			});

			props.dispatch(decrementLoading());
			return res;
		} catch (e) {
			props.dispatch(addError(e));
			props.dispatch(decrementLoading());
		}
	}

	return (
		<AbstractPrintableButtonManager
			<ServiceReport>
			color="black"
			onClick={getServiceReport}
			component={ServiceReportPrintable}
			showPrintable={props.showPrintable}
			className={props.className}
			documentTitle="service-report"
		>
			View Service Report
		</AbstractPrintableButtonManager>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ViewServiceReportButton);
