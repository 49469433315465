import {Token, User} from "client";

export interface IMetaStore {
	fullToken?: Token;
	currentUser?: User;
	loadingIncrement?: number;
	errors?: Array<any>;
	sidebarVisible: boolean;
}

export default {
	loadingIncrement: 0,
	errors: [],
	sidebarVisible: false,
} as IMetaStore;
