import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Equipment, EquipmentApi, GetEquipmentListResponse, Site, Token} from "client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button} from "reactstrap";
import AlliedTableContainer from "../tables/AlliedTableContainer";
import AlliedTableHeaderCell from "../tables/cells/AlliedTableHeaderCell";
import {getEquipmentTypeDisplay} from "../../utils/getEquipmentTypeDisplay";
import {TableData} from "frame-one-table/build/contextTypes";
import {ColumnOption} from "frame-one-table/build/TableGenerator";
import ManageSitesEditEquipmentCell from "../tables/cells/ManageSitesEditEquipmentCell";
import ManageSitesAddEquipmentModal from "./ManageSitesAddEquipmentModal";
import {valueOrBust} from "../../utils/valueOrBust";
import {getCraneTypeDisplay} from "../../utils/getCraneTypeDisplay";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	site: Site;
	onClose: () => void;
	onDone: () => void;
}

const ManageSitesEquipmentModalAdminView: React.FC<IProps> = (props) => {

	const [equipmentResponse, setEquipmentResponse] = useState<GetEquipmentListResponse>(undefined);
	const [showAddEquipmentModal, setShowAddEquipmentModal] = useState(false);

	useEffect(() => {
		if (props.isOpen) {
			getEquipmentForSite().then().catch();
		}
	}, [props.isOpen]);

	/**
	 * Get all the equipment for this site.
	 *
	 */
	async function getEquipmentForSite(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new EquipmentApi(getConfig(props.fullToken)).getEquipmentList({
				limit: 10000,
				offset: 0,
				site: [props.site?._id],
			});

			setEquipmentResponse(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Toggle visibility of the add equipment modal.
	 *
	 */
	function toggleAddEquipmentModal(): void {
		setShowAddEquipmentModal(!showAddEquipmentModal);
	}

	/**
	 * Renderer for the Edit Equipment column.
	 *
	 * @param equipment
	 */
	function makeEditEquipmentCell(equipment: Equipment): ReactNode {
		return (
			<ManageSitesEditEquipmentCell
				equipment={equipment}
				onDone={getEquipmentForSite}
			/>
		);
	}

	/**
	 * Handle closing the add modal & calling api to get updated equipmenet list.
	 *
	 */
	function onDoneAddEquipment(): void {
		setShowAddEquipmentModal(false);
		getEquipmentForSite().then().catch();
		props.onDone();
	}

	return (
		<React.Fragment>
			<ManageSitesAddEquipmentModal
				isOpen={showAddEquipmentModal}
				site={props.site}
				onClose={toggleAddEquipmentModal}
				onDone={onDoneAddEquipment}
			/>

			<AlliedModal
				isOpen={props.isOpen}
				size="xl"
			>
				<AlliedModalHeader
					title={`View/Edit Equipment for ${props.site?.name}`}
					onClose={props.onClose}
				/>

				<hr/>

				<div>
					<Button
						color="black"
						onClick={toggleAddEquipmentModal}
						className="mb-3"
					>
						Add Equipment
					</Button>
				</div>

				<AlliedTableContainer
					data={equipmentResponse?.equipment}
					columnOptions={[
						{
							headerValue: "Make",
							key: "make",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
						},
						{
							headerValue: "Model",
							key: "model",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
						},
						{
							headerValue: "S/N",
							key: "serialNumber",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
						},
						{
							headerValue: "Equipment",
							key: "type",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getEquipmentTypeDisplay,
						},
						{
							headerValue: "Crane Type",
							key: "craneType",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: (value: any) => valueOrBust(getCraneTypeDisplay(value)),
						},
						{
							headerValue: "Edit Equipment",
							key: undefined,
							showSortIcons: false,
							sortable: false,
							headerRender: AlliedTableHeaderCell,
							cellRender: makeEditEquipmentCell,
							headerCellClassName: "justify-content-center",
						},
					]}
				/>
			</AlliedModal>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageSitesEquipmentModalAdminView);
