import React from "react";
import {CreateLogCommentBody} from "client";
import AlliedInputRadio from "./AlliedInputRadio";

interface IProps {
	value: CreateLogCommentBody;
	onChange: (_newValue: CreateLogCommentBody) => void;
	index: number;
	radioNameSuffix?: string;
}

const DailyLogCommentInput: React.FC<IProps> = (props) => {

	/**
	 * Handle the text input changing.
	 *
	 * @param e
	 */
	function onTextChangeHelper(e: React.ChangeEvent<HTMLInputElement>): void {
		props.onChange({
			...props.value,
			comment: e.target.value,
		});
	}

	/**
	 * Handle toggling the "flagged" radio.
	 *
	 */
	function toggleFlaggedRadio(): void {
		props.onChange({
			...props.value,
			flagged: !props.value?.flagged,
		});
	}

	return (
		<div className="w-100">
			<div className="d-flex mb-2">
				<AlliedInputRadio
					label="Flag this comment"
					name={`daily-log-input-${props.index}${props.radioNameSuffix}`}
					key={`daily-log-input-${props.index}${props.radioNameSuffix}`}
					checked={props.value?.flagged === true}
					onChange={toggleFlaggedRadio}
				/>
			</div>

			<input
				placeholder="Add Comment..."
				value={props.value?.comment}
				onChange={onTextChangeHelper}
			/>
		</div>
	);
};

export default DailyLogCommentInput;
