import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	CraneDailyLogGroup,
	CraneDailyLogSortField,
	Equipment,
	FormsApi,
	GetCraneDailyLogListRequest,
	GetCraneDailyLogListResponse,
	Site,
	Token,
	UserType
} from "client";
import AlliedContainer from "../../components/AlliedContainer";
import AlliedPageHeader from "../../components/AlliedPageHeader";
import AlliedTableContainer from "../../components/tables/AlliedTableContainer";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {cleanRequestForAPISubmission} from "../../utils/cleanRequestForAPISubmission";
import {TableData} from "frame-one-table/build/contextTypes";
import {ColumnOption} from "frame-one-table/build/TableGenerator";
import AlliedTableHeaderCell from "../../components/tables/cells/AlliedTableHeaderCell";
import {getWeekRangeForTable} from "../../utils/getWeekRangeForTable";
import {getGenericObjectProperty} from "../../utils/getGenericObjectProperty";
import CraneDailyLogsViewCell from "../../components/tables/cells/CraneDailyLogsViewCell";
import getConfig from "../../utils/getConfig";
import CraneDailyLogsFilterModal from "../../components/modals/CraneDailyLogsFilterModal";
import {userTypeCheck} from "../../utils/userTypeCheck";
import CraneDailyLogsOperatorOptionsCell from "../../components/tables/cells/CraneDailyLogsOperatorOptionsCell";
import CraneDailyLogsManagerAndSupervisorOptionsCell
	from "../../components/tables/cells/CraneDailyLogsManagerAndSupervisorOptionsCell";
import {parseDateForTableDisplay} from "../../utils/parseDateForTableDisplay";
import classNames from "classnames";
import {formatLastUpdatedLogDateAndFlaggedForGroupedDailyLogs} from "../../utils/formatLastUpdatedLogDateAndFlagged";
import {getFormPublishedStatus} from "../../utils/getFormPublishedStatus";
import {getFormApprovedStatus} from "../../utils/getFormApprovedStatus";
import CraneDailyLogsAdminsViewCell from "../../components/tables/cells/CraneDailyLogsAdminsViewCell";
import {alliedPaginationLimitOptions} from "../../components/tables/paginator/AlliedPaginatorLimitController";
import {convertOffsetDate} from "../../utils/timeZoneConversions";
import {abstractOnSortChange, getTableHeaderSortClassName} from "../../utils/abstractOnSortChange";
import CraneDailyLogsAdminUnlockCell from "../../components/tables/cells/CraneDailyLogsAdminUnlockCell";
import {getAllOperatorsFromGroupedDailyLogs} from "../../utils/getAllOperatorsFromGroupedDailyLogs";
import DailyLogsGroupViewHistoryCell from "../../components/tables/cells/DailyLogsGroupViewHistoryCell";
import moment from "moment/moment";

interface IProps {
	dispatch?: any;
	fullToken: Token;
}

const defaultFilter: GetCraneDailyLogListRequest = {
	limit: alliedPaginationLimitOptions[0],
	offset: 0,
	craneType: undefined,
	weekOf: [],
	make: [],
	model: [],
	serialNumber: [],
	site: [],
	user: [],
}

const CraneDailyLogsPage: React.FC<IProps> = (props) => {

	const [showFilterModal, setShowFilterModal] = useState(false);
	const [filter, setFilter] = useState<GetCraneDailyLogListRequest>(defaultFilter);
	const [logsResponse, setLogsResponse] = useState<GetCraneDailyLogListResponse>(undefined);

	useEffect(() => {
		getDailyLogHistory().then().catch();
	}, [JSON.stringify(filter)]);

	/**
	 * Call api to get the crane daily logs.
	 *
	 */
	async function getDailyLogHistory(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FormsApi(getConfig(props.fullToken)).getCraneDailyLogList(cleanRequestForAPISubmission({
				...filter,
				weekOf: filter?.weekOf?.map(w => convertOffsetDate(w)),
			} as GetCraneDailyLogListRequest));
			setLogsResponse(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Toggles visibility of the filter modal.
	 *
	 */
	function toggleFilterModal(): void {
		setShowFilterModal(!showFilterModal);
	}

	/**
	 * When finished the filter modal, save the new filter (will prompt api via useEffect),
	 * and hide the filter modal.
	 *
	 * @param newFilter
	 */
	function onDoneFilterModal(newFilter: GetCraneDailyLogListRequest): void {
		console.log("new filter:", newFilter);
		setFilter({
			...newFilter,
			offset: 0,
		});
		setShowFilterModal(false);
	}

	/**
	 * Renderer for the View Logs column.
	 *
	 * @param value
	 * @param row
	 * @param key
	 * @param data
	 * @param column
	 * @param index
	 */
	function makeViewCell(value: any, row: CraneDailyLogGroup, key: string | number, data: TableData, column: ColumnOption, index?: number): ReactNode {
		return (
			<CraneDailyLogsViewCell
				key={"view" + row._id}
				logs={row}
			/>
		);
	}

	/**
	 * Renderer of the Operator's version of the Options column.
	 *
	 * @param logs
	 */
	function makeOperatorOptionsCell(logs: CraneDailyLogGroup): ReactNode {
		return (
			<CraneDailyLogsOperatorOptionsCell
				key={"options" + logs._id}
				logs={logs}
				onDone={getDailyLogHistory}
			/>
		);
	}

	/**
	 * Renderer of the Manager & Supervisor versions of the Options column.
	 *
	 * @param logs
	 */
	function makeManagerAndSupervisorOptionsCell(logs: CraneDailyLogGroup): ReactNode {
		return (
			<CraneDailyLogsManagerAndSupervisorOptionsCell
				key={"manager-supervisor-options" + logs._id}
				logGroup={logs}
				onDone={getDailyLogHistory}
			/>
		);
	}

	/**
	 * Renderer of the Admin's version of the Options column.
	 *
	 * @param logs
	 */
	function makeAdminViewCell(logs: CraneDailyLogGroup): ReactNode {
		return (
			<CraneDailyLogsAdminsViewCell
				key={"admins-view" + logs._id}
				logs={logs}
			/>
		);
	}

	function makeUnlockCell(logs: CraneDailyLogGroup): ReactNode {
		return (
			<CraneDailyLogsAdminUnlockCell
				key={"unlock" + logs._id}
				logGroup={logs}
				onDone={getDailyLogHistory}
			/>
		);
	}

	/**
	 * Renderer for the view history button column.
	 *
	 * @param log
	 */
	function makeViewHistoryCell(log: CraneDailyLogGroup): ReactNode {
		return (
			<DailyLogsGroupViewHistoryCell
				key={"history" + log._id}
				history={log.history}
				logGroup={log}
			/>
		);
	}

	function makeDateListString(weeks: Array<number | string>): string {
		let str = "";
		weeks.forEach((w, i) => {
			if (i > 0) {
				str += ", ";
			}

			str += moment(parseInt(w.toString())).format("MMM DD YYYY");
		});

		return str;
	}

	return (
		<React.Fragment>
			<CraneDailyLogsFilterModal
				isOpen={showFilterModal}
				filter={filter}
				onClose={toggleFilterModal}
				onDone={onDoneFilterModal}
			/>

			<AlliedContainer>
				<AlliedPageHeader
					title="Crane Daily Log History"
					onClick={toggleFilterModal}
				/>
				{filter.weekOf && filter.weekOf.length > 0 && (
					<p>
						{`Showing results from the week(s) of ${makeDateListString(filter.weekOf)}.`}
					</p>
				)}

				<hr/>

				<AlliedTableContainer
					<GetCraneDailyLogListRequest>
					data={logsResponse?.craneDailyLogGroups}
					showPaginator={true}
					paginationInfo={logsResponse?.paginationInfo}
					filter={filter}
					setFilter={setFilter}
					columnOptions={[
						{
							headerValue: "Date Submitted",
							key: "submittedDate",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							rowCellClassName: "font-weight-bold",
							valueFormatter: (date: number) => {
								if (typeof date === "number") {
									return parseDateForTableDisplay(date);
								}

								return "-";
							},
							headerCellClassName: getTableHeaderSortClassName(CraneDailyLogSortField.submittedDate, filter),
							onSort: abstractOnSortChange<CraneDailyLogSortField, GetCraneDailyLogListRequest>(CraneDailyLogSortField.submittedDate, filter, setFilter),
						},
						{
							headerValue: "Last Updated",
							key: undefined,
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							rowCellClassName: (value, cellContextDetails) => classNames("font-weight-bold", formatLastUpdatedLogDateAndFlaggedForGroupedDailyLogs(value, cellContextDetails.row as CraneDailyLogGroup).className),
							valueFormatter: (value, cellContextDetails) => formatLastUpdatedLogDateAndFlaggedForGroupedDailyLogs(value, cellContextDetails.row as CraneDailyLogGroup).value,
							headerCellClassName: getTableHeaderSortClassName(CraneDailyLogSortField.updatedAt, filter),
							onSort: abstractOnSortChange<CraneDailyLogSortField, GetCraneDailyLogListRequest>(CraneDailyLogSortField.updatedAt, filter, setFilter),
						},
						{
							headerValue: "Week",
							key: "date",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getWeekRangeForTable(1),
						},
						{
							headerValue: "Make",
							key: "equipment",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getGenericObjectProperty<Equipment>("make"),
						},
						{
							headerValue: "Model",
							key: "equipment",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getGenericObjectProperty<Equipment>("model"),
						},
						{
							headerValue: "S/N",
							key: "equipment",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getGenericObjectProperty<Equipment>("serialNumber"),
						},
						{
							headerValue: "Site Name",
							key: "originalSite",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getGenericObjectProperty<Site>("name"),
						},
						// (userTypeCheck([UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR, UserType.CRANE_OPERATOR, UserType.HEALTH_AND_SAFETY_OFFICER], props.fullToken) &&
							{
								headerValue: "Operator(s)",
								key: "operator",
								showSortIcons: false,
								sortable: true,
								headerRender: AlliedTableHeaderCell,
								valueFormatter: (value, cellContextDetails) => getAllOperatorsFromGroupedDailyLogs(value, cellContextDetails.row as CraneDailyLogGroup),
							},
						// ),
						(userTypeCheck([UserType.ADMIN], props.fullToken) &&
							{
								headerValue: "Published",
								key: "publishedDate",
								showSortIcons: false,
								sortable: true,
								headerRender: AlliedTableHeaderCell,
								valueFormatter: getFormPublishedStatus,
								headerCellClassName: getTableHeaderSortClassName(CraneDailyLogSortField.publishedDate, filter),
								onSort: abstractOnSortChange<CraneDailyLogSortField, GetCraneDailyLogListRequest>(CraneDailyLogSortField.publishedDate, filter, setFilter),
							}
						),
						(userTypeCheck([UserType.SECONDARY_ADMIN, UserType.SUPERVISOR, UserType.CRANE_OPERATOR], props.fullToken) &&
							{
								headerValue: "Approved",
								key: "approvedBy",
								showSortIcons: false,
								sortable: true,
								headerRender: AlliedTableHeaderCell,
								valueFormatter: getFormApprovedStatus,
							}
						),
						(userTypeCheck([UserType.HEALTH_AND_SAFETY_OFFICER, UserType.ALLIED_CRANE_EMPLOYEE, UserType.CRANE_OPERATOR], props.fullToken) &&
							{
								headerValue: "Daily Log Options",
								key: undefined,
								showSortIcons: false,
								sortable: false,
								headerRender: AlliedTableHeaderCell,
								cellRender: makeOperatorOptionsCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							}
						),
						(userTypeCheck([UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR], props.fullToken) &&
							{
								headerValue: "Daily Log Options",
								key: "dailyLogOptions",
								showSortIcons: false,
								sortable: false,
								headerRender: AlliedTableHeaderCell,
								cellRender: makeManagerAndSupervisorOptionsCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							}
						),
						(userTypeCheck([UserType.ADMIN, UserType.HEALTH_AND_SAFETY_OFFICER], props.fullToken) &&
							{
								headerValue: "View Logs",
								key: "viewLogOptions",
								showSortIcons: false,
								sortable: false,
								headerRender: AlliedTableHeaderCell,
								cellRender: makeAdminViewCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							}
						),
						// (userTypeCheck([UserType.ADMIN], props.fullToken) &&
						// 	{
						// 		headerValue: "Unlock Logs",
						// 		key: "unlockLogs",
						// 		showSortIcons: false,
						// 		sortable: false,
						// 		headerRender: AlliedTableHeaderCell,
						// 		cellRender: makeUnlockCell,
						// 		headerCellClassName: "justify-content-center",
						// 		rowCellClassName: "justify-content-center",
						// 	}
						// ),
						{
							headerValue: "View Edit History",
							key: undefined,
							showSortIcons: false,
							sortable: false,
							headerRender: AlliedTableHeaderCell,
							cellRender: makeViewHistoryCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
					]}
				/>
			</AlliedContainer>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(CraneDailyLogsPage);
