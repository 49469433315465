import React from "react";
import {GeneratorDailyLogGroup, Token} from "client";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/MetaActions";
import {connect} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import AbstractPrintableButtonManager from "./AbstractPrintableButtonManager";
import GeneratorDailyLogPrintable from "../forms/GeneratorDailyLogPrintable";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	groupedGeneratorLogs: GeneratorDailyLogGroup;
	children: string;
	showPrintable?: boolean;
	className?: string;
}

const ViewGeneratorDailyLogButton: React.FC<IProps> = (props) => {

	/**
	 * Call api to get & return the generator daily logs.
	 *
	 */
	async function getGeneratorDailyLogs(): Promise<GeneratorDailyLogGroup> {
		props.dispatch(incrementLoading());

		try {
			props.dispatch(decrementLoading());
			return props.groupedGeneratorLogs;
		} catch (e) {
			props.dispatch(addError(e));
			props.dispatch(decrementLoading());
		}
	}

	return (
		<AbstractPrintableButtonManager
			<GeneratorDailyLogGroup>
			color="black"
			onClick={getGeneratorDailyLogs}
			component={GeneratorDailyLogPrintable}
			showPrintable={props.showPrintable}
			className={props.className}
			documentTitle="generator-daily-logs"
		>
			{props.children}
		</AbstractPrintableButtonManager>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ViewGeneratorDailyLogButton);
