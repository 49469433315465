import React, {ChangeEventHandler, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Token, Site, SitesApi, UpdateSiteBody, CreateSiteBody, Address} from "client";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button, Col, Row} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";

interface IProps {
	isOpen: boolean;
	site: Site;
	onClose: () => void;
}

const ManageSitesViewDetailsModalManagerSupervisorView: React.FC<IProps> = (props) => {

	return (
		<AlliedModal
			isOpen={props.isOpen}
			size="lg"
		>
			<AlliedModalHeader
				title="View Site Details"
				onClose={props.onClose}
			/>

			<hr/>

			<Row>
				<Col xs={12} lg={6} className="mb-3">
					<label>Site Name</label>
					<input
						placeholder="Site Name..."
						value={props.site?.name}
						disabled={true}
					/>
				</Col>

				<Col xs={0} lg={6}/>

				<Col xs={12} lg={6} className="mb-3">
					<label>Address Line 1</label>
					<input
						placeholder="Address Line 1..."
						value={props.site?.address?.addressLine1}
						disabled={true}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Address Line 2</label>
					<input
						placeholder="Address Line 2..."
						value={props.site?.address?.addressLine2}
						disabled={true}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>City</label>
					<input
						placeholder="City..."
						value={props.site?.address?.city}
						disabled={true}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Province</label>
					<input
						placeholder="Province..."
						value={props.site?.address?.state}
						disabled={true}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Country</label>
					<input
						placeholder="Country..."
						value={props.site?.address?.country}
						disabled={true}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Postal Code</label>
					<input
						placeholder="Postal Code..."
						value={props.site?.address?.zipCode}
						disabled={true}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Renter</label>
					<input
						placeholder="Renter Company Name..."
						value={props.site?.renter}
						disabled={true}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Contact</label>
					<input
						placeholder="Owner Contact Name..."
						value={props.site?.siteOwner}
						disabled={true}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Contact Number</label>
					<input
						placeholder="Contact Number..."
						value={props.site?.contactNumber}
						disabled={true}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Contact Email</label>
					<input
						placeholder="Contact Email..."
						value={props.site?.contactEmail}
						disabled={true}
					/>
				</Col>
			</Row>
		</AlliedModal>
	);
};

export default ManageSitesViewDetailsModalManagerSupervisorView;
