import React from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {FormsApi, ServiceReport, Token} from "client";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {useHistory} from "react-router-dom";
import getConfig from "../../utils/getConfig";
import ViewServiceReportButton from "../printables/buttons/ViewServiceReportButton";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	report: ServiceReport;
	onClose: () => void;
	onDone: () => void;
}

const ManageServiceReportsOptionsModal: React.FC<IProps> = (props) => {

	const history = useHistory();

	/**
	 * Send user to the edit page for the Service Report in question.
	 *
	 */
	function onEditServiceReport(): void {
		history.push(`/edit-forms/edit-service-report?id=${props.report?._id}`);
	}

	/**
	 * Call api to submit the Service Report,
	 * and call onDone function from props after to update table.
	 *
	 */
	async function onSubmitServiceReport(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new FormsApi(getConfig(props.fullToken)).submitForm({
				iDBody: {
					id: props.report?._id,
				},
			});
			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Call api to publish the Service Report,
	 * and call onDone function from props after to update table.
	 *
	 */
	async function onPublishServiceReport(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new FormsApi(getConfig(props.fullToken)).publishForm({
				iDBody: {
					id: props.report?._id,
				},
			});
			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<AlliedModal
			isOpen={props.isOpen}
			size="md"
		>
			<AlliedModalHeader
				title="Service Report Options2"
				onClose={props.onClose}
			/>

			<hr/>

			<p>
				*Note that you can only edit the form <b>before</b> it's published.
			</p>

			<div className="d-flex flex-row flex-wrap align-items-start">
				<ViewServiceReportButton
					id={props.report?._id}
					className="mr-3 mb-3"
				>
					View Service Report
				</ViewServiceReportButton>

				<Button
					color="black"
					onClick={onEditServiceReport}
					className="mb-3"
					disabled={props.report?.publishedDate !== undefined}
				>
					Edit Service Report
				</Button>
			</div>

			<hr className="mt-0"/>

			{props.report?.submittedDate === undefined ? (
				<div>
					<Button
						color="red"
						onClick={onSubmitServiceReport}
						disabled={props.report?.publishedDate !== undefined}
					>
						Submit Service Report
					</Button>
				</div>
			) : (
				<div>
					<Button
						color="red"
						onClick={onPublishServiceReport}
						disabled={props.report?.publishedDate !== undefined}
					>
						Publish Service Report
					</Button>
				</div>
			)}
		</AlliedModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageServiceReportsOptionsModal);

