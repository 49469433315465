/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const InspectionStatus = {
    APPROVED: 'APPROVED',
    FAULTY: 'FAULTY',
    NOT_APPLICABLE: 'NOT_APPLICABLE'
};
export function InspectionStatusFromJSON(json) {
    return InspectionStatusFromJSONTyped(json, false);
}
export function InspectionStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function InspectionStatusToJSON(value) {
    return value;
}
