/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { CraneFromJSON, CreateCraneBodyToJSON, CreateGeneratorBodyToJSON, CreateMaterialHoistBodyToJSON, DisableEquipmentBodyToJSON, EnableEquipmentBodyToJSON, EquipmentFromJSON, GeneratorFromJSON, GetEquipmentListFiltersResponseFromJSON, GetEquipmentListResponseFromJSON, MaterialHoistFromJSON, UpdateCraneBodyToJSON, UpdateGeneratorBodyToJSON, UpdateMaterialHoistBodyToJSON, } from '../models';
/**
 *
 */
export class EquipmentApi extends runtime.BaseAPI {
    /**
     */
    async createCraneRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/equipment/cranes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCraneBodyToJSON(requestParameters.createCraneBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CraneFromJSON(jsonValue));
    }
    /**
     */
    async createCrane(requestParameters = {}, initOverrides) {
        const response = await this.createCraneRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async createGeneratorRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/equipment/generators`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateGeneratorBodyToJSON(requestParameters.createGeneratorBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GeneratorFromJSON(jsonValue));
    }
    /**
     */
    async createGenerator(requestParameters = {}, initOverrides) {
        const response = await this.createGeneratorRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async createMaterialHoistRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/equipment/material-hoists`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateMaterialHoistBodyToJSON(requestParameters.createMaterialHoistBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => MaterialHoistFromJSON(jsonValue));
    }
    /**
     */
    async createMaterialHoist(requestParameters = {}, initOverrides) {
        const response = await this.createMaterialHoistRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async disableEquipmentRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Admin", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/equipment/disable`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DisableEquipmentBodyToJSON(requestParameters.disableEquipmentBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentFromJSON(jsonValue));
    }
    /**
     */
    async disableEquipment(requestParameters = {}, initOverrides) {
        const response = await this.disableEquipmentRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async enableEquipmentRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Admin", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/equipment/enable`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EnableEquipmentBodyToJSON(requestParameters.enableEquipmentBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentFromJSON(jsonValue));
    }
    /**
     */
    async enableEquipment(requestParameters = {}, initOverrides) {
        const response = await this.enableEquipmentRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to get equipment
     */
    async getEquipmentRaw(requestParameters, initOverrides) {
        if (requestParameters.equipmentID === null || requestParameters.equipmentID === undefined) {
            throw new runtime.RequiredError('equipmentID', 'Required parameter requestParameters.equipmentID was null or undefined when calling getEquipment.');
        }
        const queryParameters = {};
        if (requestParameters.equipmentID !== undefined) {
            queryParameters['equipmentID'] = requestParameters.equipmentID;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/equipment/get_equipment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentFromJSON(jsonValue));
    }
    /**
     * Used to get equipment
     */
    async getEquipment(requestParameters, initOverrides) {
        const response = await this.getEquipmentRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Gets a paginated list of equipment. If siteID or equipmentType are not passed then it will default to all
     */
    async getEquipmentListRaw(requestParameters, initOverrides) {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getEquipmentList.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getEquipmentList.');
        }
        const queryParameters = {};
        if (requestParameters.showDisabled !== undefined) {
            queryParameters['showDisabled'] = requestParameters.showDisabled;
        }
        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }
        if (requestParameters.craneType) {
            queryParameters['craneType'] = requestParameters.craneType;
        }
        if (requestParameters.ownedBy) {
            queryParameters['ownedBy'] = requestParameters.ownedBy;
        }
        if (requestParameters.rentedBy) {
            queryParameters['rentedBy'] = requestParameters.rentedBy;
        }
        if (requestParameters.make) {
            queryParameters['make'] = requestParameters.make;
        }
        if (requestParameters.model) {
            queryParameters['model'] = requestParameters.model;
        }
        if (requestParameters.serialNumber) {
            queryParameters['serialNumber'] = requestParameters.serialNumber;
        }
        if (requestParameters.site) {
            queryParameters['site'] = requestParameters.site;
        }
        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }
        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/equipment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetEquipmentListResponseFromJSON(jsonValue));
    }
    /**
     * Gets a paginated list of equipment. If siteID or equipmentType are not passed then it will default to all
     */
    async getEquipmentList(requestParameters, initOverrides) {
        const response = await this.getEquipmentListRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to get lists of all possible owners/renters/etc. That can be used to filter for equipment.
     */
    async getEquipmentListFiltersRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.equipmentType !== undefined) {
            queryParameters['equipmentType'] = requestParameters.equipmentType;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/equipment/filters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetEquipmentListFiltersResponseFromJSON(jsonValue));
    }
    /**
     * Used to get lists of all possible owners/renters/etc. That can be used to filter for equipment.
     */
    async getEquipmentListFilters(requestParameters = {}, initOverrides) {
        const response = await this.getEquipmentListFiltersRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async updateCraneRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/equipment/cranes`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCraneBodyToJSON(requestParameters.updateCraneBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CraneFromJSON(jsonValue));
    }
    /**
     */
    async updateCrane(requestParameters = {}, initOverrides) {
        const response = await this.updateCraneRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async updateGeneratorRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/equipment/generators`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateGeneratorBodyToJSON(requestParameters.updateGeneratorBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GeneratorFromJSON(jsonValue));
    }
    /**
     */
    async updateGenerator(requestParameters = {}, initOverrides) {
        const response = await this.updateGeneratorRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async updateMaterialHoistRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/equipment/material-hoists`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMaterialHoistBodyToJSON(requestParameters.updateMaterialHoistBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => MaterialHoistFromJSON(jsonValue));
    }
    /**
     */
    async updateMaterialHoist(requestParameters = {}, initOverrides) {
        const response = await this.updateMaterialHoistRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
