import React, {ReactNode, useEffect, useRef, useState} from "react";
import classNames from "classnames";
import {useOnClickOutside} from "../../hooks/useOnClickOutside";
import {BsChevronDown} from "react-icons/all";
import {cloneDeep, find} from "lodash";
import AutoCompleteListDisplay from "./AutoCompleteListDisplay";
import {unstable_batchedUpdates} from "react-dom";

export interface IAutoCompleteOption {
	value: string;
	label: string;
}

interface IProps {
	placeholder?: string;
	options: Array<IAutoCompleteOption>;
	selections: Array<string>;
	setSelections: (newSelections: Array<string>) => void;
	multiSelect?: boolean; // If true, user can select any number of options. If false, only one option can be chosen at a time. Defaults true.
	showSelections?: boolean; // Controls whether or not to show the list of selections when multiSelect === true, defaults true.
}

const AlliedAutoComplete: React.FC<IProps> = (props) => {

	const inputRef = useRef<any>();
	const autoCompleteRef = useRef();
	useOnClickOutside(autoCompleteRef, handleOnClickOutside);

	const [search, setSearch] = useState("");
	const [showList, setShowList] = useState(false);
	const [lock, setLock] = useState(true);
	const [initialLock, setInitialLock] = useState(true);

	useEffect(() => {
		if (props.options?.length > 0 && props.selections?.length > 0) {
			if (initialLock) {
				handleOnClickOutside();
				setInitialLock(false);
			}
		}

		setShowList(false);
	}, [JSON.stringify(props.options), JSON.stringify(props.selections)]);

	/**
	 * Listen for changes to search value and open or close the list based on if
	 * the user is typing more or has deleted their search by pressing backspace.
	 *
	 */
	useEffect(() => {
		if (lock) {
			setLock(false);
		} else {
			setShowList(true);
		}
	}, [search]);

	/**
	 * For use when multiSelect is false; find and display
	 * the selected option in the text field after choosing.
	 *
	 */
	useEffect(() => {
		if (!props.multiSelect && props.selections?.length > 0) {
			const val: string = find(props.options, ["value", props.selections?.[0]])?.label;
			unstable_batchedUpdates(() => {
				setSearch(val !== undefined ? val : "");
				setLock(true);
			});
		}
	}, [JSON.stringify(props.selections)]);

	/**
	 * Handler for when user clicks off the list so we can close it.
	 *
	 */
	function handleOnClickOutside(): void {
		setShowList(false);

		if (!props.multiSelect && props.selections?.length > 0) {
			const foundValue: string = find(props.options, ["value", props.selections?.[0]])?.label;

			unstable_batchedUpdates(() => {
				setSearch(foundValue !== undefined ? foundValue : "");
				setLock(true);
			});
		} else {
			unstable_batchedUpdates(() => {
				setSearch("");
				setLock(true);
			});
		}
	}

	/**
	 * Open the list when user clicks so they can see that it's an auto-complete input.
	 *
	 */
	function onFocusInput(): void {
		setShowList(true);
		inputRef.current.focus();
	}

	/**
	 * Handle the search input onChange.
	 *
	 * @param e
	 */
	function onChangeSearch(e: React.ChangeEvent<HTMLInputElement>): void {
		setSearch(e?.target?.value);
	}

	/**
	 * Render each list item & attach dynamic functionality based on if input is multiSelect or not.
	 *
	 * @param item
	 * @param i
	 */
	function makeListOption(item: IAutoCompleteOption, i: number): ReactNode {
		if (!item.label.toString().toLowerCase().includes(search?.toLowerCase())) {
			return null;
		}

		const _optionIsSelected: boolean = props.selections?.indexOf(item.value) > -1;

		function onSelect(): void {
			if (props.multiSelect) {
				const clonedSelections: string[] = cloneDeep(props.selections);
				const indexOfSelection: number = props.selections.indexOf(item.value);

				if (indexOfSelection > -1 && _optionIsSelected) {
					clonedSelections.splice(indexOfSelection, 1);
				} else if (indexOfSelection < 0 && !_optionIsSelected) {
					clonedSelections.push(item.value);
				}

				props.setSelections(clonedSelections);
			} else {
				// If not multiselect, see if the user is choosing the already selected value, in which case, send back an empty array.
				if (props.selections?.length > 0 && item.value === props.selections?.[0]) {
					props.setSelections([]);
				} else {
					props.setSelections([item.value]);
				}
			}

			setSearch("");
			setShowList(false);
			setLock(true);
		}

		return (
			<p
				key={`auto-complete-list-item-${i}`}
				onClick={onSelect}
				className={classNames("allied-auto-complete-list-item", {
					"list-item-selected": _optionIsSelected,
				})}
			>
				{item.label}
			</p>
		);
	}

	/**
	 * Used in the return statement to check if we can find at least 1 option that
	 * matches the search, and if we can't then we show an appropriate message.
	 *
	 */
	function checkMatchingResults(): boolean {
		if (search === "" && props.options?.length > 0) {
			return true;
		}

		for (const item of props.options) {
			if (item.label.toString().toLowerCase().includes(search?.toLowerCase())) {
				return true;
			}
		}

		return false;
	}

	return (
		<React.Fragment>
			<div
				ref={autoCompleteRef}
				className="allied-auto-complete"
			>
				<input
					ref={inputRef}
					placeholder={props.placeholder}
					onFocus={onFocusInput}
					value={search}
					onChange={onChangeSearch}
				/>

				<span onClick={onFocusInput}>
					<BsChevronDown/>
				</span>

				{showList && (
					<div className="allied-auto-complete-list">
						{checkMatchingResults() ? (
							<React.Fragment>
								{props.options?.map(makeListOption)}
							</React.Fragment>
						) : (
							<p className="allied-auto-complete-list-empty-item">
								No Results.
							</p>
						)}
					</div>
				)}
			</div>

			{props.multiSelect && props.showSelections && (
				<AutoCompleteListDisplay
					options={props.options}
					selections={props.selections}
					setSelections={props.setSelections}
				/>
			)}
		</React.Fragment>
	);
};

AlliedAutoComplete.defaultProps = {
	multiSelect: true,
	showSelections: true,
};

export default AlliedAutoComplete;
