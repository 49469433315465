/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { UserTypeFromJSON, UserTypeToJSON, } from './UserType';
import { CreateAdminBodyFromJSONTyped, CreateAlliedCraneEmployeeBodyFromJSONTyped, CreateCraneOperatorBodyFromJSONTyped, CreateHealthAndSafetyOfficerBodyFromJSONTyped, CreateSecondaryAdminBodyFromJSONTyped, CreateSupervisorBodyFromJSONTyped } from './';
/**
 * Check if a given object implements the CreateUserBody interface.
 */
export function instanceOfCreateUserBody(value) {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "confirmPassword" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    return isInstance;
}
export function CreateUserBodyFromJSON(json) {
    return CreateUserBodyFromJSONTyped(json, false);
}
export function CreateUserBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['type'] === 'Admin') {
            return CreateAdminBodyFromJSONTyped(json, true);
        }
        if (json['type'] === 'AlliedCraneEmployee') {
            return CreateAlliedCraneEmployeeBodyFromJSONTyped(json, true);
        }
        if (json['type'] === 'CraneOperator') {
            return CreateCraneOperatorBodyFromJSONTyped(json, true);
        }
        if (json['type'] === 'HealthAndSafetyOfficer') {
            return CreateHealthAndSafetyOfficerBodyFromJSONTyped(json, true);
        }
        if (json['type'] === 'SecondaryAdmin') {
            return CreateSecondaryAdminBodyFromJSONTyped(json, true);
        }
        if (json['type'] === 'Supervisor') {
            return CreateSupervisorBodyFromJSONTyped(json, true);
        }
    }
    return {
        'type': UserTypeFromJSON(json['type']),
        'username': json['username'],
        'password': json['password'],
        'confirmPassword': json['confirmPassword'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
    };
}
export function CreateUserBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': UserTypeToJSON(value.type),
        'username': value.username,
        'password': value.password,
        'confirmPassword': value.confirmPassword,
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
    };
}
