import React, {ReactNode} from "react";
import {Modal} from "reactstrap";

interface IProps {
	isOpen?: boolean;
	size?: "sm" | "md" | "lg" | "xl";
	children?: ReactNode;
}

const AlliedModal: React.FC<IProps> = (props) => {

	return (
		<Modal
			isOpen={props.isOpen}
			fade={true}
			centered={true}
			size={props.size}
			contentClassName="allied-modal"
		>
			{props.children}
		</Modal>
	);
};

AlliedModal.defaultProps = {
	size: "lg",
};

export default AlliedModal;
