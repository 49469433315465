import React from "react";
import {Button} from "reactstrap";
import {useHistory} from "react-router-dom";
import {GeneratorDailyLog, GeneratorDailyLogGroup} from "client";
import {GeneratorDailyLogWithDate} from "../../../utils/getDateOfIndividualDailyLogBasedOnDay";

interface IProps {
	log: GeneratorDailyLogWithDate;
	logGroup: GeneratorDailyLogGroup;
}

const GeneratorDailyLogsEditIndividualLogCell: React.FC<IProps> = (props) => {

	const history = useHistory();

	/**
	 * Navigate to the Edit page for the log.
	 *
	 */
	function goToEdit(): void {
		history.push(`/edit-forms/edit-daily-log/edit-generator-daily-log?id=${props.logGroup?._id}&day=${props.log.day}`);
	}

	return (
		<div className="d-flex justify-content-center">
			<Button
				color="black"
				onClick={goToEdit}
				disabled={props.logGroup.submittedDate !== undefined}
			>
				Edit Log
			</Button>
		</div>
	);
};

export default GeneratorDailyLogsEditIndividualLogCell;
