/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { AddNotesToServiceReportBodyToJSON, AddNotesToTimeSheetBodyToJSON, AddReferenceToTimeSheetEntryBodyToJSON, AddTimeSheetEntryBodyToJSON, AdminAddTimeSheetEntryToUserTimeSheetToJSON, CraneDailyLogGroupFromJSON, CraneMonthlyLogFromJSON, CraneWeeklyLogFromJSON, CreateCraneMonthlyLogBodyToJSON, CreateCraneMonthlyLogResponseFromJSON, CreateCraneWeeklyLogBodyToJSON, CreateCraneWeeklyLogResponseFromJSON, CreateDeficiencyBodyToJSON, CreateFinalTestFormBodyToJSON, CreateFinalTestFormResponseFromJSON, CreateGeneratorDailyLogBodyToJSON, CreateLogCommentBodyToJSON, CreateLuffingTowerCraneDailyLogBodyToJSON, CreateSelfErectingCraneDailyLogBodyToJSON, CreateServiceReportBodyToJSON, CreateServiceReportResponseFromJSON, CreateTowerCraneDailyLogBodyToJSON, DeficiencyFromJSON, DeleteCraneDailyLogBodyToJSON, DeleteGeneratorDailyLogBodyToJSON, FinalTestFormFromJSON, FormFromJSON, GeneratorDailyLogGroupFromJSON, GetCraneDailyLogListResponseFromJSON, GetCraneLogListFiltersResponseFromJSON, GetCraneMonthlyLogListResponseFromJSON, GetCraneWeeklyLogListResponseFromJSON, GetDeficiencyListForEquipmentResponseFromJSON, GetFinalTestFormListFiltersResponseFromJSON, GetFinalTestFormListResponseFromJSON, GetGeneratorDailyLogListFiltersResponseFromJSON, GetGeneratorDailyLogListResponseFromJSON, GetServiceReportListFiltersResponseFromJSON, GetServiceReportListResponseFromJSON, GetTimeSheetListFiltersResponseFromJSON, GetTimeSheetListResponseFromJSON, IDBodyToJSON, ResolveDeficiencyBodyToJSON, ServiceReportFromJSON, TimeSheetFromJSON, TimeSheetEntryFromJSON, UpdateCraneMonthlyLogBodyToJSON, UpdateCraneWeeklyLogBodyToJSON, UpdateDeficiencyBodyToJSON, UpdateFinalTestFormBodyToJSON, UpdateGeneratorDailyLogBodyToJSON, UpdateLogCommentBodyToJSON, UpdateLuffingTowerCraneDailyLogBodyToJSON, UpdateSelfErectingCraneDailyLogBodyToJSON, UpdateServiceReportBodyToJSON, UpdateTimeSheetEntryBodyToJSON, UpdateTowerCraneDailyLogBodyToJSON, } from '../models';
/**
 *
 */
export class FormsApi extends runtime.BaseAPI {
    /**
     * Used to add notes to a service report
     */
    async addNotesToServiceReportRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/add_notes_to_service_report`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddNotesToServiceReportBodyToJSON(requestParameters.addNotesToServiceReportBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used to add notes to a service report
     */
    async addNotesToServiceReport(requestParameters = {}, initOverrides) {
        await this.addNotesToServiceReportRaw(requestParameters, initOverrides);
    }
    /**
     * Used to add notes to a time sheet
     */
    async addNotesToTimeSheetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/add_notes_to_time_sheet`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddNotesToTimeSheetBodyToJSON(requestParameters.addNotesToTimeSheetBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used to add notes to a time sheet
     */
    async addNotesToTimeSheet(requestParameters = {}, initOverrides) {
        await this.addNotesToTimeSheetRaw(requestParameters, initOverrides);
    }
    /**
     * Used to add a reference to a time sheet entry
     */
    async addReferenceToTimeSheetEntryRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/add_reference_to_time_sheet_entry`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddReferenceToTimeSheetEntryBodyToJSON(requestParameters.addReferenceToTimeSheetEntryBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used to add a reference to a time sheet entry
     */
    async addReferenceToTimeSheetEntry(requestParameters = {}, initOverrides) {
        await this.addReferenceToTimeSheetEntryRaw(requestParameters, initOverrides);
    }
    /**
     * Used to create new time sheet entries. Will create a new time sheet if one does not exist
     */
    async addTimeSheetEntryRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/add_time_sheet_entry`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddTimeSheetEntryBodyToJSON(requestParameters.addTimeSheetEntryBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used to create new time sheet entries. Will create a new time sheet if one does not exist
     */
    async addTimeSheetEntry(requestParameters = {}, initOverrides) {
        await this.addTimeSheetEntryRaw(requestParameters, initOverrides);
    }
    /**
     * Used by admins to create new time sheet entries on existing \"submitted\" employee time sheets.
     */
    async adminAddTimeSheetEntryForUserRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/admin_add_time_sheet_entry_for_user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminAddTimeSheetEntryToUserTimeSheetToJSON(requestParameters.adminAddTimeSheetEntryToUserTimeSheet),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by admins to create new time sheet entries on existing \"submitted\" employee time sheets.
     */
    async adminAddTimeSheetEntryForUser(requestParameters = {}, initOverrides) {
        await this.adminAddTimeSheetEntryForUserRaw(requestParameters, initOverrides);
    }
    /**
     * Used to create new monthly logs for cranes
     */
    async createCraneMonthlyLogRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("CraneOperator", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/create_crane_monthly_log`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCraneMonthlyLogBodyToJSON(requestParameters.createCraneMonthlyLogBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCraneMonthlyLogResponseFromJSON(jsonValue));
    }
    /**
     * Used to create new monthly logs for cranes
     */
    async createCraneMonthlyLog(requestParameters = {}, initOverrides) {
        const response = await this.createCraneMonthlyLogRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to create new weekly logs for cranes
     */
    async createCraneWeeklyLogRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("CraneOperator", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/create_crane_weekly_log`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCraneWeeklyLogBodyToJSON(requestParameters.createCraneWeeklyLogBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCraneWeeklyLogResponseFromJSON(jsonValue));
    }
    /**
     * Used to create new weekly logs for cranes
     */
    async createCraneWeeklyLog(requestParameters = {}, initOverrides) {
        const response = await this.createCraneWeeklyLogRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async createDeficiencyRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/deficiencies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDeficiencyBodyToJSON(requestParameters.createDeficiencyBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => DeficiencyFromJSON(jsonValue));
    }
    /**
     */
    async createDeficiency(requestParameters = {}, initOverrides) {
        const response = await this.createDeficiencyRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to create new final test forms
     */
    async createFinalTestFormRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/final-test-forms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFinalTestFormBodyToJSON(requestParameters.createFinalTestFormBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateFinalTestFormResponseFromJSON(jsonValue));
    }
    /**
     * Used to create new final test forms
     */
    async createFinalTestForm(requestParameters = {}, initOverrides) {
        const response = await this.createFinalTestFormRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to create new daily logs for generators.
     */
    async createGeneratorDailyLogRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("CraneOperator", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/generator-daily-log`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateGeneratorDailyLogBodyToJSON(requestParameters.createGeneratorDailyLogBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GeneratorDailyLogGroupFromJSON(jsonValue));
    }
    /**
     * Used to create new daily logs for generators.
     */
    async createGeneratorDailyLog(requestParameters = {}, initOverrides) {
        const response = await this.createGeneratorDailyLogRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to create new log comments
     */
    async createLogCommentRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/log-comments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLogCommentBodyToJSON(requestParameters.createLogCommentBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used to create new log comments
     */
    async createLogComment(requestParameters = {}, initOverrides) {
        await this.createLogCommentRaw(requestParameters, initOverrides);
    }
    /**
     * Used to create new daily logs for luffing tower cranes.
     */
    async createLuffingTowerCraneDailyLogRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("CraneOperator", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/luffing-tower-crane-daily-log`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLuffingTowerCraneDailyLogBodyToJSON(requestParameters.createLuffingTowerCraneDailyLogBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CraneDailyLogGroupFromJSON(jsonValue));
    }
    /**
     * Used to create new daily logs for luffing tower cranes.
     */
    async createLuffingTowerCraneDailyLog(requestParameters = {}, initOverrides) {
        const response = await this.createLuffingTowerCraneDailyLogRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to create new daily logs for self erecting cranes.
     */
    async createSelfErectingCraneDailyLogRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("CraneOperator", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/self-erecting-crane-daily-log`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSelfErectingCraneDailyLogBodyToJSON(requestParameters.createSelfErectingCraneDailyLogBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CraneDailyLogGroupFromJSON(jsonValue));
    }
    /**
     * Used to create new daily logs for self erecting cranes.
     */
    async createSelfErectingCraneDailyLog(requestParameters = {}, initOverrides) {
        const response = await this.createSelfErectingCraneDailyLogRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async createServiceReportRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/service-reports`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateServiceReportBodyToJSON(requestParameters.createServiceReportBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateServiceReportResponseFromJSON(jsonValue));
    }
    /**
     */
    async createServiceReport(requestParameters = {}, initOverrides) {
        const response = await this.createServiceReportRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to create new daily logs for tower cranes.
     */
    async createTowerCraneDailyLogRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("CraneOperator", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/tower-crane-daily-log`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTowerCraneDailyLogBodyToJSON(requestParameters.createTowerCraneDailyLogBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CraneDailyLogGroupFromJSON(jsonValue));
    }
    /**
     * Used to create new daily logs for tower cranes.
     */
    async createTowerCraneDailyLog(requestParameters = {}, initOverrides) {
        const response = await this.createTowerCraneDailyLogRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async deleteCraneDailyLogRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deleteCraneDailyLog.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("CraneOperator", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/crane-daily-log/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteCraneDailyLogBodyToJSON(requestParameters.deleteCraneDailyLogBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deleteCraneDailyLog(requestParameters, initOverrides) {
        await this.deleteCraneDailyLogRaw(requestParameters, initOverrides);
    }
    /**
     */
    async deleteGeneratorDailyLogRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deleteGeneratorDailyLog.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("CraneOperator", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/generator-daily-log/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteGeneratorDailyLogBodyToJSON(requestParameters.deleteGeneratorDailyLogBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deleteGeneratorDailyLog(requestParameters, initOverrides) {
        await this.deleteGeneratorDailyLogRaw(requestParameters, initOverrides);
    }
    /**
     */
    async disableFormRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/disable`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IDBodyToJSON(requestParameters.iDBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FormFromJSON(jsonValue));
    }
    /**
     */
    async disableForm(requestParameters = {}, initOverrides) {
        const response = await this.disableFormRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async disableTimeSheetEntryRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling disableTimeSheetEntry.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/time-sheet-entries/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TimeSheetEntryFromJSON(jsonValue));
    }
    /**
     */
    async disableTimeSheetEntry(requestParameters, initOverrides) {
        const response = await this.disableTimeSheetEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getCraneDailyLogRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getCraneDailyLog.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/crane-daily-log/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CraneDailyLogGroupFromJSON(jsonValue));
    }
    /**
     */
    async getCraneDailyLog(requestParameters, initOverrides) {
        const response = await this.getCraneDailyLogRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getCraneDailyLogListRaw(requestParameters, initOverrides) {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getCraneDailyLogList.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getCraneDailyLogList.');
        }
        const queryParameters = {};
        if (requestParameters.craneType !== undefined) {
            queryParameters['craneType'] = requestParameters.craneType;
        }
        if (requestParameters.weekOf) {
            queryParameters['weekOf'] = requestParameters.weekOf;
        }
        if (requestParameters.make) {
            queryParameters['make'] = requestParameters.make;
        }
        if (requestParameters.model) {
            queryParameters['model'] = requestParameters.model;
        }
        if (requestParameters.serialNumber) {
            queryParameters['serialNumber'] = requestParameters.serialNumber;
        }
        if (requestParameters.site) {
            queryParameters['site'] = requestParameters.site;
        }
        if (requestParameters.user) {
            queryParameters['user'] = requestParameters.user;
        }
        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }
        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/crane-daily-logs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetCraneDailyLogListResponseFromJSON(jsonValue));
    }
    /**
     */
    async getCraneDailyLogList(requestParameters, initOverrides) {
        const response = await this.getCraneDailyLogListRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to get lists of all possible sites/owners/etc. That can be used to filter for crane logs.
     */
    async getCraneLogListFiltersRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/get_crane_log_list_filters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetCraneLogListFiltersResponseFromJSON(jsonValue));
    }
    /**
     * Used to get lists of all possible sites/owners/etc. That can be used to filter for crane logs.
     */
    async getCraneLogListFilters(initOverrides) {
        const response = await this.getCraneLogListFiltersRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async getCraneMonthlyLogRaw(requestParameters, initOverrides) {
        if (requestParameters.craneMonthlyLogID === null || requestParameters.craneMonthlyLogID === undefined) {
            throw new runtime.RequiredError('craneMonthlyLogID', 'Required parameter requestParameters.craneMonthlyLogID was null or undefined when calling getCraneMonthlyLog.');
        }
        const queryParameters = {};
        if (requestParameters.craneMonthlyLogID !== undefined) {
            queryParameters['craneMonthlyLogID'] = requestParameters.craneMonthlyLogID;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/get_crane_monthly_log`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CraneMonthlyLogFromJSON(jsonValue));
    }
    /**
     */
    async getCraneMonthlyLog(requestParameters, initOverrides) {
        const response = await this.getCraneMonthlyLogRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getCraneMonthlyLogListRaw(requestParameters, initOverrides) {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getCraneMonthlyLogList.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getCraneMonthlyLogList.');
        }
        const queryParameters = {};
        if (requestParameters.craneType !== undefined) {
            queryParameters['craneType'] = requestParameters.craneType;
        }
        if (requestParameters.month) {
            queryParameters['month'] = requestParameters.month;
        }
        if (requestParameters.make) {
            queryParameters['make'] = requestParameters.make;
        }
        if (requestParameters.model) {
            queryParameters['model'] = requestParameters.model;
        }
        if (requestParameters.serialNumber) {
            queryParameters['serialNumber'] = requestParameters.serialNumber;
        }
        if (requestParameters.site) {
            queryParameters['site'] = requestParameters.site;
        }
        if (requestParameters.user) {
            queryParameters['user'] = requestParameters.user;
        }
        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }
        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/get_crane_monthly_log_list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetCraneMonthlyLogListResponseFromJSON(jsonValue));
    }
    /**
     */
    async getCraneMonthlyLogList(requestParameters, initOverrides) {
        const response = await this.getCraneMonthlyLogListRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getCraneWeeklyLogRaw(requestParameters, initOverrides) {
        if (requestParameters.craneWeeklyLogID === null || requestParameters.craneWeeklyLogID === undefined) {
            throw new runtime.RequiredError('craneWeeklyLogID', 'Required parameter requestParameters.craneWeeklyLogID was null or undefined when calling getCraneWeeklyLog.');
        }
        const queryParameters = {};
        if (requestParameters.craneWeeklyLogID !== undefined) {
            queryParameters['craneWeeklyLogID'] = requestParameters.craneWeeklyLogID;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/get_crane_weekly_log`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CraneWeeklyLogFromJSON(jsonValue));
    }
    /**
     */
    async getCraneWeeklyLog(requestParameters, initOverrides) {
        const response = await this.getCraneWeeklyLogRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getCraneWeeklyLogListRaw(requestParameters, initOverrides) {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getCraneWeeklyLogList.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getCraneWeeklyLogList.');
        }
        const queryParameters = {};
        if (requestParameters.craneType !== undefined) {
            queryParameters['craneType'] = requestParameters.craneType;
        }
        if (requestParameters.weekOf) {
            queryParameters['weekOf'] = requestParameters.weekOf;
        }
        if (requestParameters.make) {
            queryParameters['make'] = requestParameters.make;
        }
        if (requestParameters.model) {
            queryParameters['model'] = requestParameters.model;
        }
        if (requestParameters.serialNumber) {
            queryParameters['serialNumber'] = requestParameters.serialNumber;
        }
        if (requestParameters.site) {
            queryParameters['site'] = requestParameters.site;
        }
        if (requestParameters.user) {
            queryParameters['user'] = requestParameters.user;
        }
        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }
        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/get_crane_weekly_log_list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetCraneWeeklyLogListResponseFromJSON(jsonValue));
    }
    /**
     */
    async getCraneWeeklyLogList(requestParameters, initOverrides) {
        const response = await this.getCraneWeeklyLogListRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getDeficiencyRaw(requestParameters, initOverrides) {
        if (requestParameters.deficiencyID === null || requestParameters.deficiencyID === undefined) {
            throw new runtime.RequiredError('deficiencyID', 'Required parameter requestParameters.deficiencyID was null or undefined when calling getDeficiency.');
        }
        const queryParameters = {};
        if (requestParameters.deficiencyID !== undefined) {
            queryParameters['deficiencyID'] = requestParameters.deficiencyID;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/get_deficiency`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => DeficiencyFromJSON(jsonValue));
    }
    /**
     */
    async getDeficiency(requestParameters, initOverrides) {
        const response = await this.getDeficiencyRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getDeficiencyListForEquipmentRaw(requestParameters, initOverrides) {
        if (requestParameters.equipment === null || requestParameters.equipment === undefined) {
            throw new runtime.RequiredError('equipment', 'Required parameter requestParameters.equipment was null or undefined when calling getDeficiencyListForEquipment.');
        }
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getDeficiencyListForEquipment.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getDeficiencyListForEquipment.');
        }
        const queryParameters = {};
        if (requestParameters.equipment !== undefined) {
            queryParameters['equipment'] = requestParameters.equipment;
        }
        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }
        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }
        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }
        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/get_deficiency_list_for_equipment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetDeficiencyListForEquipmentResponseFromJSON(jsonValue));
    }
    /**
     */
    async getDeficiencyListForEquipment(requestParameters, initOverrides) {
        const response = await this.getDeficiencyListForEquipmentRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getFinalTestFormRaw(requestParameters, initOverrides) {
        if (requestParameters.finalTestFormID === null || requestParameters.finalTestFormID === undefined) {
            throw new runtime.RequiredError('finalTestFormID', 'Required parameter requestParameters.finalTestFormID was null or undefined when calling getFinalTestForm.');
        }
        const queryParameters = {};
        if (requestParameters.finalTestFormID !== undefined) {
            queryParameters['finalTestFormID'] = requestParameters.finalTestFormID;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/get_final_test_form`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FinalTestFormFromJSON(jsonValue));
    }
    /**
     */
    async getFinalTestForm(requestParameters, initOverrides) {
        const response = await this.getFinalTestFormRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getFinalTestFormListRaw(requestParameters, initOverrides) {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getFinalTestFormList.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getFinalTestFormList.');
        }
        const queryParameters = {};
        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }
        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }
        if (requestParameters.make) {
            queryParameters['make'] = requestParameters.make;
        }
        if (requestParameters.model) {
            queryParameters['model'] = requestParameters.model;
        }
        if (requestParameters.serialNumber) {
            queryParameters['serialNumber'] = requestParameters.serialNumber;
        }
        if (requestParameters.equipment) {
            queryParameters['equipment'] = requestParameters.equipment;
        }
        if (requestParameters.site) {
            queryParameters['site'] = requestParameters.site;
        }
        if (requestParameters.user) {
            queryParameters['user'] = requestParameters.user;
        }
        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }
        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/get_final_test_form_list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetFinalTestFormListResponseFromJSON(jsonValue));
    }
    /**
     */
    async getFinalTestFormList(requestParameters, initOverrides) {
        const response = await this.getFinalTestFormListRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to get lists of all possible names/owners/etc. That can be used to filter for final test forms.
     */
    async getFinalTestFormListFiltersRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/get_final_test_form_list_filters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetFinalTestFormListFiltersResponseFromJSON(jsonValue));
    }
    /**
     * Used to get lists of all possible names/owners/etc. That can be used to filter for final test forms.
     */
    async getFinalTestFormListFilters(initOverrides) {
        const response = await this.getFinalTestFormListFiltersRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async getGeneratorDailyLogRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getGeneratorDailyLog.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/generator-daily-log/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GeneratorDailyLogGroupFromJSON(jsonValue));
    }
    /**
     */
    async getGeneratorDailyLog(requestParameters, initOverrides) {
        const response = await this.getGeneratorDailyLogRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getGeneratorDailyLogListRaw(requestParameters, initOverrides) {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getGeneratorDailyLogList.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getGeneratorDailyLogList.');
        }
        const queryParameters = {};
        if (requestParameters.weekOf) {
            queryParameters['weekOf'] = requestParameters.weekOf;
        }
        if (requestParameters.make) {
            queryParameters['make'] = requestParameters.make;
        }
        if (requestParameters.model) {
            queryParameters['model'] = requestParameters.model;
        }
        if (requestParameters.serialNumber) {
            queryParameters['serialNumber'] = requestParameters.serialNumber;
        }
        if (requestParameters.site) {
            queryParameters['site'] = requestParameters.site;
        }
        if (requestParameters.user) {
            queryParameters['user'] = requestParameters.user;
        }
        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }
        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/generator-daily-logs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetGeneratorDailyLogListResponseFromJSON(jsonValue));
    }
    /**
     */
    async getGeneratorDailyLogList(requestParameters, initOverrides) {
        const response = await this.getGeneratorDailyLogListRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to get lists of all possible sites/owners/etc. That can be used to filter for generator daily logs.
     */
    async getGeneratorDailyLogListFiltersRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/get_generator_daily_log_list_filters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetGeneratorDailyLogListFiltersResponseFromJSON(jsonValue));
    }
    /**
     * Used to get lists of all possible sites/owners/etc. That can be used to filter for generator daily logs.
     */
    async getGeneratorDailyLogListFilters(initOverrides) {
        const response = await this.getGeneratorDailyLogListFiltersRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async getServiceReportRaw(requestParameters, initOverrides) {
        if (requestParameters.serviceReportID === null || requestParameters.serviceReportID === undefined) {
            throw new runtime.RequiredError('serviceReportID', 'Required parameter requestParameters.serviceReportID was null or undefined when calling getServiceReport.');
        }
        const queryParameters = {};
        if (requestParameters.serviceReportID !== undefined) {
            queryParameters['serviceReportID'] = requestParameters.serviceReportID;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/get_service_report`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceReportFromJSON(jsonValue));
    }
    /**
     */
    async getServiceReport(requestParameters, initOverrides) {
        const response = await this.getServiceReportRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getServiceReportListRaw(requestParameters, initOverrides) {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getServiceReportList.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getServiceReportList.');
        }
        const queryParameters = {};
        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }
        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }
        if (requestParameters.make) {
            queryParameters['make'] = requestParameters.make;
        }
        if (requestParameters.model) {
            queryParameters['model'] = requestParameters.model;
        }
        if (requestParameters.serialNumber) {
            queryParameters['serialNumber'] = requestParameters.serialNumber;
        }
        if (requestParameters.equipment) {
            queryParameters['equipment'] = requestParameters.equipment;
        }
        if (requestParameters.site) {
            queryParameters['site'] = requestParameters.site;
        }
        if (requestParameters.user) {
            queryParameters['user'] = requestParameters.user;
        }
        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }
        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/get_service_report_list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetServiceReportListResponseFromJSON(jsonValue));
    }
    /**
     */
    async getServiceReportList(requestParameters, initOverrides) {
        const response = await this.getServiceReportListRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to get lists of all possible names/owners/etc. That can be used to filter for service reports.
     */
    async getServiceReportListFiltersRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/get_service_report_list_filters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetServiceReportListFiltersResponseFromJSON(jsonValue));
    }
    /**
     * Used to get lists of all possible names/owners/etc. That can be used to filter for service reports.
     */
    async getServiceReportListFilters(initOverrides) {
        const response = await this.getServiceReportListFiltersRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async getTimeSheetRaw(requestParameters, initOverrides) {
        if (requestParameters.timeSheetID === null || requestParameters.timeSheetID === undefined) {
            throw new runtime.RequiredError('timeSheetID', 'Required parameter requestParameters.timeSheetID was null or undefined when calling getTimeSheet.');
        }
        const queryParameters = {};
        if (requestParameters.timeSheetID !== undefined) {
            queryParameters['timeSheetID'] = requestParameters.timeSheetID;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/time-sheets/get-one`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TimeSheetFromJSON(jsonValue));
    }
    /**
     */
    async getTimeSheet(requestParameters, initOverrides) {
        const response = await this.getTimeSheetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getTimeSheetEntryRaw(requestParameters, initOverrides) {
        if (requestParameters.timeSheetEntryID === null || requestParameters.timeSheetEntryID === undefined) {
            throw new runtime.RequiredError('timeSheetEntryID', 'Required parameter requestParameters.timeSheetEntryID was null or undefined when calling getTimeSheetEntry.');
        }
        const queryParameters = {};
        if (requestParameters.timeSheetEntryID !== undefined) {
            queryParameters['timeSheetEntryID'] = requestParameters.timeSheetEntryID;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/get_time_sheet_entry`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TimeSheetEntryFromJSON(jsonValue));
    }
    /**
     */
    async getTimeSheetEntry(requestParameters, initOverrides) {
        const response = await this.getTimeSheetEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getTimeSheetListRaw(requestParameters, initOverrides) {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getTimeSheetList.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getTimeSheetList.');
        }
        const queryParameters = {};
        if (requestParameters.weekOf) {
            queryParameters['weekOf'] = requestParameters.weekOf;
        }
        if (requestParameters.payPeriod) {
            queryParameters['payPeriod'] = requestParameters.payPeriod;
        }
        if (requestParameters.user) {
            queryParameters['user'] = requestParameters.user;
        }
        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }
        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/get_time_sheet_list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetTimeSheetListResponseFromJSON(jsonValue));
    }
    /**
     */
    async getTimeSheetList(requestParameters, initOverrides) {
        const response = await this.getTimeSheetListRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to get lists of all possible payment periods and users. That can be used to filter for time sheets.
     */
    async getTimeSheetListFiltersRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/get_time_sheet_list_filters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetTimeSheetListFiltersResponseFromJSON(jsonValue));
    }
    /**
     * Used to get lists of all possible payment periods and users. That can be used to filter for time sheets.
     */
    async getTimeSheetListFilters(initOverrides) {
        const response = await this.getTimeSheetListFiltersRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async publishCraneDailyLogRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling publishCraneDailyLog.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/crane-daily-log/{id}/publish`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CraneDailyLogGroupFromJSON(jsonValue));
    }
    /**
     */
    async publishCraneDailyLog(requestParameters, initOverrides) {
        const response = await this.publishCraneDailyLogRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to publish a crane monthly log
     */
    async publishCraneMonthlyLogRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/publish_crane_monthly_log`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IDBodyToJSON(requestParameters.iDBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used to publish a crane monthly log
     */
    async publishCraneMonthlyLog(requestParameters = {}, initOverrides) {
        await this.publishCraneMonthlyLogRaw(requestParameters, initOverrides);
    }
    /**
     * Used to publish a crane weekly log
     */
    async publishCraneWeeklyLogRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/publish_crane_weekly_log`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IDBodyToJSON(requestParameters.iDBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used to publish a crane weekly log
     */
    async publishCraneWeeklyLog(requestParameters = {}, initOverrides) {
        await this.publishCraneWeeklyLogRaw(requestParameters, initOverrides);
    }
    /**
     */
    async publishFormRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/publish`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IDBodyToJSON(requestParameters.iDBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FormFromJSON(jsonValue));
    }
    /**
     */
    async publishForm(requestParameters = {}, initOverrides) {
        const response = await this.publishFormRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async publishGeneratorDailyLogRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling publishGeneratorDailyLog.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/generator-daily-log/{id}/publish`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GeneratorDailyLogGroupFromJSON(jsonValue));
    }
    /**
     */
    async publishGeneratorDailyLog(requestParameters, initOverrides) {
        const response = await this.publishGeneratorDailyLogRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async resolveDeficiencyRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/deficiencies/resolve`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResolveDeficiencyBodyToJSON(requestParameters.resolveDeficiencyBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async resolveDeficiency(requestParameters = {}, initOverrides) {
        await this.resolveDeficiencyRaw(requestParameters, initOverrides);
    }
    /**
     */
    async submitCraneDailyLogRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling submitCraneDailyLog.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("CraneOperator", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/crane-daily-log/{id}/submit`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CraneDailyLogGroupFromJSON(jsonValue));
    }
    /**
     */
    async submitCraneDailyLog(requestParameters, initOverrides) {
        const response = await this.submitCraneDailyLogRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to submit a crane monthly log
     */
    async submitCraneMonthlyLogRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("CraneOperator", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/submit_crane_monthly_log`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IDBodyToJSON(requestParameters.iDBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used to submit a crane monthly log
     */
    async submitCraneMonthlyLog(requestParameters = {}, initOverrides) {
        await this.submitCraneMonthlyLogRaw(requestParameters, initOverrides);
    }
    /**
     * Used to submit a crane weekly log
     */
    async submitCraneWeeklyLogRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("CraneOperator", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/submit_crane_weekly_log`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IDBodyToJSON(requestParameters.iDBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used to submit a crane weekly log
     */
    async submitCraneWeeklyLog(requestParameters = {}, initOverrides) {
        await this.submitCraneWeeklyLogRaw(requestParameters, initOverrides);
    }
    /**
     * Used to submit a form
     */
    async submitFormRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/submit`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IDBodyToJSON(requestParameters.iDBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FormFromJSON(jsonValue));
    }
    /**
     * Used to submit a form
     */
    async submitForm(requestParameters = {}, initOverrides) {
        const response = await this.submitFormRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async submitGeneratorDailyLogRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling submitGeneratorDailyLog.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("CraneOperator", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/generator-daily-log/{id}/submit`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GeneratorDailyLogGroupFromJSON(jsonValue));
    }
    /**
     */
    async submitGeneratorDailyLog(requestParameters, initOverrides) {
        const response = await this.submitGeneratorDailyLogRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async unSubmitFormRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/unsubmit`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IDBodyToJSON(requestParameters.iDBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FormFromJSON(jsonValue));
    }
    /**
     */
    async unSubmitForm(requestParameters = {}, initOverrides) {
        const response = await this.unSubmitFormRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to update daily logs for generators
     */
    async updateCraneMonthlyLogRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/update_crane_monthly_log`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCraneMonthlyLogBodyToJSON(requestParameters.updateCraneMonthlyLogBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CraneWeeklyLogFromJSON(jsonValue));
    }
    /**
     * Used to update daily logs for generators
     */
    async updateCraneMonthlyLog(requestParameters = {}, initOverrides) {
        const response = await this.updateCraneMonthlyLogRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to update daily logs for generators
     */
    async updateCraneWeeklyLogRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/update_crane_weekly_log`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCraneWeeklyLogBodyToJSON(requestParameters.updateCraneWeeklyLogBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CraneWeeklyLogFromJSON(jsonValue));
    }
    /**
     * Used to update daily logs for generators
     */
    async updateCraneWeeklyLog(requestParameters = {}, initOverrides) {
        const response = await this.updateCraneWeeklyLogRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async updateDeficiencyRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/deficiencies`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDeficiencyBodyToJSON(requestParameters.updateDeficiencyBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => DeficiencyFromJSON(jsonValue));
    }
    /**
     */
    async updateDeficiency(requestParameters = {}, initOverrides) {
        const response = await this.updateDeficiencyRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to update final test forms
     */
    async updateFinalTestFormRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/update_final_test_form`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFinalTestFormBodyToJSON(requestParameters.updateFinalTestFormBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FinalTestFormFromJSON(jsonValue));
    }
    /**
     * Used to update final test forms
     */
    async updateFinalTestForm(requestParameters = {}, initOverrides) {
        const response = await this.updateFinalTestFormRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async updateGeneratorDailyLogRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateGeneratorDailyLog.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/generator-daily-log/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateGeneratorDailyLogBodyToJSON(requestParameters.updateGeneratorDailyLogBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GeneratorDailyLogGroupFromJSON(jsonValue));
    }
    /**
     */
    async updateGeneratorDailyLog(requestParameters, initOverrides) {
        const response = await this.updateGeneratorDailyLogRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async updateLogCommentRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("User", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/log-comments`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLogCommentBodyToJSON(requestParameters.updateLogCommentBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async updateLogComment(requestParameters = {}, initOverrides) {
        await this.updateLogCommentRaw(requestParameters, initOverrides);
    }
    /**
     */
    async updateLuffingTowerCraneDailyLogRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateLuffingTowerCraneDailyLog.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/luffing-tower-crane-daily-log/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLuffingTowerCraneDailyLogBodyToJSON(requestParameters.updateLuffingTowerCraneDailyLogBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CraneDailyLogGroupFromJSON(jsonValue));
    }
    /**
     */
    async updateLuffingTowerCraneDailyLog(requestParameters, initOverrides) {
        const response = await this.updateLuffingTowerCraneDailyLogRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async updateSelfErectingCraneDailyLogRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateSelfErectingCraneDailyLog.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/self-erecting-crane-daily-log/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSelfErectingCraneDailyLogBodyToJSON(requestParameters.updateSelfErectingCraneDailyLogBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CraneDailyLogGroupFromJSON(jsonValue));
    }
    /**
     */
    async updateSelfErectingCraneDailyLog(requestParameters, initOverrides) {
        const response = await this.updateSelfErectingCraneDailyLogRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to update service reports
     */
    async updateServiceReportRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/update_service_report`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateServiceReportBodyToJSON(requestParameters.updateServiceReportBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceReportFromJSON(jsonValue));
    }
    /**
     * Used to update service reports
     */
    async updateServiceReport(requestParameters = {}, initOverrides) {
        const response = await this.updateServiceReportRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Used to update time sheet entries
     */
    async updateTimeSheetEntryRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/update_time_sheet_entry`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTimeSheetEntryBodyToJSON(requestParameters.updateTimeSheetEntryBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TimeSheetFromJSON(jsonValue));
    }
    /**
     * Used to update time sheet entries
     */
    async updateTimeSheetEntry(requestParameters = {}, initOverrides) {
        const response = await this.updateTimeSheetEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async updateTowerCraneDailyLogRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateTowerCraneDailyLog.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("TokenDependant", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/forms/tower-crane-daily-log/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTowerCraneDailyLogBodyToJSON(requestParameters.updateTowerCraneDailyLogBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CraneDailyLogGroupFromJSON(jsonValue));
    }
    /**
     */
    async updateTowerCraneDailyLog(requestParameters, initOverrides) {
        const response = await this.updateTowerCraneDailyLogRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
