/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const EquipmentType = {
    CRANE: 'CRANE',
    GENERATOR: 'GENERATOR',
    MATERIAL_HOIST: 'MATERIAL_HOIST'
};
export function EquipmentTypeFromJSON(json) {
    return EquipmentTypeFromJSONTyped(json, false);
}
export function EquipmentTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EquipmentTypeToJSON(value) {
    return value;
}
