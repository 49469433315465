import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {FormsApi, GetFinalTestFormListFiltersResponse, GetFinalTestFormListRequest, Token, UserType} from "client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import moment from "moment";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button, Col, Row} from "reactstrap";
import AlliedDatePicker from "../inputs/AlliedDatePicker";
import AlliedAutoComplete from "../inputs/AlliedAutoComplete";
import {parseStringArrayForAutoComplete} from "../../utils/parseStringArrayForAutoComplete";
import {parseEquipmentForAutoComplete} from "../../utils/parseEquipmentForAutoComplete";
import {parseSitesForAutoComplete} from "../../utils/parseSitesForAutoComplete";
import {userTypeCheck} from "../../utils/userTypeCheck";
import {parseUsersForAutoComplete} from "../../utils/parseUsersForAutoComplete";
import getConfig from "../../utils/getConfig";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	filter: GetFinalTestFormListRequest;
	onClose: () => void;
	onDone: (newFilter: GetFinalTestFormListRequest) => void;
}

const FinalTestFormsFilterModal: React.FC<IProps> = (props) => {

	const [filter, setFilter] = useState<GetFinalTestFormListRequest>(props.filter);
	const [apiFilters, setApiFilters] = useState<GetFinalTestFormListFiltersResponse>(undefined);

	/**
	 * When modal opens, update the local filter to match that of props,
	 * and call api to get values for the auto-complete inputs.
	 */
	useEffect(() => {
		if (props.isOpen) {
			setFilter(props.filter);
			getDropDownValues().then().catch();
		}
	}, [props.isOpen]);

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function resetAndClose(): void {
		setFilter(props.filter);
		props.onClose();
	}

	/**
	 * Call api to get list of sites for the auto-complete inputs.
	 *
	 */
	async function getDropDownValues(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FormsApi(getConfig(props.fullToken)).getFinalTestFormListFilters();
			setApiFilters(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * onChange for the date inputs.
	 *
	 * @param key
	 */
	function onDateChange(key: keyof GetFinalTestFormListRequest): (d: Date) => void {
		return (d: Date) => {
			setFilter({
				...filter,
				[key]: key === "endDate" ? moment(d).endOf("day").valueOf() : moment(d).valueOf(),
			});
		}
	}

	/**
	 * Dynamic onChange helper to update the string arrays in the filter object.
	 *
	 * @param key
	 */
	function filterArrayOnChange(key: keyof GetFinalTestFormListRequest): (value: string[]) => void {
		return (value: string[]) => {
			setFilter({
				...filter,
				[key]: value,
			});
		}
	}

	/**
	 * When done configuring filter, call onDone function from props.
	 *
	 */
	function onSave(): void {
		props.onDone(filter);
	}

	return (
		<AlliedModal
			size="lg"
			isOpen={props.isOpen}
		>
			<AlliedModalHeader
				title="Filter Final Test Forms"
				onClose={resetAndClose}
			/>

			<hr/>

			<Row>
				<Col xs={12} lg={6} className="mb-3">
					<label>From Date Submitted</label>
					<AlliedDatePicker
						selected={filter.startDate}
						onChange={onDateChange("startDate")}
						showMonthDropdown={true}
						showYearDropdown={true}
						dropdownMode="select"
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>To Date Submitted</label>
					<AlliedDatePicker
						selected={filter.endDate}
						onChange={onDateChange("endDate")}
						showMonthDropdown={true}
						showYearDropdown={true}
						dropdownMode="select"
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Specify Makes</label>
					<AlliedAutoComplete
						placeholder="Select Makes..."
						options={parseStringArrayForAutoComplete(apiFilters?.makes)}
						selections={filter.make}
						setSelections={filterArrayOnChange("make")}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Specify Models</label>
					<AlliedAutoComplete
						placeholder="Select Models..."
						options={parseStringArrayForAutoComplete(apiFilters?.models)}
						selections={filter.model}
						setSelections={filterArrayOnChange("model")}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Specify S/N</label>
					<AlliedAutoComplete
						placeholder="Select Serial Numbers..."
						options={parseStringArrayForAutoComplete(apiFilters?.serialNumbers)}
						selections={filter.serialNumber}
						setSelections={filterArrayOnChange("serialNumber")}
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Specify Equipment</label>
					<AlliedAutoComplete
						placeholder="Select Equipment..."
						options={parseEquipmentForAutoComplete(apiFilters?.equipment)}
						selections={filter.equipment}
						setSelections={filterArrayOnChange("equipment")}
					/>
				</Col>
				<Col xs={12} lg={6} className="mb-3">
					<label>Specify Sites</label>
					<AlliedAutoComplete
						placeholder="Select Sites..."
						options={parseSitesForAutoComplete(apiFilters?.sites)}
						selections={filter.site}
						setSelections={filterArrayOnChange("site")}
					/>
				</Col>
				
				{userTypeCheck([UserType.ADMIN], props.fullToken) && (
					<Col xs={12} lg={6} className="mb-3">
						<label>Specify AC Employees</label>
						<AlliedAutoComplete
							placeholder="Select AC Employees..."
							options={parseUsersForAutoComplete(apiFilters?.users)}
							selections={filter.user}
							setSelections={filterArrayOnChange("user")}
						/>
					</Col>
				)}
			</Row>

			<div>
				<Button
					color="black"
					onClick={onSave}
				>
					Apply Filters
				</Button>
			</div>
		</AlliedModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(FinalTestFormsFilterModal);
