import {AddTimeSheetEntryBody, TimeSheetEntry} from "client";
import {generateOffsetDate} from "./timeZoneConversions";
import {roundMomentObjectMinutes} from "./roundMomentObjectMinutes";

export function convertTimeSheetEntryResponseToCreateBody(res: TimeSheetEntry): AddTimeSheetEntryBody {
	return {
		isSickDay: res.isSickDay,
		timeIn: res.timeIn ? generateOffsetDate(res.timeIn) : roundMomentObjectMinutes().valueOf(),
		timeOut: res.timeOut ? generateOffsetDate(res.timeOut) : roundMomentObjectMinutes().valueOf(),
		jobDescription: res.jobDescription,
		lunchBreak: res.lunchBreak || false,
		driveTime: res.driveTime,
	}
}
