/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CreateLogCommentBodyFromJSON, CreateLogCommentBodyToJSON, } from './CreateLogCommentBody';
import { InspectionStatusFromJSON, InspectionStatusToJSON, } from './InspectionStatus';
/**
 * Check if a given object implements the CreateGeneratorDailyLogBody interface.
 */
export function instanceOfCreateGeneratorDailyLogBody(value) {
    let isInstance = true;
    isInstance = isInstance && "equipmentID" in value;
    isInstance = isInstance && "siteID" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "initials" in value;
    return isInstance;
}
export function CreateGeneratorDailyLogBodyFromJSON(json) {
    return CreateGeneratorDailyLogBodyFromJSONTyped(json, false);
}
export function CreateGeneratorDailyLogBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'equipmentID': json['equipmentID'],
        'siteID': json['siteID'],
        'date': json['date'],
        'initials': json['initials'],
        'createLogCommentBodies': !exists(json, 'createLogCommentBodies') ? undefined : (json['createLogCommentBodies'].map(CreateLogCommentBodyFromJSON)),
        'users': !exists(json, 'users') ? undefined : json['users'],
        'hours': !exists(json, 'hours') ? undefined : json['hours'],
        'hourMeterStart': !exists(json, 'hourMeterStart') ? undefined : json['hourMeterStart'],
        'hourMeterEnd': !exists(json, 'hourMeterEnd') ? undefined : json['hourMeterEnd'],
        'hourMeterTotal': !exists(json, 'hourMeterTotal') ? undefined : json['hourMeterTotal'],
        'checkOilLevel': !exists(json, 'checkOilLevel') ? undefined : InspectionStatusFromJSON(json['checkOilLevel']),
        'checkCoolantLevels': !exists(json, 'checkCoolantLevels') ? undefined : InspectionStatusFromJSON(json['checkCoolantLevels']),
        'visualInspectionForWear': !exists(json, 'visualInspectionForWear') ? undefined : InspectionStatusFromJSON(json['visualInspectionForWear']),
        'checkForLeakage': !exists(json, 'checkForLeakage') ? undefined : InspectionStatusFromJSON(json['checkForLeakage']),
        'ensureDoorPanelsAndCoversAreClosed': !exists(json, 'ensureDoorPanelsAndCoversAreClosed') ? undefined : InspectionStatusFromJSON(json['ensureDoorPanelsAndCoversAreClosed']),
    };
}
export function CreateGeneratorDailyLogBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'equipmentID': value.equipmentID,
        'siteID': value.siteID,
        'date': value.date,
        'initials': value.initials,
        'createLogCommentBodies': value.createLogCommentBodies === undefined ? undefined : (value.createLogCommentBodies.map(CreateLogCommentBodyToJSON)),
        'users': value.users,
        'hours': value.hours,
        'hourMeterStart': value.hourMeterStart,
        'hourMeterEnd': value.hourMeterEnd,
        'hourMeterTotal': value.hourMeterTotal,
        'checkOilLevel': InspectionStatusToJSON(value.checkOilLevel),
        'checkCoolantLevels': InspectionStatusToJSON(value.checkCoolantLevels),
        'visualInspectionForWear': InspectionStatusToJSON(value.visualInspectionForWear),
        'checkForLeakage': InspectionStatusToJSON(value.checkForLeakage),
        'ensureDoorPanelsAndCoversAreClosed': InspectionStatusToJSON(value.ensureDoorPanelsAndCoversAreClosed),
    };
}
