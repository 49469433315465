import React from "react";

const PrintableHeader: React.FC = () => {

	return (
		<div className="printable-header">
			<img
				src={process.env.PUBLIC_URL + "/logos/allied-logo.svg"}
				className="printable-header__logo"
			/>

			<div className="printable-header__info">
				<p>
					<b>Phone: </b>604-467-4130
				</p>

				{/*<p>*/}
				{/*	<b>Fax: </b>604-467-4135*/}
				{/*</p>*/}

				<p>
					23452 River Rd., Maple Ridge, B.C. V2W 1B7
				</p>

				<p>
					www.alliedcraneltd.ca
				</p>

				<p>
					info@alliedcrane.ca
				</p>

				<p>
					sales@alliedcrane.ca
				</p>
			</div>
		</div>
	);
};

export default PrintableHeader;
