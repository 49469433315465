/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const EquipmentSortField = {
    type: 'type',
    ownedBy: 'ownedBy',
    rentedBy: 'rentedBy',
    make: 'make',
    model: 'model',
    serialNumber: 'serialNumber',
    preExistingUsageHours: 'preExistingUsageHours',
    createdAt: 'createdAt',
    updatedAt: 'updatedAt'
};
export function EquipmentSortFieldFromJSON(json) {
    return EquipmentSortFieldFromJSONTyped(json, false);
}
export function EquipmentSortFieldFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EquipmentSortFieldToJSON(value) {
    return value;
}
