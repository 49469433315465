import React, {useEffect, useState} from "react";
import {CreateDeficiencyBody, DeficiencyStatus, Equipment, FormsApi, FormType, Site, SitesApi, Token} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import moment from "moment";
import {Button, Col, Row} from "reactstrap";
import AlliedDatePicker from "../inputs/AlliedDatePicker";
import {parseSitesForAutoComplete} from "../../utils/parseSitesForAutoComplete";
import AlliedAutoComplete from "../inputs/AlliedAutoComplete";
import {find} from "lodash";
import AlliedSelect from "../inputs/AlliedSelect";
import {parseDeficiencyStatusForDisplay} from "../../utils/parseDeficiencyStatusForDisplay";
import {parseSiteAddressForSingleInput} from "../../utils/parseSiteAddressForSingleInput";
import {convertOffsetDate} from "../../utils/timeZoneConversions";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	equipment: Equipment;
	onClose: () => void;
	onDone: () => void;
}

const defaultDeficiencyForm: CreateDeficiencyBody = {
	status: undefined,
	equipmentID: "",
	siteID: "",
	date: moment().startOf("day").valueOf(),
	issue: "",
}

const CreateGeneralDeficiencyIssueModal: React.FC<IProps> = (props) => {

	const [form, setForm] = useState<CreateDeficiencyBody>({...defaultDeficiencyForm, equipmentID: props.equipment?._id});
	const [siteList, setSiteList] = useState<Array<Site>>([]);

	useEffect(() => {
		if (props.isOpen) {
			setForm({...defaultDeficiencyForm, equipmentID: props.equipment?._id});
			getSiteList().then().catch();
		}
	}, [props.isOpen]);

	/**
	 * Reset form and close the modal.
	 *
	 */
	function resetAndClose(): void {
		setForm({...defaultDeficiencyForm, equipmentID: props.equipment?._id});
		props.onClose();
	}

	/**
	 * Call api to get the list of sites for the auto-complete input.
	 *
	 */
	async function getSiteList(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new SitesApi(getConfig(props.fullToken)).getSiteList({
				limit: 10000,
				offset: 0,
			});
			setSiteList(res.sites);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * onChange for the date input.
	 *
	 * @param d
	 */
	function onDateChange(d: Date): void {
		setForm({
			...form,
			date: moment(d).valueOf(),
		});
	}

	/**
	 * onChange for the issue text input.
	 *
	 * @param e
	 */
	function onIssueChange(e: React.ChangeEvent<HTMLTextAreaElement>): void {
		setForm({
			...form,
			issue: e?.target?.value,
		});
	}

	/**
	 * Handle the auto-complete onChange for the selected site,
	 * which returns an array, but we only want the first element.
	 *
	 * @param site
	 */
	function siteOnChange(site: string[]): void {
		setForm({
			...form,
			siteID: form.siteID !== site[0] ? site[0] : "",
		});
	}

	/**
	 * onChange for the status select.
	 *
	 * @param e
	 */
	function onStatusChange(e: React.ChangeEvent<HTMLSelectElement>): void {
		setForm({
			...form,
			status: e?.target?.value as DeficiencyStatus,
		})
	}

	/**
	 * Call api to add the new issue,
	 * call props.onDone function to update table.
	 *
	 */
	async function addIssue(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new FormsApi(getConfig(props.fullToken)).createDeficiency({
				createDeficiencyBody: {
					status: form.status,
					equipmentID: form.equipmentID ? form.equipmentID : undefined,
					siteID: form.siteID ? form.siteID : undefined,
					date: form.date !== undefined ? convertOffsetDate(form.date) : undefined,
					issue: form.issue ? form.issue : undefined,
				}
			});
			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<AlliedModal
			size="lg"
			isOpen={props.isOpen}
		>
			<AlliedModalHeader
				title="Manually Add Issue"
				onClose={resetAndClose}
			/>

			<hr/>

			<Row>
				<Col xs={12} md={6} className="mb-3">
					<label>Date</label>
					<AlliedDatePicker
						selected={form.date}
						onChange={onDateChange}
					/>
				</Col>
			</Row>

			<Row>
				<Col xs={12} className="mb-3">
					<label>Specify Issue</label>
					<textarea
						placeholder="Enter Issue..."
						value={form.issue}
						onChange={onIssueChange}
					/>
				</Col>
			</Row>

			<Row>
				<Col xs={12} md={6} className="mb-3">
					<label>Site of Issue</label>
					<AlliedAutoComplete
						placeholder="Select Site..."
						options={parseSitesForAutoComplete(siteList)}
						selections={form.siteID ? [form.siteID] : []}
						setSelections={siteOnChange}
						multiSelect={false}
					/>
				</Col>
			</Row>

			<Row>
				<Col xs={12} className="mb-3">
					<label>Site Address</label>
					<input
						disabled={true}
						placeholder="Select a site to view its address..."
						value={parseSiteAddressForSingleInput(find(siteList, ["_id", form.siteID])?.address)}
					/>
				</Col>
			</Row>

			<Row>
				<Col xs={12} md={6} className="mb-3">
					<label>Status</label>
					<AlliedSelect
						value={form.status}
						onChange={onStatusChange}
					>
						<option value="" selected disabled>Select Issue Status</option>
						<hr/>
						<option value={DeficiencyStatus.PENDING_APPOINTMENT}>{parseDeficiencyStatusForDisplay(DeficiencyStatus.PENDING_APPOINTMENT)}</option>
						<option value={DeficiencyStatus.REQUIRES_PARTS}>{parseDeficiencyStatusForDisplay(DeficiencyStatus.REQUIRES_PARTS)}</option>
						<option value={DeficiencyStatus.PARTS_AVAILABLE}>{parseDeficiencyStatusForDisplay(DeficiencyStatus.PARTS_AVAILABLE)}</option>
						<option value={DeficiencyStatus.RESOLVED}>{parseDeficiencyStatusForDisplay(DeficiencyStatus.RESOLVED)}</option>
					</AlliedSelect>
				</Col>
			</Row>

			<div>
				<Button
					color="black"
					onClick={addIssue}
				>
					Add issue
				</Button>
			</div>
		</AlliedModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(CreateGeneralDeficiencyIssueModal);
