import React, {ReactNode} from "react";
import {CellContextDetails, ContextFunctionCellWithValue} from "frame-one-table/build/contextTypes";

const AlliedTableHeaderCell: ContextFunctionCellWithValue<any, ReactNode> = (
	value: any,
	data: CellContextDetails,
) => {

	return (
		<div className="allied-table-header-cell">
			<h6>
				{value}
			</h6>

			{data.column.onSort && (
				<div className="allied-sorting-icons-container">
					<img
						src={process.env.PUBLIC_URL + "/icons/arrow.svg"}
						className="allied-sorting-icons-container-top allied-sorting-icons-container-icon"
						alt="chevron up"
					/>

					<img
						src={process.env.PUBLIC_URL + "/icons/arrow.svg"}
						className="allied-sorting-icons-container-bottom allied-sorting-icons-container-icon"
						alt="chevron down"
					/>
				</div>
			)}
		</div>
	);
};

export default AlliedTableHeaderCell;
