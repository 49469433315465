/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AlliedCraneEmployeeFromJSON, AlliedCraneEmployeeToJSON, } from './AlliedCraneEmployee';
import { CraneOperatorFromJSON, CraneOperatorToJSON, } from './CraneOperator';
import { HealthAndSafetyOfficerFromJSON, HealthAndSafetyOfficerToJSON, } from './HealthAndSafetyOfficer';
import { SecondaryAdminFromJSON, SecondaryAdminToJSON, } from './SecondaryAdmin';
import { SupervisorFromJSON, SupervisorToJSON, } from './Supervisor';
/**
 * Check if a given object implements the UsersByType interface.
 */
export function instanceOfUsersByType(value) {
    let isInstance = true;
    isInstance = isInstance && "alliedCraneEmployees" in value;
    isInstance = isInstance && "secondaryAdmins" in value;
    isInstance = isInstance && "supervisors" in value;
    isInstance = isInstance && "craneOperators" in value;
    isInstance = isInstance && "healthAndSafetyOfficers" in value;
    return isInstance;
}
export function UsersByTypeFromJSON(json) {
    return UsersByTypeFromJSONTyped(json, false);
}
export function UsersByTypeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'alliedCraneEmployees': (json['alliedCraneEmployees'].map(AlliedCraneEmployeeFromJSON)),
        'secondaryAdmins': (json['secondaryAdmins'].map(SecondaryAdminFromJSON)),
        'supervisors': (json['supervisors'].map(SupervisorFromJSON)),
        'craneOperators': (json['craneOperators'].map(CraneOperatorFromJSON)),
        'healthAndSafetyOfficers': (json['healthAndSafetyOfficers'].map(HealthAndSafetyOfficerFromJSON)),
    };
}
export function UsersByTypeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'alliedCraneEmployees': (value.alliedCraneEmployees.map(AlliedCraneEmployeeToJSON)),
        'secondaryAdmins': (value.secondaryAdmins.map(SecondaryAdminToJSON)),
        'supervisors': (value.supervisors.map(SupervisorToJSON)),
        'craneOperators': (value.craneOperators.map(CraneOperatorToJSON)),
        'healthAndSafetyOfficers': (value.healthAndSafetyOfficers.map(HealthAndSafetyOfficerToJSON)),
    };
}
