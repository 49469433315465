import {CreateFinalTestFormBody, FinalTestForm} from "client";
import {generateOffsetDate} from "./timeZoneConversions";

/**
 * Takes a Final Test From and converts it to the interface for the create/edit page.
 * This is intended to be used when editing an existing Final Test Form.
 *
 * @param res
 */
export function convertFinalTestFormResponseToCreateBody(res: FinalTestForm): CreateFinalTestFormBody {
	return {
		equipmentID: res?.equipment?._id,
		siteID: res.originalSite?._id,
		date: res.date !== undefined ? generateOffsetDate(res.date) : undefined,
		baseType: res.baseType,
		travelGear: res.travelGear,
		towerHeight: res.towerHeight,
		heightUnderHook: res.heightUnderHook,
		jibLength: res.jibLength,
		requiredPowerSupplyVolts: res.requiredPowerSupplyVolts,
		phase: res.phase,
		amps: res.amps,
		measuredPowerSupplyUnderLoad: res.measuredPowerSupplyUnderLoad,
		noLoad: res.noLoad,
		minimumFreeSlewRadius: res.minimumFreeSlewRadius,
		trollyInLimitSlow: res.trollyInLimitSlow,
		trollyInLimitFinal: res.trollyInLimitFinal,
		trollyOutLimitSlow: res.trollyOutLimitSlow,
		trollyOutLimitFinal: res.trollyOutLimitFinal,
		hoistUpSlow: res.hoistUpSlow,
		hoistUpFinal: res.hoistUpFinal,
		hoistDownSlow: res.hoistDownSlow,
		hoistDownFinal: res.hoistDownFinal,
		travelInLimitSlow: res.travelInLimitSlow,
		travelInLimitFinal: res.travelInLimitFinal,
		travelOutLimitSlow: res.travelOutLimitSlow,
		travelOutLimitFinal: res.travelOutLimitFinal,
		slowingLimitInstalled: res.slowingLimitInstalled,
		verifyTheCraneCannotMakeMoreThan2TurnsToEachSide: res.verifyTheCraneCannotMakeMoreThan2TurnsToEachSide,
		requiredWeightForTipLimit: res.requiredWeightForTipLimit,
		primaryWeightUsedForTipLimit: res.primaryWeightUsedForTipLimit,
		kickerWeightUsedForTipLimit: res.kickerWeightUsedForTipLimit,
		requiredWeightForMaxLinePull: res.requiredWeightForMaxLinePull,
		setPercentOfMaxLinePull: res.setPercentOfMaxLinePull,
		primaryWeightUsedForMaxLinePull: res.primaryWeightUsedForMaxLinePull,
		kickerWeightUsedForMaxLinePull: res.kickerWeightUsedForMaxLinePull,
		isCraneGrounded: res.isCraneGrounded,
		deadManControlOperational: res.deadManControlOperational,
		constantPressureControls: res.constantPressureControls,
		springLoadedControls: res.springLoadedControls,
		controlFunctionsTested: res.controlFunctionsTested,
		eStopFunctioning: res.eStopFunctioning,
		operatorInformationDisplayInCab: res.operatorInformationDisplayInCab,
		displayCalibrated: res.displayCalibrated,
		zoningSystemInstalled: res.zoningSystemInstalled,
		craneOperatorOriented: res.craneOperatorOriented,
		name: res.name,
		licenseNumber: res.licenseNumber,
		comments: res.comments,
	}
}

