/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { UserFromJSON, UserToJSON, } from './User';
/**
 * Check if a given object implements the GetTimeSheetListFiltersResponse interface.
 */
export function instanceOfGetTimeSheetListFiltersResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "paymentPeriods" in value;
    return isInstance;
}
export function GetTimeSheetListFiltersResponseFromJSON(json) {
    return GetTimeSheetListFiltersResponseFromJSONTyped(json, false);
}
export function GetTimeSheetListFiltersResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'paymentPeriods': json['paymentPeriods'],
        'users': !exists(json, 'users') ? undefined : (json['users'].map(UserFromJSON)),
    };
}
export function GetTimeSheetListFiltersResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'paymentPeriods': value.paymentPeriods,
        'users': value.users === undefined ? undefined : (value.users.map(UserToJSON)),
    };
}
