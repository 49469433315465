import * as React from "react";
import {Equipment} from "client";
import {IAutoCompleteOption} from "../components/inputs/AlliedAutoComplete";

/**
 * Converts equipment list into array of user-friendly strings, including the make, model, serial number, and assigned site name - if present.
 *
 * @param equipmentList
 */
export function parseEquipmentForSelectDropdown(equipmentList: Array<Equipment> = []): Array<IAutoCompleteOption> {
	return equipmentList.map((_equipment: Equipment): IAutoCompleteOption => {
		return {
			value: _equipment._id,
			label: _equipment.make + " " + _equipment.model + " " + _equipment.serialNumber + (_equipment.site?.name ? " - " + _equipment.site.name : "")
		}
	});
}
