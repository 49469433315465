import React, {ReactNode} from "react";

interface IProps {
	children?: ReactNode;
}

const AlliedRadioRow: React.FC<IProps> = (props) => {

	return (
		<div className="allied-radio-row">
			{props.children}
		</div>
	);
};

export default AlliedRadioRow;
