import React, {ChangeEventHandler} from "react";
import {User} from "client";
import {Col, Row} from "reactstrap";
import AlliedAutoComplete from "./AlliedAutoComplete";
import {parseUsersForAutoComplete} from "../../utils/parseUsersForAutoComplete";
import DatePicker, {ReactDatePickerProps} from "react-datepicker";
import AlliedDatePicker from "./AlliedDatePicker";
import moment from "moment";

interface IProps {
	// value: CreateLunchBreakerBody;
	value: any;
	// onChange: (newValue: CreateLunchBreakerBody) => void;
	onChange: (newValue: any) => void;
	employees: Array<User>;
}

const LunchBreakInput: React.FC<IProps> = (props) => {

	/**
	 * onChange to help with the single select auto-complete
	 * for choosing an employee.
	 *
	 * @param users
	 */
	function employeeOnChange(users: string[]): void {
		props.onChange({
			...props.value,
			alliedCraneEmployeeID: props.value.alliedCraneEmployeeID !== users[0] ? users[0] : "",
		});
	}

	/**
	 * Dynamic onChange for start & end times.
	 *
	 * @param key
	 */
	// function onTimeChange(key: keyof CreateLunchBreakerBody): (d: Date) => void {
	function onTimeChange(key: keyof any): (d: Date) => void {
		return (d: Date) => {
			props.onChange({
				...props.value,
				[key]: moment(d).valueOf(),
			});
		}
	}

	return (
		<Row>
			<Col xs={12} className="mb-1">
				<label>Employee Name</label>
				<AlliedAutoComplete
					placeholder="Employee..."
					options={parseUsersForAutoComplete(props.employees)}
					selections={props.value?.alliedCraneEmployeeID ? [props.value?.alliedCraneEmployeeID] : []}
					setSelections={employeeOnChange}
					multiSelect={false}
				/>
			</Col>

			<Col xs={6}>
				<label>Start Time</label>
				<AlliedDatePicker
					{...standardTimePickerProps}
					selected={props.value?.startTime}
					onChange={onTimeChange("startTime")}
				/>
			</Col>

			<Col xs={6}>
				<label>End Time</label>
				<AlliedDatePicker
					{...standardTimePickerProps}
					selected={props.value?.endTime}
					onChange={onTimeChange("endTime")}
				/>
			</Col>
		</Row>
	);
};

export const standardTimePickerProps: Partial<ReactDatePickerProps> = {
	showTimeSelect: true,
	showTimeSelectOnly: true,
	dateFormat: "hh:mm a",
	timeIntervals: 15
};

export default LunchBreakInput;
