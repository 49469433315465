import React from "react";
import {Button} from "reactstrap";
import {Crane} from "client";
import {useHistory} from "react-router-dom";

interface IProps {
	crane: Crane;
}

const ManageCranesViewDeficienciesCell: React.FC<IProps> = (props) => {

	const history = useHistory();

	function goToDeficiencies(): void {
		history.push(`/deficiency-reports?id=${props.crane?._id}`);
	}

	return (
		<div className="allied-table-cell">
			<Button
				color="black"
				onClick={goToDeficiencies}
			>
				View Forms
			</Button>
		</div>
	);
};

export default ManageCranesViewDeficienciesCell;
