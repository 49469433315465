/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserFromJSON, UserToJSON, } from './User';
/**
 * Check if a given object implements the LogComment interface.
 */
export function instanceOfLogComment(value) {
    let isInstance = true;
    isInstance = isInstance && "_id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "flagged" in value;
    isInstance = isInstance && "comment" in value;
    return isInstance;
}
export function LogCommentFromJSON(json) {
    return LogCommentFromJSONTyped(json, false);
}
export function LogCommentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'user': UserFromJSON(json['user']),
        'flagged': json['flagged'],
        'comment': json['comment'],
    };
}
export function LogCommentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'user': UserToJSON(value.user),
        'flagged': value.flagged,
        'comment': value.comment,
    };
}
