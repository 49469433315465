import React, {ReactNode} from "react";
import PlusButton from "../PlusButton";
import classNames from "classnames";

interface IProps<T, U> {
	values: Array<T>;
	onChange?: (i: number, value: T) => void;
	onAdd?: () => void;
	onRemove?: (i: number) => void;
	component: React.FC<{value: T, onChange: (value: T) => void, index: number, children?: ReactNode} & U | any>;
	forwardedProps?: Partial<U>;
	plusIconAlignment?: "align-items-start" | "align-items-center" | "align-items-end";
	nestRemoveIconAsChild?: boolean;
	plusIconClassName?: string;
	removeButtonText?: string;
}

function AlliedGenericInputList<T, U>(props: IProps<T, U>): JSX.Element {
	function makeInputs(value: any, i: number): ReactNode {
		const Component = props.component;

		function onChangeHelper(_newValue): void {
			if (props.onChange) {
				props.onChange(i, _newValue)
			}
		}

		function onRemoveHelper(e): void {
			e?.preventDefault();
			props.onRemove(i)
		}

		const removeButton: ReactNode = (
			<div className="allied-input-list-generic__entry__remove-row">
				<span onClick={onRemoveHelper}>
					{props.removeButtonText}
				</span>
			</div>
		);

		return (
			<div
				key={`input_${i}`}
				className="allied-input-list-generic__entry"
			>
				<div className={classNames("w-100 d-flex", props.plusIconAlignment)}>
					<Component
						value={value}
						onChange={onChangeHelper}
						{...props.forwardedProps}
						index={i}
					>
						{(props.onRemove !== undefined && props.values.length > 1 && props.nestRemoveIconAsChild) && (
							<div className="allied-input-list-generic__entry__nested-remove">
								{removeButton}
							</div>
						)}
					</Component>

					{props.onAdd !== undefined && (
						<div
							className={classNames("allied-input-list-generic__entry__plus-spacer", props.plusIconClassName, {
								"allied-input-list-generic__entry__plus-spacer-hidden": i < props.values.length - 1,
								"pt-1": props.plusIconAlignment === "align-items-start",
							})}
						>
							<PlusButton onClick={props.onAdd}/>
						</div>
					)}
				</div>

				{(props.onRemove !== undefined && props.values.length > 1 && !props.nestRemoveIconAsChild) && removeButton}
			</div>
		);
	}

	return (
		<div className="allied-input-list-generic">
			{props.values?.map(makeInputs)}
		</div>
	);
}

AlliedGenericInputList.defaultProps = {
	plusIconAlignment: "align-items-end",
	nestRemoveIconAsChild: false,
	removeButtonText: "remove",
};

export default AlliedGenericInputList;
