import React from "react";

interface IProps {
	margin?: string; // Should be a css length property with units specified.
}

const PrintableStyle: React.FC<IProps> = (props) => {

	return (
		<style>
			{getPageMargins(props.margin)}

			{/*{`*/}
			{/*	@page {*/}
			{/*		size: landscape*/}
			{/*	}*/}
			{/*`}*/}
		</style>
	);
};

export default PrintableStyle;

/**
 * Make dynamic margins for the edges of the page.
 *
 * @param margin
 */
function getPageMargins(margin: string = "12.35mm"): string {
	return `@page { margin: ${margin} ${margin} ${margin} ${margin} !important; }`;
}
