import React, {ReactElement} from "react";
import AlliedModalHeader from "./AlliedModalHeader";
import AlliedTableContainer from "../tables/AlliedTableContainer";
import AlliedModal from "./AlliedModal";
import {Site, User} from "client";
import {getFullNameForTable, getFullNameForUserTable} from "../../utils/getFullNameForTable";

interface Props {
	isOpen: boolean;
	sites: Array<Site>;
	user: User;
	onClose: () => void;
}

function ManageUsersSiteListModal({isOpen, sites, user, onClose}: Props): ReactElement {

	return (
		<AlliedModal
			isOpen={isOpen}
			size="md"
		>
			<AlliedModalHeader
				title={`${getFullNameForTable(user)}'s sites (${sites.length})`}
				onClose={onClose}
			/>

			<hr/>

			<AlliedTableContainer
				data={sites}
				columnOptions={[
					{
						headerValue: "Site",
						key: "name",
					},
				]}
			/>
		</AlliedModal>
	);
}

export default ManageUsersSiteListModal;