
import React, {ReactNode} from "react";
import {CraneDailyLogGroup, GeneratorDailyLogGroup, LogHistory} from "client";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import moment from "moment";
import {getFullNameForTable} from "../../utils/getFullNameForTable";
import {parseLogHistoryType} from "../../utils/parseLogHistoryType";
import {getDateOfSingleIndividualLog} from "../../utils/getDateOfIndividualDailyLogBasedOnDay";
import {getWeekRangeForTable} from "../../utils/getWeekRangeForTable";
import DailyLogsGroupViewHistoryCell from "../tables/cells/DailyLogsGroupViewHistoryCell";
import WeeklyMonthlyLogsViewHistoryCell from "../tables/cells/WeeklyMonthlyLogsViewHistoryCell";

interface IProps {
	isOpen: boolean;
	onClose: () => void;
	history: Array<LogHistory>;
}

const WeeklyMonthlyLogsViewHistoryModal: React.FC<IProps> = (props) => {

	function renderHistoryItem(historyItem: LogHistory, i: number): ReactNode {
		return (
			<div
				key={`log-history_${i}`}
			>
				<b>
					{moment(historyItem.date).format("MM/DD/YYYY")}
				</b>

				<p>
					{parseLogHistoryType(historyItem.type)} by {getFullNameForTable(historyItem.user)}
				</p>
			</div>
		);
	}

	return (
		<AlliedModal
			isOpen={props.isOpen}
			size="md"
		>
			<AlliedModalHeader
				title="Log History"
				onClose={props.onClose}
			/>

			<hr/>

			{props.history?.length > 0 ? props.history.map(renderHistoryItem) : (
				<p className="text-center font-italic">
					No History Available.
				</p>
			)}
		</AlliedModal>
	);
};

export default WeeklyMonthlyLogsViewHistoryModal;
