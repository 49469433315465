import {IAutoCompleteOption} from "../components/inputs/AlliedAutoComplete";
import {Site, SiteWithUsersAndEquipment} from "client";

/**
 * Converts an array of sites into an array of options for the auto-complete input.
 *
 * @param sites
 */
export function parseSitesForAutoComplete(sites: Array<Site | SiteWithUsersAndEquipment> = []): Array<IAutoCompleteOption> {
	return sites.map((_site: Site): IAutoCompleteOption => {
		return {
			value: _site._id,
			label: _site.name,
		}
	});
}
