import {CreateCraneWeeklyLogBody} from "client";

/**
 * Parse the keys from the interfaces in the strings from the PDFs.
 *
 * @param key
 */
export function getCraneWeeklyLogInspectionItemDisplayName(key: keyof CreateCraneWeeklyLogBody): string {
	switch (key) {
		case "visualInspectionForOfSlewRing":
			return "Visual inspection of slew ring and gear teeth";
		case "visualInspectionOfTrolleyRollers":
			return "Visual inspection of trolley rollers noting signs of wear (if applicable)";
		case "visualInspectionOfSheaves":
			return "Visual inspection of sheaves and reeving system";
		case "jibBackstops":
			return "Jib backstops (boom stop) if applicable (luffing only)";
		case "boomHoistBrake":
			return "Boom hoist brake (luffing only)";
		case "checkWireRopeEnds":
			return "Check wire rope ends and fixings";
		case "inspectLoadLine":
			return "Inspect load line and and applicable trolley or boom wire rope";
		case "visualInspectionOfTieIns":
			return "Visual inspection of tie-ins to slabs or other bracing systems if used";
		case "ensureMachineIsProperlyLubricated":
			return "Ensure that the machine is properly lubricated and oil reservoirs are checked";
		case "visualInspectionOfAllDriveComponents":
			return "Visual inspection of all drive components";
		case "visualInspectionOfCounterweightSupports":
			return "Visual inspection of counterweight supports and brackets";
		case "visualInspectionOfAnchorBolts":
			return "Visual inspection of anchor bolts and pins or base and base ballast";
		case "visualInspectionOfTowerBolts":
			return "Visual inspection of tower bolts, pins and keepers";
		case "ensureTrackIsLevel":
			return "Ensure the track is level and parallel, in the case of traveling crane";
		case "supervisorNotifiedOfDefects":
			return "Supervisor notified of defects or faults";
		default:
			return key
	}
}
