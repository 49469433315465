import {CraneDailyLog, CraneDailyLogGroup, GeneratorDailyLog, GeneratorDailyLogGroup} from "client";

export function getCraneDailyLogGroupAsArray(groupedDailyLog: CraneDailyLogGroup = {} as CraneDailyLogGroup): Array<CraneDailyLog> {
	return [
		groupedDailyLog["_0"],
		groupedDailyLog["_1"],
		groupedDailyLog["_2"],
		groupedDailyLog["_3"],
		groupedDailyLog["_4"],
		groupedDailyLog["_5"],
		groupedDailyLog["_6"],
	].filter(l => l !== undefined);
}

export function getCraneDailyLogGroupAsArrayUnFiltered(groupedDailyLog: CraneDailyLogGroup = {} as CraneDailyLogGroup): Array<CraneDailyLog> {
	return [
		groupedDailyLog["_0"],
		groupedDailyLog["_1"],
		groupedDailyLog["_2"],
		groupedDailyLog["_3"],
		groupedDailyLog["_4"],
		groupedDailyLog["_5"],
		groupedDailyLog["_6"],
	];
}

export function getGeneratorDailyLogGroupAsArray(groupedDailyLog: GeneratorDailyLogGroup = {} as GeneratorDailyLogGroup): Array<GeneratorDailyLog> {
	return [
		groupedDailyLog["_0"],
		groupedDailyLog["_1"],
		groupedDailyLog["_2"],
		groupedDailyLog["_3"],
		groupedDailyLog["_4"],
		groupedDailyLog["_5"],
		groupedDailyLog["_6"],
	].filter(l => l !== undefined);
}


export function getGeneratorDailyLogGroupAsArrayUnFiltered(groupedDailyLog: GeneratorDailyLogGroup = {} as GeneratorDailyLogGroup): Array<GeneratorDailyLog> {
	return [
		groupedDailyLog["_0"],
		groupedDailyLog["_1"],
		groupedDailyLog["_2"],
		groupedDailyLog["_3"],
		groupedDailyLog["_4"],
		groupedDailyLog["_5"],
		groupedDailyLog["_6"],
	];
}
