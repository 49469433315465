import moment from "moment";
import {generateOffsetDate} from "./timeZoneConversions";

/**
 * Format date from apis to MM/DD/YYYY
 *
 * @param date
 */
export function parseDateForTableDisplay(date: number): string {
	return (date !== undefined && typeof date !== "object") ? moment(generateOffsetDate(date)).format("MM/DD/YYYY") : "-";
}
