import React, {useState} from "react";
import {CraneDailyLogGroup, GeneratorDailyLogGroup, LogHistory} from "client";
import {Button} from "reactstrap";
import DailyLogsGroupViewHistoryModal from "../../modals/DailyLogsGroupViewHistoryModal";

interface IProps {
	history: Array<LogHistory>;
	logGroup: CraneDailyLogGroup | GeneratorDailyLogGroup;
}

const DailyLogsGroupViewHistoryCell: React.FC<IProps> = (props) => {

	const [showHistoryModal, setShowHistoryModal] = useState(false);

	/**
	 * Show or hide the history modal
	 *
	 */
	function toggleHistoryModal(): void {
		setShowHistoryModal(!showHistoryModal);
	}

	return (
		<React.Fragment>
			<DailyLogsGroupViewHistoryModal
				isOpen={showHistoryModal}
				onClose={toggleHistoryModal}
				history={props.history}
				logGroup={props.logGroup}
			/>

			<div className="d-flex justify-content-center">
				<Button
					color="black"
					onClick={toggleHistoryModal}
				>
					View History
				</Button>
			</div>
		</React.Fragment>
	);
};

export default DailyLogsGroupViewHistoryCell;
