import "react-datepicker/dist/react-datepicker.css";
import React from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import ErrorManager from "./components/ErrorManager";
import LoadingManager from "./components/LoadingManager";
import TokenManager from "./components/TokenManager";
import SideBarManager from "./components/sideBar/SideBarManager";
import NotFoundPage from "./pages/NotFoundPage";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import DemoPage from "./pages/DemoPage";
import LoginPage from "./pages/authentication/LoginPage";
import {UserType} from "client";
import CreateNewEquipmentPage from "./pages/equipmentOptions/CreateNewEquipmentPage";
import EquipmentLocationFormPage from "./pages/equipmentOptions/EquipmentLocationFormPage";
import ManageCranesPage from "./pages/equipmentOptions/ManageCranesPage";
import ManageGeneratorPage from "./pages/equipmentOptions/ManageGeneratorPage";
import ManageMaterialHoistPage from "./pages/equipmentOptions/ManageMaterialHoistPage";
import CreateNewUserPage from "./pages/usersOptions/CreateNewUserPage";
import ManageUsersPage from "./pages/usersOptions/ManageUsersPage";
import CreateNewSitePage from "./pages/sitesOptions/CreateNewSitePage";
import ManageSitesPages from "./pages/sitesOptions/ManageSitesPages";
import ServiceReportsPage from "./pages/alliedCraneEmployeeForms/ServiceReportsPage";
import TimeSheetsPage from "./pages/alliedCraneEmployeeForms/TimeSheetsPage";
import FinalTestFormsPage from "./pages/alliedCraneEmployeeForms/FinalTestFormsPage";
import CraneDailyLogsPage from "./pages/craneOperatorForms/CraneDailyLogsPage";
import GeneratorDailyLogsPage from "./pages/craneOperatorForms/GeneratorDailyLogsPage";
import CraneWeeklyLogsPage from "./pages/craneOperatorForms/CraneWeeklyLogsPage";
import CraneMonthlyLogsPage from "./pages/craneOperatorForms/CraneMonthlyLogsPage";
import CreateCraneDailyLogPage from "./pages/createForms/CreateCraneDailyLogPage";
import CreateGeneratorDailyLogPage from "./pages/createForms/CreateGeneratorDailyLogPage";
import CreateCraneWeeklyLogPage from "./pages/createForms/CreateCraneWeeklyLogPage";
import CreateCraneMonthlyLogPage from "./pages/createForms/CreateCraneMonthlyLogPage";
import CreateServiceReportPage from "./pages/createForms/CreateServiceReportPage";
import CreateTimeSheetPage from "./pages/createForms/CreateTimeSheetPage";
import CreateFinalTestFormPage from "./pages/createForms/CreateFinalTestFormPage";
import ServiceReportsHistoryPage from "./pages/formHistory/ServiceReportsHistoryPage";
import TimeSheetsHistoryPage from "./pages/formHistory/TimeSheetsHistoryPage";
import FinalTestFormsHistoryPage from "./pages/formHistory/FinalTestFormsHistoryPage";
import EditServiceReportPage from "./pages/createForms/EditServiceReportPage";
import EditTimeSheetPage from "./pages/createForms/EditTimeSheetPage";
import EditFinalTestFormPage from "./pages/createForms/EditFinalTestFormPage";
import EditGeneratorDailyLogPage from "./pages/createForms/EditGeneratorDailyLogPage";
import EditCraneDailyLogPage from "./pages/createForms/EditCraneDailyLogPage";
import EditCraneWeeklyLogPage from "./pages/createForms/EditCraneWeeklyLogPage";
import EditCraneMonthlyLogPage from "./pages/createForms/EditCraneMonthlyLogPage";
import GeneralDeficiencyReports from "./pages/deficienyReports/GeneralDeficiencyReports";
import AdminCreateTimeSheetForUserPage from "./pages/adminForms/AdminCreateTimeSheetForUserPage";
import OperatorSelfRegistration from "./pages/authentication/OperatorSelfRegistration";
import OperatorQrCodeLogin from "./pages/authentication/OperatorQrCodeLogin";

const App: React.FC = () => {

	return (
		<BrowserRouter>
			<ErrorManager/>
			<LoadingManager/>
			<TokenManager/>

			<SideBarManager>
				<main className="mh-100">
					<Switch>
						<AuthenticatedRoute exact path="/authenticated-test" component={DemoPage}/>
						{/*<Route exact path="/test" component={DemoPage}/>*/}

						{/**/}
						{/**/}

						{/*Equipment Options*/}
						<AuthenticatedRoute
							exact
							path="/equipment-options/create-new-equipment"
							component={CreateNewEquipmentPage}
							access={[UserType.ADMIN]}
						/>

						<AuthenticatedRoute
							exact
							path="/equipment-options/equipment-location-form"
							component={EquipmentLocationFormPage}
							access={[UserType.ADMIN]}
						/>

						<AuthenticatedRoute
							exact
							path="/equipment-options/manage-equipment/manage-cranes"
							component={ManageCranesPage}
							access={[UserType.ADMIN]}
						/>

						<AuthenticatedRoute
							exact
							path="/equipment-options/manage-equipment/manage-equipment"
							component={ManageGeneratorPage}
							access={[UserType.ADMIN]}
						/>

						<AuthenticatedRoute
							exact
							path="/equipment-options/manage-equipment/manage-material-hoists"
							component={ManageMaterialHoistPage}
							access={[UserType.ADMIN]}
						/>

						<AuthenticatedRoute
							exact
							path="/manage-equipment/crane-deficiencies"
							component={ManageCranesPage}
							access={[UserType.ALLIED_CRANE_EMPLOYEE]}
						/>

						<AuthenticatedRoute
							exact
							path="/manage-equipment/equipment-deficiencies"
							component={ManageGeneratorPage}
							access={[UserType.ALLIED_CRANE_EMPLOYEE]}
						/>

						<AuthenticatedRoute
							exact
							path="/manage-equipment/material-hoist-deficiencies"
							component={ManageMaterialHoistPage}
							access={[UserType.ALLIED_CRANE_EMPLOYEE]}
						/>

						{/*Deficiencies*/}
						<AuthenticatedRoute
							exact
							path="/deficiency-reports"
							component={GeneralDeficiencyReports}
							access={[UserType.ADMIN, UserType.ALLIED_CRANE_EMPLOYEE]}
						/>

						{/*Users Options*/}
						<AuthenticatedRoute
							exact
							path="/user-options/create-new-user"
							component={CreateNewUserPage}
							access={[UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR]}
						/>

						<AuthenticatedRoute
							exact
							path="/user-options/manage-users"
							component={ManageUsersPage}
							access={[UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR]}
						/>

						{/*Sites Options*/}
						<AuthenticatedRoute
							exact
							path="/sites-options/create-new-site"
							component={CreateNewSitePage}
							access={[UserType.ADMIN]}
						/>

						<AuthenticatedRoute
							exact
							path="/sites-options/manage-sites"
							component={ManageSitesPages}
							access={[UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR]}
						/>

						<AuthenticatedRoute
							exact
							path="/sites-options/view-sites"
							component={ManageSitesPages}
							access={[UserType.CRANE_OPERATOR, UserType.ALLIED_CRANE_EMPLOYEE]}
						/>

						{/*Allied Crane Employee Forms*/}
						<AuthenticatedRoute
							exact
							path="/allied-crane-employee-forms/service-reports"
							component={ServiceReportsPage}
							access={[UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR, UserType.HEALTH_AND_SAFETY_OFFICER]}
						/>

						<AuthenticatedRoute
							exact
							path="/allied-crane-employee-forms/time-sheets"
							component={TimeSheetsPage}
							access={[UserType.ADMIN]}
						/>

						<AuthenticatedRoute
							exact
							path="/allied-crane-employee-forms/create-time-sheet"
							component={AdminCreateTimeSheetForUserPage}
							access={[UserType.ADMIN]}
						/>

						<AuthenticatedRoute
							exact
							path="/allied-crane-employee-forms/final-test-forms"
							component={FinalTestFormsPage}
							access={[UserType.ADMIN]}
						/>

						{/*Create Forms*/}
						<AuthenticatedRoute
							exact
							path="/create-forms/create-daily-log/create-crane-daily-log"
							component={CreateCraneDailyLogPage}
							access={[UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR, UserType.CRANE_OPERATOR, UserType.ALLIED_CRANE_EMPLOYEE, UserType.HEALTH_AND_SAFETY_OFFICER]}
						/>

						<AuthenticatedRoute
							exact
							path="/edit-forms/edit-daily-log/edit-crane-daily-log"
							component={EditCraneDailyLogPage}
							access={[UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR, UserType.CRANE_OPERATOR, UserType.ALLIED_CRANE_EMPLOYEE, UserType.HEALTH_AND_SAFETY_OFFICER]}
						/>

						<AuthenticatedRoute
							exact
							path="/create-forms/create-daily-log/create-equipment-daily-log"
							component={CreateGeneratorDailyLogPage}
							access={[UserType.CRANE_OPERATOR]}
						/>

						<AuthenticatedRoute
							exact
							path="/edit-forms/edit-daily-log/edit-equipment-daily-log"
							component={EditGeneratorDailyLogPage}
							access={[UserType.SECONDARY_ADMIN, UserType.SUPERVISOR, UserType.CRANE_OPERATOR]}
						/>

						<AuthenticatedRoute
							exact
							path="/create-forms/create-crane-weekly-log"
							component={CreateCraneWeeklyLogPage}
							access={[UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR, UserType.CRANE_OPERATOR, UserType.ALLIED_CRANE_EMPLOYEE, UserType.HEALTH_AND_SAFETY_OFFICER]}
						/>

						<AuthenticatedRoute
							exact
							path="/edit-forms/edit-crane-weekly-log"
							component={EditCraneWeeklyLogPage}
							access={[UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR, UserType.CRANE_OPERATOR, UserType.ALLIED_CRANE_EMPLOYEE, UserType.HEALTH_AND_SAFETY_OFFICER]}
						/>

						<AuthenticatedRoute
							exact
							path="/create-forms/create-crane-monthly-log"
							component={CreateCraneMonthlyLogPage}
							access={[UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR, UserType.CRANE_OPERATOR, UserType.ALLIED_CRANE_EMPLOYEE, UserType.HEALTH_AND_SAFETY_OFFICER]}
						/>

						<AuthenticatedRoute
							exact
							path="/edit-forms/edit-crane-monthly-log"
							component={EditCraneMonthlyLogPage}
							access={[UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR, UserType.CRANE_OPERATOR, UserType.ALLIED_CRANE_EMPLOYEE, UserType.HEALTH_AND_SAFETY_OFFICER]}
						/>

						<AuthenticatedRoute
							exact
							path="/create-forms/create-service-report"
							component={CreateServiceReportPage}
							access={[UserType.ADMIN, UserType.ALLIED_CRANE_EMPLOYEE]}
						/>

						<AuthenticatedRoute
							exact
							path="/edit-forms/edit-service-report"
							component={EditServiceReportPage}
							access={[UserType.ADMIN, UserType.ALLIED_CRANE_EMPLOYEE]}
						/>

						<AuthenticatedRoute
							exact
							path="/create-forms/create-time-sheet"
							component={CreateTimeSheetPage}
							access={[UserType.ALLIED_CRANE_EMPLOYEE]}
						/>

						<AuthenticatedRoute
							exact
							path="/edit-forms/edit-time-sheet"
							component={EditTimeSheetPage}
							access={[UserType.ADMIN, UserType.ALLIED_CRANE_EMPLOYEE]}
						/>

						<AuthenticatedRoute
							exact
							path="/create-forms/create-final-test-form"
							component={CreateFinalTestFormPage}
							access={[UserType.ADMIN, UserType.ALLIED_CRANE_EMPLOYEE]}
						/>

						<AuthenticatedRoute
							exact
							path="/edit-forms/edit-final-test-form"
							component={EditFinalTestFormPage}
							access={[UserType.ADMIN, UserType.ALLIED_CRANE_EMPLOYEE]}
						/>

						{/*Form History*/}
						<AuthenticatedRoute
							exact
							path="/form-history/service-reports-history"
							component={ServiceReportsHistoryPage}
							access={[UserType.ALLIED_CRANE_EMPLOYEE]}
						/>

						<AuthenticatedRoute
							exact
							path="/form-history/time-sheets-history"
							component={TimeSheetsHistoryPage}
							access={[UserType.ALLIED_CRANE_EMPLOYEE]}
						/>

						<AuthenticatedRoute
							exact
							path="/form-history/final-test-forms-history"
							component={FinalTestFormsHistoryPage}
							access={[UserType.ALLIED_CRANE_EMPLOYEE]}
						/>

						<AuthenticatedRoute
							exact
							path="/form-history/daily-log-history/crane-daily-log-history"
							component={CraneDailyLogsPage}
							access={[UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR, UserType.CRANE_OPERATOR, UserType.ALLIED_CRANE_EMPLOYEE, UserType.HEALTH_AND_SAFETY_OFFICER]}
						/>

						<AuthenticatedRoute
							exact
							path="/form-history/daily-log-history/equipment-daily-log-history"
							component={GeneratorDailyLogsPage}
							access={[UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR, UserType.CRANE_OPERATOR, UserType.HEALTH_AND_SAFETY_OFFICER]}
						/>

						<AuthenticatedRoute
							exact
							path="/form-history/weekly-log-history/crane-weekly-log-history"
							component={CraneWeeklyLogsPage}
							access={[UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR, UserType.CRANE_OPERATOR, UserType.ALLIED_CRANE_EMPLOYEE, UserType.HEALTH_AND_SAFETY_OFFICER]}
						/>

						<AuthenticatedRoute
							exact
							path="/form-history/monthly-log-history/crane-monthly-log-history"
							component={CraneMonthlyLogsPage}
							access={[UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR, UserType.CRANE_OPERATOR, UserType.ALLIED_CRANE_EMPLOYEE, UserType.HEALTH_AND_SAFETY_OFFICER]}
						/>

						<Route exact path="/" component={LoginPage}/>
						<Route exact path="/operator-registration/:crane" component={OperatorSelfRegistration}/>
						<Route exact path="/operator-login/:crane" component={OperatorQrCodeLogin}/>
						<Route exact path="/404" component={NotFoundPage}/>
						{/*<Redirect to="/404"/>*/}
					</Switch>
				</main>
			</SideBarManager>
		</BrowserRouter>
	);
};

export default App;
