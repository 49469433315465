/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const ServiceReportSortField = {
    publishedDate: 'publishedDate',
    submittedDate: 'submittedDate',
    date: 'date',
    customer: 'customer',
    serviceRequestedBy: 'serviceRequestedBy',
    usageHours: 'usageHours',
    reportedIssue: 'reportedIssue',
    additionalServiceRequired: 'additionalServiceRequired',
    repEmail: 'repEmail',
    authorizedRepresentative: 'authorizedRepresentative',
    shortIdentifier: 'shortIdentifier',
    createdAt: 'createdAt',
    updatedAt: 'updatedAt'
};
export function ServiceReportSortFieldFromJSON(json) {
    return ServiceReportSortFieldFromJSONTyped(json, false);
}
export function ServiceReportSortFieldFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ServiceReportSortFieldToJSON(value) {
    return value;
}
