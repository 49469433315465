import {CraneType} from "client";

/**
 * Returns the keys of the fields needed for logs based on crane type,
 * with keys of the interfaces CreateLuffingTowerCraneDailyLogBody,
 * CreateSelfErectingCraneDailyLogBody, and CreateTowerCraneDailyLogBody.
 *
 * @param craneType
 */
export function getCraneDailyLogInspectionItems(craneType: CraneType): string[] {

	switch (craneType) {
		case CraneType.LUFFING_TOWER:
			return [
				"electricalPowerCords",
				"groundFaultCircuitInterrupter",
				"onOffSwitch",
				"craneBaseInspection",
				"inspectWalkways",
				"inspectStructure",
				"ensureAllTower",
				"ensureAllDoors",
				"operatorsControlsAdequate",
				"loadMomentHoistLimit",
				"loadMomentLuffingLimit",
				"confirmLocationOfZoning",
				"maximumLoad",
				"luffingUpSwitch",
				"luffingDownSwitch",
				"luffingUpAndDownDecelerationSwitch",
				"boomAngleIndicator",
				"counterweightSafetySwitch",
				"hoistUpDecelerationLimit",
				"hoistUpperLimit",
				"antiTwoBlockSwitch",
				"hoistDownLimit",
				"ensureAllAudioVisualIndicatorsFunction",
				"anemometer",
				"hoistBrakeFunctioning",
				"slewingBrakeFunctioning",
				"luffingBrake",
				"visuallyInspectLoadBlockAndHook",
				"travelBrakeToRail",
				"railTravelForwardAndReverse",
				"inspectTracksForLoseConnections",
				"housekeeping",
				"supervisorNotedOfAnyDefectsOrFaults",
			];
		case CraneType.SELF_ERECTING:
			return [
				"electricalPowerCords",
				"groundFaultCircuitInterrupter",
				"onOffSwitch",
				"craneBaseInspection",
				"inspectWalkways",
				"inspectStructure",
				"ensureAllDoors",
				"operatorsControlsAdequate",
				"loadMomentHoistLimit",
				"loadMomentTrolleyLimit",
				"confirmLocationOfZoning",
				"maximumLoad",
				"trolleyOut",
				"trolleyIn",
				"hoistUpDecelerationLimit",
				"hoistUpperLimit",
				"hoistDownLimit",
				"ensureAllAudioVisualIndicatorsFunction",
				"anemometer",
				"hoistBrakeFunctioning",
				"slewingBrakeFunctioning",
				"trolleyBrake",
				"visuallyInspectLoadBlockAndHook",
				"travelBrakeToRail",
				"railTravelForwardAndReverse",
				"inspectTracksForLoseConnections",
				"baseLevel",
				"foundationCondition",
				"housekeeping",
				"supervisorNotedOfAnyDefectsOrFaults",
			];
		case CraneType.TOWER:
			return [
				"electricalPowerCords",
				"groundFaultCircuitInterrupter",
				"onOffSwitch",
				"craneBaseInspection",
				"inspectWalkways",
				"inspectStructure",
				"ensureAllTower",
				"ensureAllDoors",
				"operatorsControlsAdequate",
				"loadMomentHoistLimit",
				"loadMomentTrolleyLimit",
				"confirmLocationOfZoning",
				"maximumLoad",
				"trolleyOut",
				"trolleyIn",
				"hoistUpDecelerationLimit",
				"hoistUpperLimit",
				"hoistDownLimit",
				"ensureAllAudioVisualIndicatorsFunction",
				"anemometer",
				"hoistBrakeFunctioning",
				"slewingBrakeFunctioning",
				"trolleyBrake",
				"visuallyInspectLoadBlockAndHook",
				"travelBrakeToRail",
				"railTravelForwardAndReverse",
				"inspectTracksForLoseConnections",
				"housekeeping",
				"supervisorNotedOfAnyDefectsOrFaults",
			];
	}

	return [];
}
