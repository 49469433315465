import React, {ChangeEventHandler, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	Address,
	CreateSiteBody,
	Equipment,
	EquipmentApi,
	EquipmentType,
	SitesApi,
	Token,
	User,
	UsersApi,
	UserType
} from "client";
import AlliedContainer from "../../components/AlliedContainer";
import AlliedPageHeader from "../../components/AlliedPageHeader";
import AlliedFormContainer from "../../components/AlliedFormContainer";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {Button, Col, Row} from "reactstrap";
import getConfig from "../../utils/getConfig";
import AlliedAutoComplete from "../../components/inputs/AlliedAutoComplete";
import {parseUsersForAutoComplete} from "../../utils/parseUsersForAutoComplete";
import {parseEquipmentForAutoComplete} from "../../utils/parseEquipmentForAutoComplete";
import Spacer from "../../components/Spacer";
import {useHistory} from "react-router-dom";

interface IProps {
	dispatch?: any;
	fullToken: Token;
}

const defaultCreateSiteForm: CreateSiteBody = {
	name: "",
	address: {
		addressLine1: "",
		addressLine2: undefined,
		zipCode: undefined,
		city: "",
		state: undefined,
		country: "",
	},
	contactNumber: "",
	contactEmail: "",
	renter: "",
	siteOwner: "",
	userIDs: [],
	equipmentIDs: [],
};

const CreateNewSitePage: React.FC<IProps> = (props) => {

	const history = useHistory();

	// Form & selected auto-complete values.
	const [createSiteForm, setCreateSiteForm] = useState<CreateSiteBody>(defaultCreateSiteForm);
	const [selectedManagers, setSelectedManagers] = useState<string[]>([]);
	const [selectedSupervisors, setSelectedSupervisors] = useState<string[]>([]);
	const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
	const [selectedOperators, setSelectedOperators] = useState<string[]>([]);
	const [selectedHealthOfficers, setSelectedHealthOfficers] = useState<string[]>([]);
	const [selectedCranes, setSelectedCranes] = useState<string[]>([]);
	const [selectedGenerators, setSelectedGenerators] = useState<string[]>([]);
	const [selectedHoists, setSelectedHoists] = useState<string[]>([]);

	// Api responses for the auto-complete inputs, these only get set once.
	const [apiManagers, setApiManagers] = useState<Array<User>>(undefined);
	const [apiSupervisors, setApiSupervisors] = useState<Array<User>>(undefined);
	const [apiEmployees, setApiEmployees] = useState<Array<User>>(undefined);
	const [apiOperators, setApiOperators] = useState<Array<User>>(undefined);
	const [apiHealthOfficers, setApiHealthOfficers] = useState<Array<User>>(undefined);
	const [apiCranes, setApiCranes] = useState<Array<Equipment>>(undefined);
	const [apiGenerators, setApiGenerators] = useState<Array<Equipment>>(undefined);
	const [apiHoists, setApiHoists] = useState<Array<Equipment>>(undefined);

	/**
	 * On mount, call api to get values from the auto-complete inputs.
	 *
	 */
	useEffect(() => {
		getAutoCompleteValues().then().catch();
	}, []);

	/**
	 * Call apis to get auto-complete lists for managers, supervisors, employees, and operators,
	 * as well as cranes, generators, and material hoists.
	 *
	 */
	async function getAutoCompleteValues(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const userApiBase = new UsersApi(getConfig(props.fullToken));
			const equipmentApiBase = new EquipmentApi(getConfig(props.fullToken));

			const managers = await userApiBase.getUserList({
				limit: 10000,
				offset: 0,
				type: [UserType.SECONDARY_ADMIN],
			});

			const supervisors = await userApiBase.getUserList({
				limit: 10000,
				offset: 0,
				type: [UserType.SUPERVISOR],
			});

			const employees = await userApiBase.getUserList({
				limit: 10000,
				offset: 0,
				type: [UserType.ALLIED_CRANE_EMPLOYEE],
			});

			const operators = await userApiBase.getUserList({
				limit: 10000,
				offset: 0,
				type: [UserType.CRANE_OPERATOR],
			});
			const healthOfficers = await userApiBase.getUserList({
				limit: 10000,
				offset: 0,
				type: [UserType.HEALTH_AND_SAFETY_OFFICER],
			});

			const cranes = await equipmentApiBase.getEquipmentList({
				limit: 10000,
				offset: 0,
				type: [EquipmentType.CRANE],
			});

			const generators = await equipmentApiBase.getEquipmentList({
				limit: 10000,
				offset: 0,
				type: [EquipmentType.GENERATOR],
			});

			const hoists = await equipmentApiBase.getEquipmentList({
				limit: 10000,
				offset: 0,
				type: [EquipmentType.MATERIAL_HOIST],
			});

			setApiManagers(managers.users);
			setApiSupervisors(supervisors.users);
			setApiEmployees(employees.users);
			setApiOperators(operators.users);
			setApiHealthOfficers(healthOfficers.users);
			setApiCranes(cranes.equipment);
			setApiGenerators(generators.equipment);
			setApiHoists(hoists.equipment);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Dynamic onChange for the form text fields.
	 *
	 * @param key
	 */
	function createSiteOnChange(key: keyof CreateSiteBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setCreateSiteForm({
				...createSiteForm,
				[key]: e?.target?.value,
			});
		}
	}

	/**
	 * Dynamic onChange for the address fields.
	 *
	 * @param key
	 */
	function addressOnChange(key: keyof Address): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setCreateSiteForm({
				...createSiteForm,
				address: {
					...createSiteForm.address,
					[key]: e?.target?.value,
				}
			});
		}
	}

	/**
	 * Call api to submit new site, navigate to the Manage Sites page on success.
	 *
	 * @param e
	 */
	async function submitNewSite(e?: React.SyntheticEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new SitesApi(getConfig(props.fullToken)).createSite({
				createSiteBody: {
					...createSiteForm,
					userIDs: [...selectedManagers, ...selectedSupervisors, ...selectedEmployees, ...selectedOperators],
					equipmentIDs: [...selectedCranes, ...selectedGenerators, ...selectedHoists],
				},
			});

			history.push("/sites-options/manage-sites");
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<AlliedContainer>
			<AlliedPageHeader
				title="Create a New Site"
				showTooltip={false}
			/>

			<hr/>

			<AlliedFormContainer>
				<form onSubmit={submitNewSite}>
					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Site Name</label>
							<input
								placeholder="Enter Site Name..."
								value={createSiteForm.name}
								onChange={createSiteOnChange("name")}
							/>
						</Col>

						<Col xs={0} md={8}/>

						<Col xs={12} md={4} className="mb-3">
							<label>Address Line 1</label>
							<input
								placeholder="Enter Address Line 1..."
								value={createSiteForm?.address?.addressLine1}
								onChange={addressOnChange("addressLine1")}
							/>
						</Col>

						<Col xs={12} md={4} className="mb-3">
							<label>Address Line 2</label>
							<input
								placeholder="Enter Address Line 2..."
								value={createSiteForm?.address?.addressLine2}
								onChange={addressOnChange("addressLine2")}
							/>
						</Col>

						<Col xs={12} md={4} className="mb-3">
							<label>City</label>
							<input
								placeholder="Enter City..."
								value={createSiteForm?.address?.city}
								onChange={addressOnChange("city")}
							/>
						</Col>

						<Col xs={12} md={4} className="mb-3">
							<label>Province</label>
							<input
								placeholder="Enter Province..."
								value={createSiteForm?.address?.state}
								onChange={addressOnChange("state")}
							/>
						</Col>

						<Col xs={12} md={4} className="mb-3">
							<label>Country</label>
							<input
								placeholder="Enter Country..."
								value={createSiteForm?.address?.country}
								onChange={addressOnChange("country")}
							/>
						</Col>

						<Col xs={12} md={4} className="mb-3">
							<label>Postal Code</label>
							<input
								placeholder="Enter Postal Code..."
								value={createSiteForm?.address?.zipCode}
								onChange={addressOnChange("zipCode")}
							/>
						</Col>

						<Col xs={12} md={4} className="mb-3">
							<label>Renter</label>
							<input
								placeholder="Enter Renter Company Name..."
								value={createSiteForm?.renter}
								onChange={createSiteOnChange("renter")}
							/>
						</Col>

						<Col xs={12} md={0}/>

						<Col xs={12} md={4} className="mb-3">
							<label>Contact Name</label>
							<input
								placeholder="Enter Contact Name..."
								value={createSiteForm?.siteOwner}
								onChange={createSiteOnChange("siteOwner")}
							/>
						</Col>

						<Col xs={12} md={4} className="mb-3">
							<label>Contact Number</label>
							<input
								placeholder="Enter Contact Number..."
								value={createSiteForm?.contactNumber}
								onChange={createSiteOnChange("contactNumber")}
							/>
						</Col>

						<Col xs={12} md={4} className="mb-3">
							<label>Contact Email</label>
							<input
								placeholder="Enter Contact Email..."
								value={createSiteForm?.contactEmail}
								onChange={createSiteOnChange("contactEmail")}
							/>
						</Col>

						<Col xs={12} md={4} className="mb-3">
							<label>Assign Manager(s)</label>
							<AlliedAutoComplete
								placeholder="Select Manager(s)..."
								options={parseUsersForAutoComplete(apiManagers)}
								selections={selectedManagers}
								setSelections={setSelectedManagers}
							/>
						</Col>

						<Col xs={0} md={8}/>

						<Col xs={12} md={4} className="mb-3">
							<label>Assign Supervisor(s)</label>
							<AlliedAutoComplete
								placeholder="Select Supervisor(s)..."
								options={parseUsersForAutoComplete(apiSupervisors)}
								selections={selectedSupervisors}
								setSelections={setSelectedSupervisors}
							/>
						</Col>

						<Col xs={12} md={4} className="mb-3">
							<label>Assign Allied Crane Employee(s)</label>
							<AlliedAutoComplete
								placeholder="Select Allied Crane Employee(s)..."
								options={parseUsersForAutoComplete(apiEmployees)}
								selections={selectedEmployees}
								setSelections={setSelectedEmployees}
							/>
						</Col>

						<Col xs={12} md={4} className="mb-3">
							<label>Assign Crane Operator(s)</label>
							<AlliedAutoComplete
								placeholder="Select Crane Operator(s)..."
								options={parseUsersForAutoComplete(apiOperators)}
								selections={selectedOperators}
								setSelections={setSelectedOperators}
							/>
						</Col>

						<Col xs={12} md={4} className="mb-3">
							<label>Assign Health & Safety Officer(s)</label>
							<AlliedAutoComplete
								placeholder="Select Health & Safety Officer(s)..."
								options={parseUsersForAutoComplete(apiHealthOfficers)}
								selections={selectedHealthOfficers}
								setSelections={setSelectedHealthOfficers}
							/>
						</Col>
					</Row>

					<hr/>

					<Row>
						<Col xs={12} md={8} className="mb-3">
							<label>Assign Crane(s)</label>
							<AlliedAutoComplete
								placeholder="Select Crane(s)..."
								options={parseEquipmentForAutoComplete(apiCranes)}
								selections={selectedCranes}
								setSelections={setSelectedCranes}
							/>
						</Col>

						<Col xs={0} md={4}/>

						<Col xs={12} md={8} className="mb-3">
							<label>Assign Generator(s)</label>
							<AlliedAutoComplete
								placeholder="Select Generator(s)..."
								options={parseEquipmentForAutoComplete(apiGenerators)}
								selections={selectedGenerators}
								setSelections={setSelectedGenerators}
							/>
						</Col>

						<Col xs={0} md={4}/>

						<Col xs={12} md={8} className="mb-3">
							<label>Assign Material Hoist(s)</label>
							<AlliedAutoComplete
								placeholder="Select Material Hoist(s)..."
								options={parseEquipmentForAutoComplete(apiHoists)}
								selections={selectedHoists}
								setSelections={setSelectedHoists}
							/>
						</Col>

						<Col xs={0} md={4}/>
					</Row>

					<Button
						color="black"
						type="submit"
						onClick={submitNewSite}
					>
						Create New Site
					</Button>
				</form>

				<Spacer size={10}/>
			</AlliedFormContainer>
		</AlliedContainer>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(CreateNewSitePage);
