import {omitBy} from "lodash";

/**
 * Removes empty arrays from the passed in object.
 *
 * @param _object
 */
export function removeEmptyArraysFromObject(_object: { [key: string]: any }): any {

	return omitBy(_object, (value: any) => {
		return Array.isArray(value) && value.length === 0;
	});
}
