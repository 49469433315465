import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	GetSiteListRequest,
	GetSiteListResponse,
	Site,
	SitesApi,
	SiteSortField,
	SiteWithUsersAndEquipment,
	Token,
	UsersByType,
	UserType
} from "client";
import AlliedContainer from "../../components/AlliedContainer";
import AlliedPageHeader from "../../components/AlliedPageHeader";
import AlliedTableContainer from "../../components/tables/AlliedTableContainer";
import AlliedTableHeaderCell from "../../components/tables/cells/AlliedTableHeaderCell";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import ManageSitesViewDetailsCell from "../../components/tables/cells/ManageSitesViewDetailsCell";
import ManageSitesEditUsersCell from "../../components/tables/cells/ManageSitesEditUsersCell";
import ManageSitesDisableCell from "../../components/tables/cells/ManageSitesDisableCell";
import ManageSitesEquipmentCell from "../../components/tables/cells/ManageSitesEquipmentCell";
import {userTypeCheck} from "../../utils/userTypeCheck";
import ManageSitesFilterModal from "../../components/modals/ManageSitesFilterModal";
import {removeEmptyArraysFromObject} from "../../utils/removeEmptyArraysFromObject";
import {omit} from "lodash";
import {alliedPaginationLimitOptions} from "../../components/tables/paginator/AlliedPaginatorLimitController";
import {abstractOnSortChange, getTableHeaderSortClassName} from "../../utils/abstractOnSortChange";
import ManageSitesUsersCell from "../../components/tables/cells/ManageSitesUsersCell";

interface IProps {
	dispatch?: any;
	fullToken: Token;
}

export interface GetSiteListRequestFrontend extends GetSiteListRequest {
	managers: string[];
	supervisors: string[];
	operators: string[];
	alliedCraneEmployees: string[];
	safetyOfficers: string[];
}

const defaultFilter: GetSiteListRequestFrontend  = {
	limit: alliedPaginationLimitOptions[0],
	offset: 0,
	name: [],
	owner: [],
	renter: [],
	make: [],
	model: [],
	serialNumber: [],
	equipment: [],
	user: [],
	managers: [],
	supervisors: [],
	operators: [],
	alliedCraneEmployees: [],
	safetyOfficers: [],
};

const ManageSitesPages: React.FC<IProps> = (props) => {

	const [showFilterModal, setShowFilterModal] = useState(false);
	const [siteFilter, setSiteFilter] = useState<GetSiteListRequestFrontend>(defaultFilter);
	const [sitesResponse, setSitesResponse] = useState<GetSiteListResponse>(undefined);

	console.log('sitesResl.ons.site', sitesResponse?.sites);

	useEffect(() => {
		getSites().then().catch();
	}, [JSON.stringify(siteFilter)]);

	/**
	 *  Call api to get list of sites.
	 *
	 */
	async function getSites(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			// Combine the IDs for the different user types & remove them from the object.
			const formattedRequest: GetSiteListRequest = {
				...omit(siteFilter, ["managers", "supervisors", "operators", "alliedCraneEmployees", "safetyOfficers"]),
				user: [...siteFilter.managers, ...siteFilter.supervisors, ...siteFilter.operators, ...siteFilter.alliedCraneEmployees, ...siteFilter.safetyOfficers],
			}

			const res = await new SitesApi(getConfig(props.fullToken)).getSiteList(removeEmptyArraysFromObject(formattedRequest));
			setSitesResponse(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Toggles visibility of the filter modal.
	 *
	 */
	function toggleFilterModal(): void {
		setShowFilterModal(!showFilterModal);
	}

	/**
	 * When finished the filter modal, save the new filter (will prompt api via useEffect),
	 * and hide the filter modal
	 *
	 * @param newFilter
	 */
	function onDoneFilterModal(newFilter: GetSiteListRequestFrontend): void {
		setSiteFilter({
			...newFilter,
			offset: 0,
		});
		setShowFilterModal(false);
	}

	function makeUserCell(siteId: string, users: UsersByType, userType: keyof UsersByType, modalTitle: string): ReactNode {
		return (
			<ManageSitesUsersCell
				key={"users" + "userType" + siteId}
				users={users[userType]}
				userType={userType}
				modalTitle={modalTitle}
			/>
		);
	}

	/**
	 * Renderer for the Equipment cell.
	 *
	 * @param site
	 */
	function makeEquipmentCell(site: SiteWithUsersAndEquipment): ReactNode {
		return (
			<ManageSitesEquipmentCell
				key={"equipment" + site._id}
				site={site}
				onDone={getSites}
			/>
		);
	}

	/**
	 * Renderer for the View Site Details column.
	 *
	 * @param site
	 */
	function makeViewDetailsCell(site: Site): ReactNode {
		return (
			<ManageSitesViewDetailsCell
				key={"view" + site._id}
				site={site}
				onDone={getSites}
			/>
		);
	}

	/**
	 * Renderer for the Edit Users column.
	 *
	 * @param site
	 */
	function makeEditUsersCell(site: SiteWithUsersAndEquipment): ReactNode {
		return (
			<ManageSitesEditUsersCell
				key={"edit-users" + site._id}
				site={site}
				onDone={getSites}
			/>
		);
	}

	/**
	 * Renderer for the Disable Site column.
	 *
	 * @param site
	 */
	function makeDisableCell(site: Site): ReactNode {
		return (
			<ManageSitesDisableCell
				key={"disable" + site._id}
				site={site}
				onDone={getSites}
			/>
		);
	}

	/**
	 * Generate the correct string for the page title based on user type.
	 *
	 * @param tokenType
	 */
	function getPageTitle(tokenType: UserType): string {
		switch (tokenType) {
			case UserType.ADMIN:
			case UserType.SECONDARY_ADMIN:
			case UserType.SUPERVISOR:
				return "Manage Sites";
			case UserType.CRANE_OPERATOR:
			case UserType.ALLIED_CRANE_EMPLOYEE:
			default:
				return "View Sites";
		}
	}

	return (
		<React.Fragment>
			<ManageSitesFilterModal
				isOpen={showFilterModal}
				filter={siteFilter}
				onClose={toggleFilterModal}
				onDone={onDoneFilterModal}
			/>

			<AlliedContainer>
				<AlliedPageHeader
					title={getPageTitle(props.fullToken?.type)}
					onClick={toggleFilterModal}
				/>

				<hr/>

				<AlliedTableContainer
					<GetSiteListRequestFrontend>
					data={sitesResponse?.sites}
					showPaginator={true}
					paginationInfo={sitesResponse?.paginationInfo}
					filter={siteFilter}
					setFilter={setSiteFilter}
					columnOptions={[
						{
							headerValue: "Name",
							key: "name",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							rowCellClassName: "font-weight-bold",
							headerCellClassName: getTableHeaderSortClassName(SiteSortField.name, siteFilter),
							onSort: abstractOnSortChange<SiteSortField, GetSiteListRequestFrontend>(SiteSortField.name, siteFilter, setSiteFilter),
						},
						(userTypeCheck([UserType.ADMIN], props.fullToken) && {
							headerValue: "Managers",
							key: undefined,
							showSortIcons: false,
							sortable: false,
							headerRender: AlliedTableHeaderCell,
							cellRender: (site: SiteWithUsersAndEquipment) => makeUserCell(site._id, site.users, "secondaryAdmins", `${site.name} Managers`),
						}),
						{
							headerValue: "Supervisors",
							key: undefined,
							showSortIcons: false,
							sortable: false,
							headerRender: AlliedTableHeaderCell,
							cellRender: (site: SiteWithUsersAndEquipment) => makeUserCell(site._id, site.users, "supervisors", `${site.name} Supervisors`),
						},
						(userTypeCheck([UserType.ADMIN, UserType.ALLIED_CRANE_EMPLOYEE], props.fullToken) && {
							headerValue: "AC Employees",
							key: undefined,
							showSortIcons: false,
							sortable: false,
							headerRender: AlliedTableHeaderCell,
							cellRender: (site: SiteWithUsersAndEquipment) => makeUserCell(site._id, site.users, "alliedCraneEmployees", `${site.name} Employees`),
						}),
						(userTypeCheck([UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR, UserType.CRANE_OPERATOR], props.fullToken) && {
							headerValue: "Operators",
							key: undefined,
							showSortIcons: false,
							sortable: false,
							headerRender: AlliedTableHeaderCell,
							cellRender: (site: SiteWithUsersAndEquipment) => makeUserCell(site._id, site.users, "craneOperators", `${site.name} Crane Operators`),
						}),
						(userTypeCheck([UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR], props.fullToken) && {
							headerValue: "Health & Safety",
							key: undefined,
							showSortIcons: false,
							sortable: false,
							headerRender: AlliedTableHeaderCell,
							cellRender: (site: SiteWithUsersAndEquipment) => makeUserCell(site._id, site.users, "healthAndSafetyOfficers", `${site.name} Health & Safety Officers`),
						}),
						{
							headerValue: "Equipment",
							key: undefined,
							showSortIcons: false,
							sortable: false,
							headerRender: AlliedTableHeaderCell,
							cellRender: makeEquipmentCell,
							headerCellClassName: "justify-content-center",
						},
						{
							headerValue: "View Site Details",
							key: undefined,
							showSortIcons: false,
							sortable: false,
							headerRender: AlliedTableHeaderCell,
							cellRender: makeViewDetailsCell,
							headerCellClassName: "justify-content-center",
						},
						(userTypeCheck([UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR], props.fullToken) && {
							headerValue: "Edit Users",
							key: undefined,
							showSortIcons: false,
							sortable: false,
							headerRender: AlliedTableHeaderCell,
							cellRender: makeEditUsersCell,
							headerCellClassName: "justify-content-center",
						}),
						(userTypeCheck([UserType.ADMIN], props.fullToken) && {
							headerValue: "Delete Site",
							key: undefined,
							showSortIcons: false,
							sortable: false,
							headerRender: AlliedTableHeaderCell,
							cellRender: makeDisableCell,
							headerCellClassName: "justify-content-center",
						}),
					]}
				/>
			</AlliedContainer>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageSitesPages);
