import moment, {Moment} from "moment";
import {IAutoCompleteOption} from "../components/inputs/AlliedAutoComplete";

/**
 * Populates auto-complete with month values starting at the beginning of baseDate
 * and up until 1 month in the future.
 *
 */
export function getMonthsForMonthlyLogForm(): number[] {
	const baseDate: Moment = moment({year: 2021, month: 0}).startOf("month");
	const endDate: Moment = moment().add(2, "months").startOf("month");

	const ranges: Array<Moment> = [];

	let date: Moment = baseDate;
	while (date.isBefore(endDate)) {
		ranges.push(date);
		date = moment(date).add(1, "month");
	}

	return ranges.reverse().map((r: Moment) => r.valueOf());
}

/**
 * Used tp convert a list of dates to user-friendly labels.
 *
 * @param ranges
 */
export function convertMonthListToAutoCompleteValues(ranges: number[] = []): Array<IAutoCompleteOption> {
	return ranges.map((r: number) => {
		return {
			value: r?.toString(),
			label: moment(r).format("MMMM YYYY"),
		}
	});
}
