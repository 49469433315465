/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CraneTypeFromJSON, CraneTypeToJSON, } from './CraneType';
import { InspectionStatusFromJSON, InspectionStatusToJSON, } from './InspectionStatus';
import { LogCommentFromJSON, LogCommentToJSON, } from './LogComment';
import { UserFromJSON, UserToJSON, } from './User';
import { LuffingTowerCraneDailyLogFromJSONTyped, SelfErectingCraneDailyLogFromJSONTyped, TowerCraneDailyLogFromJSONTyped } from './';
/**
 * Check if a given object implements the CraneDailyLog interface.
 */
export function instanceOfCraneDailyLog(value) {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "users" in value;
    isInstance = isInstance && "comments" in value;
    isInstance = isInstance && "initials" in value;
    return isInstance;
}
export function CraneDailyLogFromJSON(json) {
    return CraneDailyLogFromJSONTyped(json, false);
}
export function CraneDailyLogFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['type'] === 'LUFFING_TOWER') {
            return LuffingTowerCraneDailyLogFromJSONTyped(json, true);
        }
        if (json['type'] === 'SELF_ERECTING') {
            return SelfErectingCraneDailyLogFromJSONTyped(json, true);
        }
        if (json['type'] === 'TOWER') {
            return TowerCraneDailyLogFromJSONTyped(json, true);
        }
    }
    return {
        'type': CraneTypeFromJSON(json['type']),
        'users': (json['users'].map(UserFromJSON)),
        'comments': (json['comments'].map(LogCommentFromJSON)),
        'initials': json['initials'],
        'hours': !exists(json, 'hours') ? undefined : json['hours'],
        'electricalPowerCords': !exists(json, 'electricalPowerCords') ? undefined : InspectionStatusFromJSON(json['electricalPowerCords']),
        'groundFaultCircuitInterrupter': !exists(json, 'groundFaultCircuitInterrupter') ? undefined : InspectionStatusFromJSON(json['groundFaultCircuitInterrupter']),
        'onOffSwitch': !exists(json, 'onOffSwitch') ? undefined : InspectionStatusFromJSON(json['onOffSwitch']),
        'craneBaseInspection': !exists(json, 'craneBaseInspection') ? undefined : InspectionStatusFromJSON(json['craneBaseInspection']),
        'inspectWalkways': !exists(json, 'inspectWalkways') ? undefined : InspectionStatusFromJSON(json['inspectWalkways']),
        'inspectStructure': !exists(json, 'inspectStructure') ? undefined : InspectionStatusFromJSON(json['inspectStructure']),
        'ensureAllDoors': !exists(json, 'ensureAllDoors') ? undefined : InspectionStatusFromJSON(json['ensureAllDoors']),
        'operatorsControlsAdequate': !exists(json, 'operatorsControlsAdequate') ? undefined : InspectionStatusFromJSON(json['operatorsControlsAdequate']),
        'loadMomentHoistLimit': !exists(json, 'loadMomentHoistLimit') ? undefined : InspectionStatusFromJSON(json['loadMomentHoistLimit']),
        'confirmLocationOfZoning': !exists(json, 'confirmLocationOfZoning') ? undefined : InspectionStatusFromJSON(json['confirmLocationOfZoning']),
        'maximumLoad': !exists(json, 'maximumLoad') ? undefined : InspectionStatusFromJSON(json['maximumLoad']),
        'hoistUpDecelerationLimit': !exists(json, 'hoistUpDecelerationLimit') ? undefined : InspectionStatusFromJSON(json['hoistUpDecelerationLimit']),
        'hoistUpperLimit': !exists(json, 'hoistUpperLimit') ? undefined : InspectionStatusFromJSON(json['hoistUpperLimit']),
        'hoistDownLimit': !exists(json, 'hoistDownLimit') ? undefined : InspectionStatusFromJSON(json['hoistDownLimit']),
        'ensureAllAudioVisualIndicatorsFunction': !exists(json, 'ensureAllAudioVisualIndicatorsFunction') ? undefined : InspectionStatusFromJSON(json['ensureAllAudioVisualIndicatorsFunction']),
        'anemometer': !exists(json, 'anemometer') ? undefined : InspectionStatusFromJSON(json['anemometer']),
        'hoistBrakeFunctioning': !exists(json, 'hoistBrakeFunctioning') ? undefined : InspectionStatusFromJSON(json['hoistBrakeFunctioning']),
        'slewingBrakeFunctioning': !exists(json, 'slewingBrakeFunctioning') ? undefined : InspectionStatusFromJSON(json['slewingBrakeFunctioning']),
        'visuallyInspectLoadBlockAndHook': !exists(json, 'visuallyInspectLoadBlockAndHook') ? undefined : InspectionStatusFromJSON(json['visuallyInspectLoadBlockAndHook']),
        'travelBrakeToRail': !exists(json, 'travelBrakeToRail') ? undefined : InspectionStatusFromJSON(json['travelBrakeToRail']),
        'railTravelForwardAndReverse': !exists(json, 'railTravelForwardAndReverse') ? undefined : InspectionStatusFromJSON(json['railTravelForwardAndReverse']),
        'inspectTracksForLoseConnections': !exists(json, 'inspectTracksForLoseConnections') ? undefined : InspectionStatusFromJSON(json['inspectTracksForLoseConnections']),
        'housekeeping': !exists(json, 'housekeeping') ? undefined : InspectionStatusFromJSON(json['housekeeping']),
        'supervisorNotedOfAnyDefectsOrFaults': !exists(json, 'supervisorNotedOfAnyDefectsOrFaults') ? undefined : InspectionStatusFromJSON(json['supervisorNotedOfAnyDefectsOrFaults']),
    };
}
export function CraneDailyLogToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': CraneTypeToJSON(value.type),
        'users': (value.users.map(UserToJSON)),
        'comments': (value.comments.map(LogCommentToJSON)),
        'initials': value.initials,
        'hours': value.hours,
        'electricalPowerCords': InspectionStatusToJSON(value.electricalPowerCords),
        'groundFaultCircuitInterrupter': InspectionStatusToJSON(value.groundFaultCircuitInterrupter),
        'onOffSwitch': InspectionStatusToJSON(value.onOffSwitch),
        'craneBaseInspection': InspectionStatusToJSON(value.craneBaseInspection),
        'inspectWalkways': InspectionStatusToJSON(value.inspectWalkways),
        'inspectStructure': InspectionStatusToJSON(value.inspectStructure),
        'ensureAllDoors': InspectionStatusToJSON(value.ensureAllDoors),
        'operatorsControlsAdequate': InspectionStatusToJSON(value.operatorsControlsAdequate),
        'loadMomentHoistLimit': InspectionStatusToJSON(value.loadMomentHoistLimit),
        'confirmLocationOfZoning': InspectionStatusToJSON(value.confirmLocationOfZoning),
        'maximumLoad': InspectionStatusToJSON(value.maximumLoad),
        'hoistUpDecelerationLimit': InspectionStatusToJSON(value.hoistUpDecelerationLimit),
        'hoistUpperLimit': InspectionStatusToJSON(value.hoistUpperLimit),
        'hoistDownLimit': InspectionStatusToJSON(value.hoistDownLimit),
        'ensureAllAudioVisualIndicatorsFunction': InspectionStatusToJSON(value.ensureAllAudioVisualIndicatorsFunction),
        'anemometer': InspectionStatusToJSON(value.anemometer),
        'hoistBrakeFunctioning': InspectionStatusToJSON(value.hoistBrakeFunctioning),
        'slewingBrakeFunctioning': InspectionStatusToJSON(value.slewingBrakeFunctioning),
        'visuallyInspectLoadBlockAndHook': InspectionStatusToJSON(value.visuallyInspectLoadBlockAndHook),
        'travelBrakeToRail': InspectionStatusToJSON(value.travelBrakeToRail),
        'railTravelForwardAndReverse': InspectionStatusToJSON(value.railTravelForwardAndReverse),
        'inspectTracksForLoseConnections': InspectionStatusToJSON(value.inspectTracksForLoseConnections),
        'housekeeping': InspectionStatusToJSON(value.housekeeping),
        'supervisorNotedOfAnyDefectsOrFaults': InspectionStatusToJSON(value.supervisorNotedOfAnyDefectsOrFaults),
    };
}
