/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CreateServiceReportEntryBodyFromJSON, CreateServiceReportEntryBodyToJSON, } from './CreateServiceReportEntryBody';
import { PartsAndSuppliesFromJSON, PartsAndSuppliesToJSON, } from './PartsAndSupplies';
/**
 * Check if a given object implements the UpdateServiceReportBody interface.
 */
export function instanceOfUpdateServiceReportBody(value) {
    let isInstance = true;
    isInstance = isInstance && "formID" in value;
    return isInstance;
}
export function UpdateServiceReportBodyFromJSON(json) {
    return UpdateServiceReportBodyFromJSONTyped(json, false);
}
export function UpdateServiceReportBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'formID': json['formID'],
        'equipmentID': !exists(json, 'equipmentID') ? undefined : json['equipmentID'],
        'siteID': !exists(json, 'siteID') ? undefined : json['siteID'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'customer': !exists(json, 'customer') ? undefined : json['customer'],
        'serviceRequestedBy': !exists(json, 'serviceRequestedBy') ? undefined : json['serviceRequestedBy'],
        'usageHours': !exists(json, 'usageHours') ? undefined : json['usageHours'],
        'reportedIssue': !exists(json, 'reportedIssue') ? undefined : json['reportedIssue'],
        'workPerformed': !exists(json, 'workPerformed') ? undefined : json['workPerformed'],
        'createServiceReportEntryBodies': !exists(json, 'createServiceReportEntryBodies') ? undefined : (json['createServiceReportEntryBodies'].map(CreateServiceReportEntryBodyFromJSON)),
        'partsAndSuppliesArray': !exists(json, 'partsAndSuppliesArray') ? undefined : (json['partsAndSuppliesArray'].map(PartsAndSuppliesFromJSON)),
        'additionalServiceRequired': !exists(json, 'additionalServiceRequired') ? undefined : json['additionalServiceRequired'],
        'repEmail': !exists(json, 'repEmail') ? undefined : json['repEmail'],
        'authorizedRepresentative': !exists(json, 'authorizedRepresentative') ? undefined : json['authorizedRepresentative'],
    };
}
export function UpdateServiceReportBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'formID': value.formID,
        'equipmentID': value.equipmentID,
        'siteID': value.siteID,
        'date': value.date,
        'customer': value.customer,
        'serviceRequestedBy': value.serviceRequestedBy,
        'usageHours': value.usageHours,
        'reportedIssue': value.reportedIssue,
        'workPerformed': value.workPerformed,
        'createServiceReportEntryBodies': value.createServiceReportEntryBodies === undefined ? undefined : (value.createServiceReportEntryBodies.map(CreateServiceReportEntryBodyToJSON)),
        'partsAndSuppliesArray': value.partsAndSuppliesArray === undefined ? undefined : (value.partsAndSuppliesArray.map(PartsAndSuppliesToJSON)),
        'additionalServiceRequired': value.additionalServiceRequired,
        'repEmail': value.repEmail,
        'authorizedRepresentative': value.authorizedRepresentative,
    };
}
