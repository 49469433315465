import React from "react";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button} from "reactstrap";

interface IProps {
	isOpen: boolean;
	onClose: () => void;
}

const EditUserPasswordModalSuccess: React.FC<IProps> = (props) => {

	return (
		<AlliedModal
			isOpen={props.isOpen}
			size="md"
		>
			<AlliedModalHeader
				title="Success!"
				onClose={props.onClose}
			/>

			<hr/>

			<div>
				<p>
					User's password has been updated successfully.
				</p>
			</div>

			<div>
				<Button
					color="black"
					onClick={props.onClose}
				>
					Dismiss
				</Button>
			</div>
		</AlliedModal>
	);
};

export default EditUserPasswordModalSuccess;
