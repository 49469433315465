import React from "react";
import {Crane, Equipment, EquipmentType, FinalTestForm, Generator} from "client";
import PrintableSpacer from "../elements/PrintableSpacer";
import PrintableCellLabelWithValue from "../elements/PrintableCellLabelWithValue";
import {valueOrBustPrintables} from "../../../utils/valueOrBust";
import {asPercentOf100} from "../../../utils/asPercentOf100";
import moment from "moment";
import PrintableCellRow from "../elements/PrintableCellRow";
import {parseSiteAddressForSingleInput} from "../../../utils/parseSiteAddressForSingleInput";
import {getCraneTypeDisplay} from "../../../utils/getCraneTypeDisplay";
import PrintableSplitSubHeaders from "../elements/PrintableSplitSubHeaders";
import {convertBooleanToString} from "../../../utils/convertBooleanToString";
import PrintableTextArea from "../elements/PrintableTextArea";
import {getFullNameForTable} from "../../../utils/getFullNameForTable";
import {generateOffsetDate} from "../../../utils/timeZoneConversions";

interface IProps {
	form: FinalTestForm;
}

const FinalTestFormPrintable: React.FC<IProps> = (props) => {

	return (
		<React.Fragment>
			<p className="printable__header">
				Final Test Report
			</p>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Date:"
					value={valueOrBustPrintables(props.form?.date, moment(generateOffsetDate(props.form?.date)).format("MM/DD/YYYY"))}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<p className="printable__sub-header">
				Site Details
			</p>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Project Address:"
					value={valueOrBustPrintables(props.form?.originalSite?.address, parseSiteAddressForSingleInput(props.form?.originalSite?.address))}
					width={asPercentOf100(2 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<p className="printable__sub-header">
				Crane Details
			</p>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Crane Type:"
					value={valueOrBustPrintables(isCrane(props.form?.equipment) && getCraneTypeDisplay(props.form?.equipment?.craneType))}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Base Type:"
					value={valueOrBustPrintables(props.form?.baseType)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Travel Gear:"
					value={valueOrBustPrintables(props.form?.travelGear)}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Crane Make:"
					value={valueOrBustPrintables(props.form?.equipment?.make)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Crane Model:"
					value={valueOrBustPrintables(props.form?.equipment?.model)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Serial #:"
					value={valueOrBustPrintables(props.form?.equipment?.serialNumber)}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Tower Height:"
					value={valueOrBustPrintables(props.form?.towerHeight)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Height Under Hook:"
					value={valueOrBustPrintables(props.form?.heightUnderHook)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Jib Length:"
					value={valueOrBustPrintables(props.form?.jibLength)}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Required Power Supply Volts:"
					value={valueOrBustPrintables(props.form?.requiredPowerSupplyVolts)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Phase"
					value={valueOrBustPrintables(props.form?.phase)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Amps:"
					value={valueOrBustPrintables(props.form?.amps)}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Measured Power Supply Under Load:"
					value={valueOrBustPrintables(props.form?.measuredPowerSupplyUnderLoad)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Measured Power Supply with No Load:"
					value={valueOrBustPrintables(props.form?.noLoad)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Minimum Free Slew Radius:"
					value={valueOrBustPrintables(props.form?.minimumFreeSlewRadius)}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<p className="printable__sub-header">
				Crane Limits
			</p>

			<PrintableSpacer size={10}/>

			<PrintableSplitSubHeaders
				titleA="Trolley In Limit"
				titleB="Trolley Out Limit"
			/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Slow:"
					value={valueOrBustPrintables(props.form?.trollyInLimitSlow)}
					width={asPercentOf100(1 / 4)}
				/>

				<PrintableCellLabelWithValue
					label="Final:"
					value={valueOrBustPrintables(props.form?.trollyInLimitFinal)}
					width={asPercentOf100(1 / 4)}
				/>

				<PrintableCellLabelWithValue
					label="Slow:"
					value={valueOrBustPrintables(props.form?.trollyOutLimitSlow)}
					width={asPercentOf100(1 / 4)}
				/>

				<PrintableCellLabelWithValue
					label="Final:"
					value={valueOrBustPrintables(props.form?.trollyOutLimitFinal)}
					width={asPercentOf100(1 / 4)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableSplitSubHeaders
				titleA="Hoist Up (1m)"
				titleB="Hoist Down"
			/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Slow:"
					value={valueOrBustPrintables(props.form?.hoistUpSlow)}
					width={asPercentOf100(1 / 4)}
				/>

				<PrintableCellLabelWithValue
					label="Final:"
					value={valueOrBustPrintables(props.form?.hoistUpFinal)}
					width={asPercentOf100(1 / 4)}
				/>

				<PrintableCellLabelWithValue
					label="Slow:"
					value={valueOrBustPrintables(props.form?.hoistDownSlow)}
					width={asPercentOf100(1 / 4)}
				/>

				<PrintableCellLabelWithValue
					label="Final:"
					value={valueOrBustPrintables(props.form?.hoistDownFinal)}
					width={asPercentOf100(1 / 4)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableSplitSubHeaders
				titleA="Travel In Limit"
				titleB="Travel Out Limit"
			/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Slow:"
					value={valueOrBustPrintables(props.form?.travelInLimitSlow)}
					width={asPercentOf100(1 / 4)}
				/>

				<PrintableCellLabelWithValue
					label="Final:"
					value={valueOrBustPrintables(props.form?.travelInLimitFinal)}
					width={asPercentOf100(1 / 4)}
				/>

				<PrintableCellLabelWithValue
					label="Slow:"
					value={valueOrBustPrintables(props.form?.travelOutLimitSlow)}
					width={asPercentOf100(1 / 4)}
				/>

				<PrintableCellLabelWithValue
					label="Final:"
					value={valueOrBustPrintables(props.form?.travelOutLimitFinal)}
					width={asPercentOf100(1 / 4)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Slewing Limit Installed:"
					value={valueOrBustPrintables(props.form?.slowingLimitInstalled, convertBooleanToString(props.form?.slowingLimitInstalled))}
					width={asPercentOf100(2 / 5)}
				/>

				<PrintableCellLabelWithValue
					label="Verify the crane cannot make more than 2 turns to each side:"
					value={valueOrBustPrintables(props.form?.verifyTheCraneCannotMakeMoreThan2TurnsToEachSide, convertBooleanToString(props.form?.verifyTheCraneCannotMakeMoreThan2TurnsToEachSide))}
					width={asPercentOf100(3 / 5)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<p className="printable__sub-header">
				Tip Limit
			</p>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Required Weight:"
					value={valueOrBustPrintables(props.form?.requiredWeightForTipLimit)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Primary Weight used:"
					value={valueOrBustPrintables(props.form?.primaryWeightUsedForTipLimit)}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Kicker Weight used:"
					value={valueOrBustPrintables(props.form?.kickerWeightUsedForTipLimit)}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<p className="printable__sub-header">
				Max Line Pull
			</p>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Required Weight:"
					value={valueOrBustPrintables(props.form?.requiredWeightForMaxLinePull)}
					width={asPercentOf100(1 / 4)}
				/>

				<PrintableCellLabelWithValue
					label="Set Percent:"
					value={valueOrBustPrintables(props.form?.setPercentOfMaxLinePull)}
					width={asPercentOf100(1 / 4)}
				/>

				<PrintableCellLabelWithValue
					label="Primary Weight:"
					value={valueOrBustPrintables(props.form?.primaryWeightUsedForMaxLinePull)}
					width={asPercentOf100(1 / 4)}
				/>

				<PrintableCellLabelWithValue
					label="Kicker Weight:"
					value={valueOrBustPrintables(props.form?.kickerWeightUsedForMaxLinePull)}
					width={asPercentOf100(1 / 4)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Is The Crane Grounded?:"
					value={valueOrBustPrintables(props.form?.isCraneGrounded, convertBooleanToString(props.form?.isCraneGrounded))}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<p className="printable__sub-header">
				Operator Controls
			</p>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Dead Man Control Operational:"
					value={valueOrBustPrintables(props.form?.deadManControlOperational, convertBooleanToString(props.form?.deadManControlOperational))}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Constant Pressure Controls:"
					value={valueOrBustPrintables(props.form?.constantPressureControls, convertBooleanToString(props.form?.constantPressureControls))}
					width={asPercentOf100(1 / 3)}
				/>

				<PrintableCellLabelWithValue
					label="Spring Loaded Controls:"
					value={valueOrBustPrintables(props.form?.springLoadedControls, convertBooleanToString(props.form?.springLoadedControls))}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Control Functions Tested/Correct:"
					value={valueOrBustPrintables(props.form?.controlFunctionsTested, convertBooleanToString(props.form?.controlFunctionsTested))}
					width={asPercentOf100(1 / 4)}
				/>

				<PrintableCellLabelWithValue
					label={`"E" Stop Functioning:`}
					value={valueOrBustPrintables(props.form?.eStopFunctioning, convertBooleanToString(props.form?.eStopFunctioning))}
					width={asPercentOf100(1 / 4)}
				/>

				<PrintableCellLabelWithValue
					label="Operator Information Display in Cab"
					value={valueOrBustPrintables(props.form?.operatorInformationDisplayInCab, convertBooleanToString(props.form?.operatorInformationDisplayInCab))}
					width={asPercentOf100(1 / 4)}
				/>

				<PrintableCellLabelWithValue
					label="Display Calibrated/Functioning"
					value={valueOrBustPrintables(props.form?.displayCalibrated, convertBooleanToString(props.form?.displayCalibrated))}
					width={asPercentOf100(1 / 4)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<p className="printable__sub-header">
				Additional Features
			</p>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Zoning System Installed:"
					value={valueOrBustPrintables(props.form?.zoningSystemInstalled, convertBooleanToString(props.form?.zoningSystemInstalled))}
					width={asPercentOf100(1 / 4)}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<p className="printable__sub-header">
				Operator Details
			</p>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Crane Operator Oriented:"
					value={valueOrBustPrintables(props.form?.craneOperatorOriented, convertBooleanToString(props.form?.craneOperatorOriented))}
					width={asPercentOf100(1 / 3)}
				/>

				{/*<PrintableCellLabelWithValue*/}
				{/*	label="Name:"*/}
				{/*	value={valueOrBustPrintables(props.form?.name)}*/}
				{/*	width={asPercentOf100(1 / 3)}*/}
				{/*/>*/}

				{/*<PrintableCellLabelWithValue*/}
				{/*	label="License #:"*/}
				{/*	value={valueOrBustPrintables(props.form?.licenseNumber)}*/}
				{/*	width={asPercentOf100(1 / 3)}*/}
				{/*/>*/}
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableTextArea
					label="Comments:"
					value={valueOrBustPrintables(props.form?.comments)}
					width={100}
				/>
			</PrintableCellRow>

			<PrintableSpacer size={10}/>

			<PrintableCellRow>
				<PrintableCellLabelWithValue
					label="Completing Technician:"
					value={valueOrBustPrintables(getFullNameForTable(props.form?.owner))}
					width={asPercentOf100(1 / 3)}
				/>
			</PrintableCellRow>
		</React.Fragment>
	);
};

export default FinalTestFormPrintable;

export function isCrane(c: Equipment): c is Crane {
	return c?.type === EquipmentType.CRANE;
}

export function isGenerator(g: Equipment): g is Generator {
	return g?.type === EquipmentType.GENERATOR;
}
