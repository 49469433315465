import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	Equipment,
	FormsApi,
	GetServiceReportListRequest,
	GetServiceReportListResponse,
	ServiceReport,
	ServiceReportSortField,
	Token,
} from "client";
import AlliedContainer from "../../components/AlliedContainer";
import AlliedPageHeader from "../../components/AlliedPageHeader";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import AlliedTableContainer from "../../components/tables/AlliedTableContainer";
import AlliedTableHeaderCell from "../../components/tables/cells/AlliedTableHeaderCell";
import {parseDateForTableDisplay} from "../../utils/parseDateForTableDisplay";
import ManageServiceReportsHistoryOptionsCell
	from "../../components/tables/cells/ManageServiceReportsHistoryOptionsCell";
import {getGenericObjectProperty} from "../../utils/getGenericObjectProperty";
import {getEquipmentTypeDisplay} from "../../utils/getEquipmentTypeDisplay";
import {cleanRequestForAPISubmission} from "../../utils/cleanRequestForAPISubmission";
import moment from "moment";
import {getFormSubmittedStatus} from "../../utils/getFormPublishedStatus";
import ManageServiceReportsFilterModal from "../../components/modals/ManageServiceReportsFilterModal";
import {getSiteName} from "../../utils/getSiteName";
import {alliedPaginationLimitOptions} from "../../components/tables/paginator/AlliedPaginatorLimitController";
import {convertOffsetDate} from "../../utils/timeZoneConversions";
import {parseServiceReportShortID} from "../../utils/parseServiceReportShortID";
import {abstractOnSortChange, getTableHeaderSortClassName} from "../../utils/abstractOnSortChange";

interface IProps {
	dispatch?: any;
	fullToken: Token;
}

export const defaultFilter: GetServiceReportListRequest = {
	limit: alliedPaginationLimitOptions[0],
	offset: 0,
	startDate: moment().subtract(13, "days").startOf("day").valueOf(),
	endDate: moment().add(7, "days").endOf("day").valueOf(),
	make: [],
	model: [],
	serialNumber: [],
	equipment: [],
	site: [],
	user: [],
}

// This is the version of the page for EMPLOYEES.

const ServiceReportsHistoryPage: React.FC<IProps> = (props) => {

	const [showFilterModal, setShowFilterModal] = useState(false);
	const [filter, setFilter] = useState<GetServiceReportListRequest>(defaultFilter);
	const [reportsResponse,  setReportsResponse] = useState<GetServiceReportListResponse>(undefined);

	useEffect(() => {
		getServiceReportsHistory().then().catch();
	}, [JSON.stringify(filter)]);

	/**
	 * Call api to get list of service reports.
	 *
	 */
	async function getServiceReportsHistory(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FormsApi(getConfig(props.fullToken)).getServiceReportList(cleanRequestForAPISubmission({
				...filter,
				startDate: convertOffsetDate(filter?.startDate),
				endDate: convertOffsetDate(filter?.endDate),
			} as GetServiceReportListRequest));
			setReportsResponse(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Toggles visibility of the filter modal.
	 *
	 */
	function toggleFilterModal(): void {
		setShowFilterModal(!showFilterModal);
	}

	/**
	 * When finished the filter modal, save the new filter (will prompt api via useEffect),
	 * and hide the filter modal.
	 *
	 * @param newFilter
	 */
	function onDoneFilterModal(newFilter: GetServiceReportListRequest): void {
		setFilter({
			...newFilter,
			offset: 0,
		});
		setShowFilterModal(false);
	}

	/**
	 * Renderer for the Service Report Options column.
	 *
	 */
	function makeOptionsCell(report: ServiceReport): ReactNode {
		return (
			<ManageServiceReportsHistoryOptionsCell
				key={"history" + report._id}
				report={report}
				onDone={getServiceReportsHistory}
			/>
		);
	}

	return (
		<React.Fragment>
			<ManageServiceReportsFilterModal
				isOpen={showFilterModal}
				filter={filter}
				onClose={toggleFilterModal}
				onDone={onDoneFilterModal}
			/>

			<AlliedContainer>
				<AlliedPageHeader
					title="Service Reports History"
					onClick={toggleFilterModal}
				/>
				<p>
					{`Showing results from ${moment(filter.startDate).format("MMM DD YYYY")} - ${moment(filter.endDate).format("MMM DD YYYY")}`}
				</p>

				<hr/>

				<AlliedTableContainer
					<GetServiceReportListRequest>
					data={reportsResponse?.serviceReports}
					showPaginator={true}
					paginationInfo={reportsResponse?.paginationInfo}
					filter={filter}
					setFilter={setFilter}
					columnOptions={[
						{
							headerValue: "Date Submitted",
							key: "submittedDate",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							rowCellClassName: "font-weight-bold",
							valueFormatter: (date: number) => {
								if (typeof date === "number") {
									return parseDateForTableDisplay(date);
								}

								return "-";
							},
							headerCellClassName: getTableHeaderSortClassName(ServiceReportSortField.submittedDate, filter),
							onSort: abstractOnSortChange<ServiceReportSortField, GetServiceReportListRequest>(ServiceReportSortField.submittedDate, filter, setFilter),
						},
						{
							headerValue: "Date",
							key: "date",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: parseDateForTableDisplay,
							headerCellClassName: getTableHeaderSortClassName(ServiceReportSortField.date, filter),
							onSort: abstractOnSortChange<ServiceReportSortField, GetServiceReportListRequest>(ServiceReportSortField.date, filter, setFilter),
						},
						{
							headerValue: "Report ID",
							key: "shortIdentifier",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: parseServiceReportShortID,
							rowCellClassName: "text-right",
							headerCellClassName: getTableHeaderSortClassName(ServiceReportSortField.shortIdentifier, filter),
							onSort: abstractOnSortChange<ServiceReportSortField, GetServiceReportListRequest>(ServiceReportSortField.shortIdentifier, filter, setFilter),
						},
						{
							headerValue: "Make",
							key: "equipment",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getGenericObjectProperty<Equipment>("make"),
						},
						{
							headerValue: "Model",
							key: "equipment",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getGenericObjectProperty<Equipment>("model"),
						},
						{
							headerValue: "S/N",
							key: "equipment",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getGenericObjectProperty<Equipment>("serialNumber"),
						},
						{
							headerValue: "Equipment",
							key: "equipment",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getGenericObjectProperty<Equipment>("type", getEquipmentTypeDisplay),
						},
						{
							headerValue: "Site Name",
							key: "originalSite",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getSiteName,
						},
						{
							headerValue: "Submitted",
							key: "submittedDate",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getFormSubmittedStatus,
							headerCellClassName: getTableHeaderSortClassName(ServiceReportSortField.submittedDate, filter),
							onSort: abstractOnSortChange<ServiceReportSortField, GetServiceReportListRequest>(ServiceReportSortField.submittedDate, filter, setFilter),
						},
						{
							headerValue: "Service Report Options",
							key: undefined,
							showSortIcons: false,
							sortable: false,
							headerRender: AlliedTableHeaderCell,
							cellRender: makeOptionsCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
					]}
				/>
			</AlliedContainer>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ServiceReportsHistoryPage);
