import React, {ReactNode} from "react";

interface IProps {
	children?: ReactNode;
}

const PrintableCellRow: React.FC<IProps> = (props) => {

	return (
		<div className="printable__cell-row">
			{props.children}
		</div>
	);
};

export default PrintableCellRow;
