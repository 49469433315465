import React, {useState} from "react";
import {TimeSheetEntry, Token} from "client";
import {Button} from "reactstrap";
import ManageTimeSheetsEntryReferenceModal from "../../modals/ManageTimeSheetsEntryReferenceModal";

interface IProps {
	entry: TimeSheetEntry;
	onDone: () => void;
}

const ManageTimeSheetsEntryReferenceCell: React.FC<IProps> = (props) => {

	const [showReferenceModal, setShowReferenceModal] = useState(false);

	/**
	 * Toggle visibility of the reference modal.
	 *
	 */
	function toggleShowReferenceModal(): void {
		setShowReferenceModal(!showReferenceModal);
	}

	/**
	 * Close the reference modal and call the props.onDone function.
	 *
	 */
	function onDone(): void {
		setShowReferenceModal(false);
		props.onDone();
	}

	return (
		<React.Fragment>
			<ManageTimeSheetsEntryReferenceModal
				isOpen={showReferenceModal}
				entry={props.entry}
				onClose={toggleShowReferenceModal}
				onDone={onDone}
			/>

			<div className="allied-table-cell">
				<Button
					color="black"
					onClick={toggleShowReferenceModal}
				>
					Edit Reference
				</Button>
			</div>
		</React.Fragment>
	);
};

export default ManageTimeSheetsEntryReferenceCell;
