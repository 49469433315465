import moment from "moment-timezone";

// use when reading values from apis
export function generateOffsetDate(date: number): number {
	if (date === undefined) {
		return undefined;
	}

	const destinationOffset: number = moment().tz("America/Vancouver").utcOffset();
	// const destinationOffset: number = moment().tz("Asia/Tokyo").utcOffset();
	const localTimezoneOffset: number = moment().tz(moment.tz.guess()).utcOffset();

	const adjusted: number = (destinationOffset - localTimezoneOffset) * 60000;

	return date + adjusted;
}

// use for SUBMISSIONS
export function convertOffsetDate(date: number | string): number {
	if (date === undefined) {
		return undefined;
	}

	const destinationOffset: number = moment().tz("America/Vancouver").utcOffset();
	// const destinationOffset: number = moment().tz("Asia/Tokyo").utcOffset();
	const localTimezoneOffset: number = moment().tz(moment.tz.guess()).utcOffset();

	const adjusted: number = (destinationOffset - localTimezoneOffset) * 60000;

	return parseInt(date.toString()) - adjusted;
}
