/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
/**
 * Check if a given object implements the CreateServiceReportEntryBody interface.
 */
export function instanceOfCreateServiceReportEntryBody(value) {
    let isInstance = true;
    isInstance = isInstance && "alliedCraneEmployeeID" in value;
    isInstance = isInstance && "lunchBreak" in value;
    return isInstance;
}
export function CreateServiceReportEntryBodyFromJSON(json) {
    return CreateServiceReportEntryBodyFromJSONTyped(json, false);
}
export function CreateServiceReportEntryBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'alliedCraneEmployeeID': json['alliedCraneEmployeeID'],
        'calledOut': !exists(json, 'calledOut') ? undefined : json['calledOut'],
        'arrivalToSite': !exists(json, 'arrivalToSite') ? undefined : json['arrivalToSite'],
        'leftJobSite': !exists(json, 'leftJobSite') ? undefined : json['leftJobSite'],
        'returnTime': !exists(json, 'returnTime') ? undefined : json['returnTime'],
        'standByTime': !exists(json, 'standByTime') ? undefined : json['standByTime'],
        'standByReason': !exists(json, 'standByReason') ? undefined : json['standByReason'],
        'sourcePartsTime': !exists(json, 'sourcePartsTime') ? undefined : json['sourcePartsTime'],
        'sourcePartsReason': !exists(json, 'sourcePartsReason') ? undefined : json['sourcePartsReason'],
        'lunchBreak': json['lunchBreak'],
        'lunchBreakStartTime': !exists(json, 'lunchBreakStartTime') ? undefined : json['lunchBreakStartTime'],
        'lunchBreakEndTime': !exists(json, 'lunchBreakEndTime') ? undefined : json['lunchBreakEndTime'],
    };
}
export function CreateServiceReportEntryBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'alliedCraneEmployeeID': value.alliedCraneEmployeeID,
        'calledOut': value.calledOut,
        'arrivalToSite': value.arrivalToSite,
        'leftJobSite': value.leftJobSite,
        'returnTime': value.returnTime,
        'standByTime': value.standByTime,
        'standByReason': value.standByReason,
        'sourcePartsTime': value.sourcePartsTime,
        'sourcePartsReason': value.sourcePartsReason,
        'lunchBreak': value.lunchBreak,
        'lunchBreakStartTime': value.lunchBreakStartTime,
        'lunchBreakEndTime': value.lunchBreakEndTime,
    };
}
