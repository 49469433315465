/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { DeficiencyStatusFromJSON, DeficiencyStatusToJSON, } from './DeficiencyStatus';
/**
 * Check if a given object implements the UpdateDeficiencyBody interface.
 */
export function instanceOfUpdateDeficiencyBody(value) {
    let isInstance = true;
    isInstance = isInstance && "formID" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "siteID" in value;
    isInstance = isInstance && "date" in value;
    return isInstance;
}
export function UpdateDeficiencyBodyFromJSON(json) {
    return UpdateDeficiencyBodyFromJSONTyped(json, false);
}
export function UpdateDeficiencyBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'formID': json['formID'],
        'status': DeficiencyStatusFromJSON(json['status']),
        'siteID': json['siteID'],
        'date': json['date'],
        'issue': !exists(json, 'issue') ? undefined : json['issue'],
    };
}
export function UpdateDeficiencyBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'formID': value.formID,
        'status': DeficiencyStatusToJSON(value.status),
        'siteID': value.siteID,
        'date': value.date,
        'issue': value.issue,
    };
}
