import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	CraneDailyLogGroup,
	Equipment,
	FormsApi,
	GeneratorDailyLogGroup,
	GeneratorDailyLogSortField,
	GetGeneratorDailyLogListRequest,
	GetGeneratorDailyLogListResponse,
	Site,
	Token,
	UserType
} from "client";
import AlliedContainer from "../../components/AlliedContainer";
import AlliedPageHeader from "../../components/AlliedPageHeader";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {cleanRequestForAPISubmission} from "../../utils/cleanRequestForAPISubmission";
import AlliedTableHeaderCell from "../../components/tables/cells/AlliedTableHeaderCell";
import {getWeekRangeForTable} from "../../utils/getWeekRangeForTable";
import {getGenericObjectProperty} from "../../utils/getGenericObjectProperty";
import AlliedTableContainer from "../../components/tables/AlliedTableContainer";
import GeneratorDailyLogsFilterModal from "../../components/modals/GeneratorDailyLogsFilterModal";
import {userTypeCheck} from "../../utils/userTypeCheck";
import GeneratorDailyLogsOperatorOptionsCell from "../../components/tables/cells/GeneratorDailyLogsOperatorOptionsCell";
import GeneratorDailyLogsManagerAndSupervisorOptionsCell
	from "../../components/tables/cells/GeneratorDailyLogsManagerAndSupervisorOptionsCell";
import {formatLastUpdatedLogDateAndFlaggedForGroupedDailyLogs} from "../../utils/formatLastUpdatedLogDateAndFlagged";
import classNames from "classnames";
import {getFormPublishedStatus} from "../../utils/getFormPublishedStatus";
import {parseDateForTableDisplay} from "../../utils/parseDateForTableDisplay";
import {getFormApprovedStatus} from "../../utils/getFormApprovedStatus";
import GeneratorDailyLogsAdminViewCell from "../../components/tables/cells/GeneratorDailyLogsAdminViewCell";
import {alliedPaginationLimitOptions} from "../../components/tables/paginator/AlliedPaginatorLimitController";
import {convertOffsetDate} from "../../utils/timeZoneConversions";
import {abstractOnSortChange, getTableHeaderSortClassName} from "../../utils/abstractOnSortChange";
import GeneratorDailyLogsAdminUnlockCell from "../../components/tables/cells/GeneratorDailyLogsAdminUnlockCell";
import DailyLogsGroupViewHistoryCell from "../../components/tables/cells/DailyLogsGroupViewHistoryCell";
import {getAllOperatorsFromGroupedDailyLogs} from "../../utils/getAllOperatorsFromGroupedDailyLogs";
import moment from "moment";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
}

const defaultFilter: GetGeneratorDailyLogListRequest = {
	limit: alliedPaginationLimitOptions[0],
	offset: 0,
	weekOf: [],
	make: [],
	model: [],
	serialNumber: [],
	site: [],
	user: [],
}

const GeneratorDailyLogsPage: React.FC<IProps> = (props) => {

	const [showFilterModal, setShowFilterModal] = useState(false);
	const [filter, setFilter] = useState<GetGeneratorDailyLogListRequest>(defaultFilter);
	const [logsResponse, setLogsResponse] = useState<GetGeneratorDailyLogListResponse>(undefined);

	useEffect(() => {
		getDailyLogHistory().then().catch();
	}, [JSON.stringify(filter)]);

	/**
	 * Call api to get the generator daily logs.
	 *
	 */
	async function getDailyLogHistory(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FormsApi(getConfig(props.fullToken)).getGeneratorDailyLogList(cleanRequestForAPISubmission({
				...filter,
				weekOfs: filter?.weekOf?.map(w => convertOffsetDate(w)),
			} as GetGeneratorDailyLogListRequest));
			setLogsResponse(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Toggles visibility of the filter modal.
	 *
	 */
	function toggleFilterModal(): void {
		setShowFilterModal(!showFilterModal);
	}

	/**
	 * When finished the filter modal, save the new filter (will prompt api via useEffect),
	 * and hide the filter modal.
	 *
	 * @param newFilter
	 */
	function onDoneFilterModal(newFilter: GetGeneratorDailyLogListRequest): void {
		setFilter({
			...newFilter,
			offset: 0,
		});
		setShowFilterModal(false);
	}

	/**
	 * Renderer of the Operator's version of the Options column.
	 *
	 * @param logs
	 */
	function makeOperatorOptionsCell(logs: GeneratorDailyLogGroup): ReactNode {
		return (
			<GeneratorDailyLogsOperatorOptionsCell
				key={"options" + logs._id}
				logs={logs}
				onDone={getDailyLogHistory}
			/>
		);
	}

	/**
	 * Renderer of the Manager & Supervisor versions of the Options column.
	 *
	 * @param logs
	 */
	function makeManagerAndSupervisorOptionsCell(logs: GeneratorDailyLogGroup): ReactNode {
		return (
			<GeneratorDailyLogsManagerAndSupervisorOptionsCell
				key={"manager-supervisor-options" + logs._id}
				logGroup={logs}
				onDone={getDailyLogHistory}
			/>
		);
	}

	/**
	 * Renderer of the Admin's version of the Options column.
	 *
	 * @param logs
	 */
	function makeAdminViewCell(logs: GeneratorDailyLogGroup): ReactNode {
		return (
			<GeneratorDailyLogsAdminViewCell
				key={"admin-view" + logs._id}
				logs={logs}
			/>
		);
	}

	function makeUnlockCell(logs: GeneratorDailyLogGroup): ReactNode {
		return (
			<GeneratorDailyLogsAdminUnlockCell
				key={"admin-unlock" + logs._id}
				logGroup={logs}
				onDone={getDailyLogHistory}
			/>
		);
	}

	/**
	 * Renderer for the view history button column.
	 *
	 * @param log
	 */
	function makeViewHistoryCell(log: GeneratorDailyLogGroup): ReactNode {
		return (
			<DailyLogsGroupViewHistoryCell
				key={"history" + log._id}
				history={log.history}
				logGroup={log}
			/>
		);
	}

	function makeDateListString(weeks: Array<number | string>): string {
		let str = "";
		weeks.forEach((w, i) => {
			if (i > 0) {
				str += ", ";
			}

			str += moment(parseInt(w.toString())).format("MMM DD YYYY");
		});

		return str;
	}

	return (
		<React.Fragment>
			<GeneratorDailyLogsFilterModal
				isOpen={showFilterModal}
				filter={filter}
				onClose={toggleFilterModal}
				onDone={onDoneFilterModal}
			/>

			<AlliedContainer>
				<AlliedPageHeader
					title="Generator Daily Log History"
					onClick={toggleFilterModal}
				/>
				{filter.weekOf && filter.weekOf.length > 0 && (
					<p>
						{`Showing results from the week(s) of ${makeDateListString(filter.weekOf)}.`}
					</p>
				)}

				<hr/>

				<AlliedTableContainer
					<GetGeneratorDailyLogListRequest>
					data={logsResponse?.generatorDailyLogGroups}
					showPaginator={true}
					paginationInfo={logsResponse?.paginationInfo}
					filter={filter}
					setFilter={setFilter}
					columnOptions={[
						{
							headerValue: "Date Submitted",
							key: "submittedDate",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							rowCellClassName: "font-weight-bold",
							valueFormatter: (date: number) => {
								if (typeof date === "number") {
									return parseDateForTableDisplay(date);
								}

								return "-";
							},
							headerCellClassName: getTableHeaderSortClassName(GeneratorDailyLogSortField.submittedDate, filter),
							onSort: abstractOnSortChange<GeneratorDailyLogSortField, GetGeneratorDailyLogListRequest>(GeneratorDailyLogSortField.submittedDate, filter, setFilter),
						},
						{
							headerValue: "Last Updated",
							key: undefined,
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							rowCellClassName: (value, cellContextDetails) => classNames("font-weight-bold", formatLastUpdatedLogDateAndFlaggedForGroupedDailyLogs(value, cellContextDetails.row as GeneratorDailyLogGroup).className),
							valueFormatter: (value, cellContextDetails) => formatLastUpdatedLogDateAndFlaggedForGroupedDailyLogs(value, cellContextDetails.row as GeneratorDailyLogGroup).value,
							headerCellClassName: getTableHeaderSortClassName(GeneratorDailyLogSortField.updatedAt, filter),
							onSort: abstractOnSortChange<GeneratorDailyLogSortField, GetGeneratorDailyLogListRequest>(GeneratorDailyLogSortField.updatedAt, filter, setFilter),
						},
						{
							headerValue: "Week",
							key: "date",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getWeekRangeForTable(1),
						},
						{
							headerValue: "Make",
							key: "equipment",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getGenericObjectProperty<Equipment>("make"),
						},
						{
							headerValue: "Model",
							key: "equipment",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getGenericObjectProperty<Equipment>("model"),
						},
						{
							headerValue: "S/N",
							key: "equipment",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getGenericObjectProperty<Equipment>("serialNumber"),
						},
						{
							headerValue: "Site Name",
							key: "originalSite",
							showSortIcons: false,
							sortable: true,
							headerRender: AlliedTableHeaderCell,
							valueFormatter: getGenericObjectProperty<Site>("name"),
						},
						(userTypeCheck([UserType.ADMIN, UserType.SECONDARY_ADMIN, UserType.SUPERVISOR, UserType.CRANE_OPERATOR, UserType.HEALTH_AND_SAFETY_OFFICER], props.fullToken) &&
							{
								headerValue: "Operator(s)",
								key: "operator",
								showSortIcons: false,
								sortable: true,
								headerRender: AlliedTableHeaderCell,
								valueFormatter: (value, cellContextDetails) => getAllOperatorsFromGroupedDailyLogs(value, cellContextDetails.row as CraneDailyLogGroup),
							}
						),
						(userTypeCheck([UserType.ADMIN], props.fullToken) &&
							{
								headerValue: "Published",
								key: "publishedDate",
								showSortIcons: false,
								sortable: true,
								headerRender: AlliedTableHeaderCell,
								valueFormatter: getFormPublishedStatus,
								headerCellClassName: getTableHeaderSortClassName(GeneratorDailyLogSortField.publishedDate, filter),
								onSort: abstractOnSortChange<GeneratorDailyLogSortField, GetGeneratorDailyLogListRequest>(GeneratorDailyLogSortField.publishedDate, filter, setFilter),
							}
						),
						(userTypeCheck([UserType.SECONDARY_ADMIN, UserType.SUPERVISOR, UserType.CRANE_OPERATOR], props.fullToken) &&
							{
								headerValue: "Approved",
								key: "approvedBy",
								showSortIcons: false,
								sortable: true,
								headerRender: AlliedTableHeaderCell,
								valueFormatter: getFormApprovedStatus,
							}
						),
						(userTypeCheck([UserType.CRANE_OPERATOR], props.fullToken) &&
							{
								headerValue: "Daily Log Options",
								key: undefined,
								showSortIcons: false,
								sortable: false,
								headerRender: AlliedTableHeaderCell,
								cellRender: makeOperatorOptionsCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							}
						),
						(userTypeCheck([UserType.SECONDARY_ADMIN, UserType.SUPERVISOR], props.fullToken) &&
							{
								headerValue: "Daily Log Options",
								key: undefined,
								showSortIcons: false,
								sortable: false,
								headerRender: AlliedTableHeaderCell,
								cellRender: makeManagerAndSupervisorOptionsCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							}
						),
						(userTypeCheck([UserType.ADMIN, UserType.HEALTH_AND_SAFETY_OFFICER], props.fullToken) &&
							{
								headerValue: "View Logs",
								key: undefined,
								showSortIcons: false,
								sortable: false,
								headerRender: AlliedTableHeaderCell,
								cellRender: makeAdminViewCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							}
						),
						(userTypeCheck([UserType.ADMIN], props.fullToken) &&
							{
								headerValue: "Unlock Logs",
								key: "unlockLogs",
								showSortIcons: false,
								sortable: false,
								headerRender: AlliedTableHeaderCell,
								cellRender: makeUnlockCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							}
						),
						{
							headerValue: "View Edit History",
							key: undefined,
							showSortIcons: false,
							sortable: false,
							headerRender: AlliedTableHeaderCell,
							cellRender: makeViewHistoryCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
					]}
				/>
			</AlliedContainer>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(GeneratorDailyLogsPage);
