import React, {ChangeEventHandler, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {ChangeUserPasswordBody, Token, UpdateUserBody, User, UsersApi} from "client";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {Button, Col, Row} from "reactstrap";
import EditUserPasswordModalSuccess from "./EditUserPasswordModalSuccess";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	user: User;
	onClose: () => void;
}

type ChangeUserPasswordBodyFrontend = Omit<ChangeUserPasswordBody, "userID">;

const defaultChangeUserPasswordBody: ChangeUserPasswordBodyFrontend = {
	password: "",
	confirmPassword: "",
};

const EditPasswordModal: React.FC<IProps> = (props) => {

	const [passwordForm, setPasswordForm] = useState<ChangeUserPasswordBodyFrontend>(defaultChangeUserPasswordBody);
	const [showSuccessModal, setShowSuccessModal] = useState(false);

	/**
	 * Reset form to its original state and
	 * call close function from props
	 *
	 */
	function resetAndClose(): void {
		setShowSuccessModal(false); // Only does anything if success modal is visible.
		setPasswordForm(defaultChangeUserPasswordBody);
		props.onClose();
	}

	/**
	 * Dynamic onChange for the form text fields.
	 *
	 * @param key
	 */
	function updatePasswordFormOnChange(key: keyof ChangeUserPasswordBodyFrontend): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setPasswordForm({
				...passwordForm,
				[key]: e?.target?.value,
			});
		}
	}

	/**
	 * Call api to submit the new password and when
	 * successful call the resetAndClose function.
	 *
	 */
	async function submitNewPassword(e?: React.SyntheticEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new UsersApi(getConfig(props.fullToken)).changeUserPassword({
				changeUserPasswordBody: {
					userID: props.user?._id,
					password: passwordForm.password,
					confirmPassword: passwordForm.confirmPassword,
				},
			});

			setShowSuccessModal(true);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<React.Fragment>
			<EditUserPasswordModalSuccess
				isOpen={showSuccessModal}
				onClose={resetAndClose}
			/>

			<AlliedModal
				isOpen={props.isOpen}
				size="lg"
			>
				<AlliedModalHeader
					title="Edit User Information"
					onClose={resetAndClose}
				/>

				<hr/>

				<form onSubmit={submitNewPassword}>
					<Row>
						<Col xs={12} lg={6} className="mb-3">
							<label>New Password</label>
							<input
								placeholder="Enter New Password..."
								value={passwordForm.password}
								onChange={updatePasswordFormOnChange("password")}
								type="password"
							/>
						</Col>

						<Col xs={12} lg={6} className="mb-3">
							<label>Confirm New Password</label>
							<input
								placeholder="Confirm New Password..."
								value={passwordForm.confirmPassword}
								onChange={updatePasswordFormOnChange("confirmPassword")}
								type="password"
							/>
						</Col>
					</Row>

					<div>
						<Button
							color="black"
							type="submit"
							onClick={submitNewPassword}
						>
							Update Password
						</Button>
					</div>
				</form>
			</AlliedModal>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(EditPasswordModal);
