import { isObject, isEmpty, cloneDeep } from "lodash";

/**
 * Remove empty values from api request objects, and empty objects from array.
 * Can be configured to include strings or not.
 *
 * Mutates your request object so use lodash cloneDeep
 *
 * @param _request
 * @param removeStrings
 */
export function cleanRequestForAPISubmission<T>(_request: any, removeStrings: boolean = false): any {

	const request = cloneDeep(_request);

	function validateValue(item: any): boolean {
		return (
			item === null ||
			item === undefined ||
			isObject(item) && isEmpty(item) ||
			item === "" && removeStrings
		);
	}

	function t(r, v) {
		if (Array.isArray(r[v]) || isObject(r[v])) {
			r[v] = cleanRequestForAPISubmission(r[v], removeStrings);
		}

		if (validateValue(r[v])) {
			delete r[v];
		}
	}

	if (Array.isArray(request)) {
		let i: number;
		for (i = 0; i < request.length; i++) {
			let arrayItem = request[i];

			arrayItem = cleanRequestForAPISubmission(arrayItem, removeStrings);

			if (
				(isObject(arrayItem) && Object.keys(arrayItem).length < 1) ||
				validateValue(arrayItem)
			) {
				request.splice(i, 1);
			}
		}
	} else if (isObject(request)) {
		for (const propName in request) {
			if (request.hasOwnProperty(propName)) {
				t(request, propName);
			}
		}
	}

	return request;
}
