import React, {ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {useParams} from "react-router";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {EquipmentApi, OperatorSignUpBody, Token, UsersApi, UserType} from "client";
import {Link, NavLink, useHistory} from "react-router-dom";
import {
	addError,
	decrementLoading,
	incrementLoading,
	login,
	logout,
	updateCurrentUser
} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {Button} from "reactstrap";

const defaultOperatorSignUpBody: OperatorSignUpBody = {
	username: "",
	password: "",
	confirmPassword: "",
	email: "",
	firstName: "",
	lastName: "",
	certificationNumber: "",
	crane: "",
	securityCode: "",
};

interface IProps {
	dispatch?: any;
	fullToken?: Token;
}

function OperatorSelfRegistration({dispatch, fullToken}: IProps): ReactElement {

	const history = useHistory();
	const {crane} = useParams<{ crane: string }>();
	const [loggedOutOnce, setLoggedOutOnce] = useState(false);
	const [form, setForm] = useState<OperatorSignUpBody>(defaultOperatorSignUpBody);

	/**
	 * If the token exists when user lands here log them out
	 *
	 */
	useEffect(() => {
		if (!loggedOutOnce) {
			setLoggedOutOnce(true);

			if ((fullToken && fullToken.token)) {
				dispatch(logout());
			}
		}
	}, [fullToken?.token, loggedOutOnce]);

	function onChangeHelper(key: keyof OperatorSignUpBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm((f) => {
				return {
					...f,
					[key]: e.target.value,
				}
			});
		}
	}

	async function submitRegistration(e?: React.SyntheticEvent): Promise<void> {
		e?.preventDefault();
		dispatch(incrementLoading());

		try {
			const res = await new UsersApi(getConfig()).craneOperatorSignUp({
				operatorSignUpBody: {
					username: form.username,
					password: form.password,
					confirmPassword: form.confirmPassword,
					email: form.email,
					firstName: form.firstName,
					lastName: form.lastName,
					certificationNumber: form.certificationNumber,
					crane,
					securityCode: form.securityCode,
				},
			});

			const loginRes  = await new UsersApi(getConfig()).login({
				loginBody: {
					username: form.username,
					password: form.password,
				},
			});

			const userRes = await new UsersApi(getConfig(loginRes)).getProfile();

			await dispatch(login(loginRes));
			await dispatch(updateCurrentUser(userRes.user));
			history.push("/sites-options/view-sites");
		} catch (e) {
			dispatch(addError(e));
		}

		dispatch(decrementLoading());
	}

	return (
		<main className="operator-reg">
			<div className="operator-reg-background-container">
				<img
					src="/images/worker-in-reflective-vest-and-hardhat-standing-on--XCK4WA6.png"
					alt="working background"
				/>
			</div>

			<div className="operator-reg-content">
				<div/>

				<div className="operator-reg-content__inner">
					<div className="operator-reg-content__inner__shadow">
						<div className="operator-reg-content__inner__shadow__image-container">
							<img src="/logos/allied-logo.svg"/>
						</div>

						<div className="operator-reg-content__inner__shadow__form-container">
							<div/>

							<form
								className="operator-reg-content__inner__shadow__form-container__form"
								onSubmit={submitRegistration}
							>
								<div className="operator-reg-content__inner__shadow__form-container__form__inner">
									<div className="d-flex justify-content-end mb-4">
										<NavLink to={`/operator-login/${crane}`} className="text-white text-right">
											Already Have An Account?
										</NavLink>
									</div>

									<div className="operator-reg-content__inner__shadow__form-container__form__inner__title">
										<h3>Operator Sign-Up</h3>
									</div>

									<div>
										<label className="text-white">First Name</label>
										<input
											className="mb-3 border-0"
											placeholder="Enter First Name..."
											value={form?.firstName}
											onChange={onChangeHelper("firstName")}
										/>
									</div>

									<div>
										<label className="text-white">Last Name</label>
										<input
											className="mb-3 border-0"
											placeholder="Enter Last Name..."
											value={form?.lastName}
											onChange={onChangeHelper("lastName")}
										/>
									</div>

									<div>
										<label className="text-white">Certification Number</label>
										<input
											className="mb-3 border-0"
											placeholder="Enter Certification Number..."
											value={form?.certificationNumber}
											onChange={onChangeHelper("certificationNumber")}
										/>
									</div>

									<div>
										<label className="text-white">Username</label>
										<input
											className="mb-3 border-0"
											placeholder="Enter Username..."
											value={form?.username}
											onChange={onChangeHelper("username")}
										/>
									</div>

									<div>
										<label className="text-white">Email</label>
										<input
											className="mb-3 border-0"
											placeholder="Enter Email..."
											value={form?.email}
											onChange={onChangeHelper("email")}
										/>
									</div>

									<div>
										<label className="text-white">Password</label>
										<input
											className="mb-3 border-0"
											placeholder="Enter Password..."
											value={form?.password}
											onChange={onChangeHelper("password")}
											type="password"
										/>
									</div>

									<div>
										<label className="text-white">Confirm Password</label>
										<input
											className="mb-3 border-0"
											placeholder="Confirm Password..."
											value={form?.confirmPassword}
											onChange={onChangeHelper("confirmPassword")}
											type="password"
										/>
									</div>

									<div>
										<label className="text-white">Access Code (Underneath QR Code)</label>
										<input
											className="mb-3 border-0"
											placeholder="Enter Access Code..."
											value={form?.securityCode}
											onChange={onChangeHelper("securityCode")}
										/>
									</div>

									<div className="d-flex justify-content-end">
										<Button
											type="submit"
											color="black"
										>
											Create Account
										</Button>
									</div>
								</div>
							</form>

							<div className="operator-reg-content__inner__shadow__form-container__version-label">
								<p>
									{process.env.REACT_APP_VERSION}
								</p>
							</div>
						</div>
					</div>
				</div>

				<div/>
			</div>
		</main>
	);
}

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore?.fullToken,
		...props,
	}
})(OperatorSelfRegistration);