/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
/**
 * Check if a given object implements the UpdateUserBody interface.
 */
export function instanceOfUpdateUserBody(value) {
    let isInstance = true;
    isInstance = isInstance && "userID" in value;
    return isInstance;
}
export function UpdateUserBodyFromJSON(json) {
    return UpdateUserBodyFromJSONTyped(json, false);
}
export function UpdateUserBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userID': json['userID'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'certificationNumber': !exists(json, 'certificationNumber') ? undefined : json['certificationNumber'],
    };
}
export function UpdateUserBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userID': value.userID,
        'username': value.username,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'certificationNumber': value.certificationNumber,
    };
}
