import {CreateCraneWeeklyLogBody} from "client";

/**
 * Util for getting a list of the keys of the fields that
 * are of type InspectionStatus on CreateCraneWeeklyLogBody.
 *
 */
export function getCraneWeeklyLogInspectionItems(): Array<keyof CreateCraneWeeklyLogBody> {
	return [
		"visualInspectionForOfSlewRing",
		"visualInspectionOfTrolleyRollers",
		"visualInspectionOfSheaves",
		"jibBackstops",
		"boomHoistBrake",
		"checkWireRopeEnds",
		"inspectLoadLine",
		"visualInspectionOfTieIns",
		"ensureMachineIsProperlyLubricated",
		"visualInspectionOfAllDriveComponents",
		"visualInspectionOfCounterweightSupports",
		"visualInspectionOfAnchorBolts",
		"visualInspectionOfTowerBolts",
		"ensureTrackIsLevel",
		"supervisorNotifiedOfDefects",
	];
}
