/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { SiteFromJSON, SiteToJSON, } from './Site';
import { UserTypeFromJSON, UserTypeToJSON, } from './UserType';
import { AdminFromJSONTyped, AlliedCraneEmployeeFromJSONTyped, CraneOperatorFromJSONTyped, HealthAndSafetyOfficerFromJSONTyped, SecondaryAdminFromJSONTyped, SupervisorFromJSONTyped } from './';
/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value) {
    let isInstance = true;
    isInstance = isInstance && "_id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "disabled" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    return isInstance;
}
export function UserFromJSON(json) {
    return UserFromJSONTyped(json, false);
}
export function UserFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['type'] === 'ADMIN') {
            return AdminFromJSONTyped(json, true);
        }
        if (json['type'] === 'ALLIED_CRANE_EMPLOYEE') {
            return AlliedCraneEmployeeFromJSONTyped(json, true);
        }
        if (json['type'] === 'CRANE_OPERATOR') {
            return CraneOperatorFromJSONTyped(json, true);
        }
        if (json['type'] === 'HEALTH_AND_SAFETY_OFFICER') {
            return HealthAndSafetyOfficerFromJSONTyped(json, true);
        }
        if (json['type'] === 'SECONDARY_ADMIN') {
            return SecondaryAdminFromJSONTyped(json, true);
        }
        if (json['type'] === 'SUPERVISOR') {
            return SupervisorFromJSONTyped(json, true);
        }
    }
    return {
        '_id': json['_id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'disabled': json['disabled'],
        'type': UserTypeFromJSON(json['type']),
        'username': json['username'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'sites': !exists(json, 'sites') ? undefined : (json['sites'].map(SiteFromJSON)),
    };
}
export function UserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'disabled': value.disabled,
        'type': UserTypeToJSON(value.type),
        'username': value.username,
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'sites': value.sites === undefined ? undefined : (value.sites.map(SiteToJSON)),
    };
}
