import React, {ReactElement, useState} from "react";
import AlliedTextButton from "../../AlliedTextButton";
import {Site, User, UsersByType} from "client";
import ManageUsersSiteListModal from "../../modals/ManageUsersSiteListModal";

interface IProps {
	sites: Array<Site>;
	user: User;
}

function ManageUsersSiteListCell({sites, user}: IProps): ReactElement {

	const [showSitesModal, setShowSitesModal] = useState(false);

	function toggleSitesModal(): void {
		setShowSitesModal(s => !s);
	}

	return (
		<React.Fragment>
			<ManageUsersSiteListModal
				isOpen={showSitesModal}
				sites={sites}
				user={user}
				onClose={toggleSitesModal}
			/>

			<div className="d-flex justify-content-end">
				{sites?.length > 0 ? (
					<AlliedTextButton onClick={toggleSitesModal}>
						{`${sites?.length} (view)`}
					</AlliedTextButton>
				) : (
					<span>No Sites.</span>
				)}
			</div>
		</React.Fragment>
	);
}

export default ManageUsersSiteListCell;