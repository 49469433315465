/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the AlliedCraneEmployee interface.
 */
export function instanceOfAlliedCraneEmployee(value) {
    let isInstance = true;
    return isInstance;
}
export function AlliedCraneEmployeeFromJSON(json) {
    return AlliedCraneEmployeeFromJSONTyped(json, false);
}
export function AlliedCraneEmployeeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function AlliedCraneEmployeeToJSON(value) {
    return value;
}
