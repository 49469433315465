import React from "react";
import {Button} from "reactstrap";
import {GeneratorDailyLogGroup} from "client";
import ViewGeneratorDailyLogButton from "../../printables/buttons/ViewGeneratorDailyLogButton";

interface IProps {
	logs: GeneratorDailyLogGroup;
}

const GeneratorDailyLogsAdminViewCell: React.FC<IProps> = (props) => {

	return (
		<div className="d-flex justify-content-center">
			<ViewGeneratorDailyLogButton groupedGeneratorLogs={props.logs}>
				View Logs
			</ViewGeneratorDailyLogButton>
		</div>
	);
};

export default GeneratorDailyLogsAdminViewCell;
