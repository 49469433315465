import {UserType} from "client";

export function getTokenTypeDisplay(tokenType: UserType): string {
	switch(tokenType) {
		case UserType.ADMIN:
			return "Admin";
		case UserType.ALLIED_CRANE_EMPLOYEE:
			return "Allied Crane Employee";
		case UserType.CRANE_OPERATOR:
			return "Operator";
		case UserType.HEALTH_AND_SAFETY_OFFICER:
			return "Health & Safety";
		case UserType.SECONDARY_ADMIN:
			return "Manager";
		case UserType.SUPERVISOR:
			return "Supervisor";
		default:
			return UserType[tokenType];
	}
}
