import React from "react";
import CreateCraneDailyLogForm from "../../components/forms/CreateCraneDailyLogForm";

const CreateCraneDailyLogPage: React.FC = () => {

	return (
		<CreateCraneDailyLogForm/>
	);
};

export default CreateCraneDailyLogPage;
