import React, {useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {CraneWeeklyLog, FormsApi, Token} from "client";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {useHistory} from "react-router-dom";
import getConfig from "../../utils/getConfig";
import ViewCraneWeeklyLogButton from "../printables/buttons/ViewCraneWeeklyLogButton";
import CraneWeeklyLogConfirmDeleteDraftModalForOperator from "./CraneWeeklyLogConfirmDeleteDraftModalForOperator";

interface IProps {
    dispatch?: any;
    fullToken?: Token;
    isOpen: boolean;
    log: CraneWeeklyLog;
    onClose: () => void;
    onDone: () => void;
}

const CraneWeeklyLogsManagerAndSupervisorOptionsModal: React.FC<IProps> = (props) => {

    const history = useHistory();
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    /**
     * Send user to the edit page for the Crane Weekly Logs in question.
     *
     */
    function onEditCraneWeeklyLogs(): void {
        history.push(`/edit-forms/edit-crane-weekly-log?id=${props.log?._id}`);
    }

    function toggleConfirmDeleteModal(): void {
        setShowDeleteModal(!showDeleteModal);
    }

    function onConfirmDeleteLog(): void {
        setShowDeleteModal(false);
        props.onDone();
    }

    /**
     * Call api to approve the Crane Weekly Logs,
     * and call onDone function from props after to update table.
     *
     */
    async function onApproveCraneWeeklyLogs(): Promise<void> {
        props.dispatch(incrementLoading());

        try {
            await new FormsApi(getConfig(props.fullToken)).publishCraneWeeklyLog({
                iDBody: {
                    id: props.log?._id,
                },
            });
            props.onDone();
        } catch (e) {
            props.dispatch(addError(e));
        }

        props.dispatch(decrementLoading());
    }

    return (
        <React.Fragment>
            <CraneWeeklyLogConfirmDeleteDraftModalForOperator
                isOpen={showDeleteModal}
                log={props.log}
                onClose={toggleConfirmDeleteModal}
                onDone={onConfirmDeleteLog}
            />

            <AlliedModal
                isOpen={props.isOpen}
                size="md"
            >
                <AlliedModalHeader
                    title="Crane Weekly Log Options"
                    onClose={props.onClose}
                />

                <hr/>

                <p>
                    *Note that you can only edit the form <b>before</b> it's approved.
                </p>

                <div className="d-flex flex-row align-items-start flex-wrap">
                    <ViewCraneWeeklyLogButton
                        id={props.log?._id}
                        className="mr-3 mb-3"
                    >
                        View Weekly Log
                    </ViewCraneWeeklyLogButton>

                    <Button
                        color="black"
                        onClick={onEditCraneWeeklyLogs}
                        className="mb-3"
                        disabled={props.log?.approvedBy !== undefined}
                    >
                        Edit Weekly Log
                    </Button>

                    <Button
                        color="black"
                        className="mr-3 mb-3"
                        onClick={toggleConfirmDeleteModal}
                        disabled={props.log?.submittedDate !== undefined}
                    >
                        Delete Draft
                    </Button>
                </div>

                <hr className="mt-0"/>

                <div>
                    <Button
                        color="red"
                        onClick={onApproveCraneWeeklyLogs}
                        disabled={props.log?.approvedBy !== undefined}
                    >
                        Approve Weekly Log
                    </Button>
                </div>
            </AlliedModal>
        </React.Fragment>
    );
};

export default connect((store: IStore, props: IProps) => {
    return {
        fullToken: store.metaStore.fullToken,
        ...props,
    }
})(CraneWeeklyLogsManagerAndSupervisorOptionsModal);

