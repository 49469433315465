import moment from "moment";

/**
 * Format date from apis to h:mm A
 *
 * @param date
 */
export function parseTimeForTableDisplay(date: number): string {
	return date !== undefined ? moment(date).format("h:mm A") : "-";
}
