import {AddTimeSheetEntryBody, FormsApi, GetUserListResponse, TimeSheet, Token, UsersApi, UserType} from "client";
import React, {ChangeEventHandler, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import moment, {Moment} from "moment";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import AlliedContainer from "../AlliedContainer";
import AlliedPageHeader from "../AlliedPageHeader";
import AlliedFormContainer from "../AlliedFormContainer";
import {Button, Col, Input, Row} from "reactstrap";
import AlliedDatePicker from "../inputs/AlliedDatePicker";
import {standardTimePickerProps} from "../inputs/LunchBreakInput";
import NumberFormat from "react-number-format";
import AlliedRadioRow from "../inputs/AlliedRadioRow";
import {AlliedInputRadioFactory} from "../inputs/AlliedInputRadio";
import {roundMomentObjectMinutes} from "../../utils/roundMomentObjectMinutes";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {convertOffsetDate} from "../../utils/timeZoneConversions";
import {humanReadableDuration} from "../../utils/convertMillisecondsToHours";
import {getFullNameForTable} from "../../utils/getFullNameForTable";
import {getWeekRangeForTable} from "../../utils/getWeekRangeForTable";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	timeSheetId: string;
}

const defaultCreateTimeSheetForm: AddTimeSheetEntryBody = {
	isSickDay: false,
	timeIn: roundMomentObjectMinutes().valueOf(),
	timeOut: roundMomentObjectMinutes().valueOf(),
	jobDescription: "",
	lunchBreak: true,
	driveTime: undefined,
};

/* Form for Admins to create time sheets on behalf of Employees, only usable for time sheets that are already "submitted" (not to be confused with "published") */

const AdminCreateTimeSheetForUserForm: React.FC<IProps> = (props) => {

	const history = useHistory();
	const [timeSheet, setTimeSheet] = useState<TimeSheet>(undefined);
	const [date, setDate] = useState<number>(moment().startOf("day").valueOf());
	const [createTimeSheetForm, setCreateTimeSheetForm] = useState<AddTimeSheetEntryBody>(defaultCreateTimeSheetForm);

	useEffect(() => {
		getExistingTimeSheet().then().catch();
	}, []);

	async function getExistingTimeSheet(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FormsApi(getConfig(props.fullToken)).getTimeSheet({
				timeSheetID: props.timeSheetId,
			});
			setTimeSheet(res);
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	function setSelectedEmployee(users: string[]): void {
		setCreateTimeSheetForm({
			...createTimeSheetForm,
			userID: createTimeSheetForm.userID !== users[0] ? users[0] : "",
		});
	}

	/**
	 * Track the value of the date input,
	 * which is used to calculate real values for the time in & time out upon submission.
	 *
	 * @param d
	 */
	function onDateChange(d: Date): void {
		setDate(moment(d).valueOf());
	}

	/**
	 * onChange for the date inputs.
	 *
	 * @param key
	 */
	function onTimeChange(key: keyof AddTimeSheetEntryBody): (d: Date) => void {
		return (d: Date) => {
			setCreateTimeSheetForm({
				...createTimeSheetForm,
				[key]: moment(d).valueOf(),
			});
		}
	}

	/**
	 * Dynamic onChange for the form text fields.
	 *
	 * @param key
	 */
	function createServiceReportOnChange(key: keyof AddTimeSheetEntryBody): ChangeEventHandler<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> {
		return (e) => {
			setCreateTimeSheetForm({
				...createTimeSheetForm,
				[key]: e?.target?.value,
			});
		}
	}

	/**
	 * Dynamic onChange for number inputs using the NumberFormat library.
	 *
	 * @param key
	 */
	function numberFormatOnChange(key: keyof AddTimeSheetEntryBody): (e) => void {
		return (e) => {
			setCreateTimeSheetForm({
				...createTimeSheetForm,
				[key]: e?.floatValue,
			});
		}
	}

	/**
	 * onChange function for the "Lunch Break" radios.
	 *
	 * @param lunchBreak
	 */
	function lunchBreakOnChange(lunchBreak: boolean): () => void {
		return () => {
			setCreateTimeSheetForm({
				...createTimeSheetForm,
				lunchBreak: lunchBreak,
			});
		}
	}

	/**
	 * onChange function for the "Sick Day" radios.
	 *
	 * @param isSickDay
	 */
	function isSickDayOnChange(isSickDay: boolean): () => void {
		return () => {
			setCreateTimeSheetForm({
				...createTimeSheetForm,
				isSickDay: isSickDay,
			});
		}
	}

	/**
	 * Call api to either submit new time sheet or update existing and return id if saving.
	 *
	 */
	async function saveTimeSheet(e?: React.SyntheticEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			// Clean the request and composite the temporary date input with the timeIn & timeOut inputs to get times on the correct date for submission.
			const timeInMoment: Moment = moment(createTimeSheetForm.timeIn);
			const timeOutMoment: Moment = moment(createTimeSheetForm.timeOut);

			const cleanRequest: AddTimeSheetEntryBody = {
				userID: timeSheet?.owner?._id,
				timeIn: createTimeSheetForm.isSickDay ? convertOffsetDate(moment(date).startOf("day").valueOf()) : convertOffsetDate(moment(date).set({
					hours: timeInMoment.hours(),
					minutes: timeInMoment.minutes(),
				}).valueOf()),
				timeOut: createTimeSheetForm.isSickDay ? undefined : convertOffsetDate(moment(date).set({
					hours: timeOutMoment.hours(),
					minutes: timeOutMoment.minutes(),
				}).valueOf()),
				jobDescription: createTimeSheetForm.jobDescription ? createTimeSheetForm.jobDescription : undefined,
				lunchBreak: createTimeSheetForm.isSickDay ? undefined : (createTimeSheetForm.isSickDay ? undefined : (createTimeSheetForm.lunchBreak !== undefined ? createTimeSheetForm.lunchBreak : undefined)),
				driveTime: createTimeSheetForm.driveTime !== undefined ? createTimeSheetForm.driveTime : undefined,
				isSickDay: createTimeSheetForm.isSickDay,
			};
			//
			// if (props.timeSheetId) {
			// 	await new FormsApi(getConfig(props.fullToken)).updateTimeSheetEntry({
			// 		updateTimeSheetEntryBody: {
			// 			...cleanRequest,
			// 			timeSheetEntryID: props.id
			// 		},
			// 	});
			// } else {
			// 	await new FormsApi(getConfig(props.fullToken)).addTimeSheetEntry({
			// 		addTimeSheetEntryBody: cleanRequest,
			// 	});
			// }

			await new FormsApi(getConfig(props.fullToken)).adminAddTimeSheetEntryForUser({
				adminAddTimeSheetEntryToUserTimeSheet: {
					timeSheetId: timeSheet._id,
					timeSheetEntryBody: cleanRequest,
				},
			});
		} catch (e) {
			props.dispatch(decrementLoading());
			props.dispatch(addError(e));
			throw e;
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Used when creating or updating to save the draft.
	 * Afterwards, navigate to the appropriate page based on usertype.
	 *
	 */
	async function saveDraft(e?: React.SyntheticEvent): Promise<void> {
		e?.preventDefault();
		console.log("test:", createTimeSheetForm);
		try {
			await saveTimeSheet();
			history.push("/allied-crane-employee-forms/time-sheets");
		} catch (e) {
		}
	}

	return (
		<AlliedContainer>
			<AlliedPageHeader
				title="Create Time Sheet Entry for User"
				showTooltip={false}
			/>

			<hr/>

			<AlliedFormContainer>
				<form>
					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Employee</label>
							<Input
								value={getFullNameForTable(timeSheet?.owner)}
								placeholder="Employee..."
								disabled={true}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Date</label>
							<AlliedDatePicker
								selected={date}
								onChange={onDateChange}
								minDate={moment(timeSheet?.weekOf).toDate()}
								maxDate={moment(timeSheet?.weekOf).add(6, "days").toDate()}
								// excludeDates={timeSheet?.entries?.map((entry, i) => {
								// 	return moment(entry.timeIn).startOf("day").toDate();
								// })}
								// disabled={props.id !== undefined}
							/>
							Week of: {getWeekRangeForTable(1, "MMM DD")(timeSheet?.weekOf)}
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={6} className="mb-3">
							<label>Job Description</label>
							<textarea
								placeholder="Enter Job Description..."
								value={createTimeSheetForm.jobDescription}
								onChange={createServiceReportOnChange("jobDescription")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Was this a sick or zero-hour day?</label>
							<AlliedRadioRow>
								<AlliedInputRadioFactory
									radios={[
										{
											label: "Yes",
											name: "Yes Sick Day",
											checked: createTimeSheetForm.isSickDay === true,
											onChange: isSickDayOnChange(true),
										},
										{
											label: "No",
											name: "No Sick Day",
											checked: createTimeSheetForm.isSickDay === false,
											onChange: isSickDayOnChange(false),
										}
									]}
								/>
							</AlliedRadioRow>
						</Col>
					</Row>

					{!createTimeSheetForm.isSickDay && (
						<React.Fragment>
							<Row>
								<Col xs={12} md={4} className="mb-3">
									<label>Time In</label>
									<AlliedDatePicker
										{...standardTimePickerProps}
										selected={createTimeSheetForm.timeIn}
										onChange={onTimeChange("timeIn")}
									/>
								</Col>
							</Row>

							<Row>
								<Col xs={12} md={4} className="mb-3">
									<label>Lunch Break</label>
									<AlliedRadioRow>
										<AlliedInputRadioFactory
											radios={[
												{
													label: "Yes",
													name: "Yes Lunch Break",
													checked: createTimeSheetForm.lunchBreak === true,
													onChange: lunchBreakOnChange(true),
												},
												{
													label: "No",
													name: "No Lunch Break",
													checked: createTimeSheetForm.lunchBreak === false,
													onChange: lunchBreakOnChange(false),
												}
											]}
										/>
									</AlliedRadioRow>
								</Col>
							</Row>

							<Row>
								<Col xs={12} md={4} className="mb-3">
									<label>Drive Time</label>
									<NumberFormat
										allowLeadingZeros={false}
										placeholder="Drive Time..."
										value={createTimeSheetForm.driveTime}
										onValueChange={numberFormatOnChange("driveTime")}
										decimalScale={2}
										allowNegative={false}
									/>
								</Col>
							</Row>

							<Row>
								<Col xs={12} md={4} className="mb-3">
									<label>Time Out</label>
									<AlliedDatePicker
										{...standardTimePickerProps}
										selected={createTimeSheetForm.timeOut}
										onChange={onTimeChange("timeOut")}
									/>
								</Col>
							</Row>

							<Row>
								<Col xs={12} md={4} className="mb-3">
									<label>Hours</label>
									<p>
										{humanReadableDuration(createTimeSheetForm.timeOut - createTimeSheetForm.timeIn)}
									</p>
								</Col>
							</Row>
						</React.Fragment>
					)}

					<div className="d-flex flex-column align-items-center flex-sm-row">
						<Button
							color="black"
							type="submit"
							onClick={saveDraft}
							className="mr-0 mr-sm-3 mb-3 mb-sm-0"
						>
							Save
						</Button>
					</div>
				</form>
			</AlliedFormContainer>
		</AlliedContainer>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(AdminCreateTimeSheetForUserForm);
