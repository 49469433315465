import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Token} from "client";
import SideBarNavigationLinkGroup from "./SideBarNavigationLinkGroup";
import {getUserTypeNavLinks, INavLinkGroup} from "../../utils/getUserTypeNavLinks";
import {logout, toggleSideBar} from "../../redux/meta/MetaActions";
import {useHistory} from "react-router-dom";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	sideBarVisible?: boolean;
}

const SideBarNavigationLinkGenerator: React.FC<IProps> = (props) => {

	const history = useHistory();

	/**
	 * Dispatch redux logout function,
	 * close sidebar if open on mobile,
	 * navigate to default route (login page).
	 *
	 * @param e
	 */
	function onLogout(e: React.MouseEvent<HTMLElement>): void {
		e?.preventDefault();

		if (props.sideBarVisible) {
			props.dispatch(toggleSideBar(false));
		}

		props.dispatch(logout());
		history.push("/");
	}

	/**
	 * Map the array of INavLinkGroup into their own components.
	 * Done this way due to allow each component to handle its own collapsable state easily.
	 *
	 * @param linkGroup
	 * @param i
	 */
	function generateLinks(linkGroup: INavLinkGroup, i: number): ReactNode {
		return (
			<SideBarNavigationLinkGroup
				key={`nav-bar-link-group-${i}`}
				// @ts-ignore
				title={linkGroup.title}
				entries={linkGroup.entries}
			/>
		);
	}

	return (
		<React.Fragment>
			{props.fullToken && (
				<React.Fragment>
					{getUserTypeNavLinks(props.fullToken?.type).map(generateLinks)}

					<a
						href="#"
						onClick={onLogout}
					>
						<h5 className="nav-bar-h5">
							Log Out
						</h5>
					</a>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore?.fullToken,
		sideBarVisible: store.metaStore.sidebarVisible,
		...props,
	}
})(SideBarNavigationLinkGenerator)
