import React, {ChangeEventHandler, useEffect, useState} from "react";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button, Col, Row} from "reactstrap";
import {Token, UpdateUserBody, User, UsersApi, UserType} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {isCraneOperator} from "../../utils/userTypeGaurds";
import EditPasswordModal from "./EditPasswordModal";
import {userTypeCheck} from "../../utils/userTypeCheck";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	user: User;
	onClose: () => void;
	onDone: () => void;
}

const ManageUsersEditModal: React.FC<IProps> = (props) => {

	const [showPasswordModal, setShowPasswordModal] = useState(false);
	const [updateUserForm, setUpdateUserForm] = useState<Partial<UpdateUserBody>>(convertUserToUpdateBody(props.user));

	function toggleShowPasswordModal(): void {
		setShowPasswordModal(!showPasswordModal);
	}

	/**
	 * Util to convert the user from props to
	 * the interface of UpdateUserBody for editing.
	 *
	 * @param _user
	 */
	function convertUserToUpdateBody(_user: User): Partial<UpdateUserBody> {
		return {
			firstName: _user.firstName,
			lastName: _user.lastName,
			email: _user.email,
			username: _user.username,
			certificationNumber: isCraneOperator(_user) ? _user.certificationNumber : undefined,
		}
	}

	useEffect(() => {
		if (props.isOpen) {
			setUpdateUserForm(convertUserToUpdateBody(props.user));
		}
	}, [props.isOpen]);

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function resetAndClose(): void {
		props.onClose();
		setUpdateUserForm(convertUserToUpdateBody(props.user));
	}

	/**
	 * Dynamic onChange for the form text fields.
	 *
	 * @param key
	 */
	function updateUserOnChange(key: keyof UpdateUserBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setUpdateUserForm({
				...updateUserForm,
				[key]: e?.target?.value,
			});
		}
	}

	/**
	 * Call api to update the user being edited, close modal when done,
	 * and the page should fetch new data for the table.
	 *
	 */
	async function updateUser(e?: React.SyntheticEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new UsersApi(getConfig(props.fullToken)).updateUser({
				updateUserBody: {
					userID: props.user._id,
					firstName: updateUserForm.firstName,
					lastName: updateUserForm.lastName,
					email: updateUserForm.email,
					username: updateUserForm.username,
					certificationNumber: updateUserForm.certificationNumber,
				},
			});

			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}


	return (
		<React.Fragment>
			<EditPasswordModal
				isOpen={showPasswordModal}
				user={props.user}
				onClose={toggleShowPasswordModal}
			/>

			<AlliedModal
				isOpen={props.isOpen}
				size="lg"
			>
				<AlliedModalHeader
					title="Edit User Information"
					onClose={resetAndClose}
				/>

				<hr/>

				<React.Fragment>
					<div className="d-flex justify-content-start">
						<Button
							color="red"
							onClick={toggleShowPasswordModal}
						>
							Edit User Password
						</Button>
					</div>

					<hr/>
				</React.Fragment>

				<form onSubmit={updateUser}>
					<Row>
						<Col xs={12} lg={6} className="mb-3">
							<label>First Name</label>
							<input
								placeholder="Enter First Name..."
								value={updateUserForm.firstName}
								onChange={updateUserOnChange("firstName")}
							/>
						</Col>

						<Col xs={12} lg={6} className="mb-3">
							<label>Last Name</label>
							<input
								placeholder="Enter Last Name..."
								value={updateUserForm.lastName}
								onChange={updateUserOnChange("lastName")}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} lg={6} className="mb-3">
							<label>Username</label>
							<input
								placeholder="Enter Username..."
								value={updateUserForm.username}
								onChange={updateUserOnChange("username")}
							/>
						</Col>

						<Col xs={12} lg={6} className="mb-3">
							<label>Email Address</label>
							<input
								placeholder="Enter Email Address..."
								value={updateUserForm.email}
								onChange={updateUserOnChange("email")}
							/>
						</Col>
					</Row>

					{isCraneOperator(props.user) && (
						<Row>
							<Col xs={12} lg={6} className="mb-3">
								<label>Certification Number</label>
								<input
									placeholder="Enter Certification Number..."
									value={updateUserForm.certificationNumber}
									onChange={updateUserOnChange("certificationNumber")}
								/>
							</Col>
						</Row>
					)}

					<div>
						<Button
							color="black"
							type="submit"
							onClick={updateUser}
						>
							Update User
						</Button>
					</div>
				</form>
			</AlliedModal>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageUsersEditModal);
