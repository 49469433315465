import React, {useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import {Button} from "reactstrap";
import {Equipment} from "client";
import ManageSitesEditEquipmentModal from "../../modals/ManageSitesEditEquipmentModal";

interface IProps {
	equipment: Equipment,
	onDone: () => Promise<void>;
}

const ManageSitesEditEquipmentCell: React.FC<IProps> = (props) => {

	const [showEdit, setShowEdit] = useState(false);

	/**
	 * Toggles visibility of the edit equipment modal.
	 *
	 */
	function toggleShowEditModal(): void {
		setShowEdit(!showEdit);
	}

	/**
	 * Close modal and call done function from props.
	 *
	 */
	function onDone(): void {
		setShowEdit(false);
		props.onDone().then().catch();
	}

	return (
		<div className="d-flex justify-content-center">
			<ManageSitesEditEquipmentModal
				isOpen={showEdit}
				equipment={props.equipment}
				onClose={toggleShowEditModal}
				onDone={onDone}
			/>

			<Button
				color="black"
				onClick={toggleShowEditModal}
			>
				Edit Equipment
			</Button>
		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageSitesEditEquipmentCell);
