import {
	Crane,
	CraneDailyLog,
	CraneDailyLogGroup, CraneOperator,
	CreateGeneratorDailyLogBody,
	CreateLogCommentBody,
	EquipmentApi,
	EquipmentType,
	FormsApi,
	FormType,
	Generator, GeneratorDailyLog, GeneratorDailyLogGroup,
	InspectionStatus, Site, SitesApi,
	Token, UpdateGeneratorDailyLogBody,
	User,
	UsersApi, UserType,
} from "client";
import moment from "moment";
import React, {ChangeEventHandler, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {useHistory} from "react-router-dom";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {cloneDeep, find} from "lodash";
import {getFullNameForTable} from "../../utils/getFullNameForTable";
import AlliedContainer from "../AlliedContainer";
import AlliedPageHeader from "../AlliedPageHeader";
import AlliedFormContainer from "../AlliedFormContainer";
import {Button, Col, Row} from "reactstrap";
import AlliedAutoComplete from "../inputs/AlliedAutoComplete";
import {parseEquipmentForSelectDropdown} from "../../utils/parseEquipmentForSelectDropdown";
import AlliedDatePicker from "../inputs/AlliedDatePicker";
import AlliedSelectInputList from "../inputs/inputList/AlliedSelectInputList";
import {convertGeneratorInspectionItemsToInputListItems} from "../../utils/convertGeneratorInspectionItemToInputListItem";
import NumberFormat from "react-number-format";
import AlliedGenericInputList from "../inputs/AlliedGenericInputList";
import DailyLogCommentInput from "../inputs/DailyLogCommentInput";
import {parseSitesForAutoComplete} from "../../utils/parseSitesForAutoComplete";
import {convertGeneratorDailyLogToUpdateBody} from "../../utils/convertGeneratorDailyLogToCreateBody";
import {convertOffsetDate} from "../../utils/timeZoneConversions";
import {isCrane, isGenerator} from "../printables/forms/FinalTestFormPrintable";
import {defaultCreateGeneratorDailyLogForm} from "./CreateGeneratorDailyLogForm";
import {getCraneTypeDisplay} from "../../utils/getCraneTypeDisplay";
import {parseUsersForAutoComplete} from "../../utils/parseUsersForAutoComplete";

export const defaultUpdateGeneratorDailyLogForm: UpdateGeneratorDailyLogBody = {
	initials: false,
	day: 0,
	hours: undefined,
	hourMeterStart: undefined,
	hourMeterEnd: undefined,
	hourMeterTotal: undefined,
	createLogCommentBodies: [{
		flagged: false,
		comment: "",
	}],
	checkOilLevel: undefined,
	checkCoolantLevels: undefined,
	visualInspectionForWear: undefined,
	checkForLeakage: undefined,
	ensureDoorPanelsAndCoversAreClosed: undefined,
}

const validLogDays: Array<string> = ["0", "1", "2", "3", "4", "5", "6"];

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	id?: string;
	day?: string;
}

const UpdateGeneratorDailyLogForm: React.FC<IProps> = (props) => {

	const history = useHistory();

	const [operatorsList, setOperatorsList] = useState<Array<CraneOperator>>([]);
	const [updateGeneratorDailyLogForm, setUpdateGeneratorDailyLogForm] = useState<UpdateGeneratorDailyLogBody>(defaultUpdateGeneratorDailyLogForm);
	const [userProfile, setUserProfile] = useState<User>(undefined); // Used for making the user enter their name as a signature (matching the name).
	const [signature, setSignature] = useState(""); // The "signature" as entered by the user; not submitted to api but used to check for the "initials" (boolean) field.
	const [rawEditingResponse, setRawEditingResponse] = useState<GeneratorDailyLog>(undefined);
	const [rawGroupedLog, setRawGroupedLog] = useState<GeneratorDailyLogGroup>(undefined);

	useEffect(() => {
		getAutoCompleteValues().then().catch();

		if (props.id && props.day !== undefined) {
			if (validLogDays.indexOf(props.day) < 0) {
				history.replace("/form-history/daily-log-history/equipment-daily-log-history");
			} else {
				getFormForEditing().then().catch();
			}
		}
	}, []);

	/**
	 * Call api to get the list of generators & sites for the auto-complete inputs, and the user's profile for the signature match.
	 *
	 */
	async function getAutoCompleteValues(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const operatorsRes = await new UsersApi(getConfig(props.fullToken)).getUserList({
				limit: 100000,
				offset: 0,
				type: [UserType.CRANE_OPERATOR],
			});

			const userRes = (await new UsersApi(getConfig(props.fullToken)).getProfile()).user;

			setOperatorsList(operatorsRes.users as Array<CraneOperator>);
			setUserProfile(userRes);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Call api to get existing Generator Daily Log if props.id exists,
	 * and save it to the form object after formatting correctly.
	 *
	 */
	async function getFormForEditing(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FormsApi(getConfig(props.fullToken)).getGeneratorDailyLog({
				id: props.id,
			});

			// Grab the correct "day" from the grouped res (index 0 -> 6)
			const singleDayLog: GeneratorDailyLog = res["_" + props.day];

			if (!isGenerator(res.equipment)) {
				props.dispatch(addError({messages: ["There was an error trying to interpret the equipment associated with this log."]}));
				history.replace("/form-history/daily-log-history/crane-daily-log-history");
				return;
			}

			setUpdateGeneratorDailyLogForm(convertGeneratorDailyLogToUpdateBody(singleDayLog, Number(props.day)));
			setRawEditingResponse(singleDayLog);
			setRawGroupedLog(res)
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * onChange handler for the "Inspection Items" section, which
	 * are displayed dynamically based on the type of crane selected.
	 *
	 * @param key
	 * @param value
	 */
	function onInspectionItemChange(key: keyof CreateGeneratorDailyLogBody, value: InspectionStatus): void {
		setUpdateGeneratorDailyLogForm({
			...updateGeneratorDailyLogForm,
			[key]: value,
		});
	}

	/**
	 * onChange to help with the single select auto-complete for choosing an employee.
	 *
	 * @param operators
	 */
	function operatorsOnChange(operators: string[]): void {
		setUpdateGeneratorDailyLogForm({
			...updateGeneratorDailyLogForm,
			users: operators,
		});
	}

	/**
	 * Dynamic onChange for number inputs using the NumberFormat library.
	 *
	 * @param key
	 */
	function numberFormatOnChange(key: keyof CreateGeneratorDailyLogBody): (e) => void {
		return (e) => {
			setUpdateGeneratorDailyLogForm({
				...updateGeneratorDailyLogForm,
				[key]: e?.floatValue,
			});
		}
	}

	/**
	 * onChange function for all of the dynamic lists with plus & minus icons.
	 *
	 * @param key
	 */
	function inputListOnChange<T>(key: keyof CreateGeneratorDailyLogBody): (i: number, value: T) => void {
		return (i: number, value: T) => {
			const copy: Array<T> = cloneDeep(updateGeneratorDailyLogForm[key] as unknown as Array<T>);
			copy[i] = value;

			setUpdateGeneratorDailyLogForm({
				...updateGeneratorDailyLogForm,
				[key]: copy,
			});
		}
	}

	/**
	 * Add another entry to the array in question.
	 *
	 */
	function inputListOnAdd<T>(key: keyof CreateGeneratorDailyLogBody): () => void {
		return () => {
			setUpdateGeneratorDailyLogForm({
				...updateGeneratorDailyLogForm,
				[key]: (updateGeneratorDailyLogForm[key] as unknown as Array<T>).concat([defaultCreateGeneratorDailyLogForm[key][0]]),
			});
		}
	}

	/**
	 * Remove item at index i from the array in question.
	 *
	 */
	function inputListOnRemove<T>(key: keyof CreateGeneratorDailyLogBody): (i: number) => void {
		return (i: number) => {
			const copy: Array<T> = cloneDeep(updateGeneratorDailyLogForm[key] as unknown as Array<T>);
			copy.splice(i, 1);

			setUpdateGeneratorDailyLogForm({
				...updateGeneratorDailyLogForm,
				[key]: copy,
			});
		}
	}

	/**
	 * Dynamic onChange for standard text inputs.
	 *
	 * @param key
	 */
	function textInputOnChange(key: keyof CreateGeneratorDailyLogBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setUpdateGeneratorDailyLogForm({
				...updateGeneratorDailyLogForm,
				[key]: e?.target?.value,
			});
		}
	}

	/**
	 * onChange for the signature field that the user must enter before submitting.
	 *
	 * @param e
	 */
	function onSignatureChange(e: React.ChangeEvent<HTMLInputElement>): void {
		setSignature(e?.target?.value);
	}

	/**
	 * Call api to either submit new Generator Daily Log or update existing and return id.
	 *
	 * @param e
	 */
	async function saveGeneratorDailyLog(e?: React.SyntheticEvent): Promise<string> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		let createResponse: GeneratorDailyLogGroup;

		try {
			const initials: boolean = signature?.toLowerCase() === getFullNameForTable(userProfile)?.toLowerCase(); // Check if the user's entered signature matches their name properly.

			const request = {
				users: updateGeneratorDailyLogForm.users,
				day: Number(props.day),
				hours: updateGeneratorDailyLogForm?.hours !== undefined ? updateGeneratorDailyLogForm?.hours : undefined,
				hourMeterStart: updateGeneratorDailyLogForm?.hourMeterStart !== undefined ? updateGeneratorDailyLogForm?.hourMeterStart : undefined,
				hourMeterEnd: updateGeneratorDailyLogForm?.hourMeterEnd !== undefined ? updateGeneratorDailyLogForm?.hourMeterEnd : undefined,
				hourMeterTotal: updateGeneratorDailyLogForm?.hourMeterTotal !== undefined ? updateGeneratorDailyLogForm?.hourMeterTotal : undefined,
				createLogCommentBodies: updateGeneratorDailyLogForm?.createLogCommentBodies?.filter((c: CreateLogCommentBody): boolean => {
					return c.comment?.length > 0;
				}).map(c => {
					return {
						...c,
						date: convertOffsetDate(rawGroupedLog.date + (Number(props.day) * 1000 * 60 * 60 * 24)),
					}
				}),
				checkOilLevel: updateGeneratorDailyLogForm?.checkOilLevel ? updateGeneratorDailyLogForm?.checkOilLevel : undefined,
				checkCoolantLevels: updateGeneratorDailyLogForm?.checkCoolantLevels ? updateGeneratorDailyLogForm?.checkCoolantLevels : undefined,
				visualInspectionForWear: updateGeneratorDailyLogForm?.visualInspectionForWear ? updateGeneratorDailyLogForm?.visualInspectionForWear : undefined,
				checkForLeakage: updateGeneratorDailyLogForm?.checkForLeakage ? updateGeneratorDailyLogForm?.checkForLeakage : undefined,
				ensureDoorPanelsAndCoversAreClosed: updateGeneratorDailyLogForm?.ensureDoorPanelsAndCoversAreClosed ? updateGeneratorDailyLogForm?.ensureDoorPanelsAndCoversAreClosed : undefined,
				initials,
			}

			await new FormsApi(getConfig(props.fullToken)).updateGeneratorDailyLog({
				id: props.id,
				updateGeneratorDailyLogBody: request,
			});
		} catch (err) {
			props.dispatch(addError(err));
			props.dispatch(decrementLoading());
			throw err;
		}

		props.dispatch(decrementLoading());

		if (!props.id && createResponse) {
			return createResponse?._id;
		}
	}

	/**
	 * User when creating or updating to save the draft.
	 * Afterwards, navigate to the history page.
	 *
	 * @param e
	 */
	async function saveDraft(e?: React.SyntheticEvent): Promise<void> {
		e?.preventDefault();
		try {
			await saveGeneratorDailyLog();
			history.push("/form-history/daily-log-history/equipment-daily-log-history");
		} catch (e) {

		}
	}

	/**
	 * Util to check if the "Save Draft" button should be enabled
	 *
	 * @param _signature
	 * @param user
	 */
	function checkForSaveButtonEnabled(_signature: string, user: User): boolean {
		return !((_signature?.toLowerCase() === getFullNameForTable(user).toLowerCase()));
	}

	/**
	 * Frontend filtering to get just the operators who exist on the selected site.
	 *
	 * @param operators
	 */
	function getFilteredOperators(operators: Array<CraneOperator>): Array<CraneOperator> {
		return operators.filter(o => o.sites?.find(s => s._id === rawGroupedLog.originalSite._id));
	}

	if (!rawGroupedLog) {
		return null;
	}

	const selectedGenerator: Generator = rawGroupedLog?.equipment as Generator;

	return (
		<AlliedContainer>
			<AlliedPageHeader
				title="Edit Generator Daily Log"
				showTooltip={false}
			/>

			<hr/>

			<AlliedFormContainer>
				<form onSubmit={saveDraft}>
					<Row>
						<Col xs={12} md={6} className="mb-3">
							<label>Editing log for Generator:</label>
							<b>
								{`${selectedGenerator.make} ${selectedGenerator.model} - ${selectedGenerator.serialNumber}`}
							</b>
							<br/>
							<b>
								{moment(rawGroupedLog.date + (Number(props.day) * 1000 * 60 * 60 * 24)).format("dddd MMM Do")}
							</b>
						</Col>
					</Row>

					<hr/>

					<Row>
						<Col xs={12} md={6} className="mb-3">
							<AlliedSelectInputList
								key={JSON.stringify(selectedGenerator)}
								title="Inspection Items"
								emptyMessage="Please select a crane first."
								inputs={
									convertGeneratorInspectionItemsToInputListItems(
										[
											"checkOilLevel",
											"checkCoolantLevels",
											"visualInspectionForWear",
											"checkForLeakage",
											"ensureDoorPanelsAndCoversAreClosed",
										],
										updateGeneratorDailyLogForm,
									)}
								onChange={onInspectionItemChange}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4} className="mb-3">
							<label>Operators for Selected Date</label>
							<AlliedAutoComplete
								placeholder="Select Operators..."
								options={parseUsersForAutoComplete(getFilteredOperators(operatorsList))}
								selections={updateGeneratorDailyLogForm.users}
								setSelections={operatorsOnChange}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<label>Daily Generator Hours:</label>
						</Col>

						<Col xs={12} md={4} className="mb-3">
							<NumberFormat
								allowLeadingZeros={false}
								placeholder="Enter Daily Generator Hours..."
								value={updateGeneratorDailyLogForm.hours}
								onValueChange={numberFormatOnChange("hours")}
								allowNegative={false}
								decimalScale={2}
							/>
						</Col>
					</Row>

					<h5 className="font-weight-bold">Generator Hour Meter</h5>

					<Row>
						<Col xs={12}>
							<label>Record the Hour Meter Value at the Beginning of the Day:</label>
						</Col>

						<Col xs={12} md={4} className="mb-3">
							<NumberFormat
								allowLeadingZeros={false}
								placeholder="Enter Hour Value..."
								value={updateGeneratorDailyLogForm.hourMeterStart}
								onValueChange={numberFormatOnChange("hourMeterStart")}
								allowNegative={false}
								decimalScale={2}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<label>Record the Hour Meter Value at the End of the Day:</label>
						</Col>

						<Col xs={12} md={4} className="mb-3">
							<NumberFormat
								allowLeadingZeros={false}
								placeholder="Enter Hour Value..."
								value={updateGeneratorDailyLogForm.hourMeterEnd}
								onValueChange={numberFormatOnChange("hourMeterEnd")}
								allowNegative={false}
								decimalScale={2}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<label>Total Hour Meter Value for Next Service:</label>
						</Col>

						<Col xs={12} md={4} className="mb-3">
							<NumberFormat
								allowLeadingZeros={false}
								placeholder="Enter Hour Value..."
								value={updateGeneratorDailyLogForm.hourMeterTotal}
								onValueChange={numberFormatOnChange("hourMeterTotal")}
								allowNegative={false}
								decimalScale={2}
							/>
						</Col>
					</Row>

					{(props.id !== undefined && rawEditingResponse?.comments?.length > 0) && (
						<Row>
							<Col xs={12}>
								<h5 className="font-weight-bold">Previous Comments</h5>
								<h6>(Cannot be edited)</h6>
							</Col>
							<Col xs={12} md={6} className="mb-3">
								<AlliedGenericInputList
									values={rawEditingResponse?.comments}
									component={DailyLogCommentInput}
									forwardedProps={{radioNameSuffix: "-previous"}}
								/>
							</Col>
						</Row>
					)}

					<Row>
						<Col xs={12}>
							<h5 className="font-weight-bold">Comments</h5>
						</Col>
						<Col xs={12} md={6} className="mb-3">
							<AlliedGenericInputList
								values={updateGeneratorDailyLogForm.createLogCommentBodies}
								onChange={inputListOnChange<CreateLogCommentBody>("createLogCommentBodies")}
								onAdd={inputListOnAdd("createLogCommentBodies")}
								onRemove={inputListOnRemove("createLogCommentBodies")}
								component={DailyLogCommentInput}
							/>
						</Col>
					</Row>

					<hr/>

					<Row>
						<Col xs={12}>
							<label>Enter full name to confirm this report is accurate.</label>
						</Col>

						<Col xs={12} sm={6} md={4} lg={3} className="mb-3">
							<input
								placeholder="Enter Signature..."
								value={signature}
								onChange={onSignatureChange}
							/>
						</Col>
						<Col xs={12} sm={6} md={4} lg={3} className="mb-3 user-select-none">
							<input
								disabled={true}
								placeholder="Loading..."
								value={getFullNameForTable(userProfile)}
								className="user-select-none pointer-events-none"
							/>
						</Col>
					</Row>

					<div className="d-flex flex-column align-items-center flex-sm-row">
						<Button
							color="black"
							disabled={checkForSaveButtonEnabled(signature, userProfile)}
							type="submit"
							onClick={saveDraft}
							className="mr-0 mr-sm-3 mb-3 mb-sm-0"
						>
							Update Draft
						</Button>
					</div>
				</form>
			</AlliedFormContainer>
		</AlliedContainer>
	);
}

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(UpdateGeneratorDailyLogForm);
