import React from "react";
import {Equipment, EquipmentApi, Token} from "client";
import AbstractPrintableButtonManager from "./AbstractPrintableButtonManager";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/MetaActions";
import getConfig from "../../../utils/getConfig";
import EquipmentLocationListPrintable from "../forms/EquipmentLocationListPrintable";
import {connect} from "react-redux";
import {IStore} from "../../../redux/defaultStore";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	children: string;
	showPrintable?: boolean;
	className?: string;
}

const ViewEquipmentLocationListButton: React.FC<IProps> = (props) => {

	/**
	 * Call api to get & return the equipment location list.
	 *
	 */
	async function getCraneWeeklyLogs(): Promise<Array<Equipment>> {
		props.dispatch(incrementLoading());

		try {
			const res = await new EquipmentApi(getConfig(props.fullToken)).getEquipmentList({
				limit: Number.MAX_SAFE_INTEGER,
				offset: 0,
			});

			props.dispatch(decrementLoading());
			return res.equipment;
		} catch (e) {
			props.dispatch(addError(e));
			props.dispatch(decrementLoading());
		}
	}

	return (
		<AbstractPrintableButtonManager
			<Array<Equipment>>
			color="black"
			onClick={getCraneWeeklyLogs}
			component={EquipmentLocationListPrintable}
			showPrintable={props.showPrintable}
			className={props.className}
			documentTitle="equipment-location-list"
		>
			{props.children}
		</AbstractPrintableButtonManager>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ViewEquipmentLocationListButton);
