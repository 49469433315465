import React, {useState} from "react";
import {TimeSheet} from "client";
import {Button} from "reactstrap";
import ManageTimeSheetsEditEntriesModal from "../../modals/ManageTimeSheetsEditEntriesModal";

interface IProps {
	timeSheet: TimeSheet;
	onDone: () => Promise<void>;
}

// This is the version of the table cell used by Admins.
// For the Employee version see ManageTimeSheetsHistoryEditEntriesCell.

const ManageTimeSheetsEditEntriesCell: React.FC<IProps> = (props) => {

	const [showEntriesModal, setShowEntriesModal] = useState(false);

	/**
	 * Toggle visibility of the time sheet entries modal
	 *
	 */
	function toggleShowEntriesModal(): void {
		setShowEntriesModal(!showEntriesModal);
	}

	/**
	 * Close modal and call isDone function from props to update table.
	 *
	 */
	function onDone(): void {
		setShowEntriesModal(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<ManageTimeSheetsEditEntriesModal
				isOpen={showEntriesModal}
				timeSheet={props.timeSheet}
				onClose={toggleShowEntriesModal}
				onDone={onDone}
			/>

			<div className="allied-table-cell">
				<Button
					color="black"
					onClick={toggleShowEntriesModal}
				>
					Add/Edit/Publish Entries
				</Button>
			</div>
		</React.Fragment>
	);
};

export default ManageTimeSheetsEditEntriesCell;
