import React, {useEffect, useState} from "react";
import {
	GetDeficiencyListForEquipmentRequest,
	Token
} from "client";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button, Col, Row} from "reactstrap";
import AlliedDatePicker from "../inputs/AlliedDatePicker";
import moment from "moment";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	filter: GetDeficiencyListForEquipmentRequest;
	onClose: () => void;
	onDone: (newFilter: GetDeficiencyListForEquipmentRequest) => void;
}

const GeneralDeficiencyFilterModal: React.FC<IProps> = (props) => {

	const [filter, setFilter] = useState<GetDeficiencyListForEquipmentRequest>(props.filter);

	/**
	 * When modal opens, update the local filter to match that of props.
	 *
	 */
	useEffect(() => {
		if (props.isOpen) {
			setFilter(props.filter);
		}
	}, [props.isOpen]);

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function resetAndClose(): void {
		setFilter(props.filter);
		props.onClose();
	}

	/**
	 * onChange for the date inputs.
	 *
	 * @param key
	 */
	function onDateChange(key: keyof GetDeficiencyListForEquipmentRequest): (d: Date) => void {
		return (d: Date) => {
			setFilter({
				...filter,
				[key]: key === "to" ? moment(d).endOf("day").valueOf() : moment(d).valueOf(),
			});
		}
	}

	/**
	 * When done configuring filter, call onDone function from props.
	 *
	 */
	function onSave(): void {
		props.onDone(filter);
	}

	return (
		<AlliedModal
			size="lg"
			isOpen={props.isOpen}
		>
			<AlliedModalHeader
				title="Filter Deficiencies"
				onClose={resetAndClose}
			/>

			<hr/>

			<Row>
				<Col xs={12} lg={6} className="mb-3">
					<label>Generate Forms From</label>
					<AlliedDatePicker
						selected={filter.from}
						onChange={onDateChange("from")}
						showMonthDropdown={true}
						showYearDropdown={true}
						dropdownMode="select"
					/>
				</Col>

				<Col xs={12} lg={6} className="mb-3">
					<label>Generate Forms To</label>
					<AlliedDatePicker
						selected={filter.to}
						onChange={onDateChange("to")}
						showMonthDropdown={true}
						showYearDropdown={true}
						dropdownMode="select"
					/>
				</Col>
			</Row>

			<div>
				<Button
					color="black"
					onClick={onSave}
				>
					Apply Filters
				</Button>
			</div>
		</AlliedModal>
	);
}

export default GeneralDeficiencyFilterModal;
