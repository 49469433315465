import React, {ReactNode, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {CraneDailyLog, FormsApi, CraneDailyLogGroup, Token, UserType} from "client";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import CraneDailyLogsViewOtherFieldsCell from "../tables/cells/CraneDailyLogsViewOtherFieldsCell";
import CraneDailyLogsManagerAndSupervisorEditIndividualLogCell
    from "../tables/cells/CraneDailyLogsManagerAndSupervisorEditIndividualLogCell";
import AlliedTableHeaderCell from "../tables/cells/AlliedTableHeaderCell";
import {parseDateForTableDisplay} from "../../utils/parseDateForTableDisplay";
import AlliedTableContainer from "../tables/AlliedTableContainer";
import {valueOrBust} from "../../utils/valueOrBust";
import ViewCraneDailyLogButton from "../printables/buttons/ViewCraneDailyLogButton";
import ConfirmUnlockLogsModal from "./ConfirmUnlockLogsModal";
import {
    addDatesToEachIndividualCraneDailyLogWithinGroup,
    CraneDailyLogWithDate
} from "../../utils/getDateOfIndividualDailyLogBasedOnDay";
import {getOperatorsOnSingleDailyLog} from "../../utils/getAllOperatorsFromGroupedDailyLogs";
import CraneDailyLogsOperatorConfirmDeleteGroupModal from "./CraneDailyLogsOperatorConfirmDeleteGroupModal";
import {userTypeCheck} from "../../utils/userTypeCheck";

interface IProps {
    dispatch?: any;
    fullToken?: Token;
    isOpen: boolean;
    logGroup: CraneDailyLogGroup;
    onClose: () => void;
    onDone: () => void;
}

const CraneDailyLogsManagerAndSupervisorOptionsModal: React.FC<IProps> = (props) => {

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showConfirmUnlockLogsModal, setShowConfirmUnlockLogsModal] = useState(false);

    function toggleDeleteGroupModal(): void {
        setShowDeleteModal(!showDeleteModal);
    }

    function onDoneDeleteModal(): void {
        setShowDeleteModal(false);
        props.onDone();
    }

    function toggleConfirmUnlockLogsModal(): void {
        setShowConfirmUnlockLogsModal(!showConfirmUnlockLogsModal);
    }

    /**
     * Call api to approve the Crane Daily Logs,
     * and call onDone function from props after to update table.
     *
     */
    async function onApproveCraneDailyLogs(): Promise<void> {
        props.dispatch(incrementLoading());

        try {
            await new FormsApi(getConfig(props.fullToken)).publishCraneDailyLog({
                id: props.logGroup?._id,
            });

            props.onDone();
        } catch (e) {
            props.dispatch(addError(e));
        }

        props.dispatch(decrementLoading());
    }

    /**
     * Call api to mark group of logs as un-submitted, removing them from the user's view, and making them editable
     * again by the originally submitting user.
     *
     */
    async function confirmUnlockLogs(): Promise<void> {
        props.dispatch(incrementLoading());

        try {
            await new FormsApi(getConfig(props.fullToken)).unSubmitForm({
                iDBody: {
                    // Pass the id of any of the entries to submit the whole group.
                    id: props.logGroup?._id,
                }
            });

            setShowConfirmUnlockLogsModal(false);
            props.onDone();
        } catch (e) {
            props.dispatch(addError(e));
        } finally {
            props.dispatch(decrementLoading());
        }
    }

    /**
     * Renderer of the Edit Log column.
     *
     * @param log
     */
    function makeOtherFieldsCell(log: CraneDailyLog): ReactNode {
        return (
            <CraneDailyLogsViewOtherFieldsCell
                log={log}
                logGroup={props.logGroup}
            />
        );
    }

    /**
     * Renderer of the Edit Log column.
     *
     * @param log
     */
    function makeEditCell(log: CraneDailyLogWithDate): ReactNode {
        return (
            <CraneDailyLogsManagerAndSupervisorEditIndividualLogCell
                log={log}
                logGroup={props.logGroup}
            />
        );
    }

    return (
        <React.Fragment>
            <CraneDailyLogsOperatorConfirmDeleteGroupModal
                isOpen={showDeleteModal}
                log={props.logGroup}
                onClose={toggleDeleteGroupModal}
                onDone={onDoneDeleteModal}
            />

            <ConfirmUnlockLogsModal
                isOpen={showConfirmUnlockLogsModal}
                onClose={toggleConfirmUnlockLogsModal}
                onConfirm={confirmUnlockLogs}
            />

            <AlliedModal
                isOpen={props.isOpen}
                size="xl"
            >
                <AlliedModalHeader
                    title="Crane Daily Log Options"
                    onClose={props.onClose}
                />

                <hr/>

                <p>
                    *Note that you can only edit the logs <b>before</b> they're published.
                </p>

                <div className="d-flex flex-row flex-wrap align-items-start">
                    <Button
                        color="red"
                        onClick={onApproveCraneDailyLogs}
                        disabled={props.logGroup?.publishedDate !== undefined}
                        className="mb-3 mr-3"
                    >
                        Publish Logs
                    </Button>

                    <ViewCraneDailyLogButton
                        groupedCraneLogs={props.logGroup}
                        className="mb-3 mr-3"
                    >
                        View Logs
                    </ViewCraneDailyLogButton>

                    <Button
                        color="black"
                        onClick={toggleDeleteGroupModal}
                        disabled={props.logGroup?.submittedDate !== undefined}
                        className="mb-3 mr-3"
                    >
                        Delete Log Group
                    </Button>

                    {userTypeCheck([UserType.SECONDARY_ADMIN, UserType.SUPERVISOR], props.fullToken) && props.logGroup?.publishedDate === undefined && (
                        <Button
                            color="black"
                            onClick={toggleConfirmUnlockLogsModal}
                            className="mb-3"
                        >
                            Unlock Logs
                        </Button>
                    )}

                    {userTypeCheck([UserType.ADMIN], props.fullToken) && props.logGroup?.publishedDate !== undefined && (
                        <Button
                            color="black"
                            onClick={toggleConfirmUnlockLogsModal}
                            className="mb-3"
                        >
                            Unlock Logs
                        </Button>
                    )}
                </div>

                    <hr className="mt-0"/>

                    <AlliedTableContainer
                        data={addDatesToEachIndividualCraneDailyLogWithinGroup(props.logGroup)}
                        columnOptions={[
                            {
                                headerValue: "Date",
                                key: "date",
                                showSortIcons: false,
                                sortable: true,
                                headerRender: AlliedTableHeaderCell,
                                valueFormatter: parseDateForTableDisplay,
                            },
                            {
                                headerValue: "Hours",
                                key: "hours",
                                showSortIcons: false,
                                sortable: true,
                                headerRender: AlliedTableHeaderCell,
                            },
                            {
                                headerValue: "Operator(s)",
                                key: "users",
                                showSortIcons: false,
                                sortable: true,
                                headerRender: AlliedTableHeaderCell,
                                valueFormatter: (value, cellContextDetails) => getOperatorsOnSingleDailyLog(cellContextDetails.row as CraneDailyLog),
                            },
                            {
                                headerValue: "View All Fields",
                                key: undefined,
                                showSortIcons: false,
                                sortable: false,
                                headerRender: AlliedTableHeaderCell,
                                cellRender: makeOtherFieldsCell,
                                headerCellClassName: "justify-content-center",
                                rowCellClassName: "justify-content-center",
                            },
                            {
                                headerValue: "Edit Log",
                                key: undefined,
                                showSortIcons: false,
                                sortable: true,
                                headerRender: AlliedTableHeaderCell,
                                cellRender: makeEditCell,
                                headerCellClassName: "justify-content-center",
                                rowCellClassName: "justify-content-center",
                            },
                        ]}
                    />
            </AlliedModal>
        </React.Fragment>
)
;
};

export default connect((store: IStore, props: IProps) => {
    return {
        fullToken: store.metaStore.fullToken,
        ...props,
    }
})(CraneDailyLogsManagerAndSupervisorOptionsModal);

