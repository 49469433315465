import React, {ReactNode} from "react";
import classNames from "classnames";

interface IProps {
	children: ReactNode;
	onClick: () => void;
	className?: string;
}

const AlliedTextButton: React.FC<IProps> = (props) => {

	function onClickHelper(e?: React.SyntheticEvent): void {
		e?.preventDefault();
		props?.onClick();
	}

	return (
		<a
			href="#"
			onClick={onClickHelper}
			className={classNames("allied-text-button", props.className)}
		>
			{props.children}
		</a>
	);
};

export default AlliedTextButton;
