import React, {ChangeEvent, ChangeEventHandler, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	Crane,
	CraneType,
	CreateEquipmentBody,
	Equipment,
	EquipmentApi,
	EquipmentType,
	Token,
	UpdateEquipmentBody
} from "client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import AlliedModal from "./AlliedModal";
import AlliedModalHeader from "./AlliedModalHeader";
import {Button, Col, Input, Row} from "reactstrap";
import {getCraneTypeDisplay} from "../../utils/getCraneTypeDisplay";
import AlliedSelect from "../inputs/AlliedSelect";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	equipment: Equipment;
	onClose: () => void;
	onDone: () => void;
}

type UpdateEquipmentBodyFrontend = Omit<UpdateEquipmentBody, "siteID">;

const ManageSitesEditEquipmentModal: React.FC<IProps> = (props) => {

	const defaultEditEquipmentForm: UpdateEquipmentBodyFrontend = {
		equipmentID: props.equipment?._id,
		make: props.equipment?.make,
		model: props.equipment?.model,
		serialNumber: props.equipment?.serialNumber,
		rentedBy: props.equipment?.rentedBy,
		ownedBy: props.equipment?.ownedBy,
		preExistingUsageHours: props.equipment?.preExistingUsageHours,
	}

	const [equipmentForm, setEquipmentForm] = useState<UpdateEquipmentBodyFrontend>(defaultEditEquipmentForm);
	const [craneType, setCraneType] = useState<CraneType>((props.equipment as Crane)?.craneType);

	/**
	 * Reset any changes to the form & call the onClose function from props.
	 *
	 */
	function resetAndClose(): void {
		setEquipmentForm(defaultEditEquipmentForm);
		setCraneType((props.equipment as Crane)?.craneType);
		props.onClose();
	}

	/**
	 * Dynamic onChange for the form text fields.
	 *
	 * @param key
	 */
	function editEquipmentOnChange(key: keyof CreateEquipmentBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setEquipmentForm({
				...equipmentForm,
				[key]: e?.target?.value,
			});
		}
	}

	/**
	 * onChange for the crane type field that only appears when equipment type is set to crane.
	 *
	 * @param event
	 */
	function craneTypeOnChange(event): void {
		setCraneType(event?.target?.value);
	}

	/**
	 * Dynamically call the correct update api on the equipment.
	 *
	 */
	async function submitEquipmentChange(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const apiBase = new EquipmentApi(getConfig(props.fullToken));

			const combinedForm: UpdateEquipmentBodyFrontend = equipmentForm;

			switch(props.equipment?.type) {
				case EquipmentType.CRANE:
					await apiBase.updateCrane({
						updateCraneBody: {
							siteID: props.equipment?.site?._id,
							...combinedForm,
							craneType,
						},
					});
					break;
				case EquipmentType.GENERATOR:
					await apiBase.updateGenerator({
						updateGeneratorBody: {
							siteID: props.equipment?.site?._id,
							...combinedForm
						},
					});
					break;
				case EquipmentType.MATERIAL_HOIST:
					await apiBase.updateMaterialHoist({
						updateMaterialHoistBody: {
							siteID: props.equipment?.site?._id,
							...combinedForm
						},
					});
					break;
			}

			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<AlliedModal
			isOpen={props.isOpen}
			size="lg"
		>
			<AlliedModalHeader
				title="Edit Equipment"
				onClose={resetAndClose}
				onBack={resetAndClose}
			/>

			<hr/>

			<Row>
				<Col xs={12} md={6} className="mb-3">
					<label>Make</label>
					<input
						placeholder="Enter Make..."
						value={equipmentForm.make}
						onChange={editEquipmentOnChange("make")}
					/>
				</Col>

				<Col xs={12} md={6} className="mb-3">
					<label>Model</label>
					<input
						placeholder="Enter Model..."
						value={equipmentForm.model}
						onChange={editEquipmentOnChange("model")}
					/>
				</Col>

				<Col xs={12} md={6} className="mb-3">
					<label>S/N</label>
					<input
						placeholder="Enter S/N..."
						value={equipmentForm.serialNumber}
						onChange={editEquipmentOnChange("serialNumber")}
					/>
				</Col>

				{props.equipment?.type === EquipmentType.CRANE && (
					<Col xs={12} md={6} className="mb-3">
						<label>Type</label>
						<AlliedSelect
							value={craneType}
							onChange={craneTypeOnChange}
						>
							<option value="" selected disabled>Select Crane Type</option>
							<hr/>
							<option value={CraneType.LUFFING_TOWER}>{getCraneTypeDisplay(CraneType.LUFFING_TOWER)}</option>
							<option value={CraneType.SELF_ERECTING}>{getCraneTypeDisplay(CraneType.SELF_ERECTING)}</option>
							<option value={CraneType.TOWER}>{getCraneTypeDisplay(CraneType.TOWER)}</option>
						</AlliedSelect>
					</Col>
				)}
			</Row>

			<div>
				<Button
					color="black"
					onClick={submitEquipmentChange}
				>
					Save Changes
				</Button>
			</div>
		</AlliedModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageSitesEditEquipmentModal);
