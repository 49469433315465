/* tslint:disable */
/* eslint-disable */
/**
 * Allied CraneAPI Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { AddressFromJSON, AddressToJSON, } from './Address';
/**
 * Check if a given object implements the UpdateSiteBody interface.
 */
export function instanceOfUpdateSiteBody(value) {
    let isInstance = true;
    isInstance = isInstance && "siteID" in value;
    return isInstance;
}
export function UpdateSiteBodyFromJSON(json) {
    return UpdateSiteBodyFromJSONTyped(json, false);
}
export function UpdateSiteBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'siteID': json['siteID'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'contactNumber': !exists(json, 'contactNumber') ? undefined : json['contactNumber'],
        'contactEmail': !exists(json, 'contactEmail') ? undefined : json['contactEmail'],
        'renter': !exists(json, 'renter') ? undefined : json['renter'],
        'siteOwner': !exists(json, 'siteOwner') ? undefined : json['siteOwner'],
    };
}
export function UpdateSiteBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'siteID': value.siteID,
        'name': value.name,
        'address': AddressToJSON(value.address),
        'contactNumber': value.contactNumber,
        'contactEmail': value.contactEmail,
        'renter': value.renter,
        'siteOwner': value.siteOwner,
    };
}
