import React, {useState} from "react";
import {Button} from "reactstrap";
import {CraneMonthlyLog} from "client";
import CraneMonthlyLogsViewOtherFieldsModal from "../../modals/CraneMonthlyLogsViewOtherFieldsModal";

interface IProps {
	log: CraneMonthlyLog;
}

const CraneMonthlyLogsViewOtherFieldsCell: React.FC<IProps> = (props) => {

	const [showModal, setShowModal] = useState(false);

	function toggleShowModal(): void {
		setShowModal(!showModal);
	}

	return (
		<React.Fragment>
			<CraneMonthlyLogsViewOtherFieldsModal
				isOpen={showModal}
				log={props.log}
				onClose={toggleShowModal}
			/>

			<div className="d-flex justify-content-center">
				<Button
					color="black"
					onClick={toggleShowModal}
				>
					View Fields
				</Button>
			</div>
		</React.Fragment>
	);
};

export default CraneMonthlyLogsViewOtherFieldsCell;
