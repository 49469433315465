import React, {useState} from "react";
import {Button} from "reactstrap";
import {User} from "client";
import ManageUsersDisableModal from "../../modals/ManageUsersDisableModal";

interface IProps {
	user: User;
	onDone: () => Promise<void>;
}

const ManageUsersDisableCell: React.FC<IProps> = (props) => {

	const [showDisable, setShowDisable] = useState(false);

	/**
	 * Toggle showing the edit user modal.
	 *
	 */
	function toggleShowDisable(): void {
		setShowDisable(!showDisable);
	}

	/**
	 * Call API to update parent table.
	 *
	 */
	function onDone(): void {
		setShowDisable(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<ManageUsersDisableModal
				isOpen={showDisable}
				user={props.user}
				onClose={toggleShowDisable}
				onDone={onDone}
			/>

			<div className="allied-table-cell">
				<Button
					color={props.user?.disabled ? "gray" : "red"}
					onClick={toggleShowDisable}
				>
					{props.user?.disabled ? "Enable User" : "Disable User"}
				</Button>
			</div>
		</React.Fragment>
	);
};

export default ManageUsersDisableCell;
